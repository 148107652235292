import React from 'react';
import { MultiPortfolioReviewComponents } from './components/common/MultiPortfolioReviewComponents';
import type { RootProps } from './components/common/MultiPortfolioReviewComponents.Root';
import { MultiProFormaReviewComponents } from './components/specialized/MultiProFormaReviewComponents';

type MultiPortfolioReviewStepProps = Omit<RootProps, 'children'>;

export const MultiProFormaReviewStep = (props: MultiPortfolioReviewStepProps) => {
  return (
    <MultiPortfolioReviewComponents.Root {...props}>
      <MultiPortfolioReviewComponents.Content>
        <MultiPortfolioReviewComponents.LeftSideBar />
        <MultiPortfolioReviewComponents.RightSideBar>
          <MultiProFormaReviewComponents.RightPane.ErrorBanner />
          <MultiProFormaReviewComponents.RightPane.ContentContainer>
            {({ portfolio }) => <MultiPortfolioReviewComponents.RightSideBarContent portfolio={portfolio} />}
          </MultiProFormaReviewComponents.RightPane.ContentContainer>
        </MultiPortfolioReviewComponents.RightSideBar>
      </MultiPortfolioReviewComponents.Content>
      <MultiProFormaReviewComponents.Footer />
      <MultiPortfolioReviewComponents.ConfirmationModal />
    </MultiPortfolioReviewComponents.Root>
  );
};
