import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  GetColor,
  Icon,
  Tooltip,
  TooltipPosition,
  ZIndex,
  ExternalActivityListener,
  portalMenuIgnoreActivityClassName,
} from 'venn-ui-kit';
import { PortfolioLabContext, ConfirmationModal, UnsavedChangesTrigger } from 'venn-components';
import AllocationConstraints from './AllocationConstraints';
import FactorExposureConstraints from './FactorExposureConstraints';

export const SIDE_BAR_WIDTH = 40;
const PANEL_WIDTH = 430;

export interface LabSideBarProps {
  isSideBarCollapsed: boolean;
  toggleSideBarCollapsed: () => void;
  allocationSectionExpanded: boolean;
  toggleAllocationSection: () => void;
  factorSectionExpanded: boolean;
  toggleFactorSection: () => void;
  optimizing?: boolean;
}

const LabSideBar = ({
  isSideBarCollapsed,
  toggleSideBarCollapsed,
  allocationSectionExpanded,
  toggleAllocationSection,
  factorSectionExpanded,
  toggleFactorSection,
  optimizing,
}: LabSideBarProps) => {
  const { loadingPolicy, allocationConstraints, factorConstraints } = useContext(PortfolioLabContext);
  const [isMaximized, setIsMaximized] = useState(true);
  const [isAllocationInEditState, setIsAllocationInEditState] = useState(false);
  const [isFactorExposureInEditState, setIsFactorExposureInEditState] = useState(false);

  const onToggleAllocationIcon = () => {
    if (isSideBarCollapsed) {
      if (!allocationSectionExpanded) {
        toggleAllocationSection();
      }
      if (factorSectionExpanded) {
        toggleFactorSection();
      }
      toggleSideBarCollapsed();
    } else {
      toggleAllocationSection();
    }
  };

  const onToggleFactorIcon = () => {
    if (isSideBarCollapsed) {
      if (!factorSectionExpanded) {
        toggleFactorSection();
      }
      if (allocationSectionExpanded) {
        toggleAllocationSection();
      }
      toggleSideBarCollapsed();
    } else {
      toggleFactorSection();
    }
  };

  const allocationConstraintsCount = loadingPolicy ? 0 : (allocationConstraints?.length ?? 0);
  const factorConstraintsCount = loadingPolicy ? 0 : (factorConstraints?.length ?? 0);

  const isInEditState = isAllocationInEditState || isFactorExposureInEditState;

  return (
    <UnsavedChangesTrigger isNotSaved={isInEditState}>
      {({ isOpen, handleConfirmNavigation, setIsOpen, handleReject }) => (
        <>
          {isOpen ? (
            <ConfirmationModal
              header="You have not saved your constraint changes."
              subhead="Would you like to exit without saving? "
              proceedLabel="Yes, discard changes"
              className="unsaved-changes-modal"
              onCancel={() => handleReject()}
              onSecondaryClick={() => handleReject()}
              onProceed={() => {
                handleConfirmNavigation();
                toggleSideBarCollapsed();
                setIsAllocationInEditState(false);
                setIsFactorExposureInEditState(false);
              }}
            />
          ) : null}
          <ExternalActivityListener
            onExternalActivity={isInEditState ? () => setIsOpen(true) : toggleSideBarCollapsed}
            listeningEnabled={!isSideBarCollapsed && !optimizing}
            ignoreActivityFromClassName={[
              portalMenuIgnoreActivityClassName,
              'update-constraints-modal',
              'clear-all-constraints-modal',
              'unsaved-changes-modal',
              'qa-navigate-to-home',
              'side-navbar',
            ]}
          >
            <SideBarAndPanelContainer isSideBarCollapsed={isSideBarCollapsed} isMaximized={isMaximized}>
              {!isSideBarCollapsed && <SideBarSeparator />}
              <SideBarLine>
                <Tooltip content={isSideBarCollapsed ? 'Show inputs' : 'Hide inputs'} position={TooltipPosition.Right}>
                  <Button type="button" onClick={isInEditState ? () => setIsOpen(true) : toggleSideBarCollapsed}>
                    <Icon type={isSideBarCollapsed ? 'expand' : 'compress'} />
                  </Button>
                </Tooltip>
                {!isSideBarCollapsed && (
                  <InputsBar>
                    <div>INPUTS</div>
                    <Tooltip content={isMaximized ? 'Collapse' : 'Expand'} usePortal position={TooltipPosition.Right}>
                      <MaximizeButton type="button" onClick={() => setIsMaximized(!isMaximized)}>
                        <Icon type={isMaximized ? 'sort-size-up-alt' : 'sort-size-down-alt'} />
                      </MaximizeButton>
                    </Tooltip>
                  </InputsBar>
                )}
              </SideBarLine>
              <InputSections>
                <SideBarLine>
                  <InputSectionIcon
                    type="button"
                    onClick={onToggleAllocationIcon}
                    borderRight={!isSideBarCollapsed}
                    className="qa-allocation-constraints-btn"
                  >
                    <Icon type="sliders-h" />
                    Allocation
                  </InputSectionIcon>
                  {!isSideBarCollapsed && (
                    <InputSectionExpand type="button" onClick={toggleAllocationSection}>
                      <div>Allocation Constraints ({allocationConstraintsCount})</div>
                      <ExpandIcon type={allocationSectionExpanded ? 'chevron-down' : 'chevron-right'} />
                    </InputSectionExpand>
                  )}
                </SideBarLine>
                {!isSideBarCollapsed && allocationSectionExpanded && (
                  <SideBarLine>
                    <BarSpace />
                    <InputSection>
                      <InputSectionContent>
                        <AllocationConstraints
                          isInEditState={isAllocationInEditState}
                          setIsInEditState={setIsAllocationInEditState}
                          isSideBarMaximized={isMaximized}
                        />
                      </InputSectionContent>
                    </InputSection>
                  </SideBarLine>
                )}
                <SideBarLine>
                  <InputSectionIcon
                    type="button"
                    onClick={onToggleFactorIcon}
                    borderRight={!isSideBarCollapsed}
                    className="qa-exposure-btn"
                  >
                    <Icon type="dna" />
                    Exposure
                  </InputSectionIcon>
                  {!isSideBarCollapsed && (
                    <InputSectionExpand type="button" onClick={toggleFactorSection}>
                      <div>Factor Exposure Constraints ({factorConstraintsCount})</div>
                      <ExpandIcon type={factorSectionExpanded ? 'chevron-down' : 'chevron-right'} />
                    </InputSectionExpand>
                  )}
                </SideBarLine>
                {!isSideBarCollapsed && factorSectionExpanded && (
                  <SideBarLine hideBorder>
                    <BarSpace />
                    <InputSection>
                      <InputSectionContent>
                        <FactorExposureConstraints
                          isInEditState={isFactorExposureInEditState}
                          setIsInEditState={setIsFactorExposureInEditState}
                          isSideBarMaximized={isMaximized}
                        />
                      </InputSectionContent>
                    </InputSection>
                  </SideBarLine>
                )}
              </InputSections>
            </SideBarAndPanelContainer>
          </ExternalActivityListener>
        </>
      )}
    </UnsavedChangesTrigger>
  );
};

export default LabSideBar;

const SideBarAndPanelContainer = styled.div<{ isSideBarCollapsed?: boolean; isMaximized?: boolean }>`
  position: absolute;
  height: 100%;
  width: ${({ isSideBarCollapsed, isMaximized }) =>
    isSideBarCollapsed
      ? SIDE_BAR_WIDTH
      : isMaximized
        ? SIDE_BAR_WIDTH + PANEL_WIDTH * 2
        : SIDE_BAR_WIDTH + PANEL_WIDTH}px;
  top: -1px;
  left: 0;

  border-top: 1px solid ${GetColor.Grey};
  border-right: 1px solid ${GetColor.Grey};
  ${({ isSideBarCollapsed }) => !isSideBarCollapsed && 'box-shadow: 10px 0px 5px rgba(0, 0, 0, 0.1);'}
  z-index: ${ZIndex.Cover};

  @media print {
    display: none;
  }
`;

const SideBarSeparator = styled.div`
  position: absolute;
  left: ${SIDE_BAR_WIDTH - 1}px;
  top: 0;
  height: 100%;
  border-right: 1px solid ${GetColor.Grey};
`;

const SideBarLine = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  flex: ${SIDE_BAR_WIDTH - 1}px 1;
  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border-bottom: 1px solid ${GetColor.Grey};
    `}
`;

const BarSpace = styled.div`
  min-width: ${SIDE_BAR_WIDTH}px;
  background-color: ${GetColor.WhiteGrey};
  border-right: 1px solid ${GetColor.Grey};
`;

const Button = styled.button`
  width: ${SIDE_BAR_WIDTH}px;
  height: ${SIDE_BAR_WIDTH}px;
  border-right: 1px solid ${GetColor.Grey};
  background-color: ${GetColor.Primary.Main};
  color: ${GetColor.Black};
  &:hover {
    background-color: ${GetColor.Primary.Main};
    color: ${GetColor.Black};
  }
`;

const InputsBar = styled.div`
  background-color: ${GetColor.Primary.Main};
  height: ${SIDE_BAR_WIDTH}px;
  width: calc(100% - ${SIDE_BAR_WIDTH}px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 8px;
`;

const MaximizeButton = styled.button`
  i {
    padding: 8px;
    transform: rotate(-90deg);
    color: ${GetColor.Black};
    &:hover: {
      color: ${GetColor.Black};
    }
  }
`;

const InputSections = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - ${SIDE_BAR_WIDTH}px);
  background-image: linear-gradient(
    90deg,
    ${GetColor.WhiteGrey} ${SIDE_BAR_WIDTH - 1}px,
    ${GetColor.Grey} ${SIDE_BAR_WIDTH - 1}px,
    ${GetColor.White} ${SIDE_BAR_WIDTH}px,
    ${GetColor.White} 100%
  );
`;

const InputSection = styled.div`
  width: 100%;
  background-color: ${GetColor.White};
`;

const InputSectionExpand = styled.button`
  width: calc(100% - ${SIDE_BAR_WIDTH}px);
  background-color: ${GetColor.White};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  font-weight: bold;
  color: ${GetColor.Black};
`;

const ExpandIcon = styled(Icon)`
  padding: 9px 8px 7px 8px;
`;

const InputSectionIcon = styled.button<{ borderTop?: boolean; borderRight?: boolean }>`
  width: ${SIDE_BAR_WIDTH}px;
  height: ${SIDE_BAR_WIDTH}px;
  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid ${GetColor.Grey};
    `}
  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid ${GetColor.Grey};
    `}
  background-color: ${GetColor.Black};
  color: ${GetColor.White};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  i {
    font-size: 12px;
    margin-bottom: 2px;
  }
  padding-top: 2px;
`;

const InputSectionContent = styled.div`
  width: 100%;
  min-height: 296px;
  display: flex;
  flex-direction: column;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${GetColor.HintGrey};
`;

export const StyledIcon = styled(Icon)`
  font-size: 14px;
  font-weight: normal;
  margin-right: 8px;
  color: ${GetColor.HintGrey};
`;
