import type { InputHTMLAttributes } from 'react';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { isNumber, uniqueId } from 'lodash';
import styled from 'styled-components';
import { Label } from '../typography';
import { GetColor } from '../../style/color';
import KeyCodes from '../../KeyCode';

interface TextFieldProps<T extends string | number = string>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id?: string;
  value?: T;
  label?: string;
  placeholder?: string;
  type?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  /**
   * If true, the input will only fire the onChange event on blur or if pressing Enter
   */
  changeOnBlur?: boolean;
  /**
   * Whether the text must be centered
   */
  center?: boolean;
  className?: string;
  onChange?: (value: T) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const TextField = <T extends string | number = string>({
  id,
  value,
  label,
  placeholder,
  type,
  innerRef,
  center,
  changeOnBlur,
  className,
  onChange,
  onFocus,
  onBlur,
  ...inputProps
}: TextFieldProps<T>) => {
  const [internal, setInternal] = useState<string>(value ? value.toString() : '');
  useEffect(() => {
    setInternal(value ? value.toString() : '');
  }, [value]);
  const inputId = useRef(id || uniqueId());
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      if (!changeOnBlur) {
        if (isNumber(value)) {
          onChange?.(parseFloat(v) as T);
        } else {
          onChange?.(v as T);
        }
      } else {
        setInternal(v);
        if (isNumber(value)) {
          onChange?.(parseFloat(v) as T);
        } else {
          onChange?.(v as T);
        }
      }
    },
    [onChange, value, changeOnBlur],
  );
  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === KeyCodes.Enter && changeOnBlur) {
        if (isNumber(internal)) {
          onChange?.(parseFloat(internal as string) as T);
        } else {
          onChange?.(internal as T);
        }
      }
    },
    [internal, onChange, changeOnBlur],
  );
  const handleBlur = useCallback(() => {
    if (changeOnBlur) {
      if (isNumber(internal)) {
        onChange?.(parseFloat(internal as string) as T);
      } else {
        onChange?.(internal as T);
      }
    }
    if (onBlur) {
      onBlur();
    }
  }, [onChange, onBlur, internal, changeOnBlur]);
  return (
    <TextFieldContainer className={className}>
      {label && <Label htmlFor={inputId.current}>{label}</Label>}
      <Input
        {...inputProps}
        id={inputId.current}
        value={internal}
        ref={innerRef}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
        center={center!}
        type={type || 'text'}
      />
    </TextFieldContainer>
  );
};

const TextFieldContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  ${Label} {
    margin-bottom: 5px;
  }
`;

const Input = styled.input<{ center: boolean }>`
  border-radius: 4px;
  border: solid 1px ${GetColor.Grey};
  background-color: ${GetColor.White};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${GetColor.Black};
  ${(props) => (props.center ? 'text-align: center;' : '')}

  &:focus-within {
    border-color: ${GetColor.Primary.Main};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }

  &::placeholder {
    color: ${GetColor.HintGrey};
    opacity: 1;
  }
`;

export default TextField;
