import React from 'react';
import { Icon, Tooltip, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import type { AnalysisConfig, ExcelCell } from 'venn-utils';
import { formatExportValue, getSecondaryDisplayLabel, SpecialCssClasses } from 'venn-utils';
import type { AnalysisRequest, Scenario, ScenarioAnalysis, ShockUnitsEnum } from 'venn-api';
import type { RowWithID } from '../../basictable/types';
import { SORTDIR } from '../../basictable/index';
import type { ScenarioAnalysisResult } from '../../utils/scenario';
import { convertByUnits, getUnitPrecision, getUnitSymbol } from '../../utils/scenario';
import { compact, findIndex } from 'lodash';

/* If server storage shock that are no longer available, for example,
it's out of 2 STD, we should use the closest range bound value */
export const limitAvailShock = (scenarios: Scenario[]): Scenario[] =>
  scenarios.map((scenario) => ({
    ...scenario,
    shock: getInRangeValue(scenario.shock, scenario.minShock, scenario.maxShock),
  }));

/* If user types a value that is out of range, we would use the closest bound value */
export const getInRangeValue = (shock: number, min: number | undefined, max: number | undefined) => {
  if (!min || !max) {
    return shock;
  }
  return shock > max ? max : shock < min ? min : shock;
};

export const formatZScore = (value: number): number => {
  return parseFloat(value.toFixed(1));
};

export const formattedNumber = (
  value: number | undefined,
  units = 'PERCENTAGE' as ShockUnitsEnum,
  isErrorBand?: boolean,
): string => {
  const converted = parseFloat(convertByUnits(value, units)).toFixed(getUnitPrecision(units));
  const unit = getUnitSymbol(units);
  // Error Band (+/-) won't have `+` for positive value
  return value && value > 0 ? `${isErrorBand ? '' : '+'}${converted}${unit}` : `${converted}${unit}`;
};

const formatCell = (value: number | undefined, units = 'PERCENTAGE' as ShockUnitsEnum): ExcelCell => {
  return formatExportValue(value, units === 'PERCENTAGE' || units === 'BPS', units === 'PRICE');
};

export const convertLoading = (senarioList: Scenario[]): ScenarioAnalysisResult[] => {
  return senarioList.map((scenario) => ({
    ...scenario,
    id: scenario.id!,
    mainPredict: undefined,
    benchmarkPredict: undefined,
    comparisonPredict: undefined,
    loading: true,
  }));
};

export const convertResponse = (
  request: Partial<AnalysisRequest> | undefined,
  analyses: ScenarioAnalysis[][],
): ScenarioAnalysisResult[] => {
  if (!request || !request.analyses || !request.analyses[0]) {
    return [];
  }
  const subjectIndex = 0;
  const benchmarkIndex = findIndex(request.subjects, (s) => s.comparisonType === 'BENCHMARK');
  const comparisonIndex = findIndex(
    request.subjects,
    (s) => s.comparisonType === 'COMPARISON' || s.comparisonType === 'CATEGORY',
  );
  const scenarioList = request.analyses[0].scenarios ?? [];
  return scenarioList
    .map((scenario, index) => ({
      ...scenario,
      id: scenario.id!,
      mainPredict: analyses?.[subjectIndex]?.[index],
      benchmarkPredict: analyses?.[benchmarkIndex]?.[index],
      comparisonPredict: analyses?.[comparisonIndex]?.[index],
      loading: false,
    }))
    .filter((scenario) => scenario.mainPredict !== undefined);
};

export const getMax = (result: ScenarioAnalysisResult[]) => {
  let max = 0;
  result.forEach((item) => {
    max = Math.max(
      Math.abs(item?.mainPredict?.predicted || 0),
      Math.abs(item?.benchmarkPredict?.predicted || 0),
      Math.abs(item?.comparisonPredict?.predicted || 0),
      max,
    );
  });
  return max;
};

export const scenarioReturnHeaderRenderer = (label: string) => (
  <ReturnHeader>
    {label}
    <Tooltip content="Performance of index over next 30 days." maxWidth={240}>
      <InfoIcon type="info-circle" prefix="fas" className={SpecialCssClasses.NotDownloadable} />
    </Tooltip>
  </ReturnHeader>
);

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const renderHead = (colSpan: number, relative: boolean) => () => (
  <Tr>
    <th />
    <th />
    <th />
    <StyledTh colSpan={colSpan}>{relative ? 'Estimated Relative Return' : 'Estimated Return'}</StyledTh>
  </Tr>
);

export const hasBenchmarkData = (analysisConfig: AnalysisConfig, scenariosResult: ScenarioAnalysisResult[]) =>
  !!analysisConfig?.subject?.activeBenchmark &&
  scenariosResult?.[0]?.benchmarkPredict !== undefined &&
  !analysisConfig.relative;

export const hasComparisonData = (analysisConfig: AnalysisConfig, scenariosResult: ScenarioAnalysisResult[]) =>
  !!analysisConfig?.subject?.hasSecondarySubject && scenariosResult?.[0]?.comparisonPredict !== undefined;

export const hasCategoryData = (analysisConfig: AnalysisConfig, scenariosResult: ScenarioAnalysisResult[]) =>
  !!analysisConfig?.subject?.categoryGroup && scenariosResult?.[0]?.comparisonPredict !== undefined;

export const convertScenarioDataToExcel = (
  analysisConfig: AnalysisConfig,
  scenarioAnalysisResult: ScenarioAnalysisResult[],
): ExcelCell[][] | undefined => {
  if (!analysisConfig) {
    return undefined;
  }
  const { relative } = analysisConfig;
  const relativeHeaderTitle = relative ? 'Estimated Relative Return' : 'Estimated Return';

  const mainName = analysisConfig?.subject?.name || '';
  const benchmarkName = `Benchmark: ${analysisConfig?.subject?.activeBenchmarkName || ''}`;
  const comparisonName = analysisConfig.subject
    ? getSecondaryDisplayLabel(
        analysisConfig.subject,
        `${analysisConfig.subject.secondaryPortfolio?.name} as of`,
        `: ${analysisConfig.subject?.secondaryPortfolio?.name}`,
      )
    : undefined;
  const categoryName = analysisConfig?.subject?.categoryGroup
    ? `Category: ${analysisConfig?.subject?.categoryGroup?.name}`
    : undefined;

  const hasBenchmark = hasBenchmarkData(analysisConfig, scenarioAnalysisResult);
  const hasComparison = hasComparisonData(analysisConfig, scenarioAnalysisResult);
  const hasCategory = hasCategoryData(analysisConfig, scenarioAnalysisResult);

  const header: ExcelCell[] = compact([
    'Input',
    'Shock Value',
    `${mainName} ${relativeHeaderTitle}`,
    `${mainName} (+/-)`,
    hasComparison ? `${comparisonName} ${relativeHeaderTitle}` : undefined,
    hasComparison ? `${comparisonName} (+/-)` : undefined,
    hasBenchmark ? `${benchmarkName} ${relativeHeaderTitle}` : undefined,
    hasBenchmark ? `${benchmarkName} (+/-)` : undefined,
    hasCategory ? `${categoryName} ${relativeHeaderTitle}` : undefined,
    hasCategory ? `${categoryName} (+/-)` : undefined,
  ]).map((h) => ({ value: h, bold: true }));
  const excelSheet = [header];
  scenarioAnalysisResult.forEach(({ fundName, shock, mainPredict, benchmarkPredict, comparisonPredict, units }) => {
    const excelRow = compact([
      { value: fundName },
      formatCell(shock, units),
      formatCell(mainPredict?.predicted),
      formatCell(mainPredict?.predictedError, 'PERCENTAGE'),
      hasBenchmark ? formatCell(benchmarkPredict?.predicted) : null,
      hasBenchmark ? formatCell(benchmarkPredict?.predictedError, 'PERCENTAGE') : null,
      hasComparison ? formatCell(comparisonPredict?.predicted) : null,
      hasComparison ? formatCell(comparisonPredict?.predictedError, 'PERCENTAGE') : null,
      hasCategory ? formatCell(comparisonPredict?.predicted) : null,
      hasCategory ? formatCell(comparisonPredict?.predictedError, 'PERCENTAGE') : null,
    ]);
    excelSheet.push(excelRow);
  });
  return excelSheet;
};

export const scenarioSortTableFunc =
  (accessor: string) => (rows: (ScenarioAnalysisResult & RowWithID)[], dir: SORTDIR) =>
    rows.sort((a, b) => {
      if (!a[accessor]) {
        return 0;
      }
      const comparison = a[accessor].predicted < b[accessor].predicted;
      if (dir === SORTDIR.DESC) {
        return comparison ? 1 : -1;
      }
      return comparison ? -1 : 1;
    });

const ReturnHeader = styled.span`
  margin-right: 2px;
`;

const InfoIcon = styled(Icon)`
  margin-left: 5px;
`;

const StyledTh = styled.th`
  border-top: 2px solid ${GetColor.Black};
  text-transform: uppercase;
  && {
    color: ${GetColor.Black};
    text-align: center;
    font-size: 11px;
    padding-top: 2px;
  }
`;

const Tr = styled.tr`
  border: none;
`;
