import React, { useCallback, useMemo } from 'react';
import styled, { withTheme } from 'styled-components';
import { compact, find } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { ColorUtils, GetColor, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { BasicTable, ColumnAlign, FactorDescriptionTooltip, FactorLensesContext, SORTDIR } from 'venn-components';
import { Dates, Numbers } from 'venn-utils';
import last from 'lodash/last';
import {
  type FactorWithNonSerializedReturns as FactorEntity,
  getCurrentForecast,
  type RecentFactorPerformance,
  type RecentPortfolioFactorHistory,
} from 'venn-api';
import FactorSummaryTooltip from '../../../home-page/src/components/FactorSummaryTooltip';
import { useQuery } from '@tanstack/react-query';

const emptyRenderer = () => <StyledCell className="empty-table-cell">--</StyledCell>;

const percentageRenderer = (value: number) => {
  const formattedValue = Numbers.safeFormatPercentage(value, 1);
  return <StyledCell>{formattedValue}</StyledCell>;
};

interface FactorSummaryTableProps {
  viewFactor: (factorId: number) => void;
  factorHistory: RecentPortfolioFactorHistory;
  factors?: FactorEntity[];
  theme: Theme;
}

const sevenDayReturnRenderer = ({ sevenDayReturn }: RecentFactorPerformance): JSX.Element =>
  percentageRenderer(sevenDayReturn);
const mtdReturnRenderer = ({ mtdReturn }: RecentFactorPerformance): JSX.Element => percentageRenderer(mtdReturn);
const qtdReturnRenderer = ({ qtdReturn }: RecentFactorPerformance): JSX.Element => percentageRenderer(qtdReturn);

const headerRenderer = (label: string) => <span style={{ marginLeft: 20 }}>{label}</span>;

type FactorSummaryTableDatatype = RecentFactorPerformance & { longTermForecast: number | undefined };

const longTermRenderer = ({ longTermForecast }: FactorSummaryTableDatatype) => {
  if (!longTermForecast) {
    return emptyRenderer();
  }

  return percentageRenderer(longTermForecast);
};

const cumulativeReturnRenderer = (item: FactorSummaryTableDatatype) => (
  <ReturnWrapper>
    <FactorSummaryTooltip item={item} />
  </ReturnWrapper>
);

const CURRENT_FACTOR_FORECAST_KEY = 'currentForecast';

const FactorSummaryTable: React.FC<FactorSummaryTableProps> = ({ viewFactor, factorHistory, factors = [], theme }) => {
  const { data: factorForecasts } = useQuery({
    queryKey: [CURRENT_FACTOR_FORECAST_KEY],
    staleTime: 10_000,
    queryFn: async () => {
      const response = await getCurrentForecast();
      const content = response.content;
      return content.factorForecasts;
    },
    suspense: false,
  });

  const factorNameRenderer = useCallback(
    ({ id }: FactorSummaryTableDatatype) => {
      const factor = find(factors, { id })!;
      if (!factor) {
        return emptyRenderer();
      }

      return (
        <Tooltip
          content={<FactorDescriptionTooltip name={factor.name} description={factor.description} />}
          position={TooltipPosition.Right}
          background={theme.Colors.White}
          maxWidth={320}
          showShadow
          largerPointer
        >
          <StyledName onClick={() => viewFactor(factor.id)} className="qa-factor-tooltip-name">
            {factor.name}
          </StyledName>
        </Tooltip>
      );
    },
    [factors, theme.Colors.White, viewFactor],
  );

  const tableColumns = useMemo((): BasicTableColumn<FactorSummaryTableDatatype>[] => {
    return compact([
      {
        label: 'Name',
        accessor: 'name',
        cellRenderer: factorNameRenderer,
        headerRenderer,
      },
      {
        label: 'Cumulative Return',
        accessor: 'cumulativeReturn',
        headerStyle: {
          textAlign: 'left' as const,
          paddingLeft: '40px',
          width: 255,
        },
        cellRenderer: cumulativeReturnRenderer,
      },
      {
        label: 'Last Week',
        accessor: 'sevenDayReturn',
        align: ColumnAlign.RIGHT,
        sortable: true,
        sorted: SORTDIR.DESC,
        headerStyle: {
          width: 120,
        },
        cellRenderer: sevenDayReturnRenderer,
      },
      {
        label: 'MTD',
        accessor: 'mtdReturn',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: {
          width: 120,
        },
        cellRenderer: mtdReturnRenderer,
      },
      {
        label: 'QTD',
        accessor: 'qtdReturn',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: {
          width: 120,
        },
        cellRenderer: qtdReturnRenderer,
      },
      {
        label: 'My Long-Term Forecast',
        accessor: 'longTerm',
        align: ColumnAlign.RIGHT,
        headerStyle: {
          width: 120,
        },
        cellRenderer: longTermRenderer,
      },
    ]);
  }, [factorNameRenderer]);

  const renderHead = useCallback(
    () => (
      <StyledTr>
        <th />
        <th />
        <StyledTh colSpan={3}>Return</StyledTh>
        <th />
        <th />
      </StyledTr>
    ),
    [],
  );

  const renderTail = useCallback(() => {
    const startDate = factorHistory?.factorPerformance[0]?.ytdCumulativeReturn?.[1]?.[0];
    const endDate = last(factorHistory?.factorPerformance[0]?.ytdCumulativeReturn)?.[0];
    const startText = startDate ? Dates.toDayMonthYear(startDate) : 'Start of year';
    const endText = endDate ? Dates.toDayMonthYear(endDate) : 'Today';
    return (
      <StyledTail className="bottom-legend">
        <td />
        <td colSpan={2}>
          <FlexTd>
            <span>{startText}</span>
            <SpaceSpan>{endText}</SpaceSpan>
          </FlexTd>
        </td>
      </StyledTail>
    );
  }, [factorHistory]);

  const data = useMemo(() => {
    return factorHistory.factorPerformance.map((factorPerformance) => {
      const id = factorPerformance.id;
      const factorForecast = find(factorForecasts, { id });
      return {
        ...factorPerformance,
        longTermForecast: factorForecast?.annualizedReturn,
      };
    });
  }, [factorHistory, factorForecasts]);

  return (
    <StyledTable
      key={1}
      data={data}
      columns={tableColumns}
      renderHead={renderHead}
      renderTail={renderTail}
      rowClassName={(data) =>
        !data.performanceAlert
          ? undefined
          : data.performanceAlert.positive
            ? 'row-alert-positive'
            : 'row-alert-negative'
      }
    />
  );
};

export default withTheme((props: FactorSummaryTableProps) => (
  <FactorLensesContext.Consumer>
    {({ primaryFactorLens }) => <FactorSummaryTable factors={primaryFactorLens?.factors || []} {...props} />}
  </FactorLensesContext.Consumer>
));

const StyledTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  margin: 5px 0 0;

  > tbody,
  > thead {
    > tr {
      > th,
      > td {
        border-bottom: 1px solid ${GetColor.PaleGrey};
      }
    }
  }

  > tbody {
    > tr.row-alert-negative {
      background-color: ${GetColor.DivergingColor.A1};
    }
    > tr.row-alert-positive {
      background-color: ${GetColor.DivergingColor.B1};
    }
    > tr {
      :hover {
        background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DataLineColor.PaleBlue, 0.2)};
      }
    }
  }

  > thead {
    border-bottom: 1px solid ${GetColor.Black};
    > tr {
      height: auto;
      :hover {
        background-color: transparent;
      }
      > th {
        color: ${GetColor.DarkGrey};
        border-bottom: 1px solid ${GetColor.Black};
      }
    }
  }
`;

const StyledCell = styled.span`
  color: ${GetColor.Black};
  &.empty-table-cell {
    display: table;
    margin: 0 auto;
  }
`;

const StyledName = styled(StyledCell)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
  width: auto;
  margin-left: 20px;
  @media (max-width: 1024px) {
    max-width: 120px;
  }
  @media (max-width: 900px) {
    max-width: 60px;
  }
`;

const StyledTr = styled.tr`
  && {
    border-bottom: none;
    th {
      border-bottom: none;
    }
  }
`;

const StyledTh = styled.th`
  border-top: 2px solid ${GetColor.Black};
  text-transform: uppercase;
  && {
    color: ${GetColor.Black};
    text-align: center;
    font-size: 11px;
    padding-top: 2px;
  }
`;

const FlexTd = styled.div`
  display: flex;
`;

const SpaceSpan = styled.span`
  padding-left: 170px;
`;

const StyledTail = styled.tr`
  border: none;
  font-weight: bold;
  && {
    height: 15px;
    :hover {
      background-color: transparent !important;
    }
  }
  &.bottom-legend {
    > td {
      border: none;
      color: ${GetColor.DarkGrey};
      font-size: 12px;
      padding-top: 3px;
    }
  }
`;

const ReturnWrapper = styled.div`
  padding-left: 35px;
`;
