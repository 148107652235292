import type { RangeDebugGroup } from 'venn-components';

export const getMockedRangeResponse = (frequency = 'DAILY', portfolioRange = 783734399999) =>
  ({
    primary: {
      response: {
        constrainingEndDate: true,
        constrainingPrimaryEnd: false,
        constrainingPrimaryFrequency: false,
        constrainingPrimaryStart: false,
        constrainingStartDate: false,
        insufficientRange: true,
        maxEndDate: 1582934399999,
        maxFrequency: frequency,
        maxStartDate: 794102399999,
        portfolioRange: {
          investments: [
            {
              end: 1583193599999,
              frequency,
              id: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
              name: 'Managed Futures Investment Category (MF)',
              numReturns: 6610,
              start: portfolioRange,
            },
          ],
          factors: [
            {
              end: 1582934399999,
              frequency,
              id: '123',
              name: 'Equity',
              numReturns: 6523,
              start: 794102399999,
            },
          ],
          secondary: {
            end: 1583020799999,
            frequency,
            id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
            name: 'Other Investment Category (HF)',
            numReturns: 421,
            start: 478483199999,
            subjectError: {
              text: 'cant analyze benchmark',
              code: 10047,
              type: 'ERROR',
            },
          },
          name: 'A',
          baseline: {
            end: 1583020799999,
            frequency,
            id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
            name: 'Other Investment Category (HF)',
            numReturns: 421,
            start: 478483199999,
          },
          benchmark: {
            end: 1583020799999,
            frequency,
            id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
            name: 'Other Investment Category (HF)',
            numReturns: 421,
            start: 478483199999,
          },
        },
      },
      subjectError: {
        text: 'something went wrong',
        code: 10047,
        type: 'ERROR',
      },
    },

    maxEndTime: 1582934399999,
    maxFrequency: frequency,
    maxStartTime: Math.max(794102399999, portfolioRange),
  }) as RangeDebugGroup;
