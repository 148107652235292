import React from 'react';
import styled from 'styled-components';
import { mergeRefs } from 'venn-utils';

export const OverflowContainer = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
`;

export const OverflowText = {
  SingleLine: styled.span`
    white-space: nowrap;
    display: inline;

    @media print {
      white-space: inherit;
    }
  `,
  MultiLine: styled.span`
    display: inline-block;
  `,
};

type OverflowFunction = (overflowing: boolean) => React.ReactNode;

export interface EllipsisProps extends Omit<React.BaseHTMLAttributes<HTMLSpanElement>, 'children'> {
  children: React.ReactNode | OverflowFunction;
  before?: React.ReactNode | OverflowFunction;
  after?: React.ReactNode | OverflowFunction;
  singleLine?: boolean;
  onOverflowChange?(overflowing: boolean): void;
  containerRef?: React.MutableRefObject<HTMLSpanElement | null>;
}
export interface EllipsisState {
  overflowing: boolean;
}
export class Ellipsis extends React.PureComponent<EllipsisProps, EllipsisState> {
  private overflowContainer = React.createRef<HTMLSpanElement>();

  private overflowingText = React.createRef<HTMLSpanElement>();

  constructor(props: EllipsisProps) {
    super(props);
    this.state = {
      overflowing: false,
    };
  }

  componentDidMount() {
    this.measureSize();
  }

  componentDidUpdate() {
    this.measureSize();
  }

  isOverflowingNow = (containerElement: HTMLElement, textElement: HTMLElement) => {
    const { singleLine } = this.props;
    const containerBox = containerElement.getBoundingClientRect();
    const textBox = textElement.getBoundingClientRect();

    // There's a small height difference between the container and the text if it's single line
    // because the former's display is inline-block while the latter's is inline
    const heightBuffer = singleLine ? 1 : 0;
    const overflowingNow =
      Math.round(containerBox.width) < Math.round(textBox.width) ||
      Math.round(containerBox.height) + heightBuffer < Math.round(textBox.height);

    if (this.state.overflowing !== overflowingNow) {
      this.setState({ overflowing: overflowingNow }, () => {
        if (this.props.onOverflowChange) {
          this.props.onOverflowChange(this.state.overflowing);
        }
      });
    }
  };

  measureSize() {
    if (this.overflowContainer.current && this.overflowingText.current) {
      this.isOverflowingNow(this.overflowContainer.current, this.overflowingText.current);
    }
  }

  renderTextNode() {
    const { singleLine, children } = this.props;
    const { overflowing } = this.state;

    const TextNode = singleLine ? OverflowText.SingleLine : OverflowText.MultiLine;

    const textNodeChildren = typeof children === 'function' ? children(overflowing) : children;

    return (
      <TextNode className="qa-ellipsis-text" ref={this.overflowingText}>
        {textNodeChildren}
      </TextNode>
    );
  }

  render() {
    const { before, after, singleLine, onOverflowChange, ...domNodeProps } = this.props;
    const { overflowing } = this.state;

    return (
      <>
        {before ? (typeof before === 'function' ? before(overflowing) : before) : null}
        <OverflowContainer ref={mergeRefs(this.overflowContainer, this.props.containerRef)} {...domNodeProps}>
          {this.renderTextNode()}
        </OverflowContainer>
        {after ? (typeof after === 'function' ? after(overflowing) : after) : null}
      </>
    );
  }
}
