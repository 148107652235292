import React, { useState, useMemo, useCallback } from 'react';
import type { ScheduledLog } from 'venn-api';
import { getExportsLogsByViewIds, getDocumentContent } from 'venn-api';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Expander, DateFilter, GetColor, Icon, CategorizedMenu, Loading, ButtonIcon, Tooltip } from 'venn-ui-kit';
import {
  useVersions,
  toGeneralMenuItems,
  groupByMonths,
  FooterContainer,
  LoadingContainer,
  VersionItem,
  ITEM_CLASSNAME,
} from '../version-item';
import { buttonize, logExceptionIntoSentry, analyticsService } from 'venn-utils';
import { saveAs } from 'file-saver';

interface ScheduledHistoryProps {
  savedId?: string;
  scheduledDisabledMessage?: string;
}

const HISTORY_MENU_CLASSNAME = 'history-category';
const HISTORY_DOWNLOAD_CLASSNAME = 'download-button';
const HISTORY_ITEMS_HEIGHT = 300;

const getViewScheduledExportHistory = (savedId: string) => getExportsLogsByViewIds([savedId]);

const ScheduledHistory = ({ savedId, scheduledDisabledMessage }: ScheduledHistoryProps) => {
  const [expandHistory, setExpandHistory] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const {
    versions,
    versionsLoading,
    versionsExpanded,
    setVersionsExpanded,
    month,
    year,
    filterVersions,
    filteredVersionsCount,
    totalVersionsCount,
  } = useVersions(getViewScheduledExportHistory, undefined, savedId);
  const hasAccess = !scheduledDisabledMessage;

  const categories = useMemo(
    () => groupByMonths<ScheduledLog>(hasAccess ? versions : [], 0, toGeneralMenuItems),
    [versions, hasAccess],
  );

  const onDownload = useCallback(async (item: ScheduledLog) => {
    try {
      setDownloading(true);
      if (saveAs) {
        saveAs((await getDocumentContent(item.documentId, 1)).content, item.exportFileName);
      }
    } catch (e) {
      logExceptionIntoSentry(e);
    }
    setDownloading(false);
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Download XLSX',
      locationOnPage: 'Scheduled exports history in comparison',
      purpose: 'Download scheduled exports history',
      type: 'button',
      filled: false,
    });
  }, []);

  return (
    <>
      <HistoryHeader
        {...buttonize(() => {
          setExpandHistory(!expandHistory);
        })}
      >
        <Icon type="history" />
        <HeaderText>History</HeaderText>
        <Icon type={expandHistory ? 'caret-up' : 'caret-down'} />
      </HistoryHeader>
      {expandHistory && (
        <>
          <ScheduledHistoryWrapper>
            <Tooltip isHidden={hasAccess} content={scheduledDisabledMessage} block>
              <DateFilter
                placeholder="Filter past exports..."
                month={month}
                year={year}
                disabled={totalVersionsCount === 0 || versionsLoading || !hasAccess}
                onFilter={filterVersions}
              />
            </Tooltip>
            <CategorizedMenu
              categories={categories}
              hideSeparator
              className={HISTORY_MENU_CLASSNAME}
              renderItem={(item: DropMenuItem<ScheduledLog>) => (
                <VersionItem
                  key={item.value.documentId}
                  item={item}
                  isSelected={false}
                  renderRightChild={(item: ScheduledLog) => (
                    <ButtonIcon
                      iconType="download"
                      disabled={downloading}
                      onClick={() => onDownload(item)}
                      tooltip="Download XLSX"
                      className={HISTORY_DOWNLOAD_CLASSNAME}
                    />
                  )}
                />
              )}
              height={HISTORY_ITEMS_HEIGHT}
            />
          </ScheduledHistoryWrapper>
          {versionsLoading ? (
            <LoadingContainer>
              <Loading title="Loading history..." />
            </LoadingContainer>
          ) : filteredVersionsCount > versions.length || versionsExpanded ? (
            <FooterContainer>
              <Expander expanded={versionsExpanded} toggleExpanded={setVersionsExpanded} />
            </FooterContainer>
          ) : undefined}
        </>
      )}
    </>
  );
};

export default ScheduledHistory;

const HistoryHeader = styled.div`
  border: 1px solid ${GetColor.Grey};
  padding: 10px;
  cursor: pointer;
`;

const HeaderText = styled.span`
  margin: 0 10px;
  font-weight: bold;
`;

const ScheduledHistoryWrapper = styled.div`
  padding: 20px 30px 20px 20px;
  .${HISTORY_MENU_CLASSNAME} {
    box-shadow: none;
    div {
      padding-left: 0;
    }
    .${ITEM_CLASSNAME} {
      height: 40px;
      padding-left: 10px;
    }
    .${HISTORY_DOWNLOAD_CLASSNAME} {
      div {
        padding-left: 8px;
      }
    }
  }
`;
