import { KeyCodes } from 'venn-ui-kit';
import type React from 'react';
import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { formatAllocation } from 'venn-utils';
import useInputStateHelper from '../input/useInputStateHelper';

export type ParsedValue =
  | {
      isValid: true;
      value: number;
    }
  | { isValid: false };
export type NumericTextInputStateManagerProps = SharedProps & {
  allowNegative: boolean;
  isPercentage: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onMouseDown?: () => void;
  onCommitInput: (parsedValue: ParsedValue) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  maxDecimalPlaces: number;
};

type SharedProps = {
  isLocked: boolean;
};

export type InjectedProps = SharedProps & {
  disabled: boolean;
  ref: React.RefObject<HTMLInputElement>;
  isFocused: boolean;
  isError: boolean;
  value: string;
  onFocus: () => void;
  onMouseDown: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
};

const ALPHABETS_REGEX = /^[a-zA-Z]$/;
const BLOCKED_SYMBOLS_REGEX = /^[\+\*\/]$/;
const MINUS_SYMBOL_REGEX = /^\-$/;

const useKeyBlockingNumericInputStateManager = ({
  inputRef,
  value,
  allowNegative,
  isLocked,
  onChange,
  onCommitInput,
  isPercentage,
  maxDecimalPlaces,
  onMouseDown,
  onFocus,
}: NumericTextInputStateManagerProps) => {
  const [focused, setFocused] = useState(false);

  const [caretPosition, setCaretPosition] = useState(0);

  const defaultInput = useRef<HTMLInputElement>(null);
  const input = inputRef ?? defaultInput;
  const { onFocus: onFocusWrapper } = useInputStateHelper({
    setFocused,
    input,
    value,
    onFocusInner: onFocus,
  });

  const onChangeWrapper = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, selectionEnd } = event.target;
      requestAnimationFrame(() => {
        setCaretPosition(Number(selectionEnd));
        onChange(value);
      });
    },
    [onChange],
  );

  const isKeyBlocked = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;
      return (
        key.match(ALPHABETS_REGEX) ||
        key.match(BLOCKED_SYMBOLS_REGEX) ||
        (!allowNegative && key.match(MINUS_SYMBOL_REGEX))
      );
    },
    [allowNegative],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (isKeyBlocked(event) && !(event.ctrlKey || event.metaKey || event.altKey)) {
        event.preventDefault();
      }
      if (event.keyCode === KeyCodes.Enter) {
        input.current?.blur();
      }
    },
    [input, isKeyBlocked],
  );

  const onMouseDownWrapper = useCallback(() => onMouseDown?.(), [onMouseDown]);

  const onBlur = useCallback(() => {
    const isValueValidNumber = !isNil(value) && !isEmpty(value) && Number.isFinite(Number(value));
    requestAnimationFrame(() => {
      if (isValueValidNumber) {
        onCommitInput({
          isValid: true,
          value: Number.parseFloat(value),
        });
      } else {
        onCommitInput({
          isValid: false,
        });
      }
      setFocused(false);
    });
  }, [onCommitInput, value]);

  const textValue = useMemo((): string => {
    if (isNil(value) || value === '') {
      return '';
    }
    if (focused) {
      return value;
    }
    return formatAllocation(Number(value), false, isPercentage, undefined, false, maxDecimalPlaces);
  }, [focused, isPercentage, maxDecimalPlaces, value]);

  useEffect(() => {
    // When deleting last digit of value we get 0 from props at first,
    // this makes caret appear after it not before
    const index = value === '0' ? 1 : caretPosition;
    const target = input.current;

    if (target) {
      target.selectionStart = index;
      target.selectionEnd = index;
    }
  }, [caretPosition, input, value]);

  return {
    ref: input,
    isFocused: focused,
    isError: Number.isNaN(Number(value)),
    value: textValue,
    onFocus: onFocusWrapper,
    onMouseDown: onMouseDownWrapper,
    onBlur,
    onKeyDown,
    onChange: onChangeWrapper,
    isLocked,
    disabled: isLocked,
  };
};

export default useKeyBlockingNumericInputStateManager;
