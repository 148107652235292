import React, { useCallback, useContext } from 'react';
import type { ContentRect } from 'react-measure';
import Measure from 'react-measure';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { BlockIdContext, StudioSidePanelContext, BlockWidthContextProvider, useAppPrintMode } from 'venn-components';
import {
  blockDisplayHeader,
  blockSettings,
  COLUMN_GAP,
  DEFAULT_MARGIN,
  studioBlockCustomWidth,
  studioBlockHeight,
  VIRTUALIZATION_SCROLL_BAR_PX,
} from 'venn-state';
import { buttonize } from 'venn-utils';
import { BuilderBlockWrapper } from '../../shared';
import { useVirtualization } from '../../../../logic/useVirtualization';

export const BLOCK_CONTAINER_CLASS = 'venn-block-container';
/**
 * You can set either the container width for the block or the intended width of the block, but not both.
 * TODO(collin.irwin): Receiving Width and ContainerWidth both is pretty confusing. Can we refactor this to just getting a single width,
 * or maybe some other clearer architecture?
 */
type BlockWidths =
  | {
      width: number;
      containerWidth?: undefined;
    }
  | {
      width: undefined;
      containerWidth: number;
    };
type BlockProps = {
  id: string;
  externalBlockWrapper?: boolean;
  blockRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  blockWidths: BlockWidths;
};
export const RootFoundations = {
  Root: ({ id, externalBlockWrapper, blockWidths, children, blockRef }: BlockProps) => {
    const { width, containerWidth } = blockWidths;
    const blockSetting = useRecoilValue(blockSettings(id));
    const { inPrintModeOrReportLab } = useAppPrintMode();
    const { onSelectBlock } = useContext(StudioSidePanelContext);
    const header = useRecoilValue(blockDisplayHeader(id));
    const setBlockHeight = useSetRecoilState(studioBlockHeight(id));
    const blockRelativeWidth = useRecoilValue(studioBlockCustomWidth(id));
    const hasVirtualization = useVirtualization();

    const onResize = useCallback(
      (contentRect: ContentRect) =>
        // TODO: the fact we use Math.max here means that a block never shrinks in size in printing, which is very brittle and error prone.
        // It has caused bugs where blocks have way too much space between them during printing. E.g., if a loading state is larger than the block itself,
        // this code will remember the size of the loading state forever.
        // However, without this behavior, blocks can infinite loop between small and large sizes when being laid out in the print layout.
        // It is not clear yet how to redesign  the print layout functionality to resolve these issues.
        setBlockHeight((currVal) => Math.ceil(Math.max(contentRect?.bounds?.height ?? 0, currVal))),
      [setBlockHeight],
    );

    const fullWidth = blockSetting.customBlockType === 'PAGE_BREAK';
    const getStudioBlockWidth = (pageWidth: number) => {
      const numberOfColumnGaps = 1 / blockRelativeWidth - 1;
      const virtuosoScrollBarWidth =
        hasVirtualization && numberOfColumnGaps <= 0.0001 ? VIRTUALIZATION_SCROLL_BAR_PX : 0;
      const printMarginPx = DEFAULT_MARGIN * (inPrintModeOrReportLab ? 2 : 0);

      const availablePageWidth = pageWidth - printMarginPx - COLUMN_GAP * numberOfColumnGaps - virtuosoScrollBarWidth;

      return availablePageWidth * blockRelativeWidth;
    };

    const blockWidthPx = width !== undefined ? getStudioBlockWidth(width) : containerWidth;

    const blockWidthStyle = containerWidth || fullWidth ? '100%' : `${blockWidthPx}px`;

    const Wrapper = externalBlockWrapper ? UnstyledBlockWrapper : BuilderBlockWrapper;

    return (
      <React.Suspense fallback={null}>
        <BlockIdContext.Provider value={id}>
          <BlockWidthContextProvider blockWidthPx={blockWidthPx}>
            <Measure bounds key={id} onResize={onResize}>
              {({ measureRef }) => {
                return (
                  <div
                    className={BLOCK_CONTAINER_CLASS}
                    ref={measureRef}
                    id={id}
                    key={id}
                    data-testid={`qa-${lowerCaseHeaderName(header)}`}
                    style={{
                      width: blockWidthStyle,
                    }}
                  >
                    <div
                      {...buttonize((e) => {
                        e.stopPropagation();
                        onSelectBlock(id, { scrollIntoView: false });
                      })}
                      ref={blockRef}
                    >
                      <Wrapper id={id}>{children}</Wrapper>
                    </div>

                    <BlockSpacer />
                  </div>
                );
              }}
            </Measure>
          </BlockWidthContextProvider>
        </BlockIdContext.Provider>
      </React.Suspense>
    );
  },
};

const BlockSpacer = styled.div`
  height: ${DEFAULT_MARGIN}px;
`;

const UnstyledBlockWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const lowerCaseHeaderName = (header: string) => header.toLowerCase().replace(/ /g, '-');
