import { isNil } from 'lodash';
import { useContext } from 'react';
import { UserContext } from '../../../../contexts';
import { getContextNameById } from '../../../../utils';

const ReviewPortfolioViewSubtitle = () => {
  const userContext = useContext(UserContext);

  if (isNil(userContext.currentContext)) {
    return null;
  }

  const contextName = getContextNameById(
    userContext.profileSettings?.availableContexts ?? [],
    userContext.currentContext,
  );

  return `Portfolio data to be uploaded to ${contextName}`;
};

export default ReviewPortfolioViewSubtitle;
