import { Markdown } from '../../../markdown/Markdown';
import { getTextThemeProvider } from 'venn-ui-kit';
import React from 'react';

export const ComparisonHelpLink = () => (
  <Markdown
    children={`Need some help? View a [help center article](${
      getTextThemeProvider().HelpCenterLinks.PORTFOLIO_COMPARISON_HELP_HREF
    }).`}
  />
);
