import React, { useContext, useState } from 'react';
import type { MultiPortfolioParseResult, MultiPortfolioPersistResult } from 'venn-api';
import { assertExhaustive, assertNotNil, useHasFF } from 'venn-utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import {
  IPortfolioUploadConfirmationView,
  IReviewHistoricalPortfolioView,
  IReviewProFormaPortfolioView,
  IUploadPortfolioView,
} from '../constants';
import type { MultiPortfolioUploaderViewId } from '../types';
import MultiPortfolioConfirmationStep from './MultiPortfolioConfirmationStep';
import { MultiProFormaReviewStep } from './MultiProFormaReviewStep';
import MultiPortfolioUploadStep from './MultiPortfolioUploadStep';
import { isHistoricalPortfolio } from '../utils';
import { MultiHistoricalReviewStep } from './MultiHistoricalReviewStep';

type MultiPortfolioUploaderProps = Readonly<{
  viewId: MultiPortfolioUploaderViewId;
}>;

const useMultiPortfolioUploaderState = () => {
  const hasHistoricalPortfolioFeatureFlag = useHasFF('historical_portfolios_ff');
  const { setView } = useContext(UniversalUploaderContext);
  const [parsedData, setParsedData] = useState<MultiPortfolioParseResult>();
  const [persistResult, setPersistResult] = useState<MultiPortfolioPersistResult>();

  const goToReviewStep = (parsedData: MultiPortfolioParseResult) => {
    setParsedData(parsedData);
    if (hasHistoricalPortfolioFeatureFlag && isHistoricalPortfolio(parsedData)) {
      setView(IReviewHistoricalPortfolioView);
    } else {
      setView(IReviewProFormaPortfolioView);
    }
  };

  const goBackToUploadStep = () => {
    setParsedData(undefined);
    setView(IUploadPortfolioView);
  };

  const goToUploadConfirmation = (persistResult: MultiPortfolioPersistResult) => {
    setPersistResult(persistResult);
    setView(IPortfolioUploadConfirmationView);
  };

  return {
    parsedData,
    persistResult,
    goToReviewStep,
    goBackToUploadStep,
    goToUploadConfirmation,
  };
};

const MultiPortfolioUploader = ({ viewId }: MultiPortfolioUploaderProps) => {
  const { parsedData, persistResult, goToReviewStep, goBackToUploadStep, goToUploadConfirmation } =
    useMultiPortfolioUploaderState();

  switch (viewId) {
    case 'UPLOAD_PORTFOLIO':
      return <MultiPortfolioUploadStep goToReviewStep={goToReviewStep} />;
    case 'REVIEW_PRO_FORMA_PORTFOLIO':
      return (
        <MultiProFormaReviewStep
          parsedData={assertNotNil(parsedData)}
          goBackToUploadStep={goBackToUploadStep}
          goToUploadConfirmation={goToUploadConfirmation}
        />
      );
    case 'REVIEW_HISTORICAL_PORTFOLIO':
      return (
        <MultiHistoricalReviewStep
          parsedData={assertNotNil(parsedData)}
          goBackToUploadStep={goBackToUploadStep}
          goToUploadConfirmation={goToUploadConfirmation}
        />
      );
    case 'PORTFOLIO_UPLOAD_CONFIRMATION':
      return <MultiPortfolioConfirmationStep persistResult={assertNotNil(persistResult)} />;
    default:
      throw assertExhaustive(viewId);
  }
};

export default MultiPortfolioUploader;
