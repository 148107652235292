import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ExternalActivityListener } from '../external-activity-listener';
import { StudioButton } from './StudioButton';
import { GetColor } from '../../style/color';
import { ZIndex } from '../../zIndexValues';
import { ColorUtils } from '../../style/colorUtils';
import type { ButtonSize } from './StudioButton';
import { Icon } from '../icon/Icon';

export interface StudioMenuOption<T> {
  value: T;
  label?: string;
  disabled?: boolean;
}

export interface StudioMenuProps<T> {
  options: StudioMenuOption<T>[];
  renderItem?: (t: StudioMenuOption<T>) => JSX.Element;
  onSelect: (t: T) => void;
  size: ButtonSize;
  testId?: string;
}

const OptionButton = styled.button`
  padding: 0 10px;
  text-align: start;
  line-height: 36px;
  :hover {
    background: ${GetColor.PaleGrey};
    color: unset;
  }
  color: ${GetColor.Black};
`;

const MenuContent = styled.div`
  position: absolute;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.Modal};
  display: flex;
  flex-direction: column;
  right: 0;
  padding: 7px 0;
  border: 1px solid ${GetColor.Grey};
  box-shadow: 0 6px 6px 0 ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.2)};
`;

export const StudioMenu = <T,>({ options, renderItem, size, onSelect, testId = 'studio-menu' }: StudioMenuProps<T>) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open, setOpen]);
  const onClick = useCallback(
    (value: T) => {
      onSelect(value);
      setOpen(false);
    },
    [onSelect, setOpen],
  );
  return (
    <ExternalActivityListener onExternalActivity={() => setOpen(false)} testId={testId}>
      <StudioButton size={size} type="button" onClick={toggleOpen} aria-haspopup aria-expanded={open}>
        <Icon type="ellipsis-h" />
      </StudioButton>
      {open && (
        <MenuContent role="menu" data-testid="studio-menu-content">
          {options.map((option) => (
            <OptionButton
              disabled={option.disabled}
              type="button"
              key={option.label}
              role="menuitem"
              onClick={() => onClick(option.value)}
            >
              {/*
               * button does not accept a string in the children prop
               * so we need to wrap the string in a fragment
               */}
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              {renderItem ? renderItem(option) : <>{option.label ?? option.value}</>}
            </OptionButton>
          ))}
        </MenuContent>
      )}
    </ExternalActivityListener>
  );
};
