import React, { useState, useCallback, useEffect } from 'react';
import { Icon, EllipsisTooltipSpan, Tooltip, TooltipPosition, ColorUtils, GetColor } from 'venn-ui-kit';
import type { BasicTableColumn } from '../../basictable/BasicTable';
import BasicTable from '../../basictable/BasicTable';
import { sortTableFunc } from '../../basictable/utils';
import styled, { css } from 'styled-components';
import type { ScheduledLog } from 'venn-api';
import { getDocumentContent, getExportsLogsByViewIds } from 'venn-api';
import { Dates, logExceptionIntoSentry, analyticsService, formatAnalysisPeriod } from 'venn-utils';
import { saveAs } from 'file-saver';

const Columns = (
  isDownloading: boolean,
  onDownload: (scheduledLog: ScheduledLog) => void,
): BasicTableColumn<ScheduledLog>[] => [
  {
    label: 'Export',
    accessor: 'name',
    sortable: true,
    headerStyle: { width: '30%' },
    sortTableFunc: sortTableFunc((analysisSnapshot) => analysisSnapshot.exportFileName),
    cellRenderer: getTableCellRender('exportFileName', 300),
  },
  {
    label: 'Benchmark',
    accessor: 'benchmark',
    sortable: true,
    sortTableFunc: sortTableFunc((analysisSnapshot) => analysisSnapshot.benchmarkName),
    cellRenderer: getTableCellRender('benchmarkName', 160),
  },
  {
    label: 'Analysis Period',
    accessor: 'period',
    sortable: true,
    cellStyle: { minWidth: 160 },
    cellRenderer: ({ startDate, endDate }: ScheduledLog) => formatAnalysisPeriod(startDate, endDate),
  },
  {
    label: 'Export Date',
    accessor: 'created',
    sortable: true,
    headerStyle: { width: '10%' },
    cellRenderer: ({ created }: ScheduledLog) => <span>{Dates.toDayMonthYear(created)}</span>,
  },
  {
    label: '',
    headerStyle: { width: 60 },
    cellRenderer: (scheduledLog: ScheduledLog) => (
      <Tooltip position={TooltipPosition.Top} maxWidth={110} content="Download XLSX">
        <StyledIcon
          type="download"
          onClick={() => !isDownloading && onDownload(scheduledLog)}
          disabled={isDownloading}
        />
      </Tooltip>
    ),
  },
];

interface ScheduledExportTableProps {
  viewId: string;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const getTableCellRender = (key: string, maxWidth?: number) => (scheduledLog: ScheduledLog) =>
  scheduledLog[key] ? (
    <CellWrapper>
      <EllipsisTooltipSpan maxWidth={maxWidth} content={scheduledLog[key]} block usePortal>
        {scheduledLog[key]}
      </EllipsisTooltipSpan>
    </CellWrapper>
  ) : (
    '--'
  );

const ScheduledExportTable = ({ viewId }: ScheduledExportTableProps) => {
  const [scheduledLogs, setScheduledLogs] = useState<ScheduledLog[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  /** Disable download buttons when somthing is downloading */
  const [isDownloading, setDownloading] = useState(false);

  const onDownload = useCallback(async (item: ScheduledLog) => {
    try {
      setDownloading(true);
      if (saveAs) {
        saveAs((await getDocumentContent(item.documentId, 1)).content, item.exportFileName);
      }
    } catch (e) {
      logExceptionIntoSentry(e);
    }
    setDownloading(false);
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Download XLSX',
      locationOnPage: 'Scheduled exports table',
      purpose: 'Download scheduled exports history',
      type: 'button',
      filled: false,
    });
  }, []);

  useEffect(() => {
    const fetchScheduledLogs = async () => {
      try {
        const { content: logs } = await getExportsLogsByViewIds([viewId]);
        setScheduledLogs(logs);
      } catch (e) {
        logExceptionIntoSentry(e);
      }
      setIsLoading(false);
    };
    fetchScheduledLogs();
  }, [viewId]);

  return (
    <Wrapper>
      <BasicTable
        columns={Columns(isDownloading, onDownload)}
        data={scheduledLogs}
        className="qa-snapshot-list-table"
        loading={isLoading}
      />
      {!scheduledLogs.length && !isLoading && <EmptyText>No results found.</EmptyText>}
    </Wrapper>
  );
};

export default ScheduledExportTable;

const Wrapper = styled.div`
  margin: 20px 0;
  table-layout: fixed;
  tbody tr {
    > td:first-child,
    > th:first-child {
      padding-left: 10px;
    }
    line-height: 19px;
    span {
      line-height: 19px;
    }
    &:hover {
      background: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1)};
      .button-icon i {
        color: ${GetColor.Primary.Dark};
      }
    }
  }
`;

const CellWrapper = styled.div`
  max-width: calc(100% - 20px);
`;

const EmptyText = styled.span`
  font-size: 12px;
  padding-left: 10px;
`;

const StyledIcon = styled(Icon)<{ disabled: boolean }>`
  padding: 0 5px;
  color: ${GetColor.Primary.Dark};
  ${(props) =>
    props.disabled &&
    css`
      &&&& {
        color: ${GetColor.Grey};
        :hover {
          cursor: not-allowed;
        }
      }
    `}
`;
