import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { type HTMLProps } from '../factory';

import { useAppPrintMode } from '../../../../print';

import { type CustomFont } from 'venn-state';
import { REPORT_LAB_FONT_BODY } from 'venn-ui-kit';

export type BlockMetadataElement = React.ElementRef<'div'>;
export interface BlockMetadataProps extends HTMLProps<'div'> {
  font?: CustomFont;
  children: React.ReactNode;
}

export const BlockMetadata = forwardRef<BlockMetadataElement, BlockMetadataProps>((props, ref) => {
  const { font, children } = props;
  const { inPrintModeOrReportLab } = useAppPrintMode();

  return (
    <Metadata
      ref={ref}
      inPrintMode={inPrintModeOrReportLab}
      fontSize={font?.fontSizePt}
      className="qa-metadata-section"
    >
      {children}
    </Metadata>
  );
});

BlockMetadata.displayName = 'BlockMetadata';

const Metadata = styled.div<{
  inPrintMode: boolean;
  fontSize: number | undefined;
}>`
  font-size: ${({ inPrintMode, fontSize }) =>
    fontSize ? `${fontSize}pt` : inPrintMode ? REPORT_LAB_FONT_BODY : 'calc(1rem * 7 / 6)'};
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 0;
`;
