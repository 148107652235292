import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import type {
  MultiOptimizationConfiguration,
  MultiOptimizationResponse,
  Portfolio,
  PortfolioPolicy,
  PortfolioSummary,
} from 'venn-api';
import { runAdHocMultiOptimization } from 'venn-api';
import { forceZeroResidualForecastChangedAtom } from 'venn-state';
import { logExceptionIntoSentry, type ObjectiveType } from 'venn-utils';
import { analyticsService, useApi } from 'venn-utils';

const useOptimization = (
  portfolio: Portfolio | undefined,
  optimizationPolicy: PortfolioPolicy | undefined,
  objective: ObjectiveType | undefined,
  objectiveValue: number | undefined,
  summary: PortfolioSummary | undefined,
) => {
  const [response, setResponse] = useState<MultiOptimizationResponse | undefined>();
  const [loading, setLoading] = useState(false);
  const optimizeApiRef = useRef(useApi(runAdHocMultiOptimization));
  const forceZeroResidualForecastChanged = useRecoilValue(forceZeroResidualForecastChangedAtom);

  useEffect(() => {
    const optimize = async () => {
      if (isNil(portfolio) || isNil(optimizationPolicy) || isNil(objective) || isNil(objectiveValue)) {
        setResponse(undefined);
        return;
      }

      setLoading(true);
      try {
        const { content } = await optimizeApiRef.current(
          getRequest(portfolio, optimizationPolicy, objective, objectiveValue),
        );
        const emptyResult =
          isNil(content.optimizedPortfolio.portfolio) &&
          content.alternatePortfolios.every((item) => isNil(item.portfolio));
        setResponse(emptyResult ? undefined : content);
        if (emptyResult) {
          analyticsService.portfolioLabNoSolutionsFound();
        }
        setLoading(false);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          setResponse(undefined);
          setLoading(false);
          logExceptionIntoSentry(e);
        }
      }
    };

    optimize();
  }, [
    portfolio,
    optimizationPolicy,
    objective,
    objectiveValue,
    summary,
    /**
     * This is not used directly inside useEffect, but is needed to rerun optimization
     * if "Force Zero Residual Forecast" has been toggled. Do not remove this dependency
     */
    forceZeroResidualForecastChanged,
  ]); // Summary changes when default forecast changes

  return {
    loadingOptimization: loading,
    optimizedResult: response?.optimizedPortfolio,
    alternateResults: response?.alternatePortfolios,
    averageInvestmentBuys: response?.averageInvestmentBuys,
    averageInvestmentSells: response?.averageInvestmentSells,
  };
};

export default useOptimization;

const getRequest = (
  portfolio: Portfolio,
  optimizationPolicy: PortfolioPolicy,
  objective: ObjectiveType,
  objectiveValue: number,
): Partial<MultiOptimizationConfiguration> => {
  return {
    constraints: optimizationPolicy.constraints,
    portfolio,
    optimizationTarget:
      objective === 'maximizeReturns'
        ? 'MAXIMIZE_RETURN'
        : objective === 'targetReturn'
          ? 'MINIMIZE_VOLATILITY'
          : 'MAXIMIZE_SHARPE',
    maxVolatility: objective === 'maximizeReturns' ? objectiveValue / 100 : undefined,
    minReturn: objective === 'maximizeReturns' ? undefined : objectiveValue / 100,
    relaxationsPercent: 0.1,
    disableResidualForecasts: false,
  };
};
