import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { getAppTitle, GetColor, getTextThemeProvider } from 'venn-ui-kit';
import { Copyright, Markdown } from 'venn-components';
import { browserTest } from 'venn-utils';

const isBrowserSupported = browserTest();

const AuthenticationFooterAttribution: FC<React.PropsWithChildren<unknown>> = () => {
  const DISCLAIMER = `${getAppTitle()} is not intended for individual use. Two Sigma Investor Solutions, LP
(“TSIS”) operates ${getAppTitle()}. TSIS is affiliated with entities that offer investment management and
other services. TSIS’s business is separate and the services it offers are different from the
businesses and services of its affiliates. TSIS makes no representations or warranties that any
graph, chart, formula or other device offered on ${getAppTitle()} can in and of itself be used to
determine which securities to buy or sell, or when to buy or sell them. No material on or
linked to by this website is or should be construed as an offer to sell, or a solicitation
of an offer to buy, any security or other instrument. Please see TSIS’s [Form ADV](/api/legal/doc/adv) for important disclosures, including disclosures about material conflicts
of interest and limitations of ${getAppTitle()}. Material on or linked to by this website is not to be
reproduced or distributed to persons other than the recipient and its professional advisors.

${getAppTitle().toUpperCase()}${getTextThemeProvider().tsParam.toUpperCase()} IS FOR INSTITUTIONAL/WHOLESALE/PROFESSIONAL CLIENTS AND OTHER QUALIFIED CLIENTS IN CERTAIN JURISDICTIONS ONLY – NOT FOR RETAIL USE OR DISTRIBUTION.   FOR UK SUBSCRIBERS: ${getAppTitle().toUpperCase()} IS INTENDED ONLY FOR INVESTMENT PROFESSIONALS WITHIN THE MEANING OF ARTICLE 19 OF THE FINANCIAL SERVICES AND MARKETS ACT 2000 (FINANCIAL PROMOTION) ORDER 2005 (“FPO”) OR HIGH NET WORTH ENTITIES OR OTHER ENTITIES FALLING WITHIN ARTICLE 49 OF THE FPO.  FOR HK SUBSCRIBERS: Certain ${getAppTitle()} Pro features (including optimization) are not generally available in Hong Kong. TSIS is based in the United States. TSIS does not conduct any regulated activity (as defined in the Securities and Futures Ordinance (Cap. 571, Laws of Hong Kong)) in Hong Kong, and TSIS is not licensed by any regulatory authority in Hong Kong.  FOR ERISA, PUBLIC PENSION AND OTHER RETIREMENT ASSET SUBSCRIBERS: ${getAppTitle()} is not for use as a primary basis for investment decisions. TSIS does not hold itself out as a fiduciary (or as otherwise owing any other special duties) to any pension or retirement plan under pension, retirement or similar laws or regulations.
`;
  return (
    <StyledAttribution>
      <StyledCopyright lineBreak />
      <StyledNotice>
        <Markdown children={DISCLAIMER} />
      </StyledNotice>
    </StyledAttribution>
  );
};

export default AuthenticationFooterAttribution;

const StyledAttribution = styled('div')`
  color: ${GetColor.White};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: ${isBrowserSupported ? '0' : '60px'};

  a {
    font-weight: bold;
    color: ${GetColor.Primary.Main};

    &:hover,
    &:focus {
      border-bottom: 1px solid ${GetColor.Primary.Main};
    }
  }

  img {
    display: inline-block;
    height: 8px;
    margin: 0 2px 0 0;
  }
`;

const paragraphMargin = css`
  margin: 0 0 10px;
`;

const StyledNotice = styled('div')`
  ${paragraphMargin};
`;

const StyledCopyright = styled(Copyright)`
  ${paragraphMargin};
`;
