import type { ColumnErrors, ColumnMapping, FileUploadMetadata, Fund, Portfolio } from 'venn-api';
import { DataUploaderMode, DO_NOT_MAP_ID } from '../../types';
import { isColumnDeleted } from '../mapping/helpers';
import compact from 'lodash/compact';
import { isEmpty, isNil } from 'lodash';
import flatMap from 'lodash/flatMap';

export interface ErrorViewModel {
  seriesId: string;
  // note that if it's a missing value, rowIndex is negative
  // and could change as the number of missing values changes
  rowIndex: number;
  date: string;
  value: string;
  isValid: boolean;
  errors: string[];
}

const areErrorsEqual = (err1: ErrorViewModel, err2: ErrorViewModel): boolean => {
  return (
    err1.seriesId === err2.seriesId &&
    // only use rowIndex to check equality if it's a real index
    (err1.rowIndex < 0 ? err1.date === err2.date : err1.rowIndex === err2.rowIndex)
  );
};

export function validateCells(newErrors: ErrorViewModel[], stateErrors: ErrorViewModel[]): ErrorViewModel[] {
  // update validity of stateErrors using the new errors
  const prevErrors = stateErrors.map((stateError) => {
    const updatedError = newErrors.find((newError) => areErrorsEqual(newError, stateError));
    if (updatedError) {
      // the error type could have changed so update it
      return { ...stateError, errors: updatedError.errors, isValid: false };
    }
    // If we can't find a stateError in the newErrors array, that means it was corrected
    return { ...stateError, isValid: true };
  });

  // there might be new errors that weren't there before so add them to the previous list of errors
  const actualNewErrors = newErrors.filter(
    (error) => !stateErrors.find((stateError) => areErrorsEqual(stateError, error)),
  );
  // maintain the order of the previous list of errors by simply appending the new errors
  return prevErrors.concat(actualNewErrors);
}

export function convertColumnErrorsToViewModels(columns: ColumnErrors[]): ErrorViewModel[] {
  return columns.reduce<ErrorViewModel[]>(
    (m, column) =>
      m.concat(
        column.errors.reduce<ErrorViewModel[]>((memo, e) => {
          const cellErrors = compact(
            e.cells.map((c) =>
              c.errors
                ? {
                    seriesId: c.seriesId,
                    rowIndex: c.index,
                    date: c.date,
                    value: c.value,
                    errors: c.errors,
                    isValid: false,
                  }
                : null,
            ),
          );
          return memo.concat(cellErrors);
        }, []),
      ),
    [],
  );
}

export function updateErrorValue(errors: ErrorViewModel[], error: ErrorViewModel, value: string): ErrorViewModel[] {
  const errorIndex = errors.indexOf(error);
  return [...errors.slice(0, errorIndex), { ...error, value }, ...errors.slice(errorIndex + 1)];
}

export function countData(columns: ColumnMapping[], mode: DataUploaderMode, isNew: boolean) {
  let filteredData;
  if (mode === DataUploaderMode.Returns) {
    filteredData = isNew ? columns.filter((c) => !c.fundId) : columns.filter((c) => !!c.fundId);
  } else {
    filteredData = isNew
      ? columns.filter((c) => !!c.fundId && c.typeId !== DO_NOT_MAP_ID)
      : columns.filter((c) => !c.fundId || c.typeId === DO_NOT_MAP_ID);
  }
  return filteredData.length;
}

/**
 * Return whether there are uncorrected errors for the given seriesId in the given list of errors.
 * @param errors if not provided, returns false
 * @param seriesId if not provided, all errors will be considered
 */
export function hasUncorrectedErrors(errors?: ErrorViewModel[], seriesId?: string): boolean {
  if (!errors) return false;
  if (!seriesId) return errors.some((e) => !e.isValid);
  return errors.some((e) => !e.isValid && e.seriesId === seriesId);
}

export const getColumnsToUpload = (columns: ColumnMapping[], metadata: FileUploadMetadata, errors: ErrorViewModel[]) =>
  columns.filter(
    (c) => c.newDataCount !== 0 && !isColumnDeleted(c, metadata) && !hasUncorrectedErrors(errors, c.seriesId),
  );

/** Detect whether there are any funds inside this portfolio that need to be mapped */
export const hasUnmatchedFunds = (portfolio: Portfolio): boolean => {
  if (isEmpty(portfolio.children)) {
    if (portfolio.fund) {
      // fund node
      return isNil(portfolio.fund.id);
    }
    return false; // a strategy without any children
  }
  for (const child of portfolio.children) {
    if (hasUnmatchedFunds(child)) {
      return true;
    }
  }
  return false;
};

/** Detect whether this portfolio node is a fund which needs to be mapped */
export const nodeNeedsMapping = (row: Portfolio | undefined) => {
  if (row?.fund) {
    // is this a fund?
    if (row?.fund.id) {
      // if it has an id, it's already mapped
      return {
        isFund: true,
        needsMapping: false,
      };
    } // if it doesn't have an id, it needs mapping
    return {
      isFund: true,
      needsMapping: true,
    };
  } // strategy nodes don't need mapping
  return {
    isFund: false,
    needsMapping: false,
  };
};

/** Detect whether this historical portfolio node has any duplicate funds */
export const historicalNodeIsDuplicated = (
  root: Portfolio | undefined,
  path: number[] | undefined,
  row: Portfolio | undefined,
  date: number | undefined,
) => {
  if (!root || !path || !row || !date || !row.fund?.id) {
    return {
      isFund: false,
      duplicateInvestment: false,
    };
  }

  // level 0 is the root, so we start at level 1
  let strategy = root;
  for (let level = 1; level < path.length - 1; level++) {
    strategy = strategy.children[path[level]];
  }

  return {
    isFund: true,
    duplicateInvestment: hasDuplicatedHistoricalInvestments(strategy, row, date),
  };
};

export const historicalPortfolioHasDuplicatedInvestments = (portfolio: Portfolio | undefined): boolean =>
  !!portfolio &&
  portfolio?.children?.some(
    (c) =>
      c.closingAllocationsTs?.some((dt_alloc) => hasDuplicatedHistoricalInvestments(portfolio, c, dt_alloc[0])) ||
      historicalPortfolioHasDuplicatedInvestments(c),
  );

export const hasDuplicatedHistoricalInvestments = (strategy, node, date): boolean =>
  !!strategy &&
  strategy.children.filter(
    (p) => p.fund?.id === node.fund?.id && p.closingAllocationsTs?.some((x) => x[0] === date && !!x[1]),
  ).length > 1;

/**
 * Recursively updates the fund of a portfolio node at a specified path.
 *
 * @param node - The root portfolio node.
 * @param path - An array of indices representing the path to the target node.
 * @param fund - The new fund to be assigned to the target node.
 * @returns The updated portfolio with the fund remapped at the specified path.
 *
 * @example
 * const portfolio = {
 *   name: 'Root Portfolio',
 *   fund: null,
 *   children: [
 *     {
 *       name: 'Child Portfolio',
 *       fund: null,
 *       children: [
 *         { name: 'Grandchild Portfolio', fund: { id: '123' }, children: [] },
 *         { name: 'Old Fund', fund: { id: 'old-fund-id' }, children: [] }
 *       ]
 *     }
 *   ]
 * };
 * const path = [0, 1];
 * const newFund = { id: 'new-fund-id', name: 'New Fund' };
 * const updatedPortfolio = remapInvestment(portfolio, path, newFund);
 * `updatedPortfolio.children[0].children[1]` will have the new fund
 */
export const remapInvestment = (node: Portfolio, path: number[], fund: Fund): Portfolio => {
  if (path.length < 1) {
    return {
      ...node,
      name: fund.name,
      fund,
    };
  }
  const [head, ...tail] = path;
  return {
    ...node,
    children: (node.children ?? []).map((child, index) => {
      if (index === head) {
        return remapInvestment(child, tail, fund);
      }
      return child;
    }),
  };
};

/** Find all funds ids within the portfolio */
export const getAllFundIds = (portfolio: Portfolio): string[] => {
  if (portfolio.fund) {
    return isNil(portfolio.fund.id) ? [] : [portfolio.fund.id];
  }

  return flatMap((portfolio.children ?? []).map((child) => getAllFundIds(child)));
};
