import styled from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';
import React from 'react';

type NameWithMappingErrorRendererProps = {
  name?: string;
  needsMapping: boolean;
  duplicateInvestment: boolean;
};

export const NameWithMappingErrorRenderer = ({
  name,
  needsMapping,
  duplicateInvestment,
}: NameWithMappingErrorRendererProps) => {
  return (
    <Wrapper isCompact>
      <span>
        {needsMapping && <StyledIcon type="triangle-exclamation" />}
        {name}
      </span>
      {needsMapping && <ErrorSpan>Could not be mapped to an investment in the Data Library.</ErrorSpan>}
      {duplicateInvestment && (
        <ErrorSpan>
          Duplicate investments are not allowed in the same strategy. Click {'\u22EF'} to exclude or merge.
        </ErrorSpan>
      )}
    </Wrapper>
  );
};

const ErrorSpan = styled.span`
  color: ${GetColor.Error};
`;

const StyledIcon = styled(Icon)`
  color: ${GetColor.Error};
  margin-right: 4px;
  font-size: 14px;
  font-weight: 900;
`;

const Wrapper = styled.div<{ isCompact: boolean }>`
  height: 100%;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) => props.isCompact && 'line-height: 16px;'};
`;
