import React, { Component } from 'react';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';
import {
  addEvent,
  type AlignValue,
  type AxisLabelsFormatterContextObject,
  type AxisTypeValue,
  type GradientColorObject,
  type Options,
  type PatternObject,
  type PlotLineOrBand,
  type PlotSeriesZonesOptions,
  type Point,
  type SeriesLineOptions,
  type TooltipFormatterContextObject,
} from 'highcharts';
import { HighchartsUtils, Legend } from 'venn-components';
import type { Theme, Typography, VennColors } from 'venn-ui-kit';
import { ColorUtils, GetColor, HighchartZIndex } from 'venn-ui-kit';
import type { EntityPerformance, FactorInputMapping, NestedFactorInputCollection } from 'venn-api';
import buildLegend from './build-legend';
import type { NotablePeriod } from '../../../shared/FactorEducationUtils';
import { isEqual, sortBy } from 'lodash';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { VennHighchart } from '../../../../../../../venn-components/src/highchart/Highchart';

export interface PerformanceChartProps {
  data: Partial<EntityPerformance>[];
  notablePeriods?: NotablePeriod[];
  inputMappings: FactorInputMapping[];
  selectedNotablePeriod?: number;
  onSelectNotablePeriod?: (notablePeriodIdx: number, trigger: string) => void;
  theme: Theme;
}

export class PerformanceChart extends Component<PerformanceChartProps> {
  render() {
    const {
      data,
      notablePeriods,
      selectedNotablePeriod,
      onSelectNotablePeriod,
      inputMappings,
      theme: { Typography, Colors },
    } = this.props;
    const legend = buildLegend(data, Colors);
    const seriesData = legend.flatMap((legendItem) =>
      legendItem.lines.map((line) => ({
        type: 'line' as const,
        name: line.name,
        data: (line.cumulativeReturns ?? []) as SeriesLineOptions['data'],
        zIndex: HighchartZIndex.Serie.Front,
        color: legendItem.color,
      })),
    );

    const updateSelectedNotablePeriod = (idx: number) => onSelectNotablePeriod && onSelectNotablePeriod(idx, 'chart');

    const config: Options = {
      credits: {
        enabled: false,
      },
      chart: {
        type: 'line',
        marginTop: 0,
        marginBottom: 30,
        marginRight: 0,
        marginLeft: 0,
        events: {
          load(this: Highcharts.Chart) {
            // Add a custom "rect" that provides the extra shading,
            // since the shading needs to be in a different hue than the plot band over chart.
            createXAxisPlotBands.bind(this)();
            // "Extend" plot band to cover the x axis label area too
            updatePlotBandSizes.bind(this)();

            // Dynamically update zones to colour the parts of series with input mappings
            if (inputMappings.length > 0) {
              addEvent((this as AnyDuringEslintMigration).renderTo, 'mousemove', () => {
                const { hoverPoint } = this as AnyDuringEslintMigration;
                if (hoverPoint) {
                  const zones = getZones(hoverPoint, inputMappings);
                  if (!isEqual(zones, hoverPoint.series.zones)) {
                    hoverPoint.series.update({ zones, zoneAxis: 'x' });
                  }
                }
              });
              addEvent((this as AnyDuringEslintMigration).renderTo, 'mouseleave', () => {
                const { series } = this;
                if (series) {
                  series.forEach((serie: AnyDuringEslintMigration) => {
                    if (serie.zones && serie.zones.length) {
                      serie.update({ zones: [] });
                    }
                  });
                }
              });
            }
          },
          redraw(this: Highcharts.Chart) {
            // Update plot bands's sizes & the shading on the x axis when the chart is resized
            removeXAxisPlotBands.bind(this)();
            createXAxisPlotBands.bind(this)();
            updatePlotBandSizes.bind(this)();
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      xAxis: [
        {
          type: 'datetime' as AxisTypeValue,
          gridLineWidth: 1,
          minorTickLength: 0,
          tickLength: 0,
          minPadding: 0.1,
          maxPadding: 0,
          startOnTick: true,
          endOnTick: false,
          showFirstLabel: false,
          labels: {
            y: 20,
            useHTML: true,
            formatter: HighchartsUtils.endOfTheYearFormatter,
            style: {
              fontFamily: Typography.fontFamily,
              fontSize: '14px',
            },
          },
          plotBands: notablePeriods
            ? notablePeriods.map((period: NotablePeriod, idx: number) => ({
                from: period.start,
                to: period.end,
                color: getColor(idx, Colors, selectedNotablePeriod, legend && legend[0] ? legend[0].color : undefined),
                zIndex: HighchartZIndex.PlotBand.AboveChart,
                events: {
                  setColor(this: PlotLineOrBand, color: string, isSelected: boolean) {
                    this.svgElem.attr({ fill: color });
                    isSelected && updateSelectedNotablePeriod(idx);
                  },
                  click(this: PlotLineOrBand) {
                    // Tricks to fix VENN-10622 that changes context
                    setTimeout(() => updateSelectedNotablePeriod(idx), 0);
                  },
                  mouseover(this: PlotLineOrBand) {
                    // Shrink & offset the plot band to make space for the chart border
                    // This is done on mouseover so that normally plot bands extend from top to bottom
                    updatePlotBandSize(this, -1, 1);
                  },
                  mouseout(this: PlotLineOrBand) {
                    updatePlotBandSize(this, 1, -1);
                  },
                },
              }))
            : undefined,
        },
      ],
      yAxis: [
        {
          title: { text: null },
          gridLineWidth: 1,
          startOnTick: true,
          endOnTick: true,
          showFirstLabel: true,
          showLastLabel: false,
          minPadding: 0,
          labels: {
            align: 'right' as AlignValue,
            formatter(this: AxisLabelsFormatterContextObject) {
              return `${(this.value * 100).toFixed(1)}%`;
            },
            style: {
              fontFamily: Typography.fontFamily,
              fontSize: '14px',
            },
            x: 60,
          },
        },
      ],
      legend: {
        enabled: false,
      },
      tooltip: {
        crosshairs: true,
        backgroundColor: Colors.TransparentBlack,
        style: {
          color: Colors.White,
        },
        formatter(this: TooltipFormatterContextObject) {
          const { color, series, x, y } = this;
          return getTooltipFormatter(
            color,
            series.name,
            x,
            y,
            Typography,
            inputMappings.find(({ id }) => id === series.name),
          );
        },
        useHTML: true,
      },
      series: seriesData,
    };

    return (
      <PerformanceChartContainer>
        <StyledLegend
          className="qa-chart-legend"
          series={legend.map(({ color, name, italic }) => ({
            color,
            name,
            italic,
          }))}
        />
        <VennHighchart className="qa-performance-chart" options={config} />
      </PerformanceChartContainer>
    );
  }
}

function getZones(hoverPoint: Point, inputMappings: FactorInputMapping[]) {
  const hoverPointMapping = inputMappings.find(({ id }) => id === hoverPoint.series.name);
  if (hoverPointMapping === undefined || hoverPointMapping.inputs === undefined) {
    return [];
  }

  const sortedMapping = sortBy(hoverPointMapping.inputs, ['start']);

  const { x } = hoverPoint;

  // TODO: when upgrading highcharts use: SeriesZonesOptionsObject,
  const zones: PlotSeriesZonesOptions[] = [];

  for (const { start, end } of sortedMapping) {
    if (start <= x && end !== null && x <= end) {
      zones.push({
        value: end,
        color: hoverPoint.series.color,
      });
    } else if (start <= x && end === null) {
      zones.push({
        color: hoverPoint.series.color,
      });
    } else if (end !== null) {
      zones.push({
        value: end,
        // TODO: gradients or patterns will throw an error, but we don't use gradients or patterns for anything yet
        color: ColorUtils.hex2rgba(hoverPoint.series.color as string, 0.25),
      });
    } else {
      zones.push({
        // TODO: gradients or patterns will throw an error, but we don't use gradients or patterns for anything yet
        color: ColorUtils.hex2rgba(hoverPoint.series.color as string, 0.25),
      });
    }
  }

  return zones;
}

function getTooltipFormatter(
  color: string | GradientColorObject | PatternObject,
  name: string,
  x: number,
  y: number,
  typography: Typography,
  inputMapping?: FactorInputMapping,
): string {
  const sortedMapping = inputMapping ? sortBy(inputMapping.inputs, ['start']) : [];
  const inputs: NestedFactorInputCollection | undefined = sortedMapping.find(
    ({ start, end }) => start <= x && (end === null || x <= end),
  );
  const inputBreakdown: string[] = inputs
    ? inputs.nestedInputs.map(
        (input) => `
    <br/><div class="highcharts-tooltip-circle" style="margin-left: 12px;"></div>
    <i>(${(input.contribution * 100).toFixed(0)}%)</i> ${input.name}
  `,
      )
    : [];
  return `
    <div style="font-family:${typography.fontFamily}">
      <b>${moment.utc(x).format('MMM D, YYYY')}</b><br/>
      <div
        class="highcharts-tooltip-circle"
        style="background:${color};"></div>
      ${name}: <b>${(y * 100).toFixed(2)}%</b>
      ${''.concat(...inputBreakdown)}
    </div>
  `;
}

function getColor(
  currentIdx: number,
  colors: VennColors,
  selectedIdx: number | undefined,
  themeColor: string | undefined,
): string {
  const color = themeColor || colors.Black;
  if (selectedIdx === undefined) {
    return ColorUtils.hex2rgba(color, 0.1);
  }
  return currentIdx === selectedIdx ? ColorUtils.hex2rgba(color, 0.2) : ColorUtils.hex2rgba(colors.Grey, 0.4);
}

function removeXAxisPlotBands(this: Highcharts.Chart) {
  if (!Array.isArray(this.xAxisPlotBands)) {
    return;
  }
  for (const xAxisPlotBand of this.xAxisPlotBands) {
    xAxisPlotBand.safeRemoveChild(xAxisPlotBand.element);
  }
}

// "Extends" the plot band shading on the x axis label area for each plot band
// What it actually does is create a custom rect that provides this shading and adds it to the chart
function createXAxisPlotBands(this: Highcharts.Chart) {
  const plotBands = this.xAxis[0].plotLinesAndBands ?? [];
  this.xAxisPlotBands = [];
  for (const plotBand of plotBands) {
    const rect = getXAxisPlotBandRect(plotBand);
    const xAxisPlotBand = this.renderer
      .rect(rect.x, rect.y, rect.width, rect.height)
      .attr({ fill: (plotBand as AnyDuringEslintMigration).options.color })
      .add();
    this.xAxisPlotBands.push(xAxisPlotBand);
  }
}

function getXAxisPlotBandRect(plotBand: PlotLineOrBand) {
  const { d } = plotBand.svgElem;
  const dArray = (d as AnyDuringEslintMigration).split(' ');
  return {
    x: dArray[1],
    y: dArray[5],
    width: dArray[6] - dArray[4],
    height: 30,
  };
}

function updatePlotBandSizes(this: Highcharts.Chart) {
  const plotbands = this.xAxis[0].plotLinesAndBands;
  plotbands?.forEach((plotBand) => {
    updatePlotBandSize(plotBand, 30, 0);
  });
}

function updatePlotBandSize(plotBand: PlotLineOrBand, addSize: number, offset: number) {
  const dArray = (plotBand.svgElem.d as AnyDuringEslintMigration).split(' ');
  // Move the plot band down by {offset} by accessing the top-left and top-right y values
  dArray[2] = Number(dArray[2]) + offset;
  dArray[9] = Number(dArray[9]) + offset;
  // Extend the plot band down by {addSize - offset} by accessing the bottom-left and bottom-right y values
  dArray[5] = Number(dArray[5]) + addSize - offset;
  dArray[7] = Number(dArray[7]) + addSize - offset;
  // Update the underlying svg element
  plotBand.svgElem.d = dArray.join(' ');
  (plotBand.svgElem.element.attributes as AnyDuringEslintMigration).d.nodeValue = dArray.join(' ');
}

const StyledLegend = styled(Legend)`
  padding: 0 20px;
  height: 50px;
  border-bottom: 1px solid ${GetColor.Grey};
  border-top: 1px solid ${GetColor.Grey};

  > div {
    margin: 0 20px 0 0;
  }
`;

const PerformanceChartContainer = styled.div`
  transform: translate3d(0, 0, 0);

  .highcharts-container:hover {
    .highcharts-line-series {
      &:not(.highcharts-series-hover) {
        path {
          stroke: ${GetColor.Grey};
          stroke-width: 1;
        }
      }
    }
  }

  .highcharts-plot-band:hover {
    cursor: pointer;
    stroke: ${GetColor.Primary.Main};
    stroke-width: 3px;
  }

  .highcharts-xaxis-labels span {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .highcharts-tooltip-circle {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid white;
  }
`;

export default withTheme(PerformanceChart);
