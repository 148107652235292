import React, { type FC, useContext } from 'react';
import moment from 'moment';
import type { DrawdownRangeAnalysis } from 'venn-api';
import HighchartsUtils from '../../../../../utils/highcharts';
import type { Theme, Typography, VennColors } from 'venn-ui-kit';
import { ColorUtils, HighchartZIndex } from 'venn-ui-kit';
import type { AnalysisSubject, AnyDuringEslintMigration } from 'venn-utils';
import { Dates, getSecondaryDisplayLabel, Numbers } from 'venn-utils';
import type { AxisLabelsFormatterContextObject, Options, SeriesArearangeOptions, SeriesLineOptions } from 'highcharts';
import { ThemeContext } from 'styled-components';
import { getLineColors } from '../../../internal/utils';
import { VennHighchart } from '../../../../../highchart/Highchart';

export interface DrawdownChartProps {
  subject: AnalysisSubject;
  data: DrawdownRangeAnalysis;
  start: number;
  end: number;
  print?: boolean;
}

function getConfig(
  subject: AnalysisSubject,
  portfolioData: [number, number][],
  secondaryPortfolioData: [number, number][] | undefined,
  benchmarkData: [number, number][] | undefined,
  categoryData: [number, number][] | undefined,
  errorRangeData: [number, number, number][] | undefined,
  errorDrawdown: [number, number][],
  start: number,
  end: number,
  theme: Theme,
  print?: boolean,
): Options {
  let colorIdx = 0;
  const { Typography, Colors } = theme;
  const lineColors = getLineColors(theme);
  const secondaryPortfolioLabel = secondaryPortfolioData
    ? getSecondaryDisplayLabel(subject, undefined, 'Portfolio')
    : undefined;
  const series: (SeriesLineOptions | SeriesArearangeOptions)[] = [
    {
      type: 'line',
      name: 'Portfolio',
      data: portfolioData,
      color: lineColors[colorIdx++],
      lineWidth: 1.5,
      zIndex: HighchartZIndex.Serie.Front,
    },
  ];
  if (secondaryPortfolioData) {
    series.push({
      type: 'line',
      name: secondaryPortfolioLabel,
      data: secondaryPortfolioData,
      color: lineColors[colorIdx++],
      lineWidth: 1.5,
    });
  }
  if (benchmarkData) {
    series.push({
      type: 'line',
      name: 'Benchmark',
      data: benchmarkData,
      color: lineColors[colorIdx++],
      lineWidth: 1.5,
    });
  }
  if (categoryData) {
    series.push({
      type: 'line',
      name: `Category (${subject?.categoryGroup?.name})`,
      data: categoryData,
      color: lineColors[colorIdx++],
      lineWidth: 1.5,
    });
  }

  series.splice(1, 0, {
    name: 'error',
    type: 'arearange',
    linkedTo: ':previous',
    lineWidth: 0.5,
    lineColor: Colors.DataLineColor.Gold,
    fillColor: ColorUtils.hex2rgba(Colors.DataLineColor.Gold, 0.1),
    zIndex: HighchartZIndex.Serie.Base,
    color: Colors.DataLineColor.Gold,
    data: errorRangeData,
  });

  return {
    chart: {
      type: 'line',
      marginTop: 0,
      marginBottom: 60,
      marginLeft: 0,
      marginRight: 1,
      height: print ? 250 : undefined,
      panning: false,
      events: {
        load(this: Highcharts.Chart) {
          createXAxisPlotBands.bind(this)()(Colors, Typography);
          updatePlotLineSizes.bind(this)();
        },
        redraw(this: Highcharts.Chart) {
          removeXAxisPlotBands.bind(this)();
          createXAxisPlotBands.bind(this)()(Colors, Typography);
          updatePlotLineSizes.bind(this)();
        },
      },
    },
    title: {
      text: '',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      arearange: {
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: [
      {
        type: 'datetime',
        gridLineWidth: 1,
        gridLineDashStyle: 'ShortDash',
        minorTickLength: 0,
        tickLength: 0,
        maxPadding: 0,
        minPadding: 0.1,
        max: end,
        startOnTick: true,
        lineColor: 'transparent',
        ordinal: false,
        plotLines: [getPlotLine(start, Colors), getPlotLine(end, Colors)],
        labels: {
          y: 20,
          style: {
            fontFamily: Typography.fontFamily,
            fontSize: '14px',
            whiteSpace: 'nowrap',
          },
          formatter(this: AxisLabelsFormatterContextObject) {
            if (this.value < start) {
              return '';
            }
            return HighchartsUtils.endOfTheYearFormatter.call(this);
          },
        },
      },
    ],
    yAxis: [
      {
        title: { text: null },
        gridLineWidth: 1,
        minorTickLength: 10,
        startOnTick: true,
        endOnTick: true,
        showFirstLabel: true,
        showLastLabel: false,
        minPadding: 0,
        max: 0,
        labels: {
          align: 'right',
          formatter(this: AxisLabelsFormatterContextObject) {
            return `${this.value.toFixed(1)}%`;
          },
          style: {
            fontFamily: Typography.fontFamily,
            fontSize: '14px',
          },
          x: 50,
          y: 4,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      split: false,
      shared: true,
      animation: false,
      backgroundColor: Colors.TransparentBlack,
      style: {
        color: Colors.White,
      },
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        const { points, x } = this;

        const current = points?.find((point) => point?.series?.name === 'Portfolio');
        const benchmark = points?.find((point) => point?.point?.series?.name === 'Benchmark');
        const baseline = points?.find((point) => point?.point?.series?.name === secondaryPortfolioLabel);
        const category = points?.find(
          (point) => point?.point?.series?.name === `Category (${subject?.categoryGroup?.name})`,
        );
        // Error drawdown will return real error range without cap it when current value + lowerErrorDrawdown < -100%
        const rawErrorRangeData = errorDrawdown && errorDrawdown.find((point) => point[0] === x);
        const labels: string[] = [];
        const values: string[] = [];
        let errorRange = '';

        const getMarker = (point: Highcharts.TooltipFormatterContextObject) =>
          `<span style="color:${point.color}">\u25CF</span>`;

        if (current) {
          labels.push(`<span>${getMarker(current)} ${subject.name}:</span>`);
          values.push(`<b>${current.y.toFixed(2)}%</b>`);
        }

        if (baseline && secondaryPortfolioData) {
          const secondaryPortfolioName = subject?.secondaryPortfolio?.name;
          labels.push(
            `<span>${getMarker(baseline)} ${secondaryPortfolioName} (${getSecondaryDisplayLabel(
              subject,
              'as of',
            )}):</span>`,
          );
          values.push(`<b>${baseline.y.toFixed(2)}%</b>`);
        }
        const compare = subject.activeBenchmark;
        if (compare && benchmark) {
          labels.push(`<span>${getMarker(benchmark)} ${compare.name}:</span>`);
          values.push(`<b>${benchmark.y.toFixed(2)}%</b>`);
        }
        if (category && categoryData) {
          labels.push(`<span>${getMarker(category)} Category (${subject?.categoryGroup?.name}):</span>`);
          values.push(`<b>${category.y.toFixed(2)}%</b>`);
        }

        if (current && rawErrorRangeData) {
          errorRange = `
            <div class="drawdown-tooltip-values-right">
              ${`+/-${Numbers.safeFormatPercentage(rawErrorRangeData[1])}`}
            </div>
          `;
        }

        return `
          <div>
            <b>${moment.utc(x).format('MMM D, YYYY')}</b><br/>
            <div class="drawdown-tooltip-values">
              <div class="drawdown-tooltip-values-left">
                ${labels.join('')}
              </div>
              <div class="drawdown-tooltip-values-middle">
                ${values.join('')}
              </div>
              ${errorRange}
            </div>
          </div>
        `;
      },
    },
    series,
  };
}

export const DrawdownChart: FC<React.PropsWithChildren<DrawdownChartProps>> = ({
  subject,
  data,
  start,
  end,
  print,
}) => {
  const theme = useContext(ThemeContext);

  if (!subject || !data) {
    return null;
  }

  const {
    portfolioDrawdown,
    secondaryPortfolioDrawdown,
    benchmarkDrawdown,
    lowerErrorDrawdown,
    upperErrorDrawdown,
    maxDrawdown,
    maxDrawdownDate,
    maxBenchmarkDrawdown,
    maxBenchmarkDrawdownDate,
    maxSecondaryDrawdown,
    maxSecondaryDrawdownDate,
    errorDrawdown,
  } = data;

  let portfolioData = portfolioDrawdown?.map((val) => [val[0], val[1] * 100] as [number, number]);
  let secondaryPortfolioData =
    subject.type === 'portfolio' && secondaryPortfolioDrawdown
      ? secondaryPortfolioDrawdown.map((val) => [val[0], val[1] * 100] as [number, number])
      : undefined;
  let benchmarkData = benchmarkDrawdown
    ? benchmarkDrawdown.map((val) => [val[0], val[1] * 100] as [number, number])
    : undefined;
  let categoryData =
    subject.type === 'investment' && secondaryPortfolioDrawdown
      ? secondaryPortfolioDrawdown.map((val) => [val[0], val[1] * 100] as [number, number])
      : undefined;
  let errorRangeData =
    lowerErrorDrawdown && upperErrorDrawdown
      ? lowerErrorDrawdown.map(
          (val, i) => [val[0], val[1] * 100, upperErrorDrawdown[i][1] * 100] as [number, number, number],
        )
      : undefined;

  if (maxDrawdown < -1) {
    // remove all data points after max drawdown date
    const idx = portfolioData.findIndex((p) => p[0] > maxDrawdownDate);
    portfolioData = portfolioData.slice(0, idx);
    errorRangeData = errorRangeData?.slice(0, idx);
  }

  if (maxBenchmarkDrawdown && benchmarkData && maxBenchmarkDrawdown <= -1) {
    const idx = benchmarkData.findIndex((p) => p[0] >= (maxBenchmarkDrawdownDate ?? 0));
    benchmarkData = benchmarkData.slice(0, idx);
  }

  if (subject.type === 'portfolio' && maxSecondaryDrawdown <= -1 && secondaryPortfolioData) {
    const idx = secondaryPortfolioData.findIndex((p) => p[0] >= maxSecondaryDrawdownDate);
    secondaryPortfolioData = secondaryPortfolioData.slice(0, idx);
  }

  if (subject.type === 'investment' && maxSecondaryDrawdown <= -1 && categoryData) {
    const idx = categoryData.findIndex((p) => p[0] >= maxSecondaryDrawdownDate);
    categoryData = categoryData.slice(0, idx);
  }

  const config = getConfig(
    subject,
    portfolioData,
    secondaryPortfolioData,
    benchmarkData,
    categoryData,
    errorRangeData,
    errorDrawdown as [number, number][],
    start,
    end,
    theme,
    print,
  );
  return <VennHighchart className="qa-chart-container" options={config} />;
};

export default DrawdownChart;

function getPlotLine(timestamp: number, colors: VennColors) {
  return {
    color: colors.Primary.Main,
    value: timestamp,
    width: 2,
    zIndex: HighchartZIndex.PlotBand.AboveSeries,
  };
}

function removeXAxisPlotBands(this: Highcharts.Chart) {
  if (!Array.isArray(this.xAxisPlotBands)) {
    return;
  }
  for (const xAxisPlotBand of this.xAxisPlotBands) {
    xAxisPlotBand.safeRemoveChild(xAxisPlotBand.element);
  }
}

function createXAxisPlotBands(this: Highcharts.Chart) {
  return (colors: VennColors, typography: Typography) => {
    const plotLines = this.xAxis[0].plotLinesAndBands;
    if (plotLines.length !== 2) {
      return;
    }
    this.xAxisPlotBands = [];

    // Blue highlight over the x-axis
    const rect = getXAxisPlotBandRect(plotLines[0], plotLines[1]);
    const xAxisPlotBand = this.renderer
      .rect(rect.x, rect.y, rect.width, rect.height)
      .attr({ fill: ColorUtils.hex2rgba(colors.Primary.Main, 0.2) })
      .add();
    this.xAxisPlotBands.push(xAxisPlotBand);

    // Space below the x-axis, for the labels
    const blackFill = this.renderer
      .rect(rect.x - 1, rect.y + 30, rect.width + 2, rect.height)
      .attr({ fill: colors.Black })
      .add();
    this.xAxisPlotBands.push(blackFill);

    // Start label background + text
    const startLabelBackground = this.renderer
      .rect(rect.x - 1, rect.y + 30, 100, 30)
      .attr({ fill: colors.Primary.Dark })
      .add();
    this.xAxisPlotBands.push(startLabelBackground);
    const startLabel = this.renderer
      .label(
        moment.utc(Number(plotLines[0].options.value)).format(Dates.DRAWDOWN_DATE_FORMAT),
        rect.x + 49,
        rect.y + 34,
      )
      .attr({
        zIndex: HighchartZIndex.PlotBand.AboveChart,
        align: 'center',
      })
      .css({
        color: colors.White,
        pointerEvents: 'none',
        cursor: 'default',
        fontFamily: typography.fontFamily,
        fontSize: '12px',
        fontWeight: 'bold',
      })
      .add();
    this.xAxisPlotBands.push(startLabel);

    // End label background + text
    const endLabelBackground = this.renderer
      .rect(rect.x + rect.width - 100 + 1, rect.y + 30, 100, 30)
      .attr({ fill: colors.Primary.Dark })
      .add();
    this.xAxisPlotBands.push(endLabelBackground);
    const endLabel = this.renderer
      .label(
        moment.utc(Number(plotLines[1].options.value)).format(Dates.DRAWDOWN_DATE_FORMAT),
        rect.x + rect.width - 99 + 50,
        rect.y + 34,
      )
      .attr({
        zIndex: HighchartZIndex.PlotBand.AboveChart,
        align: 'center',
      })
      .css({
        color: colors.White,
        pointerEvents: 'none',
        cursor: 'default',
        fontFamily: typography.fontFamily,
        fontSize: '12px',
        fontWeight: 'bold',
      })
      .add();
    this.xAxisPlotBands.push(endLabel);
  };
}

function getXAxisPlotBandRect(plotLineStart: Highcharts.PlotLineOrBand, plotLineEnd: Highcharts.PlotLineOrBand) {
  const dStart = plotLineStart.svgElem.d || '';
  const dEnd = plotLineEnd.svgElem.d || '';

  const dArrayStart = (dStart as AnyDuringEslintMigration).split(' ');
  const dArrayEnd = (dEnd as AnyDuringEslintMigration).split(' ');
  return {
    x: Number(dArrayStart[1]),
    y: Number(dArrayStart[5]),
    width: dArrayEnd[1] - dArrayStart[1],
    height: 30,
  };
}

function updatePlotLineSizes(this: Highcharts.Chart) {
  const plotLines = this.xAxis[0].plotLinesAndBands;
  plotLines.forEach((plotLine) => {
    if (!plotLine.svgElem.d) {
      return;
    }
    const dArray = (plotLine.svgElem.d as AnyDuringEslintMigration).split(' ');
    dArray[5] = Number(dArray[5]) + 30;
    plotLine.svgElem.d = dArray.join(' ');
    (plotLine.svgElem.element.attributes as AnyDuringEslintMigration).d.nodeValue = dArray.join(' ');
  });
}
