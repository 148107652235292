import React, { useContext, type Ref } from 'react';
import Select from 'react-select';
import { getSelectStylesV2, getSelectTheme } from './getSelectStyles';
import { ThemeContext } from 'styled-components';

/**
 * A prestyled version of react-select https://github.com/JedWatson/react-select
 * @param props
 * @constructor
 */
const VennSelect = React.forwardRef(function VennSelect<T, IsMulti extends boolean>(props, ref: Ref<Select>) {
  const { Colors } = useContext(ThemeContext);
  return (
    <Select<T, IsMulti>
      aria-label="venn-select"
      ref={ref}
      theme={getSelectTheme(Colors)}
      styles={getSelectStylesV2(Colors, props.darkPlaceholder, props.maxHeight, props.refreshedStyling)}
      {...props}
    />
  );
});

export default VennSelect;
