import React from 'react';
import styled from 'styled-components';

type RightSideBarProps = {
  children: React.ReactNode;
};
export const RightSideBar = ({ children }: RightSideBarProps) => {
  return <RightContainer>{children}</RightContainer>;
};

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
`;
