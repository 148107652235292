import React from 'react';

import { useCommonGridProps } from '../../../../studio-blocks/logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../../../studio-blocks';
import { analysisGridDefaultColDefOverrides } from '../../../../studio-blocks/components/grid/AnalysisGrid';
import ExportableGrid from '../../../../studio-blocks/components/grid/ExportableGrid';
import { GridWrapper } from '../../../../studio-blocks/common';

export const BaseGrid = ({
  refId,
  rowData,
  columnDefs,
  isCompact,
  isReport,
  inPrintMode,
  exportMetaData,
  exportable,
}) => {
  const commonGridProps = useCommonGridProps(analysisGridDefaultColDefOverrides);
  return (
    <GridWrapper isReport={isReport}>
      <ExportableGrid
        {...commonGridProps}
        selectedRefId={refId}
        rowData={rowData}
        columnDefs={columnDefs}
        isCompact={isCompact}
        inPrintMode={inPrintMode}
        exportMetaData={exportMetaData}
        exportable={exportable}
        excelStyles={DATA_TYPE_EXCEL_STYLES}
      />
    </GridWrapper>
  );
};
