import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { getSpecificPortfolioV3 } from 'venn-api';
import { Label, Loading, LoadingSize, SecondaryLabel, selectClasses } from 'venn-ui-kit';
import type { BasePortfolioField } from './types';
import { portfolioToSearchMenuItem, SearchMenuBar } from '../../search-menu';
import { CreateFromScratchFooter } from '../../search-menu/components/CreateCompositeFooter';
import { isNil } from 'lodash';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const qaClass = 'qa-create-portfolio-from';

export type BasePortfolioStateUpdater = (state: BasePortfolioField) => BasePortfolioField;
export type BasePortfolioChangeCallback = () => void;

export interface BasePortfolioProps {
  basePortfolio: BasePortfolioField;
  onChange(updater: BasePortfolioStateUpdater, callback?: BasePortfolioChangeCallback): void;
  placeholder?: string;
  label?: string;
  secondaryLabel?: string;
  className?: string;
  refreshedStyling?: boolean;
  showClearButton?: boolean;
}

const BasePortfolio = ({
  onChange,
  placeholder,
  label,
  secondaryLabel,
  basePortfolio,
  className,
  refreshedStyling,
  showClearButton = false,
}: BasePortfolioProps) => {
  const { loading, disabled: baseDisabled, allowFromScratch, baseline } = basePortfolio;

  const toggleLoading = (newLoading: boolean) =>
    onChange((prevState) => ({
      ...prevState,
      loading: newLoading,
    }));

  const startLoading = () => toggleLoading(true);

  const fetchSpecificPortfolio = async (id: number | undefined) => {
    startLoading();

    if (!id) {
      onChange((prevState) => ({
        ...prevState,
        loading: false,
        baseline: undefined,
      }));
      return;
    }

    try {
      const portfolio = (await getSpecificPortfolioV3(id)).content;
      onChange((prevState) => ({
        ...prevState,
        loading: false,
        baseline: portfolio,
      }));
    } catch (error) {
      onChange((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onLocalChange = (id: number | undefined) => {
    onChange(
      (prevState) => ({
        ...prevState,
        id,
      }),
      () => fetchSpecificPortfolio(id),
    );
  };

  const onCreateFromScratch = () => {
    onLocalChange(undefined);
  };

  return (
    <Wrapper className={classNames(className, qaClass)}>
      {loading ? (
        <Loading size={LoadingSize.small} />
      ) : (
        <>
          <Label>{label ?? 'Create new portfolio from:'}</Label>
          {secondaryLabel && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
          <StyledSearchMenuBar
            refreshedStyling={refreshedStyling}
            smallScreen
            autofocus={false}
            customPlaceholder={placeholder ?? 'Create from scratch...'}
            value={baseline ? portfolioToSearchMenuItem(baseline) : null}
            isOptionDisabled={(item) => isNil(item.value?.portfolio)}
            isClearable
            portfoliosOnly
            onSelected={(item) => onLocalChange(item.value?.portfolio?.id)}
            location="Add to portfolio"
            footer={
              allowFromScratch ? CreateFromScratchFooter(onCreateFromScratch, refreshedStyling ?? false) : undefined
            }
            disabled={baseDisabled}
            onClear={
              showClearButton
                ? () => {
                    onLocalChange(undefined);
                  }
                : undefined
            }
            privateAssetSearchMode="PUBLIC_ONLY"
          />
        </>
      )}
    </Wrapper>
  );
};

export default BasePortfolio;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  .${selectClasses.SearchIcon} {
    display: none;
  }
`;
