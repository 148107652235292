import type { AnalysisSubject } from 'venn-utils';

export const getSubjectRange = (subject?: AnalysisSubject) => {
  if (!subject) {
    return {};
  }
  return subject.fund
    ? {
        start: subject.fund.startRange,
        end: subject.fund.endRange,
      }
    : {
        start: subject.portfolio!.periodStart,
        end: subject.portfolio!.periodEnd,
      };
};
