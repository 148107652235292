import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { TutorialContent } from './TutorialContent';
import { analyticsService, assertNotNil, navigateToLibrary } from 'venn-utils';
import { Button } from 'venn-ui-kit';
import { useHistory } from 'react-router-dom';

export const MorningstarCategoryTab = () => {
  const { Images } = useContext(ThemeContext);

  const history = useHistory();
  const navigate = () => {
    navigateToLibrary(history, { state: { advancedFiltersOpen: true } });

    analyticsService.ctaClicked({
      destination: 'Library page',
      locationOnPage: 'Homepage tab - morningstar category search',
      purpose: 'morningstar category search',
      type: 'button',
    });
  };

  return (
    <TutorialContent
      headline={
        <div>
          Search by <b>Morningstar Category</b> in Data Library
        </div>
      }
      images={[assertNotNil(Images.morningstarCategoryAsset)]}
      hideBorder
      assetHeight="389px"
      assetWidth="596px"
    >
      <div>
        <Button dominant onClick={navigate} className="qa-check-it" data-testid="qa-check-it">
          Try it now
        </Button>
      </div>
    </TutorialContent>
  );
};
