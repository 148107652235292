import React, { useContext } from 'react';
import { StudioContext, StudioSidePanelContext, UserContext } from 'venn-components';
import { Icon } from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import { getDynamicIcon } from '../../../../logic/studioUtils';
import ItemActions from './ItemActions';
import { analyticsService } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import {
  allBlockIdsState,
  blockDisplayHeader,
  type BlockId,
  blockSettings,
  isReportState,
  selectedBlockIdState,
} from 'venn-state';

interface BlockItemProps {
  id: BlockId;
}

const BlockItem = ({ id }: BlockItemProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReport = useRecoilValue(isReportState);
  const blockSetting = useRecoilValue(blockSettings(id));
  const header = useRecoilValue(blockDisplayHeader(id));
  const selected = useRecoilValue(selectedBlockIdState);
  const { onDuplicateBlock, onDeleteBlock } = useContext(StudioContext);
  const { onSelectGlobal, onSelectBlock } = useContext(StudioSidePanelContext);
  const allBlockIds = useRecoilValue(allBlockIdsState);

  const duplicateBlock = () => {
    if (!blockSetting || !hasPermission('STUDIO_INSERT_BLOCKS')) {
      return;
    }

    const index = allBlockIds.findIndex((blockId) => id === blockId);
    onDuplicateBlock(id, index + 1, blockSetting.customBlockType);

    analyticsService.ctaClicked({
      purpose: 'copy studio block',
      locationOnPage: `${blockSetting.customBlockType} block left side bar`,
    });
  };

  return (
    <ViewItem isReport={!!isReport}>
      <Groups>
        <Icon type={getDynamicIcon(blockSetting)} style={{ color: 'lightgrey', fontSize: '1rem' }} />
        <Text
          data-testid="qa-block-item"
          selected={selected === id}
          role="button"
          onClick={() => (selected === id ? onSelectGlobal() : onSelectBlock(id, { scrollIntoView: true }))}
        >
          {header}
        </Text>
      </Groups>
      <ItemActions
        onDelete={
          !hasPermission('STUDIO_DELETE_BLOCKS')
            ? undefined
            : () => {
                onDeleteBlock(id);
                analyticsService.ctaClicked({
                  purpose: 'delete studio block',
                  locationOnPage: `${blockSetting?.customBlockType} block left side bar`,
                });
              }
        }
        onDuplicate={!hasPermission('STUDIO_INSERT_BLOCKS') ? undefined : () => duplicateBlock()}
        customDeleteText={`block (${header})`}
      />
    </ViewItem>
  );
};

export default BlockItem;

const ViewItem = styled.div<{ isReport: boolean }>`
  flex: 1;
  ${({ isReport }) =>
    isReport &&
    css`
      padding-left: 15px;
    `}
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
`;

const Text = styled.div<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  max-width: 180px;
  overflow: hidden;
  font-size: 14px;
  cursor: pointer;
`;

const Groups = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
