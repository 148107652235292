import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { dimensions, Footer, hideChartTooltips, type Page, useAppPrintMode } from 'venn-components';
import PageComponent from './PageComponent';
import {
  PageLayout,
  reportPageLayout,
  reportZoom,
  studioPrintOrientationType,
  viewPages,
  virtualScrollRef,
} from 'venn-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Virtuoso } from 'react-virtuoso';
import { GetColor } from 'venn-ui-kit';
import { useVirtualization } from '../logic/useVirtualization';

interface ReportPagesProps {
  focusBlockIndex: React.MutableRefObject<number | undefined>;
  focusBlockRef: React.RefObject<HTMLDivElement>;
}

/** Contains all Report Lab block content for display during edit or print modes. */
const ReportPages = React.memo(function ReportPages({ focusBlockIndex, focusBlockRef }: ReportPagesProps) {
  const { inPrintMode } = useAppPrintMode();
  const zoom = useRecoilValue(reportZoom);
  const layout = useRecoilValue(reportPageLayout);
  const printOrientationType = useRecoilValue(studioPrintOrientationType);
  const pages = useRecoilValue(viewPages);
  const setRecoilRef = useSetRecoilState(virtualScrollRef);
  const isVirtualized = useVirtualization();

  const { totalWidth, totalHeight } = dimensions[printOrientationType];

  const pageWrapperComponent = useCallback(
    (pageNumber: number, page: Page) => (
      <PageWrapper>
        <PageComponent
          totalPages={pages.length}
          zoom={zoom}
          pageNumber={pageNumber}
          page={page}
          focusBlockIndex={focusBlockIndex}
          focusBlockRef={focusBlockRef}
        />
      </PageWrapper>
    ),
    [pages.length, zoom, focusBlockIndex, focusBlockRef],
  );

  const headerComponent = useCallback(() => <Header />, []);
  return isVirtualized ? (
    <Virtuoso
      ref={(current) => setRecoilRef({ current })}
      style={{ height: 'calc(100vh - 115px)', width: '100%' }}
      onScroll={hideChartTooltips}
      data={pages}
      itemContent={pageWrapperComponent}
      context={layout}
      components={{
        List: ListContainer,
        Footer,
        Header: headerComponent,
      }}
      increaseViewportBy={{ bottom: 1000, top: 1000 }}
    />
  ) : (
    <Content
      inPrintMode={inPrintMode}
      zoom={zoom}
      layout={layout}
      totalPages={pages.length}
      width={totalWidth}
      height={totalHeight}
    >
      <BodyWrapper inPrintMode={inPrintMode} zoom={zoom} layout={layout} totalPages={pages.length}>
        {pages.map((page: Page, pageNumber: number) => (
          // TODO: Give pages ids
          // eslint-disable-next-line react/no-array-index-key
          <PageWrapper inPrintMode={inPrintMode} key={pageNumber}>
            <PageComponent
              totalPages={pages.length}
              zoom={zoom}
              pageNumber={pageNumber}
              page={page}
              focusBlockIndex={focusBlockIndex}
              focusBlockRef={focusBlockRef}
            />
          </PageWrapper>
        ))}
      </BodyWrapper>
      <Footer />
    </Content>
  );
});

export default ReportPages;

const Header = styled.div`
  height: 40px;
`;

const ListContainer = styled.div<{ context?: PageLayout }>`
  display: flex;
  min-height: calc(100vh - 115px);
  ${({ context }) =>
    context === PageLayout.VERTICAL
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : css`
          flex-wrap: wrap;
          flex-direction: row;

          > div {
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `}
`;

const PageWrapper = styled.div<{ inPrintMode?: boolean }>`
  ${({ inPrintMode }) =>
    !inPrintMode &&
    css`
      margin-bottom: 40px;
    `}
`;

const Content = styled.div<{
  inPrintMode: boolean;
  zoom: number;
  layout: PageLayout;
  totalPages: number;
  width: number;
  height: number;
}>`
  ${({ inPrintMode }) =>
    !inPrintMode &&
    css`
      background-color: ${GetColor.WhiteGrey};
    `}
  ${({ layout, inPrintMode, totalPages, zoom, width, height }) =>
    !inPrintMode &&
    (layout === PageLayout.HORIZONTAL
      ? css`
          min-height: ${(height + 80) * zoom}px;
          min-width: ${(width + 80) * zoom * totalPages}px;
        `
      : layout === PageLayout.VERTICAL
        ? css`
            min-height: ${(height + 80) * zoom}px;
            min-width: ${(width + 80) * zoom}px;
          `
        : css`
            min-height: ${(height + 80) * zoom}px;
            min-width: ${(width + 80) * zoom * 2}px;
          `)}
`;

const BodyWrapper = styled.div<{ inPrintMode: boolean; zoom: number; layout: PageLayout; totalPages: number }>`
  ${({ inPrintMode }) =>
    !inPrintMode &&
    css`
      padding: 20px;
    `}
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
  flex-direction: column;
  ${({ layout, inPrintMode }) =>
    !inPrintMode &&
    (layout === PageLayout.HORIZONTAL
      ? css`
          align-items: flex-start;
          flex-direction: row;
        `
      : layout === PageLayout.VERTICAL
        ? css`
            flex-direction: column;
          `
        : css`
            flex-wrap: wrap;
            flex-direction: row;

            > div {
              flex: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          `)}
`;
