import type { ReactNode } from 'react';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import type { DropMenuCheckboxItem, MenuCategory, MenuContainerProps } from '../types';
import { Container, Items } from './BaseMenu';
import CheckboxMenuItem from './CheckboxMenuItem';
import CheckboxMenuSelectAll from './CheckboxMenuSelectAll';
import CheckboxMenuHeader from './CheckboxMenuHeader';
import { checkHierarchyItem } from '../logic/checkbox-logic';
import { GetColor } from '../../../style/color';
import { Icon } from '../../icon/Icon';
import NoItem from './NoItem';

export interface CategorizedCheckboxMenuProps<TValue = string, TItem = DropMenuCheckboxItem<TValue>>
  extends MenuContainerProps {
  /**
   * Array of dropmenu items.
   */
  categories: MenuCategory<TValue, TItem>[];
  /**
   * If provided, displays a header on the menu containing this text.
   */
  header?: ReactNode;
  /**
   * On each change the function receives the **entire** set of items with the modification(s)
   */
  onChange: (categories: MenuCategory<TValue, TItem>[]) => void;
  onCollapse?: () => void;
  hideSelectAll?: boolean;
  /**
   * Whether to select/unselect children nodes as a result of a parent being selected
   */
  ignoreHierarchySelect?: boolean;
  showHierarchyLines?: boolean;
  headerHeight?: number;
  hideSeparator?: boolean;
  className?: string;
  /**
   * Allow selecting up to 1 item. Hide checkboxes & "Only" buttons.
   */
  singleSelection?: boolean;
  footerComponent?: JSX.Element;
}

const CategorizedCheckboxMenu = <T,>({
  categories,
  width,
  height,
  header,
  hideSelectAll,
  showHierarchyLines,
  headerHeight,
  hideSeparator,
  singleSelection,
  footerComponent,
  className,
  onChange,
  onCollapse,
  ignoreHierarchySelect,
}: CategorizedCheckboxMenuProps<T>) => {
  const [allItemsSelected, setAllItemsSelected] = useState(
    categories.every((c) => c.items.every((item) => item.checked)),
  );

  const onCheckOne = (item: DropMenuCheckboxItem<T>) => () => {
    const modifiedItems = categories.map((c) => ({
      ...c,
      items: ignoreHierarchySelect
        ? c.items.map((i) => ({
            ...i,
            checked: item.value === i.value ? !i.checked : i.checked,
          }))
        : checkHierarchyItem(c.items, item),
    }));

    setAllItemsSelected(modifiedItems.every((c) => c.items.every((item) => item.checked)));
    onChange(modifiedItems);
  };

  const onCheckOnly = (item: DropMenuCheckboxItem<T>) => () => {
    // Uncheck everything first, then check the one
    const modifiedItems = categories.map((c) => ({
      ...c,
      items: ignoreHierarchySelect
        ? c.items.map((i) => ({
            ...i,
            checked: item.value === i.value,
          }))
        : checkHierarchyItem(
            c.items.map((i) => ({
              ...i,
              checked: false,
            })),
            {
              ...item,
              checked: false,
            },
          ),
    }));

    onChange(modifiedItems);
    onCollapse?.();
    setAllItemsSelected(false);
  };

  const onSelectAll = () => {
    const modifiedItems = categories.map((c) => ({
      ...c,
      items: c.items.map((i) => ({
        ...i,
        checked: !allItemsSelected,
      })),
    }));
    onChange(modifiedItems);
    setAllItemsSelected(!allItemsSelected);
  };

  return (
    <Container width={width} height={height} tabIndex={-1} className={className}>
      {categories.length > 0 ? (
        <>
          {header && (
            <CheckboxMenuHeader text={typeof header === 'string' ? header : undefined} height={headerHeight}>
              {typeof header !== 'string' ? header : null}
            </CheckboxMenuHeader>
          )}
          {!hideSelectAll && !singleSelection && (
            <CheckboxMenuSelectAll onClick={onSelectAll} allItemsSelected={allItemsSelected} />
          )}
          <Items>
            {categories.map((category) => (
              <Category hideSeparator={hideSeparator} key={category.name}>
                <CategoryName>
                  {category.name}{' '}
                  {category.disabled && (
                    <DisabledHint>
                      <Icon type="exclamation-circle" />
                      {category.disabledMessage}
                    </DisabledHint>
                  )}
                </CategoryName>
                {(category.items || []).map((item, index) => (
                  <CheckBoxMenuPadding key={item.label}>
                    <CheckboxMenuItem<T>
                      item={item}
                      onCheck={onCheckOne(item)}
                      onOnly={onCheckOnly(item)}
                      showLines={showHierarchyLines}
                      maxWidth={width ?? 200}
                      hideCheckbox={singleSelection || item.hideCheckbox}
                      isFirst={index === 0}
                    />
                  </CheckBoxMenuPadding>
                ))}
              </Category>
            ))}
          </Items>
        </>
      ) : (
        <NoItem>(No results found)</NoItem>
      )}
      {footerComponent}
    </Container>
  );
};

const CheckBoxMenuPadding = styled.div`
  padding: 3px 0;
`;

const Category = styled.div<{ hideSeparator?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  ${({ hideSeparator }) =>
    hideSeparator
      ? ''
      : css`
          padding-bottom: 19px;
          border-bottom: 1px solid ${GetColor.PaleGrey};
        `}
`;

const CategoryName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0 20px;
  line-height: 2;
  font-weight: 400;
  color: ${GetColor.GreyScale.Grey70};
  font-style: italic;
`;

const DisabledHint = styled.div`
  color: ${GetColor.HighlightDark};
  font-size: 10px;

  i {
    margin-right: 4px;
  }
`;

export default CategorizedCheckboxMenu;
