import * as Sentry from '@sentry/browser';
import type { UserProfileSettings, OperationResult } from 'venn-api';
import { SupportedErrorCodes, rpcCodeToHttpCodeString } from 'venn-api';
import { IS_HOSTED } from '../environment';
import type { AnyObject } from '../type';
import { datadogRum } from '@datadog/browser-rum';
import { Code, ConnectError } from '@connectrpc/connect';

const dsn = 'https://8ed1d6bf0f54457096d2b81cff02d8a1@sentry.io/1815774';

/**
 * List of errors for sentry to ignore
 */
const IGNORED_ERRORS = [
  // ResizeObserver errors should be ignored, as it is benign
  // see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
];

/** We won't log into Sentry when it's local or in merge request (make it a function for ease of testing). */
const shouldLogToSentry = () => IS_HOSTED;

export const initializeSentryLogger = () => {
  if (!shouldLogToSentry()) {
    return;
  }

  Sentry.init({
    dsn,
    release: process.env.COMMIT_SHA,
    environment: process.env.ACTUAL_ENV || 'development',
    ignoreErrors: IGNORED_ERRORS,
    beforeSend: (event) => {
      const emailToIgnore = process.env.TEST_USER;
      if (emailToIgnore && event.user?.email === emailToIgnore) {
        return null;
      }
      return event;
    },
  });
};

interface OperationError {
  body: string;
  code: number;
  message: string;
  severity: string;
}

// Sentry supports grpc status codes, but we need to convert them into snake case strings
// https://develop.sentry.dev/sdk/event-payloads/contexts/
function codeToSentryTag(code: Code): string {
  return Code[code]
    .split('')
    .map((char, index) => {
      if (char.toUpperCase() === char && index !== 0) {
        return `_${char}`;
      }
      return char;
    })
    .join('')
    .toLowerCase();
}

function codeToHttpStatus(code: Code): string {
  return rpcCodeToHttpCodeString[code];
}

export const logExceptionIntoSentry = (
  error: ConnectError | Error | string | OperationResult<OperationError | undefined>,
  errorInfo?: AnyObject,
) => {
  if (!shouldLogToSentry()) {
    // eslint-disable-next-line no-console
    console.error(error);
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('warning' as Sentry.Severity);
    if (errorInfo) {
      scope.setExtras(errorInfo);
    }

    if (error instanceof ConnectError) {
      if (error.code === Code.Unavailable || error.code === Code.Aborted) {
        return;
      }
      scope.setTag('status', codeToSentryTag(error.code));
      scope.setTag('status_code', codeToHttpStatus(error.code));
      Sentry.captureException(error);
      return;
    }

    if (error instanceof Error) {
      // Ignore abort error
      if (error.name === 'AbortError') {
        return;
      }
      Sentry.captureException(error);
      return;
    }

    if (typeof error === 'string') {
      Sentry.captureException(new Error(error));
      return;
    }

    if (error.status) {
      // Ignore unauth error
      if (error.status === 401) {
        return;
      }
      scope.setTag('status', String(error.status));
    }

    for (const code in SupportedErrorCodes) {
      if (Number(code)) {
        if (error.content?.code === Number(code)) {
          // Ignore supported error codes
          return;
        }
      }
    }

    const message = error.content?.message || 'Unknown error';
    Sentry.captureException(new Error(message));
  });
};

export const logUserInfoToSentry = (profile: UserProfileSettings) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      email: profile.user.email,
      id: profile.user.id.toString(),
      username: profile.user.name,
      orgName: profile.organization.name,
      orgId: profile.organization.id,
    });
  });

  datadogRum.setUser({
    id: String(profile.user.id),
    orgId: profile.organization.id,
    actualUser: String(profile.actualUser),
    country: String(profile.country),
  });
};

export const logMessageToSentry = (message: string) => {
  Sentry.captureMessage(message);
};
