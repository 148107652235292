import React, { useMemo } from 'react';
import last from 'lodash/last';
import styled, { withTheme } from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';
import type { ChartProps } from './types';
import { addVenncast, getLineChartOptions, getVenncastWarningMessage } from './line-chart/utils';
import { VenncastWarnings } from './line-chart/VenncastWarnings';
import { useRecoilValue } from 'recoil';
import { blockFonts } from 'venn-state';
import { useBlockId } from '../../contexts/BlockIdContext';
import { StudioHighchart } from '../../../highchart/Highchart';

const LineChart = ({
  start,
  end,
  theme,
  series,
  yAxisUnitFormat,
  frequency,
  inPrintMode,
  hasDrawdownLines,
  height,
  venncastEnabled = false,
  subjectType,
  className,
}: ChartProps) => {
  const blockId = useBlockId();
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const venncastData = venncastEnabled && series.length === 1 ? series[0].venncast : undefined;
  const venncastWarningMessage = venncastEnabled
    ? getVenncastWarningMessage({
        seriesLength: series.length,
        frequency,
        isFund: subjectType === 'investment' || false,
        interpolated: venncastData?.interpolated,
        estimated: venncastData?.estimated,
        venncastStatus: venncastData?.status,
      })
    : undefined;

  const options = useMemo(() => {
    const chartConfig = getLineChartOptions({
      series,
      theme,
      yAxisUnitFormat,
      frequency,
      inPrintMode,
      hasDrawdownLines,
      height,
      venncastEnabled,
      legendFontSize: legendFont.fontSizePt,
      axisFontSize: axisFont.fontSizePt,
    });

    const visibleVenncastEnd = series.reduce((acc, currentSeries) => {
      const value = last<number[]>(currentSeries.venncast?.estimatedCumulativeReturns)?.[0] || 0;
      return value > acc ? value : acc;
    }, 0);

    const chartConfigWithVenncast =
      venncastEnabled && visibleVenncastEnd && start !== undefined && end !== undefined
        ? addVenncast(
            chartConfig,
            {
              start,
              end,
              visibleVenncastStart: end,
              visibleVenncastEnd,
              persistentBottomBar: true,
            },
            theme.Colors,
            inPrintMode,
          )
        : undefined;

    return chartConfigWithVenncast || chartConfig;
  }, [
    series,
    start,
    end,
    theme,
    yAxisUnitFormat,
    frequency,
    inPrintMode,
    hasDrawdownLines,
    height,
    venncastEnabled,
    legendFont.fontSizePt,
    axisFont.fontSizePt,
  ]);

  return (
    <>
      {venncastEnabled && venncastWarningMessage && (
        <RelativeWrapper>
          <VenncastWarningWrapper inPrintMode={inPrintMode}>
            <VenncastWarnings warningMessage={venncastWarningMessage} />
          </VenncastWarningWrapper>
        </RelativeWrapper>
      )}
      <StudioHighchart options={options} blockId={blockId} className={className ?? 'qa-line-chart'} />
    </>
  );
};

export default withTheme(LineChart);

const RelativeWrapper = styled.div`
  position: relative;
`;

const VenncastWarningWrapper = styled.div<{ inPrintMode?: boolean }>`
  position: absolute;
  top: ${({ inPrintMode }) => (inPrintMode ? 10 : -40)}px;
  left: ${({ inPrintMode }) => (inPrintMode ? 13 : 20)}px;
  vertical-align: middle;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.Front};
`;
