/**
 * @fileoverview contains a list of user settings used in the application, utilities to access them, as well as types and
 * assertions to help ensure they're used safely.
 */

import { IS_JEST_TEST } from '../../venn-utils/src/environment';
import type { UserSettings as GeneratedUserSettings } from './autogenerated/types';

export type TypedUserSettings = Partial<{
  ['analysis.investment.default']: string;
  ['analysis.portfolio.default']: string;
  hideForecastsInExportKey: boolean;
  imageExportScaleKey: number;
  vennWelcomeModalViewed: boolean;
  uploadTypeKey: TABS;
  lastReadReleaseNote: number;
  libraryNewUserKey: boolean;
  quickLinksDisplayKey: boolean;
  visited_ais: boolean;
}>;

export type UserSettingKey = keyof TypedUserSettings;

export const USER_SETTINGS_KEYS = [
  'analysis.portfolio.default',
  'analysis.investment.default',
  'hideForecastsInExportKey',
  'imageExportScaleKey',
  'vennWelcomeModalViewed',
  'uploadTypeKey',
  'lastReadReleaseNote',
  'libraryNewUserKey',
  'quickLinksDisplayKey',
  'visited_ais',
] as const;

if (USER_SETTINGS_KEYS.length !== new Set(USER_SETTINGS_KEYS).size) {
  throw new Error('USER_SETTINGS_KEYS contains duplicate keys');
}

export const DEFAULT_ANALYSIS_FOR_INVESTMENT = createKey('analysis.investment.default');
export const DEFAULT_ANALYSIS_FOR_PORTFOLIO = createKey('analysis.portfolio.default');
export const HIDE_FORECASTS_IN_EXPORTS_KEY = createKey('hideForecastsInExportKey');
export const IMAGE_EXPORT_SCALE_KEY = createKey('imageExportScaleKey');
export const VENN_WELCOME_MODAL_VIEWED_KEY = createKey('vennWelcomeModalViewed');
export const UPLOAD_TYPE_KEY = createKey('uploadTypeKey');
export const LAST_READ_RELEASE_NOTE_KEY = createKey('lastReadReleaseNote');
export const LIBRARY_NEW_USER_KEY = createKey('libraryNewUserKey');
export const QUICKLINKS_STORAGE_KEY = createKey('quickLinksDisplayKey');
export const VISITED_AIS_KEY = createKey('visited_ais');

function createKey<KeyT extends UserSettingKey>(newKey: KeyT): KeyT {
  const settingsArrayHasKey = USER_SETTINGS_KEYS.includes(newKey);
  if (!IS_JEST_TEST && !settingsArrayHasKey) {
    throw new Error(`User settings keys should be included in USER_SETTINGS_KEYS. ${newKey} is not.`);
  }

  return newKey;
}
export type UserSettings = Omit<GeneratedUserSettings, 'user'> & {
  user: TypedUserSettings;
};

export enum TABS {
  UPLOAD,
  PASTE,
  TEMPLATES,
}
