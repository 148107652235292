import React, { useEffect } from 'react';
import ImageBlock from './components/ImageBlock';
import { PageBreak } from './components/PageBreak';
import TextBlock from './components/TextBlock';
import {
  blockExcelFunction,
  blockSettings,
  isReportState,
  selectedBlockIdState,
  useScopedSetRecoilState,
} from 'venn-state';
import { useRecoilValue } from 'recoil';

interface TextAndFormatBlockProps {
  /** @deprecated use useBlockId hook */
  id: string;
}

export const TextAndFormatBlock = ({ id }: TextAndFormatBlockProps) => {
  const selected = useRecoilValue(selectedBlockIdState);
  const blockSetting = useRecoilValue(blockSettings(id));
  const isReport = useRecoilValue(isReportState);
  const customBlockType = blockSetting.customBlockType;
  const active = selected ? selected === id : false;
  const setExportFunction = useScopedSetRecoilState(blockExcelFunction(id));

  useEffect(() => {
    setExportFunction({ excelFunction: undefined });
  }, [setExportFunction]);

  switch (customBlockType) {
    case 'IMAGE':
      return <ImageBlock id={id} isReport={isReport} />;
    case 'PAGE_BREAK':
      return <PageBreak active={active} />;
    case 'MARKDOWN':
      return <TextBlock id={id} active={active} isReport={isReport} />;
    default:
      // TODO(collin.irwin): wrangle types to remove default case and use assertExhaustive
      return null;
  }
};
