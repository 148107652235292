import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { assertNotNil, FS } from 'venn-utils';
import { getAppTitle, GetColor, Headline1 } from 'venn-ui-kit';
import { StudioExamplesCards } from 'venn-components';
import type { TabConfig } from './HomePageTabs';
import { Tutorial } from './TutorialContent';
import { compact } from 'lodash';
import WealthManagerTab from './WealthManagerTab';
import { MacroScenarioTab, ScenarioTab } from './ScenarioTab';
import { ReportLabTab } from './ReportLabTab';
import { MorningstarCategoryTab } from './MorningstarCategoryTab';
import { PrivateAssetLabTab } from './PrivateAssetLabTab';
import { PeerGroupAnalysisTab } from './PeerGroupAnalysisTab';
import { PrivateAssetLabTabAGSLaunch } from './PrivateAssetLabTabAGSLaunch';

const StudioTab = () => {
  return (
    <StudioWrapper>
      <Headline1>
        {`You can now preview Studio! A space to create custom analyses and share custom reports in and outside of ${getAppTitle()}`}
      </Headline1>
      <StudioExamplesCards location="home page" header="Try it out:" />
    </StudioWrapper>
  );
};

const TrendTab = ({ fundId }: { fundId?: string }) => {
  const { Images } = useContext(ThemeContext);
  return (
    <Tutorial
      headline="Use the Two Sigma Factor Lens to view a rolling breakdown of risk, return, and exposures across factor and residual components"
      images={[assertNotNil(Images.trend)]}
      customTemplate="factor"
      fundId={fundId}
      analyticsLocation="Home tab 1.2 (factor)"
    />
  );
};

const getHomeTabs = (hasOptimizationPermission: boolean, hasWealthOnboardingFlow: boolean): TabConfig[] => {
  const hasStudio = FS.has('studio_ff');
  const hasReportLab = FS.has('studio_report_editor');
  const macroScenarioReleased = FS.has('macro_scenario_ff');
  const morningstarSearchReleased = FS.has('categories_search_ff');
  const revealPrivatesSlide = FS.has('privates_reveal_ff');
  const pgaReleased = FS.has('peer_group_analytics_ff');

  const pgaTab = pgaReleased
    ? {
        title: 'Peer Group Analysis',
        hideWelcomeHeader: true,
        Component: PeerGroupAnalysisTab,
      }
    : undefined;

  const privatesTabComponent = FS.has('public_private_asset_growth_ff')
    ? PrivateAssetLabTabAGSLaunch
    : PrivateAssetLabTab;
  const privatesTab = revealPrivatesSlide
    ? {
        title: (
          <>
            <New>BETA ACCESS</New>
            PRIVATE ASSET LAB
          </>
        ),
        hideWelcomeHeader: true,
        Component: privatesTabComponent,
      }
    : undefined;

  const morningstarTab = morningstarSearchReleased
    ? {
        title: <>Morningstar</>,
        Component: MorningstarCategoryTab,
        hideWelcomeHeader: true,
      }
    : undefined;

  const wealthTab = hasWealthOnboardingFlow
    ? {
        title: (
          <>
            <New>Getting started</New>
            View reports and analyze
          </>
        ),
        displayBackground: true,
        Component: WealthManagerTab,
        hideWelcomeHeader: true,
      }
    : undefined;

  const studioTab = hasStudio
    ? {
        title: 'Studio',
        Component: StudioTab,
        hideWelcomeHeader: true,
      }
    : undefined;

  const trendTab = !hasStudio
    ? {
        title: 'Are your managers delivering what they claim?',
        Component: TrendTab,
      }
    : undefined;

  const macroScenariosAvailableForUser = macroScenarioReleased && (hasStudio || hasReportLab);
  const macroScenarioTab = macroScenariosAvailableForUser
    ? {
        title: 'Prepared for market & macroeconomic shocks?',
        Component: MacroScenarioTab,
      }
    : undefined;
  const scenarioTab = !macroScenariosAvailableForUser
    ? {
        title: 'Are you prepared for market shocks?',
        Component: ScenarioTab,
      }
    : undefined;

  const reportLabTab = {
    title: <>Report Lab</>,
    customWelcomeHeaderLabel: 'Discover Report Lab',
    Component: ReportLabTab,
  };

  const tabsList = [
    wealthTab,
    privatesTab,
    pgaTab,
    macroScenarioTab,
    reportLabTab,
    morningstarTab,
    studioTab,
    scenarioTab,
    trendTab,
  ];

  return compact(tabsList).map((tab, index) => ({ ...tab, stepNumber: `${index + 1}.0` }));
};

export default getHomeTabs;

const StudioWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  ${Headline1} {
    margin-bottom: 20px;
  }
`;

const New = styled.div`
  color: ${GetColor.HighlightDark};
`;
