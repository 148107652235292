import React, { useCallback, useContext } from 'react';
import InputTitle from './InputTitle';
import type { Snapshot } from 'recoil';
import { useRecoilCallback, useRecoilValue, waitForAll } from 'recoil';
import {
  getSubjectId,
  type Subject,
  type SubjectInputId,
  type SubjectWithOptionalFee,
  getNewSubjectInputId,
  isReportState,
  nextSubjectInputNameState,
  requestSubjects,
  subjectInputGroupName,
  subjectInputGroups,
  subjectInputGroupSubjects,
  subjectsAreEqual,
  analysisViewIdState,
} from 'venn-state';
import { PortfoliosContext, ReorderableListGroup, UserContext } from 'venn-components';
import { SubjectGroupRowWrapper, SubjectItem } from './SubjectGroupRow';
import type { AnalysisSubject } from 'venn-utils';
import { analyticsService, MAX_SUBJECT_GROUP_SIZE, useModal } from 'venn-utils';
import { Button, Icon } from 'venn-ui-kit';
import styled from 'styled-components';
import ManageFeesModal from './ManageFeesModal';

const SubjectsSection = () => {
  const { hasPermission } = useContext(UserContext);
  const isReport = useRecoilValue(isReportState);
  const viewId = useRecoilValue(analysisViewIdState);
  const { demoPortfolio } = useContext(PortfoliosContext);
  const groupIds = useRecoilValue(subjectInputGroups);
  const groupSubjects = useRecoilValue(waitForAll(groupIds.map((id) => subjectInputGroupSubjects(id))));
  const readonly = !hasPermission('STUDIO_EDIT');
  const [isFeesModalOpen, openFeesModal, closeFeesModal] = useModal();

  const onAddNew = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const newId = getNewSubjectInputId();
        const newNameLoadable = snapshot.getLoadable(nextSubjectInputNameState).valueOrThrow();
        const [newSubjects, namedSubjectsForTracking] = demoPortfolio
          ? [
              [{ portfolioId: demoPortfolio.id }],
              [{ type: 'portfolio', id: demoPortfolio.id, name: demoPortfolio.name }],
            ]
          : [[], []];

        analyticsService.inputsSubjectGroupCreated({
          name: newNameLoadable,
          subjects: namedSubjectsForTracking,
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          viewId,
        });

        set(subjectInputGroupName(newId), newNameLoadable);
        set(subjectInputGroupSubjects(newId), newSubjects);
        set(subjectInputGroups, (existingIds) => [...existingIds, newId]);
      },
    [demoPortfolio, isReport, viewId],
  );

  const trackItemsModified = useCallback(
    async (snapshot: Snapshot, groupId: SubjectInputId, newSubjects: Subject[]) => {
      const releaseSnapshot = snapshot.retain();
      try {
        const groupName = await snapshot.getPromise(subjectInputGroupName(groupId));
        const subjects = (await snapshot.getPromise(requestSubjects(newSubjects))).map((subject) => ({
          type: subject.subjectType.toLowerCase(),
          name: subject.name,
          id: subject.id,
        }));
        analyticsService.inputsSubjectGroupModified({
          name: groupName,
          subjects,
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          viewId,
        });
      } finally {
        releaseSnapshot();
      }
    },
    [isReport, viewId],
  );

  const trackGroupChanged = useCallback(
    (
      snapshot: Snapshot,
      groupId: SubjectInputId,
      groupName: string,
      newSubjects: Subject[],
      action: 'CREATE' | 'EDIT',
    ) => {
      if (action === 'EDIT') {
        trackItemsModified(snapshot, groupId, newSubjects);
      } else {
        analyticsService.inputsSubjectGroupCreated({
          name: groupName,
          subjects: newSubjects,
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          viewId,
        });
      }
    },
    [trackItemsModified, isReport, viewId],
  );

  const onReorderItems = useRecoilCallback(
    ({ set, snapshot }) =>
      // Since two atoms for two subject groups are set here, this function must be non-async to ensure the state is updated synchronously
      (sourceGroup: SubjectInputId, destGroup: SubjectInputId, sourceIndex: number, destIndex: number) => {
        const sourceGroupIndex = groupIds.indexOf(sourceGroup);
        const destGroupIndex = groupIds.indexOf(destGroup);
        const subject = groupSubjects[sourceGroupIndex][sourceIndex];
        const destSubjects = groupSubjects[destGroupIndex];

        if (sourceGroup !== destGroup) {
          const isDuplicateSubject = destSubjects.some((destSubject) => subjectsAreEqual(subject, destSubject));
          const isOverSubjectLimit = destSubjects.length >= MAX_SUBJECT_GROUP_SIZE;
          if (isDuplicateSubject || isOverSubjectLimit) {
            // Cancel the swap
            return;
          }
        }

        if (sourceGroup !== destGroup) {
          set(subjectInputGroupSubjects(sourceGroup), (current) => {
            const updatedSubjects = current.filter((_, index) => index !== sourceIndex);
            trackItemsModified(snapshot, sourceGroup, updatedSubjects);
            return updatedSubjects;
          });
        }
        set(subjectInputGroupSubjects(destGroup), (current) => {
          const newArray =
            sourceGroup === destGroup ? current.filter((_, index) => index !== sourceIndex) : [...current];
          newArray.splice(destIndex, 0, subject);
          trackItemsModified(snapshot, destGroup, newArray);
          return newArray;
        });
      },
    [trackItemsModified, groupIds, groupSubjects],
  );

  const reorderableGroups = groupIds.map((id, index) => ({
    id,
    reorderableItems: groupSubjects[index].map((subject) => ({
      key: getSubjectId(subject),
      value: subject,
    })),
  }));

  const onDeleteItem = useRecoilCallback(
    ({ set, snapshot }) =>
      (groupId: SubjectInputId, toDelIdx: number) => {
        set(subjectInputGroupSubjects(groupId), (current) => {
          const updatedSubjects = current.filter((_, idx) => idx !== toDelIdx);
          trackItemsModified(snapshot, groupId, updatedSubjects);
          return updatedSubjects;
        });
      },
    [trackItemsModified],
  );

  // onSwapSubject memoization helps prevent excessive rerendering of the swap modal and dropdown menu
  const onSwapSubject = useRecoilCallback(
    ({ set, snapshot }) =>
      (groupId: SubjectInputId, newAnalysisSubject: AnalysisSubject, idx: number) => {
        const newSubject = {
          fundId: newAnalysisSubject.fund?.id,
          portfolioId: newAnalysisSubject.portfolio?.id,
          privateFundId: newAnalysisSubject.privateFund?.id,
          privatePortfolioId: newAnalysisSubject.privatePortfolio?.id,
        };

        set(subjectInputGroupSubjects(groupId), (current) => {
          const newArr = [...current];
          newArr[idx] = newSubject;
          trackItemsModified(snapshot, groupId, newArr);
          return newArr;
        });
      },
    [trackItemsModified],
  );

  const groupWrapper = useCallback(
    ({ group, children }) => (
      <SubjectGroupRowWrapper
        readonly={readonly}
        groupId={group.id}
        trackGroupChanged={trackGroupChanged}
        deletable={groupIds.length > 1}
      >
        {children}
      </SubjectGroupRowWrapper>
    ),
    [readonly, groupIds, trackGroupChanged],
  );

  const itemRenderer = useCallback(
    (
      groupId: SubjectInputId,
      item: {
        key: string;
        value: SubjectWithOptionalFee;
      },
      idx: number,
    ) => (
      <SubjectItem
        subject={item.value}
        groupId={groupId}
        onDelete={() => onDeleteItem(groupId, idx)}
        onSwap={(newAnalysisSubject: AnalysisSubject) => onSwapSubject(groupId, newAnalysisSubject, idx)}
        readonly={readonly}
        hideAllocatorLauncher={isReport}
        showAdvisoryFee={isReport}
      />
    ),
    [readonly, isReport, onDeleteItem, onSwapSubject],
  );
  return (
    <>
      <InputTitle title="Subject Groups" onAddNew={onAddNew} numberOfInputs={groupIds.length} />
      <ReorderableListGroup
        hideDelete
        reorderableGroups={reorderableGroups}
        onReorderItems={onReorderItems}
        GroupWrapper={groupWrapper}
        itemRenderer={itemRenderer}
      />
      {isReport && (
        <ButtonContainer>
          <ButtonWithMargin dense onClick={openFeesModal}>
            <Icon type="usd-square" /> Manage Fees
          </ButtonWithMargin>
          {isFeesModalOpen && <ManageFeesModal subjectGroups={reorderableGroups} onClose={closeFeesModal} />}
        </ButtonContainer>
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
`;

export default SubjectsSection;

const ButtonWithMargin = styled(Button)`
  width: 100%;
  margin-left: 12px;
  margin-right: 12px !important;
`;
