import type { VennColors } from '../../style/color';
import type { ControlProps, GroupBase, StylesConfig, Theme } from 'react-select';
import { ZIndex } from '../../zIndexValues';
import { ColorUtils } from '../../style';

export const selectClasses = {
  SearchIcon: 'searchIcon',
  PlaceholderText: 'placeholderText',
};

export const getSelectTheme =
  (colors: VennColors) =>
  (baseTheme: Theme): Theme => ({
    ...baseTheme,
    borderRadius: 0,
    colors: {
      ...baseTheme.colors,
      neutral10: colors.White,
      primary: ColorUtils.opacify(colors.Primary.Main, 0.15),
      primary25: colors.PaleGrey,
    },
  });

export const getSelectStyles = <T, IsMulti extends boolean>(
  colors: VennColors,
): Partial<StylesConfig<T, IsMulti, GroupBase<T>>> => ({
  indicatorsContainer: (provided, state) => ({
    height: '100%',
    display: state.selectProps.isClearable && state.hasValue ? undefined : 'none',
    cursor: 'pointer',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    '&:hover': {},
    borderColor: state.isFocused ? colors.Primary.Main : provided.borderColor,
    borderRadius: 4,
    boxShadow: 'none',
    height: '35px',
    fontWeight: 'normal' as const,
    fontSize: '14px',
  }),
  group: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: '20px',
  }),
  groupHeading: () => ({
    fontSize: '12px',
    fontWeight: 'bold' as const,
    height: '24px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 20px',
  }),
  option: (provided) => ({
    ...provided,
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    cursor: 'pointer',
    fontSize: '14px',
  }),
  placeholder: (provided) => ({
    ...provided,
    lineHeight: '20px',
    width: 'calc(100% - 40px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '1px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '20px',
    maxHeight: '600px',
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: ZIndex.ModalFront,
  }),
});

export const getSelectStylesV2 = <T, IsMulti extends boolean>(
  colors: VennColors,
  darkPlaceholder?: boolean,
  maxHeight?: number,
  refreshedStyling?: boolean,
): Partial<StylesConfig<T, IsMulti, GroupBase<T>>> => ({
  indicatorsContainer: (provided, state) => ({
    height: '100%',
    display: state.selectProps.isClearable && state.hasValue ? undefined : 'none',
    cursor: 'pointer',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  control: (provided, state) => {
    const darkPlaceholderstyles = darkPlaceholder ? getDarkPlaceholderStyles(colors, state) : {};
    return {
      ...provided,
      '&:hover': {},
      borderColor: state.isFocused
        ? refreshedStyling
          ? colors.DarkBlue
          : colors.Primary.Main
        : refreshedStyling
          ? colors.GreyScale.Grey50
          : provided.borderColor,
      borderRadius: refreshedStyling ? 4 : 8,
      borderWidth: refreshedStyling ? 1 : 2,
      boxShadow: 'none',
      height: '35px',
      fontWeight: 'normal' as const,
      fontSize: '14px',
      ...darkPlaceholderstyles,
    };
  },
  group: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: '20px',
  }),
  groupHeading: () => ({
    fontSize: '12px',
    fontWeight: 'bold' as const,
    height: '24px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  option: (provided) => ({
    ...provided,
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    cursor: 'pointer',
    fontSize: '14px',
  }),
  placeholder: (provided) => ({
    ...provided,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '1px',
  }),
  menuList: (provided, { isMulti }) => ({
    ...provided,
    ...(isMulti ? { padding: 0 } : { padding: '20px' }),
    maxHeight: maxHeight ? `${Math.floor(maxHeight)}px` : '600px',
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: ZIndex.ModalFront,
  }),
  noOptionsMessage: () => ({
    fontSize: '14px',
    lineHeight: '22px',
  }),
});

const getDarkPlaceholderStyles = <T, IsMulti extends boolean>(
  colors: VennColors,
  state: ControlProps<T, IsMulti, GroupBase<T>>,
) => {
  const unfocusedStyle = !state.isFocused
    ? {
        backgroundColor: colors.DarkGrey2,
        borderColor: colors.DarkGrey,
        borderWidth: '1px',
        padding: '1px',
        '> input': {
          color: colors.White,
        },
        [`.${selectClasses.PlaceholderText}`]: {
          fontWeight: 'bold',
          fontSize: '11px',
          textTransform: 'uppercase' as unknown,
          letterSpacing: '1px',
          color: colors.White,
        },
        [`.${selectClasses.SearchIcon}`]: {
          color: colors.Primary.Main,
        },
      }
    : {};
  return {
    '&:hover': {
      borderColor: colors.Primary.Main,
    },
    transition: 'background-color 0.2s ease-in-out',
    height: '30px',
    minHeight: '30px',
    borderRadius: 4,
    ...unfocusedStyle,
  };
};
