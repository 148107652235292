import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalBackToPrev,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalSubhead,
} from '../../modal';
import styled from 'styled-components';
import { Link, Label } from 'venn-ui-kit';
import type { Portfolio, PrivatePortfolioNode } from 'venn-api';
import { getPrivatePortfolio, getSpecificPortfolioV3 } from 'venn-api';
import type { NewFundDraft } from 'venn-utils';
import {
  AnalysisSubject,
  analyticsService,
  buttonize,
  getBlankStudio,
  navigateToStudioView,
  Routes,
  useApi,
} from 'venn-utils';
import CreatePortfolioModalContent from '../create-portfolio/CreatePortfolioModalContent';
import { InputRow } from './styles';
import AddToStrategy from './AddToStrategy';
import { ConditionalOverlay } from '../../conditional-overlay';
import BannerMessage from '../../banner-message/BannerMessage';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { StudioPrintSettingsContext, UserContext } from '../../contexts';
import { portfolioToSearchMenuItem, SearchMenuBar } from '../../search-menu';
import CreatePrivatePortfolioStep from '../create-portfolio/CreatePrivatePortfolioStep';
import { uniqBy } from 'lodash';

interface AddToPortfolioModal extends RouteComponentProps {
  investments: NewFundDraft[];
  onCancel: () => void;
  onComplete?: () => void;
  initialSelectedPortfolio?: Portfolio | PrivatePortfolioNode;
  refreshPortfolios: () => Promise<void>;
  privatesOnly?: boolean;
}

const AddToPortfolioModal: FC<React.PropsWithChildren<AddToPortfolioModal>> = ({
  investments,
  onCancel,
  onComplete,
  initialSelectedPortfolio,
  refreshPortfolios,
  history,
  privatesOnly,
}: AddToPortfolioModal) => {
  const { hasPermissionForResource, currentContext } = useContext(UserContext);
  const { addDisclosurePage } = useContext(StudioPrintSettingsContext);

  const [createNew, setCreateNew] = useState(false);
  const [error, setError] = useState('');
  const [loadingPortfolio, setLoadingPortfolio] = useState(false);
  const abortableGetSpecificPortfolio = useApi(getSpecificPortfolioV3);
  const abortableGetPrivatePortfolio = useApi(getPrivatePortfolio);

  const [selectedPortfolio, setSelectedPortfolio] = useState(
    initialSelectedPortfolio && hasPermissionForResource('EDIT_PORTFOLIO', initialSelectedPortfolio)
      ? initialSelectedPortfolio
      : undefined,
  );
  const [selectedStrategy, setSelectedStrategy] = useState<Portfolio | undefined>();

  const fetchPrivatePortfolio = useCallback(
    async (portfolioId?: string) => {
      if (!portfolioId) {
        return;
      }
      setLoadingPortfolio(true);
      try {
        const portfolioResponse = await abortableGetPrivatePortfolio(portfolioId, undefined);
        if (!portfolioResponse || portfolioResponse.status !== 200) {
          setError('Could not fetch portfolio info.');
          setLoadingPortfolio(false);
          return;
        }
        const selected = portfolioResponse.content;
        setSelectedPortfolio(selected);
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        }
        setError(e?.message);
      }
      setLoadingPortfolio(false);
    },
    [abortableGetPrivatePortfolio],
  );

  const fetchPortfolio = useCallback(
    async (portfolioId?: number) => {
      if (!portfolioId) {
        return;
      }
      setLoadingPortfolio(true);
      try {
        const portfolioResponse = await abortableGetSpecificPortfolio(portfolioId, undefined);
        if (!portfolioResponse || portfolioResponse.status !== 200) {
          setError('Could not fetch portfolio info.');
          setLoadingPortfolio(false);
          return;
        }
        const selected = portfolioResponse.content;
        setSelectedPortfolio(selected);
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        }
        setError(e?.message);
      }
      setLoadingPortfolio(false);
    },
    [abortableGetSpecificPortfolio],
  );

  useEffect(() => {
    // Need to load the initial portfolio to get it's benchmarks
    privatesOnly
      ? fetchPrivatePortfolio((initialSelectedPortfolio as PrivatePortfolioNode)?.id)
      : fetchPortfolio((initialSelectedPortfolio as Portfolio)?.id);
  }, [fetchPortfolio, fetchPrivatePortfolio, privatesOnly, initialSelectedPortfolio]);

  const redirectToPortfolio = (portfolio: Portfolio) =>
    history.push(`${Routes.DEFAULT_ANALYSIS_PATH}/portfolio/${portfolio.id}`);

  const addToPortfolio = () => {
    onComplete?.();
    history.push(`${Routes.DEFAULT_ANALYSIS_PATH}/portfolio/${selectedPortfolio?.id ?? ''}`, {
      investments,
      addToStrategy: selectedStrategy,
    });
  };

  const convertFundsToPortfolioNodes = (
    investments: NewFundDraft[],
    parentPortfolioId: string,
  ): PrivatePortfolioNode[] => {
    return investments.map((investment) => {
      return {
        name: investment.name,
        fundId: investment.id,
        id: investment.id,
        children: [],
        parentNodeId: parentPortfolioId,
        rootNodeId: parentPortfolioId,
        capitalCommitment: investment.capitalCommitment ?? 0,
        active: true,
        created: 0,
        dataSource: '',
        ownerId: 0,
        permissionGroupId: 0,
        updated: 0,
        updatedById: 0,
        ownerContextId: '',
      };
    });
  };

  const addToPrivatePortfolio = () => {
    onComplete?.();
    const privatePortfolio = selectedPortfolio as PrivatePortfolioNode;
    const newChildren: PrivatePortfolioNode[] = convertFundsToPortfolioNodes(investments, privatePortfolio?.rootNodeId);
    const fullNewChildrenList = uniqBy(privatePortfolio.children.concat(newChildren), 'fundId');
    const modifiedPortfolio: PrivatePortfolioNode = {
      ...privatePortfolio,
      children: fullNewChildrenList,
    };
    const newSubject = new AnalysisSubject(privatePortfolio, 'private-portfolio');
    const newDocument = getBlankStudio(
      'TEARSHEET',
      addDisclosurePage,
      [{ privatePortfolioId: newSubject.privatePortfolio?.id }],
      currentContext,
    );

    analyticsService.creatingNewStudios({
      source: 'add to portfolio library button',
      name: 'blank tearsheet',
    });

    navigateToStudioView(history, {
      newDocument,
      openAllocatorForPrivatePortfolio: privatePortfolio,
      modifiedUnsavedPortfolio: modifiedPortfolio,
    });
  };

  const createNewLink = (
    <StyledLink className="qa-create-new" {...buttonize(() => setCreateNew(true))}>
      create new
    </StyledLink>
  );
  const investmentsText = investments.length === 1 ? 'investment' : 'investments';

  return (
    <Modal onClose={onCancel} noPadding={createNew} testId="qa-add-to-portfolio">
      {createNew && (
        <>
          <ModalBackToPrev backToLabel="Add to existing" onClick={() => setCreateNew(false)} />
          {!privatesOnly && (
            <CreatePortfolioModalContent
              baseline={selectedPortfolio as Portfolio}
              canCreateFromScratch
              onClose={onCancel}
              onSubmit={(portfolio) => redirectToPortfolio(portfolio)}
              refreshPortfolioList={refreshPortfolios}
              investments={investments}
            />
          )}
          {privatesOnly && (
            <CreatePrivatePortfolioStep
              onCancel={onCancel}
              investments={convertFundsToPortfolioNodes(investments, '')}
              onPrivatePortfolioCreated={(portfolio) => {
                onComplete?.();
                const newDocument = getBlankStudio(
                  'TEARSHEET',
                  addDisclosurePage,
                  [{ privatePortfolioId: portfolio.id }],
                  currentContext,
                );

                analyticsService.creatingNewStudios({
                  source: 'add to portfolio library button',
                  name: 'blank tearsheet',
                });
                navigateToStudioView(history, { newDocument, openAllocatorForPrivatePortfolio: portfolio });
              }}
            />
          )}
        </>
      )}

      {!createNew && (
        <ConditionalOverlay center condition={loadingPortfolio}>
          <ModalHeader>Add to {privatesOnly && 'Private'} Portfolio</ModalHeader>
          <ModalSubhead>{`Add (${investments.length}) ${investmentsText} to an existing portfolio.`}</ModalSubhead>
          <StyledModalDescription>Or {createNewLink}.</StyledModalDescription>
          <ModalContent>
            <InputRow className="qa-create-portfolio-from">
              <Label>{`Add ${investmentsText} to portfolio:`}</Label>
              <SearchMenuBar
                smallScreen
                autofocus={false}
                value={selectedPortfolio && portfolioToSearchMenuItem(selectedPortfolio)}
                isOptionDisabled={(item) =>
                  !!item.value?.portfolio && !hasPermissionForResource('EDIT_PORTFOLIO', item.value?.portfolio)
                }
                isClearable
                portfoliosOnly
                onSelected={(item) =>
                  privatesOnly
                    ? fetchPrivatePortfolio(item.value?.privatePortfolio?.id)
                    : fetchPortfolio(item.value?.portfolio?.id)
                }
                location="Add to portfolio"
                privateAssetSearchMode={privatesOnly ? 'PRIVATES_ONLY' : 'PUBLIC_ONLY'}
                includeTags={!privatesOnly}
              />
            </InputRow>
            {!privatesOnly && (
              <AddToStrategy
                label={`Add ${investmentsText} to strategy:`}
                allowCreateNew
                portfolio={selectedPortfolio as Portfolio}
                onSelected={(selected) => setSelectedStrategy(selected)}
                initialSelectedStrategy={selectedStrategy}
              />
            )}
          </ModalContent>

          {error && (
            <ErrorMessageContainer>
              <BannerMessage errorType="error" small>
                {error}
              </BannerMessage>
            </ErrorMessageContainer>
          )}
          <ModalFooter
            onCancel={onCancel}
            primaryLabel="Add to Portfolio"
            onPrimaryClick={privatesOnly ? addToPrivatePortfolio : addToPortfolio}
            primaryDisabled={!selectedPortfolio}
            primaryClassName="qa-add-to-portfolio"
            cancelClassName="qa-cancel-add-to-portfolio"
          />
        </ConditionalOverlay>
      )}
    </Modal>
  );
};

export default withRouter(AddToPortfolioModal);

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const ErrorMessageContainer = styled.div`
  margin: 20px 0px -30px;
`;

const StyledModalDescription = styled(ModalDescription)`
  font-size: 18px;
`;
