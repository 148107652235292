import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import Highlighter from 'react-highlight-words';
import type { Theme } from '../../style/themes';

export interface HighlighterProps {
  searchWords: string[];
  text: string;
  highlightClassName?: string;
  highlightColor?: string;
  theme: Theme;
}

const HighlightContainer = styled.div<{ highlightColor: string }>`
  .highlighted {
    background: inherit;
    color: ${({ highlightColor }) => highlightColor};
    font-weight: bold;
    text-decoration: underline;
  }
`;

export class VennHighlighter extends PureComponent<HighlighterProps> {
  render() {
    const {
      searchWords,
      text,
      highlightClassName,
      highlightColor,
      theme: { Colors },
    } = this.props;
    return (
      <HighlightContainer highlightColor={highlightColor || Colors.Primary.Main}>
        <Highlighter
          autoEscape
          searchWords={searchWords}
          textToHighlight={text}
          highlightClassName={highlightClassName || 'highlighted'}
        />
      </HighlightContainer>
    );
  }
}

export default withTheme(VennHighlighter);
