import { useEffect, useRef, useState, useCallback, useMemo, useContext } from 'react';
import type { DateRange, Granularity } from 'venn-ui-kit';
import { getItemColor, getRangeFromType } from 'venn-ui-kit';
import type { AnalysisSubject, ComparisonSubject } from 'venn-utils';
import { FREQUENCY_DATEPICKER_MODES, analyticsService, useSavedComparisons } from 'venn-utils';
import { SupportedErrorCodes, viewEntity } from 'venn-api';
import useComparisonAnalysis from './useComparisonAnalysis';
import { isEmpty, compact } from 'lodash';
import { ThemeContext } from 'styled-components';
import useComparisonView from './useComparisonView';
import { convertAnalysisSubjectToItemType, MAX_SUBJECTS } from 'venn-components';
import { primaryFactorLens, useCachedLoadableValue } from 'venn-state';

/**
 * Custom hook to handle state for the comparison page. This hook does not handle
 * fetching the analysis, for that refer to {@link useComparisonAnalysis}.
 */
const useComparison = () => {
  const { Colors } = useContext(ThemeContext);
  const [maxDateRange, setMaxDateRange] = useState<DateRange>({ to: undefined, from: undefined });
  const [granularity, setGranularity] = useState<Granularity>('day');
  const [focusedIdx, setFocusedIdx] = useState<number | undefined>(undefined);
  const isMounted = useRef(false);
  const factorLens = useCachedLoadableValue(primaryFactorLens);

  const {
    subjects,
    dateRange,
    benchmark,
    relative,
    setBenchmark,
    setRelative,
    setSubjects,
    setDateRange,
    onSave,
    onSaveAs,
    onRename,
    savedId,
    isViewInitLoading,
    currentViewName,
    hasUnsavedChanges,
    isPivoted,
    togglePivoted,
    noAccessModifiedView,
  } = useComparisonView();

  const { analyses, analysisIsLoading, trackingId, analysisIsFetching, analysisUpdatedTime } = useComparisonAnalysis(
    subjects,
    dateRange,
    benchmark,
    relative,
    isPivoted,
    granularity,
  );

  const [hoveredMetricIdx, setHoveredMetricIdx] = useState<number | undefined>(undefined);

  useSavedComparisons(subjects, dateRange, benchmark, relative, savedId, isPivoted);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setHovered = useCallback((idx: number, focused: boolean, colIdx?: number) => {
    if (focused) {
      setFocusedIdx(idx);
      setHoveredMetricIdx(colIdx);
    } else {
      setFocusedIdx(undefined);
      setHoveredMetricIdx(undefined);
    }
  }, []);

  const updateBenchmark = useCallback(
    (newBenchmark: AnalysisSubject | undefined) => {
      setBenchmark(newBenchmark);
      const newRelative = !newBenchmark ? false : relative;
      setRelative(newRelative);
    },
    [relative, setRelative, setBenchmark],
  );

  const toggleRelative = () => {
    setRelative(!relative);
    analyticsService.toggleToggled({
      purpose: 'Toggle relative benchmark',
      selection: !relative ? 'On' : 'Off',
      locationOnPage: 'Comparison header',
    });
  };

  const addSubject = (newAddedSubjects: AnalysisSubject[]) => {
    if (subjects.length + newAddedSubjects.length <= MAX_SUBJECTS) {
      const newSubjects = [...subjects, ...newAddedSubjects];
      // Track new added subject
      newAddedSubjects.forEach((s) => viewEntity(s.id.toString()));
      setSubjects(newSubjects);
    }
  };

  const clearSubjects = () => {
    updateBenchmark(undefined);
    setSubjects([]);
  };

  const removeSubject = useCallback(
    (idx: number) => {
      if (idx === 0 && benchmark) {
        updateBenchmark(undefined);
      } else {
        const idxToDelete = benchmark ? idx - 1 : idx;
        const newSubjects = subjects.filter((_, subjectIdx) => subjectIdx !== idxToDelete);
        setSubjects(newSubjects);
        if (isEmpty(newSubjects)) {
          updateBenchmark(undefined);
          setDateRange({ from: undefined, to: undefined });
          setMaxDateRange({ from: undefined, to: undefined });
        }
      }
    },
    [benchmark, updateBenchmark, subjects, setDateRange, setSubjects],
  );

  const updateDateRange = (newDateRange: DateRange) => {
    if (newDateRange.period) {
      setDateRange(
        getRangeFromType(newDateRange.period, maxDateRange, granularity, analyses?.maxFrequency ?? 'DAILY', factorLens),
      );
    } else {
      setDateRange({ from: newDateRange.from, to: newDateRange.to });
    }
  };

  const subjectsWithBenchmark: ComparisonSubject[] = useMemo(
    () =>
      compact([
        benchmark && {
          analysisSubject: benchmark,
          isBenchmark: true,
          color: Colors.Black,
          isHovered: false,
          index: 0, // Index for column sorting by the order of subject addition
        },
        ...subjects.map((subject, idx) => ({
          analysisSubject: subject,
          color: getItemColor(Colors, convertAnalysisSubjectToItemType(subject)),
          isHovered: false,
          isBenchmark: false,
          index: idx + (benchmark ? 1 : 0),
        })),
      ]),
    [benchmark, subjects, Colors],
  );

  const subjectsWithHover = useMemo(
    () => subjectsWithBenchmark.map((item, idx) => ({ ...item, isHovered: idx === focusedIdx })),
    [focusedIdx, subjectsWithBenchmark],
  );

  // When analyses changes we should decide how to update date range
  useEffect(() => {
    if (analyses && analyses.maxStartTime && analyses.maxEndTime) {
      setMaxDateRange({
        from: analyses.maxStartTime,
        to: analyses.maxEndTime,
      });
      if (analyses.maxFrequency && granularity !== FREQUENCY_DATEPICKER_MODES[analyses.maxFrequency]) {
        setGranularity(FREQUENCY_DATEPICKER_MODES[analyses.maxFrequency]);
      }
    }
  }, [analyses, granularity]);

  const controlsDisabled = useMemo(
    () => !!analyses?.subjectErrors?.some((error) => error?.code === SupportedErrorCodes.AnalysisLimitErrorCode),
    [analyses],
  );

  return {
    subjects: subjectsWithHover,
    analyses,
    setHovered,
    dateRange,
    updateDateRange,
    maxDateRange,
    granularity,
    setGranularity,
    addSubject,
    clearSubjects,
    removeSubject,
    benchmark,
    updateBenchmark,
    relative,
    toggleRelative,
    analysisIsLoading,
    isAddDisabled: subjects.length >= MAX_SUBJECTS,
    controlsDisabled,
    maxSelections: MAX_SUBJECTS - subjects.length + 1,
    trackingId,
    analysisIsFetching,
    analysisUpdatedTime,
    isPivoted,
    togglePivoted,
    hoveredMetricIdx,
    // view
    isViewInitLoading,
    currentViewName,
    hasUnsavedChanges,
    savedId,
    onSave,
    onSaveAs,
    onRename,
    noAccessModifiedView,
  };
};

export default useComparison;
