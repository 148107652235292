import React from 'react';
import type {
  Portfolio,
  OptimizationConfiguration,
  PortfolioSummary,
  AllocationConstraint,
  SimpleFund,
  InvestmentFactorForecast,
  PortfolioPolicy,
  AnalysisRequest,
} from 'venn-api';
import {
  getSpecificPortfolioV3,
  getDraftSummary,
  analysis,
  runAdHocMultiOptimization,
  getPortfolioPolicyByPortfolioId,
} from 'venn-api';
import type { OptimizationErrorType, MissedTarget } from 'venn-components';
import { OptimalPortfolioContext } from 'venn-components';
import { FS } from 'venn-utils';
import { isNil } from 'lodash';
import useInvestmentForecasts from '../logic/useInvestmentForecasts';

interface OptimalPortfolioContextStoreProps {
  portfolio?: Portfolio;
  investmentForecasts?: { [key: string]: InvestmentFactorForecast };
  onForecastUpdate?: (fundId: string) => void;
}

interface OptimalPortfolioContextStoreState {
  loading: boolean;
  error: OptimizationErrorType | null;
  optimalPortfolio: Portfolio | null;
  config: Partial<OptimizationConfiguration> | null;
  missedTarget: MissedTarget | null;
  baselinePortfolioId: number | null;
  canRerunOptimization: boolean;
  customAllocationConstraints: AllocationConstraint[];
  portfolioPolicy: PortfolioPolicy | undefined;
}

class OptimalPortfolioContextStore extends React.PureComponent<
  React.PropsWithChildren<OptimalPortfolioContextStoreProps>,
  OptimalPortfolioContextStoreState
> {
  state: OptimalPortfolioContextStoreState = {
    loading: false,
    error: null,
    optimalPortfolio: null,
    config: null,
    missedTarget: null,
    baselinePortfolioId: null,
    canRerunOptimization: false,
    customAllocationConstraints: [],
    portfolioPolicy: undefined,
  };

  static getDerivedStateFromProps(
    nextProps: OptimalPortfolioContextStoreProps,
    prevState: OptimalPortfolioContextStoreState,
  ) {
    const { portfolio } = nextProps;
    const { baselinePortfolioId } = prevState;
    const portfolioId = portfolio?.id ?? null;

    if (baselinePortfolioId !== portfolioId) {
      return {
        loading: false,
        error: null,
        optimalPortfolio: null,
        config: null,
        missedTarget: null,
        baselinePortfolioId: portfolioId,
        canRerunOptimization: false,
        customAllocationConstraints: [],
        portfolioPolicy: undefined,
      };
    }

    return null;
  }

  /**
   * When an external optimization was run, we need to:
   *  - fetch the portfolio
   *  - update our `optimalPortfolio` with it
   *  - update our `config`: possibly there were changes to:
   *     - allocation constraints
   *     - factor constraints
   *     - target
   *     - max volatility
   *     - min return
   *  - update our `missedTarget`
   */
  setOptimizationResult = async (
    resultPortfolioId: number,
    resultSummary: PortfolioSummary,
    resultConfig: OptimizationConfiguration,
    resultAdHocPortfolio?: Portfolio,
    customAllocationConstraints: AllocationConstraint[] = [],
  ) => {
    const { customAllocationConstraints: currentConstraints } = this.state;
    if (resultAdHocPortfolio) {
      this.setState({
        optimalPortfolio: resultAdHocPortfolio,
        loading: false,
        error: null,
        missedTarget: getMissedTarget(resultConfig, resultSummary),
        config: resultConfig,
        customAllocationConstraints: [
          // Apply new constraints from the optimizer modal, but keep constraints for items not present in the
          // given optimization config (we want to keep constraints for nodes that were deleted in case of a RESET).
          ...filterOutExistingConstraints(currentConstraints, customAllocationConstraints),
          ...customAllocationConstraints,
        ],
      });
      return;
    }

    if (!resultPortfolioId || !resultSummary) {
      // Intentionally don't reset the current config to keep it for the next time the optimization is initiated.
      this.setState({
        optimalPortfolio: null,
        loading: false,
        error: null,
        missedTarget: null,
      });
      return;
    }

    this.setState({ loading: true });

    try {
      const draftPortfolio = (await getSpecificPortfolioV3(resultPortfolioId)).content;
      this.setState({
        optimalPortfolio: draftPortfolio,
        error: null,
        missedTarget: getMissedTarget(resultConfig, resultSummary),
        config: resultConfig,
      });
    } catch (e) {
      this.setState({
        error: 'OPTIMIZATION_FAILED',
        optimalPortfolio: null,
        missedTarget: null,
        config: resultConfig,
      });
    }

    this.setState({ loading: false });
  };

  runPortfolioLabOptimization = async () => {
    const { portfolio } = this.props;
    if (!portfolio) {
      // Can't optimizeAdHoc without a portfolio
      return;
    }

    this.setState({ loading: true });

    const { portfolioPolicy: currentPolicy } = this.state;
    let portfolioPolicy: PortfolioPolicy | undefined = currentPolicy;
    if (isNil(portfolioPolicy)) {
      portfolioPolicy = await fetchPortfolioPolicy(portfolio.id);
    }
    const defaultMaxVolatility = await fetchDefaultMaxVolatility(portfolio);

    // Can't auto-optimize without max vol value
    if (isNil(defaultMaxVolatility)) {
      this.setState({
        optimalPortfolio: null,
        missedTarget: null,
        loading: false,
        error: 'NO_DEFAULT_CONSTRAINTS',
      });
      return;
    }

    try {
      const { content } = await runAdHocMultiOptimization({
        constraints: portfolioPolicy.constraints,
        portfolio,
        optimizationTarget: 'MAXIMIZE_RETURN',
        maxVolatility: defaultMaxVolatility,
        relaxationsPercent: 0.1,
      });

      const optimalPortfolio = content.optimizedPortfolio.portfolio;
      if (isNil(optimalPortfolio)) {
        throw new Error('Empty optimization response');
      }

      this.setState({
        optimalPortfolio,
        missedTarget: null,
        error: null,
        canRerunOptimization: false,
      });
    } catch (e) {
      this.setState({
        error: 'OPTIMIZATION_FAILED',
        optimalPortfolio: null,
        missedTarget: null,
        canRerunOptimization: false,
      });
    }

    this.setState({ loading: false });
  };

  /**
   * This is for requesting an ad-hoc optimization to be run by the OptimizationContextStore.
   * We need to optimizeAdHoc, and then updated our `optimalPortfolio` with the results.
   */
  optimizeAdHoc = async () => {
    await this.runPortfolioLabOptimization();
  };

  setCanRerunOptimization = (canRerunOptimization: boolean) => this.setState({ canRerunOptimization });

  updateOptimizationContextAfterSave = (
    newPortfolio: Portfolio,
    nodeIdMapper: Map<number, number> = new Map(),
    clearAllNew = false,
  ) => {
    const { config, customAllocationConstraints } = this.state;

    this.setState(
      {
        canRerunOptimization: false,
        config: {
          ...config,
          portfolio: newPortfolio,
          allocationConstraints: [],
        },
        customAllocationConstraints: customAllocationConstraints.map((constraint: AllocationConstraint) => ({
          ...constraint,
          portfolioId: (constraint.portfolioId && nodeIdMapper.get(constraint.portfolioId)) || constraint.portfolioId,
          newFund: clearAllNew ? false : constraint.newFund,
        })),
      },
      () => {
        this.optimizeAdHoc();
      },
    );
  };

  render() {
    const { loading, error, optimalPortfolio, missedTarget, canRerunOptimization, customAllocationConstraints } =
      this.state;
    const { investmentForecasts, onForecastUpdate } = this.props;

    return (
      <OptimalPortfolioContext.Provider
        // this is good enough for the pure class component, disabling lint rule
        // https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          hasAccessToOptimization: FS.has('optimization'),
          optimalPortfolio,
          loading,
          optimizationError: error ?? undefined,
          setOptimizationResult: this.setOptimizationResult,
          missedTarget,
          canRerunOptimization,
          setCanRerunOptimization: this.setCanRerunOptimization,
          optimizeAdHoc: this.optimizeAdHoc,
          updateOptimizationContextAfterSave: this.updateOptimizationContextAfterSave,
          customAllocationConstraints,
          investmentForecasts,
          onForecastUpdate,
        }}
      >
        {this.props.children}
      </OptimalPortfolioContext.Provider>
    );
  }
}

const WithContextProps: React.FC<React.PropsWithChildren<OptimalPortfolioContextStoreProps>> = (props) => {
  const { investmentForecasts } = useInvestmentForecasts(props.portfolio);

  return <OptimalPortfolioContextStore {...props} investmentForecasts={investmentForecasts} />;
};

export default WithContextProps;

function getMissedTarget(config: Partial<OptimizationConfiguration>, summary: PortfolioSummary): MissedTarget | null {
  if (!config || config.targetId === config.resultTargetId) {
    return null;
  }

  if (!isNil(config.minReturn) && visibleValue(config.minReturn) > visibleValue(summary.annualizedTotalReturn)) {
    return {
      targetMetric: 'Return',
      value: config.minReturn,
    };
  }

  if (!isNil(config.maxVolatility) && visibleValue(config.maxVolatility) < visibleValue(summary.annualizedVolatility)) {
    return {
      targetMetric: 'Volatility',
      value: config.maxVolatility,
    };
  }

  return null;
}

function visibleValue(preciseValue: number): number {
  return Number.parseFloat((preciseValue * 100).toFixed(1));
}

function filterOutExistingConstraints(
  filterFrom: AllocationConstraint[],
  existingIn: AllocationConstraint[],
): AllocationConstraint[] {
  const constraintHashingFn = (fund?: SimpleFund, portfolioId?: number) => `${portfolioId}#${fund?.id ?? ''}`;
  const existingConstraints = new Set(
    existingIn.map(({ fund, portfolioId }) => constraintHashingFn(fund, portfolioId)),
  );
  return filterFrom.filter(({ fund, portfolioId }) => !existingConstraints.has(constraintHashingFn(fund, portfolioId)));
}

const fetchPortfolioPolicy = async (portfolioId: number): Promise<PortfolioPolicy> => {
  try {
    const { content } = await getPortfolioPolicyByPortfolioId(portfolioId);
    return content;
  } catch (e) {
    return {
      constraints: [],
      portfolioId,
    };
  }
};

const fetchDefaultMaxVolatility = async (portfolio: Portfolio): Promise<number | undefined> => {
  try {
    // Use forecast summary values as default, if available
    const forecastedVolatility = (await getDraftSummary(portfolio)).content?.annualizedVolatility;
    if (!isNil(forecastedVolatility)) {
      return forecastedVolatility;
    }
    throw new Error('No forecasted volatility');
  } catch (_) {
    // If not available, try using historical values
    const historicalPerformanceResponse = (await analysis(getHistoricalPerformanceRequestConfig(portfolio))).content
      .analyses?.[0]?.historicalPerformanceSummary?.[0];

    return historicalPerformanceResponse?.volatility ?? undefined;
  }
};

function getHistoricalPerformanceRequestConfig(portfolio: Portfolio): Partial<AnalysisRequest> {
  return {
    analyses: [
      {
        analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
        relative: false,
        scenarios: [],
        selectedNotablePeriods: [],
      },
    ],
    subjects: [
      {
        subjectType: 'PORTFOLIO',
        comparisonType: 'PRIMARY',
        portfolio,
        id: portfolio.id.toString(),
        primary: true,
        isPrivate: false,
      },
    ],
  };
}
