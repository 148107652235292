import React from 'react';
import { noop } from 'lodash';

type PortfolioError = {
  portfolioId: number;
};
type Timestamp = number;
export type HistoricalPortfolioErrorState = Record<Timestamp, PortfolioError[]>;

export type MultiHistoricalPortfolioContextValue = {
  selectedDate: Date;
  selectDate: (date: Date) => void;
  allDates: Date[];
  errors: HistoricalPortfolioErrorState;
};

const defaultMultiHistoricalPortfolioContextValue: MultiHistoricalPortfolioContextValue = {
  selectDate: noop,
  selectedDate: new Date(Date.UTC(0)),
  allDates: [],
  errors: {},
};

export const MultiHistoricalPortfolioContext = React.createContext<MultiHistoricalPortfolioContextValue>(
  defaultMultiHistoricalPortfolioContextValue,
);
MultiHistoricalPortfolioContext.displayName = 'MultiHistoricalPortfolioContext';
