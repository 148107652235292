import React, { useRef } from 'react';
import styled from 'styled-components';
import { Headline2, Loading, ZIndex } from 'venn-ui-kit';
import { Dates, logExceptionIntoSentry, withSuspense } from 'venn-utils';
import { useQuery } from '@tanstack/react-query';
import type { PrivateFundTransaction } from 'venn-api';
import { getPrivateFundTransactions } from 'venn-api';
import { compact, isNil } from 'lodash';
import {
  defaultPrivatesColumnStyling,
  privatesTransactionsTableColumns,
} from '../../../home-page/src/components/privatesColumns';
import { DataGrid } from 'venn-components';
import type { AgGridReact } from 'ag-grid-react';
import { getFormattedValue } from '../../../home-page/src/components';

interface InvestmentPortfoliosProps {
  fundId: string;
}
const FIVE_MINUTES = 1000 * 60 * 5;
const INVESTMENT_TRANSACTIONS_KEY = 'investment_transactions_key';

enum PrivateFundTransactionType {
  CapitalCall = 'Capital Call',
  Distribution = 'Distribution',
  Value = 'Value',
  CumulativeContribution = 'Cumulative Contribution',
  CumulativeDistribution = 'Cumulative Distribution',
}

interface IndividualPrivateFundTransaction {
  type: PrivateFundTransactionType;
  date: string;
  amount: string;
}

const splitToIndividualTransactions = (transactions: PrivateFundTransaction[]): IndividualPrivateFundTransaction[] => {
  return transactions
    .map((transaction) => {
      return compact([
        isNil(transaction.contribution)
          ? null
          : {
              type: PrivateFundTransactionType.CapitalCall,
              date: Dates.toDayMonthYear(transaction.transactionDate) ?? '—',
              amount: getFormattedValue(transaction.contribution),
            },
        isNil(transaction.distribution)
          ? null
          : {
              type: PrivateFundTransactionType.Distribution,
              date: Dates.toDayMonthYear(transaction.transactionDate) ?? '—',
              amount: getFormattedValue(transaction.distribution),
            },
        isNil(transaction.nav)
          ? null
          : {
              type: PrivateFundTransactionType.Value,
              date: Dates.toDayMonthYear(transaction.transactionDate) ?? '—',
              amount: getFormattedValue(transaction.nav),
            },
        isNil(transaction.cumulativeContribution)
          ? null
          : {
              type: PrivateFundTransactionType.CumulativeContribution,
              date: Dates.toDayMonthYear(transaction.transactionDate) ?? '—',
              amount: getFormattedValue(transaction.cumulativeContribution),
            },
        isNil(transaction.cumulativeDistribution)
          ? null
          : {
              type: PrivateFundTransactionType.CumulativeDistribution,
              date: Dates.toDayMonthYear(transaction.transactionDate) ?? '—',
              amount: getFormattedValue(transaction.cumulativeDistribution),
            },
      ]);
    })
    .flat();
};
export const PrivateInvestmentTransactions = withSuspense(
  <Loading title="Transactions are loading... " />,
  ({ fundId }: InvestmentPortfoliosProps) => {
    const gridRef = useRef<AgGridReact>(null);
    const { data: transactions = [] } = useQuery<IndividualPrivateFundTransaction[]>(
      [INVESTMENT_TRANSACTIONS_KEY, fundId],
      async () => {
        const response = await getPrivateFundTransactions(fundId);
        return splitToIndividualTransactions(response.content);
      },
      {
        onError: (error) => {
          return logExceptionIntoSentry(error as Error);
        },
        suspense: true,
        staleTime: FIVE_MINUTES,
      },
    );

    if (!transactions.length) {
      return (
        <EmptyStateContainer>
          <Headline2>This fund doesn't have any transactions reported.</Headline2>
        </EmptyStateContainer>
      );
    }

    return (
      <Container>
        <DataGrid
          columnDefs={privatesTransactionsTableColumns}
          gridRef={gridRef}
          defaultColDef={{
            resizable: false,
            lockPinned: true,
            sortable: false,
            suppressHeaderMenuButton: true,
            suppressMovable: true,
            editable: false,
            headerClass: 'ag-right-aligned-header',
            cellStyle: defaultPrivatesColumnStyling,
          }}
          domLayout="autoHeight"
          rowData={transactions ?? []}
          headerHeight={41}
          rowHeight={37}
          suppressPaginationPanel
          suppressRowClickSelection
          suppressContextMenu
        />
      </Container>
    );
  },
);

const Container = styled.div`
  margin-top: 50px;
  .ag-header-cell {
    padding: 2px 4px;
    font-weight: bold;
  }
  .ag-body-viewport {
    height: 600px;
    overflow-y: auto;
  }
  .ag-header {
    width: auto;
    z-index: ${ZIndex.Front};
  }
`;

const EmptyStateContainer = styled(Container)`
  text-align: center;
  h2 {
    text-align: center;
  }
`;
