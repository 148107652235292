import React, { useCallback, useContext, useMemo } from 'react';
import { isNaN, keys } from 'lodash';
import styled from 'styled-components';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { FactorWithNonSerializedReturns as FactorEntity, FactorLensWithReturns } from 'venn-api';
import { FactorLensesContext, UserContext } from 'venn-components';
import { Button } from 'venn-ui-kit';
import { navigateToFactorInsights, Routes, useHasFF } from 'venn-utils';
import Page from '../shared/Page';
import { PageHeader } from '../shared/page-header';
import type { DeepDiveContent, Factor } from './types';
import PageContent from './PageContent';
import { getDeepDiveContent } from './content';
import DeepDiveSideMenu from './DeepDiveSideMenu';

interface UrlParams {
  factor?: string;
}

const DeepDivePage = ({ history, match }: RouteComponentProps<UrlParams>) => {
  const factorLens = useContext(FactorLensesContext).primaryFactorLens;
  const { profileSettings } = useContext(UserContext);
  const currency = profileSettings?.organization?.currency;
  const hasNewFactorLens = useHasFF('mstar_indices_factors_ff');
  const content = useMemo(() => getDeepDiveContent(hasNewFactorLens), [hasNewFactorLens]);
  const selectedFactor: Factor = useParseFactor(match.params.factor, factorLens, content);
  const redirectToInsights = useCallback(() => {
    navigateToFactorInsights(history, content[selectedFactor].name);
  }, [history, selectedFactor, content]);
  const redirectToFactor = useCallback(
    (factor: string) => {
      history.push(`${Routes.FACTOR_LENS_DEEP_DIVE}/${factor}`);
    },
    [history],
  );
  const factorEntity = getFactorEntity(factorLens, selectedFactor);
  const excludeFactor: Factor[] = useMemo(() => {
    return currency !== 'USD' && currency !== 'GBP' ? ['local-inflation'] : [];
  }, [currency]);

  const menu = useMemo(() => {
    return (Object.keys(content) as Factor[])
      .filter((key: Factor) => getFactorEntity(factorLens, key))
      .filter((key: Factor) => !excludeFactor.includes(key))
      .map((key: Factor) => ({ label: content[key].name, value: key }));
  }, [factorLens, excludeFactor, content]);

  return (
    <Page>
      <TitleWrapper>
        <PageHeader title="Factor Deep Dive" />
        <InsightsButton className="qa-view-factor-insights" onClick={redirectToInsights}>
          View Factor Insights
        </InsightsButton>
      </TitleWrapper>
      <PageLayout>
        <DeepDiveSideMenu<Factor>
          title="Factors"
          items={menu}
          selectedItem={selectedFactor}
          onClick={redirectToFactor}
        />
        <PageContentContainer>
          <PageContent factor={selectedFactor} factorEntity={factorEntity} />
        </PageContentContainer>
      </PageLayout>
    </Page>
  );
};

function useParseFactor(
  factor: string | undefined,
  factorLens: FactorLensWithReturns | undefined,
  content: DeepDiveContent,
): Factor {
  const factors = keys(content) as Factor[];

  if (!factor) {
    return 'equity';
  }

  if (content[factor]) {
    return factor as Factor;
  }

  let factorEntity: FactorEntity | undefined;

  if (factorLens && !isNaN(+factor)) {
    factorEntity = factorLens.factors.find((f) => f.id === +factor);
  } else {
    factorEntity = factorLens?.factors.find((f) => f.name.toLowerCase() === factor.toLowerCase());
  }

  if (factorEntity) {
    const factorEnum = factors.find((f) => content[f].name.toLowerCase() === factorEntity?.name.toLowerCase());
    if (factorEnum) {
      return factorEnum;
    }
  }

  return 'equity';
}

function getFactorEntity(factorLens: FactorLensWithReturns | undefined, factor: Factor): FactorEntity | undefined {
  if (!factorLens) {
    return undefined;
  }
  const lowerCaseFactorName = factor.toLowerCase().replace(/\-/g, ' ');
  return factorLens.factors.find((factorEntity) => factorEntity.name.toLowerCase() === lowerCaseFactorName);
}

const PageLayout = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InsightsButton = styled(Button)`
  width: 210px;
  height: 40px;
`;

const PageContentContainer = styled.div`
  margin-left: 40px;
  flex: 1;
`;

export default withRouter(DeepDivePage);
