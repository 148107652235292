import { compact } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from 'venn-components';
import type { TableRowMenuOption } from 'venn-ui-kit';
import { TableRowOptionsMenu, Tooltip } from 'venn-ui-kit';
import { MANAGE_DATA_SECTION, type ObjectId, navigateToManageDataPage, useHasFF, useModal } from 'venn-utils';
import ConfigureBenchmarksModal from './ConfigureBenchmarksModal';
import PrivateDeleteConfirmationModal from './PrivateDeleteConfirmationModal';
import type { PrivatesTableRow } from './privatesColumns';

interface PrivatesActionMenuProps {
  item: PrivatesTableRow | undefined;
  clearSelectedItems: () => void;
  updateData: () => void;
}

export const PrivatesActionMenu = ({ item, updateData, clearSelectedItems }: PrivatesActionMenuProps) => {
  const history = useHistory();
  const { hasPermission } = useContext(UserContext);
  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const [privateDeleteConfirmationModalOpen, openPrivateDeleteConfirmationModal, closePrivateDeleteConfirmationModal] =
    useModal();
  const [showConfigureBenchmarksModal, openConfigureBenchmarkModal, closeConfigureBenchmarkModal] = useModal();
  const onDeleteComplete = () => {
    clearSelectedItems();
    updateData();
    closePrivateDeleteConfirmationModal();
  };

  const privateObject: ObjectId =
    item?.itemType === 'INVESTMENT' ? { privateFundId: item?.id } : { privatePortfolioId: item?.id };
  const options: TableRowMenuOption[] = item
    ? compact([
        {
          label: 'Manage Data',
          onClick: () => navigateToManageDataPage(history, privateObject, 'Library', false),
        },
        hasPermission('CONFIGURE_BENCHMARKS') && {
          label: 'Configure Benchmarks',
          onClick: openConfigureBenchmarkModal,
        },
        hasPrivatesCashFlowSettingsFF && {
          label: 'CF Pacing Model Parameters',
          onClick: () =>
            navigateToManageDataPage(
              history,
              privateObject,
              'Library',
              false,
              MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS,
            ),
        },
        {
          label: 'Delete',
          disabled: !item.userUploaded || (item.ownerId ? item.ownerId < 0 : false),
          onClick: () => {
            openPrivateDeleteConfirmationModal();
          },
          renderWrapper: (content) => (
            <Tip
              block
              usePortal
              content={
                (!item.userUploaded || (item.ownerId ? item.ownerId < 0 : false)) &&
                'Cannot delete non user created items.'
              }
            >
              {content}
            </Tip>
          ),
        },
      ])
    : [];

  return (
    <span data-testid="privates-action-menu">
      <TableRowOptionsMenu
        usePortal
        // adjust offset if adding different menu options
        portalProps={{
          rightOffset: 150,
        }}
        options={options}
        menuClassName="privates-actions-menu-dropdown"
        triggerClassName="privates-actions-menu-trigger"
      />

      {privateDeleteConfirmationModalOpen && item && (
        <PrivateDeleteConfirmationModal
          items={[item]}
          onCancelDelete={closePrivateDeleteConfirmationModal}
          onDeleteComplete={onDeleteComplete}
        />
      )}
      {showConfigureBenchmarksModal && hasPermission('CONFIGURE_BENCHMARKS') && item && (
        <ConfigureBenchmarksModal
          name={item.name}
          itemId={{
            privateFundId: item.itemType === 'INVESTMENT' ? item.id : undefined,
            privatePortfolioId: item.itemType === 'PORTFOLIO' ? item.id : undefined,
          }}
          onSubmit={closeConfigureBenchmarkModal}
          onClose={closeConfigureBenchmarkModal}
          data-testid="qa-configure-benchmarks-modal"
          benchmarkContext={item.itemType === 'INVESTMENT' ? 'private-investment' : 'private-portfolio'}
        />
      )}
    </span>
  );
};

const Tip = styled(Tooltip)`
  height: 100%;
`;
