import React, { useCallback } from 'react';
import JumboCheckboxTrigger from '../triggers/JumboCheckboxTrigger';
import type { JumboCheckboxMenuProps } from '../menus/JumboCheckboxMenu';
import { default as JumboCheckboxMenu } from '../menus/JumboCheckboxMenu';
import BaseDropMenu from './BaseDropMenu';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';

interface JumboCheckboxDropMenuProps<T> extends JumboCheckboxMenuProps<T>, BaseDropMenuProps<T>, MenuContainerProps {}

export const JumboCheckboxDropMenu = <T,>({
  label,
  items,
  className,
  openByDefault,
  usePortal,
  disabled,
  width,
  height,
  innerRef,
  onChange,
}: JumboCheckboxDropMenuProps<T>) => {
  const triggerComponent = useCallback(
    (expanded, _, onToggle, handleKeyEsc, handleKeyEnter, handleKeyUp, handleKeyDown) => (
      <JumboCheckboxTrigger
        className={className}
        disabled={disabled}
        label={label}
        expanded={expanded}
        innerRef={innerRef}
        onClick={onToggle}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onKeyEsc={handleKeyEsc}
        onKeyEnter={handleKeyEnter}
      />
    ),
    [label, className, disabled, innerRef],
  );

  const menuComponent = useCallback(
    (_highlightedItem, _onCollapse, menuClassName) => (
      <JumboCheckboxMenu className={menuClassName} width={width} height={height} items={items} onChange={onChange} />
    ),
    [items, onChange, width, height],
  );

  return (
    <BaseDropMenu
      openByDefault={openByDefault}
      usePortal={usePortal}
      filteredItems={items}
      triggerComponent={triggerComponent}
      menuComponent={menuComponent}
    />
  );
};

export default JumboCheckboxDropMenu;
