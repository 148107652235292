import type {
  AlignValue,
  AxisLabelsFormatterContextObject,
  AxisTypeValue,
  SeriesOptionsType,
  TooltipFormatterContextObject,
} from 'highcharts';
import type { Typography, VennColors } from 'venn-ui-kit';
import { HighchartZIndex, REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import HighchartsUtils from '../../../../utils/highcharts';
import getTooltipFormatter from '../../../../charts/analysis-charts/factor-charts/components/getTooltipFormatter';
import type { LineChartLegendItem } from '../../../../charts/analysis-charts/factor-charts/components/parseData';
import type { AnyDuringEslintMigration } from 'venn-utils';

const dottedMissingSeries = (items: LineChartLegendItem[]): SeriesOptionsType[] => {
  const resultArray: SeriesOptionsType[] = [];

  for (const item of items) {
    const seriesDotted = {
      type: 'line' as const,
      name: item.name,
      data: item.mainSerie ?? [],
      zIndex: HighchartZIndex.Serie.Front,
      color: item.color,
      lineWidth: item.lineWidth,
      connectNulls: true,
      dashStyle: 'Dot' as const,
      seriesTypes: item.seriesTypes,
    };
    const seriesData = {
      type: 'line' as const,
      name: item.name,
      data: item.mainSerie ?? [],
      zIndex: HighchartZIndex.Serie.Front,
      color: item.color,
      lineWidth: item.lineWidth,
      connectNulls: false,
      linkedTo: ':previous',
      seriesTypes: item.seriesTypes,
    };
    resultArray.push(seriesDotted, seriesData);
  }

  return resultArray;
};

export const getFactorTrendChartConfig = (
  items: LineChartLegendItem[],
  typography: Typography,
  Colors: VennColors,
  percentageValue = false,
  axisFontSize: number,
): Highcharts.Options => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'line',
    marginTop: 0,
  },
  title: {
    text: '',
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
    },
    series: {
      events: {
        legendItemClick: () => false,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },

  xAxis: [
    {
      type: 'datetime' as AxisTypeValue,
      gridLineWidth: 1,
      minorTickLength: 0,
      tickLength: 0,
      startOnTick: true,
      endOnTick: false,
      showFirstLabel: true,
      labels: {
        y: 20,
        useHTML: true,
        formatter: HighchartsUtils.endOfTheYearFormatter,
        style: {
          fontFamily: typography.fontFamily,
          fontSize: axisFontSize ? `${axisFontSize}pt` : REPORT_LAB_FONT_BODY,
        },
      },
    },
  ],
  yAxis: [
    {
      title: { text: null },
      gridLineWidth: 1,
      startOnTick: true,
      endOnTick: true,
      showFirstLabel: true,
      showLastLabel: false,
      minPadding: 0,
      labels: {
        align: 'right' as AlignValue,
        formatter(this: AxisLabelsFormatterContextObject) {
          const { value } = this;
          if (!percentageValue) {
            // TODO: (VENN-20577 / TYPES) formatter expects a string but we return string|number here. Maybe we can just do return `${value}`?
            return value as AnyDuringEslintMigration;
          }

          return `${(value * 100).toFixed(1)}%`;
        },
        style: {
          fontFamily: typography.fontFamily,
          fontSize: axisFontSize ? `${axisFontSize}pt` : REPORT_LAB_FONT_BODY,
        },
      },
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    crosshairs: true,
    backgroundColor: Colors.TransparentBlack,
    style: {
      color: Colors.White,
    },
    formatter(this: TooltipFormatterContextObject) {
      const { series, x, y } = this;

      // TODO: (VENN-20577 / TYPES) no clue where this comes from or what the type should be
      const userOptions = (series as AnyDuringEslintMigration)?.userOptions;

      return getTooltipFormatter(
        series.name,
        x,
        y,
        typography,
        userOptions?.seriesTypes ?? {},
        !percentageValue,
        userOptions?.data ?? [],
      );
    },
    useHTML: true,
  },
  series: dottedMissingSeries(items),
});
