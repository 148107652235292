import React, { useMemo, useRef, useState } from 'react';
import { ConditionalOverlay, DataGrid } from 'venn-components';
import {
  defaultPrivatesColumnStyling,
  getPrivatesPerformanceTableColumns,
} from '../../../home-page/src/components/privatesColumns';
import type { AgGridReact } from 'ag-grid-react';
import type { PrivateFundPerformance } from 'venn-api';
import { getPrivateFundPerformance } from 'venn-api';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { ZIndex } from 'venn-ui-kit';

interface PrivateInvestmentPerformanceDataProps {
  fundId: string;
}
const PRIVATE_FUND_PERF = 'private_fund_perf';
export const PrivateInvestmentPerformanceData = ({ fundId }: PrivateInvestmentPerformanceDataProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = useMemo(() => {
    return getPrivatesPerformanceTableColumns();
  }, []);

  const [rowData, setRowData] = useState<PrivateFundPerformance[] | undefined>(undefined);
  const { isLoading } = useQuery({
    queryKey: [PRIVATE_FUND_PERF, fundId],
    queryFn: () => getPrivateFundPerformance(fundId),
    onSuccess: (response) => {
      setRowData(response.content);
    },
    onError: () => setRowData(undefined),
  });
  return (
    <Wrapper>
      <ConditionalOverlay condition={isLoading} className="qa-private-investment-performance-table">
        <DataGrid
          columnDefs={columnDefs}
          gridRef={gridRef}
          defaultColDef={{
            resizable: false,
            lockPinned: true,
            sortable: false,
            suppressHeaderMenuButton: true,
            suppressMovable: true,
            editable: false,
            headerClass: 'ag-right-aligned-header',
            cellStyle: defaultPrivatesColumnStyling,
          }}
          domLayout="autoHeight"
          rowData={rowData ?? []}
          headerHeight={41}
          rowHeight={37}
          suppressPaginationPanel
          suppressRowClickSelection
          suppressContextMenu
          suppressNoRowsOverlay={rowData === undefined}
          overlayNoRowsTemplate="No performance data to show"
        />
      </ConditionalOverlay>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 50px;
  .ag-header-cell {
    padding: 2px 4px;
    font-weight: bold;
    &[col-id='Called'] {
      padding-right: 10px;
    }
  }
  .ag-body-viewport {
    overflow-y: auto;
  }
  .ag-header {
    width: auto;
    z-index: ${ZIndex.Front};
  }
`;
