import React, { useCallback, useContext, useMemo, useRef } from 'react';
import PageHeader from '../print-layout/PageHeader';
import type { Layout } from 'react-grid-layout';
import ReactGridLayout from 'react-grid-layout';
import { GetColor, Icon, Shimmer, ZIndex } from 'venn-ui-kit';
import DeprecatedBlock from '../block/DeprecatedBlock';
import PageFooter from '../print-layout/PageFooter';
import type { Page } from 'venn-components';
import {
  dimensions,
  RL_GRID_ROW_HEIGHT,
  StudioContext,
  StudioShimmerBlock,
  StudioSidePanelContext,
  UserContext,
  calcGridItemWidth,
  useAppPrintMode,
} from 'venn-components';
import styled, { css } from 'styled-components';
import { analyticsService, SpecialCssClasses } from 'venn-utils';
import { useUpdatePage } from './Shared';
import { BuilderBlockWrapper } from '../block/shared';
import { waitForAll, useRecoilValue } from 'recoil';
import { blockOverflowErrorState, studioPrintOrientationType, reportZoom, allBlockIdsState } from 'venn-state';
import { triggerResizeEvent } from '../../logic/useSelectionSystem';
import { isEqual, isUndefined, omitBy } from 'lodash';
import ReactGridLayoutErrorBoundary from './ReactGridLayoutErrorBoundary';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const DRAG_HANDLE_CLASS = 'drag-handle';
const DROP_ITEM_ID = 'dropping_item';
const DROPPING_ITEM = {
  i: DROP_ITEM_ID,
  w: 2,
  h: 5,
};

interface GridPageProps {
  focusBlockIndex: React.MutableRefObject<number | undefined>;
  focusBlockRef: React.RefObject<HTMLDivElement>;
  pageNumber: number;
  page: Page;
  totalPages: number;
}

/** Component representing a grid of Report Lab blocks, including functionality to re-arrange those blocks. */
const GridPage = ({ pageNumber, totalPages, page, focusBlockIndex, focusBlockRef }: GridPageProps) => {
  const { hasPermission } = useContext(UserContext);
  const { inPrintMode } = useAppPrintMode();
  const { onInsertBlock, draggingBlock } = useContext(StudioContext);
  const { onSelectBlock } = useContext(StudioSidePanelContext);
  const updatePage = useUpdatePage(pageNumber);
  const printOrientationType = useRecoilValue(studioPrintOrientationType);
  const gridSettings = dimensions[printOrientationType].gridSettings;
  const transformScale = useRecoilValue(reportZoom);
  const blockIds = useRecoilValue(allBlockIdsState);

  const pageFooterRef = useRef<HTMLDivElement | null>(null);

  const onPageLayoutChange = useCallback(
    (givenLayout: Layout[]) => {
      const newLayout = givenLayout.map((layout) => omitBy(layout, isUndefined)) as unknown as Layout[];
      if (newLayout.length !== page.layout.length || newLayout.some((newL, idx) => !isEqual(newL, page.layout[idx]))) {
        updatePage({ layout: newLayout });
      }
    },
    [updatePage, page.layout],
  );

  const onDrop = useCallback(
    (newLayout: Layout[]) => {
      draggingBlock &&
        onInsertBlock(
          {
            label: '',
            value: draggingBlock,
          },
          blockIds.length,
          {
            pageNumber,
            layout: newLayout,
          },
        );
      analyticsService.ctaClicked({
        purpose: 'insert studio block',
        locationOnPage: `${draggingBlock?.customBlockType} block dragged to page`,
      });
    },
    [draggingBlock, onInsertBlock, pageNumber, blockIds.length],
  );

  const { width, height } = gridSettings;
  const pageLayout = page.layout;
  const overflowErrors = useRecoilValue(waitForAll(pageLayout.map((layout) => blockOverflowErrorState(layout.i))));
  const draggingOver = pageLayout.some((layout) => layout.i === DROP_ITEM_ID);
  const style = useMemo(() => {
    return {
      width,
      height,
      transformOrigin: 'top left',
    };
  }, [width, height]);

  const margin = gridSettings.margin;
  const originalContextValue = useContext(StudioContext);

  // This is pretty hacky, would be good to refactor. Maybe to recoil.
  const contextValue = useMemo(
    () => ({
      ...originalContextValue,
      inPrintMode: true,
    }),
    [originalContextValue],
  );

  const onDragOrResize = (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const viewId = blockIds.find((id) => newItem.i === id);
    viewId && onSelectBlock(viewId, { scrollIntoView: false });
  };

  return (
    <StudioContext.Provider value={contextValue}>
      <PageHeader />
      <ContentContainer>
        <GridContainer
          width={gridSettings.width}
          height={gridSettings.height}
          dragging={!!draggingBlock}
          draggingOver={draggingOver}
        >
          <ReactGridLayoutErrorBoundary>
            {/*
             * todo: VENN-27857
             * react-grid-layout 1.5.0 does not support React 18.
             * - #VENN-27732 We rely on ReactGridLayoutErrorBoundary to catch errors and retry rendering.
             * - #VENN-27413 We rely on `postinstall` and `react-grid-layout-patch-venn-27413`
             *   to fix some performance issues with react-grid-layout on react 18
             */}
            <ReactGridLayout
              style={style}
              width={width}
              rowHeight={RL_GRID_ROW_HEIGHT}
              margin={margin}
              containerPadding={gridSettings.containerPadding}
              maxRows={gridSettings.noRows}
              isDroppable={hasPermission('STUDIO_INSERT_BLOCKS')}
              autoSize={false}
              onLayoutChange={onPageLayoutChange}
              cols={gridSettings.noColumns}
              layout={pageLayout}
              draggableHandle={`.${DRAG_HANDLE_CLASS}`}
              droppingItem={DROPPING_ITEM}
              onDrop={onDrop}
              onDragStart={onDragOrResize}
              onResizeStart={onDragOrResize}
              onResizeStop={() => triggerResizeEvent()}
              transformScale={transformScale}
              useCSSTransforms
            >
              {pageLayout.map((blockLayout, index) => {
                if (blockLayout.i === DROP_ITEM_ID) {
                  if (!draggingBlock) {
                    // If the thing being dragged is not a block, do not render
                    return null;
                  }
                  return <StudioShimmerBlock key={blockLayout.i} id={blockLayout.i} />;
                }

                const blockId = blockLayout.i;

                return (
                  <BlockOuter
                    inPrintMode={inPrintMode}
                    isReadOnly={!hasPermission('STUDIO_REORDER_BLOCKS')}
                    handleOverlapsError={overflowErrors[index]?.position === 'Bottom-Right'}
                    key={blockId}
                    id={blockId}
                  >
                    <BuilderBlockWrapper id={blockId} pageFooterRef={pageFooterRef}>
                      <BlockContainer height={blockLayout?.h ?? 0} width={blockLayout?.w ?? 0}>
                        {!inPrintMode && hasPermission('STUDIO_REORDER_BLOCKS') && (
                          <DragHandle type="arrows" className={DRAG_HANDLE_CLASS} />
                        )}
                        <DeprecatedBlock
                          id={blockId}
                          index={index}
                          focusBlockIndex={focusBlockIndex}
                          focusBlockRef={focusBlockRef}
                          externalBlockWrapper
                          containerWidth={calcGridItemWidth(gridSettings, blockLayout?.w)}
                        />
                      </BlockContainer>
                    </BuilderBlockWrapper>
                  </BlockOuter>
                );
              })}
            </ReactGridLayout>
          </ReactGridLayoutErrorBoundary>
        </GridContainer>
      </ContentContainer>
      <PageFooter ref={pageFooterRef} reportLabPage={page} currentPage={pageNumber + 1} totalPage={totalPages} />
    </StudioContext.Provider>
  );
};

export default GridPage;

const DragHandle = styled(Icon)`
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  top: 10px;
  right: 10px;
  font-size: 1rem;
  cursor: grab;
  z-index: ${ZIndex.Cover};
`;

const BlockContainer = styled.div<{ width: number; height: number }>`
  z-index: ${ZIndex.Front};
  overflow: hidden;
  height: 100%;
  > div {
    height: 100%;
    > div {
      display: flex;
      height: 100%;
    }
  }
`;

const GridContainer = styled.div<{ dragging: boolean; draggingOver: boolean; width: number; height: number }>`
  ${({ dragging, draggingOver }) =>
    dragging &&
    css`
      outline: 2px dashed ${GetColor.Grey};
      ${!draggingOver &&
      css`
        > div {
          opacity: 0.2;
        }
        ::before {
          content: 'Drop here';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -100%);
          font-size: 50px;
          position: absolute;
          z-index: ${ZIndex.Front};
        }
      `}
    `}
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const BlockOuter = styled.div<{ handleOverlapsError: boolean; inPrintMode: boolean; isReadOnly?: boolean }>`
  ${({ inPrintMode, isReadOnly, handleOverlapsError }) =>
    !inPrintMode &&
    !isReadOnly &&
    css`
      .react-resizable-handle {
        background-image: none;
        z-index: ${ZIndex.Cover};
        bottom: 0;
        right: 0;
        cursor: se-resize;

        ::after {
          z-index: ${ZIndex.Cover};
          content: '';
          position: absolute;
          right: 5px;
          bottom: 5px;
          width: 15px;
          height: 15px;
          border-right: 4px solid
            ${handleOverlapsError
              ? css`
                  ${GetColor.White}
                `
              : 'rgba(0, 0, 0, 0.6)'};
          border-bottom: 4px solid
            ${handleOverlapsError
              ? css`
                  ${GetColor.White}
                `
              : 'rgba(0, 0, 0, 0.6)'};
        }
      }
    `}
`;

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  .react-grid-placeholder.react-grid-placeholder {
    ${Shimmer};
    animation-duration: 5s;
    z-index: ${ZIndex.Base};
  }
  .react-grid-item {
    z-index: ${ZIndex.Cover};
  }
  .${SpecialCssClasses.NotDownloadable} {
    display: none;
  }
`;
