import React from 'react';
import styled from 'styled-components';
import { Button } from '../../button';
import Icon from '../../icon/Icon';

export interface ButtonTriggerProps {
  /** Whether the trigger button has dominant styling or not */
  dominant?: boolean;
  /** Whether the trigger button is disabled or not */
  disabled?: boolean;
  /** The icon to display before the label, if any */
  icon?: string;
  /** If undefined, the up and down caret isn't shown. Else, the open/close caret is displayed */
  isOpen?: boolean;
  /** The label to display on the trigger */
  label: string;
  /** Function to call on click of the trigger */
  onClick: () => void;
  /** Badge to display after the label, if any */
  badge?: JSX.Element;
  /** Class name for the button. Allows this component to be styled by styled-components */
  className?: string;
  /** Value to display instead of the label, if any */
  value?: string;
}

const buttonStyle: React.CSSProperties = {
  borderRadius: 4,
  height: 36,
  minHeight: 36,
};

/**
 * The trigger / button to click to open up a menu
 */
const ButtonTrigger = ({
  dominant,
  disabled,
  icon,
  isOpen,
  label,
  onClick,
  badge,
  className,
  value,
}: ButtonTriggerProps) => {
  const showValue = !!value;

  return (
    <Button
      className={className}
      dominant={dominant}
      noMargin
      onClick={onClick}
      disabled={disabled}
      style={buttonStyle}
      aria-expanded={isOpen}
      aria-haspopup="dialog"
    >
      {icon && <Icon type={icon} />}
      <Label isLeftPadding={!!icon} isRightPadding={isOpen !== undefined}>
        {showValue ? value : label}
      </Label>
      {badge}
      {isOpen !== undefined && <Icon type={isOpen ? 'caret-up' : 'caret-down'} />}
    </Button>
  );
};

export default ButtonTrigger;

const Label = styled.label<{ isLeftPadding: boolean; isRightPadding: boolean }>`
  ${(props) => props.isLeftPadding && 'padding-left: 5px;'}
  ${(props) => props.isRightPadding && 'padding-right: 5px;'}
  cursor: pointer;
`;
