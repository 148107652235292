import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from '../../../../modal/ModalFooter';
import { GetColor } from 'venn-ui-kit';

interface FooterButtonsProps {
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
  onContinue?: () => void;
  onCancel?: () => void;
  onStartOver?: () => void;
  hasNoData?: boolean;
  isSample?: boolean;
  primaryLabel?: string | JSX.Element;
  primaryTooltip?: string;
  secondaryLabel?: string | JSX.Element;
  cancelLabel?: string | JSX.Element;
  className?: string;
  noMargin?: boolean;
  destructive?: boolean;
}

export const UniversalUploaderFooter: FC<React.PropsWithChildren<FooterButtonsProps>> = ({
  disabled,
  disabledMessage,
  onContinue,
  onCancel,
  onStartOver,
  hasNoData,
  isSample,
  primaryLabel,
  primaryTooltip,
  cancelLabel,
  secondaryLabel,
  noMargin,
  destructive = false,
}) => {
  return (
    <StyledModalFooter
      destructive={destructive}
      noMargin={noMargin}
      simplifiedButtonStyles
      className="no-margin-footer"
      cancelLabel={cancelLabel ?? '< BACK'}
      cancelClassName="qa-upload-cancel"
      onCancel={isSample ? undefined : onCancel}
      rightChildren={disabledMessage}
      primaryLabel={hasNoData ? '' : (primaryLabel ?? 'Complete Upload')}
      primaryClassName="qa-accept-btn"
      primaryDisabled={disabled}
      primaryTooltip={primaryTooltip}
      onPrimaryClick={onContinue}
      secondaryLabel={secondaryLabel ?? (isSample ? 'Try with Your Data' : 'Start Over')}
      secondaryClassName="start-over-btn"
      onSecondaryClick={onStartOver}
    />
  );
};

export const StyledModalFooter = styled(ModalFooter)<{ noMargin?: boolean }>`
  width: 100%;
  background-color: ${GetColor.White};
  padding: 12px 24px;
  min-height: 64px;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
  ${({ noMargin }) => noMargin && 'margin-top: 0;'}

  .qa-upload-cancel, .qa-accept-btn, .start-over-btn {
    font-size: 12px;
    letter-spacing: 1px;
  }
`;
