import type { FC, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Loading } from 'venn-ui-kit';
import StaticTabs from '../../../static-tabs/StaticTabs';
import type { BenchmarkUINavigationAction } from '../state/benchmarkActions';
import { BenchmarkUIView } from '../state/benchmarkActions';
import { ModalBackToPrev, ModalHeader } from '../../../modal';

type ViewIdFunction = (viewId: BenchmarkUIView) => ReactNode;

export interface BenchmarksLayoutProps {
  /**
   * The identifier of the currently active view.
   * This will be passed to the render function provided as a child.
   */
  viewId: BenchmarkUIView;
  /**
   * An optional header node. If omitted, "Configure Benchmarks" will be rendered.
   */
  header?: ReactNode;
  /**
   * Pass true to display an overlay with a loading spinner.
   */
  busy?: boolean;
  /**
   * This handler function will be called whenever the user navigates to a
   * different view.
   */
  onUINavigationAction: OnUINavigationActionHandler;
  hidePrevLink?: boolean;
  /**
   * If a render function is provided, the current `viewId` will be passed to it as a property.
   * If a node is provided, it will be rendered as is.
   */
  children: ViewIdFunction | ReactNode;
}

type OnUINavigationActionHandler = (action: BenchmarkUINavigationAction) => void;

/**
 * `BenchmarksLayout` renders headers, tabs, footers and other navigation components
 * around the current benchmarks view.
 *
 * To render the actual content, you must provide a render function ot a React Node
 a child.
 *
 * A `header` prop can also be provided to customize the header.
 *
 * This is a stateless component, actual view state must be maintained by the
 * consumer, and provided in `viewId` prop. Consumer should respond to changes
 * by providing a handler to `onUINavigationAction`.
 *
 * |----------------|----------------|-------|
 * | viewId         | "back" top bar | tabs  |
 * |----------------|----------------|-------|
 * | .ListView      | no             | no    |
 * | .SearchView    | yes            | yes   |
 * | .CompositeView | yes            | yes   |
 * |----------------|----------------|-------|
 *
 */
const BenchmarksLayout: FC<BenchmarksLayoutProps> = ({
  viewId,
  header,
  busy,
  children,
  onUINavigationAction,
  hidePrevLink,
}) => (
  <BenchmarksContainer>
    {!hidePrevLink && <BenchmarksBackToPrev viewId={viewId} onAction={onUINavigationAction} />}
    <div>{header || <ModalHeader>Configure Benchmarks</ModalHeader>}</div>
    {!hidePrevLink && <BenchmarksTabs viewId={viewId} onAction={onUINavigationAction} />}
    {typeof children === 'function' ? children(viewId) : children}
    {busy && (
      <Overlay>
        <Loading title="" />
      </Overlay>
    )}
  </BenchmarksContainer>
);

export default BenchmarksLayout;

export interface BenchmarksUIProps {
  viewId: BenchmarkUIView;
  onAction: OnUINavigationActionHandler;
}

const BenchmarksBackToPrev: FC<React.PropsWithChildren<BenchmarksUIProps>> = ({ viewId, onAction }) => {
  if (viewId === BenchmarkUIView.ListView) {
    return null;
  }
  return (
    <ModalBackToPrev
      backToLabel="list"
      onClick={() =>
        onAction({
          type: 'BenchmarkUINavigation',
          payload: {
            target: { kind: 'View', viewId: BenchmarkUIView.ListView },
          },
        })
      }
    />
  );
};

const BenchmarksTabs: FC<React.PropsWithChildren<BenchmarksUIProps>> = ({ viewId, onAction }) => {
  if (viewId === BenchmarkUIView.ListView || viewId === BenchmarkUIView.CompositeEditView) {
    return null;
  }

  const tabs = [
    { tabId: BenchmarkUIView.SearchView, title: 'Search' },
    {
      tabId: BenchmarkUIView.CompositeCreateView,
      title: 'Create composite',
    },
  ];

  return (
    <TabContainer>
      <StaticTabs
        tabs={tabs}
        selectedTabId={viewId}
        tabClassName="benchmarkTab"
        onTabSelected={(tab) =>
          onAction({
            type: 'BenchmarkUINavigation',
            payload: {
              target: { kind: 'View', viewId: tab.tabId },
            },
          })
        }
      />
    </TabContainer>
  );
};

const BenchmarksContainer = styled.div`
  position: relative;
  min-height: 670px;
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  .benchmarkTab {
    width: 50%;
    & > div {
      width: 100%;
      padding-top: 11px;
      padding-bottom: 15px;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
`;
