import React, { useMemo } from 'react';
import PortfolioLabLayout from './PortfolioLabLayout';
import useSelectedPortfolio from './logic/useSelectedPortfolio';
import { PortfolioLabContext } from 'venn-components';
import useSideBarState from './logic/useSideBarState';
import useConstraints from './logic/useConstraints';
import useObjectiveState from './logic/useObjectiveState';
import useOptimizationSolution from './logic/useOptimizationSolutions';
import useOptimization from './logic/useOptimization';
import { isNil } from 'lodash';
import useBenchmark from './logic/useBenchmark';
import type { OptimizedPortfolio } from 'venn-api';
import useValuesFromHistoryState from './logic/useValuesFromHistoryState';
import usePortfolioSummary from './logic/usePortfolioSummary';

const PortfolioLabPage = () => {
  const {
    locationPortfolioId,
    portfolio,
    portfolioLoading,
    onSelectPortfolioId,
    newOpportunitiesProps,
    onUpdateNewOpportunities,
  } = useSelectedPortfolio();
  const { settings, storeSettings } = useValuesFromHistoryState(locationPortfolioId);
  const { onShowAllocationSection, onShowFactorSection, ...sideBarProps } = useSideBarState();
  const { ...portfolioConstraints } = useConstraints(portfolio, newOpportunitiesProps, settings, storeSettings);
  const { ...portfolioSummaryValues } = usePortfolioSummary(portfolio);
  const { constraintInputFocused, setConstraintInputFocused, ...objectiveState } = useObjectiveState(
    portfolio?.id,
    portfolioSummaryValues.summary,
    portfolioSummaryValues.historicalVolatility,
    portfolioSummaryValues.historicalReturn,
    settings,
    storeSettings,
  );
  const { optimizedResult, alternateResults, averageInvestmentBuys, averageInvestmentSells, loadingOptimization } =
    useOptimization(
      portfolio,
      portfolioConstraints.currentPolicyWithConstraints,
      objectiveState.objective,
      objectiveState.objectiveConstraintValue,
      portfolioSummaryValues.summary,
    );
  const { benchmarkSummary, benchmarkSummaryError, benchmarkPseudoPortfolio, ...benchmarkState } = useBenchmark(
    portfolio,
    settings,
    storeSettings,
  );

  const portfolioResult: Partial<OptimizedPortfolio> = useMemo(
    () => ({ portfolio, summary: portfolioSummaryValues.summary }),
    [portfolio, portfolioSummaryValues.summary],
  );
  const benchmarkResult: Partial<OptimizedPortfolio> = useMemo(
    () => ({
      portfolio: benchmarkPseudoPortfolio,
      summary: benchmarkSummary,
    }),
    [benchmarkSummary, benchmarkPseudoPortfolio],
  );

  const { selectedSolution, onSelectSolution, solutionPortfolio, ...solutions } = useOptimizationSolution(
    portfolioResult,
    optimizedResult,
    alternateResults,
    benchmarkResult,
  );

  const hasAnyOptimizationResults =
    !isNil(optimizedResult) || (alternateResults ?? []).some((item) => !isNil(item.portfolio) && !isNil(item.summary));

  const portfolioLabContextValue = useMemo(() => {
    return {
      portfolio,
      portfolioSummary: portfolioSummaryValues.summary,
      portfolioLoading,
      onSelectPortfolioId,
      onShowAllocationSection,
      onShowFactorSection,
      factorForecasts: portfolioSummaryValues.factorForecasts,
      onForecastUpdated: portfolioSummaryValues.onForecastUpdated,
      ...portfolioConstraints,
      ...objectiveState,
      benchmarkPseudoPortfolio,
      ...benchmarkState,
      selectedSolution,
      solutionPortfolio,
      onSelectSolution,
    };
  }, [
    portfolio,
    portfolioSummaryValues.summary,
    portfolioLoading,
    onSelectPortfolioId,
    onShowAllocationSection,
    onShowFactorSection,
    portfolioSummaryValues.factorForecasts,
    portfolioSummaryValues.onForecastUpdated,
    portfolioConstraints,
    objectiveState,
    benchmarkPseudoPortfolio,
    benchmarkState,
    selectedSolution,
    solutionPortfolio,
    onSelectSolution,
  ]);
  return (
    <PortfolioLabContext.Provider value={portfolioLabContextValue}>
      <PortfolioLabLayout
        constraintInputFocused={constraintInputFocused}
        setConstraintInputFocused={setConstraintInputFocused}
        optimizedResult={optimizedResult}
        alternateResults={alternateResults}
        noOptimizationResult={
          !portfolioLoading &&
          !portfolioSummaryValues.summaryLoading &&
          !loadingOptimization &&
          !hasAnyOptimizationResults
        }
        averageInvestmentBuys={averageInvestmentBuys}
        averageInvestmentSells={averageInvestmentSells}
        newFunds={newOpportunitiesProps?.funds}
        newFundsParentStrategyId={newOpportunitiesProps?.parentId}
        settings={settings}
        storeSettings={storeSettings}
        benchmarkSummaryError={benchmarkSummaryError}
        {...sideBarProps}
        optimizing={loadingOptimization}
        {...portfolioSummaryValues}
        {...solutions}
        onUpdateNewOpportunities={onUpdateNewOpportunities}
      />
    </PortfolioLabContext.Provider>
  );
};

export default PortfolioLabPage;
