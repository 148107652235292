import { useRecoilValue } from 'recoil';
import {
  forecastPanelViewSelector,
  ForecastTab,
  isSystemForecast,
  shouldShowFactorOverrideFooterAtom,
  shouldShowInvestmentOverrideFooter,
} from 'venn-state';
import type { CustomFactorForecast } from 'venn-api';

type FooterState =
  | {
      footerToShow: 'investment override';
    }
  | {
      footerToShow: 'factor override';
      forecast: CustomFactorForecast;
    }
  | {
      footerToShow: 'none';
    };

export const useForecastPanelFooter = (): FooterState => {
  const view = useRecoilValue(forecastPanelViewSelector);
  const isInvestmentOverrideViewEligibleView =
    view.tab === ForecastTab.InvestmentForecast &&
    (view.detail.type === 'InvestmentOverrideCreator' || view.detail.type === 'InvestmentOverrideEditor');
  const shouldShowInvestmentOverrideViewFooter = useRecoilValue(shouldShowInvestmentOverrideFooter('EDIT'));

  const isFactorOverrideViewEligibleView =
    view.tab === ForecastTab.FactorForecast &&
    view.detail.type === 'SpecificForecast' &&
    !isSystemForecast(view.detail.forecast);
  const shouldShowFactorOverrideFooter = useRecoilValue(shouldShowFactorOverrideFooterAtom);
  if (isInvestmentOverrideViewEligibleView && shouldShowInvestmentOverrideViewFooter) {
    return {
      footerToShow: 'none',
    };
  }
  if (isFactorOverrideViewEligibleView && shouldShowFactorOverrideFooter && view.detail.type === 'SpecificForecast') {
    return {
      footerToShow: 'factor override',
      forecast: view.detail.forecast,
    };
  }
  return {
    footerToShow: 'none',
  };
};
