import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { OverflowError } from 'venn-state';
import { blockContentSizeExceededState, blockOverflowErrorState, isReportState } from 'venn-state';
import { RL_GRID_ROW_HEIGHT, useAppPrintMode } from 'venn-components';
import { assertExhaustive } from 'venn-utils';
import styled, { css } from 'styled-components';
import { GetColor, Icon, ZIndex } from 'venn-ui-kit';

interface OverflowWrapperProps {
  id: string;
  pageFooterRef?: React.RefObject<HTMLElement>;
  internalRef: React.RefObject<HTMLDivElement>;
}

/**
 * A wrapper around blocks to display warnings if content is overflowing, or the block is overlapping with the footer
 * Note that warnings will only display in report lab, and not in studio
 */
export const OverflowWrapper: React.ComponentType<React.PropsWithChildren<OverflowWrapperProps>> = ({
  children,
  id,
  pageFooterRef,
  internalRef,
}) => {
  const setOverflowError = useSetRecoilState(blockOverflowErrorState(id));
  const error = useAlertOutline(id, internalRef, pageFooterRef);

  useEffect(() => {
    setOverflowError(error);
  }, [error, setOverflowError]);

  return (
    <Wrapper error={error}>
      {children}
      <Message error={error} />
    </Wrapper>
  );
};

const Message = ({ error }: { error: OverflowError | undefined }) => {
  if (!error) {
    return null;
  }

  const renderContent = () => {
    switch (error.position) {
      case 'Bottom':
        return (
          <>
            <Icon prefix="fal" type="arrow-down" />
            {error.message}
            <Icon prefix="fal" type="arrow-down" />
          </>
        );
      case 'Right':
      case 'Surround':
        return (
          <>
            <Icon prefix="fal" type="arrow-up" />
            {error.message}
            <Icon prefix="fal" type="arrow-up" />
          </>
        );
      case 'Bottom-Right':
        return (
          <>
            <Icon prefix="fal" type="arrow-down-right" />
            {`${error.message} `}
            <Icon prefix="fal" type="arrow-down-right" style={{ opacity: 0 }} />
          </>
        );
      default:
        throw assertExhaustive(error.position);
    }
  };

  return (
    <MessageWrapper error={error}>
      <MessageContent error={error}>{renderContent()}</MessageContent>
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div<{ error: OverflowError }>`
  z-index: ${ZIndex.Cover};
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  ${({ error }) => {
    switch (error.position) {
      case 'Bottom':
      case 'Surround':
        return css`
          transform: translateX(50%);
        `;
      case 'Right':
        return css`
          transform: translate(100%, -50%);
        `;
      case 'Bottom-Right':
        return css`
          transform: translateX(100%);
        `;
      default:
        throw assertExhaustive(error.position);
    }
  }}
`;

const MessageContent = styled.div<{ error: OverflowError }>`
  color: ${GetColor.White};
  background-color: ${GetColor.Error};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 2px;
  gap: 5px;
  white-space: pre;
  font-size: 1rem;
  ${({ error }) => {
    switch (error.position) {
      case 'Bottom':
      case 'Surround':
        return css`
          transform: translate(-50%, -100%);
        `;
      case 'Right':
        return css`
          transform-origin: left;
          transform: rotate(90deg) translate(-50%, 50%);
        `;
      case 'Bottom-Right':
        return css`
          transform: translate(-100%, -100%);
        `;
      default:
        throw assertExhaustive(error.position);
    }
  }}
`;

const Wrapper = styled.div<{ error: OverflowError | undefined }>`
  width: 100%;
  height: 100%;
  position: relative;

  ::before {
    content: '\\a0';
    display: block;
    z-index: ${ZIndex.Cover};
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    ${({ error }) => {
      if (!error) {
        return undefined;
      }

      switch (error.position) {
        case 'Bottom':
          return css`
            border-bottom: 3px solid ${GetColor.Error};
          `;
        case 'Right':
          return css`
            border-right: 3px solid ${GetColor.Error};
          `;
        case 'Bottom-Right':
          return css`
            border-bottom: 3px solid ${GetColor.Error};
            border-right: 3px solid ${GetColor.Error};
          `;
        case 'Surround':
          return css`
            border: 3px solid ${GetColor.Error};
          `;
        default:
          throw assertExhaustive(error.position);
      }
    }}
  }
`;

const useAlertOutline = (
  id: string,
  internalRef: React.RefObject<HTMLDivElement>,
  pageFooterRef: React.RefObject<HTMLElement> | undefined,
): OverflowError | undefined => {
  const { inPrintMode } = useAppPrintMode();
  const isReport = useRecoilValue(isReportState);
  const blockSizeExceeded = useRecoilValue(blockContentSizeExceededState(id));
  const [isOverlapping, setIsOverlapping] = useState<boolean>(false);

  // TODO(collin.irwin): probably should use resize observer which is resilient to non-rerender changes such as CSS. Will follow up to explore that option.
  // eslint-disable-next-line react-hooks/exhaustive-deps -- not ideal, but we want to handle ref changing from undefined to defined so we use useEffect
  useEffect(() => {
    // Note that Y coordinates start at 0 at the top of the screen, and increases towards the bottom of the screen.
    // Effectively the Y coordinate is distance from the top of the viewport.
    const blockBottomEdge = internalRef.current?.getBoundingClientRect().bottom ?? Number.NEGATIVE_INFINITY;
    const footerTopEdge = pageFooterRef?.current?.getBoundingClientRect().top ?? Number.POSITIVE_INFINITY;
    /** Margin of error to not cause red alert outline when a block overlaps the footer. Basically helps make up for the imprecise grid placement and sizing. */
    setIsOverlapping(blockBottomEdge > footerTopEdge + RL_GRID_ROW_HEIGHT / 2 + 1);
  });

  const isAlertAllowed = !!isReport && !inPrintMode;

  return useMemo(() => {
    if (!isAlertAllowed) {
      return undefined;
    }
    if (isOverlapping) {
      return {
        position: 'Surround',
        message: 'Content overlap',
      };
    }
    if (blockSizeExceeded.width || blockSizeExceeded.height) {
      return {
        position:
          blockSizeExceeded.width && blockSizeExceeded.height
            ? 'Bottom-Right'
            : blockSizeExceeded.width
              ? 'Right'
              : 'Bottom',
        message: 'Data overflow',
      };
    }
    return undefined;
  }, [blockSizeExceeded.height, blockSizeExceeded.width, isOverlapping, isAlertAllowed]);
};
