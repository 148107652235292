import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { FactorExposure, Portfolio } from 'venn-api';
import { GetColor, Icon, LoadingSize, Tooltip } from 'venn-ui-kit';
import type { FactorCondition, FactorExposureConstraint } from 'venn-utils';
import { Numbers } from 'venn-utils';
import { CenteredText, ConstraintSubjectTypeLabel, RelativeContainer, RelativeSpinner } from '../components/styled';
import ConstraintActions from '../components/ConstraintActions';
import ConstraintConditionDropdown from '../dropdowns/ConstraintConditionDropdown';
import { factorConditionOptions } from './content';
import NewFactorConstraint from './NewFactorConstraint';
import { BoldValue, BoldValueButton, FactorConstraintRow } from './styled';
import { isNil, kebabCase } from 'lodash';
import { ConditionalOverlay } from '../../conditional-overlay';

interface FactorConstraintProps {
  portfolio: Portfolio;
  factorBreakdown: FactorExposure;
  minConstraint: FactorExposureConstraint | undefined;
  maxConstraint: FactorExposureConstraint | undefined;
  canApplyConstraint?: boolean;
  onApplyConstraint?: (constraint: FactorExposureConstraint) => void;
  onAddConstraint:
    | ((constraint: FactorExposureConstraint) => void)
    | ((constraint: FactorExposureConstraint) => Promise<void>);
  onDeleteConstraint: (constraint: FactorExposureConstraint) => void;
  disabled?: boolean;
}

const FactorConstraint = ({
  portfolio,
  factorBreakdown,
  minConstraint,
  maxConstraint,
  canApplyConstraint,
  onApplyConstraint,
  onAddConstraint,
  onDeleteConstraint,
  disabled,
}: FactorConstraintProps) => {
  const { name, exposure } = factorBreakdown;
  const { Colors } = useContext(ThemeContext);

  const options = useMemo(
    () =>
      factorConditionOptions.filter(
        (option) =>
          (isNil(minConstraint) || option.value !== 'minExposure') &&
          (isNil(maxConstraint) || option.value !== 'maxExposure'),
      ),
    [minConstraint, maxConstraint],
  );

  const [isAddingConstraint, setIsAddingConstraint] = useState<FactorCondition | undefined>();
  const [isSavingConstraint, setIsSavingConstraint] = useState<FactorCondition | undefined>();

  useEffect(() => {
    setIsSavingConstraint(undefined);
  }, [minConstraint, maxConstraint]);

  const [isEditingMin, setIsEditingMin] = useState(false);
  const [isEditingMax, setIsEditingMax] = useState(false);

  return (
    <div>
      <FactorExposureRow className={`qa-${kebabCase(name)}`}>
        <FactorLabel color={factorBreakdown.significant ? Colors.Black : Colors.MidGrey1}>{name}</FactorLabel>
        <CenteredText>Current exposure:</CenteredText>
        <Tooltip content={Numbers.safeFormatNumber(exposure, 5)} isHidden={isNil(exposure)}>
          <BoldValue color={factorBreakdown.significant ? Colors.Black : Colors.MidGrey1}>
            {Numbers.safeFormatNumber(exposure, 2)}
          </BoldValue>
        </Tooltip>
        <CenteredText>(β)</CenteredText>
        <ConstraintConditionDropdown<FactorCondition>
          options={options}
          onSelect={(condition) => setIsAddingConstraint(condition)}
        >
          {(expanded, onToggle) => (
            <AddConstraint
              type="button"
              disabled={options.length === 0 || !isNil(isAddingConstraint) || disabled}
              onClick={() => onToggle(!expanded)}
            >
              <Icon type="plus-circle" prefix={expanded ? 'fas' : 'far'} />
            </AddConstraint>
          )}
        </ConstraintConditionDropdown>
      </FactorExposureRow>

      {!isNil(minConstraint) && !isEditingMin ? (
        <FactorConstraintRow>
          <CenteredText>Minimum exposure:</CenteredText>
          <Tooltip content={!disabled && 'Edit'}>
            <BoldValueButton tabIndex={0} onClick={() => setIsEditingMin(true)} disabled={disabled}>
              {minConstraint.value}
            </BoldValueButton>
          </Tooltip>
          <CenteredText>(β)</CenteredText>
          <ConstraintActions
            portfolio={portfolio}
            isInPortfolioPolicy={minConstraint.isInPortfolioPolicy}
            onDeleteConstraint={() => onDeleteConstraint(minConstraint)}
            canApplyConstraint={canApplyConstraint}
            onApplyConstraint={() => onApplyConstraint?.(minConstraint)}
            disabled={disabled}
          />
        </FactorConstraintRow>
      ) : isAddingConstraint === 'minExposure' || isSavingConstraint === 'minExposure' || isEditingMin ? (
        <RelativeContainer>
          <ConditionalOverlay condition={isSavingConstraint === 'minExposure'} overlay="">
            <NewFactorConstraint
              portfolio={portfolio}
              initialValue={minConstraint?.value}
              factorId={factorBreakdown.id}
              condition="minExposure"
              hasError={(value) => !isNil(maxConstraint) && maxConstraint.value < value}
              onDeleteConstraint={() => setIsAddingConstraint(undefined)}
              onCreateConstraint={async (constraint: FactorExposureConstraint) => {
                if (!isNil(maxConstraint) && maxConstraint.value < constraint.value) {
                  return;
                }
                setIsSavingConstraint('minExposure');
                await onAddConstraint(constraint);
                setIsAddingConstraint(undefined);
                setIsEditingMin(false);
              }}
            />
          </ConditionalOverlay>
          {isSavingConstraint === 'minExposure' && <RelativeSpinner size={LoadingSize.nano} />}
        </RelativeContainer>
      ) : null}

      {!isNil(maxConstraint) && !isEditingMax ? (
        <FactorConstraintRow>
          <CenteredText>Maximum exposure:</CenteredText>
          <Tooltip content={!disabled && 'Edit'}>
            <BoldValueButton disabled={disabled} tabIndex={0} onClick={() => setIsEditingMax(true)}>
              {maxConstraint.value}
            </BoldValueButton>
          </Tooltip>
          <CenteredText>(β)</CenteredText>
          <ConstraintActions
            portfolio={portfolio}
            isInPortfolioPolicy={maxConstraint.isInPortfolioPolicy}
            onDeleteConstraint={() => onDeleteConstraint(maxConstraint)}
            canApplyConstraint={canApplyConstraint}
            onApplyConstraint={() => onApplyConstraint?.(maxConstraint)}
            disabled={disabled}
          />
        </FactorConstraintRow>
      ) : isAddingConstraint === 'maxExposure' || isSavingConstraint === 'maxExposure' || isEditingMax ? (
        <RelativeContainer>
          <ConditionalOverlay condition={isSavingConstraint === 'maxExposure'} overlay="">
            <NewFactorConstraint
              portfolio={portfolio}
              initialValue={maxConstraint?.value}
              factorId={factorBreakdown.id}
              condition="maxExposure"
              hasError={(value) => !isNil(minConstraint) && minConstraint.value > value}
              onDeleteConstraint={() => setIsAddingConstraint(undefined)}
              onCreateConstraint={async (constraint: FactorExposureConstraint) => {
                if (!isNil(minConstraint) && minConstraint.value > constraint.value) {
                  return;
                }
                setIsSavingConstraint('maxExposure');
                await onAddConstraint(constraint);
                setIsAddingConstraint(undefined);
                setIsEditingMax(false);
              }}
            />
          </ConditionalOverlay>
          {isSavingConstraint === 'maxExposure' && <RelativeSpinner size={LoadingSize.nano} />}
        </RelativeContainer>
      ) : null}
    </div>
  );
};

export default FactorConstraint;

const FactorExposureRow = styled.div`
  display: flex;
  color: ${GetColor.HintGrey};
  margin-bottom: 10px;
`;

const FactorLabel = styled(ConstraintSubjectTypeLabel)`
  min-width: 44px;
`;

const AddConstraint = styled.button<{ disabled?: boolean }>`
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? GetColor.Grey : GetColor.Primary.Dark)};
  margin-left: 15px;
`;
