import type { CSSProperties } from 'react';
import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ExternalActivityListener } from 'venn-ui-kit';
import { TopLegend } from '../heat-map/TopLegend';
import HeatMapRenderer from '../heat-map/MapRenderer';
import Border from '../heat-map/Grid/Border';
import Lines from '../heat-map/Grid/Lines';
import Cursor from '../heat-map/Cursor';
import type { HeatMapTypes, XAxisLabel } from '../heat-map';
import YAxis from '../heat-map/YAxis';
import Values from '../heat-map/Values/Values';
import XAxis, { X_AXIS_HEIGHT } from './XAxis';
import TopGridLines from './TopGridLines';
import { getProformaPortfolioPalette } from '../heat-map/utils';
import BaseCorrelationChartTooltip from './BaseCorrelationChartTooltip';
import { useAppPrintMode } from '../print';

const TOP_MARGIN = 50;

interface BaseCorrelationChartProps {
  /** Show values as text inside the heatmap cell */
  showValues?: boolean;
  /** Width of component */
  width: number;
  /** Data required to display Heat Map  */
  data: HeatMapTypes.Root[] | null;
  /** Names of the nodes within the portfolio */
  nodeNames: XAxisLabel[] | null;
  /** Number of heat map cells in a row */
  seriesDataSize: number;
  /** Loading if the data that we have is not ready yet * */
  loading: boolean;
  /** Height of the x-axis label, overrides X_AXIS_HEIGHT */
  verticalLabelHeight?: number;
  /** Return style for a given label */
  labelFormatter?: (id?: string, type?: string) => CSSProperties;
  /** Tooltip to show on cell hover */
  tooltipRenderer?: (columnId: number, rowId: number) => JSX.Element | null;
}

const SERIES_COLUMN_WIDTH = 80;
export const INVESTMENT_COLUMN_WIDTH = 164;
const TOP_LEGEND_THICKNESS = 14;
const TOP_LEGEND_WIDTH = 180;
const X_AXIS_ANGLE = -90;
const X_AXIS_BOTTOM_SPACE = 20;
const SCREEN_CELL_HEIGHT = 32;
const PRINT_CELL_HEIGHT = 19;
export const MIN_CELL_WIDTH = 32;

const topLegendFormatter = (value?: number | null) =>
  value === null || value === undefined
    ? '--'
    : `${value > 0 ? '+' : ''}${new Intl.NumberFormat('en-US', {
        useGrouping: true,
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
        style: 'decimal',
      }).format(value)}`;

const defaultTooltipRenderer = () => {
  return null;
};
const defaultLabelFormatter = () => ({});

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  showValues,
  width,
  data,
  nodeNames,
  loading,
  seriesDataSize,
  verticalLabelHeight,
  labelFormatter = defaultLabelFormatter,
  tooltipRenderer = defaultTooltipRenderer,
}: BaseCorrelationChartProps) => {
  const { Typography, Colors } = useContext(ThemeContext);
  const [seriesValue, setSeriesValue] = useState<number | undefined>(undefined);
  const [selectedCell, setSelectedCell] = useState<{ x: number; y: number } | undefined>(undefined);

  const clearSelection = () => {
    setSeriesValue(undefined);
    setSelectedCell(undefined);
  };

  const handleCellEnter = ({ columnId, rowId }: { columnId: number; rowId: number }): void => {
    const entity = data?.[rowId]?.series?.[0]?.data?.[columnId];
    if (!entity) {
      return;
    }
    setSeriesValue(entity.value);
    setSelectedCell({ x: columnId, y: rowId });
  };

  const hidden = loading || !data;

  const elementWidth = width - INVESTMENT_COLUMN_WIDTH;
  const seriesColumnCount = data?.[0]?.series?.length || 0;
  const rightOffset = loading ? undefined : SERIES_COLUMN_WIDTH * seriesColumnCount;

  const { inPrintMode } = useAppPrintMode();

  const cellWidth = loading
    ? 1
    : !inPrintMode
      ? Math.max(elementWidth / seriesDataSize, MIN_CELL_WIDTH)
      : (elementWidth - SERIES_COLUMN_WIDTH * seriesColumnCount) / seriesDataSize;

  if (cellWidth <= 0) {
    return <Wrapper />;
  }

  if (!data) data = [];
  if (!nodeNames) nodeNames = [];

  const cellHeight = inPrintMode ? PRINT_CELL_HEIGHT : SCREEN_CELL_HEIGHT;
  const computedAreaHeight = hidden ? cellHeight : cellHeight * (data.length ?? 0);
  const labelHeight =
    (verticalLabelHeight !== undefined ? verticalLabelHeight : X_AXIS_HEIGHT) * (inPrintMode ? 0.6 : 1);
  const height = TOP_MARGIN + computedAreaHeight + labelHeight - 1;
  const highlightCell = selectedCell;
  const selectedColumn = inPrintMode ? 0 : highlightCell?.x;
  const printX = INVESTMENT_COLUMN_WIDTH + (selectedColumn ?? 0) * cellWidth;
  const tooltipContent = selectedCell ? tooltipRenderer(selectedCell.x, selectedCell.y) : null;

  return (
    <Wrapper>
      <>
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
          onMouseLeave={clearSelection}
          style={{ fontFamily: Typography.fontFamily, fontSize: 14 }}
        >
          {!inPrintMode && <Border y={TOP_MARGIN - 50} />}
          <TopLegend
            y={TOP_MARGIN - 70}
            isPercentage={false}
            value={seriesValue}
            extremes={{ min: -1.0, max: 1.0 }}
            palette={getProformaPortfolioPalette(Colors)}
            thickness={TOP_LEGEND_THICKNESS}
            width={TOP_LEGEND_WIDTH}
            labelFormatter={topLegendFormatter}
            showInsignificant={false}
            print={inPrintMode}
          />
          {!hidden && (
            <>
              <g transform={`translate(0, ${TOP_MARGIN})`}>
                <Border />
                <TopGridLines
                  height={labelHeight}
                  num={nodeNames.length}
                  cellWidth={cellWidth}
                  offset={INVESTMENT_COLUMN_WIDTH}
                />
                <XAxis
                  height={labelHeight}
                  cellWidth={cellWidth}
                  ticks={nodeNames}
                  offset={INVESTMENT_COLUMN_WIDTH}
                  bottomHeight={X_AXIS_BOTTOM_SPACE}
                  angle={X_AXIS_ANGLE}
                  labelFormatter={({ id, type }) => labelFormatter(id, type)}
                  print={inPrintMode}
                />
              </g>
              <g transform={`translate(0, ${TOP_MARGIN + labelHeight})`}>
                <YAxis
                  height={cellHeight}
                  width={INVESTMENT_COLUMN_WIDTH}
                  series={data}
                  hoveringId={selectedCell ? selectedCell.x : null}
                  textAnchor="end"
                  labelFormatter={({ factorId, type }) => {
                    return labelFormatter(factorId?.toString() ?? '', type);
                  }}
                />
                <ExternalActivityListener onExternalActivity={clearSelection} svg listeningEnabled={!!selectedCell}>
                  <HeatMapRenderer
                    leftOffset={INVESTMENT_COLUMN_WIDTH}
                    rightOffset={rightOffset}
                    series={data}
                    cellHeight={cellHeight}
                    cellWidth={cellWidth}
                    onCellMouseEnter={handleCellEnter}
                    isCellClickable={false}
                    print={inPrintMode}
                    showValues={!!showValues}
                  />
                </ExternalActivityListener>
                <Border y={computedAreaHeight} />
                <Lines margin={cellHeight} count={data.length} />
                <TopGridLines
                  height={computedAreaHeight}
                  num={nodeNames.length}
                  cellWidth={cellWidth}
                  offset={INVESTMENT_COLUMN_WIDTH}
                />
                {inPrintMode && (
                  <Values
                    isPercentage={false}
                    labels={['']}
                    source={data}
                    selectedColumn={selectedColumn}
                    height={cellHeight}
                    width={SERIES_COLUMN_WIDTH}
                    offset={width - SERIES_COLUMN_WIDTH}
                  />
                )}
                {!!inPrintMode && (
                  <rect
                    x={printX - 1}
                    y={0}
                    width={cellWidth + 2}
                    height={computedAreaHeight}
                    fill={Colors.White}
                    fillOpacity={0}
                    strokeWidth={2}
                    stroke={Colors.Black}
                  />
                )}
                {highlightCell && tooltipContent && (
                  <Cursor
                    height={cellHeight}
                    width={cellWidth}
                    x={INVESTMENT_COLUMN_WIDTH + highlightCell.x * cellWidth}
                    y={highlightCell.y * cellHeight}
                    areaHeight={computedAreaHeight}
                    areaWidth={width}
                  />
                )}
              </g>
            </>
          )}
        </svg>
        {selectedCell && tooltipContent && (
          <BaseCorrelationChartTooltip
            x={INVESTMENT_COLUMN_WIDTH + (highlightCell?.x ?? 0) * cellWidth}
            y={TOP_MARGIN + labelHeight + (highlightCell?.y ?? 0) * cellHeight}
            cellWidth={cellWidth}
            rightEdge={width}
          >
            {tooltipContent}
          </BaseCorrelationChartTooltip>
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  transform: translate3d(0, 0, 0);

  svg {
    overflow: visible !important;
  }
`;
