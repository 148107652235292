import { Root } from './MultiPortfolioReviewComponents.Root';
import { ConfirmationModal } from './MultiPortfolioReviewComponents.ConfirmationModal';
import { Content } from './MultiPortfolioReviewComponents.Content';
import { LeftSideBar } from './MultiPortfolioReviewComponents.LeftSideBar';
import { RightSideBar } from './MultiPortfolioReviewComponents.RightSideBar';
import { RightSideBarContent } from './MultiPortfolioReviewComponents.RightSideBarContent';

export const MultiPortfolioReviewComponents = {
  Root,
  Content,
  LeftSideBar,
  RightSideBar,
  RightSideBarContent,
  ConfirmationModal,
};
