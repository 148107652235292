import React, { PureComponent } from 'react';
import styled, { css, withTheme } from 'styled-components';
import { find } from 'lodash';
import FactorDescriptionTooltip from './FactorDescriptionTooltip';
import type { Theme } from 'venn-ui-kit';
import { TooltipPosition, Tooltip } from 'venn-ui-kit';
import { Routes } from 'venn-utils';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RISK_FREE_RATE_ID, RESIDUAL_ID } from '../constants';

export interface FactorDescription {
  id: number;
  description: string;
}

export interface FactorSeriesItem {
  factorId?: number;
  factorName: string;
}

export interface FactorSummaryTooltipsProps {
  itemHeight: number;
  topOffset: number;
  series?: FactorSeriesItem[];
  descriptions?: FactorDescription[] | null;
  theme: Theme;
  itemWidth: string | undefined;
}

const Item = styled.div<{ itemHeight: number; width: string | undefined }>`
  font-size: 12px;
  text-transform: uppercase;
  width: ${({ width }) => width ?? '170px'};
  opacity: 0;
  pointer-events: none;
  line-height: ${(props) => props.itemHeight}px;
`;

const WrapperCss = css`
  position: absolute;
  width: 0;
  ${Item} {
    white-space: nowrap;
  }
`;

const LinkWrapper = styled(Link)`
  ${WrapperCss}
`;

const UnclickableWrapper = styled.span`
  ${WrapperCss}
  cursor: text;
`;

type WrapperComponentProps = Omit<LinkProps, 'to'> & {
  isNotRealFactor: boolean;
  serie: FactorSeriesItem;
};

const WrapperComponent = (props: WrapperComponentProps) =>
  props.isNotRealFactor ? (
    <UnclickableWrapper {...props} />
  ) : (
    <LinkWrapper
      target="_blank"
      rel="noopener noreferrer"
      to={`${Routes.FACTOR_LENS_DEEP_DIVE}/${props.serie.factorId}`}
      {...props}
    />
  );

class FactorSummaryTooltips extends PureComponent<FactorSummaryTooltipsProps> {
  render() {
    const {
      series,
      descriptions,
      itemHeight,
      topOffset,
      theme: { Colors },
      itemWidth,
    } = this.props;

    return series?.map((serie, i) => {
      const currentDescription = find(descriptions, {
        id: serie.factorId,
      });
      if (!currentDescription) {
        return null;
      }

      const isNotRealFactor = serie.factorId === RISK_FREE_RATE_ID || serie.factorId === RESIDUAL_ID;
      return (
        <WrapperComponent
          isNotRealFactor={isNotRealFactor}
          serie={serie}
          key={serie.factorName}
          style={{ top: i * itemHeight + topOffset }}
        >
          <Tooltip
            content={<FactorDescriptionTooltip name={serie.factorName} description={currentDescription.description} />}
            position={TooltipPosition.Right}
            background={Colors.White}
            maxWidth={320}
            showShadow
            largerPointer
          >
            <Item itemHeight={itemHeight} width={itemWidth} className="qa-factorName">
              {serie.factorName}
            </Item>
          </Tooltip>
        </WrapperComponent>
      );
    });
  }
}

export default withTheme(FactorSummaryTooltips);
