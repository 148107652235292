import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import {
  ContentBlock,
  EmptyState,
  UncontrolledAllocationConstraintsManagement,
  UncontrolledFactorExposureConstraintsManagement,
  UserContext,
} from 'venn-components';
import { isNil } from 'lodash';
import {
  GetColor,
  Headline3,
  Icon,
  Link as LinkStyle,
  Notifications,
  NotificationType,
  Subtitle1,
  Tooltip,
} from 'venn-ui-kit';
import { getPortfolioLabPath, usePortfolioPolicy } from 'venn-utils';
import { AllocationShimmer, FactorShimmer } from './Shimmers';
import { Link } from 'react-router-dom';

interface PortfolioPolicyProps {
  portfolio?: Portfolio;
}

const PortfolioPolicyContainer = ({ portfolio }: PortfolioPolicyProps) => {
  const {
    loadingPolicy,
    policyFetchingError,
    policyAllocationConstraints,
    policyFactorConstraints,
    factorBreakdownData,
    savingPolicy,
    savingPolicyError,
    onUpdateAllocationConstraints,
    onUpdateFactorConstraints,
  } = usePortfolioPolicy(portfolio, 'Manage Data');
  const { hasPermissionForResource } = useContext(UserContext);

  const isEditable = portfolio && hasPermissionForResource('EDIT_PORTFOLIO', portfolio);

  useEffect(() => {
    if (!isNil(savingPolicyError)) {
      Notifications.notify(
        savingPolicyError ? 'Failed to update portfolio policy' : 'Successfully updated portfolio policy',
        savingPolicyError ? NotificationType.ERROR : NotificationType.SUCCESS,
      );
    }
  }, [savingPolicyError]);

  if (isNil(portfolio)) {
    return null;
  }

  if (policyFetchingError) {
    return (
      <Content>
        <EmptyState header="Unable to fetch portfolio policy" message="Please try again later." />
      </Content>
    );
  }

  const hasNoExposures = factorBreakdownData.filter((item) => !isNil(item.exposure)).length === 0;
  const hasNoInvestments = portfolio.allocation === 0;

  return (
    <Content>
      <LeftRight>
        <Headline3>Allocation Constraints</Headline3>
        <ClearAll
          type="button"
          disabled={loadingPolicy || !policyAllocationConstraints?.length || savingPolicy || !isEditable}
          onClick={() => onUpdateAllocationConstraints([])}
        >
          Clear All Allocation Constraints <Icon type="times-circle" />
        </ClearAll>
      </LeftRight>
      <Subtitle1>Set allocation and turnover constraints for your portfolio, strategies, and investments.</Subtitle1>
      <Editor>
        {loadingPolicy ? (
          <AllocationShimmer />
        ) : (
          <UncontrolledAllocationConstraintsManagement
            portfolio={portfolio}
            allocationConstraints={policyAllocationConstraints}
            onUpdateAllocationConstraints={onUpdateAllocationConstraints}
            disabled={!isEditable}
          />
        )}
      </Editor>
      <Separator />
      <LeftRight>
        <Headline3>Factor Exposure Constraints</Headline3>
        <ClearAll
          type="button"
          disabled={loadingPolicy || !policyFactorConstraints?.length || savingPolicy || !isEditable}
          onClick={() => onUpdateFactorConstraints([])}
        >
          Clear All Exposure Constraints <Icon type="times-circle" />
        </ClearAll>
      </LeftRight>
      <Subtitle1>
        {loadingPolicy || hasNoExposures || hasNoInvestments ? (
          ''
        ) : (
          <>
            {factorBreakdownData.filter((data) => data.significant).length} statistically significant historical factor
            exposures.{' '}
            <Tooltip content="Statistical significance is based on the factor exposures of your current portfolio using up to the last 3 years of returns">
              <Icon type="info-circle" prefix="far" />
            </Tooltip>
          </>
        )}
      </Subtitle1>
      <Editor>
        {loadingPolicy ? (
          <FactorShimmer />
        ) : hasNoExposures || hasNoInvestments ? (
          <EmptyState
            header={
              hasNoInvestments
                ? 'There are no investments in this portfolio'
                : `The returns history of ${portfolio.name} is too short to calculate factor exposures.`
            }
          />
        ) : (
          <UncontrolledFactorExposureConstraintsManagement
            portfolio={portfolio}
            factorExposures={factorBreakdownData}
            factorConstraints={policyFactorConstraints}
            onUpdateFactorConstraints={onUpdateFactorConstraints}
            alwaysShowAllFactors
            disabled={!isEditable}
            canApplyConstraintsToPolicy={isEditable}
          />
        )}
      </Editor>
    </Content>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: PortfolioPolicyProps) => {
  const { hasPermission } = useContext(UserContext);

  return (
    <ContentBlock
      header="Portfolio Policy"
      subHeader={
        <>
          Set a portfolio policy for{' '}
          {isNil(props.portfolio?.id) || !hasPermission('OPTIMIZATION') ? (
            'Portfolio Lab'
          ) : (
            <LinkStyle>
              <Link to={getPortfolioLabPath(props.portfolio.id)}>Portfolio Lab</Link>
            </LinkStyle>
          )}{' '}
          results that better reflect your specific objectives.
        </>
      }
      separator
    >
      <PortfolioPolicyContainer {...props} />
    </ContentBlock>
  );
};

const Content = styled.div`
  padding: 20px;
`;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${GetColor.Grey};
  height: 1px;
  margin: 20px 0;
`;

const Editor = styled.div`
  margin-top: 28px;
`;

const ClearAll = styled.button`
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey1 : GetColor.Primary.Dark)};
  &:hover {
    color: ${({ disabled }) => (disabled ? GetColor.MidGrey1 : GetColor.Primary.Main)};
  }
`;
