import { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import React from 'react';
import { debounce } from 'lodash';

const MOUSE_LEAVE_TIMEOUT_DURATION = 200;
const DEBOUNCE_DURATION = 200;

export interface ExternalActivityListenerProps {
  className?: string;
  listeningEnabled?: boolean;
  onExternalActivity: (event: Event) => void;
  onMouseLeave?: (event: Event) => void;
  debounce?: boolean;
  svg?: boolean;
  ignoreActivityFromClassName?: string | string[];
  style?: React.CSSProperties;
  children?: ReactNode;
  testId?: string;
  ignoreFocusEvents?: boolean;
}

const ExternalActivityListener = ({
  listeningEnabled = true,
  onMouseLeave = undefined,
  debounce: propsDebounce = true,
  testId = 'external-activity-listener',
  ignoreFocusEvents,
  ignoreActivityFromClassName = [],
  onExternalActivity,
  ...props
}: ExternalActivityListenerProps) => {
  const container = useRef<HTMLElement | SVGGElement | null>();
  const checkIfNodeContainsClass = useCallback((target: Node, className: string) => {
    // Check if the node has className that indicates we should ignore clicks coming from it
    if (target instanceof Element && target.className.includes?.(className)) {
      return true;
    }

    // Check if the node is a descendant of a node has className that indicates we should ignore clicks coming from it
    const ignoreNodes = Array.from(document.getElementsByClassName(className));
    return ignoreNodes.some((ignoreNode) => ignoreNode.contains(target));
  }, []);

  const shouldIgnoreNodeActivity = useCallback(
    (target: Node) => {
      if (!(target instanceof Element)) {
        return false;
      }

      const ignoredClassNames = Array.isArray(ignoreActivityFromClassName)
        ? ignoreActivityFromClassName
        : [ignoreActivityFromClassName];

      return ignoredClassNames.filter(Boolean).some((className) => checkIfNodeContainsClass(target, className));
    },
    [checkIfNodeContainsClass, ignoreActivityFromClassName],
  );

  /**
   * Checks if the event is a focus event on <main> tag
   * We should ignore such events because main tag covers the entire page
   *
   * I think it was not focusable previously but seems like recently Chrome made it focusable
   * Unfortunately it's up to each browser to decide what elements they consider focusable as there is no standard
   */
  const isMainTagFocused = useCallback((event: Event) => {
    const isFocusEvent = event.type === 'focus';
    const isMainTag = event.target instanceof HTMLElement && event.target.tagName === 'MAIN';
    return isFocusEvent && isMainTag;
  }, []);

  /**
   * This is a fix to a specific bug: if the event comes from a (now) unmounted node
   * Then it would have been considered an external event, instead of an internal one.
   */
  const isUnmounted = useCallback((target: Node): boolean => {
    if (target.parentNode === document) {
      return false;
    }
    if (!target.parentNode) {
      return true;
    }
    return isUnmounted(target.parentNode);
  }, []);

  const _handleWindowEvent = useCallback(
    (event: Event) => {
      const target = event?.target;
      const _isUnmounted = target instanceof Node ? isUnmounted(target) : false;

      if (
        listeningEnabled &&
        !_isUnmounted &&
        target !== window &&
        target instanceof Node &&
        container.current &&
        container.current.contains &&
        !container.current.contains(target) &&
        !shouldIgnoreNodeActivity(target) &&
        !isMainTagFocused(event)
      ) {
        onExternalActivity(event);
      }
    },
    [isMainTagFocused, isUnmounted, listeningEnabled, onExternalActivity, shouldIgnoreNodeActivity],
  );

  const _handleMouseLeaveEvent = useCallback(
    (event: Event) => {
      if (!onMouseLeave || !container.current || event.target !== container.current) {
        return;
      }

      function enterListener(enterEvent: Event) {
        if (container.current && enterEvent.target === container.current) {
          window.clearTimeout(timeout);
          container.current.removeEventListener('mouseenter', enterListener);
        }
      }

      function timerExpired() {
        onMouseLeave && onMouseLeave(event);
        container.current && container.current.removeEventListener('mouseenter', enterListener);
      }

      const timeout = window.setTimeout(timerExpired, MOUSE_LEAVE_TIMEOUT_DURATION);
      container.current.addEventListener('mouseenter', enterListener);
    },
    [onMouseLeave],
  );
  const debouncedHandleMousedownEvent = useMemo(
    () => debounce(_handleWindowEvent, DEBOUNCE_DURATION),
    [_handleWindowEvent],
  );
  const debouncedHandleFocusEvent = useMemo(
    () => debounce(_handleWindowEvent, DEBOUNCE_DURATION),
    [_handleWindowEvent],
  );
  const debouncedHandleMouseLeaveEvent = useMemo(
    () => debounce(_handleMouseLeaveEvent, DEBOUNCE_DURATION),
    [_handleMouseLeaveEvent],
  );
  const handleMousedownEvent = propsDebounce ? debouncedHandleMousedownEvent : _handleWindowEvent;
  const handleFocusEvent = propsDebounce ? debouncedHandleFocusEvent : _handleWindowEvent;
  const handleMouseLeaveEvent = propsDebounce ? debouncedHandleMouseLeaveEvent : _handleMouseLeaveEvent;

  useEffect(() => {
    const addActivityListeners = () => {
      window.addEventListener('mousedown', handleMousedownEvent, false);
      // Focus events don't bubble up so we need to use capture phase to handle them (by passing true as the third argument)
      !ignoreFocusEvents && window.addEventListener('focus', handleFocusEvent, true);

      if (onMouseLeave && container.current) {
        container.current.addEventListener('mouseleave', handleMouseLeaveEvent, false);
      }
    };

    const removeActivityListeners = () => {
      window.removeEventListener('mousedown', handleMousedownEvent, false);
      !ignoreFocusEvents && window.removeEventListener('focus', handleFocusEvent, true);

      if (onMouseLeave && container.current) {
        container.current.removeEventListener('mouseleave', handleMouseLeaveEvent, false);
      }
    };
    /**
     * using 0 timeout to ignore the clicks that opened this modal as recommended by react team
     * https://github.com/facebook/react/issues/24657#issuecomment-1150119055
     */
    const timeoutId = setTimeout(() => {
      if (listeningEnabled) {
        addActivityListeners();
      }
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      if (listeningEnabled) {
        removeActivityListeners();
      }
    };
  }, [
    handleFocusEvent,
    handleMouseLeaveEvent,
    handleMousedownEvent,
    ignoreFocusEvents,
    listeningEnabled,
    onMouseLeave,
  ]);

  const { svg } = props;
  if (svg) {
    return (
      <g
        className={`${props.className} qa-chart-container`}
        ref={(node) => {
          container.current = node;
        }}
        data-testid={testId}
      >
        {props.children}
      </g>
    );
  }
  return (
    <div
      className={props.className}
      ref={(node) => {
        container.current = node;
      }}
      style={props.style}
      data-testid={testId}
    >
      {props.children}
    </div>
  );
};

export default ExternalActivityListener;
