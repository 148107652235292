import React from 'react';
import type { CellRenderer } from './types';
import type { DataUploaderMode } from '../../types';
import { toOptionNumber } from '../../views/mapping/helpers';
import type { ErrorViewModel } from '../../views/review/helpers';
import type { ColumnMapping, FileMapping, FileUploadMetadata } from 'venn-api';
import { isCellDisabled } from './helpers';
import { PrivateFundCell } from '../review-data/PrivateFundCell';
import { useRecoilState, useRecoilValue } from 'recoil';
import { hasUncorrectedErrors } from '../../views/review/helpers';
import { isDeletedPrivateFund, uploadAppendTypePrivateFund } from 'venn-state';
import type { FundCellSetting } from '../map-data/NavsFundCell';

interface PrivateFundCellRendererProps {
  mapping: FileMapping;
  columns: ColumnMapping[];
  metadata: FileUploadMetadata;
  onChange: (newMapping: FileMapping) => void;
  columnMapping: ColumnMapping;
  readOnly: boolean;
  mode: DataUploaderMode;
  index: number;
  count: number;
  errors: ErrorViewModel[];
}

// TODO: fix unused fields
// @ts-expect-error: TODO fix strictFunctionTypes
export const PrivateFundCellRenderer: CellRenderer = ({
  // eslint-disable-next-line unused-imports/no-unused-vars
  mapping,
  // eslint-disable-next-line unused-imports/no-unused-vars
  columns,
  metadata,
  // eslint-disable-next-line unused-imports/no-unused-vars
  onChange,
  columnMapping,
  readOnly,
  // eslint-disable-next-line unused-imports/no-unused-vars
  mode,
  errors,
}: PrivateFundCellRendererProps) => {
  const { fundName, fundId, seriesId } = columnMapping;
  const isDeleted = useRecoilValue(isDeletedPrivateFund(seriesId));
  const isDisabled = isCellDisabled(columnMapping, metadata, errors, seriesId);
  const isNew = !fundId;

  const [appendId, setAppendId] = useRecoilState(uploadAppendTypePrivateFund(seriesId));

  const settingsOptions: FundCellSetting[] = [];
  const settings: (string | number)[] = [];

  if (!isNew) {
    settingsOptions.push({
      options: metadata.appendType.map(toOptionNumber),
      readonly: readOnly || hasUncorrectedErrors(errors, seriesId),
      isAppendSetting: true,
    });
    settings.push(appendId);
  }

  return (
    <PrivateFundCell
      isNew={isNew}
      canLink={!readOnly && !isDisabled}
      opensUp={false}
      onLink={() => {}}
      onLinkNew={() => {}}
      fund={fundName}
      origin={{
        column: 0,
        value: fundName,
      }}
      disabled={isDisabled}
      alwaysEnableAppendTypeSelector={!isDeleted}
      settingsOptions={settingsOptions}
      settings={settings}
      onSettingsChanged={(changedSettings) => {
        setAppendId(changedSettings[0] as number);
      }}
    />
  );
};
