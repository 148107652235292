/**
 * This is adjust from https://github.com/okonet/react-container-dimensions
 * For detect print media
 * And we only need width dimension
 */

import type { ReactElement } from 'react';
import React, { useLayoutEffect, useRef, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useAppPrintMode } from './AppPrintMode';

const DEFAULT_WIDTH = 704;
interface PrintContainerDimensionsProps {
  children: ({ width, print }: { width: number; print: boolean }) => ReactElement<unknown>;
}

/**
 * Attaches a resize observer to the parent of this component and provides the measured width of the parent element to the children of this component.
 * Note that I said parent, not root. This measures the parent element that contains this component. It does not measure the root element inside this component.
 * Also checks {@link useAppPrintMode} to see if the app is in print mode, and provides that state to the children as well for legacy reasons.
 *
 * Be careful not to cause render loops where the child resizes, causing the parent to resize, causing the child to resize.
 *
 * There are often better ways to do this, so consider if you truly need this component.
 * * Alternatives to using this component when you need print media state is to use useAppPrintMode directly.
 * * Alternatives to using this component for measurement are to use responsive layout techniques (Flexbox, Grid, % width such as
 * calc(100% - ${padding}px)), relative/absolute positioning, etc).
 */
const PrintContainerDimensions = React.memo(function PrintContainerDimensions({
  children,
}: PrintContainerDimensionsProps) {
  const parentElement = useRef<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const { inPrintMode } = useAppPrintMode();

  const renderContent = useCallback(
    (print: boolean, width: number): React.ReactNode => {
      if (print) {
        return children({ width: getWidth(parentElement.current), print });
      }
      const renderChildren = children({ width, print });
      if (renderChildren) {
        return renderChildren;
      }
      return <div />;
    },
    [children],
  );

  const onResize = useCallback(() => {
    setWidth(getWidth(parentElement.current));
  }, []);

  // Could use refs to avoid creating a new ResizeObserver when onResize changes, but onResize should never change
  const resizeObserver = useMemo(() => new ResizeObserver(onResize), [onResize]);

  useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      parentElement.current = node.parentElement;
    }

    const parent = parentElement.current;
    setWidth(getWidth(parent));
    parent && resizeObserver.observe(parent);
    return () => {
      setWidth(undefined);
      parent && resizeObserver.unobserve(parent);
    };
  }, [resizeObserver]);

  return <Container ref={ref}>{width ? renderContent(inPrintMode, width) : null}</Container>;
});

const Container = styled.div`
  width: 100%;
`;

export default PrintContainerDimensions;

function getWidth(element: HTMLElement | null): number {
  if (!element) {
    return DEFAULT_WIDTH;
  }
  return element.getBoundingClientRect().width;
}
