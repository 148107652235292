import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';
import { analysis, type PrivateAssetSummaryResponse } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';
import { blockLimitedRequestSubjects, convertStudioSubjectToApiSubject } from '../../../../venn-state/src';
import { useBlockId } from '../contexts/BlockIdContext';

const ONE_HOUR = 1000 * 60 * 60;

export const usePrivateAssetSummaryAnalysis = () => {
  const blockId = useBlockId();

  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));

  const { data } = useQuery<PrivateAssetSummaryResponse | null>(
    ['usePrivateAssetSummaryAnalysis', subjects],
    async () => {
      if (isEmpty(subjects)) {
        return null;
      }

      const response =
        (
          await analysis({
            analyses: [
              {
                analysisType: 'PRIVATE_ASSET_SUMMARY',
              },
            ],
            subjects: subjects.map((subject) => convertStudioSubjectToApiSubject(subject, 'PRIMARY', true)),
          })
        )?.content?.analyses?.[0]?.privateAssetSummary?.[0] ?? null;

      return response;
    },
    {
      onError: (error) => {
        logExceptionIntoSentry(error as Error);
      },
      suspense: true,
      staleTime: ONE_HOUR,
    },
  );

  return data;
};
