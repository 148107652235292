import type { PortfolioMultiUploaderView } from '../../types';
import { ColorUtils, Flexbox, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import React, { type Dispatch, type SetStateAction } from 'react';
import { useOptionPermissions } from './useOptionPermissions';
import { useHasFF } from 'venn-utils';

type OptionTileProps = {
  option: PortfolioMultiUploaderView;
  onClick: () => void;
  setViewTileHovered?: Dispatch<SetStateAction<PortfolioMultiUploaderView | undefined>>;
};

export const OptionTile = ({ option, onClick, setViewTileHovered }: OptionTileProps) => {
  const { disabled, reason } = useOptionPermissions(option);
  const hasHistoricals = useHasFF('historical_portfolios_ff');
  return (
    <Tooltip isHidden={!disabled} content={reason}>
      <OptionTileButton
        disabled={disabled}
        data-testid={`option-tile-${option.id}`}
        aria-label={`option-tile-${option.id}`}
        key={option.id}
        onClick={onClick}
        onMouseEnter={() => setViewTileHovered && setViewTileHovered((_) => option)}
        onMouseLeave={() => setViewTileHovered && setViewTileHovered((_) => undefined)}
      >
        <Flexbox direction="column" justifyContent="flex-start" alignItems="flex-start" gap={4}>
          <OptionHeader disabled={disabled}>
            {disabled && <StyledIcon type="lock" />}
            {hasHistoricals && option.shortTitle ? option.shortTitle : option.title}
          </OptionHeader>
          {!disabled && (
            <>
              <OptionDescription>{option.description}</OptionDescription>
              {option.highlightText && (
                <span>
                  <Badge>{hasHistoricals ? 'NEW' : 'BETA'}</Badge>
                  &nbsp;
                  <HighlightedFeatureDescription>{option.highlightText}</HighlightedFeatureDescription>
                </span>
              )}
            </>
          )}
        </Flexbox>
        {!disabled && <StyledAngleIcon type="angle-right" />}
      </OptionTileButton>
    </Tooltip>
  );
};

const StyledAngleIcon = styled(Icon)`
  font-size: 16px;
  color: ${GetColor.DarkBlue};
`;

const OptionTileButton = styled.button<{ disabled: boolean }>`
  display: flex;
  :disabled {
    pointer-events: none;
  }
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  align-items: center;
  width: 100%;
  border: 1px solid ${(props) => (props.disabled ? GetColor.GreyScale.Grey30 : GetColor.DarkBlue)};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
  text-align: left;

  &:hover {
    border-color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey30 : GetColor.DarkBlue)};
    background-color: ${(props) =>
      props.disabled
        ? ColorUtils.opacifyFrom(GetColor.GreyScale.Grey80, 0.1)
        : ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.05)};
  }
`;

const HighlightedFeatureDescription = styled.span`
  color: ${GetColor.HighlightDark};
  font-size: 14px;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  line-height: 1.5;
  font-weight: 700;
  font-size: 10px;
  height: 14px;
`;

const OptionHeader = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey80 : GetColor.DarkBlue)};
  font-size: 20px;
`;

const StyledIcon = styled(Icon)`
  font-size: 14px;
`;

const OptionDescription = styled.div`
  color: ${GetColor.GreyScale.Grey70};
  font-size: 14px;
`;
