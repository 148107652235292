import type { FC } from 'react';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

export interface CellButtonProps {
  /** The color of the button */
  backgroundColor: string;
  /** If not provided, it will be the same as the background color */
  borderColor?: string;
  /** If not provided, it will be the same as the border color */
  hoverBackgroundColor?: string;
  /** Defaults to white if not provided */
  textColor?: string;
  /** Defaults to the text color if not provided */
  hoverTextColor?: string;
  /** If true, only show this button on hover */
  showOnHover?: boolean;
}

const CellButton: FC<React.PropsWithChildren<CellButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>> = ({
  backgroundColor,
  borderColor,
  hoverBackgroundColor,
  textColor,
  hoverTextColor,
  showOnHover,
  onClick,
  ...rest
}) => {
  const { Colors } = useContext(ThemeContext);
  const actualBorderColor = borderColor || backgroundColor;
  const actualHoverBackgroundColor = hoverBackgroundColor || actualBorderColor;
  const actualTextColor = textColor || Colors.White;
  const actualHoverTextColor = hoverTextColor || actualTextColor;
  return (
    <StyledButton
      data-testid="qa-cell-button"
      backgroundColor={backgroundColor}
      borderColor={actualBorderColor}
      hoverBackgroundColor={actualHoverBackgroundColor}
      textColor={actualTextColor}
      hoverTextColor={actualHoverTextColor}
      showOnHover={showOnHover}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...rest}
    />
  );
};

export default CellButton;

const StyledButton = styled.button<CellButtonProps>`
  width: 85px;
  height: 20px;
  border-radius: 10px;
  ${(props) => css`
    border: solid 1px ${props.borderColor};
    background-color: ${props.backgroundColor};
    color: ${props.textColor};
    &:hover {
      background-color: ${props.hoverBackgroundColor};
      color: ${props.hoverTextColor};
    }
    ${props.showOnHover
      ? css`
          display: none;
          tr:hover & {
            display: block;
          }
        `
      : ''}
  `}
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  line-height: 1.6;
  i {
    margin-right: 5px;
  }
`;
