import React, { useEffect, useRef, useState, useMemo } from 'react';
import type { OptionType } from 'venn-ui-kit';
import { Icon, GetColor, TypeaheadMenu, ZIndex } from 'venn-ui-kit';
import styled from 'styled-components';
import _ from 'lodash';

interface SearchBarProps {
  value?: string;
  debounce?: number;
  placeholder?: string;
  onChange: (value: string) => void;
  onSelect?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disableAutofocus?: boolean;
  dropdownOptionsHeader?: string;
  dropdownOptions?: string[];
  /** Ref for the search input, useful for controlling the input from the parent to focus, blur, etc */
  inputRefProp?: React.MutableRefObject<HTMLInputElement | undefined>;
}

/**
 * Similar to {@link SearchBar} except that this accepts a list of options
 * to use as a typeahead menu.
 */
const TypeaheadSearchBar = ({
  disableAutofocus,
  debounce,
  value = '',
  onChange,
  onFocus,
  onBlur,
  onSelect,
  placeholder,
  dropdownOptionsHeader,
  dropdownOptions,
  inputRefProp,
}: SearchBarProps) => {
  const [searchString, setSearchString] = useState(value);
  const debouncedOnChange = _.debounce(onChange, debounce, { leading: true });
  const propagateSetSearchString = (val: string) => {
    setSearchString(val);
    debouncedOnChange(val);
  };
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const handleSelect = (item: OptionType<string>) => {
    if (item.value) {
      propagateSetSearchString(item.value);
      onSelect && onSelect(item.value);
    }
    inputRef.current?.blur();
  };

  const clearSearchQuery = () => {
    propagateSetSearchString('');
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (!disableAutofocus) {
      inputRef.current?.focus();
    }
  }, [disableAutofocus]);

  const options = useMemo(() => {
    if (!dropdownOptions) {
      return [];
    }

    const filteredOptions = dropdownOptions
      .filter((option) => option.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
      .map((s) => ({
        value: s,
        children: (
          <SearchOption>
            <Icon type="search" />
            {'  '}
            {s}
          </SearchOption>
        ),
      }));
    const slicedOptions = searchString.length > 0 ? filteredOptions.slice(0, 1) : filteredOptions;

    if (!dropdownOptionsHeader) {
      return slicedOptions;
    }
    return slicedOptions.length
      ? [{ header: <SearchOptionHeader>{dropdownOptionsHeader}</SearchOptionHeader> }, ...slicedOptions]
      : [];
  }, [dropdownOptions, dropdownOptionsHeader, searchString]);

  return (
    <Container className="qa-typeahead-searchbar">
      <LeftSearchIcon type="search" prefix="far" />
      <StyledTypeaheadMenu
        value={searchString}
        className="qa-search-typeahead"
        query={searchString}
        placeholder={placeholder}
        onChange={propagateSetSearchString}
        onFocus={onFocus}
        options={options}
        onSelect={handleSelect}
        onBlur={onBlur}
        autoFocus={!disableAutofocus}
        setInputRef={(input) => {
          inputRef.current = input;
          if (inputRefProp) {
            inputRefProp.current = input;
          }
        }}
        showOptions={Boolean(options.length)}
      />
      {searchString && <SearchIcon type="times-circle" onClick={clearSearchQuery} prefix="fas" />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const SearchOptionHeader = styled.span`
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
`;

const SearchOption = styled.span`
  padding-left: 20px;
  i {
    margin-right: 10px;
  }
`;

const StyledTypeaheadMenu = styled(TypeaheadMenu)`
  width: 100%;
  max-width: none;
  border: none;
  section {
    border: none;
  }
  input {
    width: 100%;
    border: 1px solid ${GetColor.Grey};
    border-radius: 4px;
    height: 35px;
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-weight: normal;
    font-size: 14px;
    padding-left: 35px;

    &:focus {
      border: 1px solid ${GetColor.Primary.Main};
    }
    &::placeholder {
      color: ${GetColor.HintGrey};
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }
` as typeof TypeaheadMenu;

const SearchIcon = styled(Icon)`
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 12px;
  color: ${GetColor.MidGrey2};

  &.fa-times-circle {
    cursor: pointer;
  }
`;

const LeftSearchIcon = styled(Icon)`
  font-size: 18px;
  position: absolute;
  left: 10px;
  top: 9px;
  color: ${GetColor.MidGrey2};
  z-index: ${ZIndex.Front};

  &.fa-times-circle {
    cursor: pointer;
  }
`;

export default TypeaheadSearchBar;
