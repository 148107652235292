import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import {
  CustomAnalysisBlock,
  CustomHoldingsBlock,
  PrivatesBlock,
  PortfolioComparisonBlock,
  useAppPrintMode,
  TextAndFormatBlock,
} from 'venn-components';
import { blockSettings, isReportState } from 'venn-state';
import { isAnalysisBlock, isHoldingsBlock, isPortfolioBlock, isPrivatesBlock, isTextOrFormatBlock } from 'venn-utils';
import { Block } from './components/core/Block';
import DeprecatedBlockHeader from './DeprecatedBlockHeader';

export const BLOCK_CONTAINER_CLASS = 'venn-block-container';
/**
 * You can set either the container width for the block or the intended width of the block, but not both.
 * TODO(collin.irwin): Receiving Width and ContainerWidth both is pretty confusing. Can we refactor this to just getting a single width,
 * or maybe some other clearer architecture?
 */
type BlockWidths =
  | {
      width: number;
      containerWidth?: undefined;
    }
  | { width?: undefined; containerWidth: number };

type BlockProps = {
  id: string;
  index: number;
  externalBlockWrapper?: boolean;
  focusBlockIndex: React.MutableRefObject<number | undefined>;
  focusBlockRef: React.RefObject<HTMLDivElement>;
} & BlockWidths;

const DeprecatedBlock = React.memo(function DeprecatedBlock({
  id,
  index,
  width,
  externalBlockWrapper,
  focusBlockIndex,
  focusBlockRef,
  containerWidth,
}: BlockProps) {
  const blockSetting = useRecoilValue(blockSettings(id));
  const localBlockRef = useRef<HTMLDivElement>(null);
  const isReport = useRecoilValue(isReportState);
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const blockRef = (focusBlockIndex.current === index ? focusBlockRef : undefined) || localBlockRef;
  const rootProps = {
    id,
    index,
    externalBlockWrapper,
    focusBlockIndex,
    focusBlockRef,
    blockRef,
    blockWidths: { width, containerWidth } as BlockWidths,
  };

  if (isTextOrFormatBlock(blockSetting.customBlockType)) {
    return (
      <Block.Root {...rootProps}>
        <TextAndFormatBlock id={id} />
      </Block.Root>
    );
  }
  return (
    <Block.Root {...rootProps}>
      <DeprecatedBlockHeader />
      <Block.ContentWrapper>
        {isHoldingsBlock(blockSetting.customBlockType) && (
          <CustomHoldingsBlock
            isPrinting={inPrintModeOrReportLab}
            downloadableContentRef={blockRef}
            isReport={isReport}
            selectedRefId={id}
          />
        )}
        {isPrivatesBlock(blockSetting.customBlockType) && (
          <PrivatesBlock
            isPrinting={inPrintModeOrReportLab}
            downloadableContentRef={blockRef}
            isReport={isReport}
            selectedRefId={id}
          />
        )}
        {isPortfolioBlock(blockSetting.customBlockType) && <PortfolioComparisonBlock />}
        {isAnalysisBlock(blockSetting.customBlockType) && (
          <CustomAnalysisBlock
            isPrinting={inPrintModeOrReportLab}
            downloadableContentRef={blockRef}
            isReport={isReport}
            selectedRefId={id}
          />
        )}
      </Block.ContentWrapper>
    </Block.Root>
  );
});

export default DeprecatedBlock;
