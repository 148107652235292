import React, { useRef, useState } from 'react';
import { Content, Error, Field, Input, Label, SubHeader } from '../account-modals/shared';
import { BackupCodes } from './BackupCodes';
import { Icon } from 'venn-ui-kit';
import { ModalFooter } from '../../modal';
import AuthenticatorDetails from './AuthenticatorDetails';
import useEnableAuthenticator from './useEnableAuthenticator';

interface EnableAuthenticatorProps {
  onBack: undefined | (() => void);
  onClose: () => void;
  refresh: () => void;
}

const AUTH_BACKUP_CONFIRM_MESSAGE = 'the Google Authenticator app.';
const BACKUP_HEADER = 'Save your backup codes.';

export const EnableAuthenticator = ({ refresh, onBack, onClose }: EnableAuthenticatorProps) => {
  const [showBackupCodes, setShowBackupCodes] = useState(false);
  const [showManualEntry, setShowManualEntry] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    authenticatorInfo,
    code,
    onVerificationCodeChange,
    onSubmit: onSubmitVerificationCode,
    loading,
    error,
  } = useEnableAuthenticator({
    onComplete: () => {
      setShowBackupCodes(true);
      refresh();
    },
  });

  const toggleManualEntry = () => {
    setShowManualEntry((prevState) => !prevState);
  };

  return (
    <>
      <Content>
        <SubHeader>
          <Icon type="google" prefix="fab" />
          &nbsp;Enable Google Authenticator
        </SubHeader>
      </Content>
      <form onSubmit={onSubmitVerificationCode} ref={formRef}>
        <Content>
          <Field>
            {!showBackupCodes && (
              <>
                <AuthenticatorDetails
                  showManualEntry={showManualEntry}
                  authenticatorInfo={authenticatorInfo}
                  loading={loading}
                  toggleManualEntry={toggleManualEntry}
                />
                <Label>Enter the pin code shown on the Authenticator on your smartphone:</Label>
                <Input
                  defaultValue={code}
                  placeholder="Authentication code"
                  onChange={(e) => onVerificationCodeChange(e.target.value)}
                />
              </>
            )}
          </Field>
        </Content>
        {showBackupCodes && (
          <BackupCodes onClose={onClose} header={BACKUP_HEADER} confirmMessage={AUTH_BACKUP_CONFIRM_MESSAGE} />
        )}
        <Content>
          <Error>{error}</Error>
        </Content>
        {!showBackupCodes && (
          <ModalFooter
            primaryLabel="Next"
            primaryDisabled={!code}
            onPrimaryClick={() => typeof formRef.current?.onSubmit === 'function' && formRef.current.onSubmit()}
            onCancel={onBack ?? onClose}
            cancelLabel={onBack ? 'Back' : 'Cancel'}
          />
        )}
      </form>
    </>
  );
};

export default EnableAuthenticator;
