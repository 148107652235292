import { useContext, useCallback } from 'react';
import {
  ExportContext,
  DEFAULT_IMAGE_EXPORT_SCALE,
  UserContext,
  getDownloadableDataKeys,
  getDownloadableImagesBlocks,
} from 'venn-components';
import JSZip from 'jszip';
import { generateZipFileName, getBundleZip, getImageZip, getDataZip, autoSaveFile } from './helper';
import type { ExportMetaData } from './types';
import type { UploadMetaData, ExportTypeEnum } from 'venn-api';
import { IMAGE_EXPORT_SCALE_KEY } from 'venn-api';
import { ThemeContext } from 'styled-components';
import saveAs from 'file-saver';

/**
 * @param exportMetaData contain information for exporting history
 */
const useAnalysisExport = (exportMetaData: ExportMetaData) => {
  const { exportData, updateDownloadInfo } = useContext(ExportContext);
  const theme = useContext(ThemeContext);
  const { settings } = useContext(UserContext);
  const imageScale = settings?.user?.[IMAGE_EXPORT_SCALE_KEY] ?? DEFAULT_IMAGE_EXPORT_SCALE;
  const hasDataToExport = getDownloadableDataKeys(exportData).length;
  const hasImageToExport = !!getDownloadableImagesBlocks()?.length;

  const saveCallback = useCallback(
    (blob: Blob | null, uploadMetaData: UploadMetaData, exportType: ExportTypeEnum) => {
      if (blob) {
        saveAs(blob, uploadMetaData.name);
        autoSaveFile(blob, { ...uploadMetaData, exportType });
      }
      updateDownloadInfo?.('');
    },
    [updateDownloadInfo],
  );

  const handleExport = useCallback(
    (exportType: ExportTypeEnum) => {
      const fileName = generateZipFileName(exportMetaData.viewName || 'Unsaved view');
      updateDownloadInfo?.(fileName, !!exportMetaData.savedId);
      const metaData = { ...exportMetaData, viewName: fileName };
      // Put bundle zip file into async thread to make sure
      // we don't block the ui render of loading modal
      setTimeout(() => {
        if (exportType === 'PNG') {
          getImageZip(new JSZip(), metaData, saveCallback, theme, imageScale);
        }

        if (exportType === 'XLSX') {
          getDataZip(new JSZip(), exportData, metaData, saveCallback);
        }
        if (exportType === 'ALL') {
          getBundleZip(exportData, new JSZip(), metaData, saveCallback, theme, imageScale);
        }
      }, 0);
    },
    [exportData, saveCallback, updateDownloadInfo, exportMetaData, theme, imageScale],
  );

  const handleExportAsImage = useCallback(() => {
    handleExport('PNG');
  }, [handleExport]);

  const handleExportAsData = useCallback(() => {
    handleExport('XLSX');
  }, [handleExport]);

  const handleExportAll = useCallback(() => {
    handleExport('ALL');
  }, [handleExport]);

  return {
    handleExportAsImage: hasImageToExport ? handleExportAsImage : undefined,
    handleExportAsData: hasDataToExport ? handleExportAsData : undefined,
    handleExportAll: hasImageToExport && hasDataToExport ? handleExportAll : undefined,
  };
};

export default useAnalysisExport;
