import type { Sponsor } from 'venn-api';
import { getSponsor, updateSponsor } from 'venn-api';
import { useCallback, useContext, useEffect, useState } from 'react';
import { logExceptionIntoSentry } from 'venn-utils';
import { UserContext } from 'venn-components';

interface UseSponsorCustomizationValue {
  sponsor?: Sponsor;
  loading: boolean;
  refresh: () => void;
  update: (displayName?: string, contactEmail?: string, colorScheme?: string) => void;
}

const useSponsorCustomization = (accountId: number, onUpdate: () => void): UseSponsorCustomizationValue => {
  const [sponsor, setSponsor] = useState<Sponsor>();
  const [loading, setLoading] = useState<boolean>(true);
  const { refresh: refreshUserContext } = useContext(UserContext);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const { content } = await getSponsor(accountId);
      setSponsor(content);
      setLoading(false);
    } catch (e) {
      logExceptionIntoSentry(e);
      setLoading(false);
    }
  }, [accountId]);

  const update = useCallback(
    async (displayName?: string, contactEmail?: string, colorScheme?: string) => {
      try {
        setLoading(true);
        const sponsorUpdate = {
          displayName,
          contactEmail,
          colorScheme,
          accountId,
        };
        const { content } = await updateSponsor(sponsorUpdate);

        refreshUserContext();
        setSponsor(content);
        setLoading(false);
        onUpdate();
      } catch (e) {
        logExceptionIntoSentry(e);
        setLoading(false);
      }
    },
    [accountId, onUpdate, refreshUserContext],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    loading,
    sponsor,
    refresh,
    update,
  };
};

export default useSponsorCustomization;
