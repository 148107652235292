import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import TableRowMenuItem from './options/TableRowMenuItem';
import TriggerableMenu from './TriggerableMenu';
import { ColorUtils } from '../../style/colorUtils';
import { GetColor } from '../../style/color';
import Icon from '../icon/Icon';
import type { RelativePortalProps } from '../relative-portal/RelativePortal';

export interface TableRowMenuOption {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  renderWrapper?: (item: React.ReactNode) => React.ReactNode;
}

interface TableRowOptionsMenuProps {
  options: TableRowMenuOption[];
  onToggle?: (open: boolean) => void;
  menuClassName?: string;
  usePortal?: boolean;
  /** Props passed to the portal component, only effective is usePortal is true */
  portalProps?: Partial<RelativePortalProps>;
  triggerClassName?: string;
}

export const TableRowOptionsMenu = ({
  options,
  onToggle,
  menuClassName = 'menu',
  triggerClassName,
  usePortal,
  portalProps,
}: TableRowOptionsMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const menuOptions = useMemo(
    () =>
      options.map(({ label, onClick, disabled, loading, renderWrapper = (item) => item }) => ({
        children: renderWrapper(
          <TableRowMenuItem label={label} onClick={onClick} disabled={disabled} loading={loading} />,
        ),
        disabled,
        value: undefined,
      })),
    [options],
  );
  const toggleOpen = useCallback(() => {
    onToggle?.(open);
    setOpen(!open);
  }, [onToggle, open]);
  return (
    <StyledTriggerableMenu
      options={menuOptions}
      onToggle={toggleOpen}
      usePortal={usePortal}
      portalProps={portalProps}
      innerClassName={menuClassName}
    >
      <StyledIcon
        data-testid="table-row-options-menu"
        type="ellipsis-h"
        prefix={open ? 'fas' : 'far'}
        className={`${open ? 'open ' : ''}${triggerClassName}`}
      />
    </StyledTriggerableMenu>
  );
};

export default TableRowOptionsMenu;
const StyledTriggerableMenu = styled(TriggerableMenu)`
  min-height: 28px;
  ${({ innerClassName }) => css`
    .${innerClassName} {
      transform: translate3d(-90%, 6px, 0);
      box-shadow: 0 2px 10px 0 ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.4)};
      width: 240px;
      max-height: 240px;

      .greyed-option {
        color: ${GetColor.HintGrey};
        background-color: ${GetColor.PaleGrey};
      }
      .large-option {
        padding-top: 8px;
        padding-bottom: 8px;
        > div {
          > span {
            white-space: inherit;
          }
          line-height: inherit;
          text-align: left;
          height: auto;
          white-space: inherit;
        }
      }
    }
  `}
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${GetColor.MidGrey2};
  font-size: 16px;
  padding: 3px;
  border-radius: 2px;
  :hover {
    background-color: ${GetColor.Grey};
  }
  &.open {
    background-color: ${GetColor.Grey};
    color: ${GetColor.Black};
  }
`;
