import React from 'react';
import { Button } from 'venn-ui-kit';
import { EmptyStateContent, ScreenOnly } from '../components/styled';

interface ErrorInvalidAnalysisPeriodProps {
  buttonTitle?: string;
  defaultMessage?: string;
  onResetAnalysisPeriod?: () => void;
}

const ErrorInvalidAnalysisPeriod: React.FC<React.PropsWithChildren<ErrorInvalidAnalysisPeriodProps>> = ({
  buttonTitle = 'Reset Analysis Period',
  defaultMessage = 'Your selected analysis period is preventing the analysis from running.',
  onResetAnalysisPeriod,
}) => {
  return (
    <EmptyStateContent>
      {defaultMessage}
      <ScreenOnly style={{ marginTop: 40 }}>
        <Button className="qa-reset-button" onClick={onResetAnalysisPeriod}>
          {buttonTitle}
        </Button>
      </ScreenOnly>
    </EmptyStateContent>
  );
};
export default ErrorInvalidAnalysisPeriod;
