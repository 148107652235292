import React from 'react';
import type { PeerGroupAnalysisResponse } from 'venn-api';
import { usePeerGroupsChartConfig } from './usePeerGroupsChartConfig';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { StudioHighchart } from '../../../../highchart/Highchart';

type ChartProps = {
  data: PeerGroupAnalysisResponse;
};

export const PeerGroupPercentileRankChart = ({ data }: ChartProps) => {
  const { columnStackOptions, isEmpty } = usePeerGroupsChartConfig(data);
  const blockId = useBlockId();
  if (isEmpty) {
    return null;
  }

  return <StudioHighchart options={columnStackOptions} blockId={blockId} className="qa-chart-container" />;
};
