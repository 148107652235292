import { BigBrandLogoFull } from '../../../logos';
import { Body1, getCurrentTheme, Headline3, BrandLogo, ZIndex } from 'venn-ui-kit';
import React from 'react';
import styled from 'styled-components';

interface EmptyStateProps {
  title: string;
  subtitle: string;
}

const EmptyState = ({ title, subtitle }: EmptyStateProps) => {
  const theme = getCurrentTheme();
  return (
    <>
      <Logo>
        {theme === 'default' && <BigBrandLogoFull />}
        {theme !== 'default' && <BrandLogo opacity={0.1} height={220} />}
      </Logo>
      <Content>
        <Headline3 center>{title}</Headline3>
        <Body1 center>{subtitle}</Body1>
      </Content>
    </>
  );
};

const Logo = styled.div`
  position: absolute;
  left: 30%;
  margin-top: 90px;

  svg {
    width: 220px;
    height: 220px;
  }
`;

const Content = styled.div`
  margin-top: 90px;
  padding-top: 60px;
  margin-bottom: 90px;
  z-index: ${ZIndex.Base};
`;

export default EmptyState;
