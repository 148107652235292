import React, { useState } from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';
import { ContributionPopover } from 'venn-components';
import { ColorUtils, GetColor, Hint, Icon, Tooltip } from 'venn-ui-kit';
import type { PortfolioPerformanceAttribution } from 'venn-api';
import { Numbers } from 'venn-utils';
import type { ContributionData } from '../../../../analysis-page/src/components/performance-summary/modal/ContributionModal';
import ContributionModal, {
  TimeframeEnum,
} from '../../../../analysis-page/src/components/performance-summary/modal/ContributionModal';

interface PerformanceContribution {
  value: number;
  contributions: PortfolioPerformanceAttribution | undefined;
}

export interface Contributions {
  forecast?: PerformanceContribution;
  historical?: PerformanceContribution;
}

interface CellProps {
  value: number | undefined;
  isPercentage: boolean;
  contributions: Contributions;
  metricName: string;
  portfolioName: string;
  portfolioId: number;
  relative: boolean;
  isForecast?: boolean;
  onClick?: () => void;
}

const formatter = (isPercentage: boolean, value?: number) =>
  isPercentage ? Numbers.safeFormatPercentage(value, 1) : Numbers.safeFormatNumber(value, 2);

const PopupState = {
  ShowModal: {
    isPopoverVisible: false,
    areToolsVisible: false,
    isModalVisible: true,
  } as const,
  ShowTools: {
    isPopoverVisible: false,
    areToolsVisible: true,
    isModalVisible: false,
  } as const,
  ShowToolsAndPopover: {
    isPopoverVisible: true,
    areToolsVisible: true,
    isModalVisible: false,
  } as const,
  ShowNothing: {
    isPopoverVisible: false,
    areToolsVisible: false,
    isModalVisible: false,
  } as const,
};
type PopupState = (typeof PopupState)[keyof typeof PopupState];

const Cell = ({
  value,
  isPercentage,
  contributions,
  metricName,
  portfolioName,
  portfolioId,
  relative,
  isForecast,
  onClick,
}: CellProps) => {
  const [popupState, setPopupState] = useState<PopupState>(PopupState.ShowNothing);

  if (isNil(value)) {
    return <>--</>;
  }

  const modalData = ((): ContributionData | null => {
    const attributions = (() => {
      if (isForecast && contributions?.forecast?.contributions) {
        return contributions?.forecast?.contributions;
      }
      if (!isForecast && contributions?.historical?.contributions) {
        return contributions?.historical?.contributions;
      }
      return null;
    })();

    if (!attributions) {
      return null;
    }

    return {
      metric: {
        name: metricName,
        formatter: (v: number | undefined) => formatter(isPercentage, v),
        percentage: isPercentage,
      },
      attributions,
      timeframe: isForecast ? TimeframeEnum.Forecast : TimeframeEnum.Historical,
      subjectName: portfolioName,
      value,
    };
  })();

  const displayContent = formatter(isPercentage, value);

  const { isPopoverVisible, areToolsVisible, isModalVisible } = popupState;

  if (!isForecast) {
    return (
      <>
        <HistoricalBox
          onMouseOver={() => setPopupState(PopupState.ShowToolsAndPopover)}
          onMouseLeave={() => setPopupState(PopupState.ShowNothing)}
        >
          {contributions?.historical?.contributions?.funds && areToolsVisible ? (
            <ExpandAllIcon
              buttonLabel="expand-icon"
              type="expand-alt"
              onClick={() => setPopupState(PopupState.ShowModal)}
            />
          ) : null}
          <Hint>{displayContent}</Hint>
        </HistoricalBox>
        {contributions?.historical?.contributions?.funds ? (
          <ContributionPopover
            portalPosition={{ top: -120, left: -412 }}
            popoverPosition="left"
            visible={isPopoverVisible}
            subjectName={portfolioName}
            value={value}
            timeframe="Historical"
            data={contributions.historical.contributions.funds}
            metricName={metricName}
            formatter={(v) => formatter(isPercentage, v)}
            dataItemLabel="Investment"
            shouldIgnoreMouseEvents={false}
          />
        ) : null}
        {isModalVisible && modalData ? (
          <ContributionModal
            subject={{
              name: portfolioName,
              id: portfolioId,
              type: 'portfolio',
              userUploaded: false,
            }}
            relative={relative}
            data={modalData}
            onClose={() => setPopupState(PopupState.ShowNothing)}
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <ForecastBox
          tabIndex={isNil(onClick) ? undefined : 0}
          onMouseOver={() => setPopupState(PopupState.ShowToolsAndPopover)}
          onMouseLeave={() => setPopupState(PopupState.ShowNothing)}
        >
          {areToolsVisible ? (
            <>
              {contributions?.forecast?.contributions?.funds ? (
                <ExpandAllIconForecast
                  buttonLabel="expand-icon"
                  type="expand-alt"
                  onClick={() => setPopupState(PopupState.ShowModal)}
                />
              ) : null}
              <StyledTooltip content={<i>Click to change your objective</i>} isHidden={isNil(onClick)}>
                <div
                  onMouseOver={(event) => {
                    event.stopPropagation();
                    setPopupState(PopupState.ShowTools);
                  }}
                  onFocus={(event) => {
                    event.stopPropagation();
                    setPopupState(PopupState.ShowTools);
                  }}
                >
                  <StyledIcon onClick={onClick} type="function" />
                </div>
              </StyledTooltip>
            </>
          ) : null}
          <strong>{displayContent}</strong>
        </ForecastBox>
      </Wrapper>
      {contributions?.forecast?.contributions?.funds ? (
        <ContributionPopover
          portalPosition={{ top: -125, left: -420 }}
          popoverPosition="left"
          visible={isPopoverVisible}
          subjectName={portfolioName}
          value={value}
          timeframe="Forecast"
          data={contributions.forecast.contributions.funds}
          metricName={metricName}
          formatter={(v) => formatter(isPercentage, v)}
          dataItemLabel="Investment"
          shouldIgnoreMouseEvents={false}
        />
      ) : null}
      {isModalVisible && modalData ? (
        <ContributionModal
          subject={{
            name: portfolioName,
            id: portfolioId,
            type: 'portfolio',
            userUploaded: false,
          }}
          relative={relative}
          data={modalData}
          onClose={() => setPopupState(PopupState.ShowNothing)}
        />
      ) : null}
    </>
  );
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
  &:hover {
    color: ${GetColor.Primary.Dark};
  }
`;

const HistoricalBox = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const ExpandAllIcon = styled(StyledIcon)`
  position: absolute;
  left: 15px;
  margin-top: 2px;
`;

const ForecastBox = styled.div`
  position: relative;
  max-width: 90px;
  padding: 10px 4px 3px 4px;
  height: calc(100% + 8px);
  margin-left: auto;
  &:hover {
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DivergingColor.B3, 0.2)};
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  height: calc(100% - 6px);
  top: -4px;
  position: relative;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  left: 23px;
  margin-top: 1px;
`;

const ExpandAllIconForecast = styled(StyledIcon)`
  position: absolute;
  left: 3px;
  margin-top: 2px;
`;

export default Cell;
