import type { ReactNode } from 'react';
import React from 'react';
import type { TriggerComponentProps } from '../types';
import Icon from '../../icon/Icon';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import { ColorUtils } from '../../../style/colorUtils';
import { buttonize } from 'venn-utils';

interface IconTriggerProps extends TriggerComponentProps {
  icon: string;
  size?: number;
  dark?: boolean;
  solid?: boolean;
  border?: boolean;
  borderSize?: number;
  text?: string | ReactNode;
  showCaret?: boolean;
}

const IconTrigger = ({
  expanded,
  onClick,
  icon,
  size,
  dark,
  solid,
  border,
  borderSize,
  disabled,
  text,
  showCaret,
}: IconTriggerProps) => {
  return (
    <Trigger
      className="trigger"
      expanded={!!expanded}
      {...buttonize<HTMLDivElement>((e) => {
        e.stopPropagation();
        e.preventDefault();
        !disabled && onClick?.(!expanded);
      })}
      data-testid="icon-on-click-trigger"
      aria-label="Dropdown menu trigger"
      size={size}
      dark={dark}
      border={border}
      borderSize={borderSize}
      disabled={disabled}
    >
      <Icon prefix={solid ? 'fas' : 'far'} type={icon} />
      {text && <Text>{text}</Text>}
      {showCaret && <Icon type={expanded ? 'caret-up' : 'caret-down'} />}
    </Trigger>
  );
};

const Text = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1.1;
`;

const Trigger = styled.div<{
  size?: number;
  expanded: boolean;
  dark?: boolean;
  border?: boolean;
  borderSize?: number;
  disabled?: boolean;
}>`
  cursor: pointer;
  ${(props) => props.size && `font-size: ${props.size}px;`}
  color: ${(props) =>
    props.expanded && !props.border ? GetColor.Primary.Main : props.dark ? GetColor.Black : GetColor.Primary.Dark};
  :hover {
    ${(props) =>
      props.border
        ? css`
            background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
          `
        : css`
            color: ${GetColor.Primary.Main};
          `}
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${GetColor.MidGrey1};
      :hover {
        color: ${GetColor.MidGrey1};
      }
    `}
  display: flex;
  align-items: center;
  padding: 5px;
  ${(props) =>
    props.border &&
    css`
      padding: ${props.borderSize}px;
      border-radius: 2px;
    `}
  ${(props) =>
    props.border &&
    props.expanded &&
    css`
      background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    `}
`;

export default IconTrigger;
