import React, { useContext } from 'react';
import {
  BaseMenu,
  Button,
  ExternalActivityListener,
  GetColor,
  Icon,
  LegacyRelativePortal,
  Tooltip,
  TooltipPosition,
} from 'venn-ui-kit';
import SplitButtonDropdownItem from '../split-button-dropdown/SplitButtonDropdownItem';
import styled, { css } from 'styled-components';
import useExportButton from './useExportButton';
import type { ExportButtonProps } from './shared';
import UserContext from '../contexts/user-context';
import { PrintDisabledModal } from '../print/PrintDisabledModal';
import Markdown from '../markdown/Markdown';

const ExportButton = ({
  dominant,
  disabled,
  densed,
  onPrintClick,
  downloadAll,
  downloadXlsx,
  downloadPdf,
  downloadPNGs,
  savedId,
  subjectId,
  printLabel,
}: ExportButtonProps) => {
  const { hasPermission } = useContext(UserContext);
  const canPrintAnalysis = hasPermission('ANALYSIS_PAGE_PRINT');
  const {
    isOpen,
    setOpen,
    handlePrintClick,
    exportOptions,
    hasExportsDisabled,
    hasReportLab,
    hasExportsDisabledReason,
  } = useExportButton({
    onPrintClick,
    downloadPNGs,
    downloadPdf,
    downloadXlsx,
    downloadAll,
    savedId,
    subjectId,
  });

  const shareOptions = canPrintAnalysis ? (
    <ShareGroup>
      <SplitButtonDropdownItem label={printLabel ?? 'Print Analysis'} icon="print" onClick={handlePrintClick} />
    </ShareGroup>
  ) : undefined;

  return (
    <>
      <PrintDisabledModal isPrintDisabled={hasExportsDisabled} />
      <ExternalActivityListener listeningEnabled={isOpen} onExternalActivity={() => setOpen(false)}>
        <Dropdown>
          <Tooltip
            usePortal
            maxWidth={320}
            position={TooltipPosition.Left}
            isHidden={!hasExportsDisabled || hasReportLab}
            content={<Markdown noMargin children={hasExportsDisabledReason} />}
            interactive
          >
            <Button
              dominant={dominant}
              noMargin
              dense
              onClick={() => setOpen(!isOpen)}
              disabled={disabled || (hasExportsDisabled && !hasReportLab)}
            >
              <Icon type="share-square" />
              {!densed && <span>&nbsp; Export</span>}
              <Icon type={isOpen ? 'caret-up' : 'caret-down'} />
            </Button>
          </Tooltip>
          {isOpen && (
            <div>
              <LegacyRelativePortal right={-110}>
                <BaseMenu
                  headerComponent={shareOptions}
                  items={exportOptions}
                  height="inherit"
                  renderItem={({ label, icon, onClick, tooltipContent }) => (
                    <Tooltip
                      isHidden={!!onClick}
                      content={tooltipContent}
                      usePortal
                      block
                      maxWidth={200}
                      key={label as string}
                    >
                      <OptionItem>
                        <SplitButtonDropdownItem label={label} icon={icon} onClick={onClick} />
                      </OptionItem>
                    </Tooltip>
                  )}
                />
              </LegacyRelativePortal>
            </div>
          )}
        </Dropdown>
      </ExternalActivityListener>
    </>
  );
};

export default ExportButton;

const Dropdown = styled.div`
  font-weight: normal;
`;

const SPLIT_CSS = css`
  .split-item {
    line-height: 28px;
    padding: 0 10px;
    font-size: 14px;
  }
`;

const ShareGroup = styled.div`
  ${SPLIT_CSS}
  border-bottom: 1px solid ${GetColor.Grey};
  && {
    padding: 10px 0;
  }
`;

const OptionItem = styled.div`
  ${SPLIT_CSS}
`;
