import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

type ScrollableSectionWithStickyHeaderProps = {
  title: string;
  count: number;
  groupIndex: number;
  children: React.ReactNode;
};

/**
 * Left section of the Multiportfolio Review Step.
 *
 * The headers of the sections are sticky and should be always visible,
 * the contents aren't sticky, but can be comfortably scrolled.
 * Multiple scrollable sections are supported, as long as they share the parent and the parent element doesn't scroll off-screen.
 */
export const ScrollableSectionWithStickyHeader = ({
  title,
  count,
  groupIndex,
  children,
}: ScrollableSectionWithStickyHeaderProps) => {
  return (
    <>
      <StickyGroupHeader groupIndex={groupIndex} data-testid="sticky-group-header">
        <Title>
          <b>{title}</b>
        </Title>
        {count}
      </StickyGroupHeader>
      {children}
      <Padding />
    </>
  );
};

const Title = styled.span`
  text-transform: uppercase;
`;

const Padding = styled.span`
  height: 16px;
  align-self: stretch;
`;

const STICKY_CONTENT_HEIGHT = 42;
const StickyGroupHeader = styled.div<{ groupIndex: number }>`
  display: flex;
  position: sticky;
  top: ${({ groupIndex }) => STICKY_CONTENT_HEIGHT * groupIndex}px;
  height: ${STICKY_CONTENT_HEIGHT}px;
  padding: 16px 16px 8px 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey80};
  background: ${GetColor.White};
  color: ${GetColor.Black};
  align-self: stretch;
`;
