import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { isNil, sum } from 'lodash';
import type { DataGridProps } from '../../../data-grid';
import { DataGrid } from '../../../data-grid';
import type { ExportInfo } from '../../types';
import { getBodyRows, getFooterRows, getHeaderRows, getThirdPartyExportMessageRow } from '../../logic/exportUtils';
import useExportUpdate from '../../logic/useExportUpdate';
import type { ColDef, ColGroupDef, GridApi, Column, GridReadyEvent, IRowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { type ExcelCell, type CustomBlockTypeEnum, assertNotNil, SpecialCssClasses } from 'venn-utils';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  blockAnalysisSubjects,
  blockBenchmarkConfig,
  blockExportMetadata,
  blockMaxSubjects,
  blockSettings,
  isReportState,
  recoilBlockContentSize,
} from 'venn-state';
import { convertScenarioDataToExcel } from '../charts/returns-distribution/utils';
import { useSetBlockSize } from '../../logic/useSetBlockSize';
import { useAgGridStyle } from './AgGridThemeOverrides';
import { generateSampleValueToMeasure, useMeasureGridText, useMeasureHeader } from '../../../utils/grids';
import classNames from 'classnames';
import { isColGroupDef } from '../../logic/typeUtils';
import { useDebouncedCallback } from 'use-debounce';
import type { GridColumnsChangedEvent } from 'ag-grid-community/dist/types/core/events';

export interface ExportableAnalysisGridProps extends Omit<DataGridProps, 'columnDefs' | 'gridRef'>, ExportInfo {
  columnDefs: (ColDef | ColGroupDef)[] | undefined;
  exportable: boolean;
  selectedRefId: string;
  isCompact: boolean;
  shouldExpand?: (node: IRowNode<unknown>) => boolean;
  /**
   * Used to control height/width setting from a parent component.
   * If unspecified, grid handles "red borders" overflow functionality itself
   * Otherwise, the parent component should handle it.
   *
   * This is necessary when there are other items present in the block, such as PGA chart,
   * where the grid itself cannot know of other objects inside the block.
   * */
  onGridSizeChange?: (height: number, width: number) => void;
}

const getEmptyStateMessage = (blockType: CustomBlockTypeEnum) =>
  blockType === 'SCENARIO' ? 'No inputs selected. Please configure inputs in the settings panel.' : undefined;

const getElementHeight = (element: HTMLElement) => {
  // We use clientHeight rather than getBoundingClientRect().height as the former is fixed regardless
  // of zoom level whereas the latter changes based on the size on screen.  We need something fixed
  // relative to the table size in pixels rather than on-screen-pixel size.
  let height = element.clientHeight;

  const children = element.children;
  for (let i = 0; i < children.length; ++i) {
    height = Math.max(height, getElementHeight(children.item(i) as HTMLElement) ?? 0);
  }
  return height;
};

const getAgGridHeaderGroupHeight = (gridElement: HTMLElement | null) => {
  const headers = gridElement?.querySelectorAll('.ag-header-row-column-group');
  if (!headers) {
    return undefined;
  }
  let maxHeight = 0;
  for (let i = 0; i < headers.length; ++i) {
    const height = getElementHeight(headers.item(i) as HTMLElement);
    maxHeight = Math.max(maxHeight, height);
  }
  return maxHeight;
};
const DEFAULT_EXPORTABLE_GRID_HORIZONTAL_SCROLLBAR_HEIGHT_PX = 6;

const ExportableGrid = ({
  inPrintMode,
  exportable,
  onGridSizeChange,
  selectedRefId,
  isCompact,
  shouldExpand,
  onGridReady,
  pinnedBottomRowData,
  columnDefs: rawColumnDefs,
  theme,
  horizontalScrollbarHeight = DEFAULT_EXPORTABLE_GRID_HORIZONTAL_SCROLLBAR_HEIGHT_PX,
  ...props
}: ExportableAnalysisGridProps) => {
  const gridRef = useRef<AgGridReact | null>(null);
  const gridWrapperRef = useRef<HTMLDivElement>(null);
  const isReport = useRecoilValue(isReportState);
  const customBlockSettings = useRecoilValue(blockSettings(selectedRefId));
  const exportMetaData = useRecoilValue(blockExportMetadata(selectedRefId));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(selectedRefId));
  /** One of the few rare times we use raw state, because it comes from an ag-grid event. */
  const setRawBlockContentSize = useSetBlockSize(recoilBlockContentSize.rawState(selectedRefId));
  const applyGridSizeChange = useCallback(
    (height: number, width: number) => {
      if (onGridSizeChange) {
        onGridSizeChange(height, width);
      } else {
        setRawBlockContentSize({
          bounds: {
            height,
            width,
          },
        });
      }
    },
    [onGridSizeChange, setRawBlockContentSize],
  );

  const commonBenchmarkName =
    benchmarkConfig.type === 'COMMON' && benchmarkConfig.relative ? benchmarkConfig.subject?.name : undefined;

  const customBlockType = customBlockSettings?.customBlockType;
  const currentHeightRef = useRef<number | undefined>(undefined);

  const { rowData, treeData, autoGroupColumnDef } = props;

  const measureText = useMeasureGridText();
  const measureHeader = useMeasureHeader();
  const columnDefs = useMemo((): typeof rawColumnDefs => {
    const cellWidthGuess = measureText(
      generateSampleValueToMeasure({
        mode: 'currency',
        integerDigits: 3,
        fractionalDigits: 2,
        canBeNegative: true,
      }),
      'normal',
    );

    const processColumnDef = (columnDef: ColDef | ColGroupDef): ColDef | ColGroupDef => {
      if (isColGroupDef(columnDef)) {
        return { ...columnDef, children: columnDef.children.map(processColumnDef) };
      }
      if (columnDef.minWidth || !columnDef.headerName) {
        return columnDef;
      }
      return {
        ...columnDef,
        minWidth: Math.max(cellWidthGuess, measureHeader(columnDef.headerName)),
      };
    };
    return rawColumnDefs?.map(processColumnDef);
  }, [measureText, rawColumnDefs, measureHeader]);

  const excelDataFn = useRecoilCallback(
    ({ snapshot }) =>
      (): ExcelCell[][] => {
        if (!exportable) {
          return [getThirdPartyExportMessageRow()];
        }

        if (customBlockType === 'SCENARIO') {
          const maxSubjects = snapshot.getLoadable(blockMaxSubjects(selectedRefId)).valueOrThrow();
          const subjects = snapshot
            .getLoadable(blockAnalysisSubjects(selectedRefId))
            .valueOrThrow()
            .slice(0, maxSubjects);
          // Cast to group column def as scenario analysis is always grouped
          const groupColumnDefs = columnDefs as ColGroupDef[] | undefined;
          const subjectLabels = groupColumnDefs?.[1].children.map((column) => assertNotNil(column.headerName)) ?? [];
          return convertScenarioDataToExcel(subjects, subjectLabels, rowData, commonBenchmarkName);
        }

        // skip the first row for public-private asset growth which is historical and non-exportable
        const isPublicPrivateAssetGrowth =
          customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN' ||
          customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES';
        const filteredRowData = isPublicPrivateAssetGrowth ? rowData?.slice(1) : rowData;

        const excelHeaders = getHeaderRows(!!treeData, columnDefs, autoGroupColumnDef);
        const bodyData = [...(filteredRowData ?? []), ...(pinnedBottomRowData ?? [])];
        const bodyExcel = getBodyRows(!!treeData, bodyData, columnDefs, autoGroupColumnDef, gridRef?.current);
        const footerExcel = getFooterRows(customBlockType);
        return [...excelHeaders, ...bodyExcel, ...footerExcel];
      },
    [
      exportable,
      customBlockType,
      treeData,
      columnDefs,
      autoGroupColumnDef,
      rowData,
      pinnedBottomRowData,
      selectedRefId,
      commonBenchmarkName,
    ],
  );

  useExportUpdate({ selectedRefId, exportMetaData, excelDataFn });

  const handleRowExpansion = useCallback(
    () => shouldExpand && gridRef?.current?.api?.forEachNode((node) => node.setExpanded(shouldExpand(node))),
    [shouldExpand],
  );

  const onGridReadyWithExpansionHandler = useCallback(
    (event: GridReadyEvent) => {
      onGridReady?.(event);
      handleRowExpansion();
    },
    [onGridReady, handleRowExpansion],
  );

  useEffect(handleRowExpansion, [handleRowExpansion]);

  const autofitAgGridColumns = (
    api: GridApi,
    columnApi: GridApi,
    gridElement: HTMLElement | null,
    autoSizeColumns: boolean,
  ) => {
    // hack: ag grid does not correctly handle columnDefs memoization. to work around this,
    // we manually set the columnDefs if ag grid api's column definitions differ from what we provided
    if (api.getColumnDefs()?.length !== columnDefs?.length) {
      api.setGridOption('columnDefs', columnDefs);
    }
    const cols = columnApi.getColumns();
    if (autoSizeColumns && !isNil(cols)) {
      const ids = cols.filter((c) => c.getColDef().suppressSizeToFit).map((c) => c.getColId());
      // this resizes the fixed size columns to fit their data, it only needs to be called once per
      // loading of data or grid size change as after that the column width should be fixed
      // also autosize any autocolumns (for tree views) as these do not show up in the col defs
      columnApi.autoSizeColumns(['ag-Grid-AutoColumn', ...ids], true);
    }
    // this resizes all remaining columns using flex to fit the remaining space
    // we call this on any grid resize event, the autoSizeColumns is only on data or grid config changes
    api.sizeColumnsToFit();
    const height = getAgGridHeaderGroupHeight(gridElement);
    height && api.setGridOption('groupHeaderHeight', height);
  };

  /**
   * Debounce added b/c in React 18 with multi-threaded rendering it appears that there is a race condition
   * between when AG-Grid defaults column widths to minWidth and when our resize handler gets called
   * to auto-size columns.  If ours runs first then the AG-Grid minWidths will override our auto-sizing.
   *
   * Debounce appears to fix this and ensure that our auto-sizing runs after AG-Grid has set minWidths.
   */
  const onGridColumnsChanged = useDebouncedCallback((event: GridColumnsChangedEvent) => {
    if (!event.api || !event.api.getAllGridColumns()) {
      return;
    }

    autofitAgGridColumns(event.api, event.api, gridWrapperRef.current, true);
    const width = sum(event.api.getAllGridColumns().map((column: Column) => column.getActualWidth()));
    applyGridSizeChange(currentHeightRef.current ?? 0, width);
  }, 0);

  /**
   * Debounce added b/c in React 18 with multi-threaded rendering it appears that there is a race condition
   * between when AG-Grid defaults column widths to minWidth and when our resize handler gets called
   * to auto-size columns.  If ours runs first then the AG-Grid minWidths will override our auto-sizing.
   *
   * Debounce appears to fix this and ensure that our auto-sizing runs after AG-Grid has set minWidths.
   */
  const onGridSizeChanged = useDebouncedCallback((event) => {
    if (!event.api || !event.api.getAllGridColumns()) {
      return;
    }

    autofitAgGridColumns(event.api, event.api, gridWrapperRef.current, false);
    const width = sum(event.api.getAllGridColumns().map((column: Column) => column.getActualWidth()));
    currentHeightRef.current = event.clientHeight;
    applyGridSizeChange(event.clientHeight ?? 0, width);
  }, 0);

  const agStyle = useAgGridStyle();
  const agGridStyleClassName = agStyle.className;
  // Apply the base theme of alpine and any additional theme-like overrides
  const gridClassName = classNames(agGridStyleClassName, 'ag-theme-alpine');

  return (
    <StyledDataGrid
      /**
       * 'key' is needed to force React to re-create the grid when the grid format changes.
       * Unfortunately some changes like rowHeight aren't recalculated when the class style of the grid
       * changes and there doesn't seem to be another way to force it
       */
      key={agGridStyleClassName}
      {...props}
      columnDefs={columnDefs}
      gridRef={gridRef}
      className={gridClassName}
      suppressHorizontalScroll={isReport || inPrintMode}
      horizontalScrollbarHeight={horizontalScrollbarHeight}
      suppressColumnVirtualisation
      dataGridWrapperRef={gridWrapperRef}
      onGridColumnsChanged={onGridColumnsChanged}
      onGridSizeChanged={onGridSizeChanged}
      onGridReady={onGridReadyWithExpansionHandler}
      overlayNoRowsTemplate={getEmptyStateMessage(customBlockType)}
      pinnedBottomRowData={pinnedBottomRowData}
      stopEditingWhenCellsLoseFocus
    />
  );
};

const StyledDataGrid = styled(DataGrid)<{ suppressHorizontalScroll?: boolean }>`
  .ag-cell-value {
    text-overflow: unset;
  }

  ${(props) =>
    props.suppressHorizontalScroll &&
    css`
      .ag-center-cols-viewport {
        overflow-x: clip !important;
      }
    `};

  .${SpecialCssClasses.ExportAsImage} & .ag-body-horizontal-scroll {
    display: none;
  }
`;

export default ExportableGrid;
