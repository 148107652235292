import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { checkViewName } from 'venn-api';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalSubhead } from '../../modal';
import { ConditionalOverlay } from '../../conditional-overlay';
import { logExceptionIntoSentry, useHasFF } from 'venn-utils';
import RequiredLabel from '../../label';
import StatefulInput from '../../stateful-input/StatefulInput';
import { Loading, Spinner, LoadingSize } from 'venn-ui-kit';
import ExportLearnMoreLink from '../../share-button/ExportLearnMoreLink';
import ValidationField from '../validation/Field';
import useNameExist from '../../hooks/useNameExist';
import { OwnershipContextSelector } from '../components/OwnershipContextSelector';
import { UserContext } from '../../contexts';

export type SaveAnalysisViewModalMode = 'SAVE' | 'SAVE_AS' | 'RENAME' | 'SAVE_NEW_OWNER';

export type ContentByMode = Partial<Record<SaveAnalysisViewModalMode, JSX.Element | string>>;

export interface SaveAsModalCustomContent {
  header?: ContentByMode;
  subhead?: ContentByMode;
  content?: ContentByMode;
}

interface SaveAnalysisViewModalProps {
  mode: SaveAnalysisViewModalMode;
  defaultName?: string;
  onSubmit: (name: string, ownerContextId?: string) => Promise<void> | void;
  onClose: () => void;
  customContent?: SaveAsModalCustomContent;
}

const SaveAnalysisViewModal = ({ mode, defaultName, onSubmit, onClose, customContent }: SaveAnalysisViewModalProps) => {
  const hasContextSwitching = useHasFF('context_switching');
  const { currentContext } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>(defaultName);
  const [ownerContextId, setOwnerContextId] = useState(currentContext);
  const { error, onInputChange, isChecking, name } = useNameExist(
    setInputValue,
    checkViewName,
    mode === 'RENAME',
    mode === 'SAVE_NEW_OWNER' ? `${defaultName} (Copy)` : defaultName,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const overlayRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const node = document.createElement('div');
    overlayRef.current = node;
    document.body.appendChild(overlayRef.current);
    return () => {
      if (overlayRef.current) {
        document.body.removeChild(node);
      }
    };
  }, []);

  const onSubmitClick = useCallback(async () => {
    const value = inputRef.current?.value ?? inputValue;
    if (!value?.length) {
      return;
    }
    setSubmitting(true);
    try {
      await onSubmit(value, ownerContextId);
    } catch (e) {
      logExceptionIntoSentry(e);
    }
    if (isMounted.current) {
      setSubmitting(false);
    }
    onClose();
  }, [inputValue, onClose, onSubmit, ownerContextId]);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const defaultHeaders: ContentByMode = {
    SAVE: 'Save View',
    SAVE_AS: 'Save View as...',
    SAVE_NEW_OWNER: 'Save A Copy',
  };

  const defaultSubheads: ContentByMode = {
    SAVE: 'Save this view for quicker access.',
    SAVE_AS: 'Save this view as a new view.',
  };

  const getDefaultContent = (mode: SaveAnalysisViewModalMode, defaultName?: string): JSX.Element | undefined => {
    switch (mode) {
      case 'SAVE':
      case 'SAVE_AS':
        return (
          <>
            Saving a view remembers your analysis subject, template and settings as applicable. <ExportLearnMoreLink />
          </>
        );
      case 'RENAME':
        return <>Renaming will update your saved view name, but won't apply any other changes.</>;
      case 'SAVE_NEW_OWNER':
        return <>A copy of "{defaultName}" will be created with the name:</>;
      default:
        return undefined;
    }
  };

  const defaultHeader = defaultHeaders[mode] || 'Rename view';
  const defaultContent = getDefaultContent(mode, defaultName);
  const currentSubhead = customContent?.subhead?.[mode] || defaultSubheads[mode];
  const isCreatingNewView = mode === 'SAVE_AS' || mode === 'SAVE_NEW_OWNER' || mode === 'SAVE';

  return (
    <Modal onClose={onClose} closeOnEsc className="qa-save-view-modal">
      <ConditionalOverlay condition={submitting} overlay={<Loading title="Saving..." />}>
        <ModalHeader>{customContent?.header?.[mode] || defaultHeader}</ModalHeader>
        {currentSubhead && <ModalSubhead>{currentSubhead}</ModalSubhead>}
        <ModalContent>{customContent?.content?.[mode] || defaultContent}</ModalContent>
        <ModalContent>
          <ValidationField error={error}>
            <LabelWrapper>
              <Label required>Name:</Label> {isChecking && <Spinner size={LoadingSize.nano} />}
            </LabelWrapper>

            <InputWrapper>
              <StatefulInput
                autoFocus
                innerRef={inputRef}
                disabled={submitting}
                placeholder="Name your analysis..."
                value={name}
                onChange={onInputChange}
                className="qa-save-analysis-name-input"
                selectOnFocus
              />
            </InputWrapper>
          </ValidationField>
          {hasContextSwitching && isCreatingNewView && (
            <OwnershipContextSelector
              requiredAction="CREATE_ANALYSIS_VIEW"
              ownerContextId={ownerContextId}
              setOwnerContextId={setOwnerContextId}
            />
          )}
        </ModalContent>
        <ModalFooter
          submitOnEnter
          onCancel={onClose}
          primaryLabel={mode === 'RENAME' ? 'Update name' : 'Save View'}
          primaryClassName="qa-save-analysis-save-button"
          primaryDisabled={submitting || !inputValue?.length || !!error || isChecking}
          onPrimaryClick={onSubmitClick}
        />
      </ConditionalOverlay>
    </Modal>
  );
};

export default SaveAnalysisViewModal;

const InputWrapper = styled.div`
  & > div {
    width: 100%;
  }
`;

const LabelWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

const Label = styled(RequiredLabel)`
  font-size: 12px;
`;
