import React, { useContext, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import type { ComputedInvestmentResidual, RegressionPeriodEnum } from 'venn-api';
import { EllipsisAutoPositionTooltipSpan, getAppTitle, GetColor, Icon } from 'venn-ui-kit';
import { assertExhaustive, Dates } from 'venn-utils';
import { selectedInvestmentOverrideAtom } from '../../../../../venn-state/src';
import PrintContainerDimensions from '../../../print/PrintContainerDimensions';
import { InvestmentForecastPanelFooterActionsContext } from '../contexts/InvestmentForecastPanelFooterActionsContext';
import InvestmentForecastOverrideForm from './InvestmentForecastOverrideForm';

const Constants = {
  leftAction: {
    margin: 24,
    size: 32,
  },
  rightAction: {
    width: 80,
  },
  centralContent: {
    horizontalPadding: 12,
  },
  magicNumber: 6, // Not sure why, but 6px adjustment keeps us away from ugly overflows
};

interface ForecastsListItemProps {
  forecast: ComputedInvestmentResidual;
  forecastName: string;
  organizationName?: string;
  userName?: string;
  onSetForecastAsDefault?: (forecast: ComputedInvestmentResidual) => void;
  isSystemForecast?: boolean;
  isActive?: boolean;
  customFooter?: React.ReactNode;
  height?: number;
  forecastTooltipText?: string;
  customAction?: React.ReactNode;
  isReadOnly: boolean;
  regressionStart?: number;
  regressionEnd?: number;
  regressionPeriod?: RegressionPeriodEnum;
  maxWidth?: number;
  overrideType: 'RETURN' | 'RESIDUAL';
}

const getRegressionPeriodLabel = (period: RegressionPeriodEnum) => {
  switch (period) {
    case 'FULL':
      return 'Full History';
    case 'YEAR_3':
      return '3 Year';
    case 'YEAR_5':
      return '5 Year';
    case 'YEAR_7':
      return '7 Year';
    case 'YEAR_10':
      return '10 Year';
    default:
      throw assertExhaustive(period, 'unexpected RegressionPeriodEnum');
  }
};

export const ForecastsListItem = ({
  forecastName,
  organizationName,
  userName,
  isSystemForecast = false,
  customFooter,
  height = 80,
  customAction,
  regressionStart,
  regressionEnd,
  regressionPeriod,
  maxWidth,
  overrideType,
  forecast,
  isReadOnly,
}: ForecastsListItemProps) => {
  const overrideFormRef = useRef<null | HTMLDivElement>(null);
  const regression = regressionPeriod
    ? getRegressionPeriodLabel(regressionPeriod)
    : regressionStart && regressionEnd
      ? `${Dates.toDayMonthYear(regressionStart)}-${Dates.toDayMonthYear(regressionEnd)}`
      : undefined;

  const selectedOverride = useRecoilValue(selectedInvestmentOverrideAtom('EDIT'));
  const { onCancelInvestmentOverride } = useContext(InvestmentForecastPanelFooterActionsContext);

  const isActive = forecast.fundId === selectedOverride?.fundId;

  useEffect(() => {
    if (isActive) {
      overrideFormRef.current?.scrollIntoView(false);
    }
  }, [overrideFormRef, selectedOverride, isActive]);

  const createInfoContainerLeft = (width: number) => {
    const forecastNameComponent = (
      <ForecastName className="qa-forecast-list-item-name">
        <EllipsisAutoPositionTooltipSpan
          maxWidth={
            (maxWidth ?? width) -
            (Constants.leftAction.size + Constants.leftAction.margin) -
            Constants.centralContent.horizontalPadding * 2 -
            Constants.rightAction.width -
            Constants.magicNumber
          }
          flex
        >
          <strong> {forecastName} </strong>
        </EllipsisAutoPositionTooltipSpan>
      </ForecastName>
    );
    const ownerComponent = (
      <Owner fontSize={14} marginBottom={5}>
        {isSystemForecast ? (
          getAppTitle()
        ) : (
          <>
            {organizationName || '--'} | {userName || '--'}
          </>
        )}
      </Owner>
    );
    const regressionComponent = regression ? (
      <span>
        <b>Lookback Period: </b>
        {regression}
      </span>
    ) : undefined;
    const overrideTypeComponent = (
      <OverrideType>
        {overrideType === 'RESIDUAL' ? 'Residual Forecast Override' : 'Total Return Override'}
      </OverrideType>
    );

    return (
      <InfoContainerLeft>
        {forecastNameComponent}
        {ownerComponent}
        {regressionComponent}
        {overrideTypeComponent}
      </InfoContainerLeft>
    );
  };

  return (
    <PrintContainerDimensions>
      {({ width }) => (
        <Container
          data-testid="qa-forecast-list-item"
          className="qa-forecast-list-item"
          height={height}
          isActive={isActive}
        >
          <InfoContainer>
            {createInfoContainerLeft(width)}
            <InfoContainerRight>
              <Actions>
                {!isActive ? (
                  customAction
                ) : (
                  <StyledButton onClick={onCancelInvestmentOverride}>
                    <Icon type="chevron-left" tooltip="Delete Investment Override" />
                  </StyledButton>
                )}
              </Actions>
            </InfoContainerRight>
          </InfoContainer>
          <InfoFooter>{customFooter}</InfoFooter>
          {isActive && (
            <EditFormWrapper ref={overrideFormRef}>
              <InvestmentForecastOverrideForm isCreating={false} isReadOnly={isReadOnly} />
            </EditFormWrapper>
          )}
        </Container>
      )}
    </PrintContainerDimensions>
  );
};

export default ForecastsListItem;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div<{
  height: number;
  isActive: boolean;
}>`
  width: 100%;
  display: flex;
  height: ${(props) => props.height}px;
  border-bottom: 1px solid ${GetColor.PaleGrey};
  padding: 10px 10px 10px 0px;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${({ theme }) => css`
    border: 1px solid ${theme.Colors.PaleGrey};
    padding: 10px;
    height: auto;

    & ${Owner} {
      line-height: 24px;
    }

    & ${ForecastName} {
      line-height: 24px;
    }

    & ${InfoContainer} {
      margin-bottom: 10px;
    }
  `}

  ${({ isActive, theme }) => isActive && `background-color: ${theme.Colors.HighlightLightBackground};`}
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoContainerLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const InfoContainerRight = styled.div`
  align-self: baseline;
`;

const InfoFooter = styled.div`
  margin-bottom: 5px;
`;

const Owner = styled.div<{
  fontSize?: number;
  marginBottom?: number;
}>`
  font-size: ${({ fontSize }) => fontSize ?? 12}px;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 5}px;
`;

const ForecastName = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;

const OverrideType = styled.div`
  color: ${GetColor.GreyScale.Grey80};
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
`;

const StyledButton = styled.button.attrs({ type: 'button' })`
  padding: 4px;
`;

const EditFormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 480px;
`;
