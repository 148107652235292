import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipPosition } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import { CumulativeReturnSnapshotChart, PercentageSpan } from 'venn-components';
import type { SearchResultWithUIState } from './types';
import { renderExtrapolation, renderProxyType, renderTimespan } from './Shared';
import { get10YearRet, getCumRet } from './utils';
import { getFullPeriodFootnoteSymbol } from './logic/librariesTable';

export const CUMULATIVE_TOOLTIP_BACKGROUND = 'rgba(16, 22, 27, 0.9)';

const renderFullHistoryReturn = (item: SearchResultWithUIState) => {
  const value = getCumRet(item);
  if (value === 0 || value) {
    return (
      <p>
        Cum. Return{getFullPeriodFootnoteSymbol()}&nbsp;
        <PercentageSpan className="tooltip-value" value={value} />
      </p>
    );
  }
  return null;
};

const render10YearReturn = (item: SearchResultWithUIState, hasFullHistory?: boolean) => {
  const value = get10YearRet(item);
  const footnoteSymbol = hasFullHistory ? '*' : '';

  if (value === 0 || value) {
    return (
      <p>
        Cum. Return (10yr){footnoteSymbol}&nbsp;
        <PercentageSpan className="tooltip-value" value={value} />
      </p>
    );
  }
  return null;
};

interface CumulativeTooltipProps {
  item: SearchResultWithUIState;
  min: number;
  max: number;
}
const CumulativeTooltip = ({ item, min, max }: CumulativeTooltipProps) => {
  const hasFullHistory = useHasFF('extend_full_history_ff');
  const { name } = item;
  return (
    <StyledCumulativeTooltip
      background={CUMULATIVE_TOOLTIP_BACKGROUND}
      position={TooltipPosition.Top}
      maxWidth={300} // increasing Tooltip's default max width to avoid overflow
      content={
        <div>
          <h1>{name} Cumulative Return</h1>
          {item.portfolioId && renderTimespan('Availability', item.startRange, item.endRange, item.frequency)}
          {item.fundId &&
            renderTimespan('Availability', item.unproxiedStartRange, item.unproxiedEndRange, item.unproxiedFrequency)}
          {renderProxyType(item.proxyType)}
          {item.proxyType !== 'EXTRAPOLATE' && renderExtrapolation(item.extrapolated ?? false)}
          {renderTimespan('Proxy Range', item.proxyStartRange, item.proxyEndRange, item.frequency, true)}
          {render10YearReturn(item, hasFullHistory)}
          {renderFullHistoryReturn(item)}
        </div>
      }
    >
      <span>
        <CumulativeReturnSnapshotChart
          cumulativeReturn={item.tenYearCumulativeReturnSeries as [number, number][]}
          highlightFirstPoint
          max={max}
          min={min}
          proxyStart={item.proxyStartRange}
          proxyEnd={item.proxyEndRange}
          extrapolateStart={item.extrapolated ? item.unproxiedEndRange : undefined}
        />
      </span>
    </StyledCumulativeTooltip>
  );
};

const areEqual = (prevProps: CumulativeTooltipProps, nextProps: CumulativeTooltipProps) =>
  prevProps.item.tenYearCumulativeReturnSeries === nextProps.item.tenYearCumulativeReturnSeries;
export default React.memo(CumulativeTooltip, areEqual);

export const StyledCumulativeTooltip = styled(Tooltip)`
  width: 100%;
  h1 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.5rem;
    margin: 0 0 0.67em 0;
  }

  p {
    margin: 0;
    min-width: 240px;
  }

  .tooltip-value {
    float: right;
    font-weight: bold;
    padding-left: 13px;
  }

  /* set relative position to the tooltip ContentContainer (IE Fix) */
  & > div > div {
    position: relative;
  }
`;
