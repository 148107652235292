import { capitalize, isEmpty, isNil } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import type { FrequencyEnum, InfoGraphicTypeEnum } from 'venn-api';
import {
  type DataModificationsCategory,
  DataModificationsModal,
  DeveloperFallbackComponent,
  useAnalysisBlockDataModifications,
  useAppPrintMode,
  useBlockId,
  useHoldings,
  usePrivateAnalysis,
  usePrivateAnalysisBlockDataModifications,
  withErrorBoundary,
} from 'venn-components';
import {
  blockBenchmarkConfig,
  blockBenchmarkType,
  blockCustomizableMetricsMap,
  blockDateRange,
  blockFonts,
  blockInfoGraphicType,
  blockPrivateDefaultAsOfDateBehavior,
  blockSettings,
  customizedBlock,
  isReportState,
  viewShowVenncast,
} from 'venn-state';
import { GetColor, getTextThemeProvider, Icon, REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import {
  type CustomBlockTypeEnum,
  type DATE_BEHAVIOR_ENUM,
  Dates,
  FootnoteSymbols,
  getDateBehavior,
  getSelectedRollingPeriod,
  hasDisabledBenchmark,
  hasDisabledIndividualBenchmark,
  hasDisabledRelativeToBenchmark,
  isFactorTrendMetric,
  isHoldingsBlock,
  isPrivatesBlock,
  isPrivatesPerformanceBlock,
  isPublicPrivateAssetGrowthBlock,
  isRollingMetric,
  shouldForceHideBenchmark,
  useHasFF,
  useModal,
} from 'venn-utils';

interface BlockHeaderProps {
  id: string;
}
interface BlockHeaderColumnProps extends BlockHeaderProps {
  dateBehavior: DATE_BEHAVIOR_ENUM;
  from?: number;
  to?: number;
  frequency?: FrequencyEnum;
  endDateRangePrefix?: string;
  blockGraphicType?: InfoGraphicTypeEnum;
  blockType?: CustomBlockTypeEnum;
}
type DataModificationsInfoProps = {
  blockId: string;
  modifications: DataModificationsCategory[];
};
const benchmarkTypes = {
  COMMON: 'Common Benchmark',
  INDIVIDUAL: 'Individual Benchmarks',
  NONE: 'No Benchmark',
};

export const MetadataFoundations = {
  MetaData: ({ children }) => {
    const blockId = useBlockId();
    const { inPrintModeOrReportLab } = useAppPrintMode();
    const headerInfoFont = useRecoilValue(blockFonts.blockHeaderInfo(blockId));
    return (
      <MetadataSection
        inPrintMode={inPrintModeOrReportLab}
        fontSize={headerInfoFont.fontSizePt}
        className="qa-metadata-section"
      >
        {children}
      </MetadataSection>
    );
  },
  MetadataRow: ({ children }) => {
    const { inPrintModeOrReportLab: isCompact } = useAppPrintMode();
    return <MetadataColumn compact={isCompact}>{children}</MetadataColumn>;
  },
  MetadataItem: ({ type, children }) => {
    const { inPrintModeOrReportLab: isCompact } = useAppPrintMode();
    return (
      <>
        <StyledIcon type={type} compact={isCompact} />
        <Text>{children}</Text>
      </>
    );
  },
};

export const MetadataPrefabs = {
  DateRange: () => {
    const blockId = useBlockId();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const blockType = blockSetting?.customBlockType;
    const isPrivate = isPrivatesBlock(blockType);
    const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
    const dateBehavior = getDateBehavior(blockSetting.customBlockType);
    if (isPrivate) {
      return (
        <>
          <PrivatesDateRangeColumn
            id={blockId}
            blockType={blockType}
            blockGraphicType={blockGraphicType}
            dateBehavior={dateBehavior}
          />
          <PrivateAnalysisBlockDataModifications blockId={blockId} />
        </>
      );
    }
    if (isPublicPrivateAssetGrowthBlock(blockType)) {
      return (
        <>
          <PrivateAssetGrowthDateRangeColumn dateBehavior={dateBehavior} id={blockId} />
          <PublicPrivateBlockDataModifications blockId={blockId} />
        </>
      );
    }
    return <DateRangeColumn id={blockId} dateBehavior={dateBehavior} />;
  },
  AsOfDate: () => {
    const blockId = useBlockId();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const dateBehavior = getDateBehavior(blockSetting.customBlockType);
    return dateBehavior === 'SERVER_SENT' && <AsOfDateInner />;
  },
  BenchmarkType: () => {
    const blockId = useBlockId();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const blockType = blockSetting?.customBlockType;
    const block = useRecoilValue(customizedBlock(blockId));
    const { selectedMetrics } = block || {};
    const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));
    const forceHideBenchmark = shouldForceHideBenchmark(blockType, metricDefinitionsMap, selectedMetrics);
    const customBenchmarkType = useRecoilValue(blockBenchmarkType(blockId));
    const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
    const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));
    return (
      !forceHideBenchmark &&
      customBenchmarkType &&
      !hasDisabledBenchmark(blockType, blockGraphicType) &&
      customBenchmarkType !== 'NONE' &&
      !(customBenchmarkType === 'INDIVIDUAL' && hasDisabledIndividualBenchmark(blockType)) &&
      !(customBenchmarkType === 'COMMON' && isNil(benchmarkConfig.subject)) && (
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="arrows-h">
            {benchmarkTypes[customBenchmarkType]}
          </MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      )
    );
  },
  RelativeToBenchmark: () => {
    const blockId = useBlockId();
    const block = useRecoilValue(customizedBlock(blockId));
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
    const blockType = blockSetting?.customBlockType;
    const { relativeToBenchmark } = block || {};
    const isBlockRelativeToBenchmark =
      relativeToBenchmark &&
      !hasDisabledBenchmark(blockType, blockGraphicType) &&
      !hasDisabledRelativeToBenchmark(blockType);
    return (
      isBlockRelativeToBenchmark && (
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="calculator">Relative to Benchmark</MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      )
    );
  },
  ContributionToPercentage: () => {
    const blockId = useBlockId();
    const block = useRecoilValue(customizedBlock(blockId));
    const { contributionToPercentage } = block || {};
    return (
      contributionToPercentage && (
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="percent">
            Percentage Contribution: On
          </MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      )
    );
  },
  Venncast: () => {
    const blockId = useBlockId();
    const hasStudioVenncastFF = useHasFF('studio_venncast_ff');
    const showVenncast = useRecoilValue(viewShowVenncast(blockId)) && hasStudioVenncastFF;
    return (
      showVenncast && (
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="chart-line">
            {getTextThemeProvider().VenncastName} Missing Data: On
          </MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      )
    );
  },
  RollingPeriod: () => {
    const blockId = useBlockId();
    const block = useRecoilValue(customizedBlock(blockId));
    const { rollingYears, selectedMetrics } = block || {};
    const factorTrendMetricSelected = isFactorTrendMetric(selectedMetrics);
    const rollingTimeseriesSelected = isRollingMetric(selectedMetrics);
    const selectedOption = getSelectedRollingPeriod(rollingYears, factorTrendMetricSelected);
    return (
      (rollingTimeseriesSelected || factorTrendMetricSelected) && (
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="wave-sine">
            Rolling Period: {selectedOption.label}
          </MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      )
    );
  },
  HoldingsCalculationInfo: () => {
    const blockId = useBlockId();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const blockType = blockSetting?.customBlockType;
    const text = blockType === 'ASSET_EXPOSURE' ? 'Net Rescaled Values' : 'Long Rescaled Values';
    return (
      isHoldingsBlock(blockType) && (
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="info-circle">{text}</MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      )
    );
  },
};

const AsOfDateInner = () => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const dateBehavior = getDateBehavior(blockSetting.customBlockType);
  const { data } = useHoldings(blockId);
  if (!data) {
    return null;
  }
  const text = data.asOfDate ? `As of ${Dates.toDDMMMYYYY(data.asOfDate)}` : 'Most Recent Available';
  return (
    dateBehavior === 'SERVER_SENT' && (
      <MetadataFoundations.MetadataRow>
        <MetadataFoundations.MetadataItem type="calendar">{text}</MetadataFoundations.MetadataItem>
      </MetadataFoundations.MetadataRow>
    )
  );
};

const PrivatesDateRangeColumn = withErrorBoundary(
  DeveloperFallbackComponent,
  ({ id, dateBehavior, blockType, blockGraphicType }: BlockHeaderColumnProps) => {
    const { data } = usePrivateAnalysis(id);
    const startAsOfLastTransaction = useRecoilValue(blockPrivateDefaultAsOfDateBehavior(id));
    // do not show projection start date for grid format of cash flow block
    if (blockType === 'PRIVATE_CASH_FLOW' && blockGraphicType === 'GRID') {
      return null;
    }
    // do not show as-of-date for PME block headers, since it is already incorporated inside the charts
    if (!blockType || isPrivatesPerformanceBlock(blockType)) {
      return null;
    }

    return (
      <DateRangeColumn
        dateBehavior={dateBehavior}
        id={id}
        to={startAsOfLastTransaction ? data?.cashFlows?.[0]?.projectionAsOfDate : undefined}
        frequency="QUARTERLY"
        endDateRangePrefix="Projection Start: "
      />
    );
  },
);

const PrivateAnalysisBlockDataModifications = ({ blockId }: Omit<DataModificationsInfoProps, 'modifications'>) => {
  const { modifications } = usePrivateAnalysisBlockDataModifications(blockId);
  if (isEmpty(modifications)) {
    return null;
  }
  return <DataModificationsInfoInternal blockId={blockId} modifications={modifications} />;
};

const DataModificationsInfoInternal = ({ modifications }: DataModificationsInfoProps) => {
  const [isOpen, open, close] = useModal();
  const isReport = useRecoilValue(isReportState);
  return (
    <>
      <DataModificationsButton isReport={isReport} onClick={open}>
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="info-circle">
            Contains Venn-Modified Data&nbsp;{`${isReport ? FootnoteSymbols.vennModifiedData : ''}`}
          </MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
      </DataModificationsButton>
      {isOpen && <DataModificationsModal onClose={close} modifications={modifications} />}
    </>
  );
};

const DateRangeColumn = ({
  id,
  dateBehavior,
  to: toOverride,
  from: fromOverride,
  frequency: frequencyOverride,
  endDateRangePrefix = '',
}: BlockHeaderColumnProps) => {
  const computedDateRange = useRecoilValue(blockDateRange(id));

  if (dateBehavior !== 'DATE_RANGE' && dateBehavior !== 'END_DATE_RANGE') {
    return null;
  }
  const from = fromOverride ?? computedDateRange?.range.from;
  const to = toOverride ?? computedDateRange?.range.to;
  const frequency = frequencyOverride ?? computedDateRange?.frequency;

  const frequencyText = capitalize(frequency ?? '--');
  const fromText = from && frequency ? Dates.toDDMMMYYYY(from, frequency) : '--';
  const toText = to && frequency ? Dates.toDDMMMYYYY(to, frequency) : '--';
  const rangeText = `${fromText} - ${toText}`;

  if (dateBehavior === 'END_DATE_RANGE') {
    return (
      <MetadataFoundations.MetadataRow>
        <MetadataFoundations.MetadataItem type="calendar">
          {endDateRangePrefix}
          {toText}
        </MetadataFoundations.MetadataItem>
      </MetadataFoundations.MetadataRow>
    );
  }
  return (
    <>
      <MetadataFoundations.MetadataRow>
        <MetadataFoundations.MetadataItem type="clock">{frequencyText}</MetadataFoundations.MetadataItem>
      </MetadataFoundations.MetadataRow>
      <MetadataFoundations.MetadataRow>
        <MetadataFoundations.MetadataItem type="calendar">{rangeText}</MetadataFoundations.MetadataItem>
      </MetadataFoundations.MetadataRow>
    </>
  );
};
const PrivateAssetGrowthDateRangeColumn = withErrorBoundary(
  DeveloperFallbackComponent,
  ({ id, dateBehavior }: BlockHeaderColumnProps) => {
    const dateRange = useRecoilValue(blockDateRange(id));

    return (
      <>
        <MetadataFoundations.MetadataRow>
          <MetadataFoundations.MetadataItem type="clock">Quarterly</MetadataFoundations.MetadataItem>
        </MetadataFoundations.MetadataRow>
        <DateRangeColumn
          dateBehavior={dateBehavior}
          id={id}
          to={dateRange?.range.to}
          frequency="QUARTERLY"
          endDateRangePrefix="Simulation Start Date: "
        />
      </>
    );
  },
);

const PublicPrivateBlockDataModifications = ({ blockId }: Omit<DataModificationsInfoProps, 'modifications'>) => {
  const { modifications } = useAnalysisBlockDataModifications(blockId);
  if (isEmpty(modifications)) {
    return null;
  }
  return <DataModificationsInfoInternal blockId={blockId} modifications={modifications} />;
};

const MetadataSection = styled.div<{
  inPrintMode: boolean;
  fontSize: number | undefined;
}>`
  font-size: ${({ inPrintMode, fontSize }) =>
    fontSize ? `${fontSize}pt` : inPrintMode ? REPORT_LAB_FONT_BODY : 'calc(1rem * 7 / 6)'};
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 0;
`;

const DataModificationsButton = styled.button<{ isReport: boolean }>`
  color: unset;
  ${(props) =>
    props.isReport &&
    css`
      pointer-events: none;
    `};
`;
const MetadataColumn = styled.div<{ compact?: boolean }>`
  ${({ compact }) =>
    !compact
      ? css`
          margin: 5px 5px 0 0;
          border: 1px solid ${GetColor.GreyScale.Grey50};
          border-radius: 4px;
          padding: 3px 5px;
        `
      : 'padding-left: 0px;'}
`;

const StyledIcon = styled(Icon)<{ compact?: boolean }>`
  padding: 4px;
  ${({ compact }) =>
    !compact
      ? css`
          color: ${GetColor.GreyScale.Grey60};
          background-color: ${GetColor.GreyScale.Grey20};
          border-radius: 2px;
        `
      : 'padding-left: 0px;'}
`;

const Text = styled.span`
  padding-left: 3px;
  padding-right: 10px;
`;
