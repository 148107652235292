import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { AnalysisSubject } from 'venn-utils';
import { getQueryParams, Routes } from 'venn-utils';
import { Link, useLocation } from 'react-router-dom';
import {
  Col,
  Container,
  DEFAULT_DEBOUNCE_DURATION,
  EmptyWorkspaceIcon,
  GetColor,
  Headline2,
  Notifications,
  NotificationType,
  Row,
} from 'venn-ui-kit';
import type { SearchMenuItem } from 'venn-components';
import { Footer, SearchMenuBar } from 'venn-components';
import { Content } from './Layout';
import debounce from 'lodash/debounce';

const VIEW_NOT_FOUND_MESSAGE = 'Analysis not found';

interface EmptyWorkspaceProps {
  onChangeSubject?: (item: AnalysisSubject) => void;
}

const EmptyWorkspace = ({ onChangeSubject }: EmptyWorkspaceProps) => {
  const [error, setError] = useState<boolean | undefined>(undefined);

  const location = useLocation();
  const debouncedNotify = useMemo(() => debounce(Notifications.notify, DEFAULT_DEBOUNCE_DURATION), []);
  // On first page load, test if the user has been redirected to due invalid analysis view id
  useEffect(() => {
    if (error !== undefined || location === undefined) {
      return;
    }
    const newError = !!getQueryParams(location.search)?.invalidViewId;
    setError(newError);
    newError && debouncedNotify(VIEW_NOT_FOUND_MESSAGE, NotificationType.ERROR);
  }, [debouncedNotify, error, location]);

  return (
    <div>
      <Content>
        <StyledContainer fluid>
          <Row>
            <Col xs={{ span: 8, offset: 2 }}>
              <EmptyWorkspaceIcon />
              <Headline2 center>Select something to begin an analysis.</Headline2>
              <SearchBox>
                <SearchMenuBar
                  onSelected={({ value: selectedItem }: SearchMenuItem) => {
                    if (selectedItem) {
                      onChangeSubject?.(selectedItem);
                    }
                  }}
                  location="emptyAnalysisPage"
                  privateAssetSearchMode="PUBLIC_ONLY"
                />
              </SearchBox>

              <Hint>
                You can also <Link to={Routes.LIBRARIES_PATH}>browse the data library</Link> to find investments and
                portfolios to analyze.
              </Hint>
            </Col>
          </Row>
        </StyledContainer>
      </Content>
      <Footer />
    </div>
  );
};

export default EmptyWorkspace;

const StyledContainer = styled(Container)`
  margin-top: 120px;
  padding: 120px 75px 0 75px;
  position: relative;
  height: 75vh;
  width: 100%;
  text-align: center;
`;

const SearchBox = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  > div {
    width: 100%;
  }
`;

const Hint = styled.div`
  font-size: 10px;
  color: ${GetColor.HintGrey};
`;
