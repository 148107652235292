import React from 'react';
import styled, { css } from 'styled-components';
import BaseMenu from './BaseMenu';
import type { DropMenuItem, MenuContainerProps } from '../types';
import { GetColor } from '../../../style/color';
import type { RollingPeriodEnum } from 'venn-components';
import Tooltip from '../../tooltip/Tooltip';
import { TooltipPosition } from '../../enums';

export interface SingleCustomOptionMenuItem extends DropMenuItem<RollingPeriodEnum | number> {
  isCustom?: boolean;
}

export interface SingleCustomOptionMenuProps extends MenuContainerProps {
  /**
   * Array of dropmenu items
   */
  items: SingleCustomOptionMenuItem[];
  /**
   * Currently selected item
   */
  selected?: SingleCustomOptionMenuItem;
  /**
   * Called on click on a menu item
   */
  onSelect: (item: SingleCustomOptionMenuItem) => void;
  headerComponent?: JSX.Element;
}

const SingleCustomOptionMenu = ({
  items,
  selected,
  onSelect,
  width,
  height,
  className,
  headerComponent,
}: SingleCustomOptionMenuProps) => {
  return (
    <BaseMenu<string, SingleCustomOptionMenuItem>
      items={items}
      width={width}
      height={height || 500}
      className={className}
      headerComponent={headerComponent}
      renderItem={(item: SingleCustomOptionMenuItem) => (
        <Tooltip
          key={item.label}
          isHidden={!item.disabled}
          content="There's not enough history to perform this analysis"
          position={TooltipPosition.Left}
          usePortal
          block
        >
          <Item
            key={item.label}
            type="button"
            onClick={() => onSelect(item)}
            highlighted={selected?.value === item.value}
            disabled={item.disabled}
          >
            <div>
              {item.label} {item.isCustom && <i>(custom)</i>}
            </div>
          </Item>
        </Tooltip>
      )}
    />
  );
};

export default React.memo(SingleCustomOptionMenu);

const Item = styled.button<{ highlighted: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  color: ${GetColor.Black};
  font-weight: bold;

  &:hover {
    background-color: ${GetColor.PaleGrey};
    color: ${GetColor.Black};
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${GetColor.PaleGrey};
    `}
`;
