import type { ReactElement } from 'react';
import React, { Component } from 'react';
import styled from 'styled-components';
import type { CarouselSlideProps } from './CarouselSlide';
import CarouselButton from './CarouselButton';
import { Button, GetColor } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';

export interface CarouselProps {
  /**
   * Callback fired when selection is changed.
   * @param index the index now selected.
   */
  onSelectionChanged?: (index: number, trigger?: string) => void;
  /**
   * If true, Carousel will determine the selected item based solely on props.
   */
  useExternalState?: boolean;
  selectedItem?: number;
  children: ReactElement<CarouselSlideProps>[];
}

interface CarouselState {
  selectedItem: number; // 0-based index of currently-selected item in the carousel
}

export default class Carousel extends Component<CarouselProps, CarouselState> {
  state = {
    selectedItem: 0,
  };

  static getDerivedStateFromProps(nextProps: CarouselProps, prevState: CarouselState) {
    if (nextProps.useExternalState && nextProps.selectedItem !== prevState.selectedItem) {
      return { selectedItem: nextProps.selectedItem };
    }
    return null;
  }

  selectSlide = (index: number, trigger?: string) => {
    // check that the selection has actually changed and within [0, # of children)
    if (index !== this.state.selectedItem && index >= 0 && index < React.Children.count(this.props.children)) {
      !this.props.useExternalState && this.setState({ selectedItem: index });
      if (this.props.onSelectionChanged) {
        this.props.onSelectionChanged(index, trigger);
      }
    }
  };

  handleBackClick = (): void => {
    this.selectSlide(this.state.selectedItem - 1, 'button');
  };

  handleNextClick = (): void => {
    this.selectSlide(this.state.selectedItem + 1, 'button');
  };

  handleCarouselButtonClick = (index: number): void => {
    this.selectSlide(index, 'bubble');
  };

  componentDidUpdate() {
    const carouselLength = React.Children.count(this.props.children);
    if (this.state.selectedItem >= carouselLength) {
      this.selectSlide(carouselLength - 1);
    }
  }

  render() {
    const { children } = this.props;
    const { selectedItem } = this.state;
    const childCount = React.Children.count(children);
    const childArray = React.Children.toArray(children) as ReactElement<CarouselSlideProps>[];
    const selectedChild = childArray[selectedItem];
    return (
      <div>
        <CarouselContainer className="qa-carousel-container" data-testid="carousel">
          <CarouselTitleContainer>
            {childCount > 0 && selectedChild ? selectedChild.props.description : null}
          </CarouselTitleContainer>
          {childCount > 1 && (
            <CarouselButtonContainer>
              {childArray.map((child, index: number) => (
                <CarouselButton
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  selected={index === selectedItem}
                  onClick={() => this.handleCarouselButtonClick(index)}
                />
              ))}
            </CarouselButtonContainer>
          )}
        </CarouselContainer>

        <CarouselSlideContainer>{selectedItem >= 0 ? selectedChild : null}</CarouselSlideContainer>

        {childCount > 1 && (
          <CarouselNavigationButtonContainer className={SpecialCssClasses.NotDownloadable}>
            <CarouselButtonContainer className="qa-carousel-buttons">
              <Button disabled={selectedItem === 0} onClick={this.handleBackClick}>
                Back
              </Button>
              <Button disabled={selectedItem === childCount - 1} onClick={this.handleNextClick}>
                Next
              </Button>
            </CarouselButtonContainer>
          </CarouselNavigationButtonContainer>
        )}
      </div>
    );
  }
}

const CarouselContainer = styled.div`
  width: 100%;
  background-color: ${GetColor.PaleGrey};
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
`;

const CarouselTitleContainer = styled.span`
  position: absolute;
  color: ${GetColor.MidGrey2};
  margin-left: 30px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
`;

const CarouselButtonContainer = styled.div`
  margin: auto;
`;

const CarouselSlideContainer = styled.div`
  width: 60%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 40px;
`;

const CarouselNavigationButtonContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 30px;
`;
