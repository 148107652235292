import { isNil } from 'lodash';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { openPrivateAllocatorPortfolio } from 'venn-state';
import { GetColor } from 'venn-ui-kit';
import { withSuspense } from 'venn-utils';
import { Constants } from '../Layout';
import {
  StudioAllocationPanelShimmerBlock,
  StudioAllocatorSubject,
  StudioAllocatorWindow,
  StudioAllocatorWindowHeader,
} from './components';
import { PrivatesAllocatorPanel } from './PrivatesAllocatorPanel';
import UnsavedStudioAllocatorChangesModal from './UnsavedStudioAllocatorChangesModal';
import { useUnsavedPrivatesAllocatorChangesModal } from './useUnsavedPrivatesAllocatorChangesModal';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const StudioPrivatesAllocationPanel = React.memo(() => {
  const [openPortfolio, setOpenPortfolio] = useRecoilState(openPrivateAllocatorPortfolio);

  const onClosePanel = useCallback(() => {
    setOpenPortfolio(undefined);
  }, [setOpenPortfolio]);

  const {
    isUnsavedChangesModalOpen,
    closePanelOrTriggerUnsavedChangesModal,
    discardChangesAndClosePanel,
    closeUnsavedChangesModal,
  } = useUnsavedPrivatesAllocatorChangesModal(onClosePanel);

  const allocatorWidth = 1 + Constants.ALLOCATION_PANEL_MAIN_WIDTH;

  return (
    <StudioAllocatorWindow>
      <StudioAllocatorWindowHeader width={allocatorWidth} onClose={closePanelOrTriggerUnsavedChangesModal} />
      <StudioAllocatorSubject width={allocatorWidth} isPrivate />
      {!isNil(openPortfolio) && (
        <AllocationPanelWrapper data-testid="studio-privates-allocator-wrapper">
          <PrivatesAllocatorPanel />
        </AllocationPanelWrapper>
      )}
      {isUnsavedChangesModalOpen && (
        <UnsavedStudioAllocatorChangesModal
          message="All changes to portfolio will be reset when the Allocator panel is closed."
          onConfirm={discardChangesAndClosePanel}
          onCancel={closeUnsavedChangesModal}
        />
      )}
    </StudioAllocatorWindow>
  );
});

export default withSuspense(<StudioAllocationPanelShimmerBlock isPrivate />, StudioPrivatesAllocationPanel);

const AllocationPanelWrapper = styled.div`
  grid-area: allocator-panel;
  background-color: ${GetColor.White};
  overflow-x: hidden;
`;
