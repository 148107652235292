import { Banner } from './MultiHistoricalReviewComponents.RightPane.Banner';
import { ContentContainer } from './MultiHistoricalReviewComponents.RightPane.ContentContainer';
import { Root } from './MultiHistoricalReviewComponents.RightPane.Root';
import { Footer } from './MultiHistoricalReviewComponents.Footer';

export const MultiHistoricalReviewComponents = {
  RightPane: {
    Root,
    Banner,
    ContentContainer,
  },
  Footer,
};
