import { isEmpty } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockLimitedRequestSubjects } from '../../../../../venn-state/src';
import { useBlockId } from '../../contexts/BlockIdContext';
import { useCommonGridProps } from '../../logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';
import usePrivateAssetsSummaryGrid from '../../logic/usePrivateAssetsSummaryGrid';
import { usePrivateAssetSummaryAnalysis } from '../../logic/usePrivateAssetSummaryAnalysis';
import StyledEmptyState from '../StyledEmptyState';
import ExportableGrid from './ExportableGrid';

type PrivateAssetSummaryGridProps = Readonly<{ selectedRefId: string }>;

const PrivateAssetSummaryGrid = ({ selectedRefId }: PrivateAssetSummaryGridProps) => {
  const blockId = useBlockId();
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const privateAssetSummaryResponse = usePrivateAssetSummaryAnalysis();
  const { columnDefs, rowData, isExportable } = usePrivateAssetsSummaryGrid(privateAssetSummaryResponse);
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonGridProps();

  if (isEmpty(subjects)) {
    return <StyledEmptyState header="Please select a Private Portfolio to analyze" selectedRefId={selectedRefId} />;
  }

  return (
    <ExportableGrid
      {...commonGridProps}
      exportable={isExportable}
      selectedRefId={selectedRefId}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
    />
  );
};

export default PrivateAssetSummaryGrid;
