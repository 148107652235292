import { HeaderFoundations } from './Block.Header';
import { MetadataFoundations, MetadataPrefabs } from './Block.Metadata';
import { RootFoundations } from './Block.Root';
import { SubjectFoundations, SubjectPrefabs } from './Block.Subjects';
import { BodyFoundations } from './Block.Body';

export const Block = {
  ...RootFoundations,
  ...HeaderFoundations,
  ...MetadataFoundations,
  MetadataPrefabs,
  ...SubjectFoundations,
  SubjectPrefabs,
  ...BodyFoundations,
};
