import React, { type CSSProperties, useMemo, useState } from 'react';
import type { Property } from 'csstype';
import styled from 'styled-components';
import { Text } from 'slate';
import type { BaseEditor, Descendant } from 'slate';
import type { ReactEditor, RenderLeafProps, RenderElementProps } from 'slate-react';
import { Editable, Slate } from 'slate-react';
import { SupportedBlock, createReactEditor, DEFAULT_ALIGNMENT, onPaste, useFontSize } from './utils';

interface BaseElement {
  children: CustomDescendant[];
  alignment?: Property.TextAlign;
}

type ParagraphElement = { type: SupportedBlock.PARAGRAPH } & BaseElement;

type HeadingElement = { type: SupportedBlock.H1 } & BaseElement;

type HeadingTwoElement = { type: SupportedBlock.H2 } & BaseElement;

type NumberedListElement = { type: SupportedBlock.NUMBERED_LIST } & BaseElement;

type BulletedListElement = { type: SupportedBlock.BULLETED_LIST } & BaseElement;

type ListItem = { type: SupportedBlock.LIST_ITEM } & BaseElement;

type CustomDescendant = CustomElement | CustomText;

type CustomElement =
  | HeadingElement
  | HeadingTwoElement
  | NumberedListElement
  | BulletedListElement
  | ListItem
  | ParagraphElement;

type CustomText = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  fontSize?: number;
  fontSizePt?: number;
  fontSizeLegacy?: number;
  text: string;
};

type Editor = BaseEditor & ReactEditor;

declare module 'slate' {
  interface CustomTypes {
    Editor: Editor;
    Element: CustomElement;
    Text: CustomText;
    Descendant: CustomDescendant;
  }
}

const getDefaultInitialValue = (): Descendant[] => [
  {
    type: SupportedBlock.PARAGRAPH,
    children: [
      {
        text: 'Add your custom title...',
        fontSizePt: 18,
        bold: true,
      },
    ],
  },
  {
    type: SupportedBlock.PARAGRAPH,
    children: [
      {
        text: 'Add your custom text...',
        fontSizePt: 10,
      },
    ],
  },
];

interface RichTextEditorProps {
  handleOnChange: (text: Descendant[]) => void;
  initialValue?: Descendant[];
  // If an editor is given, it is used if the parent want's to control it
  controlledEditor?: Editor;
  readOnly?: boolean;
}

const RichTextEditor = ({ handleOnChange, initialValue, controlledEditor, readOnly }: RichTextEditorProps) => {
  const [value, setValue] = useState<Descendant[]>(() => initialValue ?? getDefaultInitialValue());
  const editor = useMemo(() => controlledEditor ?? createReactEditor(), [controlledEditor]);

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(value) => {
        handleOnChange(value);
        setValue(value);
      }}
    >
      <StyledEditable
        readOnly={readOnly}
        renderElement={(props) => <Element {...props} />}
        renderLeaf={(props) => <Leaf {...props} />}
        onPaste={(event) => onPaste(event, editor)}
      />
    </Slate>
  );
};

const StyledEditable = styled(Editable)`
  cursor: text;
  p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    line-height: 1.15;
  }

  li {
    margin-left: 1em;
  }
  ol,
  ul {
    padding-inline-start: 2rem;
  }
`;

const Element = ({ children, element }: RenderElementProps) => {
  const customProps = {
    style: {
      textAlign: element.alignment ?? DEFAULT_ALIGNMENT,
    },
  };

  switch (element.type) {
    case 'bulleted-list':
      return <ul {...customProps}>{children}</ul>;
    case 'heading-one':
      return <h1 {...customProps}>{children}</h1>;
    case 'heading-two':
      return <h2 {...customProps}>{children}</h2>;
    case 'list-item':
      return (
        <CustomListItem {...customProps} element={element}>
          {children}
        </CustomListItem>
      );
    case 'numbered-list':
      return <ol {...customProps}>{children}</ol>;
    default:
      return <p {...customProps}>{children}</p>;
  }
};

const CustomListItem = ({
  style,
  element,
  children,
}: {
  style: CSSProperties;
  element: CustomElement;
  children: React.ReactChild;
}) => {
  const childFontSize = getFirstTextChild(element);
  const fontSizeSetting = useFontSize(childFontSize);
  const fontSize = fontSizeSetting ? `${fontSizeSetting}pt` : 'unset';
  return <li style={{ ...style, fontSize }}>{children}</li>;
};

const getFirstTextChild = (element: CustomElement): CustomText | undefined => {
  const child = element.children[0];
  if (Text.isText(child)) {
    return child;
  }
  return undefined;
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  const fontSizeSetting = useFontSize(leaf);
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  const fontSize = fontSizeSetting ? `${fontSizeSetting}pt` : 'unset';

  return (
    <span style={{ fontSize }} {...attributes}>
      {children}
    </span>
  );
};

export default RichTextEditor;
