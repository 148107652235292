import type { ErrorInfo } from 'react';
import React from 'react';
import { logExceptionIntoSentry } from 'venn-utils';
import type { BlockId } from 'venn-state';
import StyledEmptyState from '../StyledEmptyState';

interface UnexpectedErrorWrapperProps {
  blockId: BlockId;
  allowReset?: boolean;
}

export class UnexpectedErrorWrapper extends React.Component<
  React.PropsWithChildren<UnexpectedErrorWrapperProps>,
  { hasError: boolean }
> {
  constructor(props: UnexpectedErrorWrapperProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logExceptionIntoSentry(error, errorInfo);
  }

  onRetry = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <StyledEmptyState
          selectedRefId={this.props.blockId}
          header="An unexpected error occurred"
          actionLabel={this.props.allowReset ? 'Click to retry' : undefined}
          onAction={this.onRetry}
        />
      );
    }

    return this.props.children;
  }
}
