import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetColor } from '../../style/color';
import { buttonize } from 'venn-utils';
import { ZIndex } from '../../zIndexValues';

interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}

const ToastCloseButton = ({ closeToast }: CloseButtonProps) => {
  /**
   * Toastify library types are somewhat wrong, so we need to cast here.
   * The toastify library requires the closeToast to be exactly (e: React.MouseEvent<HTMLElement>) => void
   * which does not support keyboard closing, but it doesn't actually use the event at all, so its safe to cast.
   */
  const unsafeCastedCloseToast = closeToast as (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => void;
  return <CloseButtonIcon className="fas fa-times" {...buttonize<HTMLElement>(unsafeCastedCloseToast)} />;
};

export const VennToastContainer = () => {
  return (
    <>
      <StyledToastContainer limit={5} closeButton={ToastCloseButton} containerId="default" enableMultiContainer />
      <StyledToastContainerWide limit={3} closeButton={ToastCloseButton} containerId="wide" enableMultiContainer />
    </>
  );
};

export default VennToastContainer;

// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 400px;
    z-index: ${ZIndex.InterComFront2};
  }

  .Toastify__toast {
    color: ${GetColor.White};
    background-color: ${GetColor.HighlightDark};
    font-family: ${(props) => props.theme.Typography.fontFamily};
  }

  .Toastify__toast-body {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 16px;
  }

  .Toastify__progress-bar {
  }
`;

const StyledToastContainerWide = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 60vw;
  }

  .Toastify__toast {
    color: ${GetColor.White};
    background-color: ${GetColor.HighlightDark};
    font-family: ${(props) => props.theme.Typography.fontFamily};
  }

  .Toastify__toast-body {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 16px;
  }

  .Toastify__progress-bar {
  }
`;

const CloseButtonIcon = styled.i`
  color: ${GetColor.White};
  font-size: 24px;
`;
