import React, { useCallback, useContext, forwardRef } from 'react';
import type { ContentRect } from 'react-measure';
import Measure from 'react-measure';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { UserContext, StudioSidePanelContext } from '../../../../contexts';
import { BlockIdContext } from '../../../../studio-blocks';
import { BlockWidthContextProvider } from '../../../../studio-blocks/contexts/BlockWidthContext';
import { useAppPrintMode } from '../../../../print';
import {
  blockSettings,
  COLUMN_GAP,
  DEFAULT_MARGIN,
  studioBlockCustomWidth,
  studioBlockHeight,
  VIRTUALIZATION_SCROLL_BAR_PX,
} from 'venn-state';
import { buttonize } from 'venn-utils';
import { GetColor, ZIndex } from 'venn-ui-kit';
import { BuilderBlockWrapper } from '../../secondary/shared/BuilderBlockWrapper';

import { type HTMLProps } from '../factory';

type BlockWidths =
  | {
      width: number;
      containerWidth?: undefined;
    }
  | {
      width: undefined;
      containerWidth: number;
    };

export interface BlockRootProps extends HTMLProps<'div'> {
  id: string;
  title: string;
  externalBlockWrapper?: boolean;
  blockRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  blockWidths: BlockWidths;
  handleOverlapsError;
  pageFooterRef;
  blockLayout;
  hasVirtualization;
}

export const BLOCK_CONTAINER_CLASS = 'venn-block-container';

export const BlockRoot = forwardRef<HTMLLabelElement, BlockRootProps>((props) => {
  const {
    id,
    title,
    externalBlockWrapper,
    blockRef,
    children,
    blockWidths,
    handleOverlapsError,
    pageFooterRef,
    blockLayout,
    hasVirtualization,
  } = props;

  // TODO: Lift ID state and resize logic upwards or outside of this component

  const { hasPermission } = useContext(UserContext);
  const { width, containerWidth } = blockWidths;
  const blockSetting = useRecoilValue(blockSettings(id));
  const { inPrintMode, inPrintModeOrReportLab } = useAppPrintMode();
  const { onSelectBlock } = useContext(StudioSidePanelContext);
  // const header = useRecoilValue(blockDisplayHeader(id));
  const setBlockHeight = useSetRecoilState(studioBlockHeight(id));
  const blockRelativeWidth = useRecoilValue(studioBlockCustomWidth(id));

  const onResize = useCallback(
    (contentRect: ContentRect) =>
      // TODO: the fact we use Math.max here means that a block never shrinks in size in printing, which is very brittle and error prone.
      // It has caused bugs where blocks have way too much space between them during printing. E.g., if a loading state is larger than the block itself,
      // this code will remember the size of the loading state forever.
      // However, without this behavior, blocks can infinite loop between small and large sizes when being laid out in the print layout.
      // It is not clear yet how to redesign  the print layout functionality to resolve these issues.
      setBlockHeight((currVal) => Math.ceil(Math.max(contentRect?.bounds?.height ?? 0, currVal))),
    [setBlockHeight],
  );

  const fullWidth = blockSetting.customBlockType === 'PAGE_BREAK';
  const getStudioBlockWidth = (pageWidth: number) => {
    const numberOfColumnGaps = 1 / blockRelativeWidth - 1;
    const virtuosoScrollBarWidth = hasVirtualization && numberOfColumnGaps <= 0.0001 ? VIRTUALIZATION_SCROLL_BAR_PX : 0;
    const printMarginPx = DEFAULT_MARGIN * (inPrintModeOrReportLab ? 2 : 0);

    const availablePageWidth = pageWidth - printMarginPx - COLUMN_GAP * numberOfColumnGaps - virtuosoScrollBarWidth;

    return availablePageWidth * blockRelativeWidth;
  };

  const blockWidthPx = width !== undefined ? getStudioBlockWidth(width) : containerWidth;

  const blockWidthStyle = containerWidth || fullWidth ? '100%' : `${blockWidthPx}px`;

  const Wrapper = externalBlockWrapper ? UnstyledBlockWrapper : BuilderBlockWrapper;

  return (
    <BlockOuter
      inPrintMode={inPrintMode}
      isReadOnly={!hasPermission('STUDIO_REORDER_BLOCKS')}
      handleOverlapsError={handleOverlapsError}
      key={id}
      id={id}
    >
      <BuilderBlockWrapper id={id} pageFooterRef={pageFooterRef}>
        <BlockContainer height={blockLayout?.h ?? 0} width={blockLayout?.w ?? 0}>
          <React.Suspense fallback={null}>
            <BlockIdContext.Provider value={id}>
              <BlockWidthContextProvider blockWidthPx={blockWidthPx}>
                <Measure bounds key={id} onResize={onResize}>
                  {({ measureRef }) => {
                    return (
                      <div
                        className={BLOCK_CONTAINER_CLASS}
                        ref={measureRef}
                        id={id}
                        key={id}
                        data-testid={`qa-${lowerCaseHeaderName(title)}`}
                        style={{
                          width: blockWidthStyle,
                        }}
                      >
                        <div
                          {...buttonize((e) => {
                            e.stopPropagation();
                            onSelectBlock(id, { scrollIntoView: false });
                          })}
                          ref={blockRef}
                        >
                          <Wrapper id={id}>{children}</Wrapper>
                        </div>

                        <BlockSpacer />
                      </div>
                    );
                  }}
                </Measure>
              </BlockWidthContextProvider>
            </BlockIdContext.Provider>
          </React.Suspense>
        </BlockContainer>
      </BuilderBlockWrapper>
    </BlockOuter>
  );
});

BlockRoot.displayName = 'BlockRoot';

const BlockSpacer = styled.div`
  height: ${DEFAULT_MARGIN}px;
`;

const UnstyledBlockWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BlockOuter = styled.div<{ handleOverlapsError: boolean; inPrintMode: boolean; isReadOnly?: boolean }>`
  ${({ inPrintMode, isReadOnly, handleOverlapsError }) =>
    !inPrintMode &&
    !isReadOnly &&
    css`
      .react-resizable-handle {
        background-image: none;
        z-index: ${ZIndex.Cover};
        bottom: 0;
        right: 0;
        cursor: se-resize;

        ::after {
          z-index: ${ZIndex.Cover};
          content: '';
          position: absolute;
          right: 5px;
          bottom: 5px;
          width: 15px;
          height: 15px;
          border-right: 4px solid
            ${handleOverlapsError
              ? css`
                  ${GetColor.White}
                `
              : 'rgba(0, 0, 0, 0.6)'};
          border-bottom: 4px solid
            ${handleOverlapsError
              ? css`
                  ${GetColor.White}
                `
              : 'rgba(0, 0, 0, 0.6)'};
        }
      }
    `}
`;

const BlockContainer = styled.div<{ width: number; height: number }>`
  z-index: ${ZIndex.Front};
  overflow: hidden;
  height: 100%;
  > div {
    height: 100%;
    > div {
      display: flex;
      height: 100%;
    }
  }
`;

const lowerCaseHeaderName = (header: string) => header.toLowerCase().replace(/ /g, '-');
