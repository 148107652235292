import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockLimitedRequestSubjects, blockMetrics, blockSettings } from 'venn-state';
import StyledEmptyState from '../StyledEmptyState';
import type { ErrorWrapper } from './errorWrapperTypes';
import { useDebugValue } from 'venn-utils';

export const TimeSeriesErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const selectedMetricIds = useRecoilValue(blockMetrics(blockId));
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const forceError = useDebugValue('debugBlockErrorState') === 'TimeSeries';

  const configureMetricsAndSubjectsMessage = 'Configure metrics and subjects in the block settings panel.';

  const emptyTimeseries = customBlockType === 'TIMESERIES' && (!selectedMetricIds.length || !subjects.length);
  if (forceError || emptyTimeseries) {
    return (
      <StyledEmptyState
        selectedRefId={blockId}
        header="Select a metric and at least one subject"
        message={configureMetricsAndSubjectsMessage}
        small
      />
    );
  }

  return children;
};
