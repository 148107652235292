import type { AnalysisSubjectType, TimeFrame, AnalysesPeriod } from 'venn-utils';
import { getDateMode } from 'venn-utils';
import type { FactorLensWithReturns, Fund, Portfolio, PrivateFund, PrivatePortfolioNode } from 'venn-api';
import type { DateRange, RangeType } from 'venn-ui-kit';
import { getRangeFromType } from 'venn-ui-kit';
import isNil from 'lodash/isNil';
import type { SearchMenuItem } from 'venn-components';

export const getProxy = (
  objectType: AnalysisSubjectType,
  fullObject: Fund | Portfolio | PrivateFund | PrivatePortfolioNode,
) => {
  if (objectType === 'investment') {
    const fund = fullObject as Fund;
    const proxyId = fund?.proxyId;
    if (proxyId) {
      return {
        id: proxyId,
        name: fund?.proxyName,
        extrapolate: fund?.extrapolate,
      };
    }
  }
  return null;
};

export const getBenchmarkResultsFilter = (periodRange?: DateRange) => {
  if (!periodRange) {
    return (_item: SearchMenuItem) => false;
  }

  return (item: SearchMenuItem) => {
    if (item.category === 'tag') {
      return false;
    }
    if (!item.value) {
      return true;
    }
    const itemIsPortfolio = item.value?.superType === 'portfolio';
    const itemStartTime = itemIsPortfolio
      ? (item.value.superItem as Portfolio).periodStart
      : (item.value.superItem as Fund).startRange;
    const itemEndTime = itemIsPortfolio
      ? (item.value.superItem as Portfolio).periodEnd
      : (item.value.superItem as Fund).endRange;
    return itemEndTime! < periodRange.from! || itemStartTime! > periodRange.to!;
  };
};

/**
 * Get effective date range
 * @param selectedTimeFrame user's active select start or end time, start and end will be undefined if the user doesn't select it
 * @param selectedRange user's active select range like '1yr', '3yr', etc.
 * @param analysisPeriod the available period for current subjects, this comes from backend's analysis response
 * @return DateRange | undefined
 *  - case 1: no max period or selected period specified, return undefined;
 *  - case 2: selectedRange is ytd | 1yr | 3yr | 5yr, return selected range
 *  - case 3: selectedRange is full, return max period
 *  - case 4: return selected time frame if it's available
 *  - case 5: return analysis period
 */
export const getDateRange = (
  selectedTimeFrame: TimeFrame,
  selectedRange: RangeType | undefined,
  analysisPeriod: AnalysesPeriod | undefined,
  factorLens: FactorLensWithReturns | undefined,
): DateRange | undefined => {
  const from = selectedTimeFrame.startTime || analysisPeriod?.startTime || undefined;
  const to = selectedTimeFrame.endTime || analysisPeriod?.endTime || undefined;

  return isNil(from) || isNil(to)
    ? undefined
    : selectedRange
      ? getRangeFromType(
          selectedRange,
          { from: analysisPeriod?.maxStartTime, to: analysisPeriod?.maxEndTime },
          getDateMode(analysisPeriod?.frequency),
          analysisPeriod?.frequency ?? 'DAILY',
          factorLens,
        )
      : { from, to };
};
