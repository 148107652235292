import React, { PureComponent } from 'react';
import { FS, getPortfolioLabPath } from 'venn-utils';
import type { Portfolio, Fund } from 'venn-api';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import EvaluateInPortfolioModal from './EvaluateInPortfolioModal';
import { updateAt } from './logic';

export interface ActionsModalsProps {
  fund: Fund; // Use OptimizeInPortfolioModal.tsx when optimizing with multiple funds
  optimizerEnabled: boolean;
  createOptimizeModal: boolean;
  closeOptmizeModal: () => void;
}

interface Props extends RouteComponentProps, ActionsModalsProps {}

class ActionsModals extends PureComponent<Props> {
  render() {
    const { optimizerEnabled, closeOptmizeModal, createOptimizeModal } = this.props;
    return (
      createOptimizeModal &&
      optimizerEnabled && (
        <EvaluateInPortfolioModal
          headerText="Optimize in a portfolio"
          buttonText={FS.has('optimization') ? 'OPTIMIZE IN PORTFOLIO LAB' : 'OPTIMIZE PORTFOLIO'}
          allocationText="Set max investment allocation:"
          portfolioSelectorText="Select a portfolio:"
          onClose={closeOptmizeModal}
          onSubmit={this.onSubmitOptimizeModal}
        />
      )
    );
  }

  onSubmitOptimizeModal = (
    selectedPortfolio: Portfolio | undefined,
    allocation: number | null,
    addToStrategyId?: number,
  ) => {
    if (!selectedPortfolio) {
      return;
    }

    let portfolio: Portfolio = {
      ...selectedPortfolio,
    };

    const { fund, history, closeOptmizeModal } = this.props;
    const newFund: Partial<Portfolio> = {
      allocation: 0,
      children: [],
      compare: [],
      id: Date.now(),
      fund,
      name: fund.name,
      demo: false,
      master: false,
    };

    const strategyId = addToStrategyId || selectedPortfolio.id;
    portfolio = updateAt(portfolio, strategyId, (node) => ({
      ...(node as Portfolio),
      children: [...node.children, newFund] as Portfolio[],
    }));

    closeOptmizeModal();

    if (FS.has('optimization')) {
      history.push(getPortfolioLabPath(portfolio.id, [fund.id], addToStrategyId, allocation ?? undefined));
    }
  };
}

export default withRouter(ActionsModals);
