import { type FC, useMemo } from 'react';
import React, { useCallback, useContext, useRef } from 'react';
import type { AnalysisConfig, CategoryConfig } from 'venn-utils';
import { navigateToManageDataPage } from 'venn-utils';
import { BenchmarksContext, ComparisonContext, RangeDebugContext, useRangesDebug } from 'venn-components';
import type { Fund } from 'venn-api';
import { getFund } from 'venn-api';
import { useHistory } from 'react-router-dom';

interface PortfolioManageDataAnalysisStoreProps {
  config: AnalysisConfig;
  setCategoryConfig: (categoryConfig: CategoryConfig) => void;
  onFundUpdated: (fund: Fund) => void;
}

const EMPTY_BENCHMARK = { label: 'None', value: 'none' };

// Provides the Range Debug context and enables the portfolio manage data page to be opened from anywhere on the analysis page
const PortfolioManageDataAnalysisStore: FC<React.PropsWithChildren<PortfolioManageDataAnalysisStoreProps>> = ({
  config,
  setCategoryConfig,
  onFundUpdated,
  children,
}) => {
  const portfolioFundDeleterRef = useRef<(_fundId: string) => void>();
  const setPortfolioFundDeleterCallback = useCallback(
    (callback: (_fundId: string) => void) => (portfolioFundDeleterRef.current = callback),
    [],
  );
  const onDeleteFundFromPortfolio = useCallback((fundId: string) => portfolioFundDeleterRef.current?.(fundId), []);

  const { subject, trackingId, category } = config;

  const { loading, rangeDebugGroup } = useRangesDebug(subject, category === 'ON', trackingId);
  const portfolioRange = rangeDebugGroup?.primary?.response?.portfolioRange;

  const { onBenchmarkChange } = useContext(BenchmarksContext);
  const { updateCompareType } = useContext(ComparisonContext);

  const onFundDataUpdated = useCallback(
    async (fundId: string, throwOnFailure?: boolean) => {
      try {
        const response = await getFund(fundId);
        onFundUpdated(response.content);
      } catch (e) {
        if (throwOnFailure) {
          throw e;
        }
      }
    },
    [onFundUpdated],
  );

  const history = useHistory();
  const openManageData = useCallback(
    () =>
      navigateToManageDataPage(history, { portfolioId: subject?.portfolio?.id }, 'Analysis', false, undefined, {
        secondaryPortfolio: subject?.secondaryPortfolio,
        secondaryLabel: subject?.secondaryLabel,
      }),
    [history, subject],
  );

  const rangeDebugContextValue = useMemo(() => {
    return {
      rangeDebugGroup,
      portfolioRange,
      disabled: loading || !rangeDebugGroup || !portfolioRange,
      loading,
      openManageData,
      onClearBenchmark: () => onBenchmarkChange(EMPTY_BENCHMARK),
      onClearComparison: () => updateCompareType('None'),
      onClearCategory: () => setCategoryConfig('OFF'),
      onFundDataUpdated,
      setPortfolioFundDeleter: setPortfolioFundDeleterCallback,
      onDeleteFundFromPortfolio,
    };
  }, [
    rangeDebugGroup,
    portfolioRange,
    loading,
    openManageData,
    onBenchmarkChange,
    updateCompareType,
    setCategoryConfig,
    onFundDataUpdated,
    setPortfolioFundDeleterCallback,
    onDeleteFundFromPortfolio,
  ]);

  return <RangeDebugContext.Provider value={rangeDebugContextValue}>{children}</RangeDebugContext.Provider>;
};

export default PortfolioManageDataAnalysisStore;
