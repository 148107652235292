import React from 'react';
import styled from 'styled-components';
import SubjectHeader from './SubjectHeader';
import { COLUMN_WIDTH } from './compareConstants';
import type { AnalysisSubject, ComparisonSubject } from 'venn-utils';
import { analyticsService, subjectIdWithBenchmark } from 'venn-utils';
import { ButtonIcon, Icon, Label } from 'venn-ui-kit';
import type { ItemId, ItemTypeEnum } from 'venn-api';
import AddSubjectButton from './AddSubjectButton';
import type { SearchMenuItem } from '../../search-menu';

export interface SubjectControlProps {
  locationOnPage: string;
  subjects: ComparisonSubject[];
  relative: boolean;
  isAddDisabled: boolean;
  isPivoted?: boolean;
  maxSelections: number;
  isOptionDisabled: (option: SearchMenuItem) => boolean;
  optionDisabledTooltipContent: (option: SearchMenuItem) => string;
  togglePivoted?: () => void;
  onRemoveSubject: (idx: number) => void;
  onHoverSubject: (idx: number, hovered: boolean) => void;
  onAddSubject: (subjects: AnalysisSubject[]) => void;
  onClearSubjects: () => void;
}

const SubjectControl: React.FC<React.PropsWithChildren<SubjectControlProps>> = ({
  locationOnPage,
  subjects,
  isAddDisabled,
  relative,
  isPivoted,
  maxSelections,
  isOptionDisabled,
  optionDisabledTooltipContent,
  togglePivoted,
  onRemoveSubject,
  onHoverSubject,
  onAddSubject,
  onClearSubjects,
}) => {
  if (subjects.length === 0) {
    return null;
  }
  // Do not allow duplicate subjects to be introduced in analysis view (in order to maintain unique strategy ID)
  // Filter to ensure the subject is either a portfolio or a fund
  const excludedSubjectList: ItemId[] = subjects
    .filter((s) => s.analysisSubject?.item)
    .map((s) => ({
      id: s.analysisSubject!.item.id.toString(),
      type: (s.analysisSubject!.type === 'portfolio' || s.analysisSubject!.type === 'private-portfolio'
        ? 'PORTFOLIO'
        : 'FUND') as ItemTypeEnum,
    }));

  return togglePivoted ? (
    <TableLayoutToggle>
      <StyledLabel>Table layout:</StyledLabel>
      <LayoutToggleButtons>
        <LeftElements>
          <AddSubjectButton
            onSubjectSelect={onAddSubject}
            disabled={isAddDisabled}
            maxSelections={maxSelections}
            excludedItems={excludedSubjectList}
            isOptionDisabled={isOptionDisabled}
            optionDisabledTooltipContent={optionDisabledTooltipContent}
          />
          {!isPivoted &&
            subjects.map((subject, idx) =>
              subject.isBenchmark && relative ? null : (
                <CompareCol className="qa-subject-item" key={subjectIdWithBenchmark(subject, idx)}>
                  <SubjectHeader
                    subject={subject}
                    onRemove={() => onRemoveSubject(idx)}
                    onHover={(hovered) => onHoverSubject(idx, hovered)}
                  />
                </CompareCol>
              ),
            )}
        </LeftElements>
        <RightElements>
          <ButtonIcon
            text="Clear Table"
            textFontSize={11}
            onClick={onClearSubjects}
            iconType="minus-circle"
            border
            className="qa-clear-table"
          />
          <ButtonIcon
            onClick={() => {
              togglePivoted();
              analyticsService.ctaClicked({
                destination: undefined, // Stays on the page
                filled: false,
                locationOnPage,
                purpose: 'Change table layout to pivoted',
                text: 'None (icon - subjects as rows)',
                type: 'button',
              });
            }}
            iconType="line-height"
            iconPrefix="far"
            border
            active={isPivoted}
            className="qa-is-pivoted"
          />
          <ButtonIcon
            onClick={() => {
              togglePivoted();
              analyticsService.ctaClicked({
                destination: undefined, // Stays on the page
                filled: false,
                locationOnPage,
                purpose: 'Change table layout to classic',
                text: 'None (icon - subjects as columns)',
                type: 'button',
              });
            }}
            customIcon={<FlippedIcon type="line-height" prefix="far" />}
            border
            active={!isPivoted}
            className="qa-not-is-pivoted"
          />
        </RightElements>
      </LayoutToggleButtons>
    </TableLayoutToggle>
  ) : null;
};

const StyledLabel = styled(Label)`
  text-align: end;
  width: 100%;
`;

const RightElements = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const CompareCol = styled.div`
  width: ${COLUMN_WIDTH}px;
  min-width: ${COLUMN_WIDTH}px;
  min-height: 60px;
`;

const LeftElements = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableLayoutToggle = styled.div`
  padding: 10px 0;
  > label {
    padding: 4px 0;
    display: inline-block;
  }
`;

const LayoutToggleButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlippedIcon = styled(Icon)`
  transform: rotate(90deg);
  font-size: 17px;
`;

export default SubjectControl;
