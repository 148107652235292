import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { PrivatesTable } from './components/PrivatesTable';
import { usePrivateLibraryStateURLSynchronizer } from './components/usePrivateLibraryStateURLSynchronizer';

const PrivateAssetLibrary = () => {
  const history = useHistory<{ shouldListenerIgnore?: boolean }>();
  usePrivateLibraryStateURLSynchronizer(history);

  const LIBRARY_CONTENT_CLASS = 'library-content';

  return (
    <MainContainer className={LIBRARY_CONTENT_CLASS}>
      <PrivatesTable />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  padding-bottom: 20px;
`;

export default withRouter(PrivateAssetLibrary);
