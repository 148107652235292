import type { ComponentProps } from 'react';
import React, { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ThemeContext } from 'styled-components';
import type { Analysis } from 'venn-api';
import type { StudioAnalysisRequest } from 'venn-state';
import {
  blockDoesNotSupportBenchmark,
  blockExportMetadata,
  blockInfoGraphicType,
  blockMetrics,
  blockSettings,
  customizedBlock,
  PRINT_CHART_HEIGHT,
} from 'venn-state';
import { isPublicPrivateAssetGrowthBlock, requiresInfographic } from 'venn-utils';
import { GridWrapper } from './common';
import { BlockSuspenseFallback } from './components/BlockSuspenseFallback';
import { ReturnsGridBlock } from './components/blocks/returns-grid/ReturnsGridBlock';
import CorrelationBlock from './components/charts/CorrelationBlock';
import CustomAnalysisChart from './components/CustomAnalysisChart';
import { CorrelationErrorWrapper } from './components/error-wrappers/CorrelationErrorWrapper';
import { DateRangeErrorWrapper } from './components/error-wrappers/DateRangeErrorWrapper';
import { ErrorMultiWrapper } from './components/error-wrappers/ErrorMultiWrapper';
import { InfoGraphicTypeErrorWrapper } from './components/error-wrappers/InfoGraphicTypeErrorWrapper';
import { MetricsErrorWrapper } from './components/error-wrappers/MetricsErrorWrapper';
import { SubjectsErrorWrapper } from './components/error-wrappers/SubjectsErrorWrapper';
import { TimeSeriesErrorWrapper } from './components/error-wrappers/TimeSeriesErrorWrapper';
import { UnexpectedErrorWrapper } from './components/error-wrappers/UnexpectedErrorWrapper';
import AnalysisGrid from './components/grid/AnalysisGrid';
import AnalysisTreeGrid from './components/grid/AnalysisTreeGrid';
import { useAnalysis, useResponseParser } from './logic/useAnalysis';
import { useIsBlockGridCompact } from './logic/useIsBlockGridCompact';
import type { AnalysisRequest, CustomBlockProps } from './types';
import { isPairwiseAnalysisQuery } from './types';
import { PublicPrivateAssetGrowthBlockWrapper } from './components/blocks/public-private';
import { NotableHistoricalBlock } from './components/blocks/notable-historical/NotableHistoricalBlock';

const filterRequests = (
  allRequests: AnalysisRequest[],
  allAnalyses: (Analysis | undefined)[][],
  isTreeBlock: boolean,
  hideBenchmark?: boolean,
) => {
  const shouldKeepRequest = (req?: StudioAnalysisRequest) =>
    req && !(isTreeBlock && req.subject.subjectType === 'INVESTMENT') && !(req.isBenchmark && hideBenchmark);

  const filterPredicate = (_foo: unknown, index: number) => shouldKeepRequest(allRequests[index]);
  const requests = allRequests.filter(filterPredicate);
  const analyses = allAnalyses.filter(filterPredicate);

  return {
    requests,
    analyses,
  };
};

const CustomAnalysisBlock = ({ isPrinting, downloadableContentRef, isReport, selectedRefId }: CustomBlockProps) => {
  const theme = useContext(ThemeContext);

  const selectedBlock = useRecoilValue(customizedBlock(selectedRefId));
  const blockSetting = useRecoilValue(blockSettings(selectedRefId));
  const selectedInfoGraphicType = useRecoilValue(blockInfoGraphicType(selectedRefId));
  const selectedMetricIds = useRecoilValue(blockMetrics(selectedRefId));
  const exportMetaData = useRecoilValue(blockExportMetadata(selectedRefId));
  const hideBenchmark = useRecoilValue(blockDoesNotSupportBenchmark(selectedRefId));
  const isCompact = useIsBlockGridCompact();

  const customBlockType = blockSetting.customBlockType;

  const needsInfographic = requiresInfographic(customBlockType);
  const infoGraphicType = needsInfographic ? selectedInfoGraphicType : 'DEFAULT';

  const { analyses, requests } = useAnalysis(selectedRefId);
  const responseParser = useResponseParser(selectedRefId);

  const isTreeBlock = !!responseParser.isTree;

  const { requests: filteredRequests, analyses: filteredAnalyses } = useMemo(
    () =>
      requests.length === analyses.length
        ? filterRequests(requests, analyses, isTreeBlock, hideBenchmark)
        : {
            requests,
            analyses,
          },
    [analyses, hideBenchmark, requests, isTreeBlock],
  );

  const sharedProps: ComponentProps<NonNullable<typeof GridComponent>> = {
    requests: filteredRequests,
    theme,
    selectedBlock,
    responseParser,
    inPrintMode: !!isPrinting,
    selectedRefId,
    exportMetaData,
    customBlockType,
    analysesGroup: filteredAnalyses,
    selectedMetricIds: selectedMetricIds ?? [],
    isCompact,
  };

  if (customBlockType === 'RETURNS_GRID') {
    return (
      <GridWrapper isReport={isReport}>
        <ReturnsGridBlock {...sharedProps} />
      </GridWrapper>
    );
  }

  if (customBlockType === 'NOTABLE_PERIODS') {
    return <NotableHistoricalBlock {...sharedProps} />;
  }

  if (isPublicPrivateAssetGrowthBlock(customBlockType)) {
    return (
      <PublicPrivateAssetGrowthBlockWrapper
        data={analyses?.[0]?.[0]?.growthSimulationPublicPrivateResponse}
        subjectMessages={analyses?.[0]?.[0]?.messagesWithContext}
        inPrintMode={!!isPrinting || !!isReport}
        exportable={!!analyses?.[0]?.[0]?.exportable[0]}
        downloadableContentRef={downloadableContentRef}
      />
    );
  }

  const GridComponent = needsInfographic
    ? isPairwiseAnalysisQuery(blockSetting)
      ? CorrelationBlock
      : isTreeBlock
        ? AnalysisTreeGrid
        : AnalysisGrid
    : // left this as a fragment because returning null
      // messes GridComponent's type signature
      // eslint-disable-next-line react/jsx-no-useless-fragment
      () => <></>;

  if (infoGraphicType === 'CORRELATION') {
    return (
      <GridWrapper isReport={isReport}>
        <GridComponent {...sharedProps} />
      </GridWrapper>
    );
  }

  if (infoGraphicType?.includes('GRID')) {
    return (
      <GridWrapper isReport={isReport}>
        <GridComponent {...sharedProps} pivoted={infoGraphicType === 'PIVOTED_GRID'} />
      </GridWrapper>
    );
  }

  const height = isPrinting && !isReport ? PRINT_CHART_HEIGHT : undefined;
  return (
    <CustomAnalysisChart
      selectedRefId={selectedRefId}
      analyses={filteredAnalyses}
      requests={filteredRequests}
      blockSetting={blockSetting}
      selectedBlock={selectedBlock}
      inPrintMode={isPrinting}
      responseParser={responseParser}
      downloadableContentRef={downloadableContentRef}
      exportMetaData={exportMetaData}
      height={height}
    />
  );
};

export default React.memo(function InternalCustomAnalysisBlock(props: CustomBlockProps) {
  return (
    <React.Suspense fallback={<BlockSuspenseFallback />}>
      <ErrorMultiWrapper
        blockId={props.selectedRefId}
        wrappers={[
          UnexpectedErrorWrapper,
          SubjectsErrorWrapper,
          // Date range wrapper should generally be first, since other error wrappers may need a date range to function properly.
          DateRangeErrorWrapper,
          InfoGraphicTypeErrorWrapper,
          CorrelationErrorWrapper,
          TimeSeriesErrorWrapper,
          // MetricsErrorWrapper is last because it requires analysis, so we might as well do all the client-side error checks first
          MetricsErrorWrapper,
        ]}
      >
        <CustomAnalysisBlock {...props} />
      </ErrorMultiWrapper>
    </React.Suspense>
  );
});
