import {
  type GetRecoilValue,
  selectorFamily,
  waitForAll,
  DefaultValue,
  selector,
  useRecoilValueLoadable,
  atom,
  useRecoilState,
} from 'recoil';
import type { BlockId, Subject, SubjectWithOptionalFee } from './types';
import type {
  AnalysisPeriod,
  AnalysisView,
  AnalysisViewBenchmarkSettings,
  AnalysisViewDateRange,
  AnalysisViewSubject,
  AnalysisViewSubjectGroup,
  CustomizedBlock,
} from 'venn-api';
import {
  type BenchmarkInputId,
  type DateRangeInputId,
  inaccessibleSubjectsState,
  type SubjectInputId,
  analysisViewTypeState,
  benchmarkInputIsRelative,
  benchmarkInputName,
  benchmarkInputs,
  benchmarkInputSubject,
  benchmarkInputType,
  blockSettings,
  customizedBlock,
  customViewOptions,
  dateRangeInputConsistencyState,
  dateRangeInputDateRangeState,
  dateRangeInputNameState,
  dateRangeInputsState,
  globalCustomViewOptions,
  studioPrintOrientationType,
  subjectInputGroupName,
  subjectInputGroups,
  subjectInputGroupSubjects,
  analysisViewIdState,
  analysisViewRefIdState,
  isReportState,
  analysisViewOwnerContextIdState,
  analysisViewNameState,
  analysisViewSystemTemplateState,
  analysisViewOwnerState,
} from './configuration';
import type { DateRange, RangeType } from 'venn-ui-kit';
import { isEqual, isNil, omit } from 'lodash';
import { assertNotNil, type CustomBlockTypeEnum, isPublicPrivateAssetGrowthBlock, orderFromTo } from 'venn-utils';
import { getSubjectId } from './utils';
import { allBlockIdsState } from './grid';
import { useEffect } from 'react';

type OptionalFields = 'confidenceLevels' | 'confidenceLevelsVisibility';

const baselineAnalysisView = atom<AnalysisView | undefined>({
  key: 'baselineAnalysisView',
  default: undefined,
});

export const hasUnsavedChangesState = selector<boolean>({
  key: 'hasUnsavedChangesState',
  get: ({ get }) => {
    const currentView = get(currentAnalysisView);
    const baselineView = get(baselineAnalysisView);
    return (!isNil(baselineView) && isNil(currentView.id)) || !isEqual(baselineView, currentView);
  },
});

export const useSyncBaseline = () => {
  const analysisViewLoadable = useRecoilValueLoadable(currentAnalysisView);
  const [baseline, setBaseline] = useRecoilState(baselineAnalysisView);

  useEffect(() => {
    if (analysisViewLoadable.state === 'hasValue' && baseline === undefined) {
      setBaseline(analysisViewLoadable.getValue());
    }
  }, [analysisViewLoadable, baseline, setBaseline]);
};

const filterBlockSettings = (type: CustomBlockTypeEnum, settings: CustomizedBlock) => {
  return omit<CustomizedBlock, OptionalFields>(
    settings,
    type === 'GROWTH_SIMULATION' || isPublicPrivateAssetGrowthBlock(type)
      ? []
      : ['confidenceLevels', 'confidenceLevelsVisibility'],
  );
};

export const allBlocksAnalysisViewsState = selector<AnalysisView[]>({
  key: 'allBlocksAnalysisViewsState',
  get: ({ get }) => {
    const allIds = get(allBlockIdsState);
    return get(waitForAll(allIds.map((id) => blockAnalysisViewState(id))));
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(allBlockIdsState, newValue);
      return;
    }
    set(
      allBlockIdsState,
      newValue.sort((a, b) => (a.row ?? 0) - (b.row ?? 0)).map(({ refId }) => assertNotNil(refId)),
    );
    newValue.forEach((view) => set(blockAnalysisViewState(assertNotNil(view.refId)), view));
  },
});

export const blockAnalysisViewState = selectorFamily<AnalysisView, BlockId>({
  key: 'blockAnalysisViewState',
  get:
    (id) =>
    ({ get }) => {
      const allBlockIds = get(allBlockIdsState);
      const row = allBlockIds.indexOf(id);
      const settings = get(blockSettings(id));
      const block = filterBlockSettings(settings.customBlockType, get(customizedBlock(id)));
      const viewOptions = get(customViewOptions(id));

      const shouldPersistCustomFontState = get(isReportState);

      if (!shouldPersistCustomFontState) {
        delete viewOptions.customFonts;
        delete viewOptions.tableSpacingRatio;
      }

      return {
        refId: id,
        analysisViewType: 'ASSEMBLY_CHILD',
        systemTemplate: 'custom',
        customizedBlock: block,
        customViewOptions: viewOptions,
        version: 2,
        row,
      } as AnalysisView;
    },
  set:
    (id) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(customizedBlock(id), newValue);
        set(customViewOptions(id), newValue);
        return;
      }

      newValue.customizedBlock && set(customizedBlock(id), newValue.customizedBlock);
      set(customViewOptions(id), newValue.customViewOptions);
    },
});

/**
 * Models the entirety of the analysis view that is currently active in studio or report lab.
 * This is set in order to set all of the state when a view is loaded, and read from to save a view.
 *
 * Note: for performance in Components, hooks, and Selectors, you should generally use a specific selector for specific fields
 * in the analysis view, instead of subscribing to the entire analysis view just to get a couple specific fields out.
 *
 * For example, if you just want ID, use {@link analysisViewIdState} so that you don't rerender due to changes in
 * name, viewOptions, subjectGroups, dateRanges, etc. This isn't necessary when accessing from within a recoil callback however.
 */
export const currentAnalysisView = selector<AnalysisView>({
  key: 'currentAnalysisView',
  get: ({ get }) => {
    const id = get(analysisViewIdState);
    const refId = get(analysisViewRefIdState);
    const name = get(analysisViewNameState);
    const viewOptions = get(globalCustomViewOptions);
    const subjectGroups = getSubjectGroups(get);
    const dateRanges = getDateRanges(get);
    const benchmarkSettings = getBenchmarkSettings(get);
    const printOrientationType = get(studioPrintOrientationType);
    const ownerContextId = get(analysisViewOwnerContextIdState);
    const systemTemplate = get(analysisViewSystemTemplateState);
    const customizedViews: AnalysisView[] = get(allBlocksAnalysisViewsState);
    const analysisViewType = get(analysisViewTypeState);

    return {
      id,
      refId,
      name,
      customizedViews,
      printOrientationType,
      benchmarkSettings,
      subjectGroups,
      dateRanges,
      ownerContextId,
      systemTemplate,
      analysisViewType,
      customViewOptions: viewOptions,
      version: 2,
      created: 0,
      customAnalysisParams: [],
      customTemplateId: '',
      owner: undefined,
      published: false,
      template: false,
      templateDescription: '',
      vennTemplate: false,
      width: 0,
      updated: 0,
      subjects: [],
      customizedBlock: {
        relativeToBenchmark: false,
        contributionToPercentage: false,
        selectedFactors: [],
        selectedMetrics: [],
        linkedBenchmarkSettings: '',
        linkedDateRange: '',
        linkedSubjectGroups: [],
      },
    };
  },
  set: ({ set, reset }, view) => {
    if (view instanceof DefaultValue || view === undefined) {
      return;
    }

    reset(baselineAnalysisView);

    set(analysisViewIdState, view.id);
    set(analysisViewRefIdState, view.refId);
    set(analysisViewNameState, view.name);
    set(analysisViewSystemTemplateState, view.systemTemplate);
    set(analysisViewOwnerState, view.owner);

    view.printOrientationType && set(studioPrintOrientationType, view.printOrientationType);

    set(analysisViewOwnerContextIdState, view.ownerContextId);
    set(inaccessibleSubjectsState, getInaccessibleSubjects(view));

    set(subjectInputGroups, view.subjectGroups?.map((g) => g.id as SubjectInputId) ?? []);
    view.subjectGroups?.forEach(({ id, subjects, label }) => {
      set(
        subjectInputGroupSubjects(id as SubjectInputId),
        subjects
          .filter((subject) => !subject.subjectInaccessible)
          .map((s) => {
            const feesMapping = s.feesMapping ?? {};
            if (s.isPrivate && s.subjectType === 'PORTFOLIO') {
              return { privatePortfolioId: s.id, feesMapping };
            }
            if (s.isPrivate && s.subjectType === 'INVESTMENT') {
              return { privateFundId: s.id, feesMapping };
            }
            if (s.subjectType === 'PORTFOLIO') {
              return { portfolioId: Number(s.id), feesMapping };
            }
            return { fundId: s.id, feesMapping };
          }),
      );
      set(subjectInputGroupName(id as SubjectInputId), label);
    });
    set(dateRangeInputsState, view.dateRanges?.map((d) => d.id as DateRangeInputId) ?? []);
    view.dateRanges?.forEach(({ id, period, label, resolution }) => {
      set(dateRangeInputNameState(id as DateRangeInputId), label);
      set(dateRangeInputDateRangeState(id as DateRangeInputId), orderFromTo(convertAnalysisPeriodToDateRange(period)));
      set(dateRangeInputConsistencyState(id as DateRangeInputId), resolution);
    });

    set(benchmarkInputs, view.benchmarkSettings?.map((s) => s.id as BenchmarkInputId) ?? []);
    view.benchmarkSettings?.forEach(({ id, label, relative, subject, type }) => {
      set(benchmarkInputName(id as BenchmarkInputId), label);
      set(benchmarkInputIsRelative(id as BenchmarkInputId), relative);
      subject &&
        !subject.subjectInaccessible &&
        set(benchmarkInputSubject(id as BenchmarkInputId), analysisSubjectToSubject(subject));
      set(benchmarkInputType(id as BenchmarkInputId), type);
    });

    set(globalCustomViewOptions, view.customViewOptions);

    set(allBlocksAnalysisViewsState, view.customizedViews ?? []);

    view.analysisViewType && set(analysisViewTypeState, view.analysisViewType);
  },
});

const getSubjectGroups = (get: GetRecoilValue): AnalysisViewSubjectGroup[] => {
  const ids = get(subjectInputGroups);
  return ids.map((id) => {
    const label = get(subjectInputGroupName(id));
    const temp = get(subjectInputGroupSubjects(id));
    const subjects: AnalysisViewSubject[] = temp.map((subject) => ({
      comparisonType: 'COMPARISON' as const,
      id: getSubjectId(subject),
      subjectType: getAnalysisSubjectType(subject),
      feesMapping: subject.feesMapping,
      isPrivate: !!subject.privateFundId || !!subject.privatePortfolioId,
    }));
    return {
      id,
      label,
      subjects,
    };
  });
};

const getDateRanges = (get: GetRecoilValue): AnalysisViewDateRange[] => {
  const ids = get(dateRangeInputsState);
  return ids.map((id) => {
    const label = get(dateRangeInputNameState(id));
    const period = convertDateRangeToAnalysisPeriod(get(dateRangeInputDateRangeState(id)));
    const resolution = get(dateRangeInputConsistencyState(id));
    return {
      id,
      label,
      period,
      resolution,
    };
  });
};

const getBenchmarkSettings = (get: GetRecoilValue): AnalysisViewBenchmarkSettings[] => {
  const ids = get(benchmarkInputs);
  return ids.map((id) => {
    const label = get(benchmarkInputName(id));
    const relative = get(benchmarkInputIsRelative(id));
    const type = get(benchmarkInputType(id));
    const subjectId = get(benchmarkInputSubject(id));
    const subject: AnalysisViewSubject | undefined = subjectId && {
      comparisonType: 'BENCHMARK' as const,
      id: getSubjectId(subjectId),
      subjectType: getAnalysisSubjectType(subjectId),
      isPrivate: !!subjectId.privateFundId || !!subjectId.privatePortfolioId,
    };
    return {
      id,
      label,
      relative,
      type,
      subject,
    };
  });
};

const convertAnalysisPeriodToDateRange = ({ periodToDate, endDate, startDate }: AnalysisPeriod): DateRange => {
  if (!periodToDate && !endDate && !startDate) {
    return { period: 'full' };
  }
  const period = periodToDate as RangeType;
  return {
    to: endDate,
    from: startDate,
    period,
  };
};

const convertDateRangeToAnalysisPeriod = (dateRange: DateRange = {}): AnalysisPeriod => {
  return {
    endDate: dateRange.to,
    periodToDate: dateRange.period,
    startDate: dateRange.from,
  };
};

export const analysisSubjectToSubject = ({
  subjectType,
  id,
  isPrivate,
}: Pick<AnalysisViewSubject, 'subjectType' | 'id' | 'isPrivate'>): Subject => ({
  privateFundId: isPrivate && subjectType === 'INVESTMENT' ? id : undefined,
  privatePortfolioId: isPrivate && subjectType === 'PORTFOLIO' ? id : undefined,
  fundId: !isPrivate && subjectType === 'INVESTMENT' ? id : undefined,
  portfolioId: !isPrivate && subjectType === 'PORTFOLIO' ? Number(id) : undefined,
});

const getAnalysisSubjectType = (subject: SubjectWithOptionalFee) => {
  if (subject.fundId || subject.privateFundId) {
    return 'INVESTMENT' as const;
  }
  return 'PORTFOLIO' as const;
};

const getInaccessibleSubjects = (view: AnalysisView) =>
  view.subjects.filter((subject) => subject.subjectInaccessible).map(analysisSubjectToSubject);
