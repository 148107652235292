import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ManageInvestmentDataTrigger } from 'venn-components';
import { Icon, GetColor, OverflowContainer, OverflowText, Tooltip, BrandLogo, getAppTitle } from 'venn-ui-kit';
import type { AnalysesResults } from '../../types';
import { MIN_SUPERCOLUMN_WIDTH } from './constants';

interface CategoryColumnSuperHeaderProps {
  analyses: AnalysesResults | undefined;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ analyses }: CategoryColumnSuperHeaderProps) => {
  const category = analyses?.subject?.categoryGroup;
  const hasCategory = category && !!category.name;
  const isPredicted = analyses?.subject?.isCategoryPredicted;
  const isSet = hasCategory && !isPredicted;

  const hasUnanalyzableCategory = category && !category.categoryId;
  const fundId = analyses?.subject?.fund?.id;

  const renderer = useCallback(
    () => (
      <CategorySuperHeader className="qa-category-header" isPredicted={isPredicted} isSet={isSet}>
        <Tooltip
          maxWidth={172}
          content={
            <Content>
              {hasCategory && <Title>Category: {category.name}</Title>}
              {hasUnanalyzableCategory && (
                <UnanalyzableNote>
                  <Icon type="exclamation-circle" /> This category doesn't have enough data to perform analysis
                </UnanalyzableNote>
              )}
              {isPredicted && <div>{`This category was predicted by ${getAppTitle()}.`}</div>}
              Click the cog icon in the header to edit category data.
            </Content>
          }
        >
          <TooltipChildren>
            {isPredicted && <BrandLogo height={14} />}
            <StyledOverflowContainer isPredicted={isPredicted}>
              <OverflowText.SingleLine>CATEGORY ({hasCategory ? category.name : 'NONE'})</OverflowText.SingleLine>
            </StyledOverflowContainer>
          </TooltipChildren>
        </Tooltip>
      </CategorySuperHeader>
    ),
    [category, hasCategory, hasUnanalyzableCategory, isPredicted, isSet],
  );

  return <ManageInvestmentDataTrigger fundId={fundId} customRenderer={renderer} />;
};

const CategorySuperHeader = styled.div<{ isPredicted?: boolean; isSet?: boolean }>`
  color: ${({ isPredicted, isSet }) =>
    isPredicted ? GetColor.HighlightDark : isSet ? GetColor.Black : GetColor.Primary.Dark};
  font-weight: 700;

  i {
    margin-left: 4px;
    margin-top: -2px;
  }
`;

const TooltipChildren = styled.div`
  display: flex;
  align-items: center;
  i {
    color: ${GetColor.Primary.Dark};
  }
`;

const Content = styled.div`
  max-width: 233px;
  text-transform: none;
  text-align: center;
`;

const StyledOverflowContainer = styled(OverflowContainer)<{ isPredicted?: boolean }>`
  max-width: ${({ isPredicted }) => MIN_SUPERCOLUMN_WIDTH - 24 - (isPredicted ? 28 : 0)}px;
  @media print {
    white-space: nowrap;
  }
`;

const UnanalyzableNote = styled.div`
  margin: 8px 0;
  i {
    margin-right: 4px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;
