import type { FC } from 'react';
import React from 'react';
import type { PerformanceSummaryContentProps } from './PerformanceSummaryContent';
import PerformanceSummaryContent from './PerformanceSummaryContent';
import Loading from '../Loading';
import styled from 'styled-components';

export const PerformanceSummary: FC<React.PropsWithChildren<PerformanceSummaryContentProps>> = (props) => {
  if (props?.analysisLoading || !props?.analysisConfig?.subject) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }

  return <PerformanceSummaryContent {...props} />;
};

const Wrapper = styled.div`
  position: relative;
  height: 400px;
`;
