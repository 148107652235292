import React from 'react';
import { type AnalysisSubject, assertNotNil, MANAGE_DATA_SECTION } from 'venn-utils';
import { PrivateInvestmentInformation } from './privates';
import PrivateInvestmentCashflowPacingParameters from './privates/cashflow-pacing/PrivateInvestmentCashflowPacingSettings';
import { PrivateInvestmentPerformanceData } from './privates/PrivateInvestmentPerformanceData';
import { PrivateInvestmentPortfolios } from './privates/PrivateInvestmentPortfolios';
import { PrivateInvestmentTransactions } from './privates/PrivateInvestmentTransactions';

interface PrivateInvestmentManageDataProps {
  subject?: AnalysisSubject;
  selectedSection?: MANAGE_DATA_SECTION;
}
export const PrivateInvestmentManageData = ({
  subject,
  selectedSection = MANAGE_DATA_SECTION.INVESTMENT_INFORMATION,
}: PrivateInvestmentManageDataProps) => {
  if (!subject?.privateFund?.id) {
    return null;
  }
  switch (selectedSection) {
    case MANAGE_DATA_SECTION.INVESTMENT_INFORMATION: {
      return <PrivateInvestmentInformation fund={subject?.privateFund} />;
    }
    case MANAGE_DATA_SECTION.DATA: {
      return <PrivateInvestmentPerformanceData fundId={assertNotNil(subject?.privateFund?.id)} />;
    }
    case MANAGE_DATA_SECTION.PORTFOLIOS: {
      return (
        <PrivateInvestmentPortfolios
          fundId={assertNotNil(subject?.privateFund?.id)}
          name={assertNotNil(subject?.privateFund?.name)}
        />
      );
    }
    case MANAGE_DATA_SECTION.TRANSACTIONS: {
      return <PrivateInvestmentTransactions fundId={assertNotNil(subject?.privateFund?.id)} />;
    }
    case MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS: {
      return <PrivateInvestmentCashflowPacingParameters fund={assertNotNil(subject?.privateFund)} />;
    }

    default: {
      return null;
    }
  }
};
