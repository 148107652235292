import React from 'react';
import styled from 'styled-components';
import { GetColor, Headline3, Body1, ColorUtils } from 'venn-ui-kit';
import { kebabCase } from 'lodash';

export interface SideMenuItem<T> {
  label: string;
  value: T;
}

export type ItemClickHandler<T> = (value: T, label: string) => void;

interface SideMenuProps<T> {
  title: string;
  items: SideMenuItem<T>[];
  selectedItem: T;
  onClick: ItemClickHandler<T>;
}

const MENU_BOTTOM_PADDING_PX = 20;
const TITLE_CONTAINER_HEIGHT_PX = 66;

const DeepDiveSideMenu = <T,>({ onClick, title, items, selectedItem }: SideMenuProps<T>) => {
  return (
    <Container hasTitle={Boolean(title)}>
      <MenuTitleContainer hasTitle={!!title}>{title && <Headline3>{title}</Headline3>}</MenuTitleContainer>
      <>
        {items.map(({ value, label }) => {
          const isItemSelected = selectedItem === value;
          return (
            <Item
              data-testid={`qa-${kebabCase(label)}-filter`}
              selected={isItemSelected}
              className={isItemSelected ? 'qa-selected-filter' : ''}
              onClick={() => onClick(value, label)}
              key={label}
            >
              <Label>{label}</Label>
            </Item>
          );
        })}
      </>
    </Container>
  );
};

const Container = styled.nav<{ hasTitle: boolean }>`
  border-radius: 4px;
  background-color: ${GetColor.WhiteGrey};
  padding-bottom: ${MENU_BOTTOM_PADDING_PX}px;

  ${Headline3} {
    margin: 20px 0 20px 20px;
  }
  ${({ hasTitle }) => !hasTitle && 'padding-top: 20px;'}
`;

const MenuTitleContainer = styled.div<{ hasTitle: boolean }>`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 14px;
  }
  padding-right: 15px;
  ${({ hasTitle }) => hasTitle && `height: ${TITLE_CONTAINER_HEIGHT_PX}px;`}
`;

const Item = styled.button<{
  selected: boolean;
}>`
  width: 100%;
  padding-left: 20px;
  padding-right: 15px;
  height: 32px;
  background-color: ${({ selected }) =>
    selected ? ColorUtils.opacifyFrom(GetColor.HighlightDark, 0.1) : 'transparent'};
  color: ${GetColor.Black};
  border: 1px solid ${({ selected }) => (selected ? GetColor.HighlightDark : 'transparent')};
  :hover {
    background-color: ${GetColor.PaleGrey};
    border: 1px solid ${GetColor.MidGrey1};
    border-left: none;

    :after {
      border-left: 10px solid ${GetColor.MidGrey1};
    }
  }

  :after {
    content: '';
    position: relative;
    border-left: 10px solid ${({ selected }) => (selected ? GetColor.HighlightDark : 'transparent')};
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    width: 0;
    height: 0;
    right: -25px;
  }
  border-left: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ selected }) => (selected ? 'font-weight: bold;' : '')}

  ${Body1} {
    color: ${GetColor.Black};
  }

  label {
    flex: none;
  }
`;

const Label = styled(Body1)`
  line-height: normal;
  max-width: 100%;
  flex: 1;
  position: relative;
  top: 1px;
`;

export default DeepDiveSideMenu;
