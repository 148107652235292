import React, { useContext } from 'react';
import { useRecoilState } from 'recoil';
import styled, { css, useTheme } from 'styled-components';
import type { CustomFactorForecast } from 'venn-api';
import { forecastPanelViewSelector, ForecastTab } from 'venn-state';
import { GetColor, Loading, ZIndex } from 'venn-ui-kit';
import type { TabOption, TabWrapperProps } from '../../../static-tabs';
import { TabContent, TabsContainer } from '../../../static-tabs';
import StaticTabs from '../../../static-tabs/StaticTabs';
import { FactorForecastsContainerV2 } from '../components/FactorForecastsContainerV2';
import InvestmentOverridesContainer from '../components/InvestmentOverridesContainer';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';
import TestClassNames from '../TestClassNames';

interface LeftForecastPanelProps {
  investmentForecastsSortFirst?: string[];
  onInvestmentForecastUpdated?: (fundId?: string) => void;
  onSetFactorForecastAsDefault: (forecast: CustomFactorForecast) => void;
  onDelete: (forecast: CustomFactorForecast) => void;
  isReadOnly: boolean;
}

const tabs: TabOption<ForecastTab>[] = [
  {
    tabId: ForecastTab.FactorForecast,
    title: 'Factor Forecasts',
  },
  {
    tabId: ForecastTab.InvestmentForecast,
    title: 'Investment Forecasts',
  },
];

const RedesignTabRenderer = styled.div.attrs({ 'data-testid': 'redesign-tab-renderer' })<TabWrapperProps>`
  padding: 10px;
  color: ${GetColor.Black};

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      font-weight: 700;
      border-bottom: 3px solid ${theme.Colors.HighlightDark};
    `}
`;

export const LeftForecastPanel = ({
  investmentForecastsSortFirst,
  onInvestmentForecastUpdated,
  onSetFactorForecastAsDefault,
  onDelete,
  isReadOnly,
}: LeftForecastPanelProps) => {
  const [view, setView] = useRecoilState(forecastPanelViewSelector);
  const theme = useTheme();

  const factorForecastView = (
    <React.Suspense fallback={<Loading title="Forecasts are loading... " />}>
      <FactorForecastsContainerV2
        onDelete={onDelete}
        onSetFactorForecastAsDefault={onSetFactorForecastAsDefault}
        isReadOnly={isReadOnly}
        setRightPanelView={(view) =>
          setView({
            tab: ForecastTab.FactorForecast,
            detail: view,
          })
        }
      />
    </React.Suspense>
  );
  const investmentForecastView = (
    <InvestmentOverridesContainer
      investmentForecastsSortFirst={investmentForecastsSortFirst}
      onInvestmentForecastUpdated={onInvestmentForecastUpdated}
      isReadOnly={isReadOnly}
      setRightPanelView={(view) =>
        setView({
          tab: ForecastTab.InvestmentForecast,
          detail: view,
        })
      }
    />
  );

  const { changeTab } = useContext(ForecastPanelActionsContext);

  const selectedTabId = view.tab;

  return (
    <TabsContainer gap={18}>
      <Subtitle>Forecast Options</Subtitle>
      <StaticTabs<ForecastTab>
        className={TestClassNames.ForecastsListsTabsPicker}
        tabClassName={undefined}
        tabs={tabs}
        onTabSelected={changeTab}
        selectedTabId={selectedTabId}
        borderLocation="bottom"
        tabRenderer={RedesignTabRenderer}
        rootCssOverrides={css`
          background: none;
          border-bottom: 1px solid ${theme.Colors.MidGrey1};
          align-self: flex-start;
        `}
        positionerCssOverrides={css`
          gap: 16px;
        `}
      />
      <StyledTab
        className={TestClassNames.ForecastsListsTabsContainer}
        noBorderTop
        noBorderRight={selectedTabId === ForecastTab.InvestmentForecast}
      >
        {selectedTabId === ForecastTab.FactorForecast && factorForecastView}
        {selectedTabId === ForecastTab.InvestmentForecast && investmentForecastView}
      </StyledTab>
    </TabsContainer>
  );
};

export const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: white;
  z-index: ${ZIndex.ModalCover};
  align-content: center;
  justify-content: center;
  padding-top: 180px;
`;

const StyledTab = styled(TabContent)<{ noBorderTop: boolean; noBorderRight: boolean }>`
  flex-grow: 1;
  ${({ noBorderRight, theme }) => !noBorderRight && `border-right: 1px solid ${theme.Colors.PaleGrey};`}
  ${({ noBorderTop, theme }) => !noBorderTop && `border-top: 1px solid ${theme.Colors.PaleGrey};`};
`;

const Subtitle = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
