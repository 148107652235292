import type React from 'react';
import { useCallback } from 'react';
import { flushSync } from 'react-dom';

type Props = {
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
  input: React.RefObject<HTMLInputElement>;
  value?: string;
  onFocusInner?: () => void;
};
const useInputStateHelper = ({ setFocused, input, value, onFocusInner }: Props) => {
  const onFocus = useCallback(() => {
    flushSync(() => setFocused(true));
    requestAnimationFrame(() => {
      const target = input.current;
      target && target.setSelectionRange(0, String(value).length);
      onFocusInner?.();
    });
  }, [input, onFocusInner, setFocused, value]);
  return {
    onFocus,
  };
};

export default useInputStateHelper;
