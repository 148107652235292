import { type ReactNode, useCallback } from 'react';
import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor, Headline3, Subtitle1, ZIndex } from 'venn-ui-kit';
import type { TabularDataRow } from '../types';
import type { ComparisonAnalysisBlock } from '../analysisBlocks';
import { ComparePivotedWatermark, type BasicTableColumn } from 'venn-components';
import { getPivotedColumns, pivotData } from '../logic/pivotUtils';
import ComparisonTable from './ComparisonTable';
import { SUBJECT_NAME_WIDTH } from '../constants';
import type { AnalysisBlocksProps } from './AnalysisBlocks';
import AnalysisBlocks from './AnalysisBlocks';
import SubjectError from './SubjectError';
import SubjectLabel from './SubjectLabel';
import type { ComparisonSubject } from 'venn-utils';

// Will only change a-z values, not β
const toSafeUpperCase = (value: string): string => value.replace(/([a-z])/g, (val) => val.toUpperCase());

const CombinedAnalysisTable: React.FC<React.PropsWithChildren<AnalysisBlocksProps>> = (props) => {
  const {
    analyses,
    subjects,
    blocks,
    setHovered,
    hoveredMetricIdx,
    relative,
    factorLens,
    isLoading,
    fetchingInBackground,
    onRemoveSubject,
  } = props;
  const { Colors } = useContext(ThemeContext);

  const { superHeaders, blockFooters, blocksWithErrors, columns, pivotedData } = useMemo(() => {
    const superHeaderRows: ReactNode[] = [<td colSpan={1} key={0} />];
    const blockFooterRows: ReactNode[] = [<td colSpan={1} key={0} style={{ border: 'none' }} />];
    const blocksWithErrorsTemp: ComparisonAnalysisBlock[] = [];
    let pivotedColumns: BasicTableColumn<TabularDataRow>[] = [];
    const pivotedDataTemp: TabularDataRow[] = [];
    let dataLength = 0;

    blocks.forEach((block) => {
      const analysis = analyses && analyses.find((a) => a.analysisType === block.type);

      if (analysis?.message) {
        blocksWithErrorsTemp.push(block);
      } else {
        const extractedData = filterBenchmark(
          subjects,
          block.extractor(subjects, analysis, relative, factorLens),
          relative,
        );
        const visibleSubjects = !relative ? subjects : subjects.filter((subject) => !subject.isBenchmark);
        const blockData = pivotData(
          extractedData,
          visibleSubjects,
          dataLength,
          relative,
          analysis?.analysisType === 'PAIRWISE_CORRELATION',
        );
        const blockColumns = getPivotedColumns(
          extractedData,
          isLoading,
          setHovered,
          block.cellRenderer,
          block.headerRenderer,
          dataLength,
          onRemoveSubject,
          Colors,
        );

        const colSpanLength = dataLength === 0 ? blockColumns.length - 1 : blockColumns.length;
        superHeaderRows.push(
          <td colSpan={colSpanLength} key={superHeaderRows.length}>
            <SuperHeaderCell>{toSafeUpperCase(block.getTitle(relative))}</SuperHeaderCell>
          </td>,
        );
        blockFooterRows.push(
          <td colSpan={colSpanLength} key={blockFooterRows.length} style={{ backgroundColor: Colors.White }}>
            {block.footerComponent ? <block.footerComponent /> : undefined}
          </td>,
        );

        pivotedColumns = [...pivotedColumns, ...blockColumns];
        for (let subjectIdx = 0; subjectIdx < blockData.length; subjectIdx++) {
          const subjectRow = pivotedDataTemp[subjectIdx];
          if (!subjectRow) {
            pivotedDataTemp[subjectIdx] = blockData[subjectIdx];
          } else {
            for (let cellIdx = 0; cellIdx < extractedData.length; cellIdx++) {
              pivotedDataTemp[subjectIdx][dataLength + cellIdx] = extractedData[cellIdx][subjectIdx];
            }
          }
        }
        if (dataLength === 0) {
          dataLength += 1;
        }
        dataLength += extractedData.length;
      }
    });
    return {
      superHeaders: superHeaderRows,
      blockFooters: blockFooterRows,
      blocksWithErrors: blocksWithErrorsTemp,
      columns: pivotedColumns,
      pivotedData: pivotedDataTemp,
    };
  }, [blocks, subjects, onRemoveSubject, setHovered, isLoading, analyses, relative, factorLens, Colors]);

  return (
    <MinHeight>
      <Outer>
        <TableWrapper>
          <ComparisonTable
            renderHead={() => <tr>{superHeaders}</tr>}
            renderTail={() => <tr style={{ pointerEvents: 'none', backgroundColor: Colors.White }}>{blockFooters}</tr>}
            columns={columns}
            data={pivotedData}
            isPrinting={false}
            fetchingInBackground={fetchingInBackground}
            flexibleTableLayout
            highlightedColumnIdx={hoveredMetricIdx}
          />
        </TableWrapper>
        <ScrollShadow />
        <ComparePivotedWatermark />
      </Outer>
      {!!blocksWithErrors.length && (
        <ErrorSection>
          <div>
            <Headline3>Some analyses couldn't run.</Headline3>
            <Subtitle1>See below for more information.</Subtitle1>
          </div>
          <AnalysisBlocks {...props} blocks={blocksWithErrors} />
        </ErrorSection>
      )}
    </MinHeight>
  );
};

const filterBenchmark = (
  subjects: ComparisonSubject[],
  data: TabularDataRow[],
  relative: boolean,
): TabularDataRow[] => {
  if (!relative) {
    return data;
  }
  const result: TabularDataRow[] = [];
  for (let dataIdx = 0; dataIdx < data.length; dataIdx++) {
    const { getter, label, type } = data[dataIdx];
    const row: TabularDataRow = { getter, label, type };
    let rowSubjectIdx = 0;
    for (let subjectIdx = 0; subjectIdx < subjects.length; subjectIdx++) {
      if (!subjects[subjectIdx].isBenchmark) {
        row[rowSubjectIdx++] = data[dataIdx][subjectIdx];
      }
    }
    result.push(row);
  }
  return result;
};

const MinHeight = styled.div`
  min-height: 700px;
`;

const Outer = styled.div`
  position: relative;
`;

const SCROLLBAR_SIZE = 12;

const ScrollShadow = styled.div`
  position: absolute;
  top: 0;
  right: -${SCROLLBAR_SIZE}px;
  height: calc(100% - ${SCROLLBAR_SIZE}px);
  box-shadow: -5px 0 5px -5px ${GetColor.Grey};
  width: ${SCROLLBAR_SIZE}px;
`;

const SuperHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 3px solid ${GetColor.Black};
  margin: 0 2px;
  height: 25px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin-left: ${SUBJECT_NAME_WIDTH}px;
  > div {
    position: static;
  }
`;

const ErrorSection = styled.section`
  margin-top: 36px;
  border-top: 20px solid ${GetColor.PaleGrey};
  > :first-child {
    margin-bottom: 36px;
  }
`;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: AnalysisBlocksProps) => {
  const {
    isPrinting,
    subjectErrors,
    subjects,
    setDateRange,
    relative,
    trackingId,
    dateRange,
    hasBenchmark,
    maxFrequency,
    removeComparisonSubject,
    fetchingInBackground,
    onRemoveSubject,
    setHovered,
    hoveredMetricIdx,
  } = props;

  const { Colors } = useContext(ThemeContext);

  const cellRendererComponent = useCallback(
    (_: TabularDataRow, index: number) => (
      <SubjectLabel
        key={subjects[index]?.analysisSubject?.id}
        idx={index}
        subject={subjects[index] ?? {}}
        onRemove={() => onRemoveSubject(index)}
        onHover={(hovered) => setHovered(index, hovered)}
      />
    ),
    [subjects, onRemoveSubject, setHovered],
  );

  if (isPrinting) {
    return <AnalysisBlocks {...props} />;
  }

  // Handle subject errors
  if (subjectErrors?.some((e) => e && e.type === 'ERROR')) {
    return (
      <ErrorContainer>
        <ComparisonTable
          columns={[
            {
              label: '',
              accessor: 'label',
              cellStyle: { backgroundColor: Colors.White, zIndex: ZIndex.Base },
              headerStyle: {
                backgroundColor: Colors.White,
                zIndex: ZIndex.Base,
              },
              cellRenderer: cellRendererComponent,
            },
          ]}
          data={subjects.map(() => ({
            label: '',
            type: 'percentage',
          }))}
          isPrinting={false}
          fetchingInBackground={fetchingInBackground}
          flexibleTableLayout
          highlightedColumnIdx={hoveredMetricIdx}
        />
        <SubjectError
          subjectErrors={subjectErrors}
          subjects={subjects}
          setDateRange={setDateRange}
          relative={relative}
          trackingId={trackingId}
          dateRange={dateRange}
          hasBenchmark={hasBenchmark}
          maxFrequency={maxFrequency}
          removeComparisonSubject={removeComparisonSubject}
        />
      </ErrorContainer>
    );
  }

  return <CombinedAnalysisTable {...props} />;
};

const ErrorContainer = styled.div`
  display: flex;
  margin-left: ${SUBJECT_NAME_WIDTH}px;
  position: relative;
  > :first-child {
    margin-top: 8px;
    margin-right: 20px;
  }
`;
