import React, { useContext, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import type { ColumnMapping, FileMapping, FileUploadMetadata, ParsedPrivateFunds } from 'venn-api';
import { Content, ErrorMessage, FooterButtons, Header } from '../../components/page-parts';
import { DataStatusBar } from '../../components/review-data';
import type { ErrorViewModel } from './helpers';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from '../../../../basictable/BasicTable';
import { default as BasicTable } from '../../../../basictable/BasicTable';
import { GetColor, Headline3 } from 'venn-ui-kit';
import type { PrivateFundUploadMetadataCount } from '../../components/renderers';
import {
  createCellRenderer,
  ErrorCellRenderer,
  FundLinkerRenderer,
  PrivateFundCellRenderer,
  PrivateFundUploadMetadataCountRenderer,
} from '../../components/renderers';
import { compact } from 'lodash';
import { default as DataUploaderContext } from '../../context';
import { Header as MapDataTableHeader } from '../../components/map-data';
import { toOptionNumber } from '../mapping/helpers';
import { DataUploaderMode } from '../../types';
import type { DropdownValue } from '../../components/Dropdown';
import { Mixed } from '../../components/Dropdown';
import { globalUploadAppendTypeOptionPrivateFund } from 'venn-state';
import { useRecoilState } from 'recoil';
import { UploaderFaqLinkShared } from '../../components/FaqLink';
import { UniversalUploaderFooter } from '../../components/page-parts/UniversalUploaderFooter';

export interface ReviewProps {
  parsedPrivateFunds: ParsedPrivateFunds;
  onMappingChange: (newMapping: FileMapping) => void;
  onCancel: () => void;
  onStartOver: () => void;
  onContinue: (parsedPrivateFunds: ParsedPrivateFunds) => void;
  loading: boolean;
  /**
   * Custom text to display on the Upload Review screen
   */
  customUploadReviewButtonLabel?: string;
  metadata: FileUploadMetadata;

  multiUploaderLayout: boolean;
}

export interface ReviewState {
  errors: ErrorViewModel[];
  editingSeriesId?: string;
}

/**
 * Step 2 of the new upload returns process
 */
export const ReviewPrivates = ({
  parsedPrivateFunds,
  onCancel,
  onStartOver,
  loading,
  customUploadReviewButtonLabel,
  onContinue,
  metadata,
  multiUploaderLayout,
}: ReviewProps & ReviewState) => {
  // const { appendType } = metadataOptions;
  const { error } = useContext(DataUploaderContext);
  const theme = useTheme();
  const columns: ColumnMapping[] = parsedPrivateFunds.mappings.map(
    (m) =>
      ({
        fundName: m.rawFundName,
        name: m.rawFundName,
        fundId: m.fundId,
        seriesId: m.rawFundName,

        // greys out rows if 0
        newDataCount: m.hasMetadata && !m.skipPersist ? 1 : 0,
      }) as ColumnMapping,
  );
  const [shouldReset, setShouldReset] = useState(true);
  const [globalAppendTypeOption, setGlobalAppendTypeOption] = useRecoilState(globalUploadAppendTypeOptionPrivateFund);
  const noDataError =
    !parsedPrivateFunds.funds || Object.keys(parsedPrivateFunds.funds).length === 0
      ? 'No investments found, please check the format of the uploaded file'
      : undefined;

  useEffect(() => {
    shouldReset && setGlobalAppendTypeOption(undefined);
    setShouldReset(false);
  }, [shouldReset, setGlobalAppendTypeOption]);

  const onContinueClick = () => onContinue(parsedPrivateFunds);
  const onHeaderDropdownChange = (columns: ColumnMapping[], values: DropdownValue[]) => {
    setGlobalAppendTypeOption(values[0] as number);
  };

  const getTableColumns = (columns: ColumnMapping[]): BasicTableColumn<ColumnMapping>[] => {
    const mode = undefined;
    const onMappingChange = undefined;
    const editingSeriesId = undefined;
    const mapping = undefined;

    const privateObservationMetadata: { [fundId: string]: PrivateFundUploadMetadataCount } = {};
    parsedPrivateFunds.mappings.forEach(
      (m) =>
        (privateObservationMetadata[m.rawFundName] = {
          performanceRows: parsedPrivateFunds?.performance[m.rawFundName]?.length ?? 0,
          cashFlowRows: parsedPrivateFunds?.transactions[m.rawFundName]?.length ?? 0,
          metadataRows:
            parsedPrivateFunds.mappings.filter((f) => f.rawFundName === m.rawFundName && f.hasMetadata)?.length > 0
              ? 1
              : 0,
        }),
    );

    const rendererArgs = {
      mode,
      mapping,
      columns,
      metadata,
      privateObservationMetadata,
      useCustomErrorMessage: true,
      errors: compact(
        parsedPrivateFunds.mappings
          .filter((f) => !f.hasMetadata)
          .map(
            (f) =>
              ({
                seriesId: f.rawFundName,
                isValid: false,
                errors: ['Investment does not exist and is missing uploaded metadata'],
              }) as ErrorViewModel,
          ),
      ),
      colors: theme.Colors,
      onChange: onMappingChange,
    };

    return [
      {
        cellStyle: { width: 60 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: FundLinkerRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 300 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: PrivateFundCellRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 150 + 240, overflowX: 'hidden' },
        cellRenderer: createCellRenderer.call(null, {
          renderer: PrivateFundUploadMetadataCountRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 250 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: ErrorCellRenderer,
          editingSeriesId,
          toggleEditing: undefined,
          hoverRowIndex: null, // TODO: add back to allow ignoring funds for upload
          ...rendererArgs,
        }),
      },
    ];
  };

  const headerInnerComponent = (
    <>
      <HeaderInner>
        <div>
          <h1>Review &amp; Validate Data</h1>
          <h2>Ensure data is mapped correctly.</h2>
        </div>
      </HeaderInner>
      <StatusBarContainer>
        <DataStatusBar
          newCount={parsedPrivateFunds.mappings.filter((m) => !m.fundId).length}
          updateCount={parsedPrivateFunds.mappings.filter((m) => !!m.fundId).length}
        />
      </StatusBarContainer>
    </>
  );

  const tableComponent = (
    <>
      {!!noDataError && (
        <CenterError>
          <div>
            <Headline3>No investments found in uploaded file</Headline3>
            <HelpText>Questions? Visit the {UploaderFaqLinkShared(DataUploaderMode.Privates)}.</HelpText>
          </div>
        </CenterError>
      )}
      {!noDataError && (
        <StyledContent>
          <MapDataTableHeader
            mode={DataUploaderMode.Privates}
            columns={[
              {
                options: metadata.appendType.map(toOptionNumber),
                readonly: false,
                mixedLabel: 'Mixed Overlap Strategy',
              },
            ]}
            values={[(globalAppendTypeOption ?? Mixed) as DropdownValue]}
            onChange={(values: DropdownValue[]) => onHeaderDropdownChange(columns, values)}
            marginLeft={38}
          />
          <StyledBasicTable
            hideHead
            onRowHoverToggle={undefined}
            data={columns}
            columns={getTableColumns(columns)}
            rowHeight={60}
          />
        </StyledContent>
      )}
    </>
  );
  return multiUploaderLayout ? (
    <MainWrapper>
      {!!noDataError && <ErrorMessage>{noDataError}</ErrorMessage>}
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
      {tableComponent}
      <UniversalUploaderFooter
        onCancel={onCancel}
        onStartOver={onStartOver}
        onContinue={onContinueClick}
        disabled={loading}
        hasNoData={!!noDataError}
        isSample={false}
        primaryLabel={customUploadReviewButtonLabel ?? 'Add to Library'}
      />
    </MainWrapper>
  ) : (
    <>
      <Header>
        {headerInnerComponent}
        {!!noDataError && <ErrorMessage>{noDataError}</ErrorMessage>}
        {!!error && <ErrorMessage>{error}</ErrorMessage>}
      </Header>
      {tableComponent}
      <FooterButtons
        onCancel={onCancel}
        onStartOver={onStartOver}
        onContinue={onContinueClick}
        disabled={loading}
        hasNoData={!!noDataError}
        isSample={false}
        primaryLabel={customUploadReviewButtonLabel ?? 'Add to Library'}
      />
    </>
  );
};

const StyledBasicTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  > thead > tr {
    border-bottom-color: ${GetColor.DarkGrey};
  }

  > tbody,
  > thead {
    > tr {
      > th {
        color: ${GetColor.DarkGrey};
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 0;
        padding-right: 0;

        &:first-child {
          padding-left: 15px;
        }
      }

      > td {
        padding: 0px 0;
        border-bottom: 1px solid ${GetColor.PaleGrey};
      }
    }
  }
`;

const HeaderInner = styled.div`
  display: flex;
`;

const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  section + section {
    margin-top: 30px;
  }
`;

const StatusBarContainer = styled.div`
  padding: 7px 0 0;
`;

const CenterError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const HelpText = styled.div`
  font-size: 16px;
  color: ${GetColor.DarkGrey};
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;
