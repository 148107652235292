import type { CellClassFunc, ColDef, ColSpanParams, NestedFieldPaths, ValueGetterParams } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo } from 'react';
import type { StudioAnalysisRequest } from 'venn-state';
import {
  assertNotNil,
  assertExhaustive,
  getComparisonLabelForBlockLegend,
  type ReturnsFrequency,
  returnsFrequencyToColumns,
} from 'venn-utils';
import type { ReturnsGridRows } from './useReturnsGridData';
import type { ReturnsGridProps } from './returnsGridParserHelper';
import { lazyMergeClasses } from '../../../../utils/ag-grid/styling';
import { BOLD_CLASS, TOTAL_LABEL, PROXY_CELL_CLASS, OUT_OF_RANGE_CELL_CLASS } from '../../../customAnalysisContants';
import { getGroupColumnLabel, createLabelColumn } from '../../../logic/columnParsers';
import { getDefaultValueFormat, getDefaultCellClass } from '../../../logic/gridStyling';
import { isReturnsGridRow, isMetricRow } from '../../../logic/typeUtils';
import type { HeaderComponentParamsType, ReturnsGridRow } from '../../../types';
import { BasicHeaderRenderer } from '../../grid/renderers/BasicHeaderRenderer';
import HeaderCellRenderer from '../../grid/renderers/HeaderCellRenderer';
import { HEADER_LAST_COL_CLASS, HEADER_LAST_COL_OF_COL_GROUP_CLASS } from '../../grid/AgGridThemeOverrides';
import { useMeasureGridText } from '../../../../utils/grids';
import { formatExportableSubjectWithOptionalFee } from '../../../../legend';

/** Generates column definitions for calendar based returns grids, such as the label column, month/quarter columns, and a total column. */
export const useReturnsGridColumnDefs = (
  rowData: ReturnsGridRow[],
  requests: StudioAnalysisRequest[],
  returnsGridProps: ReturnsGridProps,
): ColDef<ReturnsGridRows>[] => {
  const measureGridText = useMeasureGridText();
  const dataGroupColumn = useDataGroupColumn(
    rowData,
    returnsGridProps.returnsFrequency,
    requests[returnsGridProps.subjectIndex],
  );

  /** When using browser zoom, sizes sometimes get distorted, and without any buffer we will get overflow as soon as the user zooms their browser at all. */
  const browserZoomBufferPX = 1;
  // We just hardcode the year 2000 here because it will be a long time before we need to support returns from the years of 999 and below or 10000 and above.
  const labelColumnMinWidth = useMemo(() => measureGridText('2000', 'bold'), [measureGridText]) + browserZoomBufferPX;

  const labelColumn = useMemo(
    () =>
      getGroupColumnLabel({
        ...createLabelColumn({
          requests,
        }),
        // The years are a known 4-digit width so there is no reason to make them flex or have an adjustable width, especially since an adjustable width
        // will cause ag-grid to take space from other columns unnecessarily.
        flex: undefined,
        minWidth: labelColumnMinWidth,
        maxWidth: labelColumnMinWidth,
      }),
    [labelColumnMinWidth, requests],
  );

  return useMemo(() => [labelColumn, dataGroupColumn], [labelColumn, dataGroupColumn]);
};

/** Column group that contains all datacolumns including the header row, but does not contain the label column. */
function useDataGroupColumn(rowData: ReturnsGridRow[], returnsFrequency: ReturnsFrequency, req: StudioAnalysisRequest) {
  const measureCellContent = useMeasureGridText();

  const dataColumns = useMemo(() => {
    const periodColumns = getColumnDefForFrequency(returnsFrequency).map((partialColumnDef) => {
      const valueGetter = returnsGridValueGetter;
      const valueFormatter = getDefaultValueFormat;

      const minWidth = Math.max(
        ...rowData.map((row) => {
          const value = valueGetter({ data: row, colDef: partialColumnDef }) ?? 0;
          const formattedValue = valueFormatter({ value, data: row, colDef: partialColumnDef });
          return measureCellContent(formattedValue, 'normal');
        }),
      );

      return {
        ...partialColumnDef,
        valueGetter,
        valueFormatter,
        minWidth,
      };
    });

    const totalValueGetter = returnsGridTotalValueGetter;
    const totalValueFormatter = getDefaultValueFormat;
    const totalColumn: ColDef<ReturnsGridRows> = {
      headerClass: [BOLD_CLASS, HEADER_LAST_COL_CLASS, HEADER_LAST_COL_OF_COL_GROUP_CLASS],
      headerComponent: BasicHeaderRenderer,
      headerName: TOTAL_LABEL,
      cellClass: returnsGridTotalCellClassFunc,
      valueGetter: totalValueGetter,
      valueFormatter: totalValueFormatter,
    };
    totalColumn.minWidth = Math.max(
      ...rowData.map((row) => {
        const value = totalValueGetter({ data: row }) ?? 0;
        const formattedValue = totalValueFormatter({ value, data: row, colDef: totalColumn });
        return measureCellContent(formattedValue, 'bold');
      }),
    );

    return [...periodColumns, totalColumn];
  }, [measureCellContent, returnsFrequency, rowData]);

  const headerName = `${formatExportableSubjectWithOptionalFee(req.subject)}${
    req.benchmark && req.relative ? ` relative to ${req.benchmark.name}` : ''
  }`;
  const relativeTo = req.relative ? req.benchmark : undefined;
  const headerGroupComponentParams: HeaderComponentParamsType = useMemo(
    () => ({
      displayName: getComparisonLabelForBlockLegend(req.subject),
      subject: req.subject,
      relativeTo,
      isCommonBenchmark: req.isBenchmark,
    }),
    [req.subject, req.isBenchmark, relativeTo],
  );
  const groupColumn = useMemo(
    () => ({
      marryChildren: true,
      headerName,
      headerGroupComponent: HeaderCellRenderer,
      headerGroupComponentParams,
      children: dataColumns,
    }),
    [headerName, headerGroupComponentParams, dataColumns],
  );

  return groupColumn;
}

const returnsGridTotalValueGetter = ({ data }: Pick<ValueGetterParams<ReturnsGridRows>, 'data'>): number | undefined =>
  isReturnsGridRow(data) ? data.total?.value : undefined;

const returnsGridTotalCellClassFunc: CellClassFunc<ReturnsGridRows> = lazyMergeClasses(
  // getDefaultCellClass can handle arbitrary CustomRows, but we need to cast it so that TS knows that we're specifically handling ReturnsGridRows
  getDefaultCellClass as CellClassFunc<ReturnsGridRows>,
  ({ data }) => compact([isReturnsGridRow(data) && data.total?.isProxy && PROXY_CELL_CLASS]),
);

const returnsGridCellClassFunc: CellClassFunc<ReturnsGridRows> = lazyMergeClasses(
  // getDefaultCellClass can handle arbitrary CustomRows, but we need to cast it so that TS knows that we're specifically handling ReturnsGridRows
  getDefaultCellClass as CellClassFunc<ReturnsGridRows>,
  ({ data, colDef }) =>
    compact([
      isReturnsGridRow(data) && data.value[assertNotNil(colDef.field)]?.isProxy && PROXY_CELL_CLASS,
      isReturnsGridRow(data) && !data.value[assertNotNil(colDef.field)]?.isInRange && OUT_OF_RANGE_CELL_CLASS,
    ]),
);

const returnsGridValueGetter = ({ data, colDef }: Pick<ValueGetterParams<ReturnsGridRows>, 'data' | 'colDef'>) => {
  if (!data) {
    return undefined;
  }
  const index = Number(colDef.field);

  if (isReturnsGridRow(data)) {
    return data.value[index]?.value;
  }
  if (isMetricRow(data)) {
    return data.value[index];
  }

  return assertExhaustive(data, 'unexpected row data type in returnsGridColumnParser valueGetter');
};

function createPeriodColDefs(returnsFrequency: ReturnsFrequency): ColDef<ReturnsGridRows>[] {
  const columns = returnsFrequencyToColumns(returnsFrequency);
  // Span the metadata across the entire group (plus 1 for the total)
  const colSpanFn = ({ data }: ColSpanParams<ReturnsGridRows>) => (data?.isMetadata ? columns.length + 1 : 1);
  return columns.map(
    (periodColumn, index): ColDef<ReturnsGridRows> => ({
      headerClass: BOLD_CLASS,
      headerName: periodColumn.name,
      headerTooltip: periodColumn.tooltip,
      colSpan: colSpanFn,
      cellClass: returnsGridCellClassFunc,
      // todo: fix types VENN-27399
      field: String(index) as NestedFieldPaths<ReturnsGridRows>,
    }),
  );
}

const quarterlyColumnDefs = createPeriodColDefs('QUARTERLY');
const monthlyColumnDefs = createPeriodColDefs('MONTHLY');
const yearlyColumnDefs = createPeriodColDefs('YEARLY');

function getColumnDefForFrequency(returnsFrequency: ReturnsFrequency) {
  switch (returnsFrequency) {
    case 'QUARTERLY':
      return quarterlyColumnDefs;
    case 'MONTHLY':
      return monthlyColumnDefs;
    case 'YEARLY':
      return yearlyColumnDefs;
    default:
      throw assertExhaustive(returnsFrequency, `Unexpected returnsFrequency value: ${returnsFrequency}`);
  }
}
