import React, { useCallback } from 'react';
import type { ReorderableItem } from './ReorderableList';
import { ReorderableListInner } from './ReorderableList';
import type ReactDnd from '@hello-pangea/dnd';
import type { DropResult } from '@hello-pangea/dnd';
import { useModuleLazily } from 'venn-utils';

export interface ReorderableGroup<GROUP_ID, ITEM = ReorderableItem> {
  id: GROUP_ID;
  reorderableItems: ITEM[];
}

export interface ReorderableListGroupProps<GROUP_ID = string, ITEM = ReorderableItem> {
  reorderableGroups: ReorderableGroup<GROUP_ID, ITEM>[];
  onReorderItems: (sourceGroup: GROUP_ID, destGroup: GROUP_ID, sourceIndex: number, destIndex: number) => void;
  /** Component which wraps each group within the list */
  GroupWrapper: (props: { group: ReorderableGroup<GROUP_ID, ITEM>; children: React.ReactNode }) => JSX.Element;
  /** Custom item component which will be used instead of label. */
  itemRenderer?: (groupId: GROUP_ID, item: ITEM, index: number) => JSX.Element;
  hideDelete?: boolean;
  disabled?: boolean;
}

/**
 * Component which renders multiple reorderable lists, allowing items to be reordered both within the lists and between them
 */
export const ReorderableListGroup = <GROUP_ID extends string, ITEM extends ReorderableItem>({
  reorderableGroups,
  onReorderItems,
  GroupWrapper,
  itemRenderer,
  ...props
}: ReorderableListGroupProps<GROUP_ID, ITEM>) => {
  const reactDnd: typeof ReactDnd | null = useModuleLazily(useCallback(() => import('@hello-pangea/dnd'), []));

  if (reactDnd === null) {
    return null;
  }

  const { DragDropContext } = reactDnd;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceGroupId = result.source.droppableId as GROUP_ID;
    const destGroupId = result.destination.droppableId as GROUP_ID;
    onReorderItems(sourceGroupId, destGroupId, result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {reorderableGroups.map((group) => (
        <GroupWrapper key={group.id} group={group}>
          <ReorderableListInner
            {...props}
            reactDnd={reactDnd}
            onReorderItems={() => {}}
            reorderableItems={group.reorderableItems}
            itemRenderer={itemRenderer ? (item: ITEM, index: number) => itemRenderer(group.id, item, index) : undefined}
            id={group.id}
          />
        </GroupWrapper>
      ))}
    </DragDropContext>
  );
};
