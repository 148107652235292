import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { GetColor, PrintAnalysisTemplate } from 'venn-ui-kit';
import { formatConstraintValue, getConstraintCondition, PortfolioLabContext } from 'venn-components';
import type { AllocConstraint } from 'venn-utils';
import { Numbers } from 'venn-utils';
import { isNil, sortBy } from 'lodash';

const PrintConstraintsFooter = () => {
  const { allocationConstraints, factorConstraints, factorBreakdownData } = useContext(PortfolioLabContext);

  const factors = useMemo(
    () => [
      ...sortBy(
        factorBreakdownData.filter((factor) => factor.significant),
        (factor) => -factor.exposure,
      ),
      ...sortBy(
        factorBreakdownData.filter((factor) => !factor.significant),
        (factor) => -factor.exposure,
      ),
    ],
    [factorBreakdownData],
  );

  return (
    <PrintFooter>
      <PrintAnalysisTemplate>Allocation Constraints</PrintAnalysisTemplate>
      <PrintContent>
        {allocationConstraints.length > 0 ? (
          <>
            {allocationConstraints.map((constraint, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <PrintConstraint key={idx}>
                <Idx>{idx + 1}</Idx> {formatConstraintSubjects(constraint)}{' '}
                {constraint.items.length > 1 || constraint.allInvestments ? 'have a' : 'has a'}{' '}
                {getConstraintCondition(constraint.condition)}of{' '}
                {formatConstraintValue(
                  constraint.allInvestments ? 2 : constraint.items.length,
                  constraint.valueType,
                  constraint.value,
                )}
              </PrintConstraint>
            ))}
          </>
        ) : (
          'No allocation constraints set'
        )}
      </PrintContent>
      <PrintAnalysisTemplate>Factor Exposure Constraints</PrintAnalysisTemplate>
      <PrintContent>
        {factorConstraints.length > 0 ? (
          <>
            {factors.map((factor) => {
              const minConstraint = factorConstraints.find(
                (constraint) => constraint.factorId === factor.id && constraint.condition === 'minExposure',
              );
              const maxConstraint = factorConstraints.find(
                (constraint) => constraint.factorId === factor.id && constraint.condition === 'maxExposure',
              );
              if (isNil(minConstraint) && isNil(maxConstraint)) {
                return null;
              }
              return (
                <div key={`${factor.id}`}>
                  <div>
                    <strong>{factor.name}</strong>
                  </div>
                  <ul>
                    {isNil(minConstraint) ? null : (
                      <li>Minimum exposure: {Numbers.safeFormatNumber(minConstraint.value, 2)} (β)</li>
                    )}
                    {isNil(maxConstraint) ? null : (
                      <li>Maximum exposure: {Numbers.safeFormatNumber(maxConstraint.value, 2)} (β)</li>
                    )}
                  </ul>
                </div>
              );
            })}
          </>
        ) : (
          'No factor exposure constraints set'
        )}
      </PrintContent>
    </PrintFooter>
  );
};

export default PrintConstraintsFooter;

const formatConstraintSubjects = (constraint: AllocConstraint): string => {
  if (constraint.allInvestments) {
    return 'All investments';
  }

  if (constraint.items.length === 0) {
    return '--';
  }

  return `${
    constraint.items[0].fund
      ? constraint.items.length === 1
        ? 'Investment:'
        : 'Investments:'
      : constraint.items.length === 1
        ? 'Strategy:'
        : 'Strategies:'
  } ${constraint.items.map((item) => item.name).join(', ')}`;
};

const PrintFooter = styled.div`
  display: none;
  @media print {
    display: block;
  }
`;

const PrintConstraint = styled.div`
  display: flex;
  align-items: center;
`;

const Idx = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${GetColor.Black};
  color: ${GetColor.White};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
`;

const PrintContent = styled.div`
  padding: 4px;
  font-size: 12px;
`;
