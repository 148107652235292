import React, { type ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { KeyCodes, portalMenuIgnoreActivityClassName } from 'venn-ui-kit';
import { eventTargetOrAncestorContainsClass } from 'venn-utils';
import type { ModalWrapperProps } from './components/ModalWrapper';
import ModalWrapper from './components/ModalWrapper';
import type { ModalOverlayProps } from './components/ModalOverlay';
import ModalOverlay from './components/ModalOverlay';
import FocusTrap from 'focus-trap-react';

export interface ModalProps extends ModalOverlayProps, Partial<ModalWrapperProps> {
  /** Function fired to close Modal */
  onClose?: () => void;
  /** Allows to fire onClose on ESC */
  closeOnEsc?: boolean;
  /** Allows to fire onClose on click outside the modal */
  closeOnOutsideClick?: boolean;
  className?: string;
  /**
   * Removes top padding to allow custom content
   */
  noPadding?: boolean;
  /**
   * Removes the 1px border
   */
  noBorder?: boolean;
  /**
   * Adds more padding, mainly from the top
   */
  extraPadding?: boolean;
  /**
   * When width below 1024px, show modal in full screen
   */
  smallFullScreen?: boolean;
  /**
   * Makes the background transparent
   */
  noBackground?: boolean;
  /**
   * Changes the modal background to black to match the inverted colorscheme
   */
  inverted?: boolean;
  /**
   * Stroke width of the close icon.
   */
  closeIconWidth?: number;
  /**
   * Selector of the DOM node to receive initial focus, i.e. '.btnClass'.
   * If not present, the first focusable element inside the modal will be focused.
   */
  initialFocus?: string;

  testId?: string;
  children?: ReactNode;
}

/**
 * Modal uses React 16 portal to appear in the different DOM node.
 * If You provide it with node, it will portal inside, otherwise
 * it will mount itself inside the div at the end of the `<body>`
 *
 * If You provide custom node, the overlay will be disabled.
 */
export const Modal = ({
  onClose,
  closeOnEsc,
  closeOnOutsideClick,
  children,
  className,
  node,
  size,
  zIndex,
  noPadding = false,
  noBorder = false,
  extraPadding = false,
  smallFullScreen = false,
  noBackground = false,
  inverted = false,
  initialFocus,
  testId = '',
}: ModalProps) => {
  const modalRef = useRef<Element | undefined>();
  useEffect(() => {
    const onOutsideModalClick = (event: MouseEvent): void => {
      const isNonPrimaryButtonPressed: boolean = event.button !== undefined && event.button !== 0;
      if (eventTargetOrAncestorContainsClass(event, portalMenuIgnoreActivityClassName)) {
        return;
      }
      if (!modalRef.current || modalRef.current?.contains(event.target as HTMLElement) || isNonPrimaryButtonPressed) {
        return;
      }

      if (onClose) {
        onClose();
      }
    };
    const onKeydown = (event: KeyboardEvent): void => {
      if (event.keyCode === KeyCodes.Escape && onClose) {
        onClose();
      }
    };
    if (!onClose) {
      return () => {};
    }
    /**
     * using 0 timeout to ignore the clicks that opened this modal as recommended by react team
     * https://github.com/facebook/react/issues/24657#issuecomment-1150119055
     */
    const timeoutId = setTimeout(() => {
      if (closeOnEsc) {
        document.addEventListener('keydown', onKeydown);
      }
      if (closeOnOutsideClick) {
        document.addEventListener('click', onOutsideModalClick);
      }
    }, 0);
    return () => {
      clearTimeout(timeoutId);
      if (closeOnEsc) {
        document.removeEventListener('keydown', onKeydown);
      }
      if (closeOnOutsideClick) {
        document.removeEventListener('click', onOutsideModalClick);
      }
    };
  }, [onClose, closeOnEsc, closeOnOutsideClick]);

  return ReactDOM.createPortal(
    <ModalOverlay node={node} zIndex={zIndex} data-testid={testId} className={`${className} qa-modal-overlay`}>
      <FocusTrap focusTrapOptions={{ initialFocus, fallbackFocus: '#modal-wrapper', clickOutsideDeactivates: true }}>
        <ModalWrapper
          id="modal-wrapper"
          ref={(modal: HTMLElement) => {
            modalRef.current = modal;
          }}
          size={size}
          className={`${className} modal-wrapper`}
          noPadding={noPadding}
          extraPadding={extraPadding}
          smallFullScreen={smallFullScreen}
          noBackground={noBackground}
          noBorder={noBorder}
          inverted={inverted}
        >
          {children}
        </ModalWrapper>
      </FocusTrap>
    </ModalOverlay>,
    node || document.body,
  );
};

export default Modal;
