import React, { useContext } from 'react';
import styled from 'styled-components';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';
import { LeftForecastPanel } from './LeftForecastPanel';
import { RightForecastPanel } from './RightForecastPanel';

export interface ForecastPanelContentProps {
  onResidualForecastUpdated?: (fundId?: string) => void;
  residualForecastsSortFirst?: string[];
  isReadOnly: boolean;
}

const ForecastPanelContent = ({
  onResidualForecastUpdated,
  residualForecastsSortFirst,
  isReadOnly,
}: ForecastPanelContentProps) => {
  const { onSetForecastAsDefault, onDeleteForecast } = useContext(ForecastPanelActionsContext);
  return (
    <ForecastPanelWrapper>
      <LeftPanelWrapper>
        <LeftForecastPanel
          investmentForecastsSortFirst={residualForecastsSortFirst}
          onInvestmentForecastUpdated={onResidualForecastUpdated}
          onSetFactorForecastAsDefault={onSetForecastAsDefault}
          isReadOnly={isReadOnly}
          onDelete={onDeleteForecast}
        />
      </LeftPanelWrapper>
      <RightPanelWrapper>
        <RightForecastPanel />
      </RightPanelWrapper>
    </ForecastPanelWrapper>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: ForecastPanelContentProps) => <ForecastPanelContent {...props} />;

const ForecastPanelWrapper = styled.div`
  display: flex;
  padding-top: 10px;
`;

const RightPanelWrapper = styled.div`
  flex: 1;
  margin-top: 102px;
`;

const LeftPanelWrapper = styled.div`
  width: 400px;
`;
