import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnalysisSubject, logExceptionIntoSentry, useApi } from 'venn-utils';
import { ButtonIcon, GetColor, Icon, LegacyRelativePortal, Tooltip, TooltipPosition, ZIndex } from 'venn-ui-kit';
import { SearchMenuBar, SearchMenuColumn } from 'venn-components';
import { isNil } from 'lodash';
import { getSpecificPortfolioV3 } from 'venn-api';

interface BenchmarkEditorButtonProps {
  benchmarkSubject: AnalysisSubject | undefined;
  benchmarkSummaryError: boolean;
  iconSize: number;
  onChangeBenchmark: (benchmark: AnalysisSubject | undefined) => void;
}

const MAX_BENCHMARK_CHARS = 40;

const BenchmarkEditorButton = ({
  benchmarkSubject,
  benchmarkSummaryError,
  onChangeBenchmark,
  iconSize,
}: BenchmarkEditorButtonProps) => {
  const [showBenchmarkMenu, setShowBenchmarkMenu] = useState(false);

  const getPortfolioApiRef = useRef(useApi(getSpecificPortfolioV3));
  const onSelectBenchmark = async (benchmark: AnalysisSubject | undefined) => {
    const benchmarkPortfolioId = benchmark?.portfolio?.id;
    if (benchmark?.type !== 'portfolio' || isNil(benchmarkPortfolioId)) {
      onChangeBenchmark(benchmark);
      return;
    }
    try {
      const { content } = await getPortfolioApiRef.current(benchmarkPortfolioId, undefined);
      onChangeBenchmark(new AnalysisSubject(content, 'portfolio'));
    } catch (e) {
      if (e?.name !== 'AbortError') {
        onChangeBenchmark(undefined);
        logExceptionIntoSentry(e);
      }
    }
  };

  const benchmarkName = isNil(benchmarkSubject?.name)
    ? undefined
    : benchmarkSubject.name.length <= MAX_BENCHMARK_CHARS
      ? benchmarkSubject.name
      : `${benchmarkSubject.name.slice(0, MAX_BENCHMARK_CHARS - 2)}...`;

  return (
    <>
      <Tooltip
        content={
          isNil(benchmarkSubject) ? (
            'Add a benchmark'
          ) : (
            <TooltipContent>
              <div>{benchmarkSubject?.name}</div>
              {benchmarkSummaryError && (
                <div>
                  <ErrorIcon type="exclamation-triangle" /> Insufficient returns to include benchmark in Portfolio Lab
                  analyses
                </div>
              )}
              <div>
                <i>Click to edit benchmark</i>
              </div>
            </TooltipContent>
          )
        }
        position={TooltipPosition.Left}
      >
        <BenchmarkButtonIconTrigger
          iconType="arrows-h"
          className="qa-benchmark-link"
          size={iconSize}
          text={benchmarkName}
          onClick={() => setShowBenchmarkMenu(true)}
          destructive={benchmarkSummaryError}
        />
      </Tooltip>
      {showBenchmarkMenu && (
        <LegacyRelativePortal right={0} top={56}>
          <StyledSearchMenuBar
            onSelected={(selectedMenuItem) => {
              onSelectBenchmark(selectedMenuItem.value);
              setShowBenchmarkMenu(false);
            }}
            location="portfolioLabBenchmarkSelection"
            value={
              benchmarkSubject
                ? {
                    value: benchmarkSubject,
                    label: benchmarkSubject.name,
                    category: 'recent',
                  }
                : null
            }
            getOptionValue={(selectedMenuItem) => selectedMenuItem?.value?.id?.toString() ?? ''}
            onBlur={() => setShowBenchmarkMenu(false)}
            autofocus
            columns={[
              SearchMenuColumn.CCY,
              SearchMenuColumn.IDENTIFIER,
              SearchMenuColumn.TYPE,
              SearchMenuColumn.AVAILABLE_DATES,
              SearchMenuColumn.LAST_UPDATED,
            ]}
            onClear={() => onChangeBenchmark(undefined)}
            privateAssetSearchMode="PUBLIC_ONLY"
          />
        </LegacyRelativePortal>
      )}
    </>
  );
};

export default BenchmarkEditorButton;

const BenchmarkButtonIconTrigger = styled(ButtonIcon)`
  padding-left: 18px;
  padding-right: 18px;
`;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  position: absolute;
  right: 0;
  width: 850px;
  z-index: ${ZIndex.StickyCover};
`;

const TooltipContent = styled.div`
  width: 236px;
  padding: 5px;
  & > :not(:last-child) {
    margin-bottom: 5px;
  }
`;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Alert};
`;
