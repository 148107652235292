import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { addTagV3, createPortfolioV3, type Portfolio, type PortfolioPolicy, setPortfolioPolicy } from 'venn-api';
import type { Solution } from 'venn-components';
import { PortfolioLabContext, UserContext } from 'venn-components';
import styled from 'styled-components';
import {
  analyticsService,
  calculateComparison,
  getAnalysisPathForPortfolio,
  getRecentComparison,
  getTimestampStr,
  logExceptionIntoSentry,
  mapAllocationConstraintsToNewPortfolio,
  normalizePortfolio,
  parseConstraintsToPolicy,
  Routes,
  saveComparionsToLocal,
} from 'venn-utils';
import { GetColor, Notifications, NotificationType } from 'venn-ui-kit';
import { isNil } from 'lodash';

const useSelectedHeaderActions = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { portfolio, solutionPortfolio, selectedSolution, factorConstraints, allocationConstraints } =
    useContext(PortfolioLabContext);
  const { currentContext } = useContext(UserContext);

  const tagWithPortfolioLab = useCallback(async (portfolioId: number) => {
    try {
      await addTagV3({
        tag: 'Portfolio Lab',
        entities: { portfolioIds: [portfolioId] },
      });
    } catch (e) {
      logExceptionIntoSentry(e);
    }
  }, []);

  const [savedPortfolioId, setSavedPortfolioId] = useState<number | undefined>(solutionPortfolio?.portfolio?.id);
  useEffect(() => {
    setSavedPortfolioId(solutionPortfolio?.portfolio?.id);
  }, [solutionPortfolio]);

  const getPortfolioId = useCallback(async () => {
    if (!solutionPortfolio || !solutionPortfolio.portfolio) {
      return undefined;
    }

    if (solutionPortfolio.portfolio.id) {
      return solutionPortfolio.portfolio.id;
    }

    if (savedPortfolioId) {
      return savedPortfolioId;
    }

    // AutoSave portfolio and attach policy
    setLoading(true);

    try {
      const portfolioCategory =
        selectedSolution?.category === 'Alternate' && !isNil(selectedSolution.alternateSolutionIdx)
          ? `Near-Optimal [#${selectedSolution.alternateSolutionIdx + 1}]`
          : 'Optimized';
      const portfolioToSave = {
        ...solutionPortfolio.portfolio,
        draft: false,
        demo: false,
        ownerContextId: currentContext,
        name: `${portfolioCategory} ${solutionPortfolio.portfolio.name} ${getTimestampStr()}`,
      };

      const savedPortfolio = (await createPortfolioV3(portfolioToSave)).content;
      const savedPortfolioAllocationConstraints = mapAllocationConstraintsToNewPortfolio(
        solutionPortfolio.portfolio,
        savedPortfolio,
        allocationConstraints,
      );
      const portfolioPolicy = parseConstraintsToPolicy(
        savedPortfolioAllocationConstraints,
        factorConstraints,
        undefined,
        savedPortfolio,
      );
      setPortfolioPolicy(mapPortfolioPolicyToUpdatedNodes(portfolioPolicy, portfolio, savedPortfolio));
      setSavedPortfolioId(savedPortfolio.id);
      tagWithPortfolioLab(savedPortfolio.id);
      setLoading(false);
      return savedPortfolio.id;
    } catch (e) {
      if (e.name === 'AbortError') {
        return undefined;
      }
      Notifications.notify(e.message, NotificationType.ERROR);
    }
    setLoading(false);
    return undefined;
  }, [
    solutionPortfolio,
    savedPortfolioId,
    selectedSolution?.category,
    selectedSolution?.alternateSolutionIdx,
    currentContext,
    allocationConstraints,
    factorConstraints,
    portfolio,
    tagWithPortfolioLab,
  ]);

  const onAnalyze = useCallback(async () => {
    const portfolioId = await getPortfolioId();

    if (portfolioId) {
      history.push(getAnalysisPathForPortfolio(portfolioId));
      analyticsService.portfolioLabSolutionSaved({
        action: 'Analyze',
        basePortfolioId: portfolio?.id,
        optimizedPortfolioId: portfolioId,
        isOptimal: selectedSolution?.category === 'Optimized',
      });
    }
  }, [getPortfolioId, history, portfolio, selectedSolution]);

  const onAddToCompare = useCallback(async () => {
    const portfolioId = await getPortfolioId();
    if (!portfolioId) {
      return;
    }
    const localComparison = getRecentComparison();
    saveComparionsToLocal({ ...localComparison, subjects: [...(localComparison.subjects ?? []), portfolioId] });
    Notifications.notify(<AddToCompareMessage solution={selectedSolution} />);
    analyticsService.portfolioLabSolutionSaved({
      action: 'Add to Compare',
      basePortfolioId: portfolio?.id,
      optimizedPortfolioId: portfolioId,
      isOptimal: selectedSolution?.category === 'Optimized',
    });
  }, [getPortfolioId, selectedSolution, portfolio]);

  const onSaveAndTag = useCallback(
    (createdPortfolio: Portfolio) => {
      if (!solutionPortfolio || !solutionPortfolio.portfolio) {
        return;
      }

      const createdPortfolioAllocationConstraints = mapAllocationConstraintsToNewPortfolio(
        solutionPortfolio.portfolio,
        createdPortfolio,
        allocationConstraints,
      );
      const portfolioPolicy = parseConstraintsToPolicy(
        createdPortfolioAllocationConstraints,
        factorConstraints,
        undefined,
        createdPortfolio,
      );
      setPortfolioPolicy(mapPortfolioPolicyToUpdatedNodes(portfolioPolicy, portfolio, createdPortfolio));
      tagWithPortfolioLab(createdPortfolio.id);
      setSavedPortfolioId(createdPortfolio.id);
      Notifications.notify(`${createdPortfolio.name} was saved and can be found under the tag 'Portfolio Lab'`);
      analyticsService.portfolioLabSolutionSaved({
        action: 'Save and Tag',
        basePortfolioId: portfolio?.id,
        optimizedPortfolioId: createdPortfolio.id,
        isOptimal: selectedSolution?.category === 'Optimized',
      });
    },
    [
      solutionPortfolio,
      allocationConstraints,
      factorConstraints,
      portfolio,
      tagWithPortfolioLab,
      selectedSolution?.category,
    ],
  );

  return {
    loading,
    onAnalyze,
    onAddToCompare,
    onSaveAndTag,
    savedPortfolioId,
  };
};

export default useSelectedHeaderActions;

const AddToCompareMessage = ({ solution }: { solution: Solution | undefined }) => (
  <Message>
    {solution?.category === 'Alternate' && !isNil(solution.alternateSolutionIdx)
      ? `Near-Optimal Portfolio [#${solution.alternateSolutionIdx + 1}]`
      : 'Optimized Portfolio'}{' '}
    successfully added to compare. <Link to={Routes.ANALYSIS_COMPARE_PATH}>Go to compare</Link>.
  </Message>
);

const Message = styled.div`
  a {
    color: ${GetColor.White};
    font-weight: bold;
  }
`;

export const mapPortfolioPolicyToUpdatedNodes = (
  policy: PortfolioPolicy,
  base: Portfolio | undefined,
  updated: Portfolio,
): PortfolioPolicy => {
  if (isNil(base)) {
    return policy;
  }
  const [nodeIdMap] = calculateComparison(normalizePortfolio(base), normalizePortfolio(updated));

  return {
    ...policy,
    portfolioId: updated.id,
    constraints: policy.constraints.map((constraint) => ({
      ...constraint,
      targets: constraint.targets.map((target) => ({
        ...target,
        strategyId: isNil(target.strategyId)
          ? target.strategyId
          : (nodeIdMap.get(target.strategyId)?.id ?? target.strategyId),
      })),
    })),
  };
};
