import { useEffect, useRef, useState } from 'react';
import type { AnalysisRequest, Portfolio } from 'venn-api';
import { analysis } from 'venn-api';
import { getDateFormatForDisplay, logExceptionIntoSentry, useApi } from 'venn-utils';
import { compact, isEqual, isNil } from 'lodash';
import moment from 'moment';

export type AnalysisPeriodParams = Pick<AnalysisRequest, 'period' | 'start' | 'end'>;

interface UseAvailablePeriodForForecastValue {
  analysisPeriodParams: Partial<AnalysisPeriodParams>;
  loadingAnalysisPeriod: boolean;
}

const DEFAULT_PERIOD: Partial<AnalysisPeriodParams> = {
  start: undefined,
  end: undefined,
  period: 'YEAR_3',
};

const useAvailablePeriodForForecast = (
  portfolio: Portfolio | undefined,
  solution: Portfolio | undefined,
  benchmark: Portfolio | undefined,
  noOptimizationResult: boolean,
): UseAvailablePeriodForForecastValue => {
  const [analysisPeriodParams, setAnalysisPeriodParams] = useState<Partial<AnalysisPeriodParams>>(DEFAULT_PERIOD);
  const [loading, setLoading] = useState(false);
  const analysisApiRef = useRef(useApi(analysis));
  const lastFulfilledRequestRef = useRef<Partial<AnalysisRequest>>({});

  useEffect(() => {
    const verify3YearsAvailable = async () => {
      setLoading(true);
      try {
        const request = getRequest(portfolio!, solution!, benchmark);
        const { content } = await analysisApiRef.current(request);
        const rangeDebug = content.analyses?.[0]?.rangeDebug?.[0];
        const { maxStartTime, maxEndTime } = content;
        setAnalysisPeriodParams(
          rangeDebug.insufficientRange && !isNil(maxStartTime) && !isNil(maxEndTime)
            ? {
                start: maxStartTime,
                end: maxEndTime,
              }
            : {
                start: content.startTime,
                end: content.endTime,
                period: 'YEAR_3',
              },
        );
        lastFulfilledRequestRef.current = request;
        setLoading(false);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          setLoading(false);
          logExceptionIntoSentry(e);
        }
      }
    };
    if (isNil(portfolio) || isNil(solution) || noOptimizationResult) {
      setAnalysisPeriodParams(DEFAULT_PERIOD);
    } else {
      verify3YearsAvailable();
    }
  }, [portfolio, solution, benchmark, noOptimizationResult]);

  const isWaitingForRange =
    !isNil(portfolio) &&
    !isNil(solution) &&
    !noOptimizationResult &&
    !isEqual(lastFulfilledRequestRef.current, getRequest(portfolio, solution, benchmark));

  return {
    analysisPeriodParams,
    loadingAnalysisPeriod: loading || isWaitingForRange,
  };
};

export default useAvailablePeriodForForecast;

const formatDate = (date: number) => moment.utc(date).format(getDateFormatForDisplay('DAILY'));

export const formatRequestPeriod = (requestPeriodParams: Partial<AnalysisPeriodParams>) => {
  if (isNil(requestPeriodParams.start) || isNil(requestPeriodParams.end)) {
    return '--';
  }

  return `${formatDate(requestPeriodParams.start)} - ${formatDate(requestPeriodParams.end)}`;
};

const getRequest = (
  portfolio: Portfolio,
  solution: Portfolio,
  benchmark: Portfolio | undefined,
): Partial<AnalysisRequest> => ({
  analyses: [{ analysisType: 'RANGE_DEBUG' as const, relative: false, scenarios: [] }],
  period: 'YEAR_3' as const,
  subjects: compact([
    {
      comparisonType: 'COMPARISON' as const,
      id: portfolio.id.toString(),
      primary: false,
      subjectType: 'PORTFOLIO' as const,
      isPrivate: false,
    },
    {
      comparisonType: 'PRIMARY' as const,
      portfolio: solution,
      primary: true,
      subjectType: 'PORTFOLIO' as const,
      id: undefined!,
      isPrivate: false,
    },
    isNil(benchmark)
      ? null
      : {
          comparisonType: 'BENCHMARK' as const,
          portfolio: benchmark,
          primary: false,
          subjectType: 'PORTFOLIO' as const,
          id: undefined!,
          isPrivate: false,
        },
  ]),
});
