import React, { useImperativeHandle, useRef } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { SpecialCssClasses, assertNotNil } from 'venn-utils';
import { type BlockId, blockSettings, hideBlockBorders, isSelectedBlockState } from 'venn-state';
import { OverflowWrapper } from './OverflowWrapper';
import { useRecoilValue } from 'recoil';
import { BLOCK_BORDER_WIDTH_PX } from '../../../../studio-blocks';

const ACTIVE_BLOCK_CLASS = 'active-block';

const defaultBuilderBlockWrapperBorderColor = GetColor.Grey;

interface BuilderBlockWrapperProps {
  children: React.ReactNode;
  'data-testid'?: string;
  id: BlockId;
  pageFooterRef?: React.RefObject<HTMLElement>;
}

export const BuilderBlockWrapper = React.forwardRef<HTMLDivElement, BuilderBlockWrapperProps>(
  function BuilderBlockWrapper(props: BuilderBlockWrapperProps, forwardedRef) {
    const { children, id, pageFooterRef } = props;

    const active = useRecoilValue(isSelectedBlockState(id));
    const settings = useRecoilValue(blockSettings(id));
    const hideBorder = useRecoilValue(hideBlockBorders(id));

    // Share a ref between forwardedRef and our internal Ref using useImperativeHandle, since otherwise we can't apply two refs
    // to the same node.
    const internalRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => assertNotNil(internalRef.current));

    const className = active ? ACTIVE_BLOCK_CLASS : '';
    const transparent = settings.customBlockType === 'PAGE_BREAK' || hideBorder;

    return (
      <OverflowWrapper id={id} internalRef={internalRef} pageFooterRef={pageFooterRef}>
        <BorderWrapper
          data-testid={props['data-testid']}
          className={className}
          ref={internalRef}
          active={active}
          transparent={transparent}
        >
          <HiddenBorder active={active} transparent={transparent}>
            {children}
          </HiddenBorder>
        </BorderWrapper>
      </OverflowWrapper>
    );
  },
);

const HiddenBorder = styled.div<{ active: boolean; transparent: boolean }>`
  border: ${({ active, transparent }) => (active ? 0 : transparent ? '2px' : '1px')} solid transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BorderWrapper = styled.div<{
  active: boolean;
  transparent: boolean;
}>`
  border-style: solid;
  border-width: ${({ active, transparent }) => (active ? `${BLOCK_BORDER_WIDTH_PX}px` : transparent ? 0 : '1px')};
  border-color: ${({ active }) => (active ? GetColor.Primary.Main : defaultBuilderBlockWrapperBorderColor)};
  background-color: ${({ transparent }) => (transparent ? 'transparent' : GetColor.White)};
  width: 100%;
  height: 100%;
  @media print {
    page-break-inside: avoid;
  }

  .${SpecialCssClasses.ExportAsImage} & {
    border-color: ${defaultBuilderBlockWrapperBorderColor};
  }

  ${({ active }) =>
    !active &&
    css`
      cursor: pointer;
      :hover {
        border: 2px solid ${GetColor.Grey};
        > div {
          border: 0;
        }
      }
    `}
`;
