import type { ErrorInfo } from 'react';
import React from 'react';
import { Loading } from 'authentication-page';
import { callIfFunctionalCookiesAllowed, CookieBanner, ExportLoader, CustomThemeCustomizer } from 'venn-components';
import { VennToastContainer } from 'venn-ui-kit';
import {
  AnalyticsUtils,
  initDynamicPageTitles,
  initializeAnalytics,
  initializeDatadogRUM,
  initializeUserActivity,
  logExceptionIntoSentry,
  setFavicons,
  useInitializeFeatures,
  VennEvents,
} from 'venn-utils';
import ShellRouter from './ShellRouter';
import Contexts from './Contexts';
import AuthComponent from './Auth';
import MobileWarning from './shell-navigation/components/MobileWarning';
import type { ReactCookieProps } from 'react-cookie';
import { withCookies } from 'react-cookie';

import '@fortawesome/fontawesome-pro/css/all.css';
import './fonts.css';
import { LazyVennDebugButton } from '../LazyVennDebugButton';
import { LazyRecoilizeDebugger } from '../LazyRecoilizeDebugger';
import { history } from './history';

interface ShellState {
  isAppExpired: boolean;
  loading: boolean;
}

/**
 * A functional component that wraps the `Shell` component to enable usage of hooks
 */
const FunctionalShellWrapper = (props: React.PropsWithoutRef<ShellPropsOnly>) => {
  const initializeFeatures = useInitializeFeatures();
  return <Shell {...props} initializeFeatures={initializeFeatures} />;
};

type PropsProducedByFunctionalWrapper = { initializeFeatures: ReturnType<typeof useInitializeFeatures> };
type ShellPropsOnly = ReactCookieProps;
type CombinedShellProps = ShellPropsOnly & PropsProducedByFunctionalWrapper;

class Shell extends React.Component<CombinedShellProps, ShellState> {
  private getNewShaInterval: number;

  constructor(props: CombinedShellProps) {
    super(props);
    this.state = {
      isAppExpired: false,
      loading: true,
    };
  }

  async componentDidMount() {
    // Make sure we add event listeners as soon as possible and initialize Datadog
    callIfFunctionalCookiesAllowed(this.props.allCookies ?? {}, initializeDatadogRUM);

    await this.props.initializeFeatures();
    initializeUserActivity();
    initDynamicPageTitles();
    setFavicons();
    initializeAnalytics();
    this.initIntervalVersionFetch();
    AnalyticsUtils.page(window.location.pathname);
    this.setState({ loading: false });
  }

  // Creates an application-level error boundary for generic error handling
  // of errors occurring during rendering that might otherwise get swallowed
  // by the framework
  componentDidCatch(error: Error, info: ErrorInfo) {
    logExceptionIntoSentry(error, info);
  }

  componentWillUnmount() {
    this.clearIntervalVersionFetch();
    document.removeEventListener(VennEvents.functionalCookiesAccepted, initializeDatadogRUM);
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : (
          <>
            <LazyRecoilizeDebugger />
            <AuthComponent isAppExpired={this.state.isAppExpired}>
              <Contexts>
                <MobileWarning />
                <ShellRouter isAppExpired={this.state.isAppExpired} />
                <ExportLoader />
                <LazyVennDebugButton />
                <CustomThemeCustomizer />
              </Contexts>
            </AuthComponent>
            <VennToastContainer />
          </>
        )}
        <CookieBanner history={history} />
      </>
    );
  }

  private getNewSha = async () => {
    const currentVersionSha = process.env.COMMIT_SHA;
    const timeStamp = Date.now();
    const prefix = process.env.PUBLIC_URL;
    let url = `/venn-version.json?time=${timeStamp}`;
    if (prefix) {
      url = prefix + url;
    }

    await fetch(url, { cache: 'no-cache' })
      .then((response) => response.text())
      .then((data) => {
        const updateSha = data.trim();
        if (/[0-9a-f]{40}/.test(updateSha)) {
          if (updateSha !== currentVersionSha) {
            this.setState({
              isAppExpired: true,
            });
          }
        }
      })
      .catch(() => {}); // no-op
  };

  private initIntervalVersionFetch = () => {
    if (!process.env.COMMIT_SHA || this.state.isAppExpired) {
      return;
    }
    // get the new sha every 5 mins.
    this.getNewShaInterval = window.setInterval(this.getNewSha, 1000 * 60 * 5);
  };

  private clearIntervalVersionFetch = () => {
    if (this.getNewShaInterval) {
      clearInterval(this.getNewShaInterval);
    }
  };
}

export default withCookies(FunctionalShellWrapper);
