import { isNil } from 'lodash';
import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { ComputedInvestmentResidual } from 'venn-api';
import { BodyHighlight, GetColor, Hint } from 'venn-ui-kit';
import { Numbers } from 'venn-utils';

interface InvestmentOverridesContainerFooterProps {
  investmentData: InvestmentData[];
}

type InvestmentData = {
  datum?: number;
  copy: string;
  shouldHighlight: boolean;
};

export const makeDefaultInvestmentDataFromForecast = (
  investmentOverride: ComputedInvestmentResidual,
): InvestmentData[] => {
  const isResidualOverride = !isNil(investmentOverride.overriddenResidual);

  return isResidualOverride
    ? [
        { datum: investmentOverride.computedReturn, copy: 'Derived Total Return', shouldHighlight: false },
        { datum: investmentOverride.defaultReturn, copy: 'Unmodified Total Return', shouldHighlight: false },
        { datum: investmentOverride.overriddenResidual, copy: 'Overridden Residual', shouldHighlight: true },
        { datum: investmentOverride.defaultResidual, copy: 'Unmodified Residual', shouldHighlight: false },
      ]
    : [
        { datum: investmentOverride.computedReturn, copy: 'Overridden Total Return', shouldHighlight: true },
        { datum: investmentOverride.defaultReturn, copy: 'Unmodified Total Return', shouldHighlight: false },
        { datum: investmentOverride.computedResidual, copy: 'Implied Residual', shouldHighlight: false },
        { datum: investmentOverride.defaultResidual, copy: 'Unmodified Residual', shouldHighlight: false },
      ];
};

const InvestmentOverridesContainerFooter = ({
  investmentData,
  children,
}: PropsWithChildren<InvestmentOverridesContainerFooterProps>) => {
  const investmentFooterRowData = ({ datum, copy, shouldHighlight }: InvestmentData) => {
    return (
      <InvestmentFooterData key={copy}>
        <Hint className="qa-forecast-list-item-footer">
          <InvestmentCopyAndData>
            <div>{copy}</div>
            <InvestmentFooterDataRight>
              {shouldHighlight ? (
                <StyledBodyHighlight>
                  {isNil(datum) ? '--' : Numbers.safeFormatPercentage(datum, 1)}
                </StyledBodyHighlight>
              ) : isNil(datum) ? (
                '--'
              ) : (
                Numbers.safeFormatPercentage(datum, 1)
              )}
            </InvestmentFooterDataRight>
          </InvestmentCopyAndData>
        </Hint>
      </InvestmentFooterData>
    );
  };
  return (
    <InvestmentFooter>
      <InvestmentDataContainer>{investmentData.map((data) => investmentFooterRowData(data))}</InvestmentDataContainer>
      {children}
    </InvestmentFooter>
  );
};

export default React.memo(InvestmentOverridesContainerFooter);

const InvestmentFooter = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const InvestmentDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-left: -10px;
  margin-bottom: 5px;
  line-height: 18px;
`;

const InvestmentFooterData = styled.div`
  margin-right: -1px;
  border-right: 1px solid ${GetColor.Grey};
  flex: 1 1 40%;
  padding-left: 10px;
`;

const InvestmentCopyAndData = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InvestmentFooterDataRight = styled.span`
  margin-right: 5px;
`;

export const StyledBodyHighlight = styled(BodyHighlight)`
  font-weight: bold;
  color: ${GetColor.HighlightDark};
`;
