import React, { useMemo } from 'react';
import styled, { withTheme } from 'styled-components';
import { createBoxChartConfig } from '../../../charts/analysis-charts/chart-config-logic';
import { getCurrencySymbol } from './chartsUtils';
import { compact } from 'lodash';
import type { BoxChartProps } from './types';
import Legend from './img/Legend.png';
import { ZIndex } from 'venn-ui-kit';
import type { SeriesBoxplotOptions } from 'highcharts';
import { useRecoilValue } from 'recoil';
import { blockFonts } from 'venn-state';
import { useBlockId } from '../../contexts/BlockIdContext';
import { StudioHighchart } from '../../../highchart/Highchart';

const BoxChart = ({
  start,
  end,
  theme,
  series,
  frequency,
  inPrintMode,
  hasDrawdownLines,
  height,
  currency,
  percentiles,
  useLogarithmicScale,
}: BoxChartProps) => {
  const blockId = useBlockId();
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const currencySymbol = currency ? getCurrencySymbol(currency) : '';

  const options: Highcharts.Options = useMemo(() => {
    const seriesValues = compact(
      series.map((serie) => {
        if (!serie.data || serie.data.length === 0 || !serie.color) {
          return undefined;
        }

        return {
          data: serie.data as SeriesBoxplotOptions['data'],
          name: serie.name,
          type: 'boxplot' as const,
          legendLabel: serie.name,
          color: serie.color,
          maxPointWidth: 80,
        };
      }),
    );

    const config = createBoxChartConfig({
      start,
      end,
      theme,
      categories: seriesValues.map((series) => series.name),
      frequency,
      print: inPrintMode,
      hasDrawdownLines,
      currencySymbol,
      percentiles,
      colors: theme.Colors,
      useLogarithmicScale,
      axisFontSize: axisFont.fontSizePt,
    });
    return {
      ...config,
      chart: {
        ...config.chart,
        height,
      },
      series: seriesValues,
      legend: {
        enabled: true,
      },
    };
  }, [
    series,
    start,
    end,
    theme,
    frequency,
    inPrintMode,
    hasDrawdownLines,
    height,
    percentiles,
    currencySymbol,
    useLogarithmicScale,
    axisFont.fontSizePt,
  ]);

  return (
    <>
      <LegendWrapper src={Legend} />
      <StudioHighchart options={options} blockId={blockId} style={{ height: '100%' }} />
    </>
  );
};

export default withTheme(BoxChart);

const LegendWrapper = styled.img`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: ${ZIndex.Front};
`;
