import React from 'react';
import type { IHeaderParams } from 'ag-grid-community';
import styled from 'styled-components';
import { CellWrapper } from './shared';
import { GetColor } from 'venn-ui-kit';

const ScenarioHeaderGroupCellRenderer = (props: IHeaderParams) => {
  return <StyledCellWrapper>{props.displayName}</StyledCellWrapper>;
};

export default ScenarioHeaderGroupCellRenderer;

const StyledCellWrapper = styled(CellWrapper)`
  width: 100%;
  height: 18px;
  text-align: center;
  justify-content: center;
  color: ${GetColor.Black};
  border-top: 2px solid ${GetColor.Black};
  margin: 0px;
  padding: 2px 0px 0px 0px;
  border-bottom: none;
`;
