import React, { Component } from 'react';
import { Tooltip } from '../tooltip/Tooltip';
import { Ellipsis } from '../ellipsis/Ellipsis';
import type { TooltipPosition, TooltipBodyDirection } from '../enums';
import { isNil } from 'lodash';

export interface EllipsisTooltipSpanProps extends Omit<React.BaseHTMLAttributes<HTMLSpanElement>, 'content'> {
  isHidden?: boolean;
  content?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  block?: boolean;
  maxWidth?: string | number;
  position?: TooltipPosition;
  bodyDirection?: TooltipBodyDirection;
  hideArrow?: boolean;
  alwaysShowTooltip?: boolean;
  tooltipMaxWidth?: number;
  usePortal?: boolean;
  flex?: boolean;
  zIndex?: number;
}

export interface EllipsisTooltipSpanState {
  isTooltipVisible: boolean;
}

export class EllipsisTooltipSpan extends Component<EllipsisTooltipSpanProps, EllipsisTooltipSpanState> {
  contentRef = React.createRef<HTMLElement>();

  constructor(props: EllipsisTooltipSpanProps) {
    super(props);

    this.state = {
      isTooltipVisible: !!props.alwaysShowTooltip,
    };
  }

  onOverflowChange = (isOverflowing: boolean) => {
    this.setState({
      isTooltipVisible: isOverflowing,
    });
  };

  onMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
    const { onMouseEnter } = this.props;
    onMouseEnter?.(e);
  };

  onMouseLeave = (e: React.MouseEvent<HTMLSpanElement>) => {
    const { onMouseLeave } = this.props;
    onMouseLeave?.(e);
  };

  render() {
    const {
      children,
      content = children,
      className,
      maxWidth,
      block,
      position,
      bodyDirection,
      hideArrow,
      isHidden = false,
      alwaysShowTooltip,
      tooltipMaxWidth,
      usePortal,
      flex,
      zIndex,
      ...restProps
    } = this.props;
    const { isTooltipVisible } = this.state;

    const flexPortalProps =
      flex && usePortal
        ? {
            portalPosition: { top: this.contentRef.current?.getBoundingClientRect().height },
            portalRelativeElement: this.contentRef.current ?? undefined,
          }
        : {};

    return (
      <Tooltip
        usePortal={usePortal}
        content={content}
        isHidden={isHidden || !isTooltipVisible}
        className={className}
        block={block}
        style={{ maxWidth }}
        position={position}
        bodyDirection={bodyDirection}
        hideArrow={hideArrow}
        flex={flex}
        maxWidth={
          !isNil(tooltipMaxWidth) ? tooltipMaxWidth : maxWidth != null ? parseInt(`${maxWidth}`, 10) : undefined
        }
        zIndex={zIndex}
        {...flexPortalProps}
      >
        <Ellipsis singleLine {...restProps} onOverflowChange={this.onOverflowChange} containerRef={this.contentRef}>
          {(overflowing) =>
            overflowing || alwaysShowTooltip ? (
              <span onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} className="ellipsis-overflowing">
                {children}
              </span>
            ) : (
              children
            )
          }
        </Ellipsis>
      </Tooltip>
    );
  }
}
export default EllipsisTooltipSpan;
