import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { assertNotNil, STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE, useHasFF } from 'venn-utils';
import Copyright from '../copyright';
import Markdown from '../markdown/Markdown';
import { AGREEMENTS_FOR_PRINT, THIRD_PARTY_NOTICES_FOR_PRINT } from './Messages';
import { StudioPrintSettingsContext } from '../contexts/studio-print-settings-context';

// Note that we use relative units, em, rather than absolute units like px or pt for all styling except for the root element itself.
// This ensures that the UI is reactive and doesn't look strange at large or small font sizes.

const StyledPrintCopy = styled('div')<{ fontSizePt?: number }>`
  color: #666666;
  font-size: ${({ fontSizePt }) => fontSizePt}pt;
  padding: 20px;
  display: none;

  @media print {
    overflow: visible;
    display: block;
    page-break-before: always;
    page-break-inside: avoid;
    line-height: 1.5;

    img {
      padding: 0 1em;
      display: inline-block;
      max-height: 1.2em;
    }

    p {
      margin: 0.8em 0;
    }
  }
`;

const StyledTitle = styled('div')`
  font-weight: 900;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
`;

const StyledNotice = styled('div')`
  margin: 1em 0;
`;

const PrintCopy = ({ organizationName }: { organizationName?: string }) => {
  const { Images } = useContext(ThemeContext);

  const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  const finalDisclosureFontSizePt =
    organizationPrintSettings?.disclosureFontSize ?? STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE;

  const hasSwissDisclosureFF = useHasFF('swiss_disclosure_ff');
  const hasPrivatesFF = useHasFF('privates_reveal_ff');
  return (
    <StyledPrintCopy fontSizePt={finalDisclosureFontSizePt}>
      <StyledTitle>CONFIDENTIAL</StyledTitle>
      <StyledTitle>Important Disclosure and Disclaimer Information</StyledTitle>
      <StyledNotice>
        <Markdown children={AGREEMENTS_FOR_PRINT(organizationName, hasSwissDisclosureFF)} />
      </StyledNotice>
      <Copyright />
      <StyledNotice>
        <Markdown children={THIRD_PARTY_NOTICES_FOR_PRINT(assertNotNil(Images.six), hasPrivatesFF)} />
      </StyledNotice>
    </StyledPrintCopy>
  );
};

export default PrintCopy;
