import React, { PureComponent, type ReactNode } from 'react';
import { noop, includes } from 'lodash';
import Option from './Option';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import { ColorUtils } from '../../../style/colorUtils';
import KeyCodes from '../../../KeyCode';
import Icon from '../../icon/Icon';
import { ZIndex } from '../../../zIndexValues';

const SHADOW_COLOR = ColorUtils.hex2rgbaFrom(GetColor.Black, 0.2);

export interface OptionType<T = unknown> {
  value?: T;
  separator?: boolean;
  children?: React.ReactNode;
  header?: JSX.Element[] | JSX.Element | string;
  className?: string;
  disabled?: boolean;
}

export interface OptionsProps<V> {
  children?: ReactNode;
  searchWords?: string;
  itemClassName?: string;
  options?: OptionType<V>[];
  error?: JSX.Element;
  showNone?: boolean;
  isLoading?: boolean;
  onSelect?: (selectedOption: OptionType<V>) => void;
}

interface OptionsState {
  hoveredOptionIndex: number;
}

const HANDLED_KEY_CODES = [KeyCodes.Enter, KeyCodes.ArrowUp, KeyCodes.ArrowDown];

class Options<V> extends PureComponent<OptionsProps<V>, OptionsState> {
  state = {
    hoveredOptionIndex: -1,
  };

  onHover = (hoveredOptionIndex: number) => {
    this.setState({ hoveredOptionIndex });
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps: OptionsProps<V>) {
    if (prevProps.searchWords !== this.props.searchWords) {
      this.setState({ hoveredOptionIndex: -1 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    const {
      error,
      options = [],
      searchWords,
      itemClassName = '',
      showNone = false,
      onSelect = noop,
      isLoading,
    } = this.props;

    if (error) {
      return (
        <StyledOptions>
          <StyledError>
            <Icon type="warning" />
            {error}
          </StyledError>
        </StyledOptions>
      );
    }

    const { hoveredOptionIndex } = this.state;
    return (
      <StyledOptions showNone={showNone && !options.length} isLoading={isLoading}>
        {options.map((option, index) => {
          const optionClassName = option.className ? `${itemClassName} ${option.className}` : itemClassName;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {option.header}
              <Option
                data-testid="menu-option"
                index={index}
                isHovered={index === hoveredOptionIndex}
                onHover={this.onHover}
                className={optionClassName}
                onMouseDown={onSelect}
                searchWords={searchWords}
                value={option}
                separator={option.separator}
                disabled={option.disabled}
              >
                {option.children}
              </Option>
            </React.Fragment>
          );
        })}
      </StyledOptions>
    );
  }

  private onKeyDown = (event: KeyboardEvent) => {
    const { keyCode } = event;
    if (!includes(HANDLED_KEY_CODES, keyCode)) {
      return;
    }

    event.preventDefault();
    const { options } = this.props;
    const lastOptionIndex = options ? options.length - 1 : 0;

    if (keyCode === KeyCodes.Enter) {
      this.confirmSelection(options);
    }

    if (keyCode === KeyCodes.ArrowUp) {
      this.moveSelectionUp(lastOptionIndex);
    }

    if (keyCode === KeyCodes.ArrowDown) {
      this.moveSelectionDown(lastOptionIndex);
    }
  };

  private moveSelectionUp = (lastOptionIndex: number) =>
    this.setState(({ hoveredOptionIndex }) => ({
      hoveredOptionIndex: hoveredOptionIndex - 1 >= 0 ? hoveredOptionIndex - 1 : lastOptionIndex,
    }));

  private moveSelectionDown = (lastOptionIndex: number) =>
    this.setState(({ hoveredOptionIndex }) => ({
      hoveredOptionIndex: hoveredOptionIndex + 1 <= lastOptionIndex ? hoveredOptionIndex + 1 : 0,
    }));

  private confirmSelection = (options: OptionsProps<V>['options']) => {
    const { onSelect } = this.props;
    const selectedOption = options?.[this.state.hoveredOptionIndex];

    if (selectedOption) {
      onSelect?.(selectedOption);
    }
  };
}

const StyledError = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  color: ${GetColor.MidGrey2};
  font-size: 12px;
  font-weight: bold;
  pointer-events: none;
`;

const StyledOptions = styled.div<Partial<OptionsProps<unknown>>>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  overflow-y: auto;
  box-shadow: 0 6px 6px 0 ${SHADOW_COLOR};
  &:empty {
    padding: 0;
  }

  // Styles below are for Search Menu
  section + &:not(:empty) {
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid ${GetColor.PaleGrey};
    border-top-width: 0;
    background-color: ${GetColor.White};
    z-index: ${ZIndex.Front};
  }

  ${(props: { showNone?: boolean; isLoading?: boolean }) =>
    props.showNone &&
    css`
      section + &:empty {
        position: absolute;
        top: 100%;
        width: 100%;
        border: 1px solid ${GetColor.UNSAFE.Separator};
        border-top-width: 0;
        background-color: ${GetColor.White};
        z-index: ${ZIndex.Front};
        height: 37px;

        &:before {
          content: '${props.isLoading ? 'Loading...' : 'No results found'}';
          padding: 10px 10px 8px;
          color: ${GetColor.Grey};
        }
      }
    `};
`;

export default Options;
