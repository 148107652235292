import type { FC, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonText,
  ColorUtils,
  GetColor,
  Headline2,
  Icon,
  Link as LinkStyle,
  TOP_NAV_HEIGHT,
  ZIndex,
} from 'venn-ui-kit';
import BackToChapter from './BackToChapter';
import { isNil, last } from 'lodash';

export interface TabConfig {
  stepNumber: string;
  title: string | ReactNode;
  displayBackground?: boolean;
  fundId?: string;
  hideWelcomeHeader?: boolean;
  customWelcomeHeaderLabel?: string;
  Component: React.ComponentType<React.PropsWithChildren<{ fundId?: string }>>;
}

export interface SelectedTabConfig {
  selectedTab: TabConfig;
  selectedTabIdx: number;
  chapterIdx: number;
}

interface ChapterConfig {
  tabs: TabConfig[];
  defaultSelectedTabIdx: number;
  chapterName: string;
}

interface HomePageTabsProps {
  defaultSelectedTab: SelectedTabConfig;
  chapterConfig: ChapterConfig[];
  onTabChange: (tab: SelectedTabConfig) => void;
  onHasSkipped?: () => void;
}

const TAB_BUTTON = 'sliding-tabs';
const TABS_OFFSET = 'tabs-container';
interface HighlightConfig {
  left: number;
  width: number;
}
const getHighlightConfig = (tabKey: number): HighlightConfig | undefined => {
  const allTabNodes = document.getElementsByClassName(TAB_BUTTON);
  const offsetLeft = document.getElementById(TABS_OFFSET)?.getBoundingClientRect().left ?? 0;

  if (tabKey >= 0 && tabKey < allTabNodes.length) {
    const { left, width } = allTabNodes[tabKey].getBoundingClientRect();
    return { left: left - offsetLeft, width };
  }

  return { left: offsetLeft, width: 0 };
};

const HomePageTabs: FC<React.PropsWithChildren<HomePageTabsProps>> = ({
  chapterConfig,
  defaultSelectedTab,
  onTabChange,
  onHasSkipped,
  children,
}) => {
  const [selectedTab, setSelectedTab] = useState<SelectedTabConfig>(defaultSelectedTab ?? chapterConfig?.[0].tabs?.[0]);

  const [highlightConfig, setHighlightConfig] = useState<HighlightConfig | undefined>({ left: 0, width: 0 });

  const onTabClick = (tabKey: number) => {
    if (tabKey < 0) {
      const updatedTab = last(chapterConfig?.[selectedTab.chapterIdx - 1]?.tabs ?? []);
      if (updatedTab) {
        const updatedSelectedTab = {
          selectedTab: updatedTab,
          selectedTabIdx: chapterConfig[selectedTab.chapterIdx - 1].tabs.length - 1,
          chapterIdx: selectedTab.chapterIdx - 1,
        };
        setSelectedTab(updatedSelectedTab);
        onTabChange(updatedSelectedTab);
      }
      return;
    }
    if (tabKey >= chapterConfig[selectedTab.chapterIdx].tabs.length) {
      const updatedTab = chapterConfig?.[selectedTab.chapterIdx + 1]?.tabs?.[0];
      if (updatedTab) {
        const updatedSelectedTab = {
          selectedTab: updatedTab,
          selectedTabIdx: 0,
          chapterIdx: selectedTab.chapterIdx + 1,
        };
        setSelectedTab(updatedSelectedTab);
        onTabChange(updatedSelectedTab);
      }
      return;
    }

    const updatedTab = chapterConfig[selectedTab.chapterIdx].tabs?.[tabKey];
    if (updatedTab) {
      const updatedSelectedTab = {
        selectedTab: updatedTab,
        selectedTabIdx: tabKey,
        chapterIdx: selectedTab.chapterIdx,
      };
      setSelectedTab(updatedSelectedTab);
      onTabChange(updatedSelectedTab);
    }
  };

  const [hoveredTabId, setHoveredTabId] = useState<number>();
  const [hoverConfig, setHoverConfig] = useState<HighlightConfig | undefined>(undefined);
  const onTabHover = (tabKey: number, isHovered: boolean) => {
    setHoveredTabId((prev) => {
      if (!isHovered) {
        return prev === tabKey ? undefined : prev;
      }
      return tabKey;
    });
  };
  useEffect(() => {
    setHoverConfig(
      !isNil(hoveredTabId) && hoveredTabId !== selectedTab.selectedTabIdx
        ? getHighlightConfig(hoveredTabId)
        : undefined,
    );
  }, [hoveredTabId, selectedTab.selectedTabIdx]);

  useEffect(() => {
    setHighlightConfig(getHighlightConfig(selectedTab.selectedTabIdx));
  }, [selectedTab]);

  const hasPreviousChapter = selectedTab.chapterIdx > 0;
  const hasNextChapter = selectedTab.chapterIdx < chapterConfig.length - 1;

  const cantGoLeft = selectedTab.selectedTabIdx === 0 && !hasPreviousChapter;
  const cantGoRight =
    selectedTab.selectedTabIdx === chapterConfig[selectedTab.chapterIdx].tabs.length - 1 && !hasNextChapter;

  return (
    <Container>
      <ChapterLabel>
        <Icon type="home" />
        {chapterConfig[selectedTab.chapterIdx].chapterName}
      </ChapterLabel>
      <BackgroundWithTabs id={TABS_OFFSET}>
        <TopBar />
        {hasPreviousChapter && (
          <BackToChapter
            backToChapterName={chapterConfig[selectedTab.chapterIdx - 1].chapterName}
            onBackToChapter={() => {
              const updatedTab =
                chapterConfig[selectedTab.chapterIdx - 1].tabs[
                  chapterConfig[selectedTab.chapterIdx - 1].defaultSelectedTabIdx
                ];
              const updatedSelectedTab = {
                selectedTab: updatedTab,
                selectedTabIdx: chapterConfig[selectedTab.chapterIdx - 1].defaultSelectedTabIdx,
                chapterIdx: selectedTab.chapterIdx - 1,
              };
              setSelectedTab(updatedSelectedTab);
              onTabChange(updatedSelectedTab);
            }}
          />
        )}
        <TabsAndArrowsRow>
          <Tabs>
            {chapterConfig[selectedTab.chapterIdx].tabs.map(({ stepNumber, title }, key) => (
              <TabButton
                key={stepNumber}
                onClick={() => onTabClick(key)}
                className={TAB_BUTTON}
                onMouseEnter={() => onTabHover(key, true)}
                onMouseLeave={() => onTabHover(key, false)}
              >
                <TabStepNumber highlighted={selectedTab.selectedTabIdx === key}>
                  <Headline2>{stepNumber}</Headline2>
                </TabStepNumber>
                <TabTitle>
                  <ButtonText>
                    <TextWithHover hovered={hoveredTabId === key && hoveredTabId !== selectedTab.selectedTabIdx}>
                      {title}
                    </TextWithHover>
                  </ButtonText>
                </TabTitle>
              </TabButton>
            ))}
          </Tabs>
          <Arrows>
            <Arrow disabled={cantGoLeft} onClick={() => onTabClick(selectedTab.selectedTabIdx - 1)}>
              <Icon type="arrow-circle-left" prefix="far" />
            </Arrow>
            <Arrow disabled={cantGoRight} onClick={() => onTabClick(selectedTab.selectedTabIdx + 1)}>
              <Icon type="arrow-circle-right" prefix="far" />
            </Arrow>
          </Arrows>
        </TabsAndArrowsRow>
        {highlightConfig && (
          <Highlight {...highlightConfig}>
            <TopBorderHighlightLine highlighted={!hasPreviousChapter} />
          </Highlight>
        )}
        {hoverConfig && <Highlight {...hoverConfig} pale />}
      </BackgroundWithTabs>
      <SelectedContent>
        {children}
        {!!onHasSkipped && (
          <SkipContainer>
            <Skip
              type="button"
              data-testid="qa-skip-chapter"
              onClick={() => {
                const { defaultSelectedTabIdx, tabs } = chapterConfig[selectedTab.chapterIdx + 1];
                const updatedSelectedTab = {
                  selectedTab: tabs[defaultSelectedTabIdx],
                  selectedTabIdx: defaultSelectedTabIdx,
                  chapterIdx: selectedTab.chapterIdx + 1,
                };
                setSelectedTab(updatedSelectedTab);
                onTabChange(updatedSelectedTab);
                onHasSkipped();
              }}
            >
              <LinkStyle>
                Skip {chapterConfig[selectedTab.chapterIdx].chapterName} <Icon type="forward" />
              </LinkStyle>
            </Skip>
          </SkipContainer>
        )}
      </SelectedContent>
    </Container>
  );
};

export default HomePageTabs;
const BOTTOM_HEIGHT = 273;

const Container = styled.div`
  min-height: calc(100vh - ${BOTTOM_HEIGHT + TOP_NAV_HEIGHT}px);
  width: 100%;
  position: relative;
`;

const ChapterLabel = styled.div`
  position: absolute;
  left: 77px;
  top: -26px;
  color: ${GetColor.DarkGrey};
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 24px;
  i {
    color: ${GetColor.HighlightBackground};
    margin-right: 6px;
  }
`;

const BackgroundWithTabs = styled.div``;

const SelectedContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 160px;
  width: calc(100% - 160px);
`;

const TopBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${GetColor.Grey};
  position: absolute;
  z-index: ${ZIndex.Background};
`;

const TabsAndArrowsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tabs = styled.div`
  display: flex;
  padding-left: 77px;
`;

const TabButton = styled.button`
  display: flex;
  padding: 8px 0 10px 8px;
`;

const TabStepNumber = styled.div<{ highlighted: boolean }>`
  & > h2 {
    color: ${({ highlighted }) => (highlighted ? GetColor.HighlightDark : GetColor.DarkGrey)};
  }
`;

const TabTitle = styled.div`
  min-width: 100px;
  max-width: 170px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 10px;
  margin-right: 32px;
`;

const Arrows = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-around;
  padding-bottom: 10px;
`;

const Arrow = styled.button<{ disabled: boolean }>`
  font-size: 30px;
  line-height: 20px;
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${GetColor.MidGrey1};
          cursor: not-allowed;
        `
      : css`
          color: ${GetColor.Black};
          :hover {
            cursor: pointer;
            color: ${GetColor.Primary.Main};
          }
        `}
`;

const Highlight = styled.div<HighlightConfig & { pale?: boolean }>`
  position: absolute;
  top: 4px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  height: 100%;
  ${({ pale }) => css`
    background-color: ${pale ? ColorUtils.hex2rgbaFrom(GetColor.WhiteGrey, 0.5) : GetColor.WhiteGrey};
  `}
  z-index: ${ZIndex.Background};
  transition: left 0.5s;
  transition-timing-function: cubic-bezier(0.03, 0.92, 0.84, 1.08);
`;

const TopBorderHighlightLine = styled.div<{ highlighted?: boolean }>`
  z-index: ${ZIndex.Cover};
  border-top: 4px solid ${({ highlighted }) => (highlighted ? GetColor.HighlightDark : GetColor.Black)};
  width: 100%;
  top: -4px;
  position: absolute;
`;

const SkipContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: bold;
  i {
    font-size: 10px;
  }
`;

const TextWithHover = styled.span<{ hovered?: boolean }>`
  ${({ hovered }) =>
    hovered &&
    css`
      color: ${GetColor.Primary.Dark};
    `}
`;

const Skip = styled.button``;
