import React, { useContext, useMemo, useState } from 'react';
import { CheckboxWrapper, GetColor, Icon, Loading } from 'venn-ui-kit';
import ChartLegend from './ChartLegend';
import { isNil } from 'lodash';
import type { Solution } from 'venn-components';
import { ContentBlock, EmptyState } from 'venn-components';
import styled, { ThemeContext } from 'styled-components';
import { getEfficientFrontierChartConfig } from './chartConfigUtils';
import type { SolutionData } from '../../logic/useOptimizationSolutions';
import type { FactorForecast, RiskReturnPoint } from 'venn-api';
import SelectableSolutionChartSubheader from './SelectableSolutionChartSubheader';
import useSynchronizedSelectedChartPoints from './useSynchronizedSelectedChartPoints';
import { VennHighchart } from '../../../../../../venn-components/src/highchart/Highchart';

export interface EfficientFrontierCardData {
  current?: SolutionData;
  optimized?: SolutionData;
  benchmark?: SolutionData;
  alternate?: SolutionData[];
  efficientFrontier?: RiskReturnPoint[];

  summaryLoading: boolean;
  summaryError: boolean;
}

interface EfficientFrontierCardViewProps extends EfficientFrontierCardData {
  factorForecasts?: FactorForecast[];

  selectedSolution?: Solution;
  onSelectSolution: (solution: Solution) => void;
}

const EfficientFrontierCardView = ({
  current,
  optimized,
  alternate,
  benchmark,
  efficientFrontier,
  factorForecasts,
  selectedSolution,
  onSelectSolution,
  summaryLoading,
  summaryError,
}: EfficientFrontierCardViewProps) => {
  const [showFactorForecasts, setShowFactorForecasts] = useState(true);
  const theme = useContext(ThemeContext);

  const { setChartPoints } = useSynchronizedSelectedChartPoints(selectedSolution);

  const chartConfig = useMemo(() => {
    return getEfficientFrontierChartConfig(
      theme,
      onSelectSolution,
      current,
      showFactorForecasts,
      factorForecasts,
      optimized,
      efficientFrontier ?? [],
      alternate ?? [],
      benchmark,
      setChartPoints,
    );
  }, [
    theme,
    onSelectSolution,
    current,
    showFactorForecasts,
    factorForecasts,
    optimized,
    efficientFrontier,
    alternate,
    benchmark,
    setChartPoints,
  ]);

  return (
    <Card header="Efficient Frontier" subHeader={<SelectableSolutionChartSubheader />}>
      {summaryLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : summaryError ? (
        <ErrorContainer>
          <EmptyState header="Unable to calculate forecast" />
        </ErrorContainer>
      ) : (
        <>
          <ChartLegend
            hasOptimized={!isNil(optimized)}
            hasBenchmark={!isNil(benchmark)}
            rightChildren={
              !isNil(factorForecasts) &&
              factorForecasts.length > 0 && (
                <ForecastsToggle>
                  <CheckboxWrapper
                    checked={showFactorForecasts}
                    onChange={() => setShowFactorForecasts(!showFactorForecasts)}
                  >
                    {' '}
                    <Icon type="dna" /> Factor Forecasts
                  </CheckboxWrapper>
                </ForecastsToggle>
              )
            }
          />
          <VennHighchart options={chartConfig} className="qa-efficient-frontier-chart" />
        </>
      )}
    </Card>
  );
};

export default React.memo(EfficientFrontierCardView);

const Card = styled(ContentBlock)`
  background-color: ${GetColor.White};
`;

const ForecastsToggle = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: bold;

  span {
    font-weight: bold;
    padding-left: 4px;
  }

  i {
    margin-right: 2px;
  }

  & > label {
    min-width: 10px;
    min-height: 10px;
  }

  & > label > input {
    min-width: 10px;
    max-width: 10px;
    height: 10px;
    font-size: 7px;
  }
`;

const LoadingContainer = styled.div`
  min-height: 430px;
`;

const ErrorContainer = styled.div`
  padding: 20px;
`;
