import type { RefObject } from 'react';
import React, { useMemo, useRef } from 'react';
import styled, { withTheme } from 'styled-components';
import Measure from 'react-measure';
import type HighchartsReact from 'highcharts-react-official';
import { createVennPieChartConfig } from '../../../charts/analysis-charts/chart-config-logic';
import type { Theme } from 'venn-ui-kit';
import { GetColor, Icon } from 'venn-ui-kit';
import DownloadableContentBlock from '../../../content-block/DownloadableContentBlock';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { getPieChartData } from '../../logic/pieChartParsers';
import type { CustomizedBlock } from 'venn-api';
import { useRecoilValue } from 'recoil';
import {
  allHoldingsCategories,
  blockFonts,
  blockLimitedHoldingsSubjectsWithFees,
  getCustomFontSize,
  holdingsCategoriesData,
  isReportState,
} from 'venn-state';
import { useHoldings } from '../../logic/useHoldings';
import { GraphicSubjectLabel } from './GraphicSubjectLabel';
import { useBlockId } from '../../contexts/BlockIdContext';
import { StudioHighchart } from '../../../highchart/Highchart';

export interface HoldingsPieChartDisplayProps {
  theme: Theme;
  inPrintMode?: boolean;
  height?: number;
  selectedBlock: CustomizedBlock;
  downloadableContentRef?: RefObject<HTMLDivElement>;
  selectedRefId: string;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const HoldingsPieChart = React.memo(
  ({
    theme,
    inPrintMode,
    height,
    selectedBlock,
    downloadableContentRef,
    selectedRefId,
  }: HoldingsPieChartDisplayProps) => {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const blockId = useBlockId();
    const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
    const isReport = useRecoilValue(isReportState);
    const subject = useRecoilValue(blockLimitedHoldingsSubjectsWithFees(selectedRefId))[0];
    const categoriesData = useRecoilValue(holdingsCategoriesData('ASSET'));
    const allCategories = useRecoilValue(allHoldingsCategories(selectedRefId));
    const { data, categories } = useHoldings(selectedRefId);
    const filteredData = useMemo(() => {
      const categoriesIds = categories.map((category) => category.id.id);
      const filteredData = data?.breakdowns?.[0]?.holdingsContributions.filter((category) =>
        categoriesIds.includes(category.id.id),
      );
      return filteredData;
    }, [categories, data]);
    const series = useMemo(
      () => getPieChartData(theme, 'ASSET_EXPOSURE', undefined, filteredData, categoriesData),
      [categoriesData, filteredData, theme],
    );
    const options = useMemo(() => {
      return createVennPieChartConfig(
        subject.name,
        series,
        theme,
        'percent',
        height,
        legendFont.fontSizePt,
        inPrintMode || isReport,
        false,
        true,
      );
    }, [subject.name, series, theme, height, inPrintMode, isReport, legendFont.fontSizePt]);
    return (
      <DownloadableContentBlock
        header=""
        noBorder
        downloadable={{
          png: true,
          options: {
            fileName: selectedBlock?.header ? selectedBlock?.header : 'HoldingsPieChart',
            watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
          },
          tracking: {
            description: 'BUILDER',
            relativeToBenchmark: false,
            userUploaded: false,
            subjectType: undefined,
            subjectId: undefined,
          },
          disabled: (series?.length ?? 0) === 0,
          target: downloadableContentRef,
        }}
        floatingOptions
      >
        <Measure onResize={() => chartRef.current?.chart?.reflow?.()}>
          {({ measureRef }) => (
            <Wrapper ref={measureRef}>
              {subject && (isReport || inPrintMode) && <GraphicSubjectLabel subject={subject} />}
              <StudioHighchart options={options} blockId={blockId} chartRef={chartRef} style={{ flex: 1 }} />
              {allCategories.length !== categories.length && (
                <NumberDisplayed fontSize={getCustomFontSize(legendFont)}>
                  <Icon type="chart-pie" />
                  &nbsp;
                  {`${categories.length} / ${allCategories.length} asset classes displayed`}
                </NumberDisplayed>
              )}
            </Wrapper>
          )}
        </Measure>
      </DownloadableContentBlock>
    );
  },
);

export default withTheme(HoldingsPieChart);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const NumberDisplayed = styled.div<{ fontSize: string | undefined }>`
  display: flex;
  padding: 4px;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
  color: ${GetColor.DarkGrey};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
`;
