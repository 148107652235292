import React, { useCallback, useContext } from 'react';
import type { Fund, Portfolio } from 'venn-api';
import { remapInvestment } from '../../review/helpers';
import { useUploadPortfolioAllocator } from '../../useUniversalUploaderAllocator';
import { useCommonTreeGridProps } from '../../../../../studio-blocks/logic/columnUtils';
import DataGrid from '../../../../../data-grid/DataGrid';
import styled from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';
import { MultiPortfolioReviewContext } from './MultiPortfolioReviewContext';
import { MultiHistoricalPortfolioContext } from '../specialized/MultiHistoricalPortfolioContext';

type RightSideBarContentProps = {
  portfolio: Portfolio;
};

export const RightSideBarContent = ({ portfolio }: RightSideBarContentProps) => {
  const {
    data: { existingParsedData, selectedIndex },
    actions: { updatePortfolio },
  } = useContext(MultiPortfolioReviewContext);
  const { selectedDate } = useContext(MultiHistoricalPortfolioContext);
  const selectedAlreadyExistingPortfolio = existingParsedData.some(
    (existingParsedDatum) => existingParsedDatum.originalIndex === selectedIndex,
  );
  const accessMode = selectedAlreadyExistingPortfolio ? 'OVERWRITE_EXISTING' : 'CREATE_NEW';
  const applyInvestmentRemap = useCallback(
    (path: number[], fund: Fund) => {
      const newPortfolio = remapInvestment(portfolio, path, fund);
      updatePortfolio(selectedIndex, newPortfolio);
    },
    [updatePortfolio, selectedIndex, portfolio],
  );

  const { rowData, columnDefs, autoGroupColumnDefs, rowClassRules } = useUploadPortfolioAllocator(
    portfolio,
    accessMode,
    applyInvestmentRemap,
    selectedDate,
  );
  const commonGridProps = useCommonTreeGridProps();

  return (
    <PortfolioAllocatorDataGridWrapper>
      <DataGrid
        {...commonGridProps}
        rowData={rowData}
        columnDefs={columnDefs}
        domLayout="normal"
        autoGroupColumnDef={autoGroupColumnDefs}
        rowClassRules={rowClassRules}
        suppressCellFocus
        suppressRowTransform
        suppressScrollOnNewData
        suppressRowHoverHighlight
      />
    </PortfolioAllocatorDataGridWrapper>
  );
};

const PortfolioAllocatorDataGridWrapper = styled.div`
  // ag-grid does not appear unless the parent has a height set
  // perhaps this is a problem with our DataGrid wrapper
  height: 100%;

  // styling for portfolio review

  .ag-row-odd,
  .ag-row-even {
    background-color: ${GetColor.GreyScale.Grey20};
  }

  .ag-header-container {
    background-color: ${GetColor.White};
  }

  .no-mapped-fund-row {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Error, 0.1)};
  }

  .ag-cell {
    &[col-id='mapped'] {
      display: flex;
      flex-direction: row;
      justify-content: left;
    }
  }

  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }

  .ag-group-expanded,
  .ag-group-contracted {
    margin-right: 4px;
  }

  .ag-header-cell {
    &[col-id='allocation'] {
      padding-right: 14px;
      text-align: right;
    }
  }
`;
