import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { ICellRendererParams } from '../../../../data-grid';
import type { ScenarioRowData } from '../../../types';
import type { FactorWithNonSerializedReturns as FactorEntity, PerformanceAttribution } from 'venn-api';
import compact from 'lodash/compact';
import { ContributionPopover } from '../../../../contribution-popover';
import { RESIDUAL_ID } from '../../../../factor-chart';
import { FactorLensesContext } from '../../../../contexts';
import { Numbers } from 'venn-utils';

const formatPercentage = (value?: number) => Numbers.safeFormatPercentage(value, 1);

interface ScenarioCombinedCellRendererProps extends ICellRendererParams {
  data: ScenarioRowData;
  subjectName: string;
  index: number;
  relative: boolean;
}

const ScenarioCombinedCellRenderer = ({ data, subjectName, index, relative }: ScenarioCombinedCellRendererProps) => {
  const [popover, setPopover] = useState<boolean>();
  const { primaryFactorLens } = useContext(FactorLensesContext);
  const hidePopover = useCallback(() => setPopover(false), []);
  const showPopover = useCallback(() => setPopover(true), []);
  const scenarioAnalysis = data.scenarioAnalysis[index];

  if (!scenarioAnalysis || !primaryFactorLens) {
    return <PredictedWrapper>--</PredictedWrapper>;
  }

  const { factors } = primaryFactorLens;
  const {
    factorsPredictedImpactMap: contributionMap,
    factorsPredictedMap: valueMap,
    cashRateImpact,
  } = scenarioAnalysis;
  const rows: Partial<PerformanceAttribution>[] = factors.map((factor: FactorEntity) => ({
    contribution: undefined,
    contributionValue: contributionMap[factor.id],
    name: factor.name,
    value: valueMap?.[factor.id],
    weight: undefined,
  }));
  rows.push({
    contribution: undefined,
    contributionValue: contributionMap[RESIDUAL_ID],
    name: 'Residual',
    value: valueMap[RESIDUAL_ID],
    weight: undefined,
  });
  if (!relative) {
    rows.push({
      contribution: undefined,
      contributionValue: cashRateImpact,
      name: 'Risk-Free Rate',
      value: cashRateImpact,
      weight: undefined,
    });
  }

  if (scenarioAnalysis.status === 'FAILURE') {
    return <PredictedWrapper>--</PredictedWrapper>;
  }

  return (
    <CellWithHover onMouseEnter={showPopover} onMouseLeave={hidePopover}>
      <PredictedWrapper>{formatPercentage(scenarioAnalysis?.predicted)}</PredictedWrapper>
      <PlusMinusText> ± </PlusMinusText>
      <ErrorBoundsSpan>{(scenarioAnalysis.predictedError * 100).toFixed(1)}</ErrorBoundsSpan>
      {popover && (
        <PopoverWrapper>
          <ContributionPopover
            popoverPosition="left"
            visible
            subjectName={subjectName}
            value={scenarioAnalysis.predicted}
            data={rows}
            keepInitialDataOrder
            metricName={relative ? 'Estimated Relative Return' : 'Estimated Return'}
            abbreviatedMetricName="Return"
            formatter={formatPercentage}
            dataItemLabel="Factor"
            italicRows={compact([!relative ? rows.length - 2 : null, rows.length - 1])}
            shouldIgnoreMouseEvents
          />
        </PopoverWrapper>
      )}
    </CellWithHover>
  );
};

export default ScenarioCombinedCellRenderer;

const PopoverWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 55px;
`;

const CellWithHover = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  position: relative;
`;

const PlusMinusText = styled.span`
  white-space: pre-wrap;
  font-weight: lighter;
  color: ${GetColor.DarkGrey};
`;

const ErrorBoundsSpan = styled.span`
  color: ${GetColor.DarkGrey};
`;

const PredictedWrapper = styled.div`
  text-align: right;
`;
