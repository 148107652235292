import React, { useContext } from 'react';
import styled from 'styled-components';
import type { AnalysisViewSearchResult, HelpArticleDoc } from 'venn-api';
import { EllipsisTooltipSpan, getItemLabel, ItemIcon } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { Dates, FundUtils } from 'venn-utils';
import UserContext from '../../contexts/user-context';
import type { SearchMenuItem } from '../types';
import { getItemType, getSubjectRange, getTicker, HeaderCell, isUploaded } from './shared';

export enum SearchMenuColumn {
  CCY = 'CCY',
  IDENTIFIER = 'IDENTIFIER',
  TYPE = 'TYPE',
  AVAILABLE_DATES = 'AVAILABLE_DATES',
  LAST_UPDATED = 'LAST_UPDATED',
  VIEW_LAST_UPDATED = 'VIEW_LAST_UPDATED',
  VIEW_LAST_UPDATED_BY = 'VIEW_LAST_UPDATED_BY',
  CREATED_AT = 'CREATED_AT',
}

export const VIEW_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.VIEW_LAST_UPDATED_BY,
  SearchMenuColumn.VIEW_LAST_UPDATED,
];

export const ARTICLE_COLUMNS: SearchMenuColumn[] = [SearchMenuColumn.CREATED_AT];

export const DEFAULT_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

export const DEFAULT_PORTFOLIOS_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

const DEFAULT_PORTFOLIO_SMALL_SCREEN_COLUMNS = [SearchMenuColumn.CCY];
const DEFAULT_SMALL_SCREEN_COLUMNS = [SearchMenuColumn.CCY, SearchMenuColumn.IDENTIFIER];
export const getDefaultColumns = (onlyPortfolio: boolean, smallScreen: boolean) => {
  if (onlyPortfolio && smallScreen) {
    return DEFAULT_PORTFOLIO_SMALL_SCREEN_COLUMNS;
  }
  if (smallScreen) {
    return DEFAULT_SMALL_SCREEN_COLUMNS;
  }

  if (onlyPortfolio) {
    return DEFAULT_PORTFOLIOS_COLUMNS;
  }
  return DEFAULT_COLUMNS;
};

interface Column {
  headerRenderer: () => React.ReactNode;
  cellRenderer: (
    item?: AnalysisSubject,
    view?: AnalysisViewSearchResult,
    helpArticle?: HelpArticleDoc,
  ) => React.ReactNode;
}

const SubjectCurrency = ({ item }: { item: AnalysisSubject }) => {
  const { profileSettings } = useContext(UserContext);
  if (item.fund) {
    return item.fund?.currency;
  }
  if (item.privateFund) {
    return item.privateFund.currency;
  }
  return profileSettings?.organization.currency;
};

export const ColumnRenderers: Record<SearchMenuColumn, Column> = {
  CCY: {
    headerRenderer: () => <HeaderCell>CCY</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject) => <td>{item ? <SubjectCurrency item={item} /> : '--'}</td>,
  },
  IDENTIFIER: {
    headerRenderer: () => <HeaderCell>Identifier</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject) => (
      <td>
        <EllipsisTooltipSpan maxWidth={100} flex>
          {item ? getTicker(item) : '--'}
        </EllipsisTooltipSpan>
      </td>
    ),
  },
  TYPE: {
    headerRenderer: () => <HeaderCell>Type</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject) => (
      <td>{item?.fund && FundUtils.getAssetTypeName(item?.fund?.assetType)}</td>
    ),
  },
  AVAILABLE_DATES: {
    headerRenderer: () => <HeaderCell>Available Dates</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject) => {
      return <SingleLineDateCell>{item && getSubjectRange(item)}</SingleLineDateCell>;
    },
  },
  LAST_UPDATED: {
    headerRenderer: () => <HeaderCell>Last Updated</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject) => (
      <SingleLineDateCell>
        {item?.superItem?.updated ? Dates.toDayMonthYear(item?.superItem?.updated) : '--'}
      </SingleLineDateCell>
    ),
  },
  VIEW_LAST_UPDATED_BY: {
    headerRenderer: () => <HeaderCell>Last Updated By</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject, view?: AnalysisViewSearchResult) => (
      <SingleLineDateCell>{view ? view.owner.displayName : '--'}</SingleLineDateCell>
    ),
  },
  VIEW_LAST_UPDATED: {
    headerRenderer: () => <HeaderCell>Last Updated</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject, view?: AnalysisViewSearchResult) => (
      <SingleLineDateCell>{view?.updated ? Dates.toDayMonthYear(view?.updated) : '--'}</SingleLineDateCell>
    ),
  },
  CREATED_AT: {
    headerRenderer: () => <HeaderCell>Created On</HeaderCell>,
    cellRenderer: (item?: AnalysisSubject, view?: AnalysisViewSearchResult, helpArticle?: HelpArticleDoc) => (
      <SingleLineDateCell>
        {helpArticle?.createdAt ? Dates.toDayMonthYear(Number(`${helpArticle.createdAt}000`)) : '--'}
      </SingleLineDateCell>
    ),
  },
};

const tooltipItemsRenderers = [
  {
    label: 'Available Dates',
    valueRenderer: (item?: AnalysisSubject) => (item?.superItem ? getSubjectRange(item) : '--'),
  },
  {
    label: 'Last Updated',
    valueRenderer: (item?: AnalysisSubject) =>
      item?.superItem.updated ? Dates.toDayMonthYear(item.superItem.updated) : '--',
  },
];

export const renderItemsTooltip = (searchItem: SearchMenuItem, isOptionDisabled?: boolean) => {
  const itemType = getItemType(searchItem);
  return (
    <TooltipContent>
      <Name>{searchItem.label}</Name>

      <SmallRow>
        <ItemIcon
          isDisabled={isOptionDisabled}
          item={itemType}
          dataSource={searchItem.value?.superItem.dataSource || searchItem.value?.portfolio?.dataSource}
          isUploaded={isUploaded(searchItem)}
          investmentSource={searchItem.value?.fund?.investmentSource}
        />
        {getItemLabel(itemType)}
      </SmallRow>

      {tooltipItemsRenderers.map((item) => (
        <Row key={item.label}>
          <span>{item.label}:</span>
          <Bold>{item.valueRenderer(searchItem.value)}</Bold>
        </Row>
      ))}
    </TooltipContent>
  );
};

const SingleLineCell = styled.td`
  white-space: nowrap;
`;

const SingleLineDateCell = styled(SingleLineCell)`
  text-align: right;
`;

const TooltipContent = styled.div`
  width: 200px;
  font-size: 11px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SmallRow = styled.div`
  margin-bottom: 10px;
  font-size: 10px;
  display: flex;
  align-items: center;
  column-gap: 3px;
`;

const Bold = styled.span`
  font-weight: bold;
`;
