import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UserContext } from 'venn-components';
import { useHasFF, withSuspense } from 'venn-utils';
import { getDefaultIndicesForUser, type ScenarioAnalysisIndex } from 'venn-api';
import { ScenarioDropdown, ScenarioDropdownShimmer } from './ScenarioDropdown';
import { useScenarioRecoilState } from './useScenarioRecoilState';
import { groupBy, isEmpty } from 'lodash';
import toPairs from 'lodash/toPairs';
import {
  AccordionMenu,
  type AccordionMenuItem,
  ColorUtils,
  GetColor,
  SimpleTrigger,
  SkeletalDropMenu,
} from 'venn-ui-kit';
import styled from 'styled-components';

const isMacro = (scenario: ScenarioAnalysisIndex) => scenario.type === 'MACRO' && !scenario.category;
const hasAssignedCategory = (scenario: ScenarioAnalysisIndex) => !!scenario.category;

const toOption = (scenario: ScenarioAnalysisIndex) => ({
  label: scenario.fundName,
  value: scenario,
});

const SuspenseState = () => (
  <>
    {useHasFF('macro_scenario_ff') && <ScenarioDropdownShimmer />}
    <ScenarioDropdownShimmer />
  </>
);

export const ScenarioDropdowns = withSuspense(<SuspenseState />, ({ selected }: { selected: string }) => {
  const showMacroScenarios = useHasFF('macro_scenario_ff');

  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_METRICS');
  const { addScenario, addCategorizedScenario } = useScenarioRecoilState(selected);

  const { data: scenarios = [] } = useQuery<ScenarioAnalysisIndex[]>(
    [],
    async () => (await getDefaultIndicesForUser()).content,
    {
      suspense: true,
    },
  );

  const macroItems = scenarios.filter(isMacro).map(toOption);

  const categorizedItems = scenarios.filter(hasAssignedCategory);
  const allCategories = toPairs(groupBy(categorizedItems, 'category')).map((pair) => {
    const [categoryName, entries] = pair;
    return {
      label: categoryName,
      value: categoryName,
      items: entries.map(toOption),
    };
  });

  const onSelect = useCallback(
    (item: ScenarioAnalysisIndex | string) => {
      if (typeof item === 'string') {
        return;
      }

      addCategorizedScenario(item);
    },
    [addCategorizedScenario],
  );

  const [search, setSearch] = useState('');
  const filteredItems = useMemo(() => {
    if (search.length === 0) {
      return allCategories;
    }
    const filteredCategories: AccordionMenuItem<string | ScenarioAnalysisIndex>[] | undefined = [];
    // Simple quick search
    for (const category of allCategories) {
      if (category.label.toLowerCase().includes(search.toLowerCase())) {
        filteredCategories.push(category);
      } else if (category.items && category.items.length > 0) {
        const items = category.items.filter((i) => i.label.toLowerCase().includes(search.toLowerCase()));
        if (items.length > 0) {
          filteredCategories.push({ ...category, items });
        }
      }
    }
    return filteredCategories;
  }, [allCategories, search]);

  return (
    <>
      {showMacroScenarios && (
        <ScenarioDropdown
          label="Macroeconomic Indicator"
          placeholder="Add indicator..."
          onChange={addScenario}
          disabled={isReadOnly}
          items={macroItems}
        />
      )}
      <Container>
        <NewContainer>
          <NewBadge>New</NewBadge>
          Check out the new Shock Input options below
        </NewContainer>
        <SkeletalDropMenu
          menuPosition="left"
          usePortal
          triggerComponent={(expanded, toggleMenu) => (
            <SimpleTrigger
              label="Add Shock Input"
              placeholder="Search recommended market shocks..."
              expanded={expanded}
              onClick={toggleMenu}
              aria-expanded={expanded}
              aria-haspopup
              searchable
              search={search}
              onSearch={(text) => {
                setSearch(text);
              }}
            />
          )}
          menuComponent={() => (
            <AccordionContainer style={{}}>
              {isEmpty(filteredItems) ? (
                <NoResultsContainer>No results found.</NoResultsContainer>
              ) : (
                <AccordionMenu
                  expanded
                  idGetter={(value: ScenarioAnalysisIndex | string) => (typeof value === 'string' ? value : value?.id)}
                  onSelect={onSelect}
                  items={filteredItems}
                  studioStyle
                />
              )}
            </AccordionContainer>
          )}
        />
      </Container>
    </>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid ${GetColor.Grey};
  margin-bottom: 10px;
  gap: 6px;
`;

const NewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 260px;
  gap: 6px;
  padding: 4px 6px;
  font-size: 10px;
  font-style: normal;
  border: 1px solid ${GetColor.Grey};
  color: ${GetColor.HintGrey};
  border-radius: 4px;
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
  margin-bottom: 6px;
`;

const NewBadge = styled.span`
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${GetColor.Primary.Dark};
  color: ${GetColor.White};
  line-height: 1.5;
  font-weight: 700;
`;

const AccordionContainer = styled.div`
  width: 260px;
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: normal;
  background-color: ${GetColor.White};
  .accordion-content-wrapper-btn {
    width: 260px;
  }
`;

const NoResultsContainer = styled.div`
  font-weight: bold;
  padding: 10px;
`;
