import { LIBRARY_ITEM_TYPE_LABELS, QUICK_FILTER_LABELS, toDisplayString } from '../filters';
import { LibraryItemType, FundUtils } from 'venn-utils';
import { compact } from 'lodash';
import type { FiltersState } from 'venn-state';

interface GetFilterPillsProps {
  filtersState: FiltersState;
  toggleParamItem: (key: string, item: string) => void;
  updateSearchParam: (key: string, value: string) => void;
  removeAdvancedQuery: (index: number) => void;
  hasFullHistoryFlag: boolean;
}

export interface FilterPillProps {
  key: string;
  label: string;
  value: string;
  icon: string;
  onClick(): void;
}

export const getFilterPills = ({
  filtersState,
  toggleParamItem,
  updateSearchParam,
  removeAdvancedQuery,
  hasFullHistoryFlag,
}: GetFilterPillsProps): FilterPillProps[] => {
  return compact([
    ...filtersState.quickFilters.map((value) => ({
      label: QUICK_FILTER_LABELS[value],
      value,
      onClick: () => toggleParamItem('filters', value),
      icon: 'filter',
      key: `filter-${value}`,
    })),
    filtersState.itemType &&
      filtersState.itemType !== LibraryItemType.ALL && {
        label: LIBRARY_ITEM_TYPE_LABELS[filtersState.itemType],
        value: filtersState.itemType,
        onClick: () => updateSearchParam('itemType', LibraryItemType.ALL),
        icon: 'filter',
        key: `itemType-${filtersState.itemType}`,
      },
    ...filtersState.tags.map((tag) => ({
      label: tag,
      value: tag,
      onClick: () => toggleParamItem('tags', tag),
      icon: 'tag',
      key: `tag-${tag}`,
    })),
    ...filtersState.currency.map((currency) => ({
      label: currency,
      value: currency,
      onClick: () => toggleParamItem('currency', currency),
      icon: 'filter',
      key: `currency-${currency}`,
    })),
    ...filtersState.assetTypes.map((assetType) => ({
      label: FundUtils.getAssetTypeName(assetType),
      value: assetType,
      onClick: () => toggleParamItem('assetTypes', assetType),
      icon: 'filter',
      key: `assetType-${assetType}`,
    })),
    ...filtersState.dataSource.map((dataSource) => ({
      label: dataSource,
      value: dataSource,
      onClick: () => toggleParamItem('dataSource', dataSource),
      icon: 'filter',
      key: `dataSource-${dataSource}`,
    })),
    ...filtersState.morningstarCategories.map((category) => ({
      label: category,
      value: category,
      onClick: () => toggleParamItem('morningstarCategories', category),
      icon: 'star',
      key: `morningstarCategory-${category}`,
    })),
    ...filtersState.advancedQueries.map((query, index) => ({
      label: toDisplayString(query, hasFullHistoryFlag),
      value: '',
      onClick: () => removeAdvancedQuery(index),
      icon: 'crosshairs',
      key: `query-${index}`,
    })),
  ]);
};
