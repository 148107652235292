import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Body1, ColorUtils, GetColor, Icon } from 'venn-ui-kit';
import { toClassName, useModuleLazily } from 'venn-utils';
import type { AnalysisBlock } from 'venn-api';
import type { DropResult } from '@hello-pangea/dnd';
import type ReactDnd from '@hello-pangea/dnd';

interface TemplateBlocksProps {
  analysisBlocks: AnalysisBlock[];
  setAnalysisBlocks: (newBlocks: AnalysisBlock[]) => void;
}
const reorder = (list: AnalysisBlock[], startIndex: number, endIndex: number): AnalysisBlock[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const TemplateBlocks = ({ analysisBlocks, setAnalysisBlocks }: TemplateBlocksProps) => {
  const reactDnd: typeof ReactDnd | null = useModuleLazily(useCallback(() => import('@hello-pangea/dnd'), []));

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newBlocksOrder = reorder(analysisBlocks, result.source.index, result.destination.index);

    setAnalysisBlocks(newBlocksOrder);
  };

  const onDeleteBlock = (block: AnalysisBlock) =>
    setAnalysisBlocks(analysisBlocks.filter((analysisBlock) => analysisBlock !== block));

  if (analysisBlocks.length === 0 || reactDnd === null) {
    return null;
  }

  const { DragDropContext, Draggable, Droppable } = reactDnd;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <AnalysisBlocks {...provided.droppableProps} ref={provided.innerRef} className="qa-chosen-templates">
            {analysisBlocks.map((block, index) => (
              <Draggable key={block.analysisBlockType} draggableId={block.analysisBlockType} index={index}>
                {(draggableProvided, snapshot) => (
                  <StyledDraggable
                    dragging={snapshot.isDragging}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    className={block ? toClassName(block.title) : 'qa-undefined'}
                  >
                    <StyledIcon type="arrows-alt-v" />
                    <AnalysisBlockContent key={block.analysisBlockType}>
                      <Body1>{block.title}</Body1>
                    </AnalysisBlockContent>
                    <DeleteButton onClick={() => onDeleteBlock(block)}>
                      <Icon type="trash" />
                    </DeleteButton>
                  </StyledDraggable>
                )}
              </Draggable>
            ))}
          </AnalysisBlocks>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TemplateBlocks;
const StyledIcon = styled(Icon)`
  visibility: hidden;
  position: absolute;
  top: 6px;
  left: 3px;
`;
const AnalysisBlocks = styled.ul`
  list-style: none;
  padding: 20px 0;
  margin: 0 -30px;
`;
const AnalysisBlockContent = styled.li`
  border-left: solid 3px ${GetColor.Grey};
  padding-left: 17.5px;
  margin: 0 30px;
`;

const DeleteButton = styled.button`
  visibility: hidden;
  position: absolute;
  top: 5px;
  right: 5px;

  i {
    color: ${GetColor.Grey};
    :hover {
      color: ${GetColor.Primary.Dark};
      cursor: pointer;
    }
  }
`;

const StyledDraggable = styled.div<{ dragging: boolean }>`
  position: relative;
  &:hover {
    background: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.25)};
    ${StyledIcon} {
      visibility: visible;
    }
    ${DeleteButton} {
      visibility: visible;
    }
  }

  ${({ dragging }) =>
    dragging &&
    `
    background-color: ${GetColor.Primary.Main};
    display: inline-block;
    width: auto !important;
    border-radius: 4px;
    ${AnalysisBlockContent} {
      padding-right: 17.5px;
      border-left: none;
      margin: 0;
      ${Body1} {
        color: ${GetColor.White};
      }
    }
  `}
`;
