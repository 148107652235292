import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
import styled, { withTheme } from 'styled-components';
import type { AutocompleteResult, ItemId } from 'venn-api';
import type { TypeaheadMenuProps, ButtonDropdownProps, Theme } from 'venn-ui-kit';
import { ColorUtils, GetColor, Highlighter, Icon, TypeaheadMenu } from 'venn-ui-kit';
import type { Option, Scope, OptionValue } from './types';
import { NEW_INSTRUMENT, SCOPE_TYPES } from './types';
import { fetchOptions, getScopeOptions, getYearRange } from './utils';
import { compact } from 'lodash';
import type { AnyDuringEslintMigration } from 'venn-utils';

export interface FundTypeaheadProps {
  isNew: boolean;
  value?: string;
  className?: string;
  initialScope?: SCOPE_TYPES;
  excludedItems?: ItemId[];
  scopeOptions?: ((() => Scope) | Scope)[];
  onFundSelect: (selected: Option) => void;
  onNewSelect?: (selected: Option) => void;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  closeOnSelect?: boolean;
  hideButton?: boolean;
  /** Whether the value should be mapped to an existing fund or not.
   * If falsy, there will be an option to create a new investment with the given value. */
  forceSelection?: boolean;
  /** If true, the input text is aligned with the selected icon.
   * If not, the text is aligned with the text in the options list */
  alignInputTextToCheckMark?: boolean;
  theme: Theme;
}

export interface FundTypeaheadState {
  loading: boolean;
  isEditing: boolean;
  options: Option[];
  query: string;
  scope: string;
}

const TYPEAHEAD_DEBOUNCE_TIME = 150;

class FundTypeahead extends Component<FundTypeaheadProps, FundTypeaheadState> {
  static defaultProps: Partial<FundTypeaheadProps> = {
    scopeOptions: getScopeOptions(),
    excludedItems: [],
    placeholder: 'Add manager',
  };

  input?: HTMLInputElement;

  constructor(props: FundTypeaheadProps) {
    super(props);

    const { initialScope: scope = SCOPE_TYPES.ALL_FUNDS } = props;

    this.state = {
      loading: false,
      isEditing: false,
      scope,
      query: props.value || '',
      options: [],
    };

    this.fetchOptions = debounce(this.fetchOptions.bind(this), TYPEAHEAD_DEBOUNCE_TIME, { leading: true });
  }

  createOption = ({ name, fund, portfolio, type }: AutocompleteResult, index: number) => {
    const {
      isNew,
      value,
      forceSelection,
      theme: { Colors },
    } = this.props;
    const selectedIcon = name === value && <SelectedIcon type="check" top={type === NEW_INSTRUMENT ? undefined : 11} />;

    if (type === NEW_INSTRUMENT) {
      const newInstrumentOption: Option = {
        label: name,
        value: -1,
        type,
        fund,
        children: (
          <NewInstrumentOption>
            {isNew && selectedIcon}
            <div>
              {name} <NewInstrumentLabel>(Create new)</NewInstrumentLabel>
            </div>
          </NewInstrumentOption>
        ),
      };
      return newInstrumentOption;
    }

    if (!fund && !portfolio) {
      return undefined;
    }

    const yearRange = getYearRange(
      fund ? fund.startRange : portfolio!.periodStart,
      fund ? fund.endRange : portfolio!.periodEnd,
    );
    const needsHeader = index === 1 && !forceSelection;

    const option: Option = {
      label: name,
      value: fund ? fund.id : portfolio!.id,
      type,
      fund,
      children: (
        <ExistingOption>
          {!isNew && selectedIcon}
          <HighlighterContainer>
            <Highlighter searchWords={[this.state.query]} text={name} highlightColor={Colors.Black} />
          </HighlighterContainer>
          <OptionIconWrapper>
            <Icon type="database" />
            <StyledYearRange>{yearRange}</StyledYearRange>
          </OptionIconWrapper>
        </ExistingOption>
      ),
      header: needsHeader ? <ExistingInvestmentsLabel>Existing Investments</ExistingInvestmentsLabel> : undefined,
    };

    return option;
  };

  handleScopeChange = (scope: Scope) => {
    this.setState({ scope: scope.value }, this.fetchOptions);
  };

  fetchOptions = async () => {
    const { excludedItems, forceSelection } = this.props;
    const { query, scope } = this.state;
    this.setState({ loading: true, options: [] });

    try {
      const results = await fetchOptions(query, {
        scope,
        excludedItems: excludedItems!,
        forceSelection,
      });

      if (results) {
        this.setState({
          loading: false,
          options: compact(results.map(this.createOption)),
        });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  // TODO: (VENN-20577 / TYPES) to remove this any type, we need to modify Menu and Options typing to correctly show that it is
  // returning the Option variant that it was provided, not a hardcoded option object.
  // That is to say that menu doesn't support just options of shape OptionType<V> it actually supports arbitrary option shapes
  // as long as they extend OptionType<V>.
  onSelect: AnyDuringEslintMigration = (fund: Option) => {
    if (fund.type === NEW_INSTRUMENT) {
      this.props.onNewSelect?.(fund);
    } else {
      this.props.onFundSelect(fund);
    }

    if (this.props.closeOnSelect && this.input) {
      this.input.blur();
    }

    this.setState({ query: fund.label });
  };

  onFocus = () => {
    this.fetchOptions();
    this.setState({ isEditing: true });
  };

  onBlur = () => {
    this.setState({ isEditing: false });
  };

  onChange = (value: string) => {
    this.handleQueryChange(value);
    this.props.onChange && this.props.onChange(value);
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, this.fetchOptions);
  };

  render() {
    const { scopeOptions, value, autoFocus, placeholder, hideButton, className, alignInputTextToCheckMark } =
      this.props;
    const { options, query, loading, isEditing } = this.state;

    const buttonProps: ButtonDropdownProps<string> = {
      options: scopeOptions?.map((scopeOption) => (isFunction(scopeOption) ? scopeOption() : scopeOption)) ?? [],
      // @ts-expect-error: TODO fix strictFunctionTypes
      onChange: this.handleScopeChange,
    };

    const menuProps = {
      buttonProps,
      placeholder,
      onChange: this.onChange,
      options,
      showNone: true,
      onSelect: this.onSelect,
      query,
      value: isEditing ? query : value,
      autoFocus,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      isLoading: loading,
      alignInputTextToCheckMark,
    };

    return (
      <FundTypeaheadContainer className={className}>
        <StyledTypeaheadMenu {...menuProps} hideButton={hideButton} setInputRef={(element) => (this.input = element)} />
        <SearchIcon type="search" />
      </FundTypeaheadContainer>
    );
  }
}

const FundTypeaheadContainer = styled.div`
  position: relative;
  height: 32px;
  padding: 0 10px;
`;

export const StyledTypeaheadMenu = styled(TypeaheadMenu)<TypeaheadMenuProps<OptionValue> & Partial<FundTypeaheadProps>>`
  border: none;
  box-shadow: none;
  height: 28px;
  min-width: 100%;
  flex: 1;
  background: transparent;
  margin-top: 3px;
  margin-bottom: 3px;

  section {
    border: none;
    > div {
      padding-left: 0;
      height: 28px;

      > div {
        min-height: inherit;
        height: inherit;
      }

      /*
       * To avoid the following kind of rules one of three things needs
       * to happen:
       * - The library component structure should be simplified
       * - The library component should accept classnames for all key
       *   inner elements
       * and pass them in accordingly via the className prop
       * - The styling framework should be reconsidered
       */
      > div > div > div > div > div {
        &:hover {
          background: ${GetColor.PaleGrey};
        }
      }
    }

    & + div {
      // menu
      max-height: 300px;
    }

    & + div > div {
      // menu option
      > div {
        position: relative;
        height: 50px;
      }

      &:hover {
        background: ${GetColor.PaleGrey};
      }
    }
  }

  button {
    ${(props) =>
      props.hideButton &&
      `
      display: none;
    `} height: 28px;
    min-height: inherit;
    min-width: inherit;
    padding: 0 25px 0 20px;
    border: none;
    background: ${GetColor.Primary.Dark};
    color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;

    &:after {
      content: '';
      position: absolute;
      border-top: 5px solid white;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      right: 8px;
    }
  }

  input {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid ${GetColor.PaleGrey};
    padding: 3px 30px;
    ${({ alignInputTextToCheckMark }) => alignInputTextToCheckMark && 'padding-left: 10px;'}

    &:focus,
    &:active {
      box-shadow: 0 0 4px 0 ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.4)};
      border: solid 1px ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.6)};
    }
  }
`;

const StyledOption = styled.div`
  position: relative;
  padding-left: 20px;
`;

const ExistingOption = styled(StyledOption)`
  display: block;
  width: 100%;
  div {
    display: inline-block;
  }
  margin-top: 5px;
`;

const HighlighterContainer = styled.div`
  width: 75%;
  > div {
    width: 100%;
  }
  > div > span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }
`;

const OptionIconWrapper = styled.span`
  display: inline-block;
  color: ${GetColor.Grey};
  font-size: 12px;
  float: right;
`;

const StyledYearRange = styled.span`
  color: ${GetColor.DarkGrey};
  line-height: 1;
  font-size: 10px;
  margin-left: 5px;
`;

const NewInstrumentOption = styled(StyledOption)`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${GetColor.HighlightDark};

  > div:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    font-weight: bold;
  }
`;

const NewInstrumentLabel = styled.span`
  font-weight: normal;
  color: ${GetColor.DarkGrey};
`;

const ExistingInvestmentsLabel = styled.div`
  font-size: 12px;
  color: ${GetColor.HintGrey};
  padding-left: 30px;
  background-color: ${GetColor.White} !important;
`;

const SelectedIcon = styled(Icon)<{ top?: number }>`
  position: absolute;
  left: 0;
  ${({ top }) => (top ? `top: ${top}px` : undefined)};
  font-weight: normal;
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  color: ${GetColor.DarkGrey};
  font-size: 14px;
  font-weight: normal;
  right: 10px;
  top: 12px;
`;

export default withTheme(FundTypeahead);
