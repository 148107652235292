import { compact } from 'lodash';

import type { Fund, ItemId, LibrarySearchResult, PrivateAssetSearchModeEnum } from 'venn-api';
import { searchHelpArticles } from 'venn-api';
import { analyticsService, getCreatedBy, logExceptionIntoSentry } from 'venn-utils';

import type { SearchFilter, SearchMenuItem, SearchMenuItemCategory, SearchType } from './types';
import {
  fundToSearchMenuItem,
  getEntityFilters,
  getInvestmentSources,
  getPrivateAssetSearchMode,
  mapItemIds,
  mapSearchResultToAnalysisSubject,
  sortDefaultResults,
  tagsInSearchCheck,
} from './utils';

// TODO: add some caching using react query
export const searchHelp = async (query: string, onResults: (items: SearchMenuItem[], totalResults: number) => void) => {
  const articleResults = await searchHelpArticles({ name: query, size: 10 });
  const totalResults = articleResults.content.total;
  onResults(
    articleResults.content.results.map((item) => ({ category: 'article', label: item.title, helpArticle: item })),
    totalResults,
  );
};

// TODO: add some caching using react query
export const search = async (
  query: string,
  /**
   * On every new search this method is queried with the formatted search result data.
   * The parent component can use it to store the data in its state.
   */
  onResults: (items: SearchMenuItem[], totalResults: number) => void,
  searchApi: SearchType,
  getDefaultInvestments: () => Promise<Fund[]>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  location: string,
  selectedFilters: SearchFilter[],
  setOpenedTags: (items: SearchMenuItem[] | []) => void,
  privateAssetSearchModeDefault: PrivateAssetSearchModeEnum,
  excludedItems?: ItemId[],
  investmentsOnly?: boolean,
  portfoliosOnly?: boolean,
  defaultSearch?: boolean,
  showDefaultSearch?: boolean,
  proxyable?: boolean,
  placeholderIds?: ItemId[],
  includeTagsSearch?: boolean,
) => {
  // If we are not showing default view results, don't make a request
  if (defaultSearch && !showDefaultSearch) {
    return;
  }
  try {
    setLoading(true);

    const { fundIds: excludedFundIds, portfolioIds: excludedPortfolioIds } = mapItemIds(excludedItems);
    const { fundIds: placeholderFundIds, portfolioIds: placeholderPortfolioIds } = mapItemIds(placeholderIds);
    const selectedItemTypes = compact(selectedFilters.map((filter) => filter.itemType));
    const selectedQuickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
    const selectedPrivateAssetSearchModes = compact(selectedFilters.map((filter) => filter.privateAssetSearchMode));
    const privateAssetSearchMode = getPrivateAssetSearchMode(
      selectedPrivateAssetSearchModes,
      privateAssetSearchModeDefault,
    );

    const recentlyViewed = defaultSearch && !placeholderIds;
    const response = await searchApi({
      name: query,
      recentlyViewed,
      entityFilters: getEntityFilters(selectedItemTypes, investmentsOnly, portfoliosOnly),
      excludedFundIds,
      excludedPortfolioIds,
      privateAssetSearchMode,
      proxyable,
      pageSize: 10,
      createdBy: getCreatedBy(selectedQuickFilters),
      investmentSources: getInvestmentSources(selectedItemTypes),
      fundIds: defaultSearch ? placeholderFundIds : undefined,
      portfolioIds: defaultSearch ? placeholderPortfolioIds : undefined,
      includeTagsSearch: tagsInSearchCheck(
        !!includeTagsSearch,
        !!defaultSearch,
        selectedItemTypes,
        placeholderIds,
        privateAssetSearchMode,
      ),
    });
    const results: LibrarySearchResult = response.content;

    if (!defaultSearch) {
      analyticsService.searchQueryEntered({
        query: query ?? '',
        totalResults: results.total,
        visibleResults: results.results.length,
        location,
        quickFilters: selectedQuickFilters,
        typeFilters: selectedItemTypes,
      });
    }

    if (defaultSearch && !results?.results.length && !portfoliosOnly) {
      const sampleInvestments = await getDefaultInvestments();
      const sampleItems = sampleInvestments.map(fundToSearchMenuItem);
      onResults(sampleItems, sampleItems.length);
    } else {
      let type: SearchMenuItemCategory | undefined;
      if (defaultSearch) {
        sortDefaultResults(results, placeholderIds);
        type = recentlyViewed ? 'recent' : 'saved';
      }
      onResults(mapSearchResultToAnalysisSubject({ result: results, type }), results.total);
      setOpenedTags([]);
    }
    setLoading(false);
  } catch (e) {
    if (e.name === 'AbortError') {
      return;
    }
    logExceptionIntoSentry(e);
  }
};
