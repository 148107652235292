import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Icon, Tooltip } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import ToolbarButton from '../toolbar-button/ToolbarButton';
import type { OptimizationErrorType } from '../contexts/optimal-portfolio-context';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import type { ManageDataState } from 'venn-utils';
import { getPortfolioLabPath, MANAGE_DATA_SECTION, navigateToManageDataPage } from 'venn-utils';

export const qaApplyAllocationsClass = 'qa-apply-constraints';
export const qaConstraintsButtonClass = 'qa-constraints-button';

interface OptimizationConstraintsButtonProps {
  portfolio: Portfolio;
  error?: OptimizationErrorType;
  showRefresh?: boolean;
  onRefresh?: () => void;
  hasComparison: boolean;
  applyCompareAllocationsTooltip: string;
  onApplyComparisonAllocationsToCurrent?: () => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  portfolio,
  error,
  showRefresh,
  onRefresh,
  hasComparison,
  applyCompareAllocationsTooltip,
  onApplyComparisonAllocationsToCurrent,
}: OptimizationConstraintsButtonProps) => {
  const cogClickDestination = isNil(error) || error === 'OPTIMIZATION_FAILED' ? 'PORTFOLIO_POLICY' : 'PORTFOLIO_LAB';

  const constraintsTooltipContent = (
    <>
      {error && (
        <ErrorMessage>
          {error === 'OPTIMIZATION_FAILED'
            ? 'Portfolio Lab was unable to find a solution. Try loosening allocation and/or factor constraints in your Portfolio Policy.'
            : "It wasn't possible to determine your optimization target constraints. Go to Portfolio Lab to set them and re-run optimization."}
        </ErrorMessage>
      )}
      <div>
        {cogClickDestination === 'PORTFOLIO_LAB' ? 'Optimize in Portfolio Lab' : 'Adjust Portfolio Policy constraints.'}
      </div>
    </>
  );

  const history = useHistory<ManageDataState>();
  const onRedirect = (destination: 'PORTFOLIO_POLICY' | 'PORTFOLIO_LAB') => {
    if (destination === 'PORTFOLIO_POLICY') {
      navigateToManageDataPage(
        history,
        { portfolioId: portfolio.id },
        'Analysis',
        false,
        MANAGE_DATA_SECTION.PORTFOLIO_POLICY,
        history.location.state,
      );
    } else {
      history.push(getPortfolioLabPath(portfolio.id));
    }
  };

  return showRefresh ? (
    <CondensedButtonsGroup>
      <Tooltip maxWidth={200} content={<CenteredTooltipContent>{constraintsTooltipContent}</CenteredTooltipContent>}>
        <CondensedButton
          onClick={() => onRedirect(cogClickDestination)}
          error={!!error}
          className={qaConstraintsButtonClass}
        >
          <Icon type="cog" />
        </CondensedButton>
      </Tooltip>
      <Tooltip
        maxWidth={200}
        content={
          <CenteredTooltipContent>
            <div>Rerun optimization</div>
          </CenteredTooltipContent>
        }
      >
        <CondensedButton dominant onClick={onRefresh} className="qa-optimization-refresh">
          <Icon type="sync" />
        </CondensedButton>
      </Tooltip>
    </CondensedButtonsGroup>
  ) : (
    <CondensedButtonsGroup>
      <Tooltip
        maxWidth={200}
        content={<CenteredTooltipContent>{applyCompareAllocationsTooltip}</CenteredTooltipContent>}
      >
        <CondensedButton
          className={qaApplyAllocationsClass}
          onClick={onApplyComparisonAllocationsToCurrent}
          disabled={!hasComparison}
          highlighted
        >
          <FlippedIcon type="sign-out-alt" />
        </CondensedButton>
      </Tooltip>
      <Tooltip maxWidth={200} content={<CenteredTooltipContent>{constraintsTooltipContent}</CenteredTooltipContent>}>
        <CondensedButton
          className={qaConstraintsButtonClass}
          error={!!error}
          onClick={() => onRedirect(cogClickDestination)}
          highlighted
        >
          <Icon type="cog" />
        </CondensedButton>
      </Tooltip>
    </CondensedButtonsGroup>
  );
};

export const CompareColumnButton = styled(ToolbarButton)`
  height: 40px;
  border: none;
  border-radius: 0;

  outline: none;
  padding: 0;
  margin: 0;
`;

const CenteredTooltipContent = styled.div`
  text-align: center;
  padding: 10px;
`;

const ErrorMessage = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const CondensedButtonsGroup = styled.div`
  display: flex;
`;

const CondensedButton = styled(CompareColumnButton)<{
  dominant?: boolean;
  error?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
}>`
  width: 52px;
  min-width: 52px;
  max-width: 52px;
  border-top: 1px solid ${GetColor.Grey};
  i {
    font-size: 15px;
  }
  ${({ highlighted }) =>
    !highlighted &&
    css`
      border-left: 1px solid ${GetColor.Grey};
    `}
  &:hover {
    border-top: 1px solid ${GetColor.Grey};
    ${({ highlighted }) =>
      !highlighted &&
      css`
        border-left: 1px solid ${GetColor.Grey};
      `}
  }

  ${({ dominant, error, highlighted, disabled }) => css`
    background-color: ${error
      ? GetColor.Error
      : disabled
        ? GetColor.MidGrey2
        : highlighted
          ? GetColor.HighlightDark
          : dominant
            ? GetColor.Primary.Main
            : GetColor.White};
    i {
      color: ${error || disabled || highlighted ? GetColor.White : dominant ? GetColor.Black : GetColor.Primary.Dark};
      ${dominant && 'font-size: 13px;'}
    }

    &:hover {
      background-color: ${error
        ? GetColor.Error
        : disabled
          ? GetColor.MidGrey2
          : highlighted
            ? GetColor.HighlightDark
            : dominant
              ? GetColor.Primary.Dark
              : GetColor.White};
      i {
        color: ${(dominant && !error) || disabled || highlighted ? GetColor.White : GetColor.Primary.Main};
        ${dominant && 'font-size: 13px;'}
      }
    }
  `}
`;

const FlippedIcon = styled(Icon)`
  transform: rotate(180deg);
  margin-top: 1px;
`;
