import React from 'react';
import { StyledErrorState } from '../StyledErrorState';
import { requiresInfographic, useDebugValue } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockSettings } from 'venn-state';
import type { ErrorWrapper } from './errorWrapperTypes';

export const InfoGraphicTypeErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const needsInfographic = requiresInfographic(customBlockType);
  const selectedInfoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const missingInfoGraphicType = needsInfographic && !selectedInfoGraphicType;
  const forceMissingInfoGraphicTypeError = useDebugValue('debugBlockErrorState') === 'MissingInfoGraphicType';

  if (forceMissingInfoGraphicTypeError || missingInfoGraphicType) {
    return (
      <StyledErrorState selectedRefId={blockId} header="Missing graphic format" message="Please select a format." />
    );
  }

  const infoGraphicType = needsInfographic ? selectedInfoGraphicType : 'DEFAULT';
  const invalidFormatSelected = infoGraphicType
    ? !blockSetting.supportedGraphicTypes.find((supportedType) => supportedType === infoGraphicType)
    : true;
  if (needsInfographic && invalidFormatSelected) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header={`The graphic format ${infoGraphicType} is no longer supported`}
        message="Please select a different format."
      />
    );
  }

  return children;
};
