import React from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu, GetColor } from 'venn-ui-kit';

interface NotablePeriodPickerProps {
  items: DropMenuItem<number>[];
  onAdd(periodId: number): void;
}

const NotablePeriodPicker: React.FunctionComponent<React.PropsWithChildren<NotablePeriodPickerProps>> = (props) => {
  return (
    <PeriodPickerContainer>
      <AddText className="hover-hide">Add another period...</AddText>
      <DropMenu
        items={props.items}
        placeholder="Select a historical period"
        className="dropdown hover-show"
        onChange={(item) => props.onAdd(item.value)}
        searchable
        minimumItemsToTrigger={1}
      />
    </PeriodPickerContainer>
  );
};

const AddText = styled.div`
  color: ${GetColor.HintGrey};
  height: 34px;
  position: absolute;
  line-height: 34px;
  &:hover .hover-show {
    opacity: 1;
  }
  &:hover .hover-hide {
    opacity: 0;
  }
`;

const PeriodPickerContainer = styled.div`
  width: 400px;
`;

export default NotablePeriodPicker;
