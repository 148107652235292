import type { Descendant } from 'slate';
import { createEditor, Editor, Element as SlateElement, Transforms } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import type { Property } from 'csstype';
import type React from 'react';
import { pxToPt } from 'venn-utils';

export enum SupportedMarks {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
}

export enum SupportedBlock {
  H1 = 'heading-one',
  H2 = 'heading-two',
  NUMBERED_LIST = 'numbered-list',
  BULLETED_LIST = 'bulleted-list',
  LIST_ITEM = 'list-item',
  PARAGRAPH = 'paragraph',
}

export enum SupportedAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface TextControlsState {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  textAlign: SupportedAlignment;
  bulletedList: boolean;
  numberedList: boolean;
  fontSize?: number;
  fontSizePt?: number;
  fontSizeLegacy?: number;
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export type EditorType = Editor;
export type EditorValue = Descendant[];

export const createReactEditor = () => withReact(withHistory(createEditor()));

export const calculateTextControlsState = (editor: Editor): TextControlsState => ({
  bold: isMarkActive(editor, SupportedMarks.BOLD),
  italic: isMarkActive(editor, SupportedMarks.ITALIC),
  underline: isMarkActive(editor, SupportedMarks.UNDERLINE),
  textAlign: isAlignmentActive(editor, SupportedAlignment.CENTER)
    ? SupportedAlignment.CENTER
    : isAlignmentActive(editor, SupportedAlignment.RIGHT)
      ? SupportedAlignment.RIGHT
      : SupportedAlignment.LEFT,
  bulletedList: isBlockActive(editor, SupportedBlock.BULLETED_LIST),
  numberedList: isBlockActive(editor, SupportedBlock.NUMBERED_LIST),
  fontSize: getFontSize(editor),
  fontSizePt: getFontSizePt(editor),
  fontSizeLegacy: getFontSizeLegacy(editor),
});

export const toggleBlock = (editor: Editor, format: SupportedBlock) => {
  const wasActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });

  const replaceType = wasActive ? SupportedBlock.PARAGRAPH : isList ? SupportedBlock.LIST_ITEM : format;

  const newProperties: Partial<SlateElement> = {
    type: replaceType,
  };

  Transforms.setNodes(editor, newProperties);

  if (!wasActive && isList) {
    const block = { type: format, children: [] };

    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: Editor, format: SupportedMarks) => {
  const wasActive = isMarkActive(editor, format);

  if (wasActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (editor: Editor, format: SupportedBlock) => {
  const { selection } = editor;

  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export const isMarkActive = (editor: Editor, format: SupportedMarks) => {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const DEFAULT_ALIGNMENT = 'left';

export const toggleAlignment = (editor: Editor, alignment: Property.TextAlign) => {
  const wasActive = isAlignmentActive(editor, alignment);
  const newProperties: Partial<SlateElement> = {
    alignment: wasActive ? DEFAULT_ALIGNMENT : alignment,
  };
  Transforms.setNodes(editor, newProperties, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
  });
};

export const getFontSize = (editor: Editor) => {
  const marks = Editor.marks(editor);
  return marks?.fontSize;
};

export const getFontSizePt = (editor: Editor) => {
  const marks = Editor.marks(editor);
  return marks?.fontSizePt;
};

export const getFontSizeLegacy = (editor: Editor) => {
  const marks = Editor.marks(editor);
  return marks?.fontSizeLegacy;
};

export const changeFontSizePts = (editor: Editor, size: number | string) => {
  Editor.addMark(editor, 'fontSizePt', size);
  Editor.removeMark(editor, 'fontSizeLegacy');
  Editor.removeMark(editor, 'fontSize');
};

export const isAlignmentActive = (editor: Editor, alignment: Property.TextAlign) => {
  const { selection } = editor;

  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      // Exclude list parent
      !LIST_TYPES.includes(n.type) &&
      (n.alignment === alignment || (n.alignment === undefined && alignment === DEFAULT_ALIGNMENT)),
  });

  return !!match;
};

export const onPaste = (event: React.ClipboardEvent<HTMLDivElement>, editor: Editor) => {
  // Default is prevented to stop pasting happening twice
  event.preventDefault();
  event.clipboardData.types.includes('application/x-slate-fragment')
    ? editor.insertFragmentData(event.clipboardData)
    : editor.insertTextData(event.clipboardData);
};

/** Provides a multiplier that should be used with font sizes in the rich text block, as part of custom font size migration. */
export const useFontSize = (
  settings:
    | {
        fontSize?: number;
        fontSizePt?: number;
        fontSizeLegacy?: number;
      }
    | undefined,
) => {
  if (!settings) {
    return undefined;
  }
  const { fontSize, fontSizePt, fontSizeLegacy } = settings;
  if (fontSizePt) {
    return fontSizePt;
  }
  if (fontSizeLegacy) {
    return pxToPt(fontSizeLegacy * 0.7);
  }
  return fontSize ? pxToPt(fontSize) : undefined;
};
