import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { compact, debounce } from 'lodash';

import type { ItemId, PrivateAssetSearchModeEnum } from 'venn-api';
import { globalSearch, searchAnalysisView } from 'venn-api';
import {
  analyticsService,
  getCreatedByTrackingField,
  getDefaultInvestmentSearchResults,
  useApi,
  useCallIfMounted,
} from 'venn-utils';

import type { SearchFilter, SearchMenuItem } from './types';
import { search, searchHelp } from './search';

import { getItemTrackingId, getItemType } from './components/shared';
import useTagsDropDown from './useTagsDropDown';
import { isSavedViews, viewFilters, isHelpArticles } from './components/QuickFilterBar';
import { getExcludedAnalysisTypes } from './getExcludedAnalysisTypes';
import { UserContext } from '../contexts';

const useSearchMenu = ({
  onSelected,
  location,
  selectedFilters,
  menuIsOpen,
  excludedItems,
  investmentsOnly,
  portfoliosOnly,
  selectedValue,
  showRecentlyAnalyzed,
  proxyable,
  privateAssetSearchMode,
  initialQuery = '',
  placeholderIds,
  includeTags = true,
}: {
  onSelected: (result: SearchMenuItem) => void;
  location: string;
  selectedFilters: SearchFilter[];
  menuIsOpen: boolean;
  excludedItems?: ItemId[];
  investmentsOnly?: boolean;
  portfoliosOnly?: boolean;
  selectedValue?: string;
  showRecentlyAnalyzed?: boolean;
  privateAssetSearchMode: PrivateAssetSearchModeEnum;
  proxyable?: boolean;
  initialQuery?: string;
  placeholderIds?: ItemId[];
  includeTags?: boolean;
}) => {
  const [query, setQuery] = useState(initialQuery);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchMenuItem[]>([]);
  const [totalResults, setTotalResults] = useState<number>();
  const searchApi = useApi(globalSearch);
  const getDefaultInvestments = useApi(getDefaultInvestmentSearchResults);
  const { hasPermission } = useContext(UserContext);
  const isFirstSearchRef = useRef(true);

  /* When new results are loaded. Propagate them to the onSearchResults callback */
  const onResults = useCallIfMounted(
    useCallback((searchMenuItems: SearchMenuItem[], newTotalResults?: number) => {
      setResults(searchMenuItems);
      setTotalResults(newTotalResults);
    }, []),
  );

  const safeSetLoading = useCallIfMounted(setLoading);

  const debouncedSearchRef = useRef(
    debounce(async (...args: Parameters<typeof search>) => {
      await search(...args);
    }, 200),
  );

  const debouncedSearchHelpRef = useRef(
    debounce(async (...args: Parameters<typeof searchHelp>) => {
      await searchHelp(...args);
    }, 500),
  );

  const onSearch = useCallback((newSearch: string) => {
    setQuery(newSearch);
  }, []);

  const onSelect = useCallback(
    (result: SearchMenuItem) => {
      onSelected(result);
      setQuery('');
      trackSelectionMade(result, results, location, query, selectedFilters, totalResults);
    },
    [location, onSelected, query, results, totalResults, selectedFilters],
  );

  const { onTagsDropdown, onTagsClose, selectedTagDropdowns, openedTags, setOpenedTags, onCloseAllTags } =
    useTagsDropDown({
      results,
      onResults,
    });

  const refetchSearch = useCallback(async () => {
    const showViews = isSavedViews(selectedFilters);
    if (showViews) {
      const { content } = await searchAnalysisView({
        pageSize: 10,
        name: query ?? '',
        page: 1,
        excludedAnalysisViewTypes: getExcludedAnalysisTypes(hasPermission),
        includeAllInWorkspace: !selectedFilters.includes(viewFilters.SAVED_BY_ME),
        vennTemplate: selectedFilters.includes(viewFilters.VENN_PROVIDED),
      });
      onResults(
        content.results.map((item) => ({ viewInfo: item, category: 'view', label: item.name })),
        content.totalResults,
      );

      return;
    }

    if (isHelpArticles(selectedFilters)) {
      const isFirstSearch = isFirstSearchRef.current;
      isFirstSearchRef.current = false;
      (isFirstSearch ? searchHelp : debouncedSearchHelpRef.current)?.(query, onResults);
      return;
    }

    // We only show the default search if there is no query and filters selected
    const defaultSearch = !query && !selectedFilters.length;
    // If we are not showing default results, reset the results to empty
    if (defaultSearch && !showRecentlyAnalyzed) {
      onResults([]);
    }

    const isFirstSearch = isFirstSearchRef.current;
    isFirstSearchRef.current = false;
    (isFirstSearch ? search : debouncedSearchRef.current)?.(
      defaultSearch ? '' : query,
      onResults,
      searchApi,
      getDefaultInvestments,
      safeSetLoading,
      location,
      selectedFilters,
      setOpenedTags,
      privateAssetSearchMode,
      excludedItems,
      investmentsOnly,
      portfoliosOnly,
      defaultSearch,
      showRecentlyAnalyzed,
      proxyable,
      placeholderIds,
      includeTags,
    );
  }, [
    selectedFilters,
    query,
    showRecentlyAnalyzed,
    onResults,
    searchApi,
    getDefaultInvestments,
    safeSetLoading,
    location,
    setOpenedTags,
    privateAssetSearchMode,
    excludedItems,
    investmentsOnly,
    portfoliosOnly,
    proxyable,
    placeholderIds,
    hasPermission,
    includeTags,
  ]);

  useEffect(() => {
    if (menuIsOpen) {
      refetchSearch();
    }
  }, [refetchSearch, selectedValue, menuIsOpen]);

  return {
    query,
    items: results,
    totalResults,
    loading,
    onSearch,
    onSelect,
    onTagsDropdown,
    onTagsClose,
    selectedTagDropdowns,
    openedTags,
    onCloseAllTags,
  };
};

const trackSelectionMade = (
  result: SearchMenuItem,
  searchMenuItems: SearchMenuItem[],
  location: string,
  query: string,
  selectedFilters: SearchFilter[],
  totalResults = 0,
) => {
  const searchResult = result.searchResult;
  const rank = searchMenuItems.findIndex(
    (item) =>
      searchResult?.fundId === item.searchResult?.fundId &&
      searchResult?.portfolioId === item.searchResult?.portfolioId,
  );
  const selectedItemTypes = compact(selectedFilters.map((filter) => filter.itemType));
  const selectedQuickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
  analyticsService.searchSelectionMade({
    location,
    totalResults,
    visibleResults: searchMenuItems.length,
    objectId: getItemTrackingId(result),
    objectType: getItemType(result),
    query,
    queryDriven: !!query,
    createdBy: searchResult ? getCreatedByTrackingField(searchResult) : '',
    rank,
    quickFilters: selectedQuickFilters,
    typeFilters: selectedItemTypes,
  });
};

export default useSearchMenu;
