import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { WorkspaceMember, UserOrgRoleEnum } from 'venn-api';
import { updateUserOrganizationRole } from 'venn-api';
import { DropMenu, GetColor, Icon, WORKSPACE_MANAGEMENT_ARTICLE_HREF } from 'venn-ui-kit';
import { analyticsService, logExceptionIntoSentry, useHasFF, useInitializeFeatures } from 'venn-utils';
import { isNil, sortBy } from 'lodash';
import { ConfirmationModal, UserContext } from 'venn-components';
import { RoleInOrganizationOptionsContext } from './UserRoleInOrganizationOptionsProvider';

const USER_ROLE_DROPMENU_WIDTH = 140;

interface UserRoleInOrganizationMenuProps {
  member: WorkspaceMember;
  onUpdateRole: () => void;
  readOnly?: boolean;
}

export const UserRoleInOrganizationMenu = ({ member, onUpdateRole, readOnly }: UserRoleInOrganizationMenuProps) => {
  const { roleInOrganization, name, userId } = member;
  const { profileSettings } = useContext(UserContext);
  const workspace = profileSettings?.organization?.name ?? 'your workspace';

  const { userRolesInOrgOptions, loadingOptions } = useContext(RoleInOrganizationOptionsContext);
  const menuOptions = useMemo(
    () =>
      loadingOptions || isNil(userRolesInOrgOptions)
        ? []
        : sortBy(
            Object.keys(userRolesInOrgOptions).map((roleId) => ({
              value: roleId,
              label: userRolesInOrgOptions[roleId],
            })),
            'label',
          ),
    [loadingOptions, userRolesInOrgOptions],
  );

  const [confirmationModal, setConfirmationModal] = useState<UserOrgRoleEnum | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setConfirmationModal(undefined);
    setLoading(false);
    setError(undefined);
  }, []);

  const initializeFeatures = useInitializeFeatures();

  const onProceed = useCallback(
    (role: UserOrgRoleEnum) => async () => {
      try {
        setError(undefined);
        setLoading(true);
        await updateUserOrganizationRole(userId, role);

        // If the current user has changed their role, this could effect their features,
        // so re-initialize them
        if (profileSettings?.user.id === userId) {
          initializeFeatures();
        }

        analyticsService.roleChanged({
          role,
          userId,
        });

        onUpdateRole();
        onClose();
      } catch (e) {
        setError("An error occurred updating the user's role.");
        setLoading(false);
        logExceptionIntoSentry(e);
      }
    },
    [userId, onUpdateRole, onClose, profileSettings, initializeFeatures],
  );

  const hasWorkspaceAdminActions = useHasFF('workspace_admin_actions');
  if (!hasWorkspaceAdminActions) {
    return userRolesInOrgOptions?.[roleInOrganization] ?? null;
  }

  return (
    <>
      <MenuWrapper>
        <DropMenu
          disabled={readOnly}
          items={menuOptions}
          selected={roleInOrganization}
          className="narrow-dropdown"
          onChange={({ value }) => {
            if (value !== roleInOrganization && userRolesInOrgOptions?.[value] !== undefined) {
              setConfirmationModal(value as UserOrgRoleEnum);
            }
          }}
        />
      </MenuWrapper>
      {confirmationModal && (
        <ConfirmationModal
          subhead={`Are you sure you want to change ${name}'s role in the current workspace to "${
            userRolesInOrgOptions?.[confirmationModal] ?? confirmationModal
          }"? `}
          text={
            <HelpLink target="_blank" rel="noopener noreferrer" href={WORKSPACE_MANAGEMENT_ARTICLE_HREF}>
              <HelpIcon type="question-circle" />
              {` This will affect the scope of the actions this user can take inside ${workspace}.`}
            </HelpLink>
          }
          onCancel={onClose}
          onProceed={onProceed(confirmationModal)}
          error={error}
          proceedDisabled={loading}
        />
      )}
    </>
  );
};

export default UserRoleInOrganizationMenu;

const HelpIcon = styled(Icon)`
  padding-right: 5px;
`;

const HelpLink = styled.a`
  color: ${GetColor.Black};
  display: flex;
`;

const MenuWrapper = styled.div`
  max-width: ${USER_ROLE_DROPMENU_WIDTH}px;
  .narrow-dropdown {
    min-width: ${USER_ROLE_DROPMENU_WIDTH}px;
  }
`;
