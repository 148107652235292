import type { CellClassParams, ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { capitalize, compact, get, has, last } from 'lodash';
import type { FrequencyEnum } from 'venn-api';
import {
  type CustomBlockTypeEnum,
  type ExcelCell,
  isPublicPrivateAssetGrowthBlock,
  Dates,
  isPrivatesBlock,
} from 'venn-utils';
import { getBenchmarkTypeText } from '../../benchmark-type-dropdown/benchmarkTypeUtils';
import { BOLD_CLASS, CURRENCY_CLASS_PREFIX, PERCENTAGE_CLASS } from '../customAnalysisContants';
import type { CustomRow, CustomRowData, ExportMetaData } from '../types';
import { PGA_RANK_DISCLAIMER } from '../components/charts/peer-groups/utils';

const THIRD_PARTY_DATA_NOT_EXPORTED_MESSAGE = 'Analyses that contain third-party returns data cannot be exported.';

const getValueGetterParams = <T>(rowData: T | undefined, colDef: ColDef<T> | undefined, gridRef?: AgGridReact | null) =>
  ({
    data: rowData,
    colDef,
    api: gridRef?.api,
    context: gridRef?.context,
  }) as ValueGetterParams;

const getCellGetterParams = <T>(rowData: T | undefined, colDef: ColDef<T>, gridRef?: AgGridReact | null) =>
  getValueGetterParams(rowData, colDef, gridRef) as unknown as CellClassParams;

/**
 * If any row provides an additional exportableName property, it should be used instead of name (which in some cases can be a React element).
 *
 * @param rowData Current row data
 * @param field Field of row object to get cell data from
 */
const checkNameField = <T extends CustomRowData>(rowData: T | undefined, field: string) => {
  if (field === 'name' && has(rowData, 'exportableName')) {
    return 'exportableName';
  }

  return field;
};

export const getCellValue = <T extends CustomRowData>(
  rowData: T | undefined,
  colDef: ColDef<T>,
  gridRef?: AgGridReact | null,
): ExcelCell => {
  // TODO(VENN-22579): we may be able to use getValue from the grid API rather than implementing our own getCellValue functionality.
  const value = (() => {
    if (typeof colDef.valueGetter === 'function') {
      // This is not generally safe to do because we're missing multiple props, but it is legacy code.
      // VENN-22579 would supplant this, if implemented.
      return colDef.valueGetter(getValueGetterParams(rowData, colDef, gridRef));
    }

    if (typeof colDef.valueGetter === 'string') {
      return get(rowData, colDef.valueGetter);
    }

    if (typeof colDef.field === 'string') {
      return get(rowData, checkNameField(rowData, colDef.field));
    }

    return undefined;
  })();

  // TODO(VENN-22579): This is not generally safe or exhaustive. It doesn't handle cellStyle, cellClassRules, and some cellClass functions might break,
  // because we're not calling colDef.cellClass with all the proper props.
  let cellClass =
    typeof colDef.cellClass === 'function'
      ? colDef.cellClass(getCellGetterParams(rowData, colDef, gridRef))
      : colDef.cellClass;

  if (typeof cellClass === 'string') {
    cellClass = [cellClass];
  }

  const isPercentage = cellClass?.includes(PERCENTAGE_CLASS);
  const isBold = cellClass?.includes(BOLD_CLASS);
  const isItalic = Boolean(rowData?.isMetadata || colDef.cellRendererParams?.isMetadata);
  const currencyClass = (cellClass ?? []).find((cls) => cls.startsWith(CURRENCY_CLASS_PREFIX));
  const currencySymbol = currencyClass?.substring(CURRENCY_CLASS_PREFIX.length);

  return {
    value,
    style: { italic: isItalic },
    percentage: isPercentage,
    currencySymbol,
    bold: isBold,
    digits: 2,
  };
};

export const prependNestedSpaceStr = (level: number, name?: string) => `${new Array(level * 4).join(' ')}${name ?? ''}`;

const getAnalysisPeriod = (startDate?: number, endDate?: number, frequency?: FrequencyEnum) => {
  if (!startDate || !endDate || !frequency) {
    return '--';
  }
  return `${Dates.toDDMMMYYYY(startDate, frequency)} - ${Dates.toDDMMMYYYY(endDate, frequency)}`;
};

const getLabelCell = (label: string) => ({ value: label, bold: true });

export const EMPTY: ExcelCell = { value: null };

export const getMetadataRows = (
  moreCols: ExcelCell[],
  exportSheetName: string,
  exportMetaData?: ExportMetaData,
): ExcelCell[][] =>
  compact([
    [getLabelCell('Name'), { value: exportSheetName }, ...moreCols],
    exportMetaData?.customBlockType == null ||
    (!isPrivatesBlock(exportMetaData?.customBlockType) &&
      !isPublicPrivateAssetGrowthBlock(exportMetaData?.customBlockType))
      ? [
          getLabelCell('Analysis Period'),
          { value: getAnalysisPeriod(exportMetaData?.startDate, exportMetaData?.endDate, exportMetaData?.frequency) },
        ]
      : null,
    exportMetaData?.projectionStartDate
      ? [
          getLabelCell('Projection Start'),
          { value: Dates.toDDMMMYYYY(exportMetaData.projectionStartDate, exportMetaData.frequency) },
        ]
      : null,
    exportMetaData?.simulationStartDate
      ? [
          getLabelCell('Simulation Start Date'),
          { value: Dates.toDDMMMYYYY(exportMetaData.simulationStartDate, exportMetaData.frequency) },
        ]
      : null,
    [getLabelCell('Analysis Frequency'), { value: capitalize(exportMetaData?.frequency) }],
    [getLabelCell('Benchmark Type'), { value: getBenchmarkTypeText(exportMetaData?.benchmarkType) }],
    exportMetaData?.relativeToBenchmark ? [getLabelCell('Relative to Benchmark'), { value: 'On' }] : null,
    exportMetaData?.contributionToPercentage ? [getLabelCell('Percentage Contribution'), { value: 'On' }] : null,
    exportMetaData?.rollingPeriod ? [getLabelCell('Rolling Period'), { value: exportMetaData?.rollingPeriod }] : null,
    exportMetaData?.peerGroupName ? [getLabelCell('Peer Group'), { value: exportMetaData?.peerGroupName }] : null,
    exportMetaData?.hasVennDataModifications
      ? [
          getLabelCell('Data Modifications'),
          {
            value:
              'Contains Venn-Modified data due to missing NAV and/or contains outdated PIC or cumulative distributions. Please check the application to learn more.',
          },
        ]
      : null,
    [],
    [],
    [],
  ]);

export const getHeaderRows = <T>(
  treeData: boolean,
  columnDefs?: (ColDef<T> | ColGroupDef<T>)[],
  autoGroupColumnDef?: ColDef<T>,
) => {
  const excelHeaders: ExcelCell[][] = [[]];

  columnDefs?.forEach((column) => {
    const metadataColumn = Boolean('cellRendererParams' in column && column.cellRendererParams.isMetadata);
    excelHeaders[0].push({ value: column.headerName, bold: true, style: { italic: metadataColumn } });
    'children' in column &&
      column.children?.forEach((child, index) => {
        if (index !== 0) {
          excelHeaders[0].push(EMPTY);
        }
        if (!excelHeaders[1]) {
          excelHeaders[1] = [];
        }
        excelHeaders[1].push({ value: child.headerName, bold: true });
      });
  });

  if (treeData) {
    const groupHeader = { value: autoGroupColumnDef?.headerName ?? 'Group', bold: true };
    // If we have 2 layers of headers (e.g. subject + metric) put header into second row, otherwise into first
    if (excelHeaders[1]) {
      excelHeaders[0] = [EMPTY, ...excelHeaders[0]];
      excelHeaders[1] = [groupHeader, ...excelHeaders[1]];
    } else {
      excelHeaders[0] = [groupHeader, ...excelHeaders[0]];
    }
  }

  return excelHeaders;
};

export const getBodyRows = <T extends CustomRow>(
  treeData: boolean,
  rowData: (T | undefined)[] | undefined | null,
  columnDefs: (ColDef<T> | ColGroupDef<T>)[] | undefined,
  autoGroupColumnDef?: ColDef<T>,
  gridRef?: AgGridReact | null,
): ExcelCell[][] =>
  compact(
    rowData?.map((row) => {
      const excelRow: ExcelCell[] = [];
      if (treeData && row && 'path' in row && row.path.length) {
        const value =
          row && typeof autoGroupColumnDef?.valueGetter === 'function'
            ? autoGroupColumnDef.valueGetter(getValueGetterParams(row, autoGroupColumnDef, gridRef))
            : last(row.path);
        excelRow.push({
          value: prependNestedSpaceStr(row.path.length - 1, value),
          style: { italic: !!row.isMetadata },
          bold: row.isStrategy,
        });
      }

      columnDefs?.forEach((colDef) => {
        if ('children' in colDef && colDef.children) {
          colDef.children.forEach((childColDef, index) => {
            // Only show metadata for the first group cell
            if (row?.isMetadata && index !== 0) {
              excelRow.push(EMPTY);
            } else {
              excelRow.push(getCellValue(row, childColDef, gridRef));
            }
          });
        } else {
          excelRow.push(getCellValue(row, colDef, gridRef));
        }
      });
      return excelRow;
    }),
  );

export const getFooterRows = (customBlockType: CustomBlockTypeEnum): ExcelCell[][] => {
  const excelRow: ExcelCell[] = compact([
    customBlockType === 'PEER_GROUPS'
      ? {
          value: PGA_RANK_DISCLAIMER,
          width: 40,
          wrapText: true,
        }
      : null,
  ]);

  return [excelRow];
};
/**
 * Returns an ExcellCell row containing a message indicating that the export failed due to non-exportable third party data.
 *
 * Generally this should be used where body rows would go if the body rows were possible.
 */
export const getThirdPartyExportMessageRow = () => {
  return [
    // Shift the error message into the second column for visibility.
    EMPTY,
    {
      // We add some styling to help ensure visibility of the full message.
      style: {
        shrinkToFit: true,
        wrapText: true,
      },
      width: THIRD_PARTY_DATA_NOT_EXPORTED_MESSAGE.length,
      value: THIRD_PARTY_DATA_NOT_EXPORTED_MESSAGE,
    },
  ];
};
