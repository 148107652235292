import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { StudioShimmerBlock } from '../../../../studio-blocks';
import { GetColor } from 'venn-ui-kit';

import { type HTMLProps } from '../factory';

export type BlockHeaderElement = React.ElementRef<'div'>;
export interface BlockHeaderProps extends HTMLProps<'div'> {
  inPrintMode: boolean;
  children: React.ReactNode;
}

export const BlockHeader = forwardRef<BlockHeaderElement, BlockHeaderProps>((props) => {
  const { inPrintMode, children } = props;

  return (
    <React.Suspense fallback={<StudioShimmerBlock />}>
      <Wrapper>
        <Container inPrintMode={inPrintMode}>{children}</Container>
      </Wrapper>
    </React.Suspense>
  );
});

BlockHeader.displayName = 'BlockHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{ inPrintMode: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ inPrintMode }) => `${inPrintMode ? '0.7rem 0.7rem 0.3rem 0.7rem' : '13px 15px 5px 15px'}`};

  label {
    font-size: 14px;
    padding-right: 5px;
  }

  border-bottom: 1px solid ${GetColor.Grey};
`;
