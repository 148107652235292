import React, { useMemo } from 'react';
import styled, { css, withTheme } from 'styled-components';
import { createVennPieChartConfig } from '../../../charts/analysis-charts/chart-config-logic';
import type { GeneralChartProps } from './types';
import type { Theme } from 'venn-ui-kit';
import { Icon } from 'venn-ui-kit';
import DownloadableContentBlock from '../../../content-block/DownloadableContentBlock';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { getPieChartData } from '../../logic/pieChartParsers';
import useExportUpdate from '../../logic/useExportUpdate';
import { useRecoilValue } from 'recoil';
import { blockFonts, blockLimitedRequestSubjects, isReportState } from 'venn-state';
import { GraphicSubjectLabel } from './GraphicSubjectLabel';
import { useBlockId } from '../../contexts/BlockIdContext';
import { StudioHighchart } from '../../../highchart/Highchart';

export interface PieChartDisplayProps extends GeneralChartProps {
  theme: Theme;
  inPrintMode?: boolean;
  height?: number;
}

const PieChart = ({
  theme,
  metrics,
  inPrintMode,
  height,
  selectedBlock,
  customBlockType,
  downloadableContentRef,
  responseParser,
  analyses,
  requests,
  selectedRefId,
  ...restProps
}: PieChartDisplayProps) => {
  const blockId = useBlockId();
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const isReport = useRecoilValue(isReportState);
  const subject = useRecoilValue(blockLimitedRequestSubjects(selectedRefId))[0];
  const parsedData = useMemo(
    () => responseParser.parser(selectedBlock, analyses),
    [analyses, responseParser, selectedBlock],
  );
  const excelDataFn = useMemo(() => {
    const excelParser = responseParser.excelParser;
    return excelParser ? () => excelParser(requests, customBlockType, metrics, parsedData) : undefined;
  }, [responseParser, requests, customBlockType, metrics, parsedData]);
  useExportUpdate({
    ...restProps,
    selectedRefId,
    excelDataFn,
  });

  const metric = metrics ? metrics[0] : undefined;
  const metricName = metric?.label;
  const yAxisUnitFormat = metric?.dataType === 'NUMERIC' ? 'ratio' : 'percent';
  const defaultHeader =
    selectedBlock?.relativeToBenchmark && metric?.relativeLabel
      ? metric.relativeLabel
      : !metric
        ? 'Builder Chart'
        : metric.label;
  const series = getPieChartData(theme, customBlockType, metric, parsedData);
  const options = useMemo(() => {
    return createVennPieChartConfig(
      metricName,
      series,
      theme,
      yAxisUnitFormat,
      height,
      legendFont.fontSizePt,
      inPrintMode,
      !isReport,
      undefined,
    );
  }, [metricName, series, theme, yAxisUnitFormat, height, inPrintMode, isReport, legendFont.fontSizePt]);

  const customLegend = !isReport && (
    <Legend>
      {series?.map((s) => (
        <div
          key={s.name ?? `${s.color}` ?? ''}
          style={{ display: 'block', marginRight: '50px', alignSelf: 'flex-start' }}
        >
          <Icon style={{ color: s.color as string }} type="circle" /> {s.legend}
        </div>
      ))}
    </Legend>
  );

  return (
    <DownloadableContentBlock
      header=""
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: (series?.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      <Wrapper customLegend={!isReport}>
        {subject && (isReport || inPrintMode) && <GraphicSubjectLabel subject={subject} />}
        <StudioHighchart options={options} blockId={blockId} style={{ flex: 1 }} />
        {customLegend}
      </Wrapper>
    </DownloadableContentBlock>
  );
};

export default withTheme(PieChart);

const Wrapper = styled.div<{ customLegend?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ customLegend }) =>
    customLegend &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `}
`;

const Legend = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 15px;
  width: 100%;
`;
