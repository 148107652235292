import React from 'react';
import type { CellRendererType, HeaderRendererType, TabularDataRow } from '../types';
import type { BasicTableColumn } from 'venn-components';
import { ColumnAlign } from 'venn-components';
import { compact } from 'lodash';
import type { VennColors } from 'venn-ui-kit';
import { ZIndex } from 'venn-ui-kit';
import SubjectLabel from '../components/SubjectLabel';
import type { AnyDuringEslintMigration, ComparisonSubject } from 'venn-utils';

const formatAccessor = (label: string, colNum: number): string =>
  label
    .toLowerCase()
    .split(' ')
    .map((part) => part.replace(/\W/g, ''))
    .concat([colNum.toString()])
    .join('-');

export const getPivotedColumns = (
  data: TabularDataRow[],
  isLoading: boolean,
  setHovered: (colNum: number, isHovered: boolean, metricIdx?: number) => void,
  cellRenderer: CellRendererType,
  headerRenderer: HeaderRendererType | undefined,
  columnOffset: number,
  onRemoveSubject: (idx: number) => void,
  Colors: VennColors,
): BasicTableColumn<TabularDataRow>[] =>
  compact([
    // If it's the first ever column, fill it with subject names
    columnOffset === 0
      ? {
          label: '',
          accessor: 'comparison-item-name',
          sortable: true,
          sortValueFunc: (row: TabularDataRow) => row?.[0].dataSubject?.index ?? NaN,
          cellStyle: {
            backgroundColor: Colors.White,
            zIndex: ZIndex.Base,
          },
          headerStyle: {
            backgroundColor: Colors.White,
            zIndex: ZIndex.Base,
            boxShadow: `5px 0 5px -5px ${Colors.Grey}`,
          },
          cellRenderer: (rowData: TabularDataRow) => (
            <SubjectLabel
              key={rowData[0].dataSubject!.analysisSubject?.id.toString()}
              idx={rowData[0].dataSubject!.index}
              subject={rowData[0].dataSubject!}
              onRemove={() => onRemoveSubject(rowData[0].dataSubject!.index)}
              onHover={(hovered) => setHovered(rowData[0].dataSubject!.index, hovered)}
            />
          ),
        }
      : null,
    ...data.map((dataRow, colNum) => ({
      label: dataRow.label,
      accessor: formatAccessor(dataRow.label, columnOffset + colNum),
      sortable: true,
      sortValueFunc: (row: TabularDataRow) => row[columnOffset + colNum + (columnOffset === 0 ? 1 : 0)].value,
      align: ColumnAlign.RIGHT,
      cellRenderer: cellRenderer(isLoading, colNum + (columnOffset === 0 ? 1 : 0), columnOffset, dataRow.type),
      headerStyle: {
        maxHeight: 42,
        height: 42,
      },
      headerRenderer: headerRenderer ? headerRenderer(isLoading) : undefined,
      cellStyle: {
        ...dataRow.style,
      },
      cellProps: (row: TabularDataRow) => {
        return {
          onMouseEnter: () =>
            setHovered(row[0].dataSubject!.index, true, columnOffset + colNum - (columnOffset === 0 ? 0 : 1)),
          onMouseLeave: () => setHovered(row[0].dataSubject!.index, false),
        };
      },
    })),
  ] as AnyDuringEslintMigration[]);

export const pivotData = (
  data: TabularDataRow[],
  subjects: ComparisonSubject[],
  columnOffset: number,
  relative: boolean,
  includeSubjects = true,
): TabularDataRow[] => {
  const results: TabularDataRow[] = [];

  for (let i = 0; i < subjects.length; i++) {
    const subjectRow: TabularDataRow = {
      label: includeSubjects ? (subjects[i].analysisSubject?.name ?? '') : '',
      type: 'percentage', // this will be overwritten
      getter: () => undefined,
    };
    let offset = 0;
    if (columnOffset === 0) {
      subjectRow[0] = {
        value: subjects[i].analysisSubject?.name ?? '',
        dataSubject: subjects[i],
      };
      offset = 1;
    }
    for (let j = 0; j < data.length; j++) {
      subjectRow[j + offset] = data[j][i];
      subjectRow.getter = data[j].getter;
    }
    results.push(subjectRow);
  }

  return results;
};
