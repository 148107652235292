import React, { useCallback, useEffect, useState } from 'react';
import type { Portfolio } from 'venn-api';
import type { AllocConstraint } from 'venn-utils';
import { splitAllocationConstraints } from 'venn-utils';
import { LoadingSize } from 'venn-ui-kit';
import AddNewConstraint from './components/AddNewConstraint';
import EditableAllocationConstraint from './components/EditableAllocationConstraint';
import { ConditionalOverlay } from '../../conditional-overlay';
import { RelativeContainer, RelativeSpinner } from '../components/styled';

interface UncontrolledAllocationConstraintsManagementProps {
  portfolio: Portfolio;
  allocationConstraints: AllocConstraint[];
  onUpdateAllocationConstraints: (allocationConstraints: AllocConstraint[]) => Promise<void>;
  addNewConstraintClassName?: string;
  disabled?: boolean;
}

const UncontrolledAllocationConstraintsManagement = ({
  portfolio,
  allocationConstraints,
  onUpdateAllocationConstraints,
  addNewConstraintClassName,
  disabled,
}: UncontrolledAllocationConstraintsManagementProps) => {
  const [isAddingConstraint, setIsAddingConstraint] = useState(false);
  const [isSavingConstraint, setIsSavingConstraint] = useState(false);
  const [isEditingConstraint, setIsEditingConstraint] = useState(false);

  useEffect(() => {
    setIsSavingConstraint(false);
    setIsEditingConstraint(false);
  }, [allocationConstraints]);

  const onNewConstraintsAdded = useCallback(
    async (constraints: AllocConstraint[]) => {
      setIsSavingConstraint(true);
      await onUpdateAllocationConstraints(mergeConstraints(allocationConstraints, constraints, portfolio.id));
      setIsAddingConstraint(false);
    },
    [allocationConstraints, onUpdateAllocationConstraints, portfolio.id],
  );

  const onReplaceConstraint = useCallback(
    async (idx: number, constraints: AllocConstraint[]) => {
      setIsEditingConstraint(true);
      const updatedConstraints = [
        ...allocationConstraints.slice(0, idx),
        ...constraints,
        ...allocationConstraints.slice(idx + 1),
      ];
      await onUpdateAllocationConstraints(mergeConstraints(updatedConstraints, [], portfolio.id));
      setIsEditingConstraint(false);
    },
    [allocationConstraints, onUpdateAllocationConstraints, portfolio.id],
  );

  const onDeleteConstraint = useCallback(
    (constraint: AllocConstraint, idx: number) => {
      onUpdateAllocationConstraints([...allocationConstraints.slice(0, idx), ...allocationConstraints.slice(idx + 1)]);
    },
    [allocationConstraints, onUpdateAllocationConstraints],
  );

  if (allocationConstraints.length === 0 && !isAddingConstraint) {
    return <AddNewConstraint onClick={() => setIsAddingConstraint(true)} />;
  }

  return (
    <div>
      {allocationConstraints.map((constraint, idx) => (
        <EditableAllocationConstraint
          key={getConstraintKey(constraint, idx)}
          existingConstraint={constraint}
          portfolio={portfolio}
          idx={idx + 1}
          onDeleteConstraint={() => onDeleteConstraint(constraint, idx)}
          onCreateConstraint={(constraints: AllocConstraint[]) => onReplaceConstraint(idx, constraints)}
          disabled={disabled}
        />
      ))}
      {(isAddingConstraint || isSavingConstraint) && (
        <RelativeContainer>
          <ConditionalOverlay condition={isSavingConstraint} overlay="">
            <EditableAllocationConstraint
              portfolio={portfolio}
              idx={allocationConstraints.length + 1}
              onCreateConstraint={onNewConstraintsAdded}
              onDeleteConstraint={() => setIsAddingConstraint(false)}
            />
          </ConditionalOverlay>
          {isSavingConstraint && <RelativeSpinner size={LoadingSize.nano} />}
        </RelativeContainer>
      )}
      {!isAddingConstraint && !isEditingConstraint && (
        <AddNewConstraint
          className={addNewConstraintClassName}
          disabled={disabled}
          onClick={() => setIsAddingConstraint(true)}
        />
      )}
    </div>
  );
};

export default UncontrolledAllocationConstraintsManagement;

const mergeConstraints = (
  constraints: AllocConstraint[],
  newConstraints: AllocConstraint[],
  rootId: number,
): AllocConstraint[] => {
  const { portfolio, strategy, investment } = splitAllocationConstraints(constraints, rootId);
  const {
    portfolio: portfolio2,
    strategy: strategy2,
    investment: investment2,
  } = splitAllocationConstraints(newConstraints, rootId);

  return [...portfolio, ...portfolio2, ...strategy, ...strategy2, ...investment, ...investment2];
};

const getConstraintKey = (constraint: AllocConstraint, idx: number): string =>
  `${constraint.allInvestments ? 'allInv' : constraint.items.map((item) => item.id).join(',')}#${
    constraint.condition
  }#${constraint.valueType}#${constraint.value}#${idx}`;
