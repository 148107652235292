import React, { type FC, useContext } from 'react';
import type { DrawdownFactorInfo } from 'venn-api';
import { HighchartZIndex } from 'venn-ui-kit';
import { drawPlotLinePerBar } from '../../../../../factor-correlations-chart/utils';
import type { AxisLabelsFormatterContextObject, SeriesBarOptions, TooltipFormatterContextObject } from 'highcharts';
import { ThemeContext } from 'styled-components';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { VennHighchart } from '../../../../../highchart/Highchart';

const LABEL_WIDTH = 200;
const XAXIS_HEIGHT = 55;

const TOOLTIP_BACKGROUND_COLOR = 'rgba(16, 22, 27, 0.9)';

export interface ReturnsChartProps {
  drawdownFactorInfoList: DrawdownFactorInfo[];
  print?: boolean;
}

export const ReturnsChart: FC<React.PropsWithChildren<ReturnsChartProps>> = ({ drawdownFactorInfoList, print }) => {
  const { Typography, Colors } = useContext(ThemeContext);

  if (!drawdownFactorInfoList || drawdownFactorInfoList.length === 0) {
    return null;
  }
  const barHeight = print ? 24 : 36;
  const categories = drawdownFactorInfoList.map((info) => info.factorName);

  const min = Math.min(
    ...drawdownFactorInfoList.map(({ annualizedReturn }) => annualizedReturn * 100),
    ...drawdownFactorInfoList.map(({ historicalAnnualizedReturn }) => historicalAnnualizedReturn * 100),
  );

  const max = Math.max(
    ...drawdownFactorInfoList.map(({ annualizedReturn }) => annualizedReturn * 100),
    ...drawdownFactorInfoList.map(({ historicalAnnualizedReturn }) => historicalAnnualizedReturn * 100),
  );

  const maxAbsoluteValue = Math.ceil(Math.max(Math.abs(min), Math.abs(max)));

  const series: SeriesBarOptions[] = [
    {
      type: 'bar',
      color: Colors.DataBarColor.LightGold,
      borderWidth: 0,
      pointWidth: barHeight - 5,
      groupPadding: 0,
      pointPadding: 0,
      showInLegend: false,
      borderRadius: 2,
      data: drawdownFactorInfoList.map(({ annualizedReturn }) => annualizedReturn * 100),
    },
  ];

  const config: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: barHeight * drawdownFactorInfoList.length,
      marginLeft: LABEL_WIDTH,
      marginRight: 0,
      marginTop: 0,
      marginBottom: XAXIS_HEIGHT,
      events: {
        render(this: Highcharts.Chart) {
          drawPlotLinePerBar(
            this,
            drawdownFactorInfoList.map((info) => info.historicalAnnualizedReturn),
          );
        },
        load(this: Highcharts.Chart) {
          const [yAxis] = this.yAxis; // eslint-disable-line react/no-this-in-sfc
          // eslint-disable-next-line react/no-this-in-sfc
          this.update({
            yAxis: {
              // TODO: (VENN-20577 / TYPES) can yAxis.max be null? If so, what do we want to do since null + number = NaN? Do we want to instead return null,
              // keep returning NaN, or return something else? Returning null makes the most sense, but we should check there is no behavior change.
              max: (yAxis.max as AnyDuringEslintMigration) + yAxis.tickInterval,
            },
          });
        },
      },
    },
    title: { text: undefined },
    xAxis: {
      gridLineWidth: 1,
      tickLength: 200,
      tickColor: Colors.LightGrey2,
      lineWidth: 0,
      categories,
      labels: {
        align: 'right',
        autoRotation: false,
        style: {
          fontSize: print ? '10px' : '14px',
          fontFamily: Typography.fontFamily,
        },
      },
    },
    yAxis: {
      gridLineWidth: 1,
      showLastLabel: false,
      title: { text: null },
      labels: {
        autoRotation: false,
        y: barHeight - 6,
        formatter(this: AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${this.value}%`;
        },
        style: {
          fontSize: print ? '10px' : '14px',
          fontFamily: Typography.fontFamily,
        },
      },
      min: -maxAbsoluteValue,
      max: maxAbsoluteValue,
      plotLines: [
        {
          id: 'yaxis-zero-line',
          color: Colors.MidGrey2,
          width: 1,
          zIndex: HighchartZIndex.PlotBand.AboveChart,
          value: 0,
        },
      ],
    },
    credits: { enabled: false },
    tooltip: {
      animation: false,
      backgroundColor: TOOLTIP_BACKGROUND_COLOR,
      borderWidth: 0,
      style: {
        color: Colors.White,
      },
      useHTML: true,
      formatter(this: TooltipFormatterContextObject) {
        const { point } = this;
        const { category } = point;
        const index = (point as AnyDuringEslintMigration).index;

        const factorReturn = drawdownFactorInfoList[index].annualizedReturn * 100;
        const historicalAvg = drawdownFactorInfoList[index].historicalAnnualizedReturn * 100;

        return `<b>${category}</b><br/>
          Return during drawdown: <b>${factorReturn.toFixed(2)}%</b><br/>
          Historical avg: <b>${historicalAvg.toFixed(2)}%</b>
        `;
      },
    },
    series,
  };

  return <VennHighchart className="qa-chart-container" options={config} />;
};

export default ReturnsChart;
