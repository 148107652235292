import type { CSSProperties } from 'react';
import React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import type { FontAwesomePrefixes } from 'venn-utils';
import { buttonize } from 'venn-utils';
import { GetColor } from '../../style/color';
import noop from 'lodash/noop';

export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

export interface IconProps {
  /**
   * Icon type. See [Font Awesome](https://fontawesome.com/)
   * for a list of icon types
   */
  type?: string;
  /** Additional classes */
  className?: string;
  /** [Font Awesome](https://fontawesome.com/) prefixes */
  prefix?: FontAwesomePrefixes;
  /** Styles added inline to the icon */
  style?: CSSProperties;
  /** TabIndex, set to -1 to not tab into */
  tabIndex?: number;
  onClick?(event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void;
  /** Text to show on top of the icon */
  overlayText?: string;
  tooltip?: string;
  disabled?: boolean;
  iconLabel?: string;
  buttonLabel?: string;
  'data-testid'?: string;
}

export const Icon = ({
  type,
  style,
  className,
  onClick,
  prefix,
  tabIndex,
  overlayText,
  tooltip,
  disabled,
  iconLabel,
  buttonLabel,
  'data-testid': testId,
}: IconProps) => {
  const iconClass = `${prefix || 'fas'} fa-${type}`;
  const stackClass = overlayText ? 'fa-stack-1x' : '';
  const icon = (
    <i
      data-testid={testId ?? 'icon'}
      className={classNames(iconClass, className, stackClass)}
      style={style}
      title={tooltip}
      aria-label={iconLabel}
    />
  );
  const contents = overlayText ? (
    <Stack>
      {icon}
      <OverlayText className="fa-stack-1x fa-inverse">{overlayText}</OverlayText>
    </Stack>
  ) : (
    icon
  );
  if (onClick) {
    return (
      <ButtonIcon
        className="button-icon"
        {...buttonize<HTMLDivElement>(disabled ? noop : onClick, false)}
        aria-label={buttonLabel}
        data-testid="icon-on-click-trigger"
        tabIndex={tabIndex}
        disabled={disabled}
      >
        {contents}
      </ButtonIcon>
    );
  }
  return contents;
};

export default Icon;

const OverlayText = styled.span`
  font-size: 11px;
  font-weight: bold;
`;

const Stack = styled.div`
  display: inline-block;
  position: relative;
  width: inherit;
  height: inherit;
  line-height: normal;
`;

const ButtonIcon = styled.div<{ disabled?: boolean }>`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  font-size: 100%;

  &.focus-visible {
    box-shadow:
      black 0px 0px 0px 2px,
      rgb(34, 34, 34) 0px 0px 0px 4px;
    transition: box-shadow 0.2s ease 0s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.Grey};
      cursor: not-allowed;
    `}
`;
