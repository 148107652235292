import React from 'react';
import styled, { css } from 'styled-components';
import { Body2, GetColor } from 'venn-ui-kit';
import type { ShockUnitsEnum } from 'venn-api';
import { getInRangeValue, formattedNumber } from './logic';

type InputRangeTooltip = {
  renderHeader: () => React.ReactNode;
  rangeLegend: string;
  meanLegend: string;
  currentValLegend: string;
  mean: number;
  currentVal: number;
  units: ShockUnitsEnum;
  minVal: number;
  maxVal: number;
  minZScore: number;
  maxZScore: number;
  isCurrentValUserSpecified?: boolean;
};

const InputRangeTooltip = (props: InputRangeTooltip) => {
  const {
    renderHeader,
    rangeLegend,
    meanLegend,
    currentValLegend,
    currentVal,
    mean,
    units,
    minVal,
    maxVal,
    minZScore,
    maxZScore,
    isCurrentValUserSpecified = true,
  } = props;
  const valueInRange = getInRangeValue(currentVal, minVal, maxVal);
  const value = Number.isNaN(valueInRange) ? 0 : valueInRange;
  const meanValue = formattedNumber(mean, units);
  const formattedCurrentValue = formattedNumber(value, units);
  const indicatorPosition = minVal === maxVal ? 0 : (value - minVal) / (maxVal - minVal);
  const meanPosition = minVal === maxVal ? 0 : (mean - minVal) / (maxVal - minVal);
  const min = formattedNumber(minVal, units);
  const max = formattedNumber(maxVal, units);

  return (
    <Wrapper>
      <Header>{renderHeader()}</Header>
      <Content>
        <Description center>Available range constrained by std. deviation</Description>
        <Scale>
          <span data-testid="minValue">{min}</span>
          <span data-testid="maxValue">{max}</span>
        </Scale>
        <BarWrapper>
          {isCurrentValUserSpecified && (
            <Rail position={indicatorPosition}>
              <Ticker position="above" data-testid="currentValue">
                {formattedCurrentValue}
                <DownArrow />
              </Ticker>
            </Rail>
          )}
          <RangeBar />
          <Rail position={meanPosition}>
            <Ticker position="below" data-testid="meanValue">
              <UpArrow />
              {meanValue}
            </Ticker>
          </Rail>
        </BarWrapper>
        <Scale>
          <span data-testid="minZScore">{minZScore !== 0 ? `${minZScore}σ` : null}</span>
          <span data-testid="maxZScore">{maxZScore !== 0 ? `${maxZScore}σ` : null}</span>
        </Scale>
      </Content>
      <Footer>
        <LegendItem>
          <GreySquare /> {rangeLegend}
        </LegendItem>
        <LegendItem>
          <UpArrow /> {meanLegend}
        </LegendItem>
        <LegendItem>
          <DownArrow /> {currentValLegend}
        </LegendItem>
      </Footer>
    </Wrapper>
  );
};

export default InputRangeTooltip;

const Description = styled(Body2)`
  margin-bottom: 4px;
`;

const Scale = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${GetColor.DarkGrey};
`;

const Rail = styled.div<{ position: number }>`
  position: absolute;
  width: 100%;
  transform: translateX(${({ position }) => position * 100}%);
  transition: transform 1s ease-in-out;
`;

const Ticker = styled.div<{ position: 'above' | 'below' }>`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  position: absolute;
  align-items: center;
  line-height: 1;
  ${({ position }) =>
    position === 'above'
      ? css`
          transform: translateX(-50%) translateY(calc(-100% + 6px));
          color: ${GetColor.HighlightDark};
        `
      : css`
          transform: translateX(-50%) translateY(-6px);
          color: ${GetColor.DarkGrey};
        `}
`;

// A nice CSS trick that allows us to render a triangle in the proportions we want
// Relies on the fact that borders in CSS meet at 45 degree angles
// See https://css-tricks.com/snippets/css/css-triangle/ for more info
const UpArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid ${GetColor.DarkGrey};
`;

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid ${GetColor.HighlightDark};
`;

const GreySquare = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${GetColor.MidGrey1};
`;

const BarWrapper = styled.div`
  padding: 16px 0;
  background-color: ${GetColor.WhiteGrey};
  position: relative;
`;

const RangeBar = styled.div`
  width: 100%;
  height: 12px;
  background-color: ${GetColor.MidGrey1};
`;

const Wrapper = styled.div`
  font-size: 10px;
  width: 320px;
  border: solid 1px ${GetColor.Grey};
  background-color: ${GetColor.White};
  text-align: left;
  color: ${GetColor.Black};
`;

const Content = styled.div`
  padding: 8px 29px 12px 29px;
  text-align: center;
`;

const Footer = styled.div`
  height: 24px;
  background-color: ${GetColor.WhiteGrey};
  padding: 8px;
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const Header = styled.div`
  height: 24px;
  background-color: ${GetColor.Black};
  color: ${GetColor.White};
  line-height: 1;
  column-gap: 4px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
`;
