import type { History } from 'history';
import { compact, isNil, omit, omitBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import type { AssetTypeEnum, CurrencyEnum, MetricFilter, OrderEnum } from 'venn-api';
import { useDebounce } from 'venn-components';
import type { MorningstarCategory, SearchParams } from 'venn-state';
import { DEFAULT_PARAMS_V2, librarySearchParamsAtom, useScopedSetRecoilState } from 'venn-state';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { analyticsService, getQueryParams, LibraryItemType, LibraryTab } from 'venn-utils';
import { toTrackingFormat } from './utils';

export enum AdvancedFilterType {
  CURRENCY = 'currency',
  ASSET_TYPE = 'assetTypes',
  METRICS = 'metrics',
  DATA_SOURCE = 'dataSource',
  MORNINGSTAR_CATEGORY = 'morningstarCategories',
}

export type AdvancedFilterValue = MetricFilter[] | CurrencyEnum[] | AssetTypeEnum[] | MorningstarCategory[] | string[];

/** A hook for managing synchronization between data library state (residing in recoil) and URL state
 *  It is a wrapper for the underlying logic and NOT meant to be reused.
 *  If you just want to read/write library state, see useLibraryState.ts
 *
 *  (TODO) in the future is to get rid of this file and use recoil-sync library instead.
 *  The one big atom consisting library state could be split into several smaller ones, each of which
 *  could be synced independently from others via an appropriate refine function. See more:
 *  https://recoiljs.org/docs/recoil-sync/sync-effect
 *  */
export const useLibraryStateURLSynchronizer = (
  history: History<{ shouldListenerIgnore?: boolean }>,
  pageLoaded: React.MutableRefObject<boolean>,
  isStateZero: React.MutableRefObject<boolean>,
) => {
  const librarySearchParams = useRecoilValue(librarySearchParamsAtom);
  const setLibrarySearchParams = useScopedSetRecoilState(librarySearchParamsAtom);
  const DEBOUNCED_DELAY = 500;
  const [debouncedParams] = useDebounce(librarySearchParams, DEBOUNCED_DELAY); // Used to update the url when queryParams change

  // Track when the user changes the filters
  useEffect(() => {
    // Do not track before the page has loaded, as the user has not made a selection at this point
    if (pageLoaded.current) {
      analyticsService.libraryFiltersSelected({
        tagFilters: librarySearchParams.tags ?? [],
        quickFilters: librarySearchParams.filters ?? [],
        typeFilters: compact([librarySearchParams.itemType]),
        currencyFilters: librarySearchParams.currency,
        dataSourceFilters: librarySearchParams.dataSource,
        metricFilters: toTrackingFormat(librarySearchParams.metrics),
        assetTypeFilters: librarySearchParams.assetTypes,
        libraryTab: LibraryTab.ReturnsData,
      });
    }
  }, [
    librarySearchParams.itemType,
    librarySearchParams.filters,
    librarySearchParams.tags,
    librarySearchParams.assetTypes,
    pageLoaded,
    librarySearchParams.currency,
    librarySearchParams.dataSource,
    librarySearchParams.metrics,
  ]);

  useEffect(() => {
    // initial sync search params
    setLibrarySearchParams(() => {
      // TODO: why is this using the callback form of state setting without using prev? Let's try simplifying, and test that everything still works the same.
      const mergeParams = { ...DEFAULT_PARAMS_V2, ...getParamsFromUrl(history.location.search) };
      if (!isStateZero.current && mergeParams.itemType === LibraryItemType.NONE) {
        mergeParams.itemType = LibraryItemType.ALL;
      }
      return mergeParams;
    });
    // prevent debouncedParams from being read until after it's been set the first time
    const timeoutRef = setTimeout(() => (pageLoaded.current = true), DEBOUNCED_DELAY);
    return () => clearTimeout(timeoutRef);
    // this should happen on mount only, and the eslint rule is intentionally disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Watches for updates to history.location.search and updates librarySearchParams accordingly
    return history.listen(({ state, search }) => {
      if (state?.shouldListenerIgnore) {
        return;
      }
      setLibrarySearchParams((prev) => {
        const paramsFromUrl = getParamsFromUrl(search);
        if (isEqual(paramsFromUrl, prev)) {
          return prev;
        }
        isStateZero.current = false;
        return {
          ...prev,
          ...paramsFromUrl,
        };
      });
    });
  }, [isStateZero, history, setLibrarySearchParams]);

  // Watches for updates to debouncedParams and updates history.location.search accordingly
  useEffect(() => {
    const path = history.location.pathname;
    const existing = getParamsFromUrl(history.location.search);
    const mergedParams = {
      ...existing,
      ...debouncedParams,
      ...generateMetricFilterQueryParams(debouncedParams.metrics ?? []),
    };
    const newSearch = queryString.stringify(omit(mergedParams, 'metrics'));
    if (pageLoaded.current && path && !isEqual(existing, mergedParams)) {
      history.push(`${path}?${newSearch}`, { shouldListenerIgnore: true });
    }
  }, [debouncedParams, history, pageLoaded]);
};

const getParamsFromUrl = (search: string): SearchParams => {
  const queryParams = getQueryParams(search);
  const searchParams: SearchParams = {
    ...DEFAULT_PARAMS_V2,
    selectedIds:
      typeof queryParams.selectedIds === 'string' ? [queryParams.selectedIds] : queryParams.selectedIds || [],
    filters: typeof queryParams.filters === 'string' ? [queryParams.filters] : queryParams.filters || [],
    tags: typeof queryParams.tags === 'string' ? [queryParams.tags] : queryParams.tags || [],
  };

  if (queryParams.itemType) {
    searchParams.itemType = queryParams.itemType as LibraryItemType;
  }
  if (queryParams.currency) {
    searchParams.currency = typeof queryParams.currency === 'string' ? [queryParams.currency] : queryParams.currency;
  }
  if (queryParams.assetTypes) {
    searchParams.assetTypes =
      typeof queryParams.assetTypes === 'string' ? [queryParams.assetTypes] : queryParams.assetTypes;
  }
  if (queryParams.morningstarCategories) {
    searchParams.morningstarCategories =
      typeof queryParams.morningstarCategories === 'string'
        ? [queryParams.morningstarCategories]
        : queryParams.morningstarCategories;
  }
  if (queryParams.dataSource) {
    searchParams.dataSource =
      typeof queryParams.dataSource === 'string' ? [queryParams.dataSource] : queryParams.dataSource;
  }
  if (queryParams.lastTrackTime) {
    searchParams.lastTrackTime = queryParams.lastTrackTime && Number(queryParams.lastTrackTime as string);
  }
  if (queryParams.page) {
    searchParams.page = Number(queryParams.page as string);
  }
  if (queryParams.order) {
    searchParams.order = queryParams.order as OrderEnum;
  }
  // make sure we get the empty string
  if (queryParams.name !== undefined) {
    searchParams.name = queryParams.name as string;
  }
  if (queryParams.sortBy) {
    searchParams.sortBy = queryParams.sortBy === 'null' ? undefined : (queryParams.sortBy as string);
  }

  searchParams.metrics = generateMetricFilterSearchParams(queryParams);

  if (queryParams.savedSearchId) {
    searchParams.savedSearchId = queryParams.savedSearchId as string;
  }
  return searchParams;
};

export const generateMetricFilterSearchParams = (queryParams: AnyDuringEslintMigration) =>
  Object.keys(queryParams)
    .filter((key) => key.startsWith(AdvancedFilterType.METRICS))
    .reduce<MetricFilter[]>((result, key) => {
      const [, timePeriod, metric, extremum] = key.split('-');
      const found = result.find((filter) => filter.metric === metric && filter.timePeriod === timePeriod);
      for (const potentialExtremum of ['maximum', 'minimum']) {
        if (extremum === potentialExtremum) {
          if (found) {
            found[extremum] = parseFloat(queryParams[key]);
          } else {
            result.push({
              timePeriod,
              metric,
              [extremum]: parseFloat(queryParams[key]),
            } as MetricFilter);
          }
        }
      }
      return result;
    }, []);

export const generateMetricFilterQueryParams = (filters: MetricFilter[]) =>
  omitBy(
    (filters || []).reduce((result, { timePeriod, metric, minimum, maximum }) => {
      const updated = { ...result };
      if (!isNil(maximum)) {
        updated[`${AdvancedFilterType.METRICS}-${timePeriod}-${metric}-maximum`] = maximum;
      }
      if (!isNil(minimum)) {
        updated[`${AdvancedFilterType.METRICS}-${timePeriod}-${metric}-minimum`] = minimum;
      }
      return updated;
    }, {}),
    isNil,
  );
