import React from 'react';
import styled, { withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { GetColor } from 'venn-ui-kit';
import useKeyBlockingNumericInputStateManager, {
  type InjectedProps,
  type NumericTextInputStateManagerProps,
} from './useKeyBlockingNumericInputStateManager';

export interface NumericTextInputProps
  extends Omit<NumericTextInputStateManagerProps, 'render' | 'maxDecimalPlaces'>,
    ViewProps {
  theme: Theme;
}

interface ViewProps {
  placeholder?: string;
  className?: string;
  height: number;
  defaultBorderColor?: string;
  focusedBorderColor?: string;
  lockedBorderColor?: string;
  textColor?: string;
}

interface SmallInputProps extends InjectedProps, ViewProps {}

const SmallInput = styled.input<SmallInputProps>`
  border-radius: 0px;
  border-color: ${(props) =>
    props.isError
      ? GetColor.Error
      : props.isLocked
        ? (props.lockedBorderColor ?? GetColor.Grey)
        : props.defaultBorderColor};

  &:focus {
    ${({ isFocused, focusedBorderColor }) => isFocused && `border-color: ${focusedBorderColor}`};
  }

  height: ${({ height }) => height - 2}px;
  margin: 0;
  padding: 0 6px;

  width: 100%;

  color: ${(props) =>
    props.isError ? GetColor.Error : props.isLocked ? (props.lockedBorderColor ?? GetColor.MidGrey2) : props.textColor};
  font-size: 14px;
  font-weight: normal;
  text-align: right;
`;

const KeyBlockingNumericInput = ({
  theme: { Colors },
  height,
  lockedBorderColor = Colors.Grey,
  defaultBorderColor = Colors.Grey,
  focusedBorderColor = Colors.HighlightLight,
  textColor = Colors.Black,
  className,
  placeholder,
  // useKeyBlockingNumericInputStateManager props
  inputRef,
  value,
  allowNegative,
  isLocked,
  onChange,
  onCommitInput,
  isPercentage,
  onMouseDown,
  onFocus,
}: NumericTextInputProps) => {
  const inputProps = useKeyBlockingNumericInputStateManager({
    maxDecimalPlaces: 1,
    inputRef,
    value,
    allowNegative,
    isLocked,
    onChange,
    onCommitInput,
    isPercentage,
    onMouseDown,
    onFocus,
  });
  return (
    <SmallInput
      {...inputProps}
      className={className}
      placeholder={placeholder}
      height={height}
      defaultBorderColor={defaultBorderColor}
      lockedBorderColor={lockedBorderColor}
      focusedBorderColor={focusedBorderColor}
      textColor={textColor}
    />
  );
};

export default withTheme(KeyBlockingNumericInput);
