import React, { useContext, useEffect, useRef, useState } from 'react';
import type {
  Analysis,
  AnalysisRequest,
  AnalysisTypeEnum,
  FactorExposure,
  Portfolio,
  PortfolioSummary,
} from 'venn-api';
import { analysis } from 'venn-api';
import { type ExcelCell, logExceptionIntoSentry } from 'venn-utils';
import { generateAnalysisParams, useApi } from 'venn-utils';
import { PortfolioLabContext } from 'venn-components';
import { isNil } from 'lodash';
import type { FactorMetricRow } from './FactorPerformanceCardView';
import FactorPerformanceCardView from './FactorPerformanceCardView';
import { getFactorData } from '../../logic/useCombinedExcelExportData';

const analysesTypes: AnalysisTypeEnum[] = ['FACTOR_EXPOSURES_FORECAST'];
const DEFAULT_ERROR = 'Unable to calculate factor analysis';

interface FactorPerformanceCardContainerProps {
  onUpdateExportData: (data: ExcelCell[][] | undefined) => void;
}

const FactorPerformanceCardContainer = ({ onUpdateExportData }: FactorPerformanceCardContainerProps) => {
  const { solutionPortfolio, portfolio, portfolioSummary, onShowFactorSection } = useContext(PortfolioLabContext);

  const [data, setData] = useState<FactorMetricRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const analysisApiRef = useRef(useApi(analysis));

  useEffect(() => {
    const fetchFactorAnalysis = async () => {
      if (isNil(solutionPortfolio) || isNil(solutionPortfolio?.portfolio) || isNil(portfolio)) {
        return;
      }
      setLoading(true);
      try {
        const { content } = await analysisApiRef.current(getRequest(solutionPortfolio.portfolio, portfolio));
        if (isNil(content.analyses?.[0]?.factorExposuresForecast)) {
          setData([]);
          setError(content.analyses?.[0]?.message?.text ?? DEFAULT_ERROR);
        } else {
          setData(convertData(content.analyses, solutionPortfolio.summary, portfolioSummary));
          setError(undefined);
        }
        setLoading(false);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          setData([]);
          setError(DEFAULT_ERROR);
          setLoading(false);
          logExceptionIntoSentry(e);
        }
      }
    };

    fetchFactorAnalysis();
  }, [solutionPortfolio, portfolio, portfolioSummary]);

  useEffect(() => {
    onUpdateExportData(getFactorData(data));
  }, [data, onUpdateExportData]);

  return (
    <FactorPerformanceCardView data={data} loading={loading} error={error} onEditExposures={onShowFactorSection} />
  );
};

export default FactorPerformanceCardContainer;

const getRequest = (selectedPortfolio: Portfolio, currentPortfolio: Portfolio): Partial<AnalysisRequest> => ({
  analyses: analysesTypes.map((type) => generateAnalysisParams(type, false)),
  subjects: [
    {
      comparisonType: 'PRIMARY' as const,
      id: undefined!,
      portfolio: selectedPortfolio,
      subjectType: 'PORTFOLIO' as const,
      primary: true,
      isPrivate: false,
    },
    {
      comparisonType: 'COMPARISON' as const,
      portfolio: currentPortfolio,
      id: currentPortfolio.id.toString(),
      subjectType: 'PORTFOLIO' as const,
      primary: false,
      isPrivate: false,
    },
  ],
});

const EMPTY_ROW: FactorMetricRow = {
  metricName: '',
  exposure: undefined,
  exposureBase: undefined,
  contributionToRisk: undefined,
  contributionToRiskBase: undefined,
  contributionToReturn: undefined,
  contributionToReturnBase: undefined,
};

const convertData = (
  analyses: Analysis[],
  summary: PortfolioSummary | undefined,
  baseSummary: PortfolioSummary | undefined,
): FactorMetricRow[] => {
  const exposure = analyses[0].factorExposuresForecast;
  const [primary, base] = [exposure[0], exposure[1]];

  const factorsMap = new Map<number, FactorMetricRow>();

  primary.forEach((factor: FactorExposure) => {
    const item = factorsMap.get(factor.id) ?? {
      ...EMPTY_ROW,
      metricName: factor.name,
    };
    item.exposure = factor.exposure;
    item.contributionToRisk = factor.riskContribution;
    item.contributionToReturn = factor.returnContribution;
    factorsMap.set(factor.id, item);
  });
  if (!isNil(base)) {
    base.forEach((factor: FactorExposure) => {
      const item = factorsMap.get(factor.id);
      if (!isNil(item)) {
        item.exposureBase = factor.exposure;
        item.contributionToRiskBase = factor.riskContribution;
        item.contributionToReturnBase = factor.returnContribution;
        factorsMap.set(factor.id, item);
      }
    });
  }

  const result: FactorMetricRow[] = [
    {
      metricName: 'Residual',
      isResidual: true,
      exposure: undefined,
      exposureBase: undefined,
      contributionToRisk: summary?.residualRiskContribution,
      contributionToRiskBase: baseSummary?.residualRiskContribution,
      contributionToReturn: summary?.residualReturnContribution,
      contributionToReturnBase: baseSummary?.residualReturnContribution,
    },
    ...factorsMap.values(),
  ];
  return result;
};
