import { type DateRange, type Granularity } from 'venn-ui-kit';
import { useState, useMemo } from 'react';
import type {
  AnalysisParams,
  AnalysisResponse,
  AnalysisSubject as ApiAnalysisSubject,
  AnalysisTypeEnum,
  TimePeriodEnum,
} from 'venn-api';
import { analysis, SupportedErrorCodes } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { analyticsService, convertPeriodRequest, useQuery, toEndOfPeriod } from 'venn-utils';
import { compact } from 'lodash';

export const COMPARISON_CACHE_KEY = 'COMPARISON_CACHE_KEY';

const analysesTypes: AnalysisTypeEnum[] = [
  'PERFORMANCE_SUMMARY_HISTORICAL',
  'PERFORMANCE_SUMMARY_FORECAST',
  'FACTOR_CONTRIBUTION_TO_RISK',
  'FACTOR_CONTRIBUTION_TO_RETURN',
  'FACTOR_EXPOSURES',
  'PAIRWISE_CORRELATION',
];

const generateAnalysisParams = (
  analysisType: AnalysisTypeEnum,
  relative: boolean,
  rollingYears: number,
  drawdownThreshold: number,
  fixedPeriods: TimePeriodEnum[] = [],
): AnalysisParams => ({
  analysisType,
  fixedPeriods,
  analysisDate: undefined,
  clientId: undefined,
  contextPortfolio: undefined,
  drawdownThreshold,
  factorLensId: undefined,
  maximumPoints: undefined,
  relative,
  rollingYears,
  residual: undefined,
  bucketSize: undefined, // For returns
  buckets: undefined, // For returns
  scenarios: [],
});

const fetchAnalysis = async (
  subjects: ApiAnalysisSubject[],
  dateRange: DateRange,
  relative: boolean,
  granularity: Granularity,
) => {
  const analysisParams = analysesTypes.map((type) =>
    generateAnalysisParams(
      type,
      relative,
      1,
      0.5,
      type === 'PERFORMANCE_SUMMARY_HISTORICAL' ? ['YEAR_1', 'YEAR_3', 'YEAR_5'] : undefined,
    ),
  );

  const period = convertPeriodRequest(dateRange.period);
  const request = {
    analyses: analysisParams,
    subjects,
    start: period ? undefined : toEndOfPeriod(dateRange.from, granularity),
    end: period ? undefined : toEndOfPeriod(dateRange.to, granularity),
    period,
  };

  const { content } = await analysis(request);
  return content;
};

const getApiAnalysisSubject = (
  subject: AnalysisSubject | undefined,
  isPrimary: boolean,
  isBenchmark: boolean,
): ApiAnalysisSubject | undefined => {
  if (!subject) {
    return undefined;
  }
  const result = {
    comparisonType: isPrimary ? 'PRIMARY' : isBenchmark ? 'BENCHMARK' : undefined,
    id: String(subject?.id),
    portfolio: undefined!,
    subjectType: subject?.superType === 'investment' ? 'INVESTMENT' : 'PORTFOLIO',
    primary: false,
    isPrivate: false,
  } as ApiAnalysisSubject;
  return result;
};

type ComparisonSubject = AnalysisSubject | undefined;

const useComparisonAnalysis = (
  subjects: ComparisonSubject[],
  dateRange: DateRange,
  benchmark: AnalysisSubject | undefined,
  relative: boolean,
  isPivotedLayout: boolean,
  granularity: Granularity,
) => {
  const [trackingId, setTrackingId] = useState<number>(-1);
  const apiSubjects = useMemo(
    () =>
      compact([
        benchmark && getApiAnalysisSubject(benchmark, false, true),
        ...subjects.map((subject, idx) => getApiAnalysisSubject(subject, idx === 0, false)),
      ]),
    [benchmark, subjects],
  );

  const {
    data: analyses,
    isLoading: analysisIsLoading,
    isFetching: analysisIsFetching,
    dataUpdatedAt: analysisUpdatedTime,
  } = useQuery<AnalysisResponse | undefined>(
    [COMPARISON_CACHE_KEY, benchmark, apiSubjects, dateRange, relative],
    () => fetchAnalysis(apiSubjects, dateRange, relative, granularity),
    {
      enabled: apiSubjects.length !== 0 && apiSubjects.some((s) => s.comparisonType === 'PRIMARY'),
      onSuccess: (data) => {
        analyticsService.comparisonPerformed({
          hasInvestments: apiSubjects.some((s) => s.subjectType === 'INVESTMENT'),
          hasPortfolios: apiSubjects.some((s) => s.subjectType === 'PORTFOLIO'),
          hasBenchmark: apiSubjects.some((s) => s.comparisonType === 'BENCHMARK'),
          relativeToBenchmark: relative,
          numberOfSubjects: subjects.length,
          layout: isPivotedLayout ? 'pivoted' : 'classic',
        });
        setTrackingId(Date.now());
        const subjectError = data?.subjectErrors?.[0];
        if (subjectError) {
          const thresholdError = subjectError.code === SupportedErrorCodes.AnalysisLimitErrorCode;
          analyticsService.comparisonFailed({
            relativeToBenchmark: relative,
            numberOfSubjects: subjects.length,
            hasBenchmark: Boolean(benchmark),
            overThreshold: thresholdError,
          });
        }
      },
    },
  );

  return {
    trackingId,
    analyses,
    analysisIsLoading,
    analysisUpdatedTime,
    analysisIsFetching,
  };
};

export default useComparisonAnalysis;
