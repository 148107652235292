import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  onOutClick?: () => void;
  children: React.ReactNode;
}

const Portal = ({ onOutClick, children }: PortalProps) => {
  const modalRef = useRef<Element | undefined>();
  useEffect(() => {
    const onOutsideModalClick = (event: MouseEvent): void => {
      const isNonPrimaryButtonPressed: boolean = event.button !== undefined && event.button !== 0;
      if (!modalRef.current || modalRef.current?.contains(event.target as HTMLElement) || isNonPrimaryButtonPressed) {
        return;
      }

      if (onOutClick) {
        onOutClick();
      }
    };
    /**
     * using 0 timeout to ignore the clicks that opened this modal as recommended by react team
     * https://github.com/facebook/react/issues/24657#issuecomment-1150119055
     */
    const timeoutId = setTimeout(() => {
      if (onOutClick) {
        document.addEventListener('click', onOutsideModalClick);
      }
    }, 0);
    return () => {
      clearTimeout(timeoutId);
      if (onOutClick) {
        document.removeEventListener('click', onOutsideModalClick);
      }
    };
  }, [onOutClick]);
  return ReactDOM.createPortal(
    <div
      ref={(modal: HTMLDivElement | null) => {
        modalRef.current = modal || undefined;
      }}
    >
      {children}
    </div>,
    document.body,
  );
};

export default Portal;
