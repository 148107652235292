import type { FC, ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { GetColor } from 'venn-ui-kit';
import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

export type NodeType = ReactNode;

export interface MarkdownProps extends Omit<ReactMarkdownOptions, 'skipHtml' | 'renderers'> {
  noMargin?: boolean;
}

const StyledReactMarkdown = styled(ReactMarkdown)<{ noMargin?: boolean }>`
  code {
    font-size: 1em;
    white-space: pre-wrap;
  }
  hr {
    border: 1px solid ${GetColor.Grey};
  }
  ${({ noMargin }) =>
    noMargin &&
    css`
      p {
        margin-block-start: unset;
        margin-block-end: unset;
      }
    `}
`;

export const Markdown: FC<MarkdownProps> = (props) => <StyledReactMarkdown {...props} linkTarget="_blank" skipHtml />;

export default Markdown;
