import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { EntityPerformance, FactorWithNonSerializedReturns, FactorPerformanceTypeEnum } from 'venn-api';
import type { BasicTableColumn, BasicTableProps } from 'venn-components';
import {
  ColumnAlign,
  SORTDIR,
  CorrelationsChart,
  BasicTable,
  PrintContainerDimensions,
  MIN_CELL_WIDTH,
  INVESTMENT_COLUMN_WIDTH,
  DownloadableContentBlock,
  exportToExcel,
  HeatMapUtils,
  ShowCorrelationValuesToggle,
} from 'venn-components';
import type { DateRange, VennColors } from 'venn-ui-kit';
import { GetColor, Icon, Headline2, Hint } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { Dates } from 'venn-utils';
import { isNil } from 'lodash';

const { convertCorrelationsDataToExcel } = HeatMapUtils;

function getColumns(
  subject: AnalysisSubject | undefined,
  sortKey = 'periodReturn',
  sortDir: SORTDIR = SORTDIR.DESC,
  isAnnualized = false,
) {
  return [
    {
      accessor: 'name',
      cellRenderer: ({ name, id, type }: EntityPerformance) => (
        <StyledNameCellWrapper type={type}>
          <StyledCell type={type}>{subject?.id.toString() === id ? <i>{name}</i> : name}</StyledCell>
        </StyledNameCellWrapper>
      ),
      excelCellRenderer: ({ name }: EntityPerformance) => ({
        value: name,
      }),
    },
    {
      label: isAnnualized ? 'Annualized Return' : 'Cumulative Return',
      accessor: 'periodReturn',
      align: ColumnAlign.RIGHT,
      sortable: true,
      sorted: sortKey === 'periodReturn' ? sortDir : undefined,
      cellRenderer: ({ periodReturn, type }: EntityPerformance) => (
        <StyledCell type={type}>{`${(periodReturn * 100).toFixed(2)}%`}</StyledCell>
      ),
      excelCellRenderer: ({ periodReturn }: EntityPerformance) => ({
        value: periodReturn ?? '--',
        percentage: true,
      }),
    },
    {
      label: 'Annualized Volatility',
      accessor: 'annualizedVolatility',
      align: ColumnAlign.RIGHT,
      sortable: true,
      sorted: sortKey === 'annualizedVolatility' ? sortDir : undefined,
      cellRenderer: ({ annualizedVolatility, type }: EntityPerformance) => {
        const content = annualizedVolatility ? `${(annualizedVolatility * 100).toFixed(2)}%` : '--';
        return <StyledCell type={type}>{content}</StyledCell>;
      },
      excelCellRenderer: ({ annualizedVolatility }: EntityPerformance) => ({
        value: annualizedVolatility ?? '--',
        percentage: true,
      }),
    },
    {
      label: 'Annualized Sharpe',
      accessor: 'annualizedSharpe',
      align: ColumnAlign.RIGHT,
      sortable: true,
      sorted: sortKey === 'annualizedSharpe' ? sortDir : undefined,
      cellRenderer: ({ annualizedSharpe, type }: EntityPerformance) => {
        const content = annualizedSharpe ? annualizedSharpe.toFixed(2) : '--';
        return <StyledCell type={type}>{content}</StyledCell>;
      },
      excelCellRenderer: ({ annualizedSharpe }: EntityPerformance) => ({
        value: annualizedSharpe ?? '--',
        percentage: false,
        digits: 2,
      }),
    },
  ];
}

export interface CharacteristicsAndCorrelationsProps {
  showInputs: boolean;
  showInputsMessage: boolean;
  selectedFactors: FactorWithNonSerializedReturns[];
  subject?: AnalysisSubject;
  sortKey?: string;
  sortDir?: SORTDIR;
  onSort: (key: string, dir: SORTDIR) => void;
  labelFormatter: (id: string, type?: FactorPerformanceTypeEnum) => CSSProperties;
  className?: string;
  factorPerformance: EntityPerformance[];
  range: DateRange;
  colors: VennColors;
}

interface CharacteristicsAndCorrelationsState {
  showValues: boolean;
}

export default class CharacteristicsAndCorrelations extends React.Component<
  CharacteristicsAndCorrelationsProps,
  CharacteristicsAndCorrelationsState
> {
  state: CharacteristicsAndCorrelationsState = {
    showValues: true,
  };

  renderContent = () => {
    const {
      showInputs,
      showInputsMessage,
      selectedFactors,
      sortKey,
      sortDir,
      subject,
      onSort,
      labelFormatter,
      factorPerformance,
      range,
      colors,
    } = this.props;

    const { showValues } = this.state;
    const correlationsExist = doCorrelationsExist(factorPerformance);
    const showCorrelations = (subject && selectedFactors.length > 0) || selectedFactors.length > 1 || showInputs;
    const periodAnnualized = factorPerformance.some((e) => e.periodAnnualized);

    const correlationsData = correlationsExist
      ? factorPerformance.map((e) => ({
          ...e,
          correlations: Object.keys(e.correlation).map((id) => ({
            id,
            value: e.correlation[id],
          })),
        }))
      : [];

    const toggleShowValues = () => {
      const { showValues } = this.state;
      showValues ? this.setState({ showValues: false }) : this.setState({ showValues: true });
    };

    const correlactionsExcelData = correlationsData.map(({ id, name, correlations }, idx: number) => ({
      factorId: isNil(id) || Number.isNaN(Number.parseInt(id, 10)) ? undefined : Number.parseInt(id, 10),
      name,
      series: [
        {
          name,
          data: correlationsData
            .slice(0, idx)
            .map((correlatedFactor) => {
              const value =
                correlatedFactor?.correlations?.find((correlation) => correlation?.id === id)?.value ||
                correlations?.find((correlation) => correlation?.id === correlatedFactor?.id)?.value;
              return {
                value,
                nonSignificant: false,
                color: colors.Black,
              };
            })
            .concat(
              ...[
                {
                  value: 1,
                  nonSignificant: false,
                  color: colors.Black,
                },
              ],
            ),
        },
      ],
    }));
    const correlationsAxis = correlactionsExcelData.map(({ name, factorId }) => ({ name, factorId }));
    const excelData = convertCorrelationsDataToExcel(correlactionsExcelData, correlationsAxis);
    const excelAnalysisPeriod =
      range.from && range.to ? ` - ${Dates.toDayMonthYear(range.from)} to ${Dates.toDayMonthYear(range.to)}` : '';
    return (
      <>
        <CorrelationsTableContainer>
          <DownloadableContentBlock
            header="Performance Characteristics"
            downloadable={{
              png: true,
              excel: [...exportToExcel(factorPerformance, getColumns(subject, sortKey, sortDir, periodAnnualized))],
              options: {
                fileName: `Performance Characteristics${excelAnalysisPeriod}`,
                width: 700,
              },
              tracking: {
                description: 'FACTOR_PERFORMANCE_SUMMARY',
                relativeToBenchmark: false,
                subjectType: 'factor',
                subjectId: undefined,
                userUploaded: false,
              },
            }}
          >
            <StyledTable
              correlationsExist={correlationsExist}
              columns={getColumns(subject, sortKey, sortDir, periodAnnualized)}
              data={factorPerformance}
              onSort={onSort}
              sortDir={sortDir}
              sortKey={sortKey}
            />
            {!periodAnnualized && (
              <StyledMessage>
                <Hint>Annualized Volatility and Sharpe Ratio are not available for periods less than 1 year.</Hint>
                <StyledIcon type="info-circle" prefix="fas" />
              </StyledMessage>
            )}
            {showInputsMessage && (
              <ShowInputsMessage>
                Click "Show Factor Inputs" or select at least one additional factor or comparator to see correlations.
              </ShowInputsMessage>
            )}
          </DownloadableContentBlock>
        </CorrelationsTableContainer>
        {showCorrelations && (
          <CorrelationsChartWrapper>
            <PrintContainerDimensions>
              {({ width }) => (
                <Container
                  maxWidth={width}
                  minWidth={INVESTMENT_COLUMN_WIDTH + correlationsData.length * MIN_CELL_WIDTH + 4}
                >
                  <DownloadableContentBlock
                    header="Correlations"
                    downloadable={{
                      png: true,
                      disabled: !correlationsExist,
                      excel: excelData,
                      options: {
                        fileName: `Factor Correlations${excelAnalysisPeriod}`,
                      },
                      tracking: {
                        description: 'FACTOR_CORRELATIONS',
                        relativeToBenchmark: false,
                        subjectType: 'factor',
                        subjectId: undefined,
                        userUploaded: false,
                      },
                    }}
                    rightOptions={
                      <StyledToggleWrapper>
                        <ShowCorrelationValuesToggle
                          hidden={!correlationsExist}
                          showValues={showValues}
                          onToggle={toggleShowValues}
                        />
                      </StyledToggleWrapper>
                    }
                  >
                    {correlationsExist ? (
                      <CorrelationsChart
                        data={correlationsData}
                        sortKey={sortKey}
                        sortDir={sortDir}
                        width={width - 4}
                        labelFormatter={labelFormatter}
                        showValues={showValues}
                      />
                    ) : (
                      <CorrelationsMessage>
                        Select a period with at least 12 observations to see correlations.
                      </CorrelationsMessage>
                    )}
                  </DownloadableContentBlock>
                </Container>
              )}
            </PrintContainerDimensions>
          </CorrelationsChartWrapper>
        )}
      </>
    );
  };

  render() {
    const { factorPerformance, className } = this.props;

    if (!factorPerformance) {
      return null;
    }

    return (
      <SectionContainer>
        <Headline2>Characteristics and Correlations</Headline2>
        <CorrelationsContainer className={className}>{this.renderContent()}</CorrelationsContainer>
      </SectionContainer>
    );
  }
}

export function doCorrelationsExist(entities: EntityPerformance[]): boolean {
  let correlationsExist = false;
  entities.forEach((entity) => {
    const { correlation } = entity;
    if (correlation) {
      Object.keys(correlation).forEach((key) => {
        if (correlation[key] !== null) {
          correlationsExist = true;
        }
      });
    }
  });
  return correlationsExist;
}

const SectionContainer = styled.div`
  page-break-before: auto;
  page-break-inside: avoid;
`;

const StyledToggleWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: -25px;
  width: 130px;
`;

const StyledIcon = styled(Icon)`
  padding-left: 5px;
`;

const StyledMessage = styled.p`
  margin: 0;
  padding: 10px 20px;
`;

const CorrelationsContainer = styled.div`
  display: flex;
  // @ts-ignore: TODO fix strictFunctionTypes
  flex-direction: row;
  // @ts-ignore: TODO fix strictFunctionTypes
  margin-top: 20px;

  @media print {
    display: block;
  }

  .highcharts-axis-labels span {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 110px !important;
  }

  .highcharts-yaxis-labels span {
    padding: 10px 0 10px 5px;
    text-align: right;
    width: 148px !important;
    margin-left: 4px !important;
    margin-top: 4px !important;
  }

  .highcharts-tooltip {
    font-family: ${(props) => props.theme.Typography.fontFamily};
  }
`;

const StyledNameCellWrapper = styled.div<{ type: string }>`
  color: ${(props) => (props.type === 'FACTOR_INPUT' ? GetColor.MidGrey1 : GetColor.Black)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 350px;
`;

const CorrelationsChartWrapper = styled.div`
  width: 100%;
  flex: 60%;

  @media print {
    margin-top: 30px;
  }
`;

const Container = styled.div<{ maxWidth: number; minWidth: number }>`
  max-width: ${({ maxWidth }) => maxWidth}px;
  min-width: ${({ minWidth }) => minWidth}px;
  position: absolute;
  @media print {
    position: static;
  }
`;

const CorrelationsTableContainer = styled.div`
  padding-right: 30px;
  @media print {
    padding-right: 0;
  }
  flex: 40%;
  flex-shrink: 1;
`;

const ShowInputsMessage = styled.div`
  border: 1px solid ${GetColor.Grey};
  border-radius: 10px;
  padding: 30px 69px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  color: ${GetColor.MidGrey1};
`;

const CorrelationsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${GetColor.Grey};
  border-radius: 10px;
  padding: 30px 69px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  color: ${GetColor.MidGrey1};
  height: 90px;
  width: 416px;
`;

const StyledTable = styled(
  <T extends BasicTableColumn<K>, K>({
    correlationsExist,
    ...props
  }: BasicTableProps<T, K> & { correlationsExist: boolean }) => <BasicTable<T, K> {...props} />,
)`
  margin: ${(props) => (props.correlationsExist ? '194px 50px 0 0' : '0 50px 0 0')};
  @media print {
    margin: 0;
  }

  thead th {
    border-bottom: 1px solid ${GetColor.PaleGrey};

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  tbody tr {
    height: 32px;
    border-bottom: 1px solid ${GetColor.PaleGrey};
  }

  tbody td {
    width: 90px;

    &:first-child {
      width: 350px;
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }
`;

const StyledCell = styled.span<{ type: string }>`
  color: ${(props) => (props.type === 'FACTOR_INPUT' ? GetColor.MidGrey1 : GetColor.Black)};
`;
