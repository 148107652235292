import React, { useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import type { LibraryFilterSection } from '../types';
import { CheckboxMenu, SkeletalDropMenu, Tooltip, MenuActions } from 'venn-ui-kit';
import { FilterMenuTrigger, SelectedPills, useSelectState } from '../shared';

interface TagsAdvancedFilterProps {
  section: LibraryFilterSection<string>;
  initialSelected: Set<string>;
  onUpdateSelected(updated: Set<string>): void;
  removeTag(tag: string): void;
}

const TagsAdvancedFilter = ({ section, initialSelected, onUpdateSelected, removeTag }: TagsAdvancedFilterProps) => {
  const { badge, disabled, tooltip, items } = section;

  const initialSelectedArray = useMemo(() => Array.from(initialSelected), [initialSelected]);
  const { selectedState, updateSelectedState, resetState } = useSelectState(initialSelectedArray);

  const renderTrigger = (expanded?: boolean, toggleMenu: () => void = noop) => {
    const trigger = (
      <FilterMenuTrigger
        label="Add Tags"
        isOpen={expanded}
        onClick={toggleMenu}
        aria-expanded={expanded}
        aria-haspopup
        icon="tag"
        badge={badge}
        disabled={disabled}
      />
    );
    return tooltip ? (
      <Tooltip usePortal content={tooltip}>
        {trigger}
      </Tooltip>
    ) : (
      trigger
    );
  };

  const menuComponent = useCallback(
    (_, onClose) => (
      <CheckboxMenu
        width={295}
        height={330}
        hideSelectAll
        items={items.map((item) => ({
          ...item,
          checked: selectedState.has(item.value),
          tooltipContent: item.value,
        }))}
        onChange={(updated) => updateSelectedState(updated.filter(({ checked }) => checked).map(({ value }) => value))}
        footerComponent={
          <MenuActions
            onCancel={() => {
              resetState();
              onClose();
            }}
            onApply={() => {
              onUpdateSelected(selectedState);
              onClose();
            }}
            applyLabel="Select"
          />
        }
      />
    ),
    [items, selectedState, updateSelectedState, resetState, onUpdateSelected],
  );

  if (disabled) {
    return renderTrigger();
  }

  return (
    <>
      <SkeletalDropMenu
        menuPosition="right"
        onExternalActivity={resetState}
        triggerComponent={renderTrigger}
        menuComponent={menuComponent}
      />
      <SelectedPills iconType="tag" selected={selectedState} onClick={removeTag} />
    </>
  );
};

export default TagsAdvancedFilter;
