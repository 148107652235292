import React from 'react';
import { Block } from './components/core/Block';

const DeprecatedBlockHeader = React.memo(function DeprecatedBlockHeader() {
  return (
    <Block.Header>
      <Block.HeaderTopWrapper>
        {/* Header Components */}
        <Block.TitleWrapper>
          <Block.Title />
          <Block.Description />
        </Block.TitleWrapper>

        {/* Metadata Components */}
        <Block.MetaData>
          <Block.MetadataPrefabs.DateRange />
          <Block.MetadataPrefabs.BenchmarkType />
          <Block.MetadataPrefabs.RelativeToBenchmark />
          <Block.MetadataPrefabs.ContributionToPercentage />
          <Block.MetadataPrefabs.Venncast />
          <Block.MetadataPrefabs.RollingPeriod />
          <Block.MetadataPrefabs.AsOfDate />
          <Block.MetadataPrefabs.HoldingsCalculationInfo />
        </Block.MetaData>
      </Block.HeaderTopWrapper>
      {/* Subject Components */}
      <Block.Subjects>
        <Block.SubjectPrefabs.CommonBenchmark />
        <Block.SubjectPrefabs.Subjects />
        <Block.SubjectPrefabs.PeerGroupInformation />
      </Block.Subjects>
    </Block.Header>
  );
});

export default DeprecatedBlockHeader;
