import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import type { HoldingsBreakdownTypeEnum, HoldingsCategory, HoldingsCategoryId, ImageDetails, Scenario } from 'venn-api';
import type {
  DataGridSizeType,
  EditorValue,
  GeoLevelFilterType,
  PortfolioStrategyFilterType,
  SectorLevelFilterType,
} from 'venn-components';
import { type CustomBlockTypeEnum, type SelectableCustomNotablePeriod, isTreeBlock } from 'venn-utils';
import { resetOnStudioReset } from '../../effects/signalEffects';
import { blockSettingsMap, holdingsCategoriesTree } from '../globalConfig';
import { type BlockId, UnitFormat } from '../types';
import { blockSettingId, blockSettings } from './blockSettings';
import { isReportState } from './view';
import { DEFAULT_STOCK_PHOTO } from '../consts';

export const convertHoldingsBlockTypeToBreakdown = (blockType: CustomBlockTypeEnum): HoldingsBreakdownTypeEnum => {
  switch (blockType) {
    case 'ASSET_EXPOSURE':
      return 'ASSET';
    case 'SECTOR_EXPOSURE':
      return 'SECTOR';
    case 'GEOGRAPHY_EXPOSURE':
      return 'REGION';
    default: {
      throw new Error(`Invalid holdings block type: ${blockType}`);
    }
  }
};

export const blockScenarios = atomFamily<Scenario[], BlockId>({
  key: 'blockScenarios',
  default: [],
});

export const viewSelectedNotablePeriods = atomFamily<number[] | undefined, BlockId>({
  key: 'viewSelectedNotablePeriods',
  default: undefined,
});

export const viewSelectedInvestmentGroupInformation = atomFamily<string[] | undefined, BlockId>({
  key: 'viewSelectedInvestmentGroupInformation',
  default: undefined,
});

export const viewCustomNotablePeriods = atomFamily<SelectableCustomNotablePeriod[] | undefined, BlockId>({
  key: 'viewCustomNotablePeriods',
  default: undefined,
});

export const viewDataGridSizeType = atomFamily<DataGridSizeType | undefined, BlockId>({
  key: 'viewDataGridSizeType',
  default: undefined,
});

export const portfolioStrategyFilterType = atomFamily<PortfolioStrategyFilterType | undefined, BlockId>({
  key: 'portfolioStrategyFilterType',
  default: undefined,
});

export const geoLevelFilterType = atomFamily<GeoLevelFilterType | undefined, BlockId>({
  key: 'geoLevelFilterType',
  default: undefined,
});

export const sectorLevelFilterType = atomFamily<SectorLevelFilterType | undefined, BlockId>({
  key: 'sectorLevelFilterType',
  default: undefined,
});

export const viewCoverPageImage = atom<string>({
  key: 'viewCoverPageImage',
  default: DEFAULT_STOCK_PHOTO,
  effects: [resetOnStudioReset],
});

export const viewImages = atom<ImageDetails[] | undefined>({
  key: 'viewImages',
  default: undefined,
  effects: [resetOnStudioReset],
});

export const viewRichText = atomFamily<EditorValue | undefined, BlockId>({
  key: 'viewRichText',
  default: undefined,
});

export const viewShowVenncast = atomFamily<boolean | undefined, BlockId>({
  key: 'viewShowVenncast',
  default: undefined,
});

export const viewImageId = atomFamily<string | undefined, BlockId>({
  key: 'viewImageId',
  default: undefined,
});

export const viewPrimaryColor = atom<string>({
  key: 'viewPrimaryColor',
  default: '#1c5af3',
  effects: [resetOnStudioReset],
});

export const blockHoldingsCategoryIds = atomFamily<HoldingsCategoryId[] | undefined, BlockId>({
  key: 'blockHoldingsCategoryIds',
  default: undefined,
});

export const blockYAxisUnitFormat = atomFamily<UnitFormat, BlockId>({
  key: 'blockYAxisUnitFormat',
  default: UnitFormat.PERCENT,
});

// Meant to be accessed via selector `hideBlockBorders` to ensure it's being used on the right blocks
const hideBlockBordersSetting = atomFamily<boolean, BlockId>({
  key: 'hideBlockBordersSetting',
  default: true,
});

export const canToggleHideBlockBorders = selectorFamily<boolean, BlockId>({
  key: 'canToggleHideBlockBorders',
  get:
    (id) =>
    ({ get }) => {
      const settingId = get(blockSettingId(id));
      const blockSettingsData = get(blockSettingsMap)[settingId ?? ''];
      const blockType = blockSettingsData?.customBlockType;
      return (blockType === 'MARKDOWN' || blockType === 'IMAGE') && get(isReportState);
    },
});

export const hideBlockBorders = selectorFamily<boolean, BlockId>({
  key: 'hideBlockBorders',
  get:
    (id) =>
    ({ get }) =>
      get(canToggleHideBlockBorders(id)) ? get(hideBlockBordersSetting(id)) : false,
  set:
    (id) =>
    ({ set }, hideBorders) =>
      set(hideBlockBordersSetting(id), hideBorders),
});

/** true iff the current reports layout has been changed since the view has been opened */
export const hasChangedLayoutState = atom<boolean>({
  key: 'hasChangedLayoutState',
  default: false,
  effects: [resetOnStudioReset],
});

export const filterHoldingsToLevel = (holdings: HoldingsCategory[], level: number): HoldingsCategory[] => {
  if (level <= 0) {
    return [];
  }

  return holdings.map((holding) => ({
    ...holding,
    children: filterHoldingsToLevel(holding.children, level - 1),
  }));
};
export const allHoldingsCategories = selectorFamily<HoldingsCategory[], BlockId>({
  key: 'allHoldingsCategories',
  get:
    (id) =>
    ({ get }) => {
      const settings = get(blockSettings(id));
      return get(holdingsCategoriesTree(convertHoldingsBlockTypeToBreakdown(settings.customBlockType)));
    },
});

export const holdingsCategories = selectorFamily<HoldingsCategory[], BlockId>({
  key: 'holdingsCategories',
  get:
    (id) =>
    ({ get }) => {
      const allCategories = get(allHoldingsCategories(id));
      const blockType = get(blockSettings(id)).customBlockType;
      const selectedCategoryIds = get(blockHoldingsCategoryIds(id))?.map((id) => id.id);
      if (blockType === 'GEOGRAPHY_EXPOSURE') {
        return allCategories;
      }
      const getCategories = (allCategories: HoldingsCategory[]) => {
        if (selectedCategoryIds) {
          const settings = get(blockSettings(id));
          const isTree = settings.customBlockType === 'SECTOR_EXPOSURE';
          if (isTree) {
            return allCategories.map((category) => ({
              ...category,
              children: category.children.filter((child) => selectedCategoryIds.includes(child.id.id)),
            }));
          }
          return allCategories.filter((category) => selectedCategoryIds.includes(category.id.id));
        }
        return allCategories;
      };

      return getCategories(allCategories);
    },
  set:
    (id) =>
    ({ get, set }, newValue) => {
      // no idea what we do with DefaultValue
      if (newValue instanceof DefaultValue) {
        // TODO: we should propagate reset and call reset on ALL blockHoldingsCategoryIds atoms
        return;
      }

      // select all
      const allCategories = get(allHoldingsCategories(id));
      const settings = get(blockSettings(id));

      const allCategoriesToCheck = isTreeBlock(settings.customBlockType)
        ? allCategories.flatMap((category) => category.children).map((category) => category.id)
        : allCategories.map((category) => category.id);

      const selectedIds = newValue.map((category) => category.id);
      const selectedIdStrings = selectedIds.map((id) => id.id);
      if (allCategoriesToCheck.every((id) => selectedIdStrings.includes(id.id))) {
        // undefined means all categories selected
        set(blockHoldingsCategoryIds(id), undefined);
        return;
      }

      // if not select all then either filter to set of IDs or an empty array for select none
      set(blockHoldingsCategoryIds(id), selectedIds);
    },
});
