import type { FC, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor, Headline1, Headline3, Subtitle1, Icon } from 'venn-ui-kit';

interface Props {
  children?: ReactNode;
  className?: string;
  extraPadding?: boolean;
}

export const ModalHeader: FC<React.PropsWithChildren<Props>> = ({ className, extraPadding, children }) => (
  <Header className={className} extraPadding={extraPadding}>
    {children}
  </Header>
);

export const ModalSubhead: FC<React.PropsWithChildren<Props>> = ({ className, extraPadding, children }) => (
  <SubHeader className={className} extraPadding={extraPadding}>
    {children}
  </SubHeader>
);

export const ModalDescription: FC<React.PropsWithChildren<Props>> = ({ className, extraPadding, children }) => (
  <Description className={className} extraPadding={extraPadding}>
    {children}
  </Description>
);

export const ModalBackToPrev: FC<
  React.PropsWithChildren<{ className?: string; backToLabel: string; onClick: () => void }>
> = ({ className, backToLabel, onClick }) => (
  <BackToPrev className={className} onClick={onClick}>
    <Icon type="chevron-circle-left" />
    Back to {backToLabel}
  </BackToPrev>
);

export default ModalHeader;

const Header = styled<FC<Props>>(Headline1)`
  font-size: 32px;
  color: ${GetColor.Black};
  ${({ extraPadding }) => `margin: ${extraPadding ? '0 30px 20px 30px' : '0 20px 10px 20px'};`}
`;

const SubHeader = styled<FC<Props>>(Headline3)`
  color: ${GetColor.Black};
  ${({ extraPadding }) => `margin: ${extraPadding ? '0 30px 10px 30px' : '0 20px 5px 20px'};`}
`;

const Description = styled<FC<Props>>(Subtitle1)`
  margin: 0 20px 5px 20px;
  ${({ extraPadding }) => `margin: ${extraPadding ? '0 30px 10px 30px' : '0 20px 5px 20px'};`}
`;

const BackToPrev = styled.button`
  width: calc(100% + 2px);
  margin: -1px;
  padding: 0 12px;
  height: 40px;
  min-height: 40px;
  line-height: 24px;
  background-color: ${GetColor.Black};
  color: ${GetColor.White};
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  i {
    color: ${GetColor.Primary.Main};
    font-size: 14px;
    margin-right: 10px;
  }
`;
