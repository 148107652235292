import type { ErrorInfo, ReactNode } from 'react';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  retryTimeoutId: NodeJS.Timeout | null;
}

const ERROR_THROTTLE_MS = 100;

class ReactGridLayoutErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    retryTimeoutId: null,
  };

  componentDidCatch(error: Error, _info: ErrorInfo): void {
    if (error.stack?.includes('resolveCompactionCollision') && !this.state.hasError) {
      const timeout = setTimeout(() => this.setState({ hasError: false }), ERROR_THROTTLE_MS);
      if (this.state.retryTimeoutId !== null) {
        clearTimeout(this.state.retryTimeoutId);
      }
      this.setState({ hasError: true, retryTimeoutId: timeout });
    }
  }

  componentWillUnmount(): void {
    const { retryTimeoutId } = this.state;

    if (retryTimeoutId !== null) {
      clearTimeout(retryTimeoutId);
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return <Wrapper hasError={hasError}>{children}</Wrapper>;
  }
}

const Wrapper = styled.div<{ hasError: boolean }>`
  ${({ hasError }) =>
    hasError &&
    css`
      pointer-events: none;
    `}
`;

export default ReactGridLayoutErrorBoundary;
