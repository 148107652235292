import React, { forwardRef } from 'react';

import { Body1 } from 'venn-ui-kit';
import { getCustomFontSize, type CustomFont } from 'venn-state';

import { type HTMLProps } from '../factory';

export type BlockSubHeadlineElement = React.ElementRef<'div'>;
export interface BlockSubHeadlineProps extends HTMLProps<'div'> {
  font: CustomFont;
  children: React.ReactNode;
}

export const BlockSubHeadline = forwardRef<BlockSubHeadlineElement, BlockSubHeadlineProps>((props, ref) => {
  const { font, children } = props;

  return (
    <Body1 ref={ref} style={{ fontSize: getCustomFontSize(font) }}>
      {children}
    </Body1>
  );
});

BlockSubHeadline.displayName = 'BlockSubHeadline';
