import type { FrequencyEnum, ReturnsHistogram } from 'venn-api';
import type { AnalysisSubject, AnalysisGroup, AnyDuringEslintMigration } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import type { ContentChartLegendItem } from '../../../content-chart-legend';
import { merge, concat } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import type * as Highcharts from 'highcharts';
import { getTooltipBackgroundColor } from '../chart-config-logic';

export const getReturnsDistributionChartConfigOptions = (
  subject: AnalysisSubject,
  frequency: FrequencyEnum,
  analysisGroup: AnalysisGroup<ReturnsHistogram>,
  legend: ContentChartLegendItem[],
  theme: Theme,
  relative: boolean,
  print: boolean,
): Highcharts.Options => {
  const benchmarkName = subject.activeBenchmarkName;
  const managerName = subject.name;
  const netPeriodReturns = analysisGroup?.subject?.returns || [];
  const benchmarkNetPeriodReturns = analysisGroup?.benchmark?.returns || [];
  const baselineNetPeriodReturns = analysisGroup?.category?.returns || [];
  const series: Highcharts.SeriesColumnOptions[] = [
    {
      type: 'column',
      name: managerName,
      color: getSafeLegendColor(legend, 0),
      data: concat(
        [0],
        netPeriodReturns.map(({ count }) => count),
      ),
    },
  ];

  const hasBaselineSerie = subject.type === 'portfolio' && !!analysisGroup.category;
  const hasBenchmarkSerie = !!benchmarkName && benchmarkNetPeriodReturns.length && !relative;

  if (hasBaselineSerie) {
    series.push({
      type: 'column',
      name: getSecondaryDisplayLabel(
        subject,
        `${subject.secondaryPortfolio?.name} as of`,
        `: ${subject.secondaryPortfolio?.name}`,
      ),
      color: getSafeLegendColor(legend, 1),
      data: concat(
        [0],
        baselineNetPeriodReturns.map(({ count }) => count),
      ),
    });
  }

  if (hasBenchmarkSerie) {
    const benchmarkColorIndex = 1 + Number(hasBaselineSerie);
    series.push({
      type: 'column',
      name: benchmarkName || '',
      color: getSafeLegendColor(legend, benchmarkColorIndex),
      data: concat(
        [0],
        benchmarkNetPeriodReturns.map(({ count }) => count),
      ),
    });
  }

  if (subject.type === 'investment' && analysisGroup.category) {
    const categoryColorIndex = 1 + Number(hasBaselineSerie) + Number(hasBenchmarkSerie);
    series.push({
      type: 'column',
      name: `Category: ${subject?.categoryGroup?.name}`,
      color: getSafeLegendColor(legend, categoryColorIndex),
      data: concat(
        [0],
        baselineNetPeriodReturns.map(({ count }) => count),
      ),
    });
  }

  const yAxisLabel = getYAxisLabel(frequency);
  const options: Highcharts.Options = {
    chart: {
      height: print ? 200 : undefined,
    },
    xAxis: {
      categories: concat(
        [''],
        netPeriodReturns.map(({ name }) => name),
      ),
      labels: {
        autoRotationLimit: 120,
        style: {
          whiteSpace: 'nowrap',
          fontSize: print ? '10px' : '14px',
        },
      },
    },
    yAxis: {
      labels: {
        align: 'left',
        reserveSpace: false,
        x: 10,
        y: 5,
        style: {
          fontSize: print ? '10px' : '14px',
        },
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          if (this.isFirst) {
            return `${this.value} ${yAxisLabel}`;
          }
          return this.value as AnyDuringEslintMigration;
        },
      },
    },
    series,
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          color: legend[0].color,
        },
        events: {
          legendItemClick() {
            return false;
          },
        },
        pointPadding: 0,
        groupPadding: series.length > 1 ? 0.1 : 0,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  return merge({}, getDefaultChartConfig(theme, print), options);
};

const getSafeLegendColor = (legend: ContentChartLegendItem[], index: number): string => {
  if (!legend || legend.length === 0 || !legend[index]) {
    return '';
  }
  return legend[index].color;
};

const getDefaultChartConfig = (theme: Theme, print: boolean): Highcharts.Options => {
  const {
    Colors,
    Schemes: { BarChartColors },
  } = theme;
  return {
    rangeSelector: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      fallbackToExportServer: true,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    title: undefined,
    chart: {
      type: 'column',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 85,
      spacingLeft: 0,
      spacingBottom: 0,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: 1,
      labels: {
        style: {
          color: Colors.MidGrey2,
          fontSize: print ? '10px' : '14px',
        },
      },
      lineColor: BarChartColors.lineColor,
      tickColor: BarChartColors.lineColor,
      lineWidth: 1,
      endOnTick: true,
      startOnTick: true,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: BarChartColors.lineColor,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: false,
      title: {
        text: null,
      },
      tickWidth: 0,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: getTooltipBackgroundColor(Colors),
      borderRadius: 10,
      borderWidth: 0,
      style: {
        color: Colors.White,
      },
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        const { color, series, x, y } = this;
        return `<div>${x}</div>
      <span style="color:${color}">\u25CF</span> ${series.name}: <b>${y}</b>`;
      },
    },
  };
};

export const getYAxisLabel = (frequency: FrequencyEnum): string => {
  switch (frequency) {
    case 'DAILY':
      return 'days';
    case 'MONTHLY':
      return 'mos.';
    case 'YEARLY':
      return 'years';
    case 'QUARTERLY':
      return 'quarters';
    default:
      return 'mos.';
  }
};
