import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface PortfolioCellProps {
  textAlign?: TextAlignment;
  checkbox?: boolean;
}

export type TextAlignment = 'start' | 'end' | 'center';

export const PortfolioCell = styled.td<PortfolioCellProps>`
  position: relative;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  padding: ${(props) => (props.checkbox ? '0 0 0 10px' : '0 10px')};
  white-space: nowrap;
  font-weight: normal;
  color: ${GetColor.Black};
  text-align: ${(props) => props.textAlign || 'left'};

  .with-tooltips {
    body & {
      display: flex;
    }
  }
`;

export const PortfolioRow = styled.tr`
  height: 34px;
  line-height: 34px;
  border-bottom: 1px solid ${GetColor.PaleGrey};

  .row-controls {
    opacity: 0;
  }
  &:hover .row-controls {
    opacity: 1;
  }
`;

export interface HeaderCellProps extends PortfolioCellProps {
  /**
   * If true, cell should have a thick black top border
   * Used for topmost grouped cells in Nav Data and Returns Data
   */
  topBorder?: boolean;
}

export const PortfolioHeaderCell = styled(PortfolioCell).attrs({ as: 'th' })`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.DarkGrey};
  ${(props: HeaderCellProps) =>
    props.topBorder
      ? css`
          color: ${GetColor.Black};
          border-top: 3px solid ${GetColor.Black};
          text-transform: uppercase;
          letter-spacing: 1px;
        `
      : ''};
`;
