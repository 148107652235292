import React, { useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { compact, isEmpty, isNil } from 'lodash';

import {
  blockDateRange,
  blockDisplayHeader,
  blockExportMetadata,
  blockFonts,
  blockInfoGraphicType,
  blockSettings,
  customizedBlock,
  isReportState,
  PRINT_CHART_HEIGHT,
  recoilBlockMaxSize,
  viewDataGridSizeType,
} from 'venn-state';

import { Block } from '../components/core';
import { DefaultDateRange } from '../components/secondary/date-ranges';
import { BaseGrid } from '../components/visualizations/grids/base-grid';

import { useAppPrintMode } from '../../print';
import { DataGridSizeType, useSetBlockSize } from '../../studio-blocks';
import { useAnalysis } from '../../studio-blocks/logic/useAnalysis';
import { useMetaRows } from '../../studio-blocks/logic/useAnalysisGrid';
import {
  useNotablePeriodsChartData,
  useNotablePeriodsGridData,
} from '../../studio-blocks/components/blocks/notable-historical/useNotablePeriodsData';
import { useNotablePeriodsColumnDefs } from '../../studio-blocks/components/blocks/notable-historical/useNotablePeriodsColumnDefs';
import { ColumnChart } from '../components/visualizations/charts/column-chart';
import { getAnalyzedSubjectFromRequestSubject } from 'venn-utils';
import { useSubjectColors } from '../../studio-blocks/logic/useSubjectColors';

type BlockWidths =
  | {
      width: number;
      containerWidth?: undefined;
    }
  | { width?: undefined; containerWidth: number };

type NotableHistoricalBlock = {
  id: string;
  index: number;
  externalBlockWrapper?: boolean;
  focusBlockIndex: React.MutableRefObject<number | undefined>;
  focusBlockRef: React.RefObject<HTMLDivElement>;
  handleOverlapsError;
  pageFooterRef;
  blockLayout;
  hasVirtualization;
} & BlockWidths;

export const NotableHistoricalBlock = ({
  id,
  index,
  width,
  externalBlockWrapper,
  focusBlockIndex,
  focusBlockRef,
  containerWidth,
  handleOverlapsError,
  pageFooterRef,
  blockLayout,
  hasVirtualization,
}: NotableHistoricalBlock) => {
  const localBlockRef = useRef<HTMLDivElement>(null);
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const title = useRecoilValue(blockDisplayHeader(id));
  const titleFont = useRecoilValue(blockFonts.blockTitle(id));
  const dateRange = useRecoilValue(blockDateRange(id));
  const isReport = useRecoilValue(isReportState);
  const onBlockResize = useSetBlockSize(recoilBlockMaxSize.transformedState(id));
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));
  const { requests, analyses } = useAnalysis(id); // TODO: Ensure requests and analysis are filtered correctly
  const isCompact = useRecoilValue(viewDataGridSizeType(id)) === DataGridSizeType.COMPACT;
  const exportMetaData = useRecoilValue(blockExportMetadata(id));

  const blockRef = (focusBlockIndex.current === index ? focusBlockRef : undefined) || localBlockRef;
  const rootProps = {
    id,
    title,
    index,
    externalBlockWrapper,
    focusBlockIndex,
    focusBlockRef,
    blockRef,
    blockWidths: { width, containerWidth } as BlockWidths,
    handleOverlapsError,
    pageFooterRef,
    blockLayout,
    hasVirtualization,
  };

  return (
    <Block.Root {...rootProps}>
      <Block.Header inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline font={titleFont} isReport={isReport}>
            {title}
          </Block.Headline>
        </Block.Title>
        <Block.Metadata>
          <DefaultDateRange dateRange={dateRange} />
        </Block.Metadata>
      </Block.Header>
      <Block.Content onResize={onBlockResize}>
        {infoGraphicType === 'GRID' ? (
          <Grid
            id={id}
            requests={requests}
            analyses={analyses}
            inPrintMode={inPrintModeOrReportLab}
            isCompact={isCompact}
            exportMetaData={exportMetaData}
            isReport={isReport}
          />
        ) : infoGraphicType === 'DISTRIBUTE_BAR' ? (
          <Chart
            id={id}
            blockRef={blockRef}
            requests={requests}
            analyses={analyses}
            inPrintMode={inPrintModeOrReportLab}
            exportMetaData={exportMetaData}
            isReport={isReport}
          />
        ) : null}
      </Block.Content>
    </Block.Root>
  );
};

const Grid = ({ id, requests, analyses, inPrintMode, isCompact, exportMetaData, isReport }) => {
  const metaRows = useMetaRows(requests);
  const dataRows = useNotablePeriodsGridData(analyses);
  const rowData = useMemo(() => [...metaRows, ...dataRows], [metaRows, dataRows]);

  const columnDefs = useNotablePeriodsColumnDefs({ requests, analysesGroup: analyses, gridData: dataRows });

  return (
    <BaseGrid
      refId={id}
      isCompact={isCompact}
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      exportable
      rowData={rowData}
      columnDefs={columnDefs}
      isReport={isReport}
    />
  );
};

const Chart = ({ id, blockRef, requests, analyses, inPrintMode, exportMetaData, isReport }) => {
  const selectedBlock = useRecoilValue(customizedBlock(id));
  const blockSetting = useRecoilValue(blockSettings(id));

  const analyzedSubjects = useMemo(
    () => requests.map(({ subject }) => getAnalyzedSubjectFromRequestSubject(subject)),
    [requests],
  );
  const subjectColors = useSubjectColors(analyzedSubjects);

  const height = inPrintMode && !isReport ? PRINT_CHART_HEIGHT : undefined;
  // We filter selectedMetrics on blockSetting.metrics because metric order should be preserved,
  // even though reversing this logic would have simpler code.
  // We do this filter to remove any unsupported selectedMetrics.
  const metrics = useMemo(
    () => compact(selectedBlock.selectedMetrics.map((m) => blockSetting.metrics.find((metric) => metric.key === m))),
    [selectedBlock, blockSetting.metrics],
  );

  const rowData = useNotablePeriodsChartData(analyses);

  if (isNil(rowData) || isEmpty(rowData)) {
    return null;
  }

  return (
    <ColumnChart
      refId={id}
      requests={requests}
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      rowData={rowData}
      downloadableContentRef={blockRef}
      height={height}
      selectedBlock={selectedBlock}
      metrics={metrics}
      customBlockType={blockSetting.customBlockType}
      subjectColors={subjectColors}
    />
  );
};
