import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import React from 'react';
import type { PeerGroupAnalysisResponse } from 'venn-api';
import { usePeerGroupsChartConfig } from './usePeerGroupsChartConfig';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { StudioHighchart } from '../../../../highchart/Highchart';

HighchartsMore(Highcharts);

type ChartProps = {
  data: PeerGroupAnalysisResponse;
};

export const PeerGroupBoxChart = ({ data }: ChartProps) => {
  const { boxOptions, isEmpty } = usePeerGroupsChartConfig(data);
  const blockId = useBlockId();

  if (isEmpty) {
    return null;
  }

  return (
    <StudioHighchart
      options={boxOptions}
      blockId={blockId}
      className="qa-chart-container"
      style={{ marginTop: '4px' }}
    />
  );
};
