import React, { useCallback, useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import type { GeneralChartProps } from '../types';
import {
  convertReturnDataToExcel,
  convertScenarioColumnChartDataToExcel,
  getDefaultChartConfig,
  getOptions,
} from './utils';
import useExportUpdate from '../../../logic/useExportUpdate';
import type { ColumnData, ScenarioColumnData } from '../../../types';
import { isNil, merge } from 'lodash';
import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import { WATERMARK_POSITION_TOP } from '../../../customAnalysisContants';
import StyledEmptyState from '../../StyledEmptyState';
import { formatExportableSubjectWithOptionalFee, formatSubjectWithOptionalFee } from '../../../../legend';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';
import { blockFonts, blockSubjectHasProxyState } from 'venn-state';
import { StudioHighchart } from '../../../../highchart/Highchart';

/** responseParser is an old system, new usage of columnChart should use columnData directly */
type ColumnChartProps =
  | (GeneralChartProps & { rowData?: undefined })
  | (Omit<GeneralChartProps, 'responseParser'> & {
      responseParser?: undefined;
      rowData: ColumnData[];
    });

export const ColumnChart = ({
  analyses,
  requests,
  inPrintMode,
  exportMetaData,
  height,
  responseParser,
  selectedBlock,
  downloadableContentRef,
  metrics,
  customBlockType,
  subjectColors,
  rowData: initialRowData,
}: ColumnChartProps) => {
  const blockId = useBlockId();
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const subjectHasProxy = useRecoilValue(blockSubjectHasProxyState(blockId));
  const theme = useContext(ThemeContext);

  const rowData = useMemo(
    () => initialRowData || (responseParser.parser(selectedBlock, analyses) as ColumnData[]),
    [initialRowData, responseParser, selectedBlock, analyses],
  );

  const chartConfig = useMemo(() => {
    if (!rowData?.length) {
      return undefined;
    }

    const options = getOptions({
      customBlockType,
      print: Boolean(inPrintMode),
      percent: rowData[0].percent,
      yAxisLabel: rowData[0].yAxisLabel ?? '',
      yAxisTitle: rowData[0].yAxisTitle,
      xAxisCategories: rowData[0].xAxisCategories,
      height,
      series: requests.map((request, index) => ({
        type: 'column' as const,
        name: formatSubjectWithOptionalFee(request.subject, !!subjectHasProxy[request.subject.id]),
        color: subjectColors[index],
        data: [...(rowData?.[index]?.seriesData || [])],
      })),
      typography: theme.Typography,
      legendFontSize: legendFont.fontSizePt,
      axisFontSize: axisFont.fontSizePt,
    });
    return merge({}, getDefaultChartConfig(theme, Boolean(inPrintMode)), options);
  }, [
    rowData,
    customBlockType,
    inPrintMode,
    height,
    requests,
    theme,
    legendFont.fontSizePt,
    axisFont.fontSizePt,
    subjectHasProxy,
    subjectColors,
  ]);

  const excelDataFn = useCallback(() => {
    const subjectNames = requests.map(({ subject }) => formatExportableSubjectWithOptionalFee(subject));
    const relatives = requests.map(({ relative }) => relative);
    const benchmarkNames = requests.map(({ relative, benchmark }) => (relative ? benchmark?.name : undefined));
    return (
      (customBlockType === 'SCENARIO'
        ? convertScenarioColumnChartDataToExcel(
            rowData.filter(({ exportable }) => exportable) as ScenarioColumnData[],
            subjectNames,
            relatives,
            benchmarkNames,
          )
        : convertReturnDataToExcel(
            rowData.filter(({ exportable }) => exportable),
            subjectNames,
            relatives,
          )) || []
    );
  }, [rowData, customBlockType, requests]);

  useExportUpdate({ selectedRefId: blockId, exportMetaData, excelDataFn });

  const chart = !isNil(chartConfig) ? (
    <StudioHighchart options={chartConfig} blockId={blockId} className="qa-chart-container" style={{ flex: 1 }} />
  ) : (
    <StyledEmptyState selectedRefId={blockId} header="Unable to run analysis" message="Chart is empty" />
  );

  if (inPrintMode) {
    return chart;
  }

  const defaultHeader =
    selectedBlock?.relativeToBenchmark && metrics?.[0]?.relativeLabel
      ? metrics[0].relativeLabel
      : !metrics?.[0]
        ? 'Builder Chart'
        : metrics[0].label;

  return (
    <DownloadableContentBlock
      header={null}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ?? defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: rowData ? rowData.length === 0 : true,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      {chart}
    </DownloadableContentBlock>
  );
};
