import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import type { Granularity, DateRange } from '../types';
import { GetColor } from '../../../style/color';
import { ColorUtils } from '../../../style/colorUtils';
import Icon from '../../icon/Icon';
import KeyCodes from '../../../KeyCode';
import { Label } from '../../typography';
import { DAY_SIZE, MONTH_SIZE, YEAR_SIZE } from '../constants';
import { formatRange } from './logic';

interface TriggerProps {
  value?: DateRange;
  granularity: Granularity;
  opened?: boolean;
  disabled?: boolean;
  altStyle?: boolean;
  className?: string;
  onClick?: () => void;
}

const Trigger = ({ value, granularity, opened, disabled, altStyle, className, onClick }: TriggerProps) => {
  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === KeyCodes.Enter || e.keyCode === KeyCodes.Space) {
        if (onClick && !disabled) {
          e.preventDefault();
          onClick();
        }
      }
    },
    [onClick, disabled],
  );
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === KeyCodes.Enter || e.keyCode === KeyCodes.Space) {
      e.preventDefault();
    }
  }, []);
  return (
    <Container
      granularity={granularity}
      disabled={disabled}
      onClick={onClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      className={className}
      tabIndex={disabled ? -1 : 0}
      role="button"
      aria-expanded={opened}
      aria-haspopup
      aria-label="Choose Date"
      data-testid="qa-date-picker"
      altStyle={altStyle}
    >
      <IconContainer opened={opened} disabled={disabled} altStyle={altStyle}>
        <IconContainerInner opened={opened} disabled={disabled} altStyle={altStyle}>
          <CalendarIcon type="calendar" />
        </IconContainerInner>
      </IconContainer>
      <LabelContainer disabled={disabled} altStyle={altStyle}>
        <DateLabel altStyle={altStyle}>{formatRange(value, granularity)}</DateLabel>
      </LabelContainer>
    </Container>
  );
};

export const LabelContainer = styled.div<{ altStyle?: boolean; disabled?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.altStyle ? 'flex-start' : 'center')};
  border-radius: 0 4px 4px 0;
  ${(props) =>
    !props.altStyle &&
    css`
      border: solid 1px ${GetColor.Grey};
    `}
  border-left: none;
  background-color: ${(props) => (props.altStyle ? 'unset' : props.disabled ? GetColor.PaleGrey : GetColor.White)};
  font-size: ${(props) => (props.altStyle ? '14px' : 'inherit')};
  > label {
    display: block;
    color: ${(props) => (props.disabled ? GetColor.MidGrey1 : props.altStyle ? GetColor.White : GetColor.Black)};
  }
`;

export const IconContainer = styled.div<{ opened?: boolean; disabled?: boolean; altStyle?: boolean }>`
  width: ${({ altStyle }) => (altStyle ? '16px' : '35px')};
  border-radius: 4px 0 0 4px;
  ${({ altStyle }) =>
    !altStyle &&
    css`
      border: solid 1px ${GetColor.Grey};
    `}
  border-right: none;
  background-color: ${(props) => (props.altStyle ? 'unset' : props.disabled ? GetColor.PaleGrey : GetColor.White)};

  ${(props) =>
    props.opened && !props.disabled && !props.altStyle
      ? css`
          background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DivergingColor.B1, 0.5)};
          border-color: ${GetColor.Primary.Dark};
        `
      : ''}
`;

export const IconContainerInner = styled.div<{ opened?: boolean; disabled?: boolean; altStyle?: boolean }>`
  height: 33px;
  border-right: solid 1px ${(props) => (props.opened && !props.disabled ? GetColor.Primary.Dark : GetColor.Grey)};
  ${({ altStyle }) =>
    altStyle &&
    css`
      border: none;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarIcon = styled(Icon)`
  font-size: 14px;
  color: ${GetColor.Primary.Dark};
`;

export const Container = styled.div<{
  granularity: Granularity;
  disabled?: boolean;
  fullWidth?: boolean;
  altStyle?: boolean;
}>`
  display: flex;
  height: 35px;
  width: ${(props) =>
    props.fullWidth
      ? '100%'
      : props.granularity === 'day'
        ? `${DAY_SIZE}px`
        : props.granularity === 'month'
          ? `${MONTH_SIZE}px`
          : `${YEAR_SIZE}px`};
  ${(props) =>
    props.altStyle &&
    css`
      width: unset;
      height: unset;
      white-space: nowrap;
    `};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.disabled ? GetColor.HintGrey : 'unset')};
  font-style: ${(props) => (props.disabled ? 'italic' : 'unset')};

  ${CalendarIcon} {
    color: ${(props) => (props.disabled ? GetColor.MidGrey1 : props.altStyle ? GetColor.White : GetColor.Primary.Dark)};
  }

  &:hover {
    ${CalendarIcon} {
      color: ${(props) =>
        props.disabled ? GetColor.MidGrey1 : props.altStyle ? GetColor.Primary.Main : GetColor.White};
    }

    ${IconContainer}, ${IconContainerInner} {
      background-color: ${(props) =>
        props.altStyle ? 'unset' : props.disabled ? GetColor.Grey : GetColor.Primary.Main};
      border-color: ${(props) =>
        props.altStyle ? 'unset' : props.disabled ? GetColor.MidGrey1 : GetColor.Primary.Main};
    }
  }
  &:focus {
    ${IconContainer} {
      border-color: ${(props) => (props.disabled ? GetColor.MidGrey1 : GetColor.Primary.Main)};
    }
    ${LabelContainer} {
      border-color: ${(props) => (props.disabled ? GetColor.MidGrey1 : GetColor.Primary.Main)};
    }
  }
`;

const DateLabel = styled(Label)<{
  altStyle?: boolean;
}>`
  ${({ altStyle }) =>
    altStyle &&
    css`
      font-size: 14px;
      margin-left: 8px;
    `};
`;

export default Trigger;
