import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { OperationResult, Portfolio, PortfolioCompare, PortfolioSummary } from 'venn-api';
import { getDraftSummary, getFund, getSpecificPortfolioV3, getSummary } from 'venn-api';
import { AnalysisSubject, logExceptionIntoSentry, useApi, useIsMounted } from 'venn-utils';
import type { PortfolioLabBenchmarkState } from 'venn-components';
import { isNil } from 'lodash';
import type { PortfolioLabStoredSettings } from './useValuesFromHistoryState';

interface UseBenchmarkValue extends PortfolioLabBenchmarkState {
  benchmarkSummary: PortfolioSummary | undefined;
  benchmarkSummaryError: boolean;
}

const useBenchmark = (
  portfolio: Portfolio | undefined,
  settings: PortfolioLabStoredSettings,
  storeSettings: (values: Partial<PortfolioLabStoredSettings>) => void,
): UseBenchmarkValue => {
  const [benchmarkSubject, setBenchmarkSubjectInState] = useState<AnalysisSubject | undefined>();
  const benchmarkPseudoPortfolio: Portfolio | undefined = useMemo(() => {
    if (benchmarkSubject?.type !== 'investment') {
      return benchmarkSubject?.portfolio;
    }
    const now = Date.now();
    return {
      id: now,
      allocation: portfolio?.allocation,
      name: benchmarkSubject.name,
      draft: true,
      demo: false,
      master: false,
      closingAllocationsTs: [],
      historical: false,
      children: [
        {
          id: now + 1,
          allocation: portfolio?.allocation,
          name: benchmarkSubject.name,
          fund: benchmarkSubject.fund,
          draft: true,
          demo: false,
          master: false,
          children: [],
          closingAllocationsTs: [],
          historical: false,
        },
      ],
    };
  }, [benchmarkSubject, portfolio]);

  const setBenchmarkSubject = useCallback(
    (benchmark: AnalysisSubject | undefined) => {
      setBenchmarkSubjectInState(benchmark);
      storeSettings({ benchmarkSubject: benchmark });
    },
    [storeSettings],
  );

  const getPortfolioApiRef = useRef(useApi(getSpecificPortfolioV3));
  const getFundApiRef = useRef(useApi(getFund));
  const isMountedRef = useIsMounted();

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    const fetchBenchmark = async (compare: PortfolioCompare) => {
      try {
        if (compare.portfolioId) {
          const { content } = await getPortfolioApiRef.current(compare.portfolioId, undefined);
          setBenchmarkSubject(new AnalysisSubject(content, 'portfolio'));
        } else if (compare.fundId) {
          const { content } = await getFundApiRef.current(compare.fundId);
          setBenchmarkSubject(new AnalysisSubject(content, 'investment'));
        } else {
          setBenchmarkSubject(undefined);
        }
      } catch (e) {
        if (e.name !== 'AbortError') {
          setBenchmarkSubject(undefined);
          logExceptionIntoSentry(e);
        }
      }
    };

    if (!isNil(settings.portfolioId) && settings.portfolioId === portfolio?.id) {
      setBenchmarkSubject(settings.benchmarkSubject);
      return;
    }

    if (isNil(portfolio)) {
      return;
    }

    const primaryBenchmark = (portfolio?.compare ?? []).find((compare) => compare.primary);
    if (isNil(primaryBenchmark)) {
      setBenchmarkSubject(undefined);
      return;
    }
    fetchBenchmark(primaryBenchmark);
  }, [portfolio, settings.portfolioId, settings.benchmarkSubject, isMountedRef, setBenchmarkSubject]);

  const [summary, setSummary] = useState<PortfolioSummary | undefined>();
  const [summaryError, setSummaryError] = useState(false);
  const getSummaryApiRef = useRef(useApi(getBenchmarkSummary));
  useEffect(() => {
    const fetchSummary = async (portfolioDraft: Portfolio, getById: boolean) => {
      try {
        const { content } = await getSummaryApiRef.current(portfolioDraft, getById);
        setSummary(content);
        setSummaryError(false);
      } catch (e) {
        if (e.name !== 'AbortError') {
          setSummary(undefined);
          setSummaryError(true);
          logExceptionIntoSentry(e);
        }
      }
    };
    if (!isNil(benchmarkPseudoPortfolio)) {
      fetchSummary(benchmarkPseudoPortfolio, benchmarkSubject?.type === 'portfolio');
    } else {
      setSummary(undefined);
      setSummaryError(false);
    }
  }, [benchmarkPseudoPortfolio, benchmarkSubject]);

  const onChangeBenchmark = useCallback(
    (updatedBenchmark: AnalysisSubject | undefined) => {
      setBenchmarkSubject(updatedBenchmark);
      if (isNil(updatedBenchmark)) {
        setSummary(undefined);
      }
    },
    [setBenchmarkSubject],
  );

  return {
    benchmarkPseudoPortfolio,
    benchmarkSubject,
    benchmarkSummary: summary,
    benchmarkSummaryError: summaryError,
    onChangeBenchmark,
  };
};

export default useBenchmark;

const getBenchmarkSummary = async (
  body: Portfolio,
  getById: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<PortfolioSummary>> => {
  if (getById) {
    return getSummary(body.id, signal);
  }
  return getDraftSummary(body, signal);
};
