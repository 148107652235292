import type React from 'react';
import { useEffect, useState } from 'react';
import type { MFAResponse, OperationError, OperationResult } from 'venn-api';
import { setupTOTPMFA, verifyTOTPMFA } from 'venn-api';
import { useQuery } from '@tanstack/react-query';

interface UseEnableAuthenticatorProps {
  onComplete(): void;
}

interface UseEnableAuthenticator {
  authenticatorInfo: MFAResponse | null;
  code: string;
  onVerificationCodeChange: (code: string) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  error: string;
}

const SETUP_TOTP_KEY = 'SETUP_TOTP';

const useEnableAuthenticator = ({ onComplete }: UseEnableAuthenticatorProps): UseEnableAuthenticator => {
  const [authenticatorInfo, setAuthenticatorInfo] = useState<MFAResponse | null>(null);
  const [code, setCode] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [error, setError] = useState('');
  const onVerificationCodeChange = (code: string) => {
    setCode(code);
  };

  const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setVerifyLoading(true);
    try {
      await verifyTOTPMFA({ code });
      setError('');
      onComplete();
    } catch (e) {
      setError(e.content?.message);
    }
    setVerifyLoading(false);
  };

  const {
    data,
    isLoading: isSetupLoading,
    error: setupError,
    isError: isSetupError,
  } = useQuery<MFAResponse>([SETUP_TOTP_KEY], async () => {
    return (await setupTOTPMFA()).content;
  });
  useEffect(() => {
    if (data) {
      setAuthenticatorInfo(data);
    }
    if (isSetupError) {
      setError((setupError as OperationResult<OperationError>).content?.message);
    }
  }, [data, isSetupError, setupError]);

  return {
    authenticatorInfo,
    code,
    onVerificationCodeChange,
    onSubmit,
    loading: verifyLoading || isSetupLoading,
    error,
  };
};

export default useEnableAuthenticator;
