import React from 'react';
import { UniversalUploaderFooter } from '../../../components/page-parts/UniversalUploaderFooter';
import { useFooter } from './logic/useFooter';

export const Footer = () => {
  const { onCancel, onContinue, disabled, primaryTooltip } = useFooter({ isHistorical: false });

  return (
    <UniversalUploaderFooter
      noMargin
      onCancel={onCancel}
      onContinue={onContinue}
      primaryLabel="Complete Upload"
      disabled={disabled}
      primaryTooltip={primaryTooltip}
    />
  );
};
