import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import type { match, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { GeneralAnalysisTemplate } from 'venn-api';
import type { AnalysisConfig } from 'venn-utils';
import { AnalysisSubject, getTemplateById, Routes } from 'venn-utils';
import { GetColor } from 'venn-ui-kit';

import { Grid, Analysis, Content, MinWidth } from './Layout';
import TemplatePanel from './side-panel/TemplatePanel';
import Title from './header/Title';
import AnalysisBlocks from './AnalysisBlocks';
import type { SharedProps } from '../types';
import useAnalysis from '../logic/useAnalysis';
import {
  AnalysisContext,
  FactorLensesContext,
  Footer,
  PortfoliosContext,
  UnsavedChangesModal,
  UserContext,
  TABS,
} from 'venn-components';
import { TRACK_TIMESTAMPS_AFTER } from '../logic/useTrackFailedAnalysis';

interface CreateTemplatePageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CreateTemplatePage = ({ history, match: matchParam }: CreateTemplatePageProps) => {
  const { id } = matchParam.params;
  const { demoPortfolio } = useContext(PortfoliosContext);
  const { profileSettings } = useContext(UserContext);
  const { templates } = useContext(AnalysisContext);
  const { factorLenses } = useContext(FactorLensesContext);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);

  const performanceBlock = getTemplateById(templates, 'PERFORMANCE_SUMMARY');

  const newTemplate = useMemo(() => {
    const template =
      profileSettings?.user && performanceBlock
        ? ({
            ...performanceBlock,
            id: '',
            name: '',
            description: '',
            appUser: profileSettings?.user,
            created: undefined,
            updated: undefined,
          } as GeneralAnalysisTemplate)
        : null;
    return id ? (getTemplateById(templates, id, 'CUSTOM') ?? template) : template;
  }, [id, templates, profileSettings, performanceBlock]);

  const [analysisConfig, setAnalysisConfig] = useState<AnalysisConfig>({
    analysisTemplate: newTemplate,
    subject: undefined,
    selectedTimeFrame: {},
    relative: false,
    category: 'HIDDEN',
    trackingId: -1,
  });

  const changes = useMemo(() => {
    if (!analysisConfig.analysisTemplate || !newTemplate) {
      return false;
    }

    if (!id) {
      return true;
    }

    const currentblocks = newTemplate?.analysisBlocks;
    const blocks = analysisConfig?.analysisTemplate?.analysisBlocks;

    if (currentblocks.length !== blocks.length) {
      return true;
    }

    return currentblocks.some((block, idx) => block.analysisBlockType !== blocks[idx].analysisBlockType);
  }, [analysisConfig, id, newTemplate]);

  useEffect(() => {
    if (changes) {
      UnsavedChangesModal.blockHistory(
        () => setShowUnsavedChangesModal(true),
        (pathName: string) => !pathName.match(new RegExp(Routes.CREATE_TEMPLATE)),
      );
    } else {
      UnsavedChangesModal.unblockHistory();
    }
    return () => UnsavedChangesModal.unblockHistory();
  }, [changes]);

  const onChangeAnalysis = useCallback((analysisTemplate: GeneralAnalysisTemplate) => {
    setAnalysisConfig((prevConfig: AnalysisConfig) => ({
      ...prevConfig,
      analysisTemplate,
      trackingId: Date.now() - TRACK_TIMESTAMPS_AFTER,
    }));
  }, []);

  const { analysesError, analysesResults, loadingAnalysis } = useAnalysis(analysisConfig);

  useEffect(() => {
    if (demoPortfolio) {
      const subject = new AnalysisSubject(demoPortfolio, 'portfolio');
      setAnalysisConfig((prevConfig) => ({
        ...prevConfig,
        subject,
        trackingId: Date.now() - TRACK_TIMESTAMPS_AFTER,
      }));
    }
  }, [demoPortfolio]);

  const sharedProps: SharedProps = {
    analysisConfig,
    analysesError,
    analysisLoading: loadingAnalysis,
    factorLenses,
    onResetTimeFrame: undefined,
    analyses: analysesResults,
  };

  if (!newTemplate) {
    history.push(`${Routes.ACCOUNT_PATH}/${TABS.WORKSPACE_CONFIGURATION}`);
    return null;
  }

  return (
    <Grid>
      <TemplatePanel analysisConfig={analysisConfig} onChangeAnalysis={onChangeAnalysis} templates={templates} />
      <Analysis>
        <Content>
          <TitleContainer>
            <Title subject={analysisConfig.subject} />
          </TitleContainer>
          <Divider />
          <AnalysisBlocks {...sharedProps} />
        </Content>
        <MinWidth>
          <Footer />
        </MinWidth>
      </Analysis>
      {showUnsavedChangesModal && <UnsavedChangesModal onCancel={() => setShowUnsavedChangesModal(false)} />}
    </Grid>
  );
};

export default withRouter(CreateTemplatePage);

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 200px;
`;

const Divider = styled.div`
  height: 30px;
  margin-bottom: 24px;
  background: ${GetColor.WhiteGrey};
`;
