import type { Portfolio, AllocationConstraint } from 'venn-api';

export { filterConstraintsNotInSubtree } from './AllocationConstraintsUtils';

export function buildInitialAllocationConstraints(portfolio: Portfolio, strategyId?: number): AllocationConstraint[] {
  const constraints: AllocationConstraint[] = [];
  buildAllocations(portfolio, strategyId || portfolio.id, false, constraints);
  return constraints;
}

const buildAllocations = (
  node: Portfolio,
  selectedRootId: number,
  isInSubtree: boolean,
  constraints: AllocationConstraint[],
  parentId?: number,
  parentMaxAllocation?: number,
) => {
  if (!node) {
    return;
  }

  const isRoot = parentId === null;
  const isEmptyStrategy = node.fund === undefined && (node.children === undefined || node.children.length === 0);

  const maxAllocation =
    node.allocation === 0 && !isRoot && !isEmptyStrategy && parentMaxAllocation
      ? parentMaxAllocation
      : isRoot
        ? (node.allocation ?? 0)
        : (node.allocation ?? 0) * 2;

  // Fund
  if (node.fund !== undefined) {
    if (isInSubtree || selectedRootId === node.id) {
      constraints.push({
        newFund: false,
        fund: node.fund,
        minAllocation: 0,
        maxAllocation,
        portfolioId: parentId,
      });
    }
    return;
  }

  // Strategy
  node.children.forEach((child: Portfolio) => {
    buildAllocations(
      child,
      selectedRootId,
      isInSubtree || selectedRootId === node.id,
      constraints,
      node.id,
      maxAllocation,
    );
  });

  if (isInSubtree && parentId !== null) {
    constraints.push({
      newFund: false,
      fund: undefined,
      minAllocation: 0,
      maxAllocation,
      portfolioId: node.id,
    });
  }
};
