import type { Granularity, DateRange } from '../types';
import moment from 'moment';
import { assertExhaustive } from 'venn-utils';

function format(date: number | null | undefined, granularity: Granularity) {
  if (!date) {
    return '--';
  }
  switch (granularity) {
    case 'day':
      return moment.utc(date).format('D MMM YYYY');
    case 'month':
      return moment.utc(date).format('MMM YYYY');
    case 'year':
      return moment.utc(date).format('YYYY');
    default:
      throw assertExhaustive(granularity);
  }
}

export function formatRange(value: DateRange | undefined, granularity: Granularity | undefined) {
  if (!value?.from || !value?.to || !granularity) {
    return '--';
  }

  return `${format(value.from, granularity)} - ${format(value.to, granularity)}`;
}

export function formatEndOfRange(value: DateRange | undefined, granularity: Granularity) {
  if (!value?.to) {
    return '--';
  }

  return `${format(value.to, granularity)}`;
}
