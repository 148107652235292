import React, { useCallback } from 'react';
import BaseDropMenu from './BaseDropMenu';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';
import IconTrigger from '../triggers/IconTrigger';
import type { CheckboxMenuProps } from '../menus/CheckboxMenu';
import AddableCheckboxMenu from '../menus/AddableCheckboxMenu';
import { Tooltip } from '../../tooltip/Tooltip';
import type { TooltipPosition } from '../../enums';
import styled from 'styled-components';

interface IconCheckboxDropMenuProps extends CheckboxMenuProps<string>, BaseDropMenuProps<string>, MenuContainerProps {
  icon: string;
  iconSize?: number;
  solid?: boolean;
  dark?: boolean;
  border?: boolean;
  borderSize?: number;
  placeholder?: string;
  emptyState?: JSX.Element;
  tooltip?: React.ReactNode;
  tooltipPosition?: TooltipPosition;
  left?: number;
  onOpen?: () => void;
  triggerText?: string;
  isReadOnly?: boolean;
}

export const IconCheckboxDropMenu = ({
  items,
  openByDefault,
  usePortal,
  width,
  height,
  onChange,
  icon,
  iconSize,
  onCollapse,
  onOpen,
  dark,
  placeholder,
  emptyState,
  solid,
  border,
  borderSize,
  tooltip,
  tooltipPosition,
  left,
  triggerText,
  isReadOnly,
}: IconCheckboxDropMenuProps) => {
  const triggerComponent = useCallback(
    (expanded, _, onToggle) => (
      <Tooltip content={expanded ? undefined : tooltip} position={tooltipPosition} usePortal>
        <IconTrigger
          dark={dark}
          solid={solid || items.some((i) => i.checked)}
          icon={icon}
          size={iconSize}
          expanded={expanded}
          onClick={(open) => {
            onToggle(open);
          }}
          border={border}
          borderSize={borderSize}
          text={triggerText}
        />
      </Tooltip>
    ),
    [icon, iconSize, dark, solid, items, border, borderSize, tooltip, tooltipPosition, triggerText],
  );

  const menuComponent = useCallback(
    (highlighted, onMenuCollapse, menuClassName) => (
      <AddableCheckboxMenu
        placeholder={placeholder}
        width={width}
        height={height}
        items={items}
        onChange={onChange}
        onCollapse={() => {
          onMenuCollapse();
        }}
        emptyState={emptyState}
        className={menuClassName}
        isReadOnly={isReadOnly}
      />
    ),
    [placeholder, width, height, items, onChange, emptyState, isReadOnly],
  );

  return (
    <StyledDropMenu
      left={left}
      openByDefault={openByDefault}
      usePortal={usePortal}
      filteredItems={items}
      onCollapse={onCollapse}
      onOpen={onOpen}
      triggerComponent={triggerComponent}
      menuComponent={menuComponent}
    />
  );
};

export default IconCheckboxDropMenu;

const StyledDropMenu = styled(BaseDropMenu)<{ left?: number }>`
  ${(props) => props.left && `left: -${props.left}px;`}
`;
