import type { FC } from 'react';
import React from 'react';
import type { AllocationItem } from 'venn-utils';
import { MAX_CHARS } from '../constraintsFormatUtils';
import { GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { isNil } from 'lodash';
import styled from 'styled-components';

interface ItemsTooltipProps {
  items: AllocationItem[];
  rootId: number;
}

const ItemsTooltip: FC<React.PropsWithChildren<ItemsTooltipProps>> = ({ items, rootId, children }) => {
  if (items.length === 0) {
    return children;
  }
  if (items.length === 1) {
    return (
      <Tooltip content={items[0].name} isHidden={items[0].name.length <= MAX_CHARS}>
        {children}
      </Tooltip>
    );
  }

  const investmentItems = items.filter((item) => item.fund);
  const strategyItems = items.filter((item) => item.id !== rootId && isNil(item.fund));
  const portfolioItems = items.filter((item) => item.id === rootId);

  const containsMultipleGroups =
    Number(investmentItems.length > 0) + Number(strategyItems.length > 0) + Number(portfolioItems.length > 0) > 1;

  if (containsMultipleGroups) {
    return (
      <Tooltip
        content={
          <div>
            {portfolioItems.length > 0 && (
              <>
                <h3>
                  <strong>Portfolio:</strong>
                </h3>
                {portfolioItems.map(({ name, id }) => (
                  <li key={id}>{name}</li>
                ))}
              </>
            )}
            {strategyItems.length > 0 && (
              <>
                <h3>
                  <strong>Strategies:</strong>
                </h3>
                {strategyItems.map(({ name, id }) => (
                  <li key={id}>{name}</li>
                ))}
              </>
            )}
            {investmentItems.length > 0 && (
              <>
                <h3>
                  <strong>Investments:</strong>
                </h3>
                {investmentItems.map(({ name, id, draft }) => (
                  <li key={id}>
                    {draft ? (
                      <Highlight>
                        {name}
                        <NewOpportunityIcon type="plus-circle" />
                      </Highlight>
                    ) : (
                      name
                    )}
                  </li>
                ))}
              </>
            )}
          </div>
        }
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      content={
        <div>
          {items.map(({ name, id, draft }) => (
            <li key={id}>
              {draft ? (
                <Highlight>
                  {name}
                  <NewOpportunityIcon type="plus-circle" />
                </Highlight>
              ) : (
                name
              )}
            </li>
          ))}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default ItemsTooltip;

const Highlight = styled.span`
  color: ${GetColor.HighlightLight};
`;

const NewOpportunityIcon = styled(Icon)`
  color: ${GetColor.HighlightLight};
  margin-left: 4px;
`;
