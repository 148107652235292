import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  Flexbox,
  GetColor,
  getTextThemeProvider,
  Headline1,
  PEER_GROUP_ANALYSIS_FAQ_HREF,
  Subtitle1,
} from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';

export const PeerGroupAnalysisTab = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <MainContainer>
      <Flexbox direction="column">
        <Flexbox direction="row" alignItems="center" gap={4}>
          <StyledHeadline>Peer Group Analysis</StyledHeadline>
        </Flexbox>
        <StyledSubtitle>
          Level up your manager due diligence, using a rich set of analytics to inform your decisions.
        </StyledSubtitle>
        <Flexbox direction="row">
          <StyledImg
            key={Images.peerGroupAnalysisAsset}
            src={Images.peerGroupAnalysisAsset}
            alt="peer group analsysi in-app demo"
          />
        </Flexbox>
        <InfoRow>
          <InfoTextArea>
            <Flexbox direction="row">
              <InfoHeader>Search and Screen</InfoHeader>
            </Flexbox>
            <Flexbox direction="row">
              <InfoText>
                Select your preferred investment peer <br /> group based on asset class or strategy.
              </InfoText>
            </Flexbox>
          </InfoTextArea>
          <InfoTextArea>
            <Flexbox direction="row">
              <InfoHeader>Peer Group Analysis Block</InfoHeader>
            </Flexbox>
            <Flexbox direction="row">
              <InfoText>
                Visualize potential investments against <br /> your selected universe of peers.
              </InfoText>
            </Flexbox>
          </InfoTextArea>
          <InfoTextArea>
            <Flexbox direction="row">
              <InfoHeader>Manager Due Diligence</InfoHeader>
            </Flexbox>
            <Flexbox direction="row">
              <InfoText>Leverage extensive analytics to compare managers across multiple peers and metrics.</InfoText>
            </Flexbox>
          </InfoTextArea>
        </InfoRow>
        <Flexbox direction="row" gap={16}>
          <StyledButton
            onClick={() => {
              window.open(PEER_GROUP_ANALYSIS_FAQ_HREF, '_blank');
              analyticsService.ctaClicked({
                destination: 'Peer group analysis help article',
                filled: true,
                locationOnPage: 'Peer group analysis carousel tab',
                purpose: 'Learn more about peer group analysis',
                text: 'Learn more',
                type: 'button',
              });
            }}
            className="qa-learn-more"
            data-testid="qa-learn-more"
            style={{ width: 328, height: 40 }}
          >
            Learn more
          </StyledButton>
          <StyledButton
            onClick={() => {
              window.location.href = `mailto:${getTextThemeProvider().salesEmail}?subject=Peer Group Analysis Inquiry`;
              analyticsService.ctaClicked({
                destination: 'Peer group analysis inquiry email',
                filled: true,
                locationOnPage: 'Peer group analysis carousel tab',
                purpose: 'Inquire about peer group analysis',
                text: 'Questions? Contact us',
                type: 'button',
              });
            }}
            className="qa-questions-mailto"
            data-testid="qa-questions-mailto"
            style={{ width: 328, height: 40 }}
          >
            Questions? Contact us
          </StyledButton>
        </Flexbox>
      </Flexbox>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  margin-bottom: 36px;
`;

const StyledHeadline = styled(Headline1)`
  font-weight: 700;
  font-style: normal;
  line-height: 60px;
  margin-top: 36px;
  font-size: 46px;
`;

const StyledSubtitle = styled(Subtitle1)`
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 29px;
  color: #292929;
  width: 100%;
`;

const StyledImg = styled.img`
  width: 100%;
  padding: 15px 0px;
`;

const InfoHeader = styled.div`
  color: ${GetColor.PeerGroupCarouselColor};
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const InfoTextArea = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 355px;
`;

const InfoText = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`;

const StyledButton = styled.button`
  display: flex;
  height: 40px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 2px;
  border: 1px solid ${GetColor.PeerGroupCarouselColor};
  background: rgba(0, 129, 138, 0.02);
  color: ${GetColor.Black};
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
`;
