import React from 'react';
import { Button, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import classNames from 'classnames';

interface SaveFiltersMenuTriggerProps {
  /** The label to display on the trigger */
  label: string;
  /** Function to call on click of the trigger */
  onClick: () => void;
  /** Class name for the button. Allows this component to be styled by styled-components */
  className?: string;
}

const buttonStyle: React.CSSProperties = {
  borderRadius: 0,
  borderColor: `${GetColor.White}`,
  minHeight: 20,
};

/**
 * The trigger / button to click to open up a library filter menu
 */
const SaveFiltersTriggerComponent = ({ label, onClick, className }: SaveFiltersMenuTriggerProps) => {
  return (
    <Button
      className={classNames('qa-library-save-filter-trigger', className)}
      noMargin
      onClick={onClick}
      style={buttonStyle}
      flat
    >
      <Label aria-label={label}>{label}</Label>
    </Button>
  );
};

export default SaveFiltersTriggerComponent;

const Label = styled.label`
  cursor: pointer;
  font-size: 11px;
  display: flex;
  align-items: center;
`;
