import React from 'react';
import { getDateBehavior, hasDisabledDateRange, requiresAnalysisRequest, useDebugValue, useHasFF } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { blockDateRange, blockPrivateDefaultAsOfDateBehavior, blockSettings } from 'venn-state';
import StyledEmptyState from '../StyledEmptyState';
import type { ErrorWrapper } from './errorWrapperTypes';

export const DateRangeErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const hasExtendFullHistoryFf = useHasFF('extend_full_history_ff');

  const dateRange = useRecoilValue(blockDateRange(blockId));
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const startAsOfLastTransaction = useRecoilValue(blockPrivateDefaultAsOfDateBehavior(blockId));
  const dateBehaviour = getDateBehavior(blockSetting.customBlockType);
  const notUsingDateRange = dateBehaviour === 'END_DATE_RANGE' && startAsOfLastTransaction;
  const needsDateRange =
    requiresAnalysisRequest(blockSetting.customBlockType) &&
    !(hasDisabledDateRange(blockSetting.customBlockType) || notUsingDateRange);

  const debugState = useDebugValue('debugBlockErrorState');
  const forceNoDateRange = debugState === 'NoDateRange';
  const forceNoOverlap = debugState === 'NoOverlap';

  if (needsDateRange) {
    if (!dateRange || forceNoDateRange) {
      return (
        <StyledEmptyState
          selectedRefId={blockId}
          header="Select a date range input"
          message="Configure the block's date range input in the block settings panel."
        />
      );
    }

    const { range, maxRange } = dateRange;
    if (
      !hasExtendFullHistoryFf &&
      ((range.from && maxRange.to && range.from >= maxRange.to) ||
        (range.to && maxRange.from && range.to <= maxRange.from))
    ) {
      // Once the extend full history flag is enabled, the backend will handle this case itself.
      // But for now we need to handle it explicitly in the frontend.
      return (
        <StyledEmptyState
          selectedRefId={blockId}
          header="Unable to run analysis"
          message={<div>The date range selected is outside of the available range.</div>}
        />
      );
    }

    if (maxRange.from === undefined || maxRange.to === undefined || forceNoOverlap) {
      return (
        <StyledEmptyState
          selectedRefId={blockId}
          header="Unable to run analysis"
          message={
            <>
              <div>Unable to calculate the selected date range.</div>
              <div>
                The subjects included in the date range have <b>no common overlap.</b>
              </div>
            </>
          }
        />
      );
    }
  }

  return children;
};
