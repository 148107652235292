import React, { createContext, type FC, useEffect, useMemo, useState } from 'react';
import type { UserOrgRoleEnum } from 'venn-api';
import { getUserOrgRoleOptions } from 'venn-api';

type UserRoleInOrgOptionsMap = { [key in UserOrgRoleEnum]: string };

interface RoleInOrganizationOptionsContextValue {
  userRolesInOrgOptions?: UserRoleInOrgOptionsMap;
  loadingOptions: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const RoleInOrganizationOptionsContext = createContext<RoleInOrganizationOptionsContextValue>({
  userRolesInOrgOptions: undefined,
  loadingOptions: false,
});

const RoleInOrganizationOptionsContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [userRolesInOrgOptions, setUserRolesInOrgOptions] = useState<UserRoleInOrgOptionsMap>();
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

  useEffect(() => {
    const loadOptions = async () => {
      setLoadingOptions(true);
      try {
        const result = (await getUserOrgRoleOptions()).content;
        setUserRolesInOrgOptions(result as UserRoleInOrgOptionsMap);
      } catch (e) {
        setUserRolesInOrgOptions({} as UserRoleInOrgOptionsMap);
      }
      setLoadingOptions(false);
    };
    loadOptions();
  }, []);

  const roleContextValue = useMemo(() => {
    return {
      userRolesInOrgOptions,
      loadingOptions,
    };
  }, [userRolesInOrgOptions, loadingOptions]);

  return (
    <RoleInOrganizationOptionsContext.Provider value={roleContextValue}>
      {children}
    </RoleInOrganizationOptionsContext.Provider>
  );
};

export default RoleInOrganizationOptionsContextProvider;
