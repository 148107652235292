import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import type { CustomFactorForecast, InvestmentForecast, CMAGrouping } from 'venn-api';
import { getFactorCMAs } from 'venn-api';
import { Link as LinkStyle, Icon, Body1, Headline3, FORECASTS_FAQ_HREF } from 'venn-ui-kit';
import { useApi } from 'venn-utils';
import CapitalMarketAssumptionsEditorV2 from '../components/CapitalMarketAssumptionsEditorV2';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';

interface CapitalMarketAssumptionsViewV2Props {
  forecast?: CustomFactorForecast;
  onClose: () => void;
}

export const CapitalMarketAssumptionsViewV2 = ({ forecast, onClose }: CapitalMarketAssumptionsViewV2Props) => {
  const isEditing = forecast !== undefined;
  const [cmaGroups, setCMAGroups] = useState<CMAGrouping[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const abortableGetFactorCMAs = useApi(getFactorCMAs);
  const { onUpdateOrCreateFactorForecast } = useContext(ForecastPanelActionsContext);
  useEffect(() => {
    const onMount = async () => {
      setLoading(true);
      try {
        const groups = (await abortableGetFactorCMAs('APP')).content;
        setCMAGroups(groups);
        setLoading(false);
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        }
        setCMAGroups(null);
        setLoading(false);
      }
    };
    onMount();
  }, [abortableGetFactorCMAs]);

  const onSubmit = useCallback(
    (assumptions: InvestmentForecast[], name: string, editedForecast?: CustomFactorForecast) => {
      !editedForecast
        ? onUpdateOrCreateFactorForecast(
            {
              name,
              investmentForecasts: assumptions,
            } as CustomFactorForecast,
            true,
          )
        : onUpdateOrCreateFactorForecast(
            {
              ...editedForecast,
              name,
              investmentForecasts: assumptions,
            },
            false,
          );
    },
    [onUpdateOrCreateFactorForecast],
  );

  return (
    <ViewContainer>
      <SubHeading>{isEditing ? 'Edit' : 'Enter'} Your Capital Market Assumptions</SubHeading>
      <Body1>Assumptions must be annualized averages. </Body1>
      <LinkBody>
        <LinkStyle>
          <a target="_blank" rel="noopener noreferrer" href={FORECASTS_FAQ_HREF}>
            <Icon type="up-right-from-square" /> Learn more
          </a>
        </LinkStyle>
      </LinkBody>
      <CapitalMarketAssumptionsEditorV2
        forecast={forecast}
        cmaGroups={cmaGroups}
        isLoadingCMAGroups={loading}
        onCancel={onClose}
        onSubmitAssumptions={onSubmit}
      />
    </ViewContainer>
  );
};

const SubHeading = styled(Headline3)`
  margin-top: 18px;
`;

const LinkBody = styled(Body1)`
  margin-bottom: 20px;
`;

const ViewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
