import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { GetColor, ColorUtils, Button, Subtitle2 } from 'venn-ui-kit';
import { useRecoilValue, waitForAll } from 'recoil';
import type { BenchmarkInputId } from 'venn-state';
import { benchmarkInputName, benchmarkInputs } from 'venn-state';
import { Message } from '../../../../../shared';
import { without } from 'lodash';
import { SelectBenchmarkInputSection } from './SelectBenchmarkInputSection';
import { BenchmarkEditor } from 'venn-components';
import { InputDropMenu } from '../../components/shared';
import { MAX_INPUTS_PER_KIND } from 'venn-utils';

/** For selecting or editing a subject input. */
export const BenchmarkInputDropMenu = React.memo(function BenchmarkInputDropMenu({
  selectedInputId,
  onSelectInput,
  readonly,
}: {
  selectedInputId?: BenchmarkInputId;
  onSelectInput: (selectedInputId: BenchmarkInputId) => void;
  readonly: boolean;
}) {
  const [editorOpen, setEditorOpen] = useState(false);
  const menuInputIds = useRecoilValue(benchmarkInputs);
  const menuInputNames = useRecoilValue(waitForAll(menuInputIds.map((groupId) => benchmarkInputName(groupId))));
  const inputDropMenuItems = menuInputIds.map((groupId, index) => ({ value: groupId, label: menuInputNames[index] }));

  const menuComponent = useCallback(
    (highlighted, forceCollapse, className) => (
      <MenuPopup className={className}>
        <BenchmarkInputSelectorMenuContent
          inputIds={menuInputIds}
          checkedInputId={highlighted?.value}
          onSelectGroup={(selectedGroupId) => {
            onSelectInput(selectedGroupId);
            forceCollapse();
          }}
          openEditor={() => {
            setEditorOpen(true);
            forceCollapse();
          }}
        />
      </MenuPopup>
    ),
    [menuInputIds, onSelectInput],
  );
  return (
    <span>
      {editorOpen && (
        <BenchmarkEditor
          benchmarkId={undefined}
          onClose={(savedGroupId) => {
            setEditorOpen(false);
            savedGroupId && onSelectInput(savedGroupId);
          }}
        />
      )}
      <InputDropMenu
        // usePortal is necessary otherwise the dropmenu is constrained by the parent panel and cannot overflow
        usePortal
        // minimumItemsToTrigger of 0 is necessary so that when no benchmark inputs are available, user can still use the menu UI to create a new group
        minimumItemsToTrigger={0}
        items={inputDropMenuItems}
        menuComponent={menuComponent}
        selected={selectedInputId}
        width={180}
        disabled={readonly}
      />
    </span>
  );
});

/** Sizing constraints on contents, but no styling or layout. */
const MenuPopup = styled.div`
  width: 280px;
  height: 434px;
`;

/**
 * Layout, styling, and content for the menu, but no constraints on width and height as that is delegated to the parent.
 */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const BenchmarkInputSelectorMenuContent = React.memo(
  ({
    inputIds,
    checkedInputId,
    onSelectGroup,
    openEditor,
  }: {
    inputIds: BenchmarkInputId[];
    checkedInputId?: BenchmarkInputId;
    onSelectGroup: (selectedGroupId: BenchmarkInputId) => void;
    openEditor: () => void;
  }) => {
    /** Sorted with the checked input Id, if it exists, to the first index. */
    const sortedInputIds = useMemo(
      () => (checkedInputId ? [checkedInputId, ...without(inputIds, checkedInputId)] : inputIds),
      [checkedInputId, inputIds],
    );

    return (
      <BenchmarkInputMenuContentWrapper>
        <BenchmarkInputMenuBody>
          <BenchmarkInputMenuHeader>Benchmark Inputs</BenchmarkInputMenuHeader>
          {sortedInputIds.length ? (
            sortedInputIds.map((inputId) => (
              <SelectBenchmarkInputSection
                key={inputId}
                inputId={inputId}
                isChecked={checkedInputId === inputId}
                onClick={onSelectGroup}
              />
            ))
          ) : (
            <Message colorOverride={ColorUtils.opacifyFrom(GetColor.Warning, 0.1)}>
              No benchmark inputs have been created yet.
            </Message>
          )}
        </BenchmarkInputMenuBody>

        <BenchmarkInputMenuFooter>
          <Button dense onClick={openEditor} icon="search" disabled={inputIds.length >= MAX_INPUTS_PER_KIND}>
            Select New Benchmark
          </Button>
        </BenchmarkInputMenuFooter>
      </BenchmarkInputMenuContentWrapper>
    );
  },
);

/** Wraps the content with layout, and container-level styling. */
const BenchmarkInputMenuContentWrapper = styled.div`
  background-color: ${GetColor.White};
  border-radius: 4px;
  border: solid 1px ${GetColor.Grey};
  box-shadow: 0 0 20px ${GetColor.Grey};
`;

const BenchmarkInputMenuHeader = styled(Subtitle2)`
  margin: 10px 0 10px 8px;
`;

const BenchmarkInputMenuBody = styled.div`
  min-height: 0;
  overflow: auto;
  padding-bottom: 16px;
  max-height: 357px;
`;

const BenchmarkInputMenuFooter = styled.footer`
  height: 70px;

  border: solid 1px ${GetColor.Grey};

  /** Horizontally and vertically center contents. */
  display: flex;
  justify-content: center;
  align-items: center;
`;
