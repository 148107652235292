import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { CheckboxMenuProps } from './CheckboxMenu';
import BaseMenu from './BaseMenu';
import CheckboxMenuItem from './CheckboxMenuItem';
import { checkHierarchyItem } from '../logic/checkbox-logic';
import styled from 'styled-components';
import type { DropMenuCheckboxItem } from '../types';
import { GetColor } from '../../../style/color';
import { ColorUtils } from '../../../style/colorUtils';

interface AddableDropMenuCheckboxItem extends DropMenuCheckboxItem<string> {
  isBeingAdded?: boolean;
}

interface AddableCheckboxMenuProps extends CheckboxMenuProps<string> {
  placeholder?: string;
  emptyState?: JSX.Element;
  isReadOnly?: boolean;
}

const AddableCheckboxMenu = ({
  items,
  width,
  height,
  onChange,
  placeholder,
  emptyState,
  onCollapse,
  isReadOnly,
}: AddableCheckboxMenuProps) => {
  const [search, setSearch] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>(null);

  const allItems = useMemo(() => {
    const trimmedSearch = search.trim();
    const hasNewItem = trimmedSearch && !items.some((item) => item.value.trim() === trimmedSearch);
    if (hasNewItem) {
      return [
        {
          value: trimmedSearch,
          label: `${trimmedSearch} (create new)`,
          checked: false,
          isBeingAdded: true,
        },
        ...items,
      ];
    }
    return items;
  }, [search, items]);

  const filteredItems = useMemo(
    () => allItems.filter((item) => item.value.toLowerCase().includes(search.trim().toLowerCase())),
    [allItems, search],
  );

  const onCheckOne = (item: AddableDropMenuCheckboxItem) => () => {
    const modifiedItems = checkHierarchyItem(allItems, item);
    if (search.length && (item.isBeingAdded || filteredItems.length === 1) && searchRef.current) {
      setSearch('');
      searchRef.current.focus();
    }
    onChange(modifiedItems);
  };

  const onCheckOnly = (item: AddableDropMenuCheckboxItem) => () => {
    // Uncheck everything first, then check the one
    const modifiedItems = checkHierarchyItem(
      allItems.map((i) => ({
        ...i,
        checked: false,
        indeterminate: false,
      })),
      {
        ...item,
        checked: false,
        indeterminate: false,
      },
    );
    onChange(modifiedItems);
    onCollapse?.();
  };

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <BaseMenu<string, AddableDropMenuCheckboxItem>
      items={filteredItems}
      width={width}
      height={height || 500}
      emptyStateComponent={emptyState}
      headerComponent={
        <Search
          value={search}
          onChange={(s) => setSearch(s.target.value)}
          placeholder={placeholder}
          onClick={(e) => e.stopPropagation()}
          data-testid="qa-checkbox-menu-input"
          ref={searchRef}
        />
      }
      renderItem={(item) => (
        <CheckBoxMenuPadding key={item.value}>
          <CheckboxMenuItem<string>
            item={item}
            onCheck={onCheckOne(item)}
            onOnly={onCheckOnly(item)}
            isReadOnly={isReadOnly}
          />
        </CheckBoxMenuPadding>
      )}
    />
  );
};

export default AddableCheckboxMenu;

const CheckBoxMenuPadding = styled.div`
  padding: 3px 0;
  &:hover {
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1)};
  }
`;

const Search = styled.input`
  font-weight: normal;
  font-size: 14px;
  width: 100%;
`;
