import type { IHelpCenterLinks, ILegalDocs, IMarcomSiteLinks, TextThemeProvider } from '../src/getTextThemeProvider';

const helpCenterLinks: IHelpCenterLinks = {
  INTERCOM_HELP_ALL_HREF: 'https://help.venn.twosigma.com',
  FACTOR_EXPOSURE_HREF:
    'https://help.venn.twosigma.com/en/articles/1075640-what-is-factor-exposure-%CE%B2-and-how-is-it-computed',
  FACTOR_CONTRIBUTION_TO_RISK_HREF:
    'https://help.venn.twosigma.com/en/articles/1075637-what-is-factor-contribution-to-risk-and-how-is-it-computed',
  FACTOR_CONTRIBUTION_TO_RETURN_HREF:
    'https://help.venn.twosigma.com/en/articles/1075638-what-is-factor-contribution-to-return-and-how-is-it-computed',
  INTERCOM_HELP_HREF: 'https://help.venn.twosigma.com/en/articles/1393204-two-sigma-factor-selection-methodology',
  METRIC_DEFINITIONS_HREF: 'https://help.venn.twosigma.com/en/articles/3590856-metric-definitions',
  FORECASTS_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/2386506-faq-forecasts',
  DEMO_PORTFOLIO_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/2447538-demo-portfolio-information',
  DEMO_PRIVATE_PORTFOLIO_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/8618307-private-asset-demo-portfolio-information',
  STUDIO_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/5704646-faq-studio',
  BENCHMARK_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/1547109-setting-benchmarks',
  FORECAST_RETURN_DEF_HREF: 'https://help.venn.twosigma.com/en/articles/3590856-metric-definitions#h_7c40a1b2d1',
  EXCESS_RETURN_DEF_HREF: 'https://help.venn.twosigma.com/en/articles/3590856-metric-definitions#h_0ac30dcc97',
  TRACKING_ERROR_DEF_HREF: 'https://help.venn.twosigma.com/en/articles/3590856-metric-definitions#h_3a162c0323',
  INFORMATION_RATIO_DEF_HREF: 'https://help.venn.twosigma.com/en/articles/3590856-metric-definitions#h_2d7f1dcb43',
  MAX_UNDERPERFORMANCE_DEF_HREF: 'https://help.venn.twosigma.com/en/articles/3590856-metric-definitions#h_3ef7abe0d6',
  HISTORICAL_RISK_STATISTICS_HREF: 'https://help.venn.twosigma.com/en/articles/3455892-historical-risk-statistics',
  PORTFOLIO_LAB_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/2350645-faq-optimization-and-portfolio-lab',
  ADVANCED_SEARCH_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/6249627-enhanced-data-library-advanced-search-overview-faq',
  HOLDINGS_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/6956454-faq-holdings',
  VENNCAST_FAQ_HREF: 'https://help.venn.twosigma.com/venncast/faq-homepage',
  CURRENCY_CONVERSION_ARTICLE_HREF: 'https://help.venn.twosigma.com/en/articles/2597160-currency-conversion',
  REBALANCE_STRATEGY_ARTICLE_HREF: 'https://help.venn.twosigma.com/en/articles/5359951-portfolio-rebalancing-frequency',
  INVESTMENT_CORRELATION_TOOL_HREF: 'https://help.venn.twosigma.com/fund-tearsheet/investment-correlation-tool',
  FACTOR_LENS_FAQ_HREF: 'https://help.venn.twosigma.com/articles/1392786-two-sigma-factor-lens-faq',
  CURRENCY_FAQ_HREF: 'https://help.venn.twosigma.com/data/which-currencies-does-venn-support',
  PROXY_FAQ_HREF: 'https://help.venn.twosigma.com/fund-search/potential-proxies',
  DESMOOTHING_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/6830715-private-asset-support-overview-desmoothing-of-private-asset-allocations',
  INTERPOLATION_EXTRAPOLATION_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/3494909-faq-interpolation',
  WORKSPACE_MANAGEMENT_ARTICLE_HREF: 'https://help.venn.twosigma.com/en/articles/4194884-workspace-management',
  GROWTH_SIMULATOR_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/6267282-faq-asset-growth-simulator',
  SCENARIO_ANALYSIS_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/3268635-faq-scenario-analysis',
  SENSITIVITY_ANALYSIS_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/3268635-faq-sensitivity-analysis',
  NOTABLE_PERIODS_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/4726378-faq-notable-historical-periods',
  CATEGORIES_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/3275165-faq-categories',
  FACTOR_ALERTS_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/2046788-two-sigma-factor-lens-tab',
  HISTORICAL_DRAWDOWN_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/6067247-faq-historical-drawdown-and-rally-scanner',
  ACCESSING_ANALYSES_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/4233771-faq-save-access-and-export-analyses',
  INVESTMENT_UPLOADER_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/2477744-investment-navs-uploader-faq',
  RETURNS_UPLOADER_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/2477736-returns-uploader-faq',
  MASTER_PORTFOLIO_ARTICLE_HREF:
    'https://help.venn.twosigma.com/en/articles/4277730-getting-started-on-venn#constructing-portfolios',
  ADDEPAR_HELP_HREF: 'https://help.venn.twosigma.com/en/articles/8239087-addepar-integration-overview',
  COMMON_BENCHMARKS_HELP_HREF: 'https://help.venn.twosigma.com/en/articles/8103009-common-benchmarks',
  PORTFOLIO_COMPARISON_HELP_HREF:
    'https://help.venn.twosigma.com/en/articles/8572876-feature-overview-portfolio-comparison',
  PRIVATE_ASSET_LAB_HELP_HREF: 'https://help.venn.twosigma.com/en/articles/8618299-feature-overview-private-asset-lab',
  PEER_GROUP_ANALYSIS_HELP_HREF:
    'https://help.venn.twosigma.com/en/articles/9279529-feature-overview-peer-group-analysis',
  PRIVATE_ASSET_UPLOADER_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/8618304-faq-uploading-private-asset-data-and-creating-a-private-asset-portfolio',
  PRIVATE_ASSET_CASH_FLOW_HELP_HREF:
    'https://help.venn.twosigma.com/en/articles/8618306-private-asset-support-overview-cash-flow-pacing-model-beta',
  PRIVATE_PERFORMANCE_HELP_HREF:
    'https://help.venn.twosigma.com/en/articles/9043477-private-asset-support-overview-performance-metrics-and-pme-beta',
  PORTFOLIO_CONSTRUCTION_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/4274571-portfolio-construction-and-configuration',
  STUDIO_REPORT_LAB_AIS_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/8505801-faq-studio-and-report-lab-with-advanced-input-settings',
  MISSING_INVESTMENTS_FAQ_HREF:
    'https://help.venn.twosigma.com/en/articles/8976939-potential-causes-of-deleted-investments',
  PUBLIC_PRIVATE_ASSET_GROWTH_HREF:
    'https://help.venn.twosigma.com/en/articles/9424582-private-asset-support-overview-holistic-asset-growth-simulation-beta',
  MULTI_PORTFOLIO_UPLOADER_FAQ_HREF: 'https://help.venn.twosigma.com/en/articles/10028784-multi-portfolio-uploader',
  ANALYSIS_PERIOD_HELP_HREF: 'https://help.venn.twosigma.com/en/articles/1075623-how-is-the-analysis-period-adjusted',
};

const marcomSiteLinks: IMarcomSiteLinks = {
  RESIDUALIZATION_URL:
    'https://www.venn.twosigma.com/vennsights/how-to-identify-independent-sources-of-risk-for-multi-asset-managers-and-portfolios',
  REVERSE_OPTIMIZATION_URL:
    'https://www.venn.twosigma.com/vennsights/the-latest-from-two-sigma-research-reverse-optimization',
  VENNSIGHTS_URL: 'https://www.venn.twosigma.com/vennsights',
  DISCLAIMERS: 'https://www.venn.twosigma.com/blog-disclaimer',
  FACTORS_ACTIONABLE_URL: 'https://www.venn.twosigma.com/vennsights/actionable-factors-0',
  FACTORS_HOLISTIC_URL: 'https://www.venn.twosigma.com/vennsights/holistic-factors-1',
  FACTORS_ORTHOGONAL_URL: 'https://www.venn.twosigma.com/vennsights/orthogonal-factors',
  FACTORS_PARSIMONIOUS_URL: 'https://www.venn.twosigma.com/vennsights/parsimonious-factors-2',
};

const legalDocuments: ILegalDocs = {
  COOKIE_POLICY: {
    baseUrl: '/api/legal/doc/cookie-policy',
    titles: [
      {
        language: 'EN',
        text: 'Cookie Policy',
      },
      {
        language: 'JA',
        text: 'クッキーポリシー',
      },
    ],
  },
  CRS_LINK: {
    baseUrl: '/api/legal/doc/crs',
    titles: [
      {
        language: 'EN',
        text: 'Form CRS',
      },
    ],
  },
  PRIVACY_POLICY_LINK: {
    baseUrl: '/api/legal/doc/privacy-policy',
    titles: [
      {
        language: 'EN',
        text: 'Privacy Policy',
      },
      {
        language: 'JA',
        text: 'プライバシー・ポリシー',
      },
    ],
  },
  SUBSCRIBER_AGREEMENT_LINK: {
    baseUrl: '/api/legal/doc/subscriber-agreement',
    titles: [
      {
        language: 'EN',
        text: 'Subscriber Agreement',
      },
      {
        language: 'JA',
        text: '利用登録契約',
      },
    ],
  },
  USER_AGREEMENT_LINK: {
    baseUrl: '/api/legal/doc/user-agreement',
    titles: [
      {
        language: 'EN',
        text: 'End User License Agreement',
      },
      {
        language: 'JA',
        text: 'エンドユーザー・ライセンス契約',
      },
    ],
  },
  SERVICE_TIERS_LINK: '/api/legal/doc/service-tiers',
};

const DefaultTextMap: TextThemeProvider = {
  AppTitle: 'Venn',
  VenncastName: 'Venncast',
  HelpCenterUrl: 'help.venn.twosigma.com',
  HelpCenterLinks: helpCenterLinks,
  MarcomSiteUrl: 'www.venn.twosigma.com',
  MarcomSiteLinks: marcomSiteLinks,
  tsParam: ' by Two Sigma',
  legalDocuments,
  supportEmail: 'support@venn.twosigma.com',
  salesEmail: 'vennsales@twosigma.com',
};

export default DefaultTextMap;
