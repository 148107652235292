import React from 'react';
import { MultiPortfolioReviewComponents } from './components/common/MultiPortfolioReviewComponents';
import type { RootProps } from './components/common/MultiPortfolioReviewComponents.Root';
import { MultiHistoricalReviewComponents } from './components/specialized/MultiHistoricalReviewComponents';

type MultiHistoricalPortfolioReviewStepProps = Omit<RootProps, 'children'>;

export const MultiHistoricalReviewStep = (props: MultiHistoricalPortfolioReviewStepProps) => {
  return (
    <MultiPortfolioReviewComponents.Root {...props}>
      <MultiPortfolioReviewComponents.Content>
        <MultiPortfolioReviewComponents.LeftSideBar />
        <MultiPortfolioReviewComponents.RightSideBar>
          <MultiHistoricalReviewComponents.RightPane.Root>
            <MultiHistoricalReviewComponents.RightPane.Banner />
            <MultiHistoricalReviewComponents.RightPane.ContentContainer>
              {({ portfolio }) => <MultiPortfolioReviewComponents.RightSideBarContent portfolio={portfolio} />}
            </MultiHistoricalReviewComponents.RightPane.ContentContainer>
          </MultiHistoricalReviewComponents.RightPane.Root>
        </MultiPortfolioReviewComponents.RightSideBar>
      </MultiPortfolioReviewComponents.Content>
      <MultiHistoricalReviewComponents.Footer />
      <MultiPortfolioReviewComponents.ConfirmationModal />
    </MultiPortfolioReviewComponents.Root>
  );
};
