import type { ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { analyticsService, focusUniversalSearch, getAnalysisPathForInvestment } from 'venn-utils';
import {
  Button,
  GetColor,
  Headline1,
  Hint,
  Link as LinkStyle,
  Tooltip,
  Icon,
  TooltipContentContainerStyled,
} from 'venn-ui-kit';

interface TutorialContentProps {
  headline: string | ReactNode;
  images: string[];
  hasInfoMessage?: boolean;
  assetHeight?: string;
  assetWidth?: string;
  hideBorder?: boolean;
}

export const INFO_MESSAGE =
  'Specific investments selected for analysis are for demonstration purposes only. They are not a recommendation as to ' +
  'any investment or an offer to sell any security, and their selection does not follow any particular source, methodology, or data.';

export const TutorialContent: React.ComponentType<React.PropsWithChildren<TutorialContentProps>> = ({
  headline,
  images,
  hasInfoMessage,
  assetHeight,
  assetWidth,
  hideBorder,
  children,
}) => {
  return (
    <TutorialContainer>
      <Headline1>
        {headline}
        {hasInfoMessage && (
          <InfoTooltip maxWidth={500} content={INFO_MESSAGE}>
            <InfoIcon prefix="fas" type="info-circle" />
          </InfoTooltip>
        )}
      </Headline1>
      <TutorialAssetContainer height={assetHeight} width={assetWidth} hideBorder={hideBorder}>
        {images.map((imgSrc) => (
          <StyledImg key={imgSrc} src={imgSrc} alt="line chart" />
        ))}
      </TutorialAssetContainer>
      <TutorialActions>{children}</TutorialActions>
    </TutorialContainer>
  );
};

interface TutorialProps {
  headline: string | ReactNode;
  fundId?: string;
  images: string[];
  customTemplate?: string;
  hasInfoMessage?: boolean;
  analyticsLocation?: string;
}

export const Tutorial = ({
  headline,
  fundId,
  images,
  customTemplate,
  hasInfoMessage,
  analyticsLocation,
}: TutorialProps) => {
  const history = useHistory();

  return (
    <TutorialContent headline={headline} images={images} hasInfoMessage={hasInfoMessage}>
      <div>
        <Button
          dominant
          disabled={!fundId}
          onClick={() => {
            history.push(getAnalysisPathForInvestment(fundId!, customTemplate ?? 'welcome'));
            analyticsService.ctaClicked({
              destination: 'Analysis Results',
              filled: true,
              locationOnPage: analyticsLocation,
              purpose: 'Discover Factor Analysis',
              text: 'See it in action',
              type: 'button',
            });
          }}
          className="qa-check-it"
          data-testid="qa-check-it"
        >
          Check it out
        </Button>
      </div>
      <TutorialAlternativeAction>
        <Hint>
          Or{' '}
          <LinkStyle>
            <Search role="button" onClick={focusUniversalSearch} className="qa-home-search-for-anything">
              search
            </Search>
          </LinkStyle>{' '}
          to analyze anything.
        </Hint>
      </TutorialAlternativeAction>
    </TutorialContent>
  );
};

export const TutorialAssetContainer = styled.div<{ hideBorder?: boolean; height?: string; width?: string }>`
  max-height: ${({ height }) => height || '200px'};
  height: ${({ height }) => height || '200px'};
  max-width: ${({ width }) => width || '715px'};
  border-radius: 4px;
  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border: 1px solid ${GetColor.WhiteGrey};
    `}
  margin-top: 22px;
  margin-bottom: 50px;
  display: flex;
  position: relative;
`;

export const TutorialAlternativeAction = styled.div`
  margin-top: 15px;
`;

export const TutorialActions = styled.div`
  display: flex;
  row-gap: 12px;
  flex-direction: column;
`;

export const TutorialContainer = styled.div`
  padding-bottom: 20px;
`;

const Search = styled.button`
  font-weight: bold;
`;

const StyledImg = styled.img`
  width: 100%;
`;

export const InfoIcon = styled(Icon)`
  font-size: 18px;
  padding: 5px;
  position: relative;
  top: -18px;
`;

export const InfoTooltip = styled(Tooltip)`
  line-height: 40px;
  ${TooltipContentContainerStyled} {
    position: relative;
    & > div {
      padding: 18px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;
