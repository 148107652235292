import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PrivatePortfolioNode } from 'venn-api';
import { globalSearch } from 'venn-api';
import { ConditionalOverlay, DataGrid } from 'venn-components';
import {
  defaultPrivatesColumnStyling,
  getPrivatesTableColumns,
} from '../../../home-page/src/components/privatesColumns';
import type { AgGridReact } from 'ag-grid-react';
import type { GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community';
import { isRequestSuccessful, logExceptionIntoSentry, useApi } from 'venn-utils';
import { searchPrivates } from '../../../home-page/src/components/filters/privates-search';
import type { ResultsInfo } from '../../../home-page/src/components/PrivatesTable';
import { resultsPendingState, toPrivatesRowData } from '../../../home-page/src/components/PrivatesTable';
import type { PrivatesSearchParams } from 'venn-state';
import { isEmpty, noop } from 'lodash';
import styled from 'styled-components';
import type { RowSelectionOptions } from 'ag-grid-community/dist/types/core/entities/gridOptions';

const PRIVATE_PORTFOLIO_PAGE_SIZE = 1e5;
interface PrivateInvestmentInformationProps {
  portfolio: PrivatePortfolioNode;
}

export const PrivatePortfolioInformation = ({ portfolio }: PrivateInvestmentInformationProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const [{ isLoading }, setResultsInfo] = useState<ResultsInfo>(resultsPendingState);

  // This effect is necessary to sync ag-grid with recoil state
  // reason being, ag-grid's server side datasource is registered on mount with a function telling how to fetch data
  // When it is registered, it captured some initial value of the state in a closure
  // which is sadly not updated even if callbacks are used. See the blog below:
  // https://dmitripavlutin.com/react-hooks-stale-closures/
  useEffect(() => {
    gridRef.current?.api?.refreshServerSide();
  }, [portfolio?.children]);

  const searchApi = useApi(globalSearch);
  const getRowId = useCallback((params) => params.data.id, []);

  const refreshSearch = useCallback(() => {
    gridRef.current?.api.refreshServerSide();
  }, []);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.setGridOption('serverSideDatasource', {
        getRows: async (getRowParams: IServerSideGetRowsParams) => {
          setResultsInfo((prevState) => {
            return {
              ...prevState,
              isLoading: true,
            };
          });
          try {
            const fundIds = portfolio?.children.map((x) => x.fundId);
            const result = await searchPrivates(
              {
                pageSize: isEmpty(fundIds) ? 0 : PRIVATE_PORTFOLIO_PAGE_SIZE,
                fundIds,
              } as PrivatesSearchParams,
              searchApi,
            );
            if (isRequestSuccessful(result)) {
              getRowParams.success({
                rowData: toPrivatesRowData(result.content.results),
                rowCount: result.content.results.length,
              });
              setResultsInfo({
                totalRowsCount: result.content.total,
                displayedRowsCount: result.content.results.length,
                isLoading: false,
              });
            } else {
              getRowParams.fail();
            }
          } catch (e) {
            if (e.name === 'AbortError') {
              return;
            }
            getRowParams.fail();
            logExceptionIntoSentry(e);
          }
        },
      });
    },
    [portfolio?.children, searchApi],
  );
  useEffect(() => {
    gridRef?.current?.api && onGridReady({ api: gridRef?.current?.api } as GridReadyEvent);
  }, [onGridReady, portfolio?.children, gridRef?.current?.api]);

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      lockPinned: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      editable: false,
      headerClass: 'ag-right-aligned-header',
      cellStyle: defaultPrivatesColumnStyling,
    }),
    [],
  );
  const rowStyle = useMemo(() => ({ cursor: 'pointer' }), []);
  const rowSelection: RowSelectionOptions = useMemo(
    () => ({
      mode: 'singleRow',
      headerCheckbox: false,
      checkboxes: false,
      enableClickSelection: false,
      enableSelectionWithoutKeys: false,
    }),
    [],
  );
  return (
    <Container>
      <ConditionalOverlay condition={isLoading} className="qa-library-table">
        <DataGrid
          columnDefs={useMemo(
            () =>
              getPrivatesTableColumns({
                updateData: refreshSearch,
                clearSelectedItems: noop,
                setSelectedRecoilState: noop,
                manageDataView: true,
                headerCheckboxSelected: false,
              }),
            [refreshSearch],
          )}
          gridRef={gridRef}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          onGridReady={onGridReady}
          getRowId={getRowId}
          rowStyle={rowStyle}
          headerHeight={41}
          rowHeight={37}
          rowBuffer={1e6}
          pagination
          paginationPageSize={1e6}
          suppressPaginationPanel
          rowModelType="serverSide"
          rowSelection={rowSelection}
          suppressContextMenu
          horizontalScrollbarHeight={6}
          serverSideEnableClientSideSort
        />
      </ConditionalOverlay>
    </Container>
  );
};

const Container = styled.div`
  .ag-header-cell {
    padding-right: 2px;
    &[col-id='updated'] {
      padding-right: 10px;
    }
  }
`;
