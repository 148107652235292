import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from '../../../../modal';

interface FooterButtonsProps {
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
  onContinue: () => void;
  onCancel: () => void;
  onStartOver: () => void;
  hasNoData?: boolean;
  isSample?: boolean;
  primaryLabel?: string;
}

const FooterButtons: FC<React.PropsWithChildren<FooterButtonsProps>> = ({
  disabled,
  disabledMessage,
  onContinue,
  onCancel,
  onStartOver,
  hasNoData,
  isSample,
  primaryLabel,
}) => {
  return (
    <FooterContainer>
      <ModalFooter
        className="no-margin-footer"
        cancelClassName="qa-upload-cancel"
        onCancel={isSample ? undefined : onCancel}
        rightChildren={disabledMessage}
        primaryLabel={hasNoData ? '' : (primaryLabel ?? 'Complete Upload')}
        primaryClassName="qa-accept-btn"
        primaryDisabled={disabled}
        onPrimaryClick={onContinue}
        secondaryLabel={isSample ? 'Try with Your Data' : 'Start Over'}
        onSecondaryClick={onStartOver}
      />
    </FooterContainer>
  );
};

export default FooterButtons;

const FooterContainer = styled.div`
  border-radius: 4px;
  .no-margin-footer {
    margin-top: 0;
  }
`;
