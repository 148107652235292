import type { VennColors } from '../../../style';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Pill from '../Pill';
import Icon from '../../icon/Icon';
import { StudioIcon } from '../../custom-icon';
import type { AnalysisViewTypeEnum, InvestmentSourceTypeEnum } from 'venn-api';
import { Tooltip } from '../../tooltip/Tooltip';
import { FundUtils } from 'venn-utils';
import { ItemType } from './itemType';
import { getItemLabel } from './getItemLabel';
import { getItemColor } from './getItemColor';
import { PrivatePortfolioIcon } from '../../custom-icon/PrivatePortfolioIcon';
import { PrivateInvestmentIcon } from '../../custom-icon/PrivateInvestmentIcon';

const getItemStyle = (
  Colors: VennColors,
  item: ItemType | AnalysisViewTypeEnum,
  fullCompositeBenchmarkLabel?: boolean,
) => {
  return {
    label: getItemLabel(item, fullCompositeBenchmarkLabel),
    color: getItemColor(Colors, item),
  };
};

export const ItemTypePill = ({
  item,
  isDisabled,
  fullCompositeBenchmarkLabel,
  type = 'normal',
}: {
  item: ItemType | AnalysisViewTypeEnum;
  isDisabled?: boolean;
  fullCompositeBenchmarkLabel?: boolean;
  type?: 'normal' | 'large';
}) => {
  const { Colors } = useContext(ThemeContext);
  const { color, label } = getItemStyle(Colors, item, !!fullCompositeBenchmarkLabel);

  if (type === 'large') {
    return (
      <LargeStyledPill className={`qa-${item}-type-pill`} color={isDisabled ? Colors.MidGrey1 : color}>
        {label}
      </LargeStyledPill>
    );
  }
  return (
    <StyledPill className={`qa-${item}-type-pill`} color={isDisabled ? Colors.MidGrey1 : color}>
      {label}
    </StyledPill>
  );
};

export default ItemTypePill;

const StyledPill = styled(Pill)<{ color: string }>`
  background: ${(props) => props.color};
  display: block;
  min-width: 72px;
  margin-right: 0;
`;

const LargeStyledPill = styled(StyledPill)`
  height: 20px;
  font-size: 13px;
  font-weight: 500;
  padding: 2px;
`;

export const ItemIcon = ({
  item,
  isDisabled,
  isUploaded,
  dataSource,
  investmentSource,
  className,
}: {
  item: ItemType;
  isDisabled?: boolean;
  isUploaded?: boolean;
  dataSource?: string;
  investmentSource?: InvestmentSourceTypeEnum;
  className?: string | undefined;
}) => {
  const { Colors } = useContext(ThemeContext);
  const itemColor = isDisabled ? Colors.MidGrey1 : getItemColor(Colors, item);

  if (isUploaded && item !== ItemType.Benchmark) {
    return (
      <StyledIcon className={className} color={isDisabled ? Colors.MidGrey1 : Colors.HighlightDark} type="upload" />
    );
  }

  if (
    ((item === ItemType.Portfolio || item === ItemType.Strategy) && dataSource) ||
    FundUtils.isUserIntegration(investmentSource)
  ) {
    return (
      <Tooltip content={dataSource}>
        <StyledIcon className={className} color={itemColor} type="exchange" />
      </Tooltip>
    );
  }

  if (item === ItemType.Tag) {
    return <StyledIcon className={className} color={itemColor} type="tag" />;
  }

  if (item === ItemType.Portfolio || item === ItemType.Strategy) {
    return <StyledIcon className={className} color={itemColor} type="th" />;
  }

  if (item === ItemType.PrivatePortfolio) {
    return <PrivatePortfolioIcon color={itemColor} />;
  }
  if (item === ItemType.PrivateInvestment) {
    return <PrivateInvestmentIcon color={itemColor} />;
  }

  if (item === ItemType.Benchmark || item === ItemType.CommonBenchmark) {
    return <StyledIcon className={className} color={itemColor} type="arrows-h" />;
  }

  if (item === ItemType.Investment) {
    return <StyledIcon className={className} color={itemColor} type="square" />;
  }

  if (item === ItemType.Analysis) {
    return <StyledIcon className={className} color={itemColor} type="calculator" />;
  }

  if ([ItemType.Studio, ItemType.StudioTearsheet, ItemType.TearsheetTemplate].includes(item)) {
    return <StudioIcon color={itemColor} size={14} top={2} />;
  }

  if ([ItemType.StudioReport, ItemType.ReportTemplate].includes(item)) {
    return <StyledIcon className={className} color={itemColor} type="file-chart-column" />;
  }

  if (item === ItemType.Compare) {
    return <StyledIcon className={className} color={itemColor} type="line-columns" />;
  }

  if (item === ItemType.Article) {
    return <StyledIcon className={className} color={itemColor} type="cloud-question" />;
  }
  if (item === ItemType.PeerGroup) {
    return <StyledIcon className={className} color={itemColor} type="user-group" />;
  }
  return null;
};

const StyledIcon = styled(Icon)<{ color: string }>`
  color: ${(props) => props.color};
`;
