import React, { forwardRef } from 'react';

import { ItemIcon, type ItemType } from 'venn-ui-kit';

import { type HTMLProps } from '../factory';

export type BlockSubjectsItemElement = React.ElementRef<'div'>;
export interface BlockSubjectsItemProps extends HTMLProps<'div'> {
  itemType?: ItemType;
  customIcon?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const BlockSubjectsItem = forwardRef<BlockSubjectsItemElement, BlockSubjectsItemProps>((props, ref) => {
  const { itemType, customIcon, className, children } = props;

  const icon = customIcon || (itemType && <ItemIcon item={itemType} className={className} />);

  return (
    <div ref={ref}>
      {icon}
      {children}
    </div>
  );
});

BlockSubjectsItem.displayName = 'BlockSubjectsItem';
