import React, { useCallback, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Body2,
  DropMenu,
  GetColor,
  Label,
  Link as LinkStyle,
  SENSITIVITY_ANALYSIS_FAQ_HREF,
  ShimmerBlock,
} from 'venn-ui-kit';
import { analyticsService, withSuspense } from 'venn-utils';
import { blockScenarios, blockSettings } from 'venn-state';
import { CustomizableErrorBoundary, ReorderableList, UserContext } from 'venn-components';
import { ScenarioDropdowns } from './scenario-section/ScenarioDropdowns';
import { FlexHeader, Section } from '../../../shared';
import styled from 'styled-components';
import type { Scenario } from 'venn-api';
import { ScenarioInput } from './scenario-section/ScenarioInput';

interface ScenarioSectionProps {
  selected: string;
}

const Content = styled.div`
  padding: 0 10px;
`;

const scenarioErrorComponent = () => (
  <DropMenu selected={-1} items={[{ label: 'Error fetching scenarios', value: -1 }]} disabled />
);

const toReorderableItem = (scenario: Scenario, index: number) => ({
  key: index.toString(),
  scenario,
});

const ScenarioSection = ({ selected }: ScenarioSectionProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_METRICS');
  const blockSetting = useRecoilValue(blockSettings(selected));
  const [selectedScenarios, setSelectedScenarios] = useRecoilState(blockScenarios(selected));

  const scenarioInputItemRenderer = useCallback(
    ({ scenario }, index) => (
      <ScenarioInput
        scenario={scenario}
        isReadOnly={isReadOnly}
        onChange={(updatedScenario) => {
          setSelectedScenarios(
            selectedScenarios.map((selectedScenario, selectedIndex) =>
              selectedIndex === index ? updatedScenario : selectedScenario,
            ),
          );
        }}
        onDelete={() => {
          const newScenarios = [...selectedScenarios];
          newScenarios.splice(index, 1);
          setSelectedScenarios(newScenarios);
        }}
      />
    ),
    [selectedScenarios, setSelectedScenarios, isReadOnly],
  );

  if (blockSetting.customBlockType !== 'SCENARIO') {
    return null;
  }

  const reorderableItems = selectedScenarios.map(toReorderableItem);
  return (
    <Section>
      <FlexHeader>Input</FlexHeader>
      <Content>
        <CustomizableErrorBoundary FallbackComponent={scenarioErrorComponent}>
          <ScenarioDropdowns selected={selected} />
        </CustomizableErrorBoundary>
        <HintSection>
          <Label>Added shocks</Label>
          {selectedScenarios.length ? (
            <>
              <Hint>Shocks applied over next 30 days</Hint>
              <LinkStyle>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={SENSITIVITY_ANALYSIS_FAQ_HREF}
                  onClick={() => {
                    analyticsService.linkClicked({
                      locationOnPage: 'Studio Scenario Configuration',
                      label: 'See how we calculate this',
                      destinationUrl: SENSITIVITY_ANALYSIS_FAQ_HREF,
                    });
                  }}
                >
                  Learn more
                </a>
              </LinkStyle>
            </>
          ) : (
            <Hint>Add input above to begin</Hint>
          )}
        </HintSection>
        <div>
          <ReorderableList
            hideDelete
            counterMargin={20}
            margin={20}
            disabled={isReadOnly}
            reorderableItems={reorderableItems}
            onReorderItems={(items) => setSelectedScenarios(items.map((item) => item.scenario))}
            itemRenderer={scenarioInputItemRenderer}
          />
        </div>
      </Content>
    </Section>
  );
};

export default withSuspense(<ShimmerBlock />, ScenarioSection);

const Hint = styled(Body2)`
  color: ${GetColor.DarkGrey};
`;

const HintSection = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
`;
