import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import type { DateRange } from 'venn-ui-kit';
import { Button, DateRangePicker, GetColor, Label } from 'venn-ui-kit';
import type { CustomNotablePeriod } from 'venn-utils';
import { useModal } from 'venn-utils';
import { FactorLensesContext, Modal, ModalContent, ModalFooter, ModalHeader, ModalSubhead } from 'venn-components';
import { InputField } from '../../../../shared';
import { isNil } from 'lodash';
import { getDefaultRange } from 'venn-state';

const NAME_MAX_LENGTH = 100;

interface AddCustomPeriodProps {
  onAddCustomPeriod: (customPeriod: CustomNotablePeriod) => void;
}

const AddCustomPeriod = ({ onAddCustomPeriod }: AddCustomPeriodProps) => {
  const [isModalOpened, onOpen, onClose] = useModal();

  return (
    <>
      <Button onClick={onOpen} disabled={isModalOpened}>
        Add Custom Period
      </Button>
      {isModalOpened && <AddCustomPeriodModal onAddCustomPeriod={onAddCustomPeriod} onClose={onClose} />}
    </>
  );
};

const AddCustomPeriodModal = ({ onAddCustomPeriod, onClose }: AddCustomPeriodProps & { onClose: () => void }) => {
  const { primaryFactorLens } = useContext(FactorLensesContext);
  const { maxRange } = getDefaultRange();
  const [name, setName] = useState<string>();
  const [dateRange, setDateRange] = useState<DateRange>({
    from: primaryFactorLens?.latestStartDate,
    to: primaryFactorLens?.earliestEndDate,
  });

  const isMissingData = !name?.length || isNil(dateRange.from) || isNil(dateRange.to);
  const onSubmit = () => {
    if (isMissingData) {
      return;
    }

    onAddCustomPeriod({ name, start: dateRange.from!, end: dateRange.to!, id: -Date.now() });
    onClose();
  };

  return (
    <Modal>
      <ModalHeader>Add Custom Notable Period</ModalHeader>
      <ModalSubhead>Set the name, start, and end date to view statistics for a custom period.</ModalSubhead>
      <ModalContent>
        <Label>
          Custom period name: <RequiredSymbol>*</RequiredSymbol>
        </Label>
        <InputField
          placeholder="Enter period name"
          value={name}
          aria-label="Custom period name"
          id="period-name"
          onChange={setName}
          charactersLimit={NAME_MAX_LENGTH}
          disabled={false}
          error={name?.length === 0}
        />
        <RangeContainer>
          <RangeLabelContainer>
            <Label>
              Custom period start and end: <RequiredSymbol>*</RequiredSymbol>
            </Label>
          </RangeLabelContainer>
          <DateRangePicker
            value={dateRange}
            range={maxRange}
            maxFrequency="DAILY"
            onChange={setDateRange}
            factorLens={primaryFactorLens}
          />
        </RangeContainer>
      </ModalContent>
      <ModalFooter
        onCancel={onClose}
        onPrimaryClick={onSubmit}
        primaryDisabled={isMissingData}
        primaryLabel="Add Period"
      />
    </Modal>
  );
};

export default AddCustomPeriod;

const RequiredSymbol = styled.span`
  color: ${GetColor.Error};
`;

const RangeLabelContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const RangeContainer = styled.div`
  margin-top: 20px;

  & > div > div {
    margin-top: 10px;
  }
`;
