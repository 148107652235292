import { isNil } from 'lodash';
import { type RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import type { ScenarioShockRange, ShockUnitsEnum } from 'venn-api';
import type { TooltipPosition, TooltipProps } from 'venn-ui-kit';
import { GetColor, KeyCodes, Tooltip } from 'venn-ui-kit';
import { formatZScore, getInRangeValue } from './logic';

import { convertByUnits, getUnitBase, getUnitPrecision, getUnitSymbol } from '../../utils/scenario';
import ShockInputTooltip from './ShockInputTooltip';
import useInputStateHelper from '../useInputStateHelper';

interface ShockInputProps {
  fundName?: string;
  shock?: number;
  units: ShockUnitsEnum;
  mean: number;
  // New Shock will accept empty shock
  addNewShock?: boolean;
  className?: string;
  setInputRef?: RefObject<HTMLInputElement>;
  onChange: (value: number, rawValue: number) => void;
  shockRange?: ScenarioShockRange;
  disabled?: boolean;
  tooltipPosition: TooltipPosition;
  tooltipOffset?: TooltipProps['portalPosition'];
}

interface ShockInputState {
  focused: boolean;
  currentValue?: string;
}

const ShockInput = ({
  shock,
  units,
  addNewShock,
  setInputRef,
  onChange,
  shockRange,
  tooltipOffset,
  tooltipPosition,
  mean,
  fundName,
  disabled,
  className,
}: ShockInputProps) => {
  const defaultInput = useRef<HTMLInputElement>(null);
  const input = setInputRef || defaultInput;
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(convertByUnits(shock, units, addNewShock));
  useEffect(() => {
    setCurrentValue(convertByUnits(shock, units, addNewShock));
  }, [addNewShock, shock, units]);
  const { onFocus } = useInputStateHelper({
    setFocused,
    input,
    value: currentValue,
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key.match(/^[a-zA-Z\+\*\/\s]$/) && !(event.ctrlKey || event.metaKey || event.altKey)) {
        event.preventDefault();
      }
      if (event.keyCode === KeyCodes.Enter) {
        input.current!.blur();
      }
    },
    [input],
  );
  const onChangeWrapper = useCallback((event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setCurrentValue(event);
    } else {
      const { value } = event.target;
      setCurrentValue(value);
    }
  }, []);

  const onNewShockFlow = useCallback(() => {
    const base = getUnitBase(units);
    const min = shockRange?.minShock;
    const max = shockRange?.maxShock;
    // When there is no value in input but user already select a range
    // We will show '--' for null
    if (isNil(currentValue) || invalidStringInput(currentValue)) {
      setFocused(false);
      setCurrentValue(undefined);
      return;
    }

    const newRawValue = isNil(currentValue) ? NaN : parseFloat(currentValue) / base;
    const newInRangeValue = getInRangeValue(newRawValue, min, max);
    setFocused(false);
    setCurrentValue('');
    onChange(newInRangeValue, newRawValue);
  }, [currentValue, onChange, shockRange?.maxShock, shockRange?.minShock, units]);

  const onBlur = useCallback(() => {
    requestAnimationFrame(() => {
      const min = shockRange?.minShock;
      const max = shockRange?.maxShock;
      const base = getUnitBase(units);

      if (addNewShock) {
        onNewShockFlow();
        return;
      }

      if (currentValue !== convertByUnits(shock, units, addNewShock)) {
        // when user deletes previous value (or enters invalid value) and leave, fallback to previous value
        if (invalidStringInput(currentValue)) {
          setFocused(false);
          setCurrentValue(convertByUnits(shock, units, addNewShock));
          return;
        }

        const newRawValue = parseFloat(currentValue ?? '') / base;
        const inRangeValue = getInRangeValue(newRawValue, min, max);

        const displayString = inRangeValue ? convertByUnits(inRangeValue, units, !!addNewShock) : currentValue;
        setCurrentValue(displayString);
        setFocused(false);

        onChange(inRangeValue, newRawValue);
      } else {
        setFocused(false);
      }
    });
  }, [addNewShock, currentValue, onChange, onNewShockFlow, shock, shockRange?.maxShock, shockRange?.minShock, units]);

  const inputValue = useMemo((): string => {
    // Only in add new value mode
    if (currentValue === '') {
      return '';
    }
    if (isNil(currentValue)) {
      return focused ? '' : '--';
    }

    const value = parseFloat(currentValue);
    const valueString = value.toFixed(getUnitPrecision(units));
    return focused ? currentValue : value <= 0 ? valueString : `+${valueString}`;
  }, [currentValue, focused, units]);

  const base = getUnitBase(units);
  const isDisabled = disabled || (!!addNewShock && !shockRange);
  const minShockZScore = useMemo(
    () => (shockRange?.minShockZScore ? formatZScore(shockRange.minShockZScore) : NaN),
    [shockRange],
  );
  const maxShockZScore = useMemo(
    () => (shockRange?.maxShockZScore ? formatZScore(shockRange.maxShockZScore) : NaN),
    [shockRange],
  );

  return (
    <Tooltip
      usePortal
      portalPosition={tooltipOffset}
      background="transparent"
      position={tooltipPosition}
      isHidden={isDisabled}
      content={
        <ShockInputTooltip
          shock={currentValue ? parseFloat(currentValue) / base : NaN}
          units={units}
          mean={mean}
          fundName={fundName}
          minVal={shockRange?.minShock ?? NaN}
          maxVal={shockRange?.maxShock ?? NaN}
          minShockZScore={minShockZScore}
          maxShockZScore={maxShockZScore}
        />
      }
    >
      <Wrapper className={className} style={{ display: 'inline-block' }}>
        <Input
          ref={input}
          focused={focused}
          currentValue={currentValue}
          value={inputValue}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChangeWrapper}
          disabled={isDisabled}
        />
        <Units disabled={isDisabled}>{getUnitSymbol(units)}</Units>
      </Wrapper>
    </Tooltip>
  );
};

function invalidStringInput(s: string | undefined): boolean {
  return typeof s === 'string' && !s.match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/);
}

export default ShockInput;

const Wrapper = styled.div`
  position: relative;
`;

const Units = styled.span<{ disabled: boolean }>`
  position: absolute;
  left: 55px;
  top: 1px;
  line-height: 30px;
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey2 : GetColor.Black)};
  pointer-events: none;
  font-weight: bold;
`;

const Input = styled.input<Partial<ShockInputState>>`
  text-align: right;
  padding-right: 20px;
  width: 76px;
  ${({ focused, currentValue }) =>
    focused
      ? css`
          border: 1px solid ${GetColor.Primary.Main};
        `
      : isNil(currentValue)
        ? css`
            border: 1px solid ${GetColor.Error};
          `
        : css`
            border: 1px solid ${GetColor.PaleGrey};
          `}
  &:disabled {
    border-color: ${GetColor.Grey};
    color: ${GetColor.MidGrey2};
    background-color: ${GetColor.PaleGrey};
    cursor: not-allowed;
  }
`;
