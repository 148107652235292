import { createGlobalStyle } from 'styled-components';
import { ZIndex } from '../zIndexValues';
import { GetColor } from './color';
import { ColorUtils } from './colorUtils';
import { normalize } from 'styled-normalize';
import './font.css';

const spacingMicro = '5px';
require('../sprites/generated/sprite.css');

export default createGlobalStyle`
  ${normalize}
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html, body {
    padding: 0;
    margin: 0;
    -webkit-print-color-adjust: exact;
  }

  main {
    display: initial;
    flex: 1;
    @media print {
      max-width: 100vw;
      width: 100%;
      display: block;
    }
  }

  table {
    border-collapse: collapse;
  }

  ul {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    margin-left: -6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(118, 131, 143, 0.6);
  }

  ::-webkit-scrollbar-track {
    background-color: white;
  }

  html {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    color: ${GetColor.Black};
    font-size: 12px;
    box-sizing: border-box;
  }

  body {
    font-size: 1.2rem;
    -webkit-font-smoothing: antialiased;
    overflow: ${process.env.STORYBOOK ? 'auto !important' : 'hidden'}; // scrolling should only happen in main; also prevent scrollbar from appearing when portals are out of view
  }

  p {
    line-height: 1.5;
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    -webkit-appearance: none;
  }

  a {
    cursor: pointer;
    color: ${GetColor.Primary.Dark};
    text-decoration: none;
    transition: color .15s linear;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    margin: ${spacingMicro} 0;
    font-weight: normal;
  }

  button,
  textarea,
  input {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 100%;
  }

  textarea,
  input {
    padding: 5px 10px;
    -webkit-transition: border-color 0.15s linear;
    transition: border-color 0.15s linear;
    border: 1px solid ${GetColor.Grey};
    outline: none;
    background-color: ${GetColor.White};
    font-weight: bold;
    line-height: 20px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${GetColor.Grey};
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: ${GetColor.Grey};
    }
  }

  textarea:focus,
  input:focus {
    border-color: ${GetColor.Primary.Main};
  }

  button {
    color: ${GetColor.Primary.Dark};
    cursor: pointer;

    &:hover {
      color: ${GetColor.Primary.Main};
    }

    &:disabled, &:hover:disabled {
      color: ${GetColor.Grey};
      cursor: not-allowed;
    }
  }

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
  }

  /** Keyboard focus Styles */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  :focus {
    outline-color: ${GetColor.Primary.Main};
    outline-offset: -4px;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  .highcharts-root {
    font-family: ${(props) => props.theme.Typography.fontFamily} !important;
  }

  .Select {
    border: 1px solid ${GetColor.Grey};
    font-size: 1.4rem;
    display: inline-block;

    .Select-option {
      line-height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-height: 36px;
    }

    .Select-option.is-focused, .Select-option.is-selected {
      background-color: ${ColorUtils.hex2rgbaFrom(GetColor.LightGrey, 0.1)};
    }

    .Select-option:hover {
      background-color: ${ColorUtils.hex2rgbaFrom(GetColor.LightGrey, 0.1)};
    }

    .Select-menu-outer {
      box-sizing: border-box;
      max-height: 300px;
      margin-top: 0;
      background-color: ${GetColor.White};
      border: 1px solid ${GetColor.Grey};
      border-radius: 0;
      overflow: hidden;
      z-index: ${ZIndex.Front};
      box-shadow: none !important;
    }
  }
  dialog {
    ::backdrop {
      background: rgba(16, 22, 27, 0.75);
    }
    border: 1px solid ${GetColor.LightGrey};
    padding: 0;
  }

  /* Animations */
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
