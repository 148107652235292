import type { IHeaderParams } from 'ag-grid-community';
import React, { useCallback } from 'react';
import { CheckboxWrapper } from 'venn-ui-kit';

export type CheckboxMenuHeaderRendererParams = IHeaderParams & {
  selected: boolean;
  setSelectedRecoilState: (selectedIds: string[]) => void;
};

export const CheckboxMenuHeaderRenderer = ({
  api,
  selected,
  setSelectedRecoilState,
}: CheckboxMenuHeaderRendererParams) => {
  const setAllVisibleRows = useCallback(
    (value: boolean) => {
      const firstDisplayedRow = api.getFirstDisplayedRowIndex();
      const lastDisplayedRow = api.getLastDisplayedRowIndex();
      const rowList: string[] = [];
      for (let currentRow = firstDisplayedRow; currentRow <= lastDisplayedRow; ++currentRow) {
        const row = api.getDisplayedRowAtIndex(currentRow);
        if (value && row) {
          rowList.push(row.id!);
        }
        row?.setSelected(value);
      }
      setSelectedRecoilState(rowList);
    },
    [api, setSelectedRecoilState],
  );

  const onChange = useCallback(() => {
    setAllVisibleRows(!selected);
  }, [setAllVisibleRows, selected]);

  return (
    <CheckboxWrapper
      className="ag-theme-alpine qa-header-checkbox"
      onChange={onChange}
      checked={selected}
      justify="left"
    />
  );
};
