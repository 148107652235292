import React, { forwardRef } from 'react';
import Measure, { type ContentRect } from 'react-measure';
import styled from 'styled-components';

import { ShimmerBlock } from 'venn-ui-kit';

import { BOLD_CLASS, LEFT_ALIGN_CLASS, RIGHT_ALIGN_CLASS, JUSTIFY_HEADER_END_CLASS } from '../../../../studio-blocks';
import { DOWNLOAD_BLOCK_CLASS } from '../../../../content-block/DownloadableContentBlock';
import { blockEmptyStateClass } from '../../../../empty-state/EmptyState';
import { StudioBlockWatermark, vennBlockWatermarkClass } from '../../../../block-watermark/BlockWatermark';

export type BlockContentElement = React.ElementRef<'div'>;
export interface BlockContentProps {
  onResize: (contentRect: ContentRect) => void;
  children: React.ReactNode;
}

export const BlockContent = forwardRef<BlockContentElement, BlockContentProps>((props) => {
  const { onResize, children } = props;

  return (
    <WatermarkContainer>
      <Measure bounds onResize={onResize}>
        {({ measureRef }) => <MeasureContainer ref={measureRef}>{children}</MeasureContainer>}
      </Measure>
      <StudioBlockWatermark />
    </WatermarkContainer>
  );
});

BlockContent.displayName = 'BlockContent';

/** Contains the block content container and the block watermark, enabling the block watermark to overlay the block content container. */
const WatermarkContainer = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  height: 100%;

  :has(.${blockEmptyStateClass}) .${vennBlockWatermarkClass} {
    display: none;
  }
`;

const MeasureContainer = styled.div`
  display: flex;
  /* needed to stop overflow */
  min-height: 0;
  height: 100%;

  /* Warning: Removing this CSS breaks many blocks in unexpected ways, because they stop growing and instead shrink down to their content's minimum size. */
  > div:last-child:not(${ShimmerBlock}) {
    width: 100%;
    height: 100%;
  }

  .${DOWNLOAD_BLOCK_CLASS} {
    width: 100%;
  }

  .${LEFT_ALIGN_CLASS} {
    text-align: left;
  }

  .${JUSTIFY_HEADER_END_CLASS} .ag-header-cell-label {
    justify-content: flex-end;
  }

  .${RIGHT_ALIGN_CLASS} {
    text-align: right;
  }
  .${BOLD_CLASS} {
    font-weight: bold;
  }
`;
