import type { FC } from 'react';
import React from 'react';
import { ButtonIcon, Icon } from 'venn-ui-kit';
import { logMessageToSentry, MANAGE_DATA_SECTION, navigateToManageDataPage } from 'venn-utils';
import { useHistory } from 'react-router-dom';

export interface ManageInvestmentDataTriggerProps {
  fundId?: string;
  disabled?: boolean;
  customRenderer?: (onClick: () => void, disabled?: boolean) => React.ReactNode;
  /** Navigate to Returns/Price Data section when launching investment manage data page */
  defaultEditData?: boolean;
  needsAttention?: boolean;
}

const ManageInvestmentDataTrigger: FC<React.PropsWithChildren<ManageInvestmentDataTriggerProps>> = ({
  defaultEditData,
  customRenderer,
  disabled,
  fundId,
  needsAttention,
}) => {
  const history = useHistory();
  const launchManageData = () => {
    if (!fundId) {
      logMessageToSentry('Unable to navigate to manage data page due to missing fundId');
      return;
    }
    navigateToManageDataPage(
      history,
      { fundId },
      'Analysis',
      false,
      defaultEditData ? MANAGE_DATA_SECTION.DATA : MANAGE_DATA_SECTION.INVESTMENT_INFORMATION,
    );
  };

  return customRenderer ? (
    customRenderer(launchManageData, disabled)
  ) : (
    <ButtonIcon
      className="qa-manage-data-button"
      iconType="cog"
      onClick={launchManageData}
      tooltip={
        needsAttention ? (
          <div>
            <h4>
              <strong>Manage data</strong>
            </h4>
            <div>
              <Icon type="exclamation-circle" />
              &nbsp; This investment is more than 6 months out of date or has an insufficient number of data points.
            </div>
          </div>
        ) : (
          'Manage data'
        )
      }
      tooltipMaxWidth={needsAttention ? 300 : undefined}
      border
      disabled={disabled}
      destructive={needsAttention}
    />
  );
};

export default React.memo(ManageInvestmentDataTrigger);
