import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import type { FactorOverrideRange } from 'venn-api';
import { Tooltip, TooltipPosition, ZIndex } from 'venn-ui-kit';
import FactorForecastOverrideRangeTooltip from './FactorForecastOverrideRangeTooltip';
import { formatZScore } from '../../../input/ShockInput/logic';
import useKeyBlockingNumericInputStateManager from '../../../key-blocking-numeric-input/useKeyBlockingNumericInputStateManager';
import type { ParsedValue } from '../../../key-blocking-numeric-input/useKeyBlockingNumericInputStateManager';

interface FactorForecastOverrideNumericTextInputProps {
  onCommitInput: (parsedValue: ParsedValue) => void;
  onInputChange: (value: string) => void;
  isLocked: boolean;
  value: string;
  shouldHighlight: boolean;
  isCurrentValUserSpecified: boolean;
  factorOverrideRange: FactorOverrideRange;
}

const FactorForecastOverrideNumericTextInput = ({
  onCommitInput,
  onInputChange,
  isLocked,
  shouldHighlight,
  isCurrentValUserSpecified,
  value,
  factorOverrideRange,
}: FactorForecastOverrideNumericTextInputProps) => {
  const { maxOverride, maxOverrideZScore, minOverride, minOverrideZScore, mean } = factorOverrideRange;
  const injectedProps = useKeyBlockingNumericInputStateManager({
    onCommitInput,
    onChange: onInputChange,
    allowNegative: true,
    isLocked,
    isPercentage: false,
    value,
    maxDecimalPlaces: 2,
  });
  return (
    <Tooltip
      usePortal
      background="transparent"
      position={TooltipPosition.Top}
      isHidden={false}
      zIndex={ZIndex.InterComFront2}
      content={
        <FactorForecastOverrideRangeTooltip
          currentVal={parseFloat(value) / 100}
          mean={mean}
          minVal={minOverride}
          maxVal={maxOverride}
          minZScore={formatZScore(minOverrideZScore)}
          maxZScore={formatZScore(maxOverrideZScore)}
          isCurrentValUserSpecified={isCurrentValUserSpecified}
        />
      }
    >
      <TextInput {...injectedProps} isValueEdited={shouldHighlight} />
    </Tooltip>
  );
};

interface TextInputProps {
  isError: boolean;
  isLocked: boolean;
  isValueEdited: boolean;
  theme: Theme;
}

const TextInput = withTheme(styled.input<TextInputProps>`
  width: 60px;
  font-weight: ${({ isValueEdited }) => (isValueEdited ? 'bold' : 'normal')};
  text-align: right;
  padding: 0 5px;
  color: ${({ isValueEdited, theme: { Colors } }) => (isValueEdited ? Colors.HighlightDark : 'inherit')};
  margin-right: 2px;
  ${({ isLocked }) =>
    isLocked &&
    css`
      border-color: transparent;
      background-color: transparent;
    `}
`);

export default FactorForecastOverrideNumericTextInput;
