import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { PerformanceAttribution } from 'venn-api';
import { Headline2, Label, GetColor } from 'venn-ui-kit';
import Bar from '../bar/Bar';
import { min, max, orderBy, isNil } from 'lodash';

import type { PortalPosition } from './Popover';
import Popover from './Popover';

const getMax = (rows: Partial<PerformanceAttribution>[]) => {
  const contributionValues = rows.map((i) => i.contributionValue);
  const minimum = min(contributionValues);
  const maximum = max(contributionValues);
  return max([Math.abs(minimum!), Math.abs(maximum!)]);
};

const split = (rows: Partial<PerformanceAttribution>[]): Partial<PerformanceAttribution>[][] => {
  if (rows.length >= 10) {
    return [rows.slice(0, 5), rows.slice(rows.length - 5, rows.length)];
  }
  return [rows];
};

interface ContributionPopoverProps {
  popoverPosition?: 'left' | 'right';
  visible?: boolean;
  subjectName?: string;
  value?: number;
  timeframe?: 'Historical' | 'Forecast';
  data: Partial<PerformanceAttribution>[];
  keepInitialDataOrder?: boolean;
  metricName: string;
  abbreviatedMetricName?: string;
  formatter: (value: number | undefined) => string;
  dataItemLabel: 'Investment' | 'Factor';
  italicRows?: number[];
  shouldIgnoreMouseEvents: boolean;
  portalPosition?: PortalPosition;
}

const ContributionPopover = ({
  popoverPosition = 'left',
  visible,
  subjectName,
  value,
  timeframe,
  data,
  keepInitialDataOrder,
  metricName,
  abbreviatedMetricName,
  formatter,
  dataItemLabel,
  italicRows,
  shouldIgnoreMouseEvents,
  portalPosition,
}: ContributionPopoverProps) => {
  const maximum = useMemo(() => getMax(data), [data]);

  const defaultOrdering = keepInitialDataOrder ? undefined : (value ?? 0) < 0 ? 'asc' : 'desc';
  const sortedData = defaultOrdering
    ? orderBy(data, ['contributionValue', 'weight'], [defaultOrdering, 'desc'])
    : undefined;
  const tableData: Partial<PerformanceAttribution>[][] = useMemo(
    () => (sortedData ? split(sortedData) : [data]),
    [sortedData, data],
  );
  const isSplit = tableData.length === 2;
  const firstColumnLabel = isSplit ? `Top 5 / Bottom 5 ${dataItemLabel}s` : `${dataItemLabel}s`;

  return (
    <Popover
      position={popoverPosition}
      visible={!!visible}
      portalPosition={portalPosition}
      shouldIgnoreMouseEvents={shouldIgnoreMouseEvents}
    >
      <Container>
        <Label>
          Contribution to {metricName} {timeframe && `(${timeframe})`}
        </Label>
        <Headline2>
          <Subject>{subjectName}</Subject> (<Highlight>{formatter(value)}</Highlight>)
        </Headline2>
        <Table>
          <thead>
            <tr>
              <HeaderCell align="left">{firstColumnLabel} </HeaderCell>
              <HeaderCell align="right">{abbreviatedMetricName || metricName}</HeaderCell>
              <HeaderCell align="right">Contribution</HeaderCell>
            </tr>
          </thead>
          <tbody>
            {tableData[0].map((row, index) => (
              <TableSection
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                row={row}
                maximum={maximum}
                formatter={formatter}
                italic={(italicRows || []).includes(index)}
              />
            ))}
            {isSplit && (
              <>
                <Separator />
                {tableData[1].map((row, index) => (
                  <TableSection
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    row={row}
                    maximum={maximum}
                    formatter={formatter}
                    italic={(italicRows || []).includes(tableData[0].length + index)}
                  />
                ))}
              </>
            )}
          </tbody>
        </Table>
      </Container>
    </Popover>
  );
};

export default ContributionPopover;

interface TableSectionProps {
  row: Partial<PerformanceAttribution>;
  maximum?: number;
  formatter: (value: number | undefined) => string;
  italic?: boolean;
}

const TableSection = ({ row, maximum, formatter, italic }: TableSectionProps) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <Row italic={italic}>
      <Cell>
        <ItemName>{row.name}</ItemName>
      </Cell>
      <Cell align="right">{formatter(row.value)}</Cell>
      <Cell align="right">
        <DataCellInner>
          {!isNil(row.value) ? (
            <DataBar
              barColor={Colors.DataBarColor.LightGold}
              min={isNil(maximum) ? undefined : -maximum}
              max={maximum}
              value={row.contributionValue}
              barWidth={85}
            />
          ) : (
            <div />
          )}
          <Contribution>{formatter(row.contributionValue)}</Contribution>
        </DataCellInner>
      </Cell>
    </Row>
  );
};

const Subject = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
`;

const HeaderCell = styled.th<{ align?: 'center' | 'left' | 'right' }>`
  text-align: ${(props) => props.align};
`;

const Cell = styled.td<{ align?: 'center' | 'left' | 'right' }>`
  text-align: ${(props) => props.align};
`;

const Table = styled.table`
  margin-top: 17px;
  width: 100%;
  thead > tr,
  tbody > tr {
    height: 17px;

    > th,
    > td {
      padding: 0 10px;
      :first-child {
        padding-left: 0px;
      }
      :last-child {
        padding-right: 0;
      }
    }
  }
  thead {
    > tr {
      font-size: 12px;
      font-weight: bold;
      border-bottom: 1px solid ${GetColor.Black};
    }
  }
  tbody {
    font-size: 10px;
    > tr {
      &:first-of-type td {
        padding-top: 5px;
      }
    }
  }
`;

const Row = styled.tr<{ italic?: boolean }>`
  ${({ italic }) => italic && 'font-style: italic;'}
`;

const Container = styled.div`
  width: 372px;
  min-height: 100px;
`;

const Contribution = styled.div`
  padding-left: 10px;
  color: ${GetColor.DataLineColor.Gold};
`;

const DataCellInner = styled.div`
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataBar = styled(Bar)`
  border: 1px solid ${GetColor.Grey};
  height: 8px;
`;

const ItemName = styled.div`
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SeparationRow = styled.tr`
  height: 2px;
`;

const SeparatorBar = styled.div`
  border: 1px solid ${GetColor.MidGrey1};
  margin: 0;
`;

const Highlight = styled.span`
  color: ${GetColor.HighlightDark};
`;

const Separator = () => (
  <SeparationRow>
    <td colSpan={3}>
      <SeparatorBar />
    </td>
  </SeparationRow>
);
