import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PortfolioLabContext } from 'venn-components';
import { compact, isNil } from 'lodash';
import type {
  Analysis,
  AnalysisRequest,
  AnalysisTypeEnum,
  Portfolio,
  PortfolioPerformanceAttributions,
  Message,
} from 'venn-api';
import { analysis } from 'venn-api';
import { type AnalysisSubject, type ExcelCell, type AnalysisGroup, logExceptionIntoSentry } from 'venn-utils';
import { analyticsService, generateAnalysisParams, useApi } from 'venn-utils';
import type { PerformanceMetricRow, PerformanceRiskMetricRow } from './PortfolioPerformanceCardView';
import PortfolioPerformanceCardView from './PortfolioPerformanceCardView';
import type { PortfolioLabStoredSettings } from '../../logic/useValuesFromHistoryState';
import { getPerformanceData } from '../../logic/useCombinedExcelExportData';
import type { AnalysisPeriodParams } from '../../logic/useAvailablePeriodForForecast';
import { formatRequestPeriod } from '../../logic/useAvailablePeriodForForecast';

import { convertContributionResponse } from '../../../../analysis-page/src/components/performance-summary/logic';

const performanceContributions: AnalysisTypeEnum[] = [
  'PERFORMANCE_SUMMARY_FORECAST_CONTRIBUTION',
  'PERFORMANCE_SUMMARY_HISTORICAL_CONTRIBUTION',
];

const analysesTypes: AnalysisTypeEnum[] = [
  'PERFORMANCE_SUMMARY_HISTORICAL',
  'PERFORMANCE_SUMMARY_FORECAST',
  ...performanceContributions,
];

interface PerformanceAnalysisContribution {
  historical?: AnalysisGroup<PortfolioPerformanceAttributions>;
  forecast?: AnalysisGroup<PortfolioPerformanceAttributions>;
}

interface PortfolioPerformanceCardContainerProps {
  requestPeriodParams: Partial<AnalysisPeriodParams>;
  loadingAnalysisPeriod: boolean;
  settings: PortfolioLabStoredSettings;
  storeSettings: (values: Partial<PortfolioLabStoredSettings>) => void;
  benchmarkSummaryError: boolean;
  onUpdateExportData: (data: ExcelCell[][] | undefined) => void;
}

const PortfolioPerformanceCardContainer = ({
  requestPeriodParams,
  loadingAnalysisPeriod,
  settings,
  storeSettings,
  benchmarkSummaryError,
  onUpdateExportData,
}: PortfolioPerformanceCardContainerProps) => {
  const { selectedSolution, solutionPortfolio, portfolio, onEditObjectiveConstraintValue, benchmarkSubject } =
    useContext(PortfolioLabContext);
  const solutionTitle =
    selectedSolution?.category !== 'Benchmark'
      ? `${selectedSolution?.category} Portfolio${
          !isNil(selectedSolution?.alternateSolutionIdx) ? ` [#${selectedSolution.alternateSolutionIdx + 1}]` : ''
        }`
      : 'Benchmark';
  const analysisApiRef = useRef(useApi(analysis));

  const [relative, setRelativeInState] = useState(settings.relative ?? false);
  const [errors, setErrors] = useState<(Message | undefined)[]>();
  const [data, setData] = useState<[PerformanceMetricRow[], PerformanceRiskMetricRow[]]>([[], []]);
  const [loading, setLoading] = useState(false);

  const actualRelative = useMemo(
    () => (isNil(benchmarkSubject) || benchmarkSummaryError ? false : relative),
    [benchmarkSubject, benchmarkSummaryError, relative],
  );

  const setRelative = useCallback(
    (updatedRelative: boolean) => {
      setRelativeInState(updatedRelative);
      storeSettings({ relative: updatedRelative });
    },
    [storeSettings],
  );

  // Only make it not undefined if it's relevant - to prevent the below useEffect from firing unnecessarily
  const relevantSubjectFundId = useMemo(
    () => (selectedSolution?.category === 'Benchmark' ? benchmarkSubject?.fund?.id : undefined),
    [selectedSolution, benchmarkSubject],
  );

  useEffect(() => {
    const fetchPerformanceAnalysis = async () => {
      if (isNil(solutionPortfolio) || isNil(solutionPortfolio.portfolio) || isNil(portfolio)) {
        return;
      }
      setErrors(undefined);
      setLoading(true);
      try {
        const { content } = await analysisApiRef.current(
          getRequest(
            solutionPortfolio.portfolio,
            portfolio,
            relevantSubjectFundId,
            benchmarkSubject,
            requestPeriodParams,
            actualRelative,
          ),
        );

        if (content.subjectErrors) {
          setErrors(content.subjectErrors);
          setLoading(false);

          return;
        }

        const contributions = convertContributionResponse(
          getRequest(
            solutionPortfolio.portfolio,
            portfolio,
            relevantSubjectFundId,
            benchmarkSubject,
            requestPeriodParams,
            actualRelative,
          ),
          content.analyses,
        );

        setData(convertData(content.analyses, contributions, actualRelative));

        setLoading(false);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          setData([[], []]);
          setLoading(false);
          logExceptionIntoSentry(e);
        }
      }
    };

    if (!loadingAnalysisPeriod) {
      fetchPerformanceAnalysis();
    }
  }, [
    solutionPortfolio,
    portfolio,
    relevantSubjectFundId,
    benchmarkSubject,
    actualRelative,
    requestPeriodParams,
    loadingAnalysisPeriod,
  ]);

  useEffect(() => {
    onUpdateExportData(
      getPerformanceData(
        actualRelative,
        data[0],
        data[1],
        benchmarkSubject?.name,
        formatRequestPeriod(requestPeriodParams),
      ),
    );
  }, [actualRelative, data, onUpdateExportData, benchmarkSubject, requestPeriodParams]);

  return (
    <PortfolioPerformanceCardView
      portfolioName={portfolio?.name}
      portfolioId={portfolio?.id}
      solutionName={solutionTitle}
      loading={loading || loadingAnalysisPeriod}
      performanceData={data[0]}
      riskData={data[1]}
      onChangeObjective={onEditObjectiveConstraintValue}
      relative={actualRelative}
      onToggleRelative={() => {
        const updatedRelative = !relative;
        setRelative(updatedRelative);
        analyticsService.ctaClicked({
          locationOnPage: 'Portfolio Lab - relative to benchmark toggle',
          purpose: `toggle relative ${updatedRelative ? 'ON' : 'OFF'}`,
          subjectId: isNil(portfolio?.id) ? undefined : portfolio.id.toString(),
          type: 'toggle',
        });
      }}
      canToggleRelative={!isNil(benchmarkSubject) && !benchmarkSummaryError}
      relativeToggleTooltip={
        isNil(benchmarkSubject)
          ? 'Add a benchmark to view relative metrics'
          : benchmarkSummaryError
            ? 'Benchmark has insufficient returns to calculate forecasts'
            : undefined
      }
      errors={errors}
    />
  );
};

export default PortfolioPerformanceCardContainer;

const getRequest = (
  selectedPortfolio: Portfolio,
  currentPortfolio: Portfolio,
  fundId: string | undefined,
  benchmark: AnalysisSubject | undefined,
  periodParams: Partial<AnalysisPeriodParams>,
  relative: boolean,
): Partial<AnalysisRequest> => ({
  analyses: analysesTypes.map((type) => generateAnalysisParams(type, relative)),
  subjects: compact([
    {
      comparisonType: 'PRIMARY' as const,
      id: !isNil(fundId) ? fundId : undefined!,
      portfolio: !isNil(fundId) ? undefined : selectedPortfolio,
      subjectType: !isNil(fundId) ? 'INVESTMENT' : 'PORTFOLIO',
      primary: true,
      isPrivate: false,
    },
    {
      comparisonType: 'COMPARISON' as const,
      portfolio: currentPortfolio,
      id: currentPortfolio.id.toString(),
      subjectType: 'PORTFOLIO' as const,
      primary: false,
      isPrivate: false,
    },
    isNil(benchmark)
      ? null
      : {
          comparisonType: 'BENCHMARK' as const,
          id: benchmark.id?.toString(),
          subjectType: benchmark.type === 'portfolio' ? 'PORTFOLIO' : 'INVESTMENT',
          primary: false,
          isPrivate: false,
        },
  ]),
  ...(!isNil(periodParams.start) && !isNil(periodParams.end)
    ? { start: periodParams.start, end: periodParams.end }
    : periodParams),
});

const convertData = (
  analyses: Analysis[],
  contributions: PerformanceAnalysisContribution,
  relative: boolean,
): [PerformanceMetricRow[], PerformanceRiskMetricRow[]] => {
  const historical = analyses[0].historicalPerformanceSummary[0];
  const historicalBase = analyses[0].historicalPerformanceSummary[1];

  const forecasted = analyses[1].forecastedPerformanceSummary[0];
  const forecastedBase = analyses[1].forecastedPerformanceSummary[1];

  const cumulativeReturn = !historical.periodAnnualized;

  const performanceMetrics: PerformanceMetricRow[] = [
    {
      metricName: relative ? 'Excess Return' : 'Return',
      cumulative: cumulativeReturn,
      isPercentage: true,
      historical: !historical.periodAnnualized
        ? relative
          ? historical.excessTotalReturn
          : historical.totalReturn
        : relative
          ? historical.periodExcessReturn
          : historical.periodReturn,
      historicalBase: !historicalBase.periodAnnualized
        ? relative
          ? historicalBase.excessTotalReturn
          : historicalBase.totalReturn
        : relative
          ? historicalBase.periodExcessReturn
          : historicalBase.periodReturn,
      forecast: relative ? forecasted.annualizedExcessReturn : forecasted.annualizedReturn,
      forecastBase: relative ? forecastedBase.annualizedExcessReturn : forecastedBase.annualizedReturn,
      contributions: {
        historical: {
          value: relative
            ? cumulativeReturn
              ? historical.excessTotalReturn
              : historical.periodExcessReturn
            : cumulativeReturn
              ? historical.totalReturn
              : historical.periodReturn,
          contributions: relative
            ? contributions.historical?.subject?.periodExcessReturn
            : contributions.historical?.subject?.periodReturn,
        },
        forecast: {
          value: relative ? forecasted.annualizedExcessReturn : forecasted.annualizedReturn,
          contributions: relative
            ? contributions.forecast?.subject?.periodExcessReturn
            : contributions.forecast?.subject?.periodReturn,
        },
      },
    },
    {
      metricName: relative ? 'Tracking Error' : 'Volatility',
      isPercentage: true,
      historical: relative ? historical.trackingError : historical.volatility,
      historicalBase: relative ? historicalBase.trackingError : historicalBase.volatility,
      forecast: relative ? forecasted.trackingError : forecasted.volatility,
      forecastBase: relative ? forecastedBase.trackingError : forecastedBase.volatility,
      contributions: {
        historical: {
          value: relative ? historical.trackingError : historical.volatility,
          contributions: relative
            ? contributions.historical?.subject?.trackingError
            : contributions.historical?.subject?.volatility,
        },
        forecast: {
          value: relative ? forecasted.trackingError : forecasted.volatility,
          contributions: relative
            ? contributions.forecast?.subject?.trackingError
            : contributions.forecast?.subject?.volatility,
        },
      },
    },
    {
      metricName: relative ? 'Information Ratio' : 'Sharpe',
      isPercentage: false,
      historical: relative ? historical.informationRatio : historical.sharpe,
      historicalBase: relative ? historicalBase.informationRatio : historicalBase.sharpe,
      forecast: relative ? forecasted.informationRatio : forecasted.sharpe,
      forecastBase: relative ? forecastedBase.informationRatio : forecastedBase.sharpe,
      contributions: {
        historical: {
          value: relative ? historical.informationRatio : historical.sharpe,
          contributions: relative
            ? contributions.historical?.subject?.informationRatio
            : contributions.historical?.subject?.sharpe,
        },
        forecast: {
          value: relative ? forecasted.informationRatio : forecasted.sharpe,
          contributions: relative
            ? contributions.forecast?.subject?.informationRatio
            : contributions.forecast?.subject?.sharpe,
        },
      },
    },
    {
      metricName: relative ? 'Max Underperformance' : 'Max Drawdown',
      isPercentage: true,
      historical: relative ? historical.maxUnderperformance : historical.maxDrawdown,
      historicalBase: relative ? historicalBase.maxUnderperformance : historicalBase.maxDrawdown,
      forecast: undefined,
      forecastBase: undefined,
      contributions: {
        historical: {
          value: relative ? historical.maxUnderperformance : historical.maxDrawdown,
          contributions: relative
            ? contributions.historical?.subject?.maxUnderperformance
            : contributions.historical?.subject?.maxDrawdown,
        },
        forecast: undefined,
      },
    },
  ];

  const riskMetrics: PerformanceRiskMetricRow[] = compact([
    {
      metricName: 'Beta To Benchmark',
      isPercentage: false,
      historical: historical.betaToBenchmark,
      historicalBase: historicalBase.betaToBenchmark,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'Batting Average',
      isPercentage: true,
      historical: historical.battingAverage,
      historicalBase: historicalBase.battingAverage,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'CVaR (5%)',
      isPercentage: true,
      historical: historical.conditionalValueAtRisk95,
      historicalBase: historicalBase.conditionalValueAtRisk95,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'VaR (95%)',
      isPercentage: true,
      historical: historical.valueAtRisk95,
      historicalBase: historicalBase.valueAtRisk95,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'VaR (97.5%)',
      isPercentage: true,
      historical: historical.valueAtRisk975,
      historicalBase: historicalBase.valueAtRisk975,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'VaR (99%)',
      isPercentage: true,
      historical: historical.valueAtRisk99,
      historicalBase: historicalBase.valueAtRisk99,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'Skewness',
      isPercentage: false,
      historical: historical.skewness,
      historicalBase: historicalBase.skewness,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'Kurtosis',
      isPercentage: false,
      historical: historical.kurtosis,
      historicalBase: historicalBase.kurtosis,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'Sortino Ratio',
      isPercentage: false,
      historical: historical.sortino,
      historicalBase: historicalBase.sortino,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'Calmar Ratio',
      isPercentage: false,
      historical: historical.calmar,
      historicalBase: historicalBase.calmar,
      forecast: undefined,
      forecastBase: undefined,
    },
    {
      metricName: 'Autocorrelation',
      isPercentage: false,
      historical: historical.autocorrelation,
      historicalBase: historicalBase.autocorrelation,
      forecast: undefined,
      forecastBase: undefined,
    },
  ]);

  return [performanceMetrics, riskMetrics];
};
