import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment/moment';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';

import type { PrivateAssetSummaryResponse, PrivateFund } from 'venn-api';
import { blockLimitedRequestSubjects, type StudioRequestSubject } from 'venn-state';
import { getItemColor, type Theme } from 'venn-ui-kit';
import type { TabularDataTypeEnum } from 'venn-utils';
import { assertNotNil, Dates } from 'venn-utils';
import { convertRequestSubjectToItemType } from '../../analysis';
import { formatData } from '../../data-grid';
import { useMeasureGridText } from '../../utils/grids';
import { BasicHeaderRenderer } from '../components/grid/renderers/BasicHeaderRenderer';
import { useBlockId } from '../contexts/BlockIdContext';
import { LEFT_ALIGN_CLASS, RIGHT_ALIGN_CLASS, SUBJECT_HEADER_RENDERER } from '../customAnalysisContants';
import type { HeaderComponentParamsType } from '../types';
import { usePrivatesExportInfo } from './usePrivatesExportInfo';

type RowData = Readonly<{
  fund: PrivateFund;
  capitalCommitment: number;
}>;

const getCommonColumnProps = ({
  theme,
  align,
  dataType,
}: {
  theme: Theme;
  align: 'left' | 'right';
  dataType: TabularDataTypeEnum;
}) => {
  return {
    headerComponent: BasicHeaderRenderer,
    headerComponentParams: {
      style: {
        width: '100%',
        color: theme.Colors.Black,
        textAlign: align,
      },
    },
    cellClass: () => [dataType, align === 'left' ? LEFT_ALIGN_CLASS : RIGHT_ALIGN_CLASS],
    cellStyle: { wordBreak: 'normal' },
    wrapText: true,
    autoHeight: true,
  };
};

const getColumnDefs = (
  theme: Theme,
  measureWidths: (text: string, fontWeight: 'bold' | 'normal') => number,
  subject: StudioRequestSubject | undefined,
): ColGroupDef<RowData>[] => {
  const childColumns: ColDef<RowData>[] = [
    {
      headerName: 'Fund Name',
      ...getCommonColumnProps({
        theme,
        align: 'left',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => data?.fund.name ?? '--',
      minWidth: measureWidths('Apollo Natural Resources', 'normal'),
      maxWidth: measureWidths('Macquarie Asia Inf', 'normal'),
    },
    {
      headerName: 'Capital Commitment',
      ...getCommonColumnProps({
        theme,
        align: 'right',
        dataType: 'NUMERIC',
      }),
      valueGetter: ({ data }) => data?.capitalCommitment,
      valueFormatter: ({ value }) => (isNil(value) ? '--' : `$${formatData(value, 'NUMERIC', 2)}`),
      cellClass: () => ['NUMERIC', RIGHT_ALIGN_CLASS],
      minWidth: measureWidths('Commitment', 'bold'),
      maxWidth: measureWidths('Capital Commitment', 'bold'),
    },
    {
      headerName: 'Fund Manager',
      ...getCommonColumnProps({
        theme,
        align: 'left',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => data?.fund.manager ?? '--',
      minWidth: measureWidths('Manager', 'bold'),
    },
    {
      headerName: 'Vintage',
      ...getCommonColumnProps({
        theme,
        align: 'right',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => (isNil(data?.fund.vintage) ? '--' : Dates.toYear(assertNotNil(data?.fund.vintage))),
      comparator: (valueA: string, valueB: string) => {
        const a = moment(valueA, 'Q YYYY').valueOf();
        const b = moment(valueB, 'Q YYYY').valueOf();
        if (a === b) return 0;
        return a - b;
      },
      minWidth: measureWidths('Q1 2020', 'normal'),
      maxWidth: measureWidths('Q1 2020', 'normal'),
    },
    {
      headerName: 'Asset Class',
      ...getCommonColumnProps({
        theme,
        align: 'left',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => data?.fund.assetClass ?? '--',
      minWidth: measureWidths('Private Equity', 'normal'),
      maxWidth: measureWidths('Very long asset class', 'normal'),
    },
    {
      headerName: 'Strategy',
      ...getCommonColumnProps({
        theme,
        align: 'left',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => data?.fund.strategy ?? '--',
      minWidth: measureWidths('Venture Capital', 'normal'),
    },
    {
      headerName: 'Geo Focus',
      ...getCommonColumnProps({
        theme,
        align: 'left',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => data?.fund.geographicFocusName ?? '--',
      minWidth: measureWidths('Geo Focus', 'bold'),
      maxWidth: measureWidths('Diversified Multi-Regional', 'normal'),
    },
    {
      headerName: 'Fund Size',
      ...getCommonColumnProps({
        theme,
        align: 'right',
        dataType: 'NUMERIC',
      }),
      valueGetter: ({ data }) => data?.fund.size,
      valueFormatter: ({ value }) => (isNil(value) ? '--' : `$${formatData(value, 'NUMERIC', 2)}`),
      cellClass: () => ['NUMERIC', RIGHT_ALIGN_CLASS],
      minWidth: measureWidths('$2137.15B', 'normal'),
      maxWidth: measureWidths('$2137.15B', 'normal'),
    },
    {
      headerName: 'Industries',
      ...getCommonColumnProps({
        theme,
        align: 'left',
        dataType: 'TEXT',
      }),
      valueGetter: ({ data }) => {
        const industries = data?.fund.coreIndustries;
        if (!isNil(industries) && !isEmpty(industries)) {
          return industries.join(', ');
        }
        return '--';
      },
      minWidth: measureWidths('Industries', 'bold'),
    },
  ];

  const headerGroupComponentParams: HeaderComponentParamsType = {
    color: getItemColor(theme.Colors, convertRequestSubjectToItemType(subject)),
    subject,
    isCommonBenchmark: false,
    noLink: true,
  };
  return [
    {
      headerName: subject?.privatePortfolio?.name ?? '--',
      headerGroupComponent: SUBJECT_HEADER_RENDERER,
      headerGroupComponentParams,
      children: childColumns,
      marryChildren: true,
    },
  ];
};

const usePrivateAssetsSummaryGrid = (response: PrivateAssetSummaryResponse | undefined | null) => {
  const blockId = useBlockId();
  const subject = useRecoilValue(blockLimitedRequestSubjects(blockId))[0];
  const theme = useTheme();
  const measureWidths = useMeasureGridText();

  const fundsWithCapitalCommitments = response?.fundsWithCapitalCommitments;
  const fundIds = fundsWithCapitalCommitments?.map(({ fund }) => fund.id);

  const columnDefs = useMemo(() => getColumnDefs(theme, measureWidths, subject), [theme, measureWidths, subject]);
  const isExportable = usePrivatesExportInfo(
    (fundIds ?? []).map((id) => {
      return { privateFundId: id };
    }),
  );

  return {
    columnDefs,
    rowData: fundsWithCapitalCommitments,
    isExportable,
  };
};

export default usePrivateAssetsSummaryGrid;
