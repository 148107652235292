import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import type { Fund } from 'venn-api';
import { FundUtils, shouldUserUpdateDataForFund, shouldUserUpdateProxyForFund, useIsMounted } from 'venn-utils';
import { GetColor, Icon } from 'venn-ui-kit';
import ProxyCell from '../modals/proxy-cell/ProxyCell';
import NeedsAttentionButton from '../needs-attention/NeedsAttentionButton';
import InlineActionPillButton from '../charts/chart-errors/components/InlineActionPillButton';
import ManageDataLink from '../manage-data/investment/ManageDataLink';

type DropdownVerticalDirection = 'down' | 'up';

interface InvestmentActionsProps {
  fund: Fund;
  /**
   * Callback to be executed after a fund gets updated (proxy/interpolation). Must be specified to use proxy and/or interpolation actions.
   * */
  onFundDataUpdated?: (fundId: string, throwOnFailure?: boolean) => Promise<void>;
  context: 'error' | 'bulk-management';
  availableStart?: number;
  availableEnd?: number;
  dropdownVerticalDirection?: DropdownVerticalDirection;
  usePortal?: boolean;
  isReadOnly?: boolean;
}

const InvestmentActions = ({
  fund,
  onFundDataUpdated,
  context,
  availableStart,
  availableEnd,
  dropdownVerticalDirection = 'down',
  usePortal,
  isReadOnly,
}: InvestmentActionsProps) => {
  const needsAttention = shouldUserUpdateDataForFund(fund);
  const fundId = fund.id;
  const proxy = fund.proxyType
    ? {
        name: fund.proxyName,
        // Confusing...cleanup later
        id: fund.proxyId,
        proxyId: fund.proxyId,
        proxyType: fund.proxyType,
        proxyCategory: fund.proxyCategory,
        numLags: fund.proxyNumLags,
        extrapolate: fund.extrapolate,
      }
    : null;

  const isMountedRef = useIsMounted();
  const [updatingProxy, setUpdatingProxy] = useState(false);

  const manageDataLinkRenderer = useCallback(
    (onClick) => (
      <InlineActionPillButton onClick={onClick}>
        <Icon type="cog" /> Manage data
      </InlineActionPillButton>
    ),
    [],
  );

  const onProxyChange = async () => {
    setUpdatingProxy(true);
    await onFundDataUpdated?.(fundId, true);
    if (isMountedRef.current) {
      setUpdatingProxy(false);
    }
  };

  if (context === 'bulk-management') {
    return (
      fund.proxyable &&
      fund && (
        <ProxyWrapper compact>
          <LabelText compact>Proxy:</LabelText>
          <ProxyCellWrapper compact>
            <ProxyCell
              proxy={proxy}
              investment={fund}
              hoverAddProxy={false}
              onProxyChange={onProxyChange}
              dropdownVerticalDirection={dropdownVerticalDirection}
              usePortal={usePortal}
              loading={updatingProxy}
              readOnly={isReadOnly}
            />
          </ProxyCellWrapper>
        </ProxyWrapper>
      )
    );
  }

  /* If the availability ranges are not set, constraning range logic will be ignored */
  let constrainingStart;
  let constrainingEnd;
  if (availableStart && availableEnd) {
    constrainingStart = fund?.startRange === availableStart || fund?.startRange > availableEnd;
    constrainingEnd = fund?.endRange === availableEnd || fund?.endRange < availableStart;
  }

  const showNeedsAttentionButton = needsAttention && fund && fund.returnFrequency !== 'YEARLY';
  const showProxyButton =
    (constrainingStart || shouldUserUpdateProxyForFund(fund)) &&
    fund?.returnFrequency !== 'YEARLY' &&
    fund.proxyable &&
    fund &&
    onFundDataUpdated &&
    !isReadOnly;
  const showManageDataButton =
    !showNeedsAttentionButton && fund && constrainingEnd && fund.returnFrequency !== 'YEARLY' && !isReadOnly;

  return (
    <>
      {showNeedsAttentionButton && (
        <ProxyWrapper>
          <ProxyCellWrapper>
            <NeedsAttentionButton
              context="Analysis"
              item={{
                name: fund.name,
                id: fund.id,
              }}
            />
          </ProxyCellWrapper>
        </ProxyWrapper>
      )}
      {showProxyButton && (
        <ProxyWrapper>
          {proxy && <ErrorLabelText>Edit proxy:&nbsp;</ErrorLabelText>}
          <ProxyCellWrapper>
            <ProxyCell
              proxy={proxy}
              investment={fund}
              hoverAddProxy={false}
              onProxyChange={onProxyChange}
              dropdownDirection="right"
              dropdownVerticalDirection={dropdownVerticalDirection}
              usePortal={usePortal}
              loading={updatingProxy}
              readOnly={isReadOnly}
            />
          </ProxyCellWrapper>
        </ProxyWrapper>
      )}
      {showManageDataButton && (
        <ManageDataLink
          canEditReturn={FundUtils.isPerformanceEditable(fund) && !isReadOnly}
          fundId={fund.id}
          customRenderer={manageDataLinkRenderer}
        />
      )}
    </>
  );
};

const ProxyWrapper = styled.div<{ compact?: boolean }>`
  ${(props) => !props.compact && 'margin-left: 20px;'}
`;

const ProxyCellWrapper = styled.div<{ compact?: boolean }>`
  height: ${(props) => (props.compact ? '20px' : '35px')};
  display: flex;
  align-items: center;
`;

const LabelText = styled.label<{ compact?: boolean }>`
  display: block;
  padding: 4px 0;
  font-size: 12px;
  font-weight: bold;
  ${(props) => props.compact && 'display: none;'}
`;

const ErrorLabelText = styled.label`
  padding: 0;
  font-size: 12px;
  color: ${GetColor.DarkGrey};
  font-weight: bold;
`;

export default InvestmentActions;
