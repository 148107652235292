import React, { useMemo } from 'react';
import type { CustomizableBlockSetting } from 'venn-utils';
import { getAnalyzedSubjectFromRequestSubject, isFactorTrendMetric, requiresAnalysisRequest } from 'venn-utils';
import { FactorBar } from './charts/factor-bar';
import TimeseriesChart from './charts/TimeseriesChart';
import type { GeneralChartProps } from './charts/types';
import ScatterChart from './charts/ScatterChart';
import { FactorTrendLineChartParent } from './charts/FactorTrendLineChartParent';
import { FactorTrendHeatMapParent } from './charts/FactorTrendHeatMapParent';
import { UnsupportedChartFormat } from './UnsupportedChartFormat';
import { ColumnChart } from './charts/returns-distribution';
import RollingCorrelationBlock from './charts/RollingCorrelatonBlock';
import GrowthSimulationChart from './charts/GrowthSimulationChart';
import PieChart from './charts/PieChart';
import BoxChartParent from './charts/BoxChartParent';
import StyledEmptyState from './StyledEmptyState';
import { useSubjectColors } from '../logic/useSubjectColors';
import { blockFactors, blockSelectedPeerGroupId, type StudioAnalysis } from 'venn-state';
import { useRecoilValue } from 'recoil';
import { PeerGroupChartWrapper } from './charts/peer-groups/PeerGroupChart';
import { compact } from 'lodash';

interface CustomAnalysisChartProps extends Omit<GeneralChartProps, 'metric' | 'factorMetrics' | 'subjectColors'> {
  blockSetting: CustomizableBlockSetting;
}

const CustomAnalysisChart = ({
  requests,
  analyses,
  selectedBlock,
  responseParser,
  blockSetting,
  ...restProps
}: CustomAnalysisChartProps) => {
  const { selectedRefId } = restProps;

  const factorMetrics = useRecoilValue(blockFactors(selectedRefId));
  const selectedPeerGroup = useRecoilValue(blockSelectedPeerGroupId(selectedRefId));

  const analyzedSubjects = useMemo(
    () => requests.map(({ subject }) => subject).map(getAnalyzedSubjectFromRequestSubject),
    [requests],
  );
  const subjectColors = useSubjectColors(analyzedSubjects);

  // We filter selectedMetrics on blockSetting.metrics because metric order should be preserved,
  // even though reversing this logic would have simpler code.
  // We do this filter to remove any unsupported selectedMetrics.
  const metrics = useMemo(
    () => compact(selectedBlock?.selectedMetrics.map((m) => blockSetting.metrics.find((metric) => metric.key === m))),
    [selectedBlock, blockSetting.metrics],
  );

  const infoGraphicType = selectedBlock?.infoGraphicType;
  const factorTrendMetric = isFactorTrendMetric(selectedBlock?.selectedMetrics);
  const needsFetch = !blockSetting || requiresAnalysisRequest(blockSetting.customBlockType);

  const allMetricsHaveErrors = useMemo(() => {
    return (
      needsFetch &&
      analyses &&
      metrics &&
      metrics.every((m) => m.analysisType === metrics[0].analysisType) &&
      analyses.every((a) => a.find((analysis) => analysis?.analysisType === metrics[0]?.analysisType)?.message)
    );
  }, [needsFetch, analyses, metrics]);

  const sharedProps = {
    ...restProps,
    factorMetrics,
    subjectColors,
    requests,
    analyses,
    responseParser,
    selectedBlock,
    metrics,
    customBlockType: blockSetting.customBlockType,
  };

  if (blockSetting.customBlockType === 'PEER_GROUPS') {
    return (
      <PeerGroupChartWrapper
        data={analyses?.[0]?.[0]?.peerGroupAnalysis}
        exportable={!!analyses?.[0]?.[0]?.exportable?.[0] ?? false}
        errorMessage={(analyses?.[0]?.[0] as unknown as StudioAnalysis | undefined)?.globalError?.text}
        selectedPeerGroup={selectedPeerGroup}
        {...sharedProps}
      />
    );
  }

  if (allMetricsHaveErrors) {
    return <StyledEmptyState selectedRefId={selectedRefId} header="Unable to run analysis" message="Chart is empty" />;
  }

  if (infoGraphicType === 'DIVERGENT_BAR') {
    if (metrics) {
      return <FactorBar {...sharedProps} />;
    }
    return null;
  }

  // HeatMap allowed only for Factor Trend-related metrics
  if (!factorTrendMetric && infoGraphicType === 'HEATMAP') {
    return <UnsupportedChartFormat selectedRefId={selectedRefId} infoGraphicType={infoGraphicType} metrics={metrics} />;
  }

  if (factorTrendMetric) {
    if (infoGraphicType === 'LINE') {
      return <FactorTrendLineChartParent {...sharedProps} />;
    }
    if (infoGraphicType === 'HEATMAP') {
      return <FactorTrendHeatMapParent {...sharedProps} />;
    }
    return <UnsupportedChartFormat selectedRefId={selectedRefId} infoGraphicType={infoGraphicType} metrics={metrics} />;
  }

  if (infoGraphicType === 'PIE') {
    return <PieChart {...sharedProps} />;
  }

  if (infoGraphicType === 'SCATTER') {
    return <ScatterChart {...sharedProps} />;
  }

  // TODO: fix this file so timeseries chart doesn't have to be an exception here
  if (infoGraphicType === 'DISTRIBUTE_BAR' && blockSetting.customBlockType !== 'TIMESERIES') {
    return <ColumnChart {...sharedProps} />;
  }

  if (infoGraphicType === 'GROWTH_SIMULATION') {
    return <GrowthSimulationChart {...sharedProps} />;
  }

  if (infoGraphicType === 'BOX_CHART') {
    return <BoxChartParent {...sharedProps} />;
  }

  if (blockSetting.customBlockType === 'ROLLING_CORRELATION') {
    return <RollingCorrelationBlock {...sharedProps} />;
  }

  return <TimeseriesChart {...sharedProps} />;
};

export default CustomAnalysisChart;
