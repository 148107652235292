import React from 'react';
import ErrorInsufficientReturns from './shared-errors/ErrorInsufficientReturns';
import ErrorInvalidAnalysisPeriod from './shared-errors/ErrorInvalidAnalysisPeriod';
import ErrorResidualRiskTooHigh from './shared-errors/ErrorResidualRiskTooHigh';
import { getRangeFrequency, getRangeStartAndEnd, processErrorMessage } from './PortfolioRangesUtils';
import type { FailedAnalysisError } from './types';
import type { AnalysisSubject, TimeFrame } from 'venn-utils';
import {
  SchemeAnalysisRequiredPeriod,
  Dates,
  FactorAnalysisRequiredPeriod,
  fundLengthInMonths,
  useHasFF,
} from 'venn-utils';
import type { RangeDebugGroup } from '../../hooks/useRangesDebug';
import ErrorNonPrimarySubjectBroken from './shared-errors/ErrorNonPrimarySubjectBroken';
import ErrorConstrainedFrequency from './shared-errors/ErrorConstrainedFrequency';
import type { FrequencyEnum } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import moment from 'moment';
import { EmptyStateContent } from './components/styled';
import { isNil } from 'lodash';
import ErrorNoReturns from './shared-errors/ErrorNoReturns';

interface ErrorSwitchProps {
  loading: boolean;
  rangeDebugGroup?: RangeDebugGroup;
  subject: AnalysisSubject;
  error: FailedAnalysisError;
  isAdvancedAnalysis?: boolean;
  isScenarioAnalysis?: boolean;
  isFactorTrend?: boolean;
  regressionName: string;
  onResetAnalysisPeriod?: () => void;
  actualTimeFrame: TimeFrame;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  loading,
  rangeDebugGroup,
  error,
  subject,
  isAdvancedAnalysis,
  isScenarioAnalysis,
  isFactorTrend,
  regressionName,
  onResetAnalysisPeriod,
  actualTimeFrame,
}: ErrorSwitchProps) => {
  const hasFullHistory = useHasFF('extend_full_history_ff');
  if (loading) {
    return null;
  }

  const portfolioRange = rangeDebugGroup?.primary.response?.portfolioRange;

  // One of the funds analysed has no returns
  if (error.code === SupportedErrorCodes.NoFundReturns) {
    return <ErrorNoReturns subject={subject} rangeDebugGroup={rangeDebugGroup} />;
  }

  // Benchmark or secondary subject un-analyzable
  if (rangeDebugGroup?.benchmark?.subjectError || rangeDebugGroup?.secondary?.subjectError) {
    return <ErrorNonPrimarySubjectBroken subject={subject} rangeDebugGroup={rangeDebugGroup} />;
  }

  // We're missing subject or range debug data, or portfolio is empty
  if (
    error.code === SupportedErrorCodes.EmptyPortfolio ||
    !subject ||
    !portfolioRange?.factors?.length ||
    !portfolioRange?.investments?.length
  ) {
    return <EmptyStateContent>{error.message}</EmptyStateContent>;
  }

  // Make sure to use max times provided by the backend instead of simply getting the max ourselves
  // since it's possible that differing frequencies cause some upconversion which the BE takes care of
  const [portfolioStart, portfolioEnd] = getRangeStartAndEnd(portfolioRange);
  const availableStart = rangeDebugGroup?.maxStartTime ?? portfolioStart;
  const availableEnd = rangeDebugGroup?.maxEndTime ?? portfolioEnd;
  const analysisFrequency: FrequencyEnum = rangeDebugGroup?.maxFrequency ?? getRangeFrequency(portfolioRange);

  const requiredPeriod = isAdvancedAnalysis
    ? SchemeAnalysisRequiredPeriod(isScenarioAnalysis)
    : FactorAnalysisRequiredPeriod();

  const requiredMonths = requiredPeriod[analysisFrequency].lengthInMonths;
  const analysisMonths = fundLengthInMonths(
    analysisFrequency,
    actualTimeFrame.startTime ?? availableStart,
    actualTimeFrame.endTime ?? availableEnd,
  );

  // Specific drawdown error code
  if (error.code === SupportedErrorCodes.ResidualRiskTooHighForDrawdown) {
    return (
      <ErrorResidualRiskTooHigh defaultMessage={error.message} portfolioRange={portfolioRange} type={subject.type} />
    );
  }

  // Unable to analyze returns below -100%
  if (error.code === SupportedErrorCodes.AnalysisBelowMinus100Percent) {
    return <EmptyStateContent>{error.message}</EmptyStateContent>;
  }

  // Manually selected time frame is wrong
  if (
    availableStart !== undefined &&
    availableEnd !== undefined &&
    availableStart <= availableEnd &&
    (isInvalidPeriod(availableStart, availableEnd, actualTimeFrame.startTime, actualTimeFrame.endTime) ||
      error.code === SupportedErrorCodes.InvalidAnalysisPeriod)
  ) {
    const buttonTitle = hasFullHistory ? 'Reset Date To Factor Lens History' : 'Reset Analysis Period';
    const errorMessage = hasFullHistory
      ? 'Subject has insufficient returns or the selected analysis period is not supported by this block. Adjust the analysis period to run analysis.'
      : `Currently selected analysis period doesn't overlap in full with the available time frame (${Dates.toDDMMMYYYY(
          moment.utc(availableStart).valueOf(),
        )} - ${Dates.toDDMMMYYYY(
          moment.utc(availableEnd).valueOf(),
        )}). Reset analysis period to run analysis over full history.`;

    return (
      <ErrorInvalidAnalysisPeriod
        buttonTitle={buttonTitle}
        defaultMessage={errorMessage}
        onResetAnalysisPeriod={onResetAnalysisPeriod}
      />
    );
  }

  // We can't recommend any fixes if we don't know the frequency
  if (!analysisFrequency) {
    return <EmptyStateContent>{error.message}</EmptyStateContent>;
  }

  /* for monthly and yearly investments we want to show error message that its not possible to run scenario analysis */
  const canRunIfQuarterlyOrYearly =
    regressionName !== 'Scenario Analysis' &&
    regressionName !== 'Sensitivity Analysis' &&
    ['QUARTERLY', 'YEARLY'].includes(analysisFrequency);

  // Frequency is too constrained
  if (analysisFrequency !== 'DAILY' && analysisFrequency !== 'MONTHLY' && !canRunIfQuarterlyOrYearly) {
    return (
      <ErrorConstrainedFrequency
        subject={subject}
        rangeDebugGroup={rangeDebugGroup}
        regressionName={regressionName}
        availableStart={availableStart}
        availableEnd={availableEnd}
      />
    );
  }

  // Less overlap than required or no overlap at all
  if (analysisMonths < requiredMonths || availableEnd < availableStart) {
    return (
      <ErrorInsufficientReturns
        rangeMetadata={{
          availableStart,
          availableEnd,
          frequency: analysisFrequency,
          requiredMonths,
          analysisMonths,
          requiredLabel: requiredPeriod[analysisFrequency].label,
        }}
        subject={subject}
        defaultMessage={processErrorMessage(error.message)}
        regressionName={regressionName}
        onResetAnalysisPeriod={onResetAnalysisPeriod}
        actualTimeFrame={actualTimeFrame}
        rangeDebugGroup={rangeDebugGroup}
        isFactorTrend={isFactorTrend}
      />
    );
  }

  return <EmptyStateContent>{error.message}</EmptyStateContent>;
};

function isInvalidPeriod(availableStart?: number, availableEnd?: number, start?: number, end?: number): boolean {
  if (isNil(availableStart) || isNil(availableEnd)) {
    // There is no available start/end, so the error isn't due to invalid analysis period selected.
    return false;
  }

  if ((!isNil(start) && start > availableEnd) || (!isNil(end) && end < availableStart)) {
    // If it starts after available end, or ends before available start, it's invalid.
    return true;
  }

  return (!isNil(start) && start < availableStart) || (!isNil(end) && end > availableEnd);
}
