import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { compact, sortBy } from 'lodash';
import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import {
  Button,
  GetColor,
  Headline2,
  JumboCheckboxDropMenu,
  Label,
  Notifications,
  NotificationType,
  Tooltip,
} from 'venn-ui-kit';
import { Panel } from '../Layout';
import type { AnalysisBlock, AnalysisBlockTypeEnum, AnalysisTemplate, GeneralAnalysisTemplate } from 'venn-api';
import { saveReportTemplate } from 'venn-api';
import { useHistory } from 'react-router-dom';
import type { AnalysisConfig, TemplateNavigationState } from 'venn-utils';
import {
  analyticsService,
  getAvailableBlocks,
  getBlockByType,
  getPreviousState,
  getRedirectLinkFromHistory,
  logExceptionIntoSentry,
  Routes,
} from 'venn-utils';
import { AnalysisContext, EmptyState, Input, LaunchAnalysisTemplate, UnsavedChangesModal } from 'venn-components';
import TemplateBlocks from './TemplateBlocks';
import { getQueryStringParam } from '../../utils';
import type { History } from 'history';

interface TemplatePanelProps {
  templates: GeneralAnalysisTemplate[];
  analysisConfig: AnalysisConfig;
  onChangeAnalysis: (subject: GeneralAnalysisTemplate) => void;
}

const analysisTemplatePath = `${Routes.DEFAULT_ANALYSIS_PATH}?active=analysis`;

const TemplatePanel = ({ analysisConfig, onChangeAnalysis, templates }: TemplatePanelProps) => {
  const { refresh } = useContext(AnalysisContext);
  const [completedTemplate, setCompletedTemplate] = useState<AnalysisTemplate>();
  const { analysisTemplate: template } = analysisConfig;
  const initialName = template?.id ? template?.name : '';
  const [name, setName] = useState(initialName);
  const [analysisBlocks, setAnalysisBlocks] = useState<AnalysisBlock[]>(template?.analysisBlocks || []);
  const [menuItems, setMenuItems] = useState<DropMenuCheckboxItem<AnalysisBlock>[]>([]);
  const history = useHistory();

  useEffect(() => {
    const items = sortBy(
      compact(
        getAvailableBlocks(templates).map((block: AnalysisBlock): DropMenuCheckboxItem<AnalysisBlock> => {
          return {
            label: block.title,
            description: block.description,
            value: block,
            checked: analysisBlocks.some((b) => b.analysisBlockType === block.analysisBlockType),
          };
        }),
      ),
      'label',
    );
    setMenuItems(items);
  }, [analysisBlocks, templates]);

  const updateAnalysisBlocks = useCallback(
    (newBlocks: AnalysisBlock[]) => {
      setAnalysisBlocks(newBlocks);
      if (!template) {
        return;
      }
      onChangeAnalysis({
        ...template,
        analysisBlocks: newBlocks,
      });
    },
    [onChangeAnalysis, template],
  );

  // Only add the block given in the url query once
  useEffect(() => {
    const param = getQueryStringParam(history.location, 'add') as AnalysisBlockTypeEnum;
    const newBlock = getBlockByType(templates, param);
    if (newBlock && !template?.analysisBlocks?.includes(newBlock)) {
      // When creating a new template (no id), we add to the end rather than the start
      const newBlocks = template?.id ? [newBlock, ...analysisBlocks] : [...analysisBlocks, newBlock];
      updateAnalysisBlocks(newBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  const blocks = useMemo(() => {
    if (analysisBlocks.length === 0) {
      return <StyledEmptyState header="Select at least one analysis to save your template." />;
    }
    return (
      <>
        <StyledLabel>Your selected analyses:</StyledLabel>
        <TemplateBlocks analysisBlocks={analysisBlocks} setAnalysisBlocks={updateAnalysisBlocks} />
      </>
    );
  }, [analysisBlocks, updateAnalysisBlocks]);

  const saveTemplate = useCallback(async () => {
    try {
      const { content: newTemplate } = await saveReportTemplate({
        id: template?.id,
        appUser: template?.appUser,
        description: template?.description,
        name,
        analysisBlocks: analysisBlocks.map((block) => block.analysisBlockType.toString()),
      });

      analyticsService.analysisTemplateSaved({
        analysisNames: analysisBlocks,
        new: template?.id ? 'F' : 'T',
      });

      await refresh();

      UnsavedChangesModal.unblockHistory();
      Notifications.notify('Template successfully saved!', NotificationType.SUCCESS);
      setCompletedTemplate(newTemplate);
    } catch (e) {
      logExceptionIntoSentry(e);
    }
  }, [analysisBlocks, name, refresh, template?.appUser, template?.description, template?.id]);

  let error;
  if (name.trim() === '') {
    error = 'Template name must not be empty';
  } else if (!analysisBlocks.length) {
    error = 'Select at least one analysis to save your template';
  }

  const Primary = (
    <StyledTooltip block content={error}>
      <MainButton dominant className="qa-save-changes" onClick={saveTemplate} disabled={!!error}>
        Save
      </MainButton>
    </StyledTooltip>
  );

  const fallbackUrl = analysisTemplatePath;
  return (
    <Panel>
      <Content>
        <ContentWithBackground>
          <Headline2>{template?.id ? 'Editing template' : 'Create a new template'}</Headline2>
        </ContentWithBackground>
        <NestedContent>
          <Field>
            <Label>Template Name</Label>
            <StyledInput
              placeholder="Enter template name"
              value={name}
              onChange={setName}
              className="qa-template-name"
              selectOnFocus
            />
          </Field>
          <Field>
            <JumboCheckboxDropMenu
              label="Select Your Analyses"
              onChange={(newItem: DropMenuCheckboxItem<AnalysisBlock>) => {
                const newAnalysisBlocks = !newItem.checked
                  ? [...analysisBlocks, newItem.value]
                  : analysisBlocks.filter((block) => block.analysisBlockType !== newItem.value.analysisBlockType);
                updateAnalysisBlocks(newAnalysisBlocks);
              }}
              items={menuItems}
              className="qa-jumbo-wrapper"
            />
          </Field>
          {blocks}
        </NestedContent>
        <MetaActions>
          <SecondaryButton
            onClick={() => {
              if (history.length > 2) {
                const redirectAnalysisLink = getRedirectLinkFromHistory(history as History<TemplateNavigationState>);
                if (redirectAnalysisLink) {
                  // @ts-expect-error Location.state isn't typed, doesn't have state
                  const state = getPreviousState(redirectAnalysisLink, history.location.state?.state);
                  history.push(redirectAnalysisLink, state);
                } else {
                  history.goBack();
                }
              } else {
                // This could happen if a user navigated to the template editor URL directly
                history.push(fallbackUrl);
              }
            }}
          >
            Cancel
          </SecondaryButton>
          {Primary}
        </MetaActions>
      </Content>
      {completedTemplate ? (
        <LaunchAnalysisTemplate
          templateId={completedTemplate.id}
          templateName={completedTemplate.name}
          onCancel={() => history.push(fallbackUrl)}
        />
      ) : null}
    </Panel>
  );
};

export default TemplatePanel;

const Field = styled.div`
  margin-bottom: 35px;
`;

const StyledEmptyState = styled(EmptyState)`
  height: auto;
  width: auto;
  margin-top: 21px;
  padding: 31px 0 15px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const NestedContent = styled.div`
  padding: 40px;
  overflow-y: scroll;
  flex-grow: 1;
`;

const ContentWithBackground = styled.div`
  padding: 12px 40px;
  background-color: ${GetColor.PaleGrey};
  display: flex;
  align-items: center;
  min-height: 94px;
`;

const StyledLabel = styled(Label)`
  display: block;
`;

const StyledInput = styled(Input)`
  margin-top: 5px;
  display: flex;
  height: 50px;

  > input {
    font-size: 20px;
    font-weight: bold;
  }
`;

const MainButton = styled(Button)``;

const MetaActions = styled.div`
  width: 100%;
  display: flex;

  ${MainButton} {
    width: 50%;
    margin-right: 0;
    border-radius: 0;
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  width: 50%;

  ${MainButton} {
    width: 100%;
  }
`;

const SecondaryButton = styled(MainButton)`
  &,
  &:hover {
    border-color: ${GetColor.Grey};
    color: ${GetColor.HintGrey};
  }
`;
