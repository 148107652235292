import type { IHeaderParams } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';

export interface MetricNameHeaderRendererProps extends IHeaderParams {
  errors?: string[];
  errorTooltipMaxWidth?: number;
  padding?: number;
}

const MetricNameHeaderRenderer = ({
  displayName,
  errors,
  padding,
  errorTooltipMaxWidth,
}: MetricNameHeaderRendererProps) => {
  return (
    <Wrapper padding={padding}>
      <Tooltip
        usePortal
        position={TooltipPosition.Top}
        maxWidth={errorTooltipMaxWidth ?? 300}
        content={(errors ?? []).join('; ')}
      >
        <ErrorIcon type="exclamation-circle" prefix="far" hidden={isEmpty(errors)} />
      </Tooltip>
      <span>{displayName}</span>
    </Wrapper>
  );
};

const ErrorIcon = styled(Icon)<{ hidden?: boolean }>`
  color: ${GetColor.Error};
  padding-right: 2px;
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;

const Wrapper = styled.span<{ padding?: number }>`
  display: block;
  width: 100%;
  ${({ padding }) =>
    padding &&
    css`
      padding-top: ${padding}px;
      padding-bottom: ${padding}px;
    `}
`;

export default MetricNameHeaderRenderer;
