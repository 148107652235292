import React, { useMemo } from 'react';
import { Body1, Body2, Button, GetColor, Notifications, Tooltip } from 'venn-ui-kit';
import { FlexHeader, Section } from '../../../shared';
import { Link, useLocation } from 'react-router-dom';
import { Routes, useModal, analyticsService, getViewIdFromLocation } from 'venn-utils';
import { Modal, ModalContent, ModalFooter, ModalHeader, TABS } from 'venn-components';
import styled from 'styled-components';
import { useRecoilCallback, useRecoilValue, waitForAll } from 'recoil';
import {
  allBlockFontsState,
  allBlockIdsState,
  blockFontKeys,
  type CustomBlockFonts,
  getCustomFontSize,
  isReportState,
  baseFontDefaults,
} from 'venn-state';
import { isEqual, pick } from 'lodash';

const fontLabels = {
  blockTitle: 'Block Title',
  blockTableData: 'Table Content',
  blockSubtitle: 'Block Subtitle',
  blockChartAxis: 'Chart Axis Labels',
  blockHeaderInfo: 'Block Header Data',
  blockChartLegend: 'Chart Legends',
} as const satisfies Record<keyof CustomBlockFonts, string>;

/**
 * Section for the left panel that allows the user to reset all block font sizes to the workspace defaults.
 * Component will be hidden in studio, and only shown in report lab.
 */
export const ApplyFontDefaultsSection = () => {
  const [isOpen, open, close] = useModal();
  const location = useLocation();
  const blockIds = useRecoilValue(allBlockIdsState);
  const allBlocksFonts = useRecoilValue(waitForAll(blockIds.map(allBlockFontsState)));
  const defaultFonts = useRecoilValue(baseFontDefaults);
  const isReport = useRecoilValue(isReportState);

  const onApplyDefaults = useRecoilCallback(
    ({ reset }) =>
      () => {
        blockIds.forEach((blockId) => reset(allBlockFontsState(blockId)));
        Notifications.notifyPersistentCustom('Font sizes have been updated for this report.', 'text-size', false);
        analyticsService.resetViewFontSizes({ viewId: getViewIdFromLocation(location) });
        close();
      },
    [close, blockIds, location],
  );

  const hasFontChanges = useMemo(() => {
    const defaultBlockFonts = pick(defaultFonts, blockFontKeys);
    return allBlocksFonts.some((blockFonts) => !isEqual(defaultBlockFonts, blockFonts));
  }, [defaultFonts, allBlocksFonts]);

  if (!isReport) {
    return null;
  }

  return (
    <Section>
      <FlexHeader style={{ columnGap: 8, margin: 0 }}>Apply default font sizes</FlexHeader>
      <DefaultsLabel style={{ lineHeight: '16px' }}>
        All font sizes will be aligned to the defaults set in{' '}
        <Link to={`${Routes.ACCOUNT_PATH}/${TABS.WORKSPACE_CONFIGURATION}`}>Workspace Configuration</Link>
      </DefaultsLabel>
      <Tooltip
        content="All sizes currently aligned with workspace defaults"
        isHidden={hasFontChanges}
        block
        maxWidth={290}
      >
        <Button
          style={{ width: '100%', marginTop: '4px' }}
          dense
          icon="text-size"
          onClick={open}
          disabled={!hasFontChanges}
        >
          Apply defaults...
        </Button>
      </Tooltip>
      {isOpen && (
        <Modal>
          <ModalHeader>Apply Default Font Sizes</ModalHeader>
          <ModalContent>
            <b style={{ fontSize: '18px' }}>
              Block sizes and positions may shift with this change after the following defaults are applied. Be sure to
              check all blocks before saving.
            </b>
            <Grid>
              {Object.keys(fontLabels).map((fontKey) => (
                <Body1 key={fontKey}>
                  {fontLabels[fontKey]}: {getCustomFontSize(defaultFonts[fontKey])}
                </Body1>
              ))}
            </Grid>
          </ModalContent>
          <ModalFooter primaryLabel="Apply defaults" onPrimaryClick={onApplyDefaults} onCancel={close} />
        </Modal>
      )}
    </Section>
  );
};

const Grid = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const DefaultsLabel = styled(Body2)`
  color: ${GetColor.GreyScale.Grey70};
`;
