import React from 'react';
import { SimpleTriggerComponents, type SimpleTriggerComponentProps } from './components/SimpleTriggerComponents';

export const SimpleTrigger = (props: SimpleTriggerComponentProps) => (
  <SimpleTriggerComponents.Container {...props} shouldHighlightBorderOnFocus>
    <SimpleTriggerComponents.Label {...props} />
    <SimpleTriggerComponents.Input {...props} />
  </SimpleTriggerComponents.Container>
);
export default SimpleTrigger;
