import React from 'react';
import ErrorInvalidAnalysisPeriod from '../shared-errors/ErrorInvalidAnalysisPeriod';
import { processErrorMessage } from '../PortfolioRangesUtils';
import type { FailedAnalysisError } from '../types';
import { SupportedErrorCodes } from 'venn-api';
import ComparisonErrorNoReturns from './ComparisonErrorNoReturns';
import { EmptyStateContent, ScreenOnly } from '../components/styled';
import type { ComparisonSubject } from 'venn-utils';

interface ComparisonErrorProps {
  error: FailedAnalysisError;
  onResetAnalysisPeriod?: () => void;
  /**
   * List of subjects used in the Comparison Page
   */
  comparisonSubjects?: ComparisonSubject[];
  /**
   * Callback to remove items from the comparison page by specifying array index in {@link comparisonSubjects}
   */
  removeComparisonSubject?: (idx: number) => void;
}

const ComparisonError = ({
  error,
  onResetAnalysisPeriod,
  comparisonSubjects,
  removeComparisonSubject,
}: ComparisonErrorProps) => {
  if (error.code === SupportedErrorCodes.InvalidAnalysisPeriod) {
    return (
      <ErrorInvalidAnalysisPeriod
        defaultMessage={processErrorMessage(error.message)}
        onResetAnalysisPeriod={onResetAnalysisPeriod}
      />
    );
  }
  if (error.code === SupportedErrorCodes.NoFundReturns && comparisonSubjects) {
    return (
      <ComparisonErrorNoReturns
        comparisonSubjects={comparisonSubjects}
        removeComparisonSubject={removeComparisonSubject}
      />
    );
  }
  if (error.code === SupportedErrorCodes.NoCommonOverlap) {
    return (
      <EmptyStateContent>
        Unable to analyze your selection for this range.
        <ScreenOnly>
          The return streams of the items you're analyzing have <strong>no common overlap</strong>.
        </ScreenOnly>
      </EmptyStateContent>
    );
  }

  return error.message;
};

export default ComparisonError;
