import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Label } from '../../typography';
import { GetColor } from '../../../style/color';
import { Icon } from '../../icon/Icon';
import type { TriggerComponentProps } from '../types';
import TriggerContainer from './TriggerContainer';

const JumboTrigger: React.FC<React.PropsWithChildren<TriggerComponentProps>> = ({
  label,
  selection,
  expanded,
  disabled,
  className,
  innerRef,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyEnter,
  onKeyEsc,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick?.();
    }
  }, [disabled, onClick]);

  return (
    <TriggerContainer
      className={className}
      disabled={disabled}
      expanded={expanded}
      innerRef={innerRef}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      onKeyEnter={onKeyEnter}
      onKeyUp={onKeyUp}
      onKeyEsc={onKeyEsc}
      shouldHighlightBorderOnFocus
    >
      {label && (
        <LabelContainer>
          <Label className="qa-dropmenu-input-label">{label}</Label>
        </LabelContainer>
      )}
      <JumboTriggerInput disabled={disabled}>
        <JumboSelection>
          <Label className="qa-dropmenu-input-content">{selection}</Label>
        </JumboSelection>
        <Icon prefix="far" type={expanded ? 'chevron-up' : 'chevron-down'} />
      </JumboTriggerInput>
    </TriggerContainer>
  );
};

interface StateProps {
  disabled?: boolean;
}

const LabelContainer = styled.div`
  margin-bottom: 5px;
`;

export const JumboSelection = styled.div`
  flex-grow: 1;
  min-width: 0;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
`;

export const JumboTriggerInput = styled.div<StateProps>`
  min-width: 300px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  padding-right: 15px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.disabled ? GetColor.HintGrey : GetColor.Black)};
  background-color: ${(props) => (props.disabled ? GetColor.PaleGrey : GetColor.White)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 20px;

  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    border: 1px solid ${GetColor.Primary.Main};

    i {
      color: ${GetColor.Primary.Main};
    }
  }`}

  label {
    display: inline-block;
    font-size: 20px;
    width: 100%;
    color: ${(props) => (props.disabled ? GetColor.HintGrey : GetColor.Black)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
`;

export default JumboTrigger;
