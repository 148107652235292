import React, { useContext } from 'react';
import { CellLoader, GetColor } from 'venn-ui-kit';
import { PrintContainerDimensions } from 'venn-components';
import { Numbers } from 'venn-utils';

import styled, { ThemeContext } from 'styled-components';

const HEIGHT = 28;

interface BarChartProps {
  loading: boolean;
  max: number;
  mainData?: number;
  benchmarkData?: number;
  comparisonData?: number;
  categoryData?: number;
}

const BarChart = ({ loading, max, mainData, comparisonData, benchmarkData, categoryData }: BarChartProps) => {
  const {
    Schemes: { BarChartColors },
  } = useContext(ThemeContext);
  const colors = [BarChartColors.mainColor, BarChartColors.secondaryColor, BarChartColors.comparisonColor];

  if (loading) {
    return <CellLoader />;
  }
  const data = [mainData, comparisonData, benchmarkData, categoryData].filter(
    (datum) => datum !== undefined && datum !== null,
  );
  if (data.length === 0 || max === 0) {
    return null;
  }
  const height = HEIGHT / data.length;
  return (
    <Group>
      <PrintContainerDimensions>
        {({ width }) => {
          const multiplier = width / (2 * max);
          return (
            <Group style={{ height: HEIGHT }}>
              {data.map((value, index) => (
                <Bar
                  style={{
                    left: value! > 0 ? width / 2 : (value! + max) * multiplier,
                    top: height * index,
                    width: Math.abs(value!) * multiplier,
                    height,
                    backgroundColor: colors[index],
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  data-testid="bar"
                />
              ))}
            </Group>
          );
        }}
      </PrintContainerDimensions>
    </Group>
  );
};

const Group = styled.div`
  position: relative;
`;

const Bar = styled.div`
  position: absolute;
`;

export default BarChart;

interface BarChartAxisFooterProps {
  max: number;
  colSpan: number;
  contents?: React.ReactNode;
}

export const BarChartAxisFooter: React.FunctionComponent<React.PropsWithChildren<BarChartAxisFooterProps>> = ({
  max,
  colSpan,
  contents,
}) => (
  <NoHoverTr>
    <td colSpan={colSpan}>{contents}</td>
    <ChartsTd>
      <span>-{Numbers.safeFormatPercentage(max, 1)}</span>
      <span>0</span>
      <span>{Numbers.safeFormatPercentage(max, 1)}</span>
    </ChartsTd>
  </NoHoverTr>
);

const NoHoverTr = styled.tr`
  &&&:hover {
    background-color: transparent;
  }
`;

const ChartsTd = styled.td`
  display: flex;
  justify-content: space-between;
  border-left: dashed 1px ${GetColor.Grey};
  border-right: dashed 1px ${GetColor.Grey};
  span {
    color: ${GetColor.HintGrey};
    font-size: 12px;
  }
`;
