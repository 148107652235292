import React from 'react';

import { ColumnChart as ReturnsChart } from '../../../../studio-blocks/components/charts/returns-distribution';
import type { GeneralChartProps } from '../../../../studio-blocks/components/charts/types';
import type { ColumnData } from '../../../../studio-blocks';

type ColumnChartProps =
  | (GeneralChartProps & { rowData?: undefined; refId: string })
  | (Omit<GeneralChartProps, 'responseParser' | 'selectedRefId'> & {
      responseParser?: undefined;
      rowData: ColumnData[];
      refId: string;
    });

export const ColumnChart = ({
  refId,
  requests,
  inPrintMode,
  exportMetaData,
  height,
  selectedBlock,
  downloadableContentRef,
  metrics,
  customBlockType,
  subjectColors,
  rowData = [],
}: ColumnChartProps) => {
  return (
    <ReturnsChart
      selectedRefId={refId}
      requests={requests}
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      rowData={rowData}
      downloadableContentRef={downloadableContentRef}
      height={height}
      selectedBlock={selectedBlock}
      metrics={metrics}
      customBlockType={customBlockType}
      subjectColors={subjectColors}
    />
  );
};
