import type { FC } from 'react';
import type React from 'react';
import { useEffect, useRef } from 'react';
import { Routes } from 'venn-utils';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

type OriginalLocationRedirect = RouteComponentProps;

const OriginalLocationRedirect: FC<React.PropsWithChildren<OriginalLocationRedirect>> = ({ children, history }) => {
  const path = history.location && history.location.pathname;

  const originalLocation = useRef(
    path && !path.includes(Routes.SIGN_IN_PATH.replace('/', '')) && !path.includes(Routes.LOGOUT_PATH.replace('/', ''))
      ? history.location
      : null,
  );

  useEffect(() => {
    const originalLoc = originalLocation.current;
    // When component dismounts, redirect to initial location
    return () => {
      if (originalLoc) {
        history.push(originalLoc);
      }
    };
  }, [history]);

  return children;
};

export default withRouter(OriginalLocationRedirect);
