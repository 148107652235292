import React, { useCallback } from 'react';
import classNames from 'classnames';
import DateFilterTrigger from './DateFilterTrigger';
import DateFilterMenu from './DateFilterMenu';
import { SkeletalDropMenu } from '../../drop-menu';

interface MonthYearFilterProps {
  placeholder?: string;
  month?: number;
  year?: number;
  disabled?: boolean;
  className?: string;
  onFilter: (month?: number, year?: number) => void;
}

const DateFilter = ({ placeholder, month, year, disabled, className, onFilter }: MonthYearFilterProps) => {
  const triggerComponent = useCallback(
    (opened, toggleMenu, onApply) => (
      <DateFilterTrigger
        placeholder={placeholder}
        month={month}
        year={year}
        disabled={disabled}
        opened={opened}
        className="qa-date-filter-trigger"
        onClick={toggleMenu}
        onClear={onApply}
      />
    ),
    [placeholder, month, year, disabled],
  );

  const menuComponent = useCallback(
    (onApply) => <DateFilterMenu month={month} year={year} onFilter={onApply} />,
    [month, year],
  );

  return (
    <SkeletalDropMenu
      menuPosition="right"
      className={classNames(className, 'qa-date-picker')}
      triggerComponent={triggerComponent}
      menuComponent={menuComponent}
      onApply={onFilter}
      disabled={!!disabled}
      usePortal
    />
  );
};

export default DateFilter;
