import Highstock from 'highcharts/highstock';
import HighchartHeatmap from 'highcharts/modules/heatmap';
import highchartsMore from 'highcharts/highcharts-more';
import ProvidePatternFill from 'highcharts-pattern-fill';
import type { SeriesBarOptions, SeriesLineOptions, Options as HighchartOptions } from 'highcharts';
import PatternFill from 'highcharts/modules/pattern-fill';
import HighchartsReact from 'highcharts-react-official';
import React, { type RefObject, useMemo } from 'react';

import { useChartReflow } from '../hooks/useChartReflow';
import { cloneDeep } from 'lodash';

highchartsMore(Highstock);
HighchartHeatmap(Highstock);
ProvidePatternFill(Highstock);
PatternFill(Highstock);
Highstock.SVGRenderer.prototype.symbols.cross = (x: number, y: number, w: number, h: number) => [
  'M',
  x,
  y,
  'L',
  x + w,
  y + h,
  'M',
  x + w,
  y,
  'L',
  x,
  y + h,
  'z',
];

Highstock.SVGRenderer.prototype.symbols.shortLine = (x: number, y: number, h: number) => [
  'M',
  x + h / 2,
  y,
  'L',
  x + h / 2 + 2,
  y,
  x + h / 2 + 2,
  y + h,
  x + h / 2,
  y + h,
  'Z',
];

/* Highcharts' hacky 'mini-plugin' to enable text-based Font Awesome icons as custom markers.
 * Usage example: for Font Awesome 'dna' icon, the unicode is 'f471', and so to use it as a custom marker symbol do:
 * {
 *   marker: {
 *     symbol: 'text:\uf471'
 *   }
 * }
 */
Highstock.wrap(
  Highstock.SVGRenderer.prototype,
  'symbol',
  function svgRendererSymbolWrapper(
    this: Highstock.SVGRenderer,
    proceed: Highstock.SVGRenderer['symbol'],
    ...[symbol, ...restArgs]: Parameters<Highstock.SVGRenderer['symbol']>
  ) {
    const [x, y, , h] = restArgs;
    if (symbol.startsWith('text:')) {
      const text = symbol.split(':')[1];
      const svgElem = this.text(text, x, y)
        .attr({
          translateY: h,
          translateX: -1,
        })
        .css({
          fontFamily: '"Font Awesome 5 Pro"',
          fontSize: h ? `${h * 2}px` : undefined,
          'font-weight': 900,
        });
      return svgElem;
    }
    return proceed.apply(this, [].slice.call(arguments, 1));
  },
);

export type VennHighchartPropsInternal = {
  options: HighchartOptions;
  className?: string;
  style?: React.CSSProperties;
  chartRef?: RefObject<HighchartsReact.RefObject>;
  constructorType?: 'mapChart';
  immutable?: boolean;
};

export type VennHighchartProps = Omit<VennHighchartPropsInternal, 'chartRef'>;
export type StudioHighchartProps = VennHighchartPropsInternal & {
  blockId: string;
};

/**
 * Common wrapper around HighchartsReact to provide a consistent interface for all Highcharts charts.
 * @param options options for the chart
 * @param className class name for the chart container
 * @param style additional style options to pass to highcharts
 * @constructor
 */
export const VennHighchartInternal = ({
  options,
  className = 'qa-venn-highchart-container',
  style,
  chartRef,
  constructorType,
  immutable = false,
}: VennHighchartPropsInternal) => {
  // clone the options object to prevent highcharts from mutating our data
  // https://www.npmjs.com/package/highcharts-react-official#:~:text=io/s/1o5y7r31k3-,Why%20Highcharts%20mutates%20my%20data%3F,-It%20can%20be
  // Example incident from the past which was caused by this issue: https://twosigma.atlassian.net/browse/VS-1740
  // with the resolution: https://gitlab.cf.twosigma.com/venn/frontend/-/merge_requests/11597
  const immutableHighchartsOptions = useMemo(() => {
    if (immutable) {
      return options;
    }

    // deep copy looks like the only way to ensure safety, see https://github.com/highcharts/highcharts/issues/4259#issuecomment-623973562
    return cloneDeep(options);
  }, [immutable, options]);

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highstock}
      containerProps={{
        className,
        style,
      }}
      constructorType={constructorType}
      immutable={immutable}
      options={immutableHighchartsOptions}
    />
  );
};

export const VennHighchart = React.memo(function VennHighchart(props: VennHighchartProps) {
  const chartRef = React.useRef<HighchartsReact.RefObject>(null);
  return <VennHighchartInternal {...props} chartRef={chartRef} />;
});

/**
 * Common wrapper around HighchartsReact to provide a consistent interface for all Highcharts charts used in studio.
 * Should be responsive to blocks resizing and zoom functionality out-of-the-box.
 *
 * If chartRef is not provided, an internal ref will be created. This is helpful if the caller wants
 * to perform some additional changes outside the scope of this component.
 */
export const StudioHighchart = React.memo(function StudioHighchart({
  blockId,
  chartRef,
  ...props
}: StudioHighchartProps) {
  const internalChartRef = React.useRef<HighchartsReact.RefObject>(null);
  const ref = chartRef ?? internalChartRef;

  useChartReflow(blockId, ref);
  return <VennHighchartInternal {...props} chartRef={ref} />;
});

export type { Options } from 'highcharts/highstock';
export { Chart } from 'highcharts/highstock';
export type { DashStyleValue as HighchartsDashStyle, TooltipShapeValue as HighchartsTooltipShape } from 'highcharts';
export type HighchartsSeriesOptions = SeriesLineOptions | SeriesBarOptions;
export const hideChartTooltips = () => Highstock.charts.map((chart) => chart?.tooltip?.hide(0));
