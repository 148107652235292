import { groupBy } from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BLOCK_CONTAINER_CLASS } from '../block/DeprecatedBlock';
import { getComputedStyles } from './CssInfo';

const REFRESH_INTERVAL_MS = 500;

export const GridInfo = () => {
  const allCellInfo = useAgGridCellInfo();

  const blockIdToCells = groupBy(allCellInfo, 'blockId');
  const blocks = Object.entries(blockIdToCells).map(([blockId, blockCells]) => {
    const blockName = blockCells[0].blockName;
    const columnHeaderToCells = groupBy(blockCells, 'columnHeaderText');
    return {
      blockId,
      blockName: blockName ?? 'unknown block name',
      columnHeaderToCells,
    };
  });

  return (
    <>
      <h3>Grid Info</h3>
      <p>Shows all ag-grid cells on the page</p>
      {blocks.map(({ blockId, blockName, columnHeaderToCells }) => {
        return (
          <div key={blockId} style={{ borderBottom: '1px solid grey', margin: 1 }}>
            <h4>
              Block Title: {blockName} - Block ID: {blockId}
            </h4>
            {Object.entries(columnHeaderToCells).map(([columnHeader, cells]) => (
              <CellInfoTable key={columnHeader}>
                <thead>
                  <tr>
                    <th colSpan={3}>{columnHeader || ' -- '}</th>
                  </tr>
                  <tr>
                    <th>Content</th>
                    {Object.keys(cells[0].info).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cells.map((cell) => (
                    <tr key={cell.index}>
                      <td
                        style={{
                          maxWidth: '100px',
                          textOverflow: 'ellipsis',
                          overflowX: 'clip',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                        }}
                      >
                        {cell.content}
                      </td>

                      {Object.entries(cell.info).map(([key, value]) => (
                        <td key={key}>
                          {value instanceof CSSRule
                            ? value.cssText
                            : typeof value === 'function'
                              ? 'ERROR: function that needs arguments to print...'
                              : value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </CellInfoTable>
            ))}
          </div>
        );
      })}
    </>
  );
};

const CellInfoTable = styled.table`
  table-layout: fixed;

  display: inline-table;

  th,
  td {
    border: 1px solid black;
    padding: 0 0.5rem;
  }

  @media screen {
    max-height: 600px;
  }
`;

const useAgGridCellInfo = () => {
  const getCellInfo = () => {
    const allCells = document.querySelectorAll('.ag-cell');

    const newCellInfo = Array.from(allCells).map((cell, index) => {
      const content = cell.textContent ?? '';
      const info = getComputedStyles(cell, ['width', 'height']);
      const columnId = cell.getAttribute('col-id');
      const columnHeaderText = columnId
        ? document.querySelector(`.ag-header-cell[col-id="${columnId}"]`)?.textContent?.trim()
        : null;
      const block = cell.closest(`.${BLOCK_CONTAINER_CLASS}`);
      const blockId = block?.id;
      const blockName = block ? block.querySelector('[data-testid="qa-block-header"]')?.textContent : null;
      return { index, content, info, columnHeaderText, blockId, blockName };
    });
    return newCellInfo;
  };

  const [cellInfo, setCellInfo] = React.useState<ReturnType<typeof getCellInfo>>([]);

  useEffect(() => {
    const updateCellInfo = () => {
      setCellInfo(getCellInfo());
    };

    updateCellInfo();
    const iid = setInterval(updateCellInfo, REFRESH_INTERVAL_MS);

    return () => clearInterval(iid);
  }, []);

  return cellInfo;
};
