import React from 'react';
import styled, { css } from 'styled-components';
import { getAppTitle, GetColor, Headline3, Hint, Icon, INTERCOM_HELP_ALL_HREF } from 'venn-ui-kit';

const LEARN_VENN = `What is ${getAppTitle()}?`;

const VennOnboardingVideo = () => {
  return (
    <VideoLauncher
      type="button"
      onClick={() => {
        window.location.href = INTERCOM_HELP_ALL_HREF;
      }}
    >
      <Play>
        <PlayIcon type="expand" prefix="fal" />
        <PlayIcon type="question-circle" inner />
      </Play>
      <div>
        <Headline3>{LEARN_VENN}</Headline3>
        <Hint>{`Visit our help center to learn more about ${getAppTitle()}.`}</Hint>
      </div>
    </VideoLauncher>
  );
};

export default VennOnboardingVideo;

const Play = styled.div`
  position: relative;
  left: 50px;
  width: 50px;
`;

const PlayIcon = styled(Icon)<{ inner?: boolean }>`
  ${({ inner }) => css`
    color: ${inner ? GetColor.Black : GetColor.Grey};
    font-size: ${inner ? '18px' : '60px'};
    top: ${inner ? '20px' : '0'};
    left: ${inner ? '-45px' : '-65px'};
  `}
  position: absolute;
`;

const VideoLauncher = styled.button`
  padding-left: 40px;
  display: flex;
`;
