import React from 'react';
import { noop } from 'lodash';
import type { Portfolio, PortfolioParseResult } from 'venn-api';

enum ConfirmationModalType {
  None,
  Discard,
  Upload,
}
export type PortfolioParseResultCategory = 'existing' | 'new' | 'excluded';
type PortfolioParseResultWithReviewMetadata = PortfolioParseResult & {
  originalIndex: number;
  category: PortfolioParseResultCategory;
};
export type MultiPortfolioReviewContextValue = {
  data: {
    confirmationModalType: ConfirmationModalType;
    excludedParsedData: PortfolioParseResultWithReviewMetadata[];
    existingParsedData: PortfolioParseResultWithReviewMetadata[];
    newParsedData: PortfolioParseResultWithReviewMetadata[];
    parsedResults: PortfolioParseResult[];
    selectedIndex: number;
    savePortfoliosMutationState: {
      isError: boolean;
      isLoading: boolean;
      isIdle: boolean;
    };
  };
  actions: {
    closeConfirmationModal: () => void;
    completeUpload: () => void;
    excludePortfolio: (index: number) => void;
    goBackToUploadStep: () => void;
    includePortfolio: (index: number) => void;
    onBackButtonClick: () => void;
    openDiscardConfirmationModal: () => void;
    openUploadConfirmationModal: () => void;
    savePortfolios: () => void;
    selectAnotherPortfolio: (index: number) => void;
    updatePortfolio: (index: number, portfolio: Portfolio) => void;
  };
};

export const defaultMultiPortfolioReviewContextValue: MultiPortfolioReviewContextValue = {
  actions: {
    closeConfirmationModal: noop,
    completeUpload: noop,
    excludePortfolio: noop,
    goBackToUploadStep: noop,
    includePortfolio: noop,
    onBackButtonClick: noop,
    openDiscardConfirmationModal: noop,
    openUploadConfirmationModal: noop,
    savePortfolios: noop,
    selectAnotherPortfolio: noop,
    updatePortfolio: noop,
  },
  data: {
    confirmationModalType: ConfirmationModalType.None,
    excludedParsedData: [],
    existingParsedData: [],
    newParsedData: [],
    parsedResults: [],
    selectedIndex: 0,
    savePortfoliosMutationState: {
      isError: false,
      isLoading: false,
      isIdle: true,
    },
  },
};

export const MultiPortfolioReviewContext = React.createContext<MultiPortfolioReviewContextValue>(
  defaultMultiPortfolioReviewContextValue,
);
MultiPortfolioReviewContext.displayName = 'MultiPortfolioReviewContext';
