import { noop } from 'lodash';

/**
 * Util for creating a promise, and returning it along with it's resolve function.
 * Used for reducing nesting when resolving promises inside of callbacks.
 */
export const makePromise = <T>() => {
  let resolvePromise: (value: T | PromiseLike<T>) => void = noop;
  const promise = new Promise<T>((resolve) => {
    resolvePromise = resolve;
  });
  return [promise, resolvePromise] as const;
};
