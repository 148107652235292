import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import type { TabularDataTypeEnum } from 'venn-utils';
import { Tooltip } from 'venn-ui-kit';
import { formatData } from '../../../../data-grid/gridUtils';

const MAX_CHARACTERS = 100;
const THOUSAND = 1000;

const MilionValueFormatterTooltip = React.memo(function MilionValueFormatterTooltip({
  value,
  valueFormatted,
  dataType,
}: {
  value: unknown;
  valueFormatted: ReactNode;
  dataType?: TabularDataTypeEnum;
}) {
  if (typeof value !== 'number' || Math.abs(value) < THOUSAND) {
    return valueFormatted;
  }
  const longFormattedNumbers = formatData(value, dataType, 2, MAX_CHARACTERS);
  return (
    <>
      <ScreenTooltipValue usePortal content={longFormattedNumbers}>
        {valueFormatted}
      </ScreenTooltipValue>
      <PrintValue>{valueFormatted}</PrintValue>
    </>
  );
});

const ScreenTooltipValue = styled(Tooltip)`
  @media print {
    display: none;
  }
`;

const PrintValue = styled.span`
  display: none;

  @media print {
    display: inline;
  }
`;

export default MilionValueFormatterTooltip;
