import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Headline3 } from 'venn-ui-kit';
import { getCustomFontSize, type CustomFont } from 'venn-state';

import { type HTMLProps } from '../factory';

export type BlockHeadlineElement = React.ElementRef<'div'>;
export interface BlockHeadlineProps extends HTMLProps<'div'> {
  font: CustomFont;
  isReport: boolean;
  children: React.ReactNode;
}

export const BlockHeadline = forwardRef<BlockHeadlineElement, BlockHeadlineProps>((props) => {
  const { font, isReport, children } = props;

  return (
    <Headline data-testid="qa-block-header" style={{ fontSize: getCustomFontSize(font) }} isReport={isReport}>
      {children}
    </Headline>
  );
});

BlockHeadline.displayName = 'BlockHeadline';

const Headline = styled(Headline3)<{ isReport: boolean }>`
  margin: 0 10px 0 0;
  vertical-align: middle;
  font-size: ${({ isReport }) => (isReport ? '1.225rem' : '21px')};
  ${({ isReport }) => (isReport ? '' : '@media print { font-size: 16pt; }')}
`;
