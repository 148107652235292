import { DiscardUploadConfirmationModal, PersistUploadConfirmationModal } from '../../UploadConfirmationModals';
import React, { useContext } from 'react';
import { MultiPortfolioReviewContext } from './MultiPortfolioReviewContext';

export enum ConfirmationModalType {
  None,
  Discard,
  Upload,
}
export const ConfirmationModal = () => {
  const {
    data: { confirmationModalType },
    actions: { savePortfolios, goBackToUploadStep, closeConfirmationModal },
  } = useContext(MultiPortfolioReviewContext);
  return (
    <>
      {confirmationModalType === ConfirmationModalType.Upload && (
        <PersistUploadConfirmationModal
          onConfirm={() => {
            closeConfirmationModal();
            savePortfolios();
          }}
          onCancel={closeConfirmationModal}
        />
      )}
      {confirmationModalType === ConfirmationModalType.Discard && (
        <DiscardUploadConfirmationModal
          closeEntireUploader={false}
          onConfirm={() => {
            closeConfirmationModal();
            goBackToUploadStep();
          }}
          onCancel={closeConfirmationModal}
        />
      )}
    </>
  );
};
