import type { CellStyle, ColDef } from 'ag-grid-community';
import { createGlobalStyle, css } from 'styled-components';
import { blockGridStyleState, type GridStyle } from 'venn-state';
import { GetColor } from 'venn-ui-kit';
import { DARK_COLUMN_CLASS } from '../../customAnalysisContants';
import { useRecoilValue } from 'recoil';
import { useBlockId } from '../../contexts/BlockIdContext';

/** TODO(collin.irwin, VENN-24627): clean up after watermark design is finalized. */
export const WATERMARK_BEHIND_CLASS = 'venn-watermark-behind';

/** Returns the venn-customized theme constants used within our Studio/RL ag-grid blocks. */
export function useAgGridStyle(): GridStyle {
  const blockId = useBlockId();
  return useRecoilValue(blockGridStyleState(blockId));
}

/**
 * Standard config for auto group columns
 * Cell style is overridden in order to give a consistent 8px indent to each level
 */
export const autoGroupColumnDefStaticConstants: ColDef = {
  headerName: '',
  suppressMovable: true,
  suppressSizeToFit: true,
  cellRendererParams: {
    suppressCount: true,
    suppressDoubleClickExpand: true,
    suppressEnterExpand: true,
  },
  valueGetter: (params) => params.data.label,
  cellStyle: (params): CellStyle => {
    const level = params.node.isRowPinned() ? 0 : params.node.level;

    // 1 is subtracted from the cell padding to take into account the 1px border per cell
    return {
      paddingLeft: `calc(${level} * var(--venn-indent-per-auto-group-level) + var(--ag-cell-horizontal-padding) - 1px)`,
      ...(params.node.allChildrenCount ? { fontWeight: 'bold' } : {}),
    };
  },
};

export const HEADER_LAST_COL_OF_COL_GROUP_CLASS = 'venn-header-last-col-of-col-group';

export const HEADER_LAST_COL_CLASS = 'venn-header-last-col';

/**
 * Global styling component that will override ag-grid theming for all ag-grid instances on the page.
 */
export const AgGridThemeOverrides = createGlobalStyle<{ customFontSizeEnabled: boolean; blockGridStyles: GridStyle[] }>`
        /* REGION: common styles across all themes  */
    ${({ blockGridStyles }) => blockGridStyles.map((style) => `.${style.className}`).join(', ')} {
        --ag-list-item-height: 18px;

        --ag-row-hover-color: ${GetColor.DEPRECATED_DivergingColor.B1};
        --ag-row-border-color: ${GetColor.PaleGrey};
        // Tweak row height to be slightly more compact versus alpine's default that multiplies by 7
        --unrounded-row-height: calc(var(--ag-grid-size) * 6 + 1px);
        --ag-row-height: round(down, var(--unrounded-row-height), 1px);
        --ag-header-height: var(--ag-row-height);

        --ag-icon-size: var(--ag-font-size);

        .ag-row {
            /* Override the default ag-grid styling that increases font size of headers and groups by 1px. */
            font-size: var(--ag-font-size);
        }

        --ag-row-group-indent-size: 0px;

        --ag-input-border-color: ${GetColor.Primary.Main};
        --ag-input-focus-border-color: ${GetColor.Primary.Main};
        --ag-range-selection-border-color: ${GetColor.Primary.Main};
        --ag-range-selection-background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
        --ag-value-change-value-highlight-background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
        --ag-border-radius: 0px;
        --ag-background-color: transparent;

        --ag-header-background-color: #fff;
        /* Customize the header styling to only separate column groups not regular columns. This needs some JS assistance via the HEADER_* classes. */
        --ag-header-column-separator-height: 100%;
        --ag-header-column-separator-width: 2px;

        .ag-header-row:not(:first-child) .ag-header-cell {
            border-top: none;
        }
        .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
          border-top: none;
        }
        .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
        .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
          border-top: none;
        }

        .ag-header-group-cell:not(:last-child), .ag-header-cell.${HEADER_LAST_COL_OF_COL_GROUP_CLASS}:not(.${HEADER_LAST_COL_CLASS}) {
            --ag-header-column-separator-display: block;
        }
    }

    /* REGION: variables or styles specific to a particular theme  */
    ${({ blockGridStyles }) =>
      blockGridStyles.map(
        (gridStyle) => css`
          .${gridStyle.className} {
            --venn-indent-per-auto-group-level: ${gridStyle.indentPerAutoGroupLevel}px;
            --ag-grid-size: ${gridStyle.gridSize};
            --ag-cell-horizontal-padding: ${gridStyle.cellHorizontalPaddingPx}px;
            --ag-font-family: ${gridStyle.fontFamily};
            --ag-font-size: ${gridStyle.fontSize};
          }
        `,
      )}
  
    /*
      * REGION: more common styles that are not scoped to the themes. As a best practice, these should be scoped to the themes instead to prevent accidental styling of ag-grids outside the editor view.
      * TODO: consider moving these to the theme-wrapped section at the top.
      */
    :where(.studio-content-wrapper) {
        .ag-row {
            border: none;
        }

        .ag-cell-wrap-text {
            word-break: break-word;
        }

        .ag-header-cell,
        .ag-header-cell-text,
        .ag-header-group-cell,
        .ag-header-cell-label {
            font-weight: bold;
            text-align: right;
            vertical-align: top;
            flex-direction: row-reverse;
            align-items: flex-start;
        }

        .ag-group-expanded,
        .ag-group-contracted {
            display: none !important;
        }

        .ag-row-odd:not(.ag-row-hover) .${DARK_COLUMN_CLASS} {
            background-color: ${GetColor.PaleGrey};
        }

        .ag-row-even:not(.ag-row-hover) .${DARK_COLUMN_CLASS} {
            background-color: ${GetColor.WhiteGrey};
        }

        .ag-header-row .${DARK_COLUMN_CLASS} {
            /* Header should always be the same color. */
            background-color: ${GetColor.WhiteGrey};
            --ag-header-cell-hover-background-color: ${GetColor.WhiteGrey};
            --ag-header-cell-moving-background-color: ${GetColor.WhiteGrey};
        }

        .ag-text-field-input {
            text-align: inherit;
            font-weight: inherit;
            padding: 0 calc(var(--ag-cell-horizontal-padding) - 1px);
        }

        .ag-floating-bottom {
            border: none;
        }

        .ag-row-pinned {
            border-top: 1px solid ${GetColor.Grey};
            font-weight: bold;

            :not(.ag-row-hover) {
                background-color: ${GetColor.PaleGrey};

                /* Extra specificity needed to override the non-footer dark-column styling */

                .${DARK_COLUMN_CLASS} {
                    background-color: ${GetColor.PaleGrey};
                }
            }
        }

        .${WATERMARK_BEHIND_CLASS} {
            .ag-root-wrapper {
                background-color: transparent;
            }

            .ag-row.ag-row {
                background-color: #00000003;
            }

            .ag-row-even.ag-row-even {
                background-color: #FFFFFF03;
            }

            .ag-row-odd.ag-row-odd {
                background-color: #00000003;
            }
        }

        ${({ customFontSizeEnabled }) =>
          customFontSizeEnabled &&
          css`
            .ag-cell {
              line-height: calc(var(--unrounded-row-height) * 0.9);
              border: unset;
            }

            .ag-sparkline-wrapper {
              position: static;
              height: calc(var(--ag-row-height) - 2px);
            }

            .ag-cell-label-container {
              padding: 5px 0px;
            }
          `}
    }
`;
