import React from 'react';
import styled from 'styled-components';
import { GetColor, LoadingSize, Spinner, ZIndex } from 'venn-ui-kit';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: white;
  z-index: ${ZIndex.ModalCover};
  display: flex;
  align-content: center;
  justify-content: center;
  position: fixed;
`;

const Content = styled.div`
  width: 571px;
  align-self: center;
  font-size: 46px;
  font-weight: 300;
  color: ${GetColor.Black};
  text-align: center;
  padding: 20px;
`;

export interface ModalOverlayProps {
  operationPending: boolean;
  pendingOperationMessage?: string;
}

export const ModalOverlay = (props: ModalOverlayProps) => {
  const { operationPending, pendingOperationMessage } = props;

  if (!operationPending) {
    return null;
  }

  return (
    <Overlay>
      <Content>
        <Spinner size={LoadingSize.medium} />
        <br />
        {pendingOperationMessage}
      </Content>
    </Overlay>
  );
};
