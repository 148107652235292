import { ContentContainer } from './MutliProFormaReviewComponents.RightPane.ContentContainer';
import { ErrorBanner } from './MutliProFormaReviewComponents.RightPane.ErrorBanner';
import { Footer } from './MultiProFormaReviewComponents.Footer';

export const MultiProFormaReviewComponents = {
  RightPane: {
    ContentContainer,
    ErrorBanner,
  },
  Footer,
};
