import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { type HTMLProps } from '../factory';

export type BlockSubjectsRowElement = React.ElementRef<'div'>;
export interface BlockSubjectsRowProps extends HTMLProps<'div'> {
  key?: React.Key;
  children: React.ReactNode;
}

export const BlockSubjectsRow = forwardRef<BlockSubjectsRowElement, BlockSubjectsRowProps>((props, ref) => {
  const { key, children } = props;

  return (
    <Row ref={ref} key={key}>
      {children}
    </Row>
  );
});

BlockSubjectsRow.displayName = 'BlockSubjectsRow';

const Row = styled.div`
  display: flex;
  column-gap: 5px;
  overflow-x: clip;
  /* Prevent superscripts from being cut off */
  overflow-y: visible;
  white-space: nowrap;
  align-items: center;
  min-width: 0;
`;
