import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import {
  EllipsisTooltipSpan,
  Tooltip,
  TooltipPosition,
  GetColor,
  Icon,
  Loading,
  ColorUtils,
  ZIndex,
} from 'venn-ui-kit';
import SaveAndShare from './SaveAndShare';
import { type SaveAsModalCustomContent } from '../modals/save-view/SaveAnalysisViewModal';
import SaveAnalysisViewModal from '../modals/save-view/SaveAnalysisViewModal';
import useSave from './useSave';
import type { ExportButtonProps } from '../share-button/shared';
import type { ShareMetadata } from 'venn-api';
import SaveTab from './SaveTab';
import type { ShareTriggerClickCallback } from '../share-button/ShareEmailButton';
import { UserContext } from '../contexts';
import { ConditionalOverlay } from '../conditional-overlay';
import Portal from '../../../venn-ui-kit/src/components/relative-portal/Portal';

interface SaveShareTopBar extends ExportButtonProps {
  // When it's empty, we don't show buttons
  isEmpty: boolean;
  emptyName: string;
  loading: boolean;
  hasUnsavedChanges: boolean;
  hasSavedBaseline?: boolean;
  onRename?: (name: string) => void;
  onSaveAs?: (name: string) => void;
  shareMetaData: Partial<ShareMetadata>;
  viewTypeName?: string;
  // Show recent views dropdown
  disabledTooltipMsg?: string;
  leftContent?: JSX.Element;
  hideSave?: boolean;
  hideSaveAs?: boolean;
  saveAsModalCustomContent?: SaveAsModalCustomContent;
  printLabel?: string;
  onShareTriggerClick?: ShareTriggerClickCallback;
  canPrintAnalysis?: boolean;
}

const SaveShareTopBar = ({
  // ui params
  isEmpty,
  emptyName,
  isCompare,
  disabled,
  disabledTooltipMsg,
  leftContent,
  // view params
  currentViewName,
  hasUnsavedChanges,
  hasSavedBaseline,
  loading,
  onRename,
  onSave,
  onSaveAs,
  savedId,
  subjectId,
  viewTypeName,
  // share and export params
  shareMetaData,
  downloadXlsx,
  downloadPdf,
  downloadAll,
  downloadPNGs,
  noAccessModifiedView,
  onPrintClick,
  hideSave,
  hideSaveAs,
  saveAsModalCustomContent,
  printLabel,
  onShareTriggerClick,
  canPrintAnalysis = true,
}: SaveShareTopBar) => {
  const { saveModalMode, onRenameClick, onSaveClick, onSaveAsClick, onCancel, onSubmit, timestamp } = useSave({
    hasUnsavedChanges,
    hasSavedBaseline,
    onSave,
    onSaveAs,
    onRenameSavedView: onRename,
    noAccessModifiedView,
    hideSave,
    hideSaveAs,
  });
  const { currentContext } = useContext(UserContext);
  const [isSaving, setIsSaving] = React.useState(false);

  const getDefaultViewName = (viewTypeName: string | undefined, timestamp: string): string => {
    const viewName = ['Saved', viewTypeName, 'View'].filter(Boolean).join(' ');
    return `${viewName} - ${timestamp}`;
  };
  const defaultName =
    saveModalMode === 'SAVE_AS'
      ? `${currentViewName} (Copy)`
      : (currentViewName ?? getDefaultViewName(viewTypeName, timestamp));

  const triggerSaveModalOrSaveImmediately = async () => {
    const shouldSaveImmediately = !noAccessModifiedView && !!currentViewName;
    if (shouldSaveImmediately) {
      setIsSaving(true);
      await onSave?.(defaultName, currentContext);
      setIsSaving(false);
    } else {
      onSaveClick?.();
    }
  };
  const isSavingOverlay = (
    <Portal>
      <Fixed>
        <ConditionalOverlay condition overlay={<Loading title="Saving..." />} className="qa-saving-overlay" />
      </Fixed>
    </Portal>
  );

  return (
    <>
      {isSaving && isSavingOverlay}
      <SaveTab>
        {leftContent}
        <AnalysisText>
          {isEmpty ? (
            <NoAnalysis highlighted>{emptyName}</NoAnalysis>
          ) : (
            <PrintContainerDimensions>
              {({ width }) => (
                <AnalysisName unsaved={hasUnsavedChanges} highlighted greyedOut={!currentViewName}>
                  <EllipsisTooltipSpan maxWidth={width} position={TooltipPosition.Bottom}>
                    {currentViewName ?? 'Unsaved View'}
                  </EllipsisTooltipSpan>
                  {!loading && hasSavedBaseline && !disabled && onRename && (
                    <Tooltip position={TooltipPosition.Bottom} content="Edit view name">
                      <EditIcon type="edit" tabIndex={0} onClick={onRenameClick} className="qa-rename-view" />
                    </Tooltip>
                  )}
                </AnalysisName>
              )}
            </PrintContainerDimensions>
          )}
        </AnalysisText>
        {!isEmpty && (
          <SaveAndShare
            onSaveClick={onSave && onSaveClick ? triggerSaveModalOrSaveImmediately : undefined}
            onSaveAsClick={onSaveAs ? onSaveAsClick : undefined}
            savedId={savedId}
            subjectId={subjectId}
            shareMetaData={shareMetaData}
            isCompare={isCompare}
            downloadXlsx={downloadXlsx}
            downloadPdf={downloadPdf}
            downloadAll={downloadAll}
            downloadPNGs={downloadPNGs}
            onPrintClick={canPrintAnalysis ? onPrintClick : undefined}
            disabled={disabled}
            disabledTooltipMsg={disabledTooltipMsg}
            onSave={onSave}
            hasSavedModified={hasUnsavedChanges && hasSavedBaseline}
            noAccessModifiedView={noAccessModifiedView}
            printLabel={printLabel}
            onShareTriggerClick={onShareTriggerClick}
          />
        )}
        {saveModalMode && (
          <SaveAnalysisViewModal
            mode={saveModalMode}
            defaultName={defaultName}
            onSubmit={onSubmit}
            onClose={onCancel}
            customContent={saveAsModalCustomContent}
          />
        )}
      </SaveTab>
    </>
  );
};

export default SaveShareTopBar;

const AnalysisText = styled.div`
  min-width: 120px;
  width: 100%;
`;

const NoAnalysis = styled.span<{ highlighted: boolean }>`
  ${({ highlighted }) => highlighted && 'font-weight: bold;'}
`;

const AnalysisName = styled.div<{ unsaved: boolean; highlighted: boolean; greyedOut: boolean }>`
  ${({ unsaved }) => unsaved && 'font-style: italic;'}
  ${({ highlighted }) => highlighted && 'font-weight: bold;'}
  ${({ greyedOut }) =>
    greyedOut &&
    css`
      color: ${GetColor.MidGrey2};
    `}

  display: flex;

  > span {
    line-height: 16px;
  }
`;

const EditIcon = styled(Icon)`
  display: inline;
  padding: 4px;
  color: ${GetColor.Grey};

  &:hover {
    color: ${GetColor.Primary.Main};
    cursor: pointer;
  }
`;

const Fixed = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${ColorUtils.hex2rgbaFrom(GetColor.White, 0.85)};
  z-index: ${ZIndex.Cover};

  .qa-saving-overlay {
    width: 100%;
  }
`;
