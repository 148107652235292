import React, { type RefObject, useContext, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { partition, sum } from 'lodash';
import Highstock from 'highcharts/highstock';
import highchartsMap from 'highcharts/modules/map';
import styled, { ThemeContext, withTheme } from 'styled-components';

import type { Theme } from 'venn-ui-kit';
import { REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import type { CustomizedBlock, HoldingsDataResponse } from 'venn-api';
import Measure from 'react-measure';
import { blockFonts, blockLimitedRequestSubjects, holdingsCategoriesData } from 'venn-state';

import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import { WATERMARK_POSITION_TOP } from '../../../customAnalysisContants';
import { createVennMapChartConfig } from '../../../../charts/analysis-charts/chart-config-logic';
import { getMapChartData } from '../../../logic/mapChartParsers';
import { GraphicSubjectLabel } from '../GraphicSubjectLabel';
import AllocationPercentageText from './AllocationPercentageText';
import { useAppPrintMode } from '../../../../print/AppPrintMode';
import { StudioHighchart } from '../../../../highchart/Highchart';
import type HighchartsReact from 'highcharts-react-official';

highchartsMap(Highstock);

export interface MapChartDisplayProps {
  // TODO: use this prop or delete it
  theme: Theme;
  // TODO: use this prop or delete it
  height: number | undefined;
  holdingsData: HoldingsDataResponse;
  selectedBlock: CustomizedBlock;
  downloadableContentRef?: RefObject<HTMLDivElement>;
  selectedRefId: string;
}

const MapChart = ({ selectedBlock, downloadableContentRef, holdingsData, selectedRefId }: MapChartDisplayProps) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const parsedData = holdingsData.breakdowns?.[0].holdingsContributions;
  const categoriesData = useRecoilValue(holdingsCategoriesData('REGION'));
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(selectedRefId));
  const [unknown, known] = partition(parsedData, (contribution) => {
    return contribution.id.id === 'UNKNOWN';
  });
  const series = getMapChartData(categoriesData, known);
  const unknownTotalAllocation = sum(unknown.map((entry) => entry.allocation));
  const { Colors } = useContext(ThemeContext);

  const options = createVennMapChartConfig(series, Colors, legendFont.fontSizePt);
  const subject = useRecoilValue(blockLimitedRequestSubjects(selectedRefId))[0];

  return (
    <DownloadableContentBlock
      header=""
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : 'Geography exposure',
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: false,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: (series?.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      <Measure onResize={() => chartRef.current?.chart?.reflow?.()}>
        {({ measureRef }) => (
          <MapChartContainer ref={measureRef}>
            {subject && inPrintModeOrReportLab && <GraphicSubjectLabel subject={subject} />}
            <StudioHighchart
              options={options}
              blockId={selectedRefId}
              style={{ flex: 1 }}
              constructorType={'mapChart' as const}
              chartRef={chartRef}
            />
            <AllocationPercentageText allocation={1 - unknownTotalAllocation} fontSize={REPORT_LAB_FONT_BODY} />
          </MapChartContainer>
        )}
      </Measure>
    </DownloadableContentBlock>
  );
};

const MapChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export default withTheme(MapChart);
